import React from "react";
import ReactDOM from "react-dom";
import {
  Container,
  Grid,
  Paper,
  Button,
  Hidden,
  CardMedia,
} from "@mui/material";
import "../style/style.css";
import unsplash from "../api/unsplash";
import { login, loginPersistant } from "../utils";
import { useNavigate } from "react-router-dom";

import Homepage from "./Homepage";
import GlobalSnackbar from "./Snackbar";
import Safety from "../assets/images/Safety_sx.jpeg";

const style = { background: "#3b5998" };

class SetPassword extends React.Component {
  constructor() {
    super();

    this.state = {
      isChecked: false,
      passwordShown: false,
      openSnack: false,
      snack: {
        severity: "success",
        messaggio: "Salvataggio Effettuato correttamente",
        open: false,
      },
      icon: "bi bi-eye-slash-fill",
    };
  }
  handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ openSnack: false });
  };
  handleCheck = (e) => {
    this.setState({ isChecked: e.target.checked });
  };
  componentDidMount = () => {
    //Coloro lo sfondo solo qui
    document.body.style.backgroundColor = "rgb(238, 242, 246)";
  };
  handleEyeClick = () => {
    this.setState({ passwordShown: !this.state.passwordShown });

    this.state.passwordShown
      ? this.setState({ icon: "bi bi-eye-slash-fill" })
      : this.setState({ icon: "bi bi-eye-fill" });
  };
  handleCreate = async (event) => {
    const urlParams = new URLSearchParams(window.location.search);
    const user = urlParams.get("user");

    if (!user) {
      this.setState({
        snack: {
          severity: "error",
          messaggio: "ERRORE DURANTE IL CAMBIO PASSWORD",
        },
      });
      this.setState({ openSnack: true });
    }
    const response = await unsplash.put(
      "utenti",
      {
        utente: user,
        password: this.password.value,
        operazione: "OnlyPassword",
      },
      {
        headers: {
          Authorization: `Bearer }`,
        },
      }
    );
    //this.setState({images:response.data});

    if (response.data.message === "utente updated successfully") {
      this.props.navigate("/login");
    } else {
      this.setState({
        snack: {
          severity: "error",
          messaggio: "ERRORE DURANTE IL CAMBIO PASSWORD",
        },
      });
      this.setState({ openSnack: true });
    }
  };

  render() {
    return (
      <>
        <GlobalSnackbar
          severity={this.state.snack.severity}
          messaggio={this.state.snack.messaggio}
          openSnack={this.state.openSnack}
          handleCloseSnack={this.handleCloseSnack}
        />
        {/* <Container maxWidth="sm">
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: "10vh" }}
            >
              <Paper
                elevation={3}
                justifyContent="center"
                alignItems="center"
                style={{ padding: "30px" }}
              >
                <img
                  src={require("../assets/images/MILLERLogo-copia.jpg")}
                  className="img-fluid"
                  alt=""
                />

                <form>
                  <label className="form-label" htmlFor="password">
                    Password
                  </label>
                  <div className="form-outline mb-4 input-group">
                    <input
                      type={this.state.passwordShown ? "text" : "password"}
                      id="password"
                      name="password"
                      ref={(password) => (this.password = password)}
                      className="form-control form-control-lg border-right-0"
                    />
                    <span className="input-group-text bg-transparent">
                      <i
                        className={this.state.icon}
                        style={{ cursor: "pointer" }}
                        onClick={this.handleEyeClick}
                      ></i>
                    </span>
                  </div>

                  <div className="d-flex justify-content-around align-items-center mb-4"></div>

                  <Button
                    fullWidth
                    variant="contained"
                    className="ButtonMiller"
                    onClick={this.handleCreate}
                  >
                    Conferma{" "}
                  </Button>
                </form>
              </Paper>
            </Grid>
          </Grid>
        </Container> */}
        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="center"
          display="flex"
          minHeight="100vh"
          flexWrap="nowrap"
        >
          <Hidden mdDown>
            <Grid
              xs={6}
              sx={{
                margin: "30px",
                borderRadius: "8px",
              }}
            >
              <CardMedia
                sx={{ borderRadius: "8px", height: "calc(100vh - 4rem)" }}
                component="img"
                image={Safety}
                alt="Safety"
              />
            </Grid>
          </Hidden>
          <Grid
            xs={8}
            lg={3}
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexWrap="wrap"
            gap={4}
            style={{ margin: "0 auto" }}
          >
            <img
              src={require("../assets/images/logo.png")}
              className="img-fluid"
              width="200px"
              alt=""
            />

            <form>
              <label className="form-label fs-5" htmlFor="password">
                Password
              </label>
              <div className="form-outline mb-4 input-group">
                <input
                  type={this.state.passwordShown ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Inserisci la password"
                  ref={(password) => (this.password = password)}
                  className="form-control form-control-lg border-right-0 bg-white fs-6"
                />
                <span className="input-group-text bg-transparent">
                  <i
                    className={this.state.icon}
                    style={{ cursor: "pointer" }}
                    onClick={this.handleEyeClick}
                  ></i>
                </span>
              </div>

              <div className="d-flex justify-content-around align-items-center mb-4"></div>

              <Button
                fullWidth
                size="large"
                variant="contained"
                className="ButtonMiller"
                onClick={this.handleCreate}
              >
                Conferma
              </Button>
            </form>
          </Grid>
        </Grid>
      </>
    );
  }
}

function WithNavigate(props) {
  const navigate = useNavigate();

  return <SetPassword {...props} navigate={navigate} />;
}

export default WithNavigate;
