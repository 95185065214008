import React, { useMemo, useState } from "react";
import {
  IconButton,
  ButtonGroup,
  TextField,
  Button,
  Box,
  Typography,
  Tab,
  Tabs,
  Paper,
  Grid,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormControl,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Zoom,
} from "@mui/material";
import Dropzone from "react-dropzone";
import DropzoneComponent from "react-dropzone-component";
import { useDropzone } from "react-dropzone";
import unsplash from "../api/unsplash";
import Upload from "@mui/icons-material/Upload";
import { Close } from "@mui/icons-material";
import GlobalSnackbar from "./Snackbar";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const FileUploader = (props) => {
  const [open, setOpen] = useState(false);
  const {
    acceptedFiles,
    isDragAccept,
    isDragReject,
    isFocused,
    getRootProps,
    getInputProps,
  } = useDropzone();
  const [files, setFiles] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      setSnack({
        severity: "error",
        messaggio: "Nessun file selezionato",
      });
      setOpenSnack(true);
    } else if (props.visiteMedicheLavoratore) {
      if (files.length > 1) {
        setSnack({
          severity: "error",
          messaggio: "Carica solo un file",
        });
        setOpenSnack(true);
      } else {
        const formData = new FormData();
        formData.append("description", props.description);
        formData.append("colonna", props.colonna);
        formData.append("ID", props.id);
        formData.append("colonnaExtra", props.colonnaExtra);
        formData.append("extra", props.extra);

        files.map((file) => {
          formData.append("file", file);
        });
        try {
          await unsplash.post("caricaDoc", formData, {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          });
          setSnack({
            severity: "success",
            messaggio: "File caricato con successo",
          });
          setOpenSnack(true);
          //props.reloadAfterUpload();
        } catch {
          setSnack({
            severity: "error",
            messaggio: "Errore durante il caricamento",
          });
          setOpenSnack(true);
        }
      }
    } else {
      const formData = new FormData();
      formData.append("colonna", props.colonna);
      formData.append("ID", props.id);
      if (
        props.hasOwnProperty("colonnaExtra") &&
        props.colonnaExtra !== undefined &&
        props.colonnaExtra !== ""
      ) {
        formData.append("colonnaExtra", props.colonnaExtra);
        formData.append("extra", props.extra);
      }
      files.map((file) => {
        formData.append("file", file);
      });
      try {
        await unsplash.post("caricaDoc", formData, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });

        setSnack({
          severity: "success",
          messaggio: "File caricato con successo",
        });
        setOpenSnack(true);
        props.reloadAfterUpload();
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante il caricamento",
        });
        setOpenSnack(true);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
      >
        <Button
          className="ButtonMiller"
          startIcon={<Upload />}
          onClick={() => {
            setOpen(true);
            setFiles([]);
          }}
        >
          {props.visiteMedicheLavoratore ? "ASSOCIA IDONEITÀ" : "CARICA"}
        </Button>
      </ButtonGroup>

      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <GlobalSnackbar
          severity={snack.severity}
          messaggio={snack.messaggio}
          openSnack={openSnack}
          handleCloseSnack={handleCloseSnack}
        />
        <DialogTitle>Carica File</DialogTitle>
        <DialogContent>
          {props?.Commento && (
            <div className="italic text-gray-400 text-sm mb-2">
              *{props.Commento}
            </div>
          )}
          <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Trascina e rilascia qui o fai clic per selezionare i file</p>
              </div>
            )}
          </Dropzone>
          <aside>
            <h4 style={{ marginTop: "10px", textAlign: "center" }}>Files</h4>
            {files?.map((file) => {
              return (
                <>
                  <ul style={{ textAlign: "center", padding: 0 }}>
                    {file.path}
                  </ul>
                </>
              );
            })}
          </aside>
        </DialogContent>
        <DialogActions
          xs={12}
          item
          sx={{
            display: "flex",
            mt: 2,
            justifyContent: "space-between",
            px: 2,
          }}
          fullWidth
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
          >
            Annulla
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Upload />}
            onClick={handleUpload}
          >
            Carica
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FileUploader;

/*

	const handleUploadTemp = async () => {
		
		const formData = new FormData();
		formData.append('visitaMedica', 58134);
		formData.append('lavoratore', 30201);
		

		files.map((file) => {
			formData.append('file', file);
		});
		try {
			await unsplash.post('caricaDocCrypt', formData, {
				headers: {
					'content-type': 'multipart/form-data',
					Authorization: `Bearer ${localStorage.getItem('jwt')}`,
				},
			});
			setSnack({
				severity: 'success',
				messaggio: 'File caricato con successo',
			});
			setOpenSnack(true);
			//props.reloadAfterUpload();
		} catch {
			setSnack({
				severity: 'error',
				messaggio: 'Errore durante il caricamento',
			});
			setOpenSnack(true);
		}

}

*/
