import React, { useEffect, useState } from "react";
import unsplash from "../api/unsplash";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Tooltip,
  Checkbox,
  Chip,
  Select,
  Card,
  Grid,
  CardContent,
  OutlinedInput,
  MenuItem,
  ListItemText,
  FormControl,
  InputLabel,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Zoom,
  LinearProgress,
} from "@mui/material";
import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import {
  Add,
  Circle,
  Search,
  HideSource,
  Delete,
  Edit,
} from "@mui/icons-material";
import GlobalSnackbar from "./Snackbar";
import moment from "moment";
import DettaglioCorsi from "./DettaglioCorsi";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const Corsi = () => {
  const [scadenzeCorsiBackup, setScadenzeCorsiBackup] = useState([]);
  const [filtro, setFiltro] = useState({
    verde: true,
    arancione: true,
    rosso: true,
    viola: true,
  });
  const [corsi, setCorsi] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anagraficaCorsi, setAnagraficaCorsi] = useState([]);
  const [modalita, setModalita] = useState([]);
  const [formatori, setFormatori] = useState([]);
  const [corsiSel, setCorsiSel] = useState([]);
  const [modalitaFatturazioneCorsi, setModalitaFatturazioneCorsi] = useState(
    []
  );
  const [corsiAttestati, setCorsiAttestati] = useState([
    {
      Attestato1: "",
      Attestato2: "",
      Attestato3: "",
    },
  ]);
  const [stampa, setStampa] = useState(true);
  const navigate = useNavigate();
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const columns = [
    {
      field: "Actions",
      filterable: false,
      headerName: "Azioni",
      width: 120,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <DettaglioCorsi
            Title="Modifica Corso"
            Corsi={anagraficaCorsi}
            Modalita={modalita}
            Formatori={formatori}
            Operation="Edit"
            id="edit"
            corsoH={params.row}
            /* CorsiAttestati={corsiAttestati}
            Stampa={stampa} */
            ModalitaFatturazioneCorsi={modalitaFatturazioneCorsi}
            reloadCorsi={loadCorsi}
            Tipo={params.row.Tipo}
            Aggiornamento={params.row.Aggiornamento}
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
          />
          <DeleteDialog corso={params.row} myFunction={handleDelete} />
        </>
      ),
    },
    {
      field: "Descrizione",
      headerName: "Descrizione",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Aggiornamento",
      headerName: "Agg.",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },

    {
      field: "ModalitaDescrizione",
      headerName: "Modalità",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Luogo",
      headerName: "Luogo",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "DataInizio",
      headerName: "Data Inizio",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => {
        return (
          <div>
            {params.row.DataInizio
              ? moment(params.row.DataInizio).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },
    {
      field: "DataFine",
      headerName: "Data Fine",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => {
        return (
          <div>
            {params.row.DataFine
              ? moment(params.row.DataFine).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },
    {
      field: "Note",
      headerName: "Note",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Durata",
      headerName: "Durata",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "nGiorni",
      headerName: "N° Giorni",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const handleDelete = async (corso) => {
    try {
      await unsplash.delete("corsi", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          corsoCompleto: corso,
        },
      });

      loadCorsi();

      setSnack({
        severity: "success",
        messaggio: "Corso eliminato con successo",
      });
      setOpenSnack(true);
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione",
      });
      setOpenSnack(true);
    }
  };

  const loadCorsi = async () => {
    let response = await unsplash.get("corsi", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setCorsi(response.data.data);
    setLoading(false);
  };

  const filterData = (e, colore, corsi) => {
    let f = { ...filtro };
    if (colore) {
      f[colore] = e.target.checked;
      setFiltro(f);
    }
    let scadenzeTemp = scadenzeCorsiBackup;
    let checkArr = []; //metto i colori che in base al flag popolo

    if (f.verde) {
      checkArr.push("verde");
    }
    if (f.arancione) {
      checkArr.push("arancione");
    }
    if (f.rosso) {
      checkArr.push("rosso");
    }

    if (f.viola) {
      checkArr.push("viola");
    }

    if (f.rosso && f.verde && f.viola && f.arancione && corsi.length === 0) {
      const filterData = (e, colore, corsi) => {
        let f = { ...filtro };
        if (colore) {
          f[colore] = e.target.checked;
          setFiltro(f);
        }
        let scadenzeTemp = scadenzeCorsiBackup;
        let checkArr = []; //metto i colori che in base al flag popolo

        if (f.verde) {
          checkArr.push("verde");
        }
        if (f.arancione) {
          checkArr.push("arancione");
        }
        if (f.rosso) {
          checkArr.push("rosso");
        }

        if (f.viola) {
          checkArr.push("viola");
        }

        if (
          f.rosso &&
          f.verde &&
          f.viola &&
          f.arancione &&
          corsi.length === 0
        ) {
        } else {
          if (corsi.length > 0) {
            scadenzeTemp = scadenzeTemp.filter(
              (s) =>
                s.Corsi.filter(
                  (sf) =>
                    checkArr.includes(sf.Colore) && corsi.includes(sf.Ruolo)
                ).length > 0
            );
          } else {
            scadenzeTemp = scadenzeTemp.filter(
              (s) =>
                s.Corsi.filter((sf) => checkArr.includes(sf.Colore)).length > 0
            );
          }
        }
      };
    }
  };

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      try {
        loadCorsi();

        let responsecorsi = await unsplash.get(
          "anagraficaCorsi",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          } //props.idAzienda}}
        );

        setAnagraficaCorsi(responsecorsi.data.data);

        let responseFormatori = await unsplash.get(
          "formatori",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          } //props.idAzienda}}
        );
        const formatoriAttvi = responseFormatori.data.data.filter(
          (a) => a.Attivo === 1
        );
        setFormatori(formatoriAttvi);
        let responseModalitaCorsi = await unsplash.get(
          "modalitaCorsi",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          } //props.idAzienda}}
        );
        setModalita(responseModalitaCorsi.data.data);

        let responseModalitaFatturazioneCorsi = await unsplash.get(
          "modalitaFatturazioneCorsi",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          } //props.idAzienda}}
        );
        setModalitaFatturazioneCorsi(
          responseModalitaFatturazioneCorsi.data.data
        );
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  const handleChangeCorsiSel = (event) => {
    setCorsiSel(event.target.value);
    filterData("", "", event.target.value);
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <DettaglioCorsi
              Title="Crea Corso"
              Corsi={anagraficaCorsi}
              Modalita={modalita}
              Formatori={formatori}
              Operation="Nuovo"
              ModalitaFatturazioneCorsi={modalitaFatturazioneCorsi}
              reloadCorsi={loadCorsi}
              snack={snack}
              openSnack={openSnack}
              handleCloseSnack={handleCloseSnack}
              setSnack={setSnack}
              setOpenSnack={setOpenSnack}
            />
            <Box
              sx={{
                height: { xs: "75vh", md: "60vh", xl: "75vh" },
                width: 1,
              }}
            >
              <DataGridPro
                initialState={{ pinnedColumns: { left: ["Actions"] } }}
                sx={{ fontSize: 12 }}
                components={{
                  Toolbar: CustomToolbar,
                  Footer: CustomFooterPersonalized,
                }}
                slots={{
                  loadingOverlay: LinearProgress,
                }}
                loading={loading}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                rows={corsi}
                columns={columns}
                getRowId={(row) => row.ID}
                pageSize={100}
                /* onRowDoubleClick={async (params, event) => {
                        await setCorsoHSel(params.row);

                        handleCorsiAttestati(
                          params.row.Tipo,
                          params.row.Aggiornamento
                        );
                        document.getElementById("edit").click();
                      }} */
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default Corsi;

const DeleteDialog = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
      >
        <DialogTitle id="alert-dialog-title">Eliminare Corso</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare il corso: <br/>
            <b>{props.corso?.Descrizione}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 2,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={() => props.myFunction(props.corso)} >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
