import React, { useState, useEffect } from "react";
import unsplash from "../../api/unsplash";
import {
  LinearProgress,
  Button,
  IconButton,
  Grid,
  Dialog,
  TextField,
} from "@mui/material";

import moment from "moment";
import { itIT } from "@mui/x-data-grid";
import {
  Folder,
  Download,
  VpnKey,
  Healing,
  Check,
  Close,
  RemoveCircle,
  CalendarMonth,
  Place,
  VerifiedUser,
  MedicalInformation,
  Business,
  Visibility,
} from "@mui/icons-material";

import { ToastContainer } from "react-toastify";

import logoChiaro from "../../assets/images/Logo-chiaro.png";
import { useNavigate } from "react-router-dom";

import { useRef } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import GlobalSnackbar from "../Snackbar";
import { ContainerList } from "../AccessoLavoratore/ContainerList";
import { AllegatiList } from "../AccessoLavoratore/AllegatiList";

const ext = {
  doc: "application/msword",
  dot: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  dotx: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  docm: "application/vnd.ms-word.document.macroEnabled.12",
  dotm: "application/vnd.ms-word.template.macroEnabled.12",
  xls: "application/vnd.ms-excel",
  xlt: "application/vnd.ms-excel",
  xla: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xltx: "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  xlsm: "application/vnd.ms-excel.sheet.macroEnabled.12",
  xltm: "application/vnd.ms-excel.template.macroEnabled.12",
  xlam: "application/vnd.ms-excel.addin.macroEnabled.12",
  xlsb: "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  ppt: "application/vnd.ms-powerpoint",
  pot: "application/vnd.ms-powerpoint",
  pps: "application/vnd.ms-powerpoint",
  ppa: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  potx: "application/vnd.openxmlformats-officedocument.presentationml.template",
  ppsx: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  ppam: "application/vnd.ms-powerpoint.addin.macroEnabled.12",
  pptm: "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  potm: "application/vnd.ms-powerpoint.template.macroEnabled.12",
  ppsm: "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
  mdb: "application/vnd.ms-access",
  png: "application/png",
  pdf: "application/pdf",
};

const HomeMedico = () => {
  const [loadingDoc, setLoadingDoc] = useState(true);
  const [loadingVis, setLoadingVis] = useState(true);
  const [prossimeVisite, setProssimeVisite] = useState([]);
  const [allegati, setAllegati] = useState([]);

  const divContainer = useRef();

  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  //CALCOLO LARGHEZZA TOTALE WIDTH
  const [screenSize, getDimension] = useState(
    window.innerWidth >= "1024" ? window.innerWidth - 256 : window.innerWidth
  );
  const setDimension = () => {
    getDimension(
      screenSize >= "1024"
        ? divContainer?.current?.offsetWidth
        : divContainer?.current?.offsetWidth * 3
    );
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    (() => {
      reloadAll();
    })();
  }, []);

  const reloadAll = async () => {
    try {
      let respData = await unsplash.post(
        "portaleMedici",
        {
          operation: "GetAll",
          medico: sessionStorage.getItem("Medico"),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      let tempVisite = respData.data.visite.filter(
        (el) =>
          moment(new Date()).format("YYYYMMDD") <=
          moment(el.DataWithoutTime).format("YYYYMMDD")
      );
      let tempDocumenti = respData.data.documenti.filter(
        (el) => !el.DataVisualizzazione
      );
      setProssimeVisite(tempVisite);
      setLoadingVis(false);
      setAllegati(tempDocumenti);
      setLoadingDoc(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  const downloadFile = async (id, estensione, descrizione, operazione) => {
    try {
      const response = await unsplash.post(
        "ftp",
        {
          file: id + estensione,
          nomeOriginale: descrizione.includes(estensione)
            ? descrizione
            : descrizione + estensione,
          operazione: "download",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: ext[estensione.replace(".", "")] })
      );
      const link = document.createElement("a");
      link.href = url;
      if (operazione === "download") {
        link.setAttribute(
          "download",
          descrizione.includes(estensione)
            ? descrizione
            : descrizione + estensione
        );
        document.body.appendChild(link);
        link.click();
      }
      setSnack({
        severity: "success",
        messaggio: "File scaricato con successo",
      });
      setOpenSnack(true);
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante il dowload del file",
      });
      setOpenSnack(true);
    }
  };

  const downloadFileCrypt = async (
    id,
    estensione,
    descrizione,
    operazione,
    criptato,
    estensioneOriginale,
    psw
  ) => {
    /* let psw;

    if (criptato === 1) {
      psw = window.prompt(
        "Inserisci la tua password per scaricare questo documento"
      );
    } */
    try {
      const response = await unsplash.post(
        "ftp",
        {
          file: id + estensione,
          nomeOriginale: descrizione.includes(estensione)
            ? descrizione
            : descrizione + estensione,
          operazione: "downloadCrypt",
          estensioneOriginale: estensioneOriginale,
          password: psw,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: ext[estensione.replace(".", "")] })
      );
      const link = document.createElement("a");
      link.href = url;
      if (operazione === "download") {
        link.setAttribute(
          "download",
          descrizione.replace(estensione, estensioneOriginale)
        );
        document.body.appendChild(link);
        link.click();
      }
      setSnack({
        severity: "success",
        messaggio: "File scaricato con successo",
      });
      setOpenSnack(true);
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante il dowload del file",
      });
      setOpenSnack(true);
    }
  };

  const columns = [
    /* {
      field: "Data",
      headerName: "Data",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) =>
        params.row.Data
          ? moment(params.row.Data).format("DD/MM/YYYY HH:mm")
          : "",
    }, */
    {
      field: "DataWithoutTime",
      headerName: "Data",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) =>
        params.row.DataWithoutTime
          ? moment(params.row.DataWithoutTime).format("DD/MM/YYYY")
          : "",
    },
    {
      field: "Luogo",
      headerName: "Luogo",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Lavoratori",
      headerName: "N° Lavoratori",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    /* {
      field: "CF",
      headerName: "CF",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "LavoratoreNomeCompleto",
      headerName: "Lavoratore",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Luogo",
      headerName: "Luogo",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    }, */
  ];

  const columnsAllegati = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "100",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <ModalNewDoc
          el={params.row}
          reloadAll={reloadAll}
          download={() =>
            downloadFile(
              params.row.ID,
              params.row.Estensione,
              params.row.Descrizione,
              "download"
            )
          }
          downloadCrypt={downloadFileCrypt}
        />
      ),
    },
    {
      field: "Descrizione",
      headerName: "Descrizione",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) =>
        params.row.Criptato === 1
          ? params.row.Descrizione.replace(
              params.row.Estensione,
              params.row.EstensioneOriginale
            )
          : params.row.Descrizione,
    },
  ];

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <div className="flex flex-wrap">
        <div className=" bg-primary-600 dark:bg-white p-4 sm:p-7 rounded-md h-fit w-full">
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            pauseOnHover
            theme="colored"
          />
          {/* Background illustration */}

          {/* Content */}
          <div className="relative">
            {/* <span className="absolute inset-y-0 right-0 top-0 pointer-events-none z-50 hidden xl:block h-20 w-20 light-logo"></span> */}
            <div className="absolute inset-y-0 right-0 top-0 pointer-events-none z-10 hidden xl:block">
              <img src={logoChiaro} alt="" className="h-20 w-20" />
            </div>
            <h1 className="text-2xl md:text-3xl text-white dark:text-primary-600 font-bold mb-1">
              Benvenuto, {sessionStorage.getItem("Cognome")}.
            </h1>
            <p className="text-white dark:text-primary-600 w-5/6 ">
              Visualizza e gestisci i tuoi livelli di sicurezza direttamente
              dalla dashboard dedicata. Oltre a monitorare l’attività ti
              suggeriamo tutte le azioni necessarie per migliorare la sicurezza
              della tua attività.
            </p>
          </div>
        </div>
        <div className="text-xl py-3 font-semibold  dark:text-white">
          Prossimi eventi e novità:
        </div>
        <div className="flex flex-row h-fit w-full gap-4 flex-wrap">
          {allegati.length === 0 &&
            prossimeVisite.length === 0 &&
            allegati.length === 0 && (
              <div className="w-full rounded-md p-4 bg-azulMiller/70 text-white">
                <div className="text-xl pb-2">
                  <RemoveCircle
                    className="mr-2 text-primary-600"
                    fontSize="large"
                  />
                  Vuoto
                </div>
              </div>
            )}
          {prossimeVisite.length > 0 && (
            <div className="w-full  rounded-md p-4 bg-azulMiller/70 text-white">
              <div className="text-xl pb-2">
                <Healing className="mr-2 text-primary-600" fontSize="large" />
                Visite  ({prossimeVisite.length})
              </div>

              <div className="flex items-center justify-between my-2">
                <div className="lg:block hidden h-96 w-full">
                  <DataGridPro
                    sx={{ fontSize: 12, backgroundColor: "white" }}
                    localeText={
                      itIT.components.MuiDataGrid.defaultProps.localeText
                    }
                    slots={{
                      loadingOverlay: LinearProgress,
                    }}
                    loading={loadingVis}
                    rows={prossimeVisite}
                    columns={columns}
                    getRowId={(row) => row.DataWithoutTime}
                    pageSize={100}
                    disableSelectionOnClick
                  />
                </div>
                <div className="block lg:hidden max-h-96  w-full text-black overflow-y-auto">
                  <ContainerList
                    data={prossimeVisite.map((visita) => {
                      //Accorpo i giorni uguali
                      return {
                        ...visita,
                        DataFilter: moment(visita.Data).format(
                          "DD/MM/YYYY"
                        )
                      };
                    })}
                    header={["Giorno", "Visita"]}
                    leftCard={["Data"]}
                    additionalFilter={["DataFilter"]}
                    rightCard={[
                      { title: true, prop: "Lavoratori" },
                      {
                        prop: "DataFilter",
                        icon: <CalendarMonth fontSize="12" />,
                      },
                      { prop: "Luogo", icon: <Place fontSize="12" /> },
                    ]}
                    titleCard="Lavoratori: "
                    home={true}
                  />
                </div>
              </div>
            </div>
          )}
          {allegati.length > 0 && (
            <div className="w-full  rounded-md p-4 bg-azulMiller/70 text-white">
              <div className="text-xl pb-2">
                <Folder className="mr-2 text-primary-600 " fontSize="large" />
                Nuovi Documenti  ({allegati.length})
              </div>
              <div className="flex items-center justify-between my-2">
                <div className="lg:block hidden h-96 w-full">
                  <DataGridPro
                    sx={{ fontSize: 12, backgroundColor: "white" }}
                    localeText={
                      itIT.components.MuiDataGrid.defaultProps.localeText
                    }
                    slots={{
                      loadingOverlay: LinearProgress,
                    }}
                    loading={loadingDoc}
                    rows={allegati}
                    columns={columnsAllegati}
                    getRowId={(row) => row.ID}
                    pageSize={100}
                    disableSelectionOnClick
                  />
                </div>
                <div className="block lg:hidden max-h-96  w-full text-black overflow-y-auto">
                  <AllegatiList
                    data={allegati.map((al) => {
                      return {
                        ...al,
                        DataVisualizzazione: al.DataVisualizzazione
                          ? moment(al.DataVisualizzazione).format("DD/MM/YYYY")
                          : "",
                      };
                    })}
                    rightCard={[
                      { title: true, prop: "Descrizione" },
                      {
                        prop: "DataVisualizzazione",
                        icon: <Visibility fontSize="medium" />,
                      },
                    ]}
                    component={
                      <ModalNewDoc
                        reloadAll={reloadAll}
                        downloadCrypt={downloadFileCrypt}
                        download={downloadFile}
                      />
                    }
                    home={true}
                  />
                </div>
              </div>
              
            </div>
          )}
          {/* <div
          className="w-full lg:w-1/3 rounded-md p-4 bg-azulMiller/70 text-white"
          //onClick={() => navigate("/Help")}
        >
          <School className="mr-2 text-primary-600" />
          <b>Prossimi Corsi </b>
          <div className="flex items-center justify-between my-2">
            <div className="h-96  w-full">
              <DataGridPro
                sx={{ fontSize: 12, backgroundColor: "white" }}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                rows={prossimiCorsi}
                columns={columnsCorsi}
                getRowId={(row) => row.ID}
                pageSize={100}
                disableSelectionOnClick
              />
            </div>
          </div>
        </div> */}
        </div>
      </div>
    </>
  );
};

export default HomeMedico;

const ModalNewDoc = (props) => {
  const [open, setOpen] = React.useState(false);
  const [password, setPassword] = useState("");
  const [openMessage, setOpenMessage] = useState(false);

  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confermaEvisuallizza = async () => {
    await unsplash.put(
      "allegati/primaVisualizzazione",
      { id: props.el.ID },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );

    if (props.el.Criptato === 1) {
      setOpenMessage(true);
    } else {
      props.download();
      props.reloadAll();
      setOpen(false);
    }
  };

  return (
    <>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Download style={{ height: "2rem", width: "2rem", color: "#11526F" }} />
      </IconButton>
      {props.el.Criptato === 1 ? (
        <IconButton aria-label="edit">
          <VpnKey style={{ color: "#11526F" }} />
        </IconButton>
      ) : (
        <></>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={"md"}
        maxWidth={"md"}
      >
        <GlobalSnackbar
          severity={snack.severity}
          messaggio={snack.messaggio}
          openSnack={openSnack}
          handleCloseSnack={handleCloseSnack}
        />
        <div className="py-6 px-6 lg:px-8">
          <h3 className="mb-4 text-xl font-medium text-center text-black">
            {openMessage ? "Controllo" : "Allegati"}
          </h3>
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="absolute top-4 right-5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="authentication-modal"
          >
            <svg
              aria-hidden="true"
              className="w-7 h-7"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="w-full">
            {openMessage ? (
              <>
                <p>Inserisci la tua password per scaricare questo documento:</p>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="Password"
                    label="Password"
                    type="text"
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
              </>
            ) : (
              <p>
                Confermi di voler visualizzare il seguente documento:{" "}
                <b>{props.el.Descrizione}</b>?
              </p>
            )}
            <div className="flex justify-between mt-4 ">
              <Button
                className="ButtonMiller"
                variant="contained"
                startIcon={<Close />}
                //className="bg-primary-600 text-white p-2 hover:bg-primary-100 mb-2 transition-all duration-300 ease-in-out"
                onClick={handleClose}
              >
                Annulla
              </Button>
              <Button
                className="ButtonMiller"
                variant="contained"
                startIcon={<Check />}
                //className="bg-primary-600 text-white p-2 hover:bg-primary-100 mb-2 transition-all duration-300 ease-in-out"
                onClick={() => {
                  if (!openMessage) {
                    confermaEvisuallizza();
                  } else {
                    props.downloadCrypt(
                      props.el.ID,
                      props.el.Estensione,
                      props.el.Descrizione,
                      "download",
                      props.el.Criptato,
                      props.el.EstensioneOriginale,
                      password
                    );
                    setOpen(false);
                    setOpenMessage(false);
                    setPassword("");
                  }
                }}
              >
                {openMessage ? "Invia" : "Conferma e visualizza"}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
