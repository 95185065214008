import React from 'react';
import { Navigate } from 'react-router-dom';
import { isLogin } from '../utils';

function PrivateRouteCliente({ children }) {
    const logok = isLogin();


    return logok ? children : <Navigate to="/login" />;
  }

export default PrivateRouteCliente;
