import React, { useEffect, useState } from "react";
import unsplash from "../api/unsplash";
import GlobalSnackbar from "./Snackbar";
import LinearProgress from "@mui/material/LinearProgress";

import {
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Zoom,
  DialogActions,
  DialogContentText,
  Autocomplete,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import { IconButton } from "@mui/material";

import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";

import { Add, Delete, Close, Check } from "@mui/icons-material";
import {
  LocalizationProvider,
  DatePicker,
  PickersDay,
  TimePicker,
  DateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment as DateAdapter } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const MediciKm = ({ idSede, idAzienda }) => {
  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={async () => {
              await setMedicoKmSelezionata(params.row);
              document.getElementById("edit").click();
              //handleClickOpen();
            }}
          >
            <EditIcon
              style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
            />
          </IconButton>

          <DeleteDialog medicoKm={params.row} myFunction={handleDelete} />
        </>
      ),
    },
    {
      field: "Descrizione",
      headerName: "Descrizione",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Km",
      headerName: "Km",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Orario",
      headerName: "Orario",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        if (params.row.Orario) {
          const ora = params.row.Orario.split(":");
          return <div>{params.row.Orario ? `${ora[0]}:${ora[1]}` : ""}</div>;
        }
      },
    },
  ];
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [mediciKm, setMediciKm] = useState([]);
  const [loading, setLoading] = useState(true);
  const [medicoKmSelezionata, setMedicoKmSelezionata] = useState(null);
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const [mediciCliente, setMediciCliente] = useState(false);

  const toggleAlert = () => {
    setOpenAlert(!openAlert);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    (async () => {
      reloadAfterUpload();
      let responseMediciCliente = await unsplash.get(
        "medici",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { id: idAzienda },
        } //props.idAzienda}}
      );

      setMediciCliente(responseMediciCliente.data.data);
    })();
  }, []);

  const reloadAfterUpload = async () => {
    try {
      let response = await unsplash.get("mediciKm", {
        params: {
          id: idSede,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      setMediciKm(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleSave = async (med, operazione) => {
    let medico = { ...med, Sede: idSede };
    if (operazione === "new") {
      //rep.IDSedi = sede.ID;
      try {
        await unsplash.post(
          "mediciKm",
          {
            medicoKm: medico,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "MedicoKm creato con successo",
        });
        setOpenSnack(true);

        reloadAfterUpload();
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante la creazione",
        });
        setOpenSnack(true);
      }
      handleClose();
    } else {
      try {
        await unsplash.put(
          "mediciKm",
          {
            medicoKm: medico,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "MedicoKm aggiornato con successo",
        });
        setOpenSnack(true);

        reloadAfterUpload();
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'aggiornamento",
        });
        setOpenSnack(true);
      }
      //handleClose();
    }
  };

  const handleDelete = async (med) => {
    try {
      await unsplash.delete("mediciKm", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          ID: med,
        },
      });

      setSnack({
        severity: "success",
        messaggio: "MedicoKm eliminato con successo",
      });
      setOpenSnack(true);
      reloadAfterUpload();
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione dell'MedicoKm",
      });
      setOpenSnack(true);
    }
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={2}
      >
        <DettaglioMediciKm
          id="edit"
          medicoKm={medicoKmSelezionata}
          saveFunction={handleSave}
          title="Modifica Km Medici"
          mediciCliente={mediciCliente}
        />
        <DettaglioMediciKm
          id="new"
          medicoKm={medicoKmSelezionata}
          saveFunction={handleSave}
          title="Nuovo Km Medici"
          mediciCliente={mediciCliente}
        />
      </Grid>
      <Box sx={{ height: "60vh", width: 1 }}>
        <DataGridPro
          initialState={{
            pinnedColumns: { left: ["Actions"] },
          }}
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooterPersonalized,
          }}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={loading}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rowHeight={52}
          rows={mediciKm}
          columns={columns}
          getRowId={(row) => row.ID}
          pageSize={20}
          rowsPerPageOptions={[20]}
          disableSelectionOnClick
        />
      </Box>
    </>
  );
};

export default MediciKm;

const DettaglioMediciKm = (props) => {
  const [open, setOpen] = useState(false);
  const [orario, setOrario] = useState(null);

  const [medicoSel, setMedicoSel] = useState(null);
  const [medicoKm, setMedicoKm] = useState({
    Medico: null,
    Km: 0,
    Orario: null,
  });

  const handleClickOpen = () => {
    if (props.id === "edit") {
      setMedicoKm(props.medicoKm);
      setMedicoSel(props.medicoKm);
      setOrario(
        props.medicoKm?.Orario
          ? moment(new Date()).format("YYYY-MM-DD") +
              " " +
              props.medicoKm?.Orario
          : null
      );
    } else {
      setMedicoKm({
        Medico: null,
        Km: 0,
        Orario: null,
      });
      setOrario(null);
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setMedicoKm({
      Medico: null,
      Km: 0,
      Orario: null,
    });
  };
  const handleChange = (e) => {
    let copiaMedicoKm = { ...medicoKm };
    copiaMedicoKm[e.target.name] = e.target.value;
    setMedicoKm(copiaMedicoKm);
  };
  const handleSave = async () => {
    props.saveFunction(medicoKm, props.id);
    handleClose();
  };

  return (
    <>
      {props.id === "edit" ? (
        <Button
          startIcon={<Add />}
          onClick={handleClickOpen}
          id={props.id}
          sx={{ color: "white", display: "none" }}
          variant="outlined"
          className="ButtonMiller"
        >
          Aggiungi
        </Button>
      ) : (
        <Button
          startIcon={<Add />}
          onClick={handleClickOpen}
          id={props.id}
          sx={{ color: "white" }}
          variant="outlined"
          className="ButtonMiller"
        >
          Aggiungi
        </Button>
      )}

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="md"
          TransitionComponent={Transition}
        >
          {/* Title Container */}
          <DialogTitle
            sx={{
              mx: "auto",
            }}
          >
            {props.title}
          </DialogTitle>
          {/* Body Container */}
          <DialogContent>
            {/* Row 2 */}
            <Grid container spacing={2}>
              <Grid item xs={12} mt={2}>
                <Autocomplete
                  value={medicoSel}
                  id="combo-box-demo"
                  options={props.mediciCliente}
                  getOptionLabel={(option) =>
                    option.Nome + " " + option.Cognome
                  }
                  fullWidth
                  onChange={(event, newValue) => {
                    setMedicoSel(newValue);
                    let d = { ...medicoKm };
                    d.Medico = newValue.ID;
                    setMedicoKm(d);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="Medico" />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={medicoKm?.Km}
                  id="outlined-basic"
                  label="Km"
                  name="Km"
                  type="number"
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <TimePicker
                    sx={{ width: "100%" }}
                    ampmInClock={false}
                    renderInput={(props) => <TextField fullWidth {...props} />}
                    label="Orario"
                    value={moment(orario)}
                    onChange={(newValue) => {
                      let r = { ...medicoKm };
                      setOrario(newValue);
                      if (newValue) {
                        r.Orario = newValue.format("HH:mm:ss");
                      }
                      setMedicoKm(r);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            {/* Bottoni Salva & Esci */}
          </DialogContent>
          <DialogActions
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mx: 2,
            }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Close />}
            >
              Chiudi
            </Button>
            <Button
              onClick={handleSave}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Check />}
            >
              Salva
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};
const DeleteDialog = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare l'medico Km selezionata?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare:
            <br />
            <b>{props.medicoKm.Descrizione}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={() => props.myFunction(props.medicoKm.ID)}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
