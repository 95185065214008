import React, { useEffect, useState } from "react";
import unsplash from "../api/unsplash";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import Allegati from "./Allegati";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import moment from "moment";
import "moment/locale/it";
import { PersonOff, Person, InsertDriveFile } from "@mui/icons-material";
import {
  LinearProgress,
  ButtonGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Box,
  Tooltip,
  Card,
  CardContent,
  Zoom,
} from "@mui/material";
import DettaglioLavoratore from "./DettaglioLavoratore/DettaglioLavoratore";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const LavoratoriSicurezza = () => {
  const [scadenzeBackup, setScadenzeBackup] = useState([]);
  const [tipologiaVisita, setTipologiaVisita] = useState();
  const [corsiSel, setCorsiSel] = useState([]);
  const [lavoratore, setLavoratore] = useState(0);
  const [lavoratoriSel, setLavoratoriSel] = useState([]);
  const [medici, setMedici] = useState();
  const [esiti, setEsiti] = useState();
  const [scadenze, setScadenze] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filtro, setFiltro] = useState({ attivi: true, passivi: false });
  const [filtroEtichette, setFiltroEtichette] = useState({
    Mansione: "",
    Lavoratore: "",
    Blocco: "",
    Cliente: "",
  });

  const [filtroClessidera, setFiltroClessidera] = useState({
    verde: true,
    arancione: true,
    rosso: true,
    blu: true,
  });

  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      headerAlign: "center",
      hide: false,
      width: "100",
      align: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <ButtonGroup>
          <DettaglioLavoratore
            lavoratore={params.row}
            cliente={params.row.Cliente}
            loadLavoratori={loadLavoratori}
          />
        </ButtonGroup>
      ),
    },
    {
      filterable: false,
      field: "DataTermine",
      headerName: "Attivo",
      width: 60,
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => {
        let attivo =
          !params.row.DataTermine ||
          moment(params.row.DataTermine).format("YYYY-MM-DD") >
            moment(new Date()).format("YYYY-MM-DD");

        return (
          <div>
            {attivo ? (
              <Tooltip title="Attivo">
                <Person sx={{ color: "#40C18F" }} />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  "Cessato il " +
                  moment(params.row.DataTermine).format("DD/MM/YYYY")
                }
              >
                <PersonOff sx={{ color: "#FF2768" }} />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      field: "RagioneSociale",
      headerName: "Cliente",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Cognome",
      headerName: "Cognome",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Nome",
      headerName: "Nome",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "SedeBlocco",
      headerName: "Blocco",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Descrizione",
      headerName: "Mansione",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
  ];

  /* const handleChange = (e) => {
    let backup = [...scadenze];
    var lavoratoreX = [];
    var i;
    for (i = 0; i < backup.length; i++) {
      if (
        backup[i].Nome.toLowerCase().includes(e.target.value.toLowerCase()) ||
        backup[i].Cognome.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        lavoratoreX.push(backup[i]);
      }
    }
    setScadenze(lavoratoreX);
  }; */

  function filtraTutto(e, tipologia) {
    if (e.target.value === "") {
      var visiteX = [...scadenzeBackup];
      if (!filtro.attivi) {
        visiteX = visiteX.filter(
          (v) =>
            !(
              !v.DataTermine ||
              moment(v.DataTermine).format("YYYY-MM-DD") >
                moment(new Date()).format("YYYY-MM-DD")
            )
        );
      }
      if (!filtro.passivi) {
        visiteX = visiteX.filter(
          (v) =>
            !v.DataTermine ||
            moment(v.DataTermine).format("YYYY-MM-DD") >
              moment(new Date()).format("YYYY-MM-DD")
        );
      }
      setScadenze(visiteX);
      //setScadenze(scadenzeBackup);
    } else {
      var visiteX = []; // popolo l'array
      var i;
      for (i = 0; i < scadenzeBackup.length; i++) {
        if (
          scadenzeBackup[i].Nome.toLowerCase().includes(
            e.target.value.toLowerCase()
          ) ||
          scadenzeBackup[i].Cognome.toLowerCase().includes(
            e.target.value.toLowerCase()
          )
        ) {
          visiteX.push(scadenzeBackup[i]);
        }
      }
      if (!filtro.attivi) {
        visiteX = visiteX.filter(
          (v) =>
            !(
              !v.DataTermine ||
              moment(v.DataTermine).format("YYYY-MM-DD") >
                moment(new Date()).format("YYYY-MM-DD")
            )
        );
      }
      if (!filtro.passivi) {
        visiteX = visiteX.filter(
          (v) =>
            !v.DataTermine ||
            moment(v.DataTermine).format("YYYY-MM-DD") >
              moment(new Date()).format("YYYY-MM-DD")
        );
      }
      setScadenze(visiteX);
      let f = { ...filtroEtichette };
      f.Lavoratore = e.target.value;
      setFiltroEtichette(f);
    }
  }

  const filterClessidera = (e, colore) => {
    let f = { ...filtro };
    f[colore] = e.target.checked;
    setFiltro(f);
    let lavTemp = scadenzeBackup;

    if (!f.attivi) {
      lavTemp = lavTemp.filter(
        (v) =>
          !(
            !v.DataTermine ||
            moment(v.DataTermine).format("YYYY-MM-DD") >
              moment(new Date()).format("YYYY-MM-DD")
          )
      );
    }
    if (!f.passivi) {
      lavTemp = lavTemp.filter(
        (v) =>
          !v.DataTermine ||
          moment(v.DataTermine).format("YYYY-MM-DD") >
            moment(new Date()).format("YYYY-MM-DD")
      );
    }

    if (filtroEtichette.Lavoratore !== "") {
      lavTemp = lavTemp.filter(
        (v) =>
          v.Nome?.toLowerCase().includes(
            filtroEtichette.Lavoratore.toLowerCase()
          ) ||
          v.Cognome?.toLowerCase().includes(
            filtroEtichette.Lavoratore.toLowerCase()
          )
      );
    }

    if (filtroEtichette.Cliente !== "") {
      lavTemp = lavTemp.filter((v) =>
        v.RagioneSociale?.toLowerCase().includes(
          filtroEtichette.Cliente.toLowerCase()
        )
      );
    }

    if (filtroEtichette.Blocco !== "") {
      lavTemp = lavTemp.filter((v) =>
        v.Blocco?.toLowerCase().includes(filtroEtichette.Blocco.toLowerCase())
      );
    }

    if (filtroEtichette.Mansione !== "") {
      lavTemp = lavTemp.filter((v) =>
        v.Descrizione?.toLowerCase().includes(
          filtroEtichette.Mansione.toLowerCase()
        )
      );
    }

    setScadenze(lavTemp);

    /* let visiteTemp = visite.filter(v => v.Esito >=1);

setVisite(visiteTemp); */
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
          <Button
            variant="text"
            size="small"
            startIcon={<InsertDriveFile />}
            onClick={() =>
              document.getElementById("printDocScadenzario").click()
            }
          >
            Scarica Excel
          </Button>
          <FormControlLabel
            sx={{ marginBottom: "0px!important" }}
            control={
              <Checkbox
                checked={filtro.attivi}
                onClick={(e) => filterClessidera(e, "attivi")}
              />
            }
            label="Attivi"
          />
          <FormControlLabel
            sx={{ marginBottom: "0px!important" }}
            control={
              <Checkbox
                checked={filtro.passivi}
                onClick={(e) => filterClessidera(e, "passivi")}
              />
            }
            label="Passivi"
          />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const loadLavoratori = async () => {
    try {
      let responseScadenzario = await unsplash.get("lavoratori", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { scandenziario: "SI" },
      });

      setScadenze(responseScadenzario.data.data);
      setScadenzeBackup(responseScadenzario.data.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      try {
        let responseScadenzario = await unsplash.get("lavoratori", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { lavoratoriSicurezza: "SI" },
        });

        setScadenze(
          responseScadenzario.data.data.filter(
            (v) =>
              !v.DataTermine ||
              moment(v.DataTermine).format("YYYY-MM-DD") >
                moment(new Date()).format("YYYY-MM-DD")
          )
        );
        setScadenzeBackup(responseScadenzario.data.data);

        let responseEsiti = await unsplash.get("esiti", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        setEsiti(responseEsiti.data.data);

        let responseTipologiaVisita = await unsplash.get("tipologiaVisita", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        setTipologiaVisita(responseTipologiaVisita.data.data);

        setLoading(false);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  const handleChangeCorsiSel = (event) => {
    setCorsiSel(event.target.value);
  };

  return (
    <>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <PopUpCorsi lavoratore={lavoratore} />
            <Box
              sx={{
                height: { xs: "75vh", md: "60vh", xl: "75vh" },
                width: 1,
              }}
            >
              <DataGridPro
                sx={{ fontSize: 12 }}
                slots={{
                  loadingOverlay: LinearProgress,
                }}
                loading={loading}
                components={{
                  Toolbar: CustomToolbar,
                  Footer: CustomFooterPersonalized,
                }}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                initialState={{ pinnedColumns: { left: ["Actions"] } }}
                rows={scadenze}
                columns={columns}
                getRowId={(row) => row.ID}
                pageSize={100}
                checkboxSelection
                onSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRowData = scadenze.filter((row) =>
                    selectedIDs.has(row.ID)
                  );
                  setLavoratoriSel(selectedRowData);
                }}
                onCellClick={(params, e) => {
                  if (params.field === "Actions") {
                    e.stopPropagation();
                  }
                }}
              />
            </Box>
            <div style={{ display: "None" }}>
              <ReactHtmlTableToExcel
                id="printDocScadenzario"
                className="download-table-xls-button"
                table="table-to-xls"
                filename={"Log"}
                sheet={"Log"}
                style={{ display: "None!important" }}
                buttonText="Download Log"
              />
            </div>
            <table
              id="table-to-xls"
              style={{ marginTop: 10, display: "None" }}
              cellSpacing={0}
              border={0}
            >
              <tr>
                <th>Scadenza</th>
                <th>Cliente</th>
                <th>Cognome</th>
                <th>Nome</th>
                <th>Blocco</th>
                <th>Distacco</th>
                <th>Medico</th>
                <th>Utente</th>
              </tr>
              {lavoratoriSel?.map((lav, index) => (
                <tr key={lav.ID}>
                  <td>
                    {lav.Scadenza
                      ? moment(lav.Scadenza).format("DD - MM - YYYY")
                      : ""}
                  </td>
                  <td>{lav.RagioneSociale}</td>
                  <td>{lav.Cognome}</td>
                  <td>{lav.Nome}</td>
                  <td>{lav.SedeBlocco}</td>
                  <td>{lav.Distaccato}</td>
                  <td>{lav.NomeMedico}</td>
                  <td>{lav.username}</td>
                </tr>
              ))}
            </table>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default LavoratoriSicurezza;

const PopUpCorsi = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        aria-label="edit"
        id="allegati"
        onClick={handleClickOpen}
        sx={{ display: "None" }}
      >
        Allegati{" "}
      </Button>
      <Dialog
        TransitionComponent={Transition}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={"md"}
        maxWidth={"md"}
      >
        <DialogTitle sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
          Allegati
        </DialogTitle>
        <DialogContent sx={{ marginTop: "30px" }}>
          <Allegati
            idLavoratore={props.lavoratore}
            id={props.lavoratore}
            colonna="Lavoratore"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={() => props.myFunction(props.id)} >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
