import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import unsplash from "../../api/unsplash";

import {
  IconButton,
  Grid,
  Dialog,
  TextField,
  Button,
  Tabs,
  Tab,
  Typography,
  Box,
  Step,
  Stepper,
  StepButton,
  InputAdornment,
} from "@mui/material";
import {
  Download,
  Close,
  Check,
  GroupAdd,
  VpnKey,
  ArrowBack,
  ArrowForward,
} from "@mui/icons-material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import GlobalSnackbar from "../Snackbar";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

const steps = ["Prima Survey", "Seconda Survey"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const SurveyParita = () => {
  const [value, setValue] = useState(0);
  const [valueSur, setValueSur] = useState(0);
  const [primaSurvey, setPrimaSurvey] = useState([]);
  const [secondaSurvey, setSecondaSurvey] = useState([]);

  const navigate = useNavigate();

  const [step, setStep] = useState(0);
  const [handleLocked, setHandleLocked] = useState(false);

  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    setValueSur(0);
  };
  const handleChangeTabSur = (event, newValue) => {
    setValueSur(newValue);
  };

  function splitCategory(domande) {
    // Create a final array to store the grouped objects
    let finalArray = [];

    // Create a map to hold arrays of objects grouped by IDParitaSurvey
    let surveyMap = {};

    // Iterate over each object in the initial array
    domande.forEach((item) => {
      // Get the IDParitaSurvey of the current object
      let surveyId = item["Nome"];
      // If the surveyId is not yet a key in the surveyMap, add it with an empty array as the value
      if (!surveyMap[surveyId]) {
        surveyMap[surveyId] = [];
      }

      // Push the current object into the array corresponding to the surveyId
      surveyMap[surveyId].push(item);
    });

    //RIORDINO I TAB IN BASE AGLI ID DELLA SUVEY
    const sortedData = domande
      .map((category) => {
        return {
          IDParitaSurvey: category?.IDParitaSurvey,
          Nome: category?.Nome,
        };
      })
      .sort((a, b) => a.IDParitaSurvey - b.IDParitaSurvey)
      .map((item) => item.Nome);

    // Define the desired order of categories based on the IDParitaSurvey
    const categoryOrder = [...new Set(sortedData)];

    // Transform the surveyMap into the desired finalArray format and order
    categoryOrder.forEach((category) => {
      if (surveyMap[category]) {
        let obj = {};
        obj[category] = surveyMap[category];
        finalArray.push(obj);
      }
    });

    return finalArray;
  }

  function unsplitCategory(groupedArray) {
    // Create an array to hold the final flattened objects
    let flattenedArray = [];

    // Iterate over each grouped object in the finalArray
    groupedArray.forEach((group) => {
      // Get the key (which is the IDParitaSurvey) and the array of items
      let surveyId = Object.keys(group)[0];
      let items = group[surveyId];

      // Iterate over the items and push them to the flattenedArray
      items.forEach((item) => {
        //carico solo gli oggetti con le risposte
        if (item.Risposta) {
          flattenedArray.push({
            ...item,
            IDCliente: sessionStorage.getItem("Cliente"),
          });
        }
      });
    });

    return flattenedArray;
  }

  const handleChangePrimaSur = (
    e,
    areaIndex,
    domandaIndex,
    nota,
    secondaPercentuale
  ) => {
    const updatedSurveys = [...primaSurvey];
    const areaKey = Object.keys(updatedSurveys[areaIndex])[0];
    if (nota) {
      updatedSurveys[areaIndex][areaKey][domandaIndex].NoteRisposta =
        e.target.value;
    } else {
      if (secondaPercentuale) {
        updatedSurveys[areaIndex][areaKey][domandaIndex].SecondaPercentuale =
          e.target.value;
      } else {
        updatedSurveys[areaIndex][areaKey][domandaIndex].Risposta =
          e.target.value;
      }
    }
    setPrimaSurvey(updatedSurveys);
  };
  const handleChangeSecondaSur = (e, areaIndex, domandaIndex) => {
    const updatedSurveys = [...secondaSurvey];
    const areaKey = Object.keys(updatedSurveys[areaIndex])[0];
    updatedSurveys[areaIndex][areaKey][domandaIndex].Risposta = e.target.value;
    setSecondaSurvey(updatedSurveys);
  };

  const handleSave = async () => {
    const risposte = unsplitCategory(value === 0 ? primaSurvey : secondaSurvey);
    try {
      const response = await unsplash.post(
        "portaleParita",
        {
          operation: "postRisposteSurvey",
          risposte: risposte,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({
        severity: "success",
        messaggio: `Risposte caricate con successo: ${response.data}!`,
      });
      setOpenSnack(true);
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'aggiornamento",
      });
      setOpenSnack(true);
    }
    //ricarico le domande con il format predefinito
    reloadSurveys();
  };

  const reloadSurveys = async () => {
    let response = await unsplash.post(
      "portaleParita",
      {
        operation: "getDomandeSurvey",
        grandezza: sessionStorage.getItem("Grandezza"),
        cliente: sessionStorage.getItem("Cliente"),
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );

    const firstSurSplitted = splitCategory(
      response.data.filter((el) => el.NSurvey === 1)
    );
    setPrimaSurvey(firstSurSplitted);

    let secondSur = response.data.filter((el) => el.NSurvey === 2);
    const secondSurSplitted = splitCategory(secondSur);
    setSecondaSurvey(secondSurSplitted);
  };

  useEffect(() => {
    (async () => {
      let response = await unsplash.post(
        "portaleParita",
        {
          operation: "getDomandeSurvey",
          grandezza: sessionStorage.getItem("Grandezza"),
          cliente: sessionStorage.getItem("Cliente"),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      const firstSurSplitted = splitCategory(
        response.data.filter((el) => el.NSurvey === 1)
      );
      setPrimaSurvey(firstSurSplitted);

      let secondSur = response.data.filter((el) => el.NSurvey === 2);
      const secondSurSplitted = splitCategory(secondSur);
      setSecondaSurvey(secondSurSplitted);

      let stepGetted;
      let responseStep = await unsplash.post(
        "portaleParita",
        {
          operation: "getCliente",
          id: sessionStorage.getItem("Cliente"),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      stepGetted = responseStep.data[0]?.Step;
      setStep(stepGetted);
    })();
  }, []);

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />

      <div className="px-0 lg:px-8 relative h-[100hv]">
        <Tabs
          value={value}
          textColor="inherit"
          variant="scrollable"
          onChange={handleChangeTab}
          aria-label="basic tabs example"
        >
          <Tab label={"Prima Survey"} />
          <Tab label={"Seconda Survey"} />
        </Tabs>
        <div className="relative h-full">
          <TabPanel value={value} index={0}>
            {step < 3 && (
              <div className="absolute inset-0 z-50 w-full h-full bg-white/80 backdrop-blur-0">
                <div className="absolute left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] flex flex-col gap-5">
                  <div className="container">
                    <span
                      onMouseOver={() => setHandleLocked(true)}
                      onMouseOut={() => setHandleLocked(false)}
                      onClick={() => {
                        navigate("/AreaParita/Home");
                      }}
                      className={`lock ${handleLocked ? "unlocked" : ""}`}
                    ></span>
                  </div>
                  <div className="text-xl font-bold text-center">
                    NON DISPONIBILE
                    <br />
                    NECESSARIO COMPLETARE GLI STEP PRECEDENTI:
                    <div
                      className="text-primary-600 cursor-pointer underline"
                      onClick={() => navigate("/AreaParita/Home")}
                    >
                      Vai all'Home Page
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Tabs
              value={valueSur}
              textColor="inherit"
              variant="scrollable"
              onChange={handleChangeTabSur}
              aria-label="basic tabs example"
            >
              {primaSurvey?.map((el) => (
                <Tab label={Object.getOwnPropertyNames(el)} />
              ))}
            </Tabs>
            {primaSurvey?.map((el, areaIndex) => (
              <TabPanel value={valueSur} index={areaIndex} key={areaIndex}>
                {el[Object.getOwnPropertyNames(el)]?.map(
                  (domanda, domandaIndex) => (
                    <div
                      className="w-full flex flex-col py-2"
                      key={domandaIndex}
                    >
                      <div className="w-full font-semibold">
                        {domandaIndex + 1}) {domanda?.Domanda}
                      </div>
                      {domanda?.Nota && (
                        <div className="w-full text-xs italic">
                          {domanda?.Nota.split("\n").map((line, index) => (
                            <p key={index}>{line}</p>
                          ))}
                        </div>
                      )}

                      {domanda?.TipologiaInput === 0 && (
                        <div className="w-full mb-2">
                          <FormControl>
                            <RadioGroup
                              row
                              value={domanda?.Risposta}
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              onChange={(e) =>
                                handleChangePrimaSur(e, areaIndex, domandaIndex)
                              }
                            >
                              <FormControlLabel
                                value={"1"}
                                control={<Radio />}
                                label="Si"
                              />
                              <FormControlLabel
                                value={"0"}
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      )}
                      {domanda?.TipologiaInput === 1 && (
                        <div className="w-1/2 md:w-1/4 mt-3 mb-2">
                          <TextField
                            value={domanda?.Risposta}
                            id={domanda.ID}
                            label="Percentuale"
                            onChange={(e) =>
                              handleChangePrimaSur(e, areaIndex, domandaIndex)
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                              inputProps: { min: 0, max: 100 },
                            }}
                            type="number"
                            fullWidth
                          />
                        </div>
                      )}
                      {domanda?.TipologiaInput === 2 && (
                        <div className="w-full mt-3">
                          <TextField
                            multiline
                            rows={2}
                            value={domanda?.Risposta}
                            id={domanda.ID}
                            label="Risposta"
                            onChange={(e) =>
                              handleChangePrimaSur(e, areaIndex, domandaIndex)
                            }
                            fullWidth
                          />
                        </div>
                      )}
                      {domanda?.TipologiaInput === 3 && (
                        <div className="flex gap-2">
                          <div className="w-1/2 mt-3 mb-2">
                            <TextField
                              value={domanda?.Risposta}
                              id={domanda.ID}
                              label="Percentuale ultimo biennio (2022-2024)"
                              onChange={(e) =>
                                handleChangePrimaSur(e, areaIndex, domandaIndex)
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                                inputProps: { min: 0, max: 100 },
                              }}
                              type="number"
                              fullWidth
                            />
                          </div>
                          <div className="w-1/2 mt-3 mb-2">
                            <TextField
                              value={domanda?.SecondaPercentuale}
                              id={domanda.ID}
                              label="Percentuale attuale"
                              onChange={(e) =>
                                handleChangePrimaSur(
                                  e,
                                  areaIndex,
                                  domandaIndex,
                                  false,
                                  true
                                )
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                                inputProps: { min: 0, max: 100 },
                              }}
                              type="number"
                              fullWidth
                            />
                          </div>
                        </div>
                      )}
                      <div className="w-full">
                        <TextField
                          value={domanda?.NoteRisposta}
                          id={domanda.ID}
                          label="Note"
                          onChange={(e) =>
                            handleChangePrimaSur(
                              e,
                              areaIndex,
                              domandaIndex,
                              true
                            )
                          }
                          fullWidth
                        />
                      </div>
                    </div>
                  )
                )}
              </TabPanel>
            ))}
            <div className="w-full flex justify-end">
              {valueSur === primaSurvey.length - 1 ? (
                <Button
                  variant="contained"
                  className="ButtonMiller"
                  startIcon={<Check />}
                  onClick={handleSave}
                >
                  Salva
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className="ButtonMiller"
                  startIcon={<ArrowForward />}
                  onClick={() => setValueSur((prev) => prev + 1)}
                >
                  Avanti
                </Button>
              )}
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            {step < 5 && (
              <div className="absolute inset-0 z-50 w-full h-full bg-white/80 backdrop-blur-0">
                <div className="absolute left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] flex flex-col gap-5">
                  <div className="container">
                    <span
                      onMouseOver={() => setHandleLocked(true)}
                      onMouseOut={() => setHandleLocked(false)}
                      onClick={() => {
                        navigate("/AreaParita/Home");
                      }}
                      className={`lock ${handleLocked ? "unlocked" : ""}`}
                    ></span>
                  </div>
                  <div className="text-xl font-bold text-center">
                    NON DISPONIBILE
                    <br />
                    NECESSARIO COMPLETARE GLI STEP PRECEDENTI:
                    <div
                      className="text-primary-600 cursor-pointer underline"
                      onClick={() => navigate("/AreaParita/Home")}
                    >
                      Vai all'Home Page
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Tabs
              value={valueSur}
              textColor="inherit"
              variant="scrollable"
              onChange={handleChangeTabSur}
              aria-label="basic tabs example"
            >
              {secondaSurvey?.map((el) => (
                <Tab label={Object.getOwnPropertyNames(el)} />
              ))}
            </Tabs>
            {secondaSurvey?.map((el, areaIndex) => (
              <TabPanel value={valueSur} index={areaIndex} key={areaIndex}>
                {el[Object.getOwnPropertyNames(el)]?.map(
                  (domanda, domandaIndex) => (
                    <div
                      className="w-full flex flex-col py-2"
                      key={domandaIndex}
                    >
                      <div className="w-full font-semibold">
                        {domandaIndex + 1}) {domanda?.Domanda}
                      </div>
                      <div className="w-full mt-2">
                        <TextField
                          multiline
                          rows={2}
                          value={domanda?.Risposta}
                          id={domanda.ID}
                          label="Risposta"
                          onChange={(e) =>
                            handleChangeSecondaSur(e, areaIndex, domandaIndex)
                          }
                          fullWidth
                        />
                      </div>
                    </div>
                  )
                )}
                <div className="w-full flex justify-end">
                  {valueSur === secondaSurvey.length - 1 ? (
                    <Button
                      variant="contained"
                      className="ButtonMiller"
                      startIcon={<Check />}
                      onClick={handleSave}
                    >
                      Salva
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className="ButtonMiller"
                      startIcon={<ArrowForward />}
                      onClick={() => setValueSur((prev) => prev + 1)}
                    >
                      Avanti
                    </Button>
                  )}
                </div>
              </TabPanel>
            ))}
          </TabPanel>
        </div>
      </div>
    </>
  );
};

export default SurveyParita;

export const ModalNewDoc = (props) => {
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [openMessage, setOpenMessage] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confermaEvisuallizza = async () => {
    await unsplash.put(
      "allegati/primaVisualizzazione",
      { id: props.el.ID },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    if (props.el.Criptato === 1) {
      setOpenMessage(true);
    } else {
      props.download();
      props.reloadAll();
      setOpen(false);
    }
  };

  return (
    <>
      <div className="flex flex-row-reverse lg:flex-row">
        <IconButton
          aria-label="edit"
          onClick={handleClickOpen}
          id={"allegatoList"}
        >
          <Download
            style={{ height: "2rem", width: "2rem", color: "#11526f" }}
          />
        </IconButton>
        {props.el?.Criptato === 1 ? (
          <IconButton aria-label="edit">
            <VpnKey style={{ color: "#11526f" }} />
          </IconButton>
        ) : (
          <></>
        )}
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={"md"}
        maxWidth={"md"}
      >
        <div className="py-6 px-6 lg:px-8">
          <h3 className="mb-4 text-xl font-medium text-center text-black">
            {openMessage ? "Inserimento Password" : "Conferma Visualizzazione"}
          </h3>
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="absolute top-4 right-5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="authentication-modal"
          >
            <svg
              aria-hidden="true"
              className="w-7 h-7"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="w-full">
            {openMessage ? (
              <>
                <p>Inserisci la tua password per scaricare questo documento:</p>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="Password"
                    label="Password"
                    type="text"
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
              </>
            ) : (
              <p>
                Confermi di voler visualizzare il seguente documento:{" "}
                <b>{props.el?.Descrizione}</b>?
              </p>
            )}

            <div className="flex justify-between mt-4 ">
              <Button
                className="ButtonMiller"
                variant="contained"
                startIcon={<Close />}
                //className="bg-primary-600 text-white p-2 hover:bg-primary-100 mb-2 transition-all duration-300 ease-in-out"
                onClick={handleClose}
              >
                Annulla
              </Button>
              <Button
                className="ButtonMiller"
                variant="contained"
                startIcon={<Check />}
                //className="bg-primary-600 text-white p-2 hover:bg-primary-100 mb-2 transition-all duration-300 ease-in-out"
                onClick={() => {
                  if (!openMessage) {
                    confermaEvisuallizza();
                  } else {
                    props.downloadCrypt(
                      props.el.ID,
                      props.el.Estensione,
                      props.el.Descrizione,
                      "download",
                      props.el.Criptato,
                      props.el.EstensioneOriginale,
                      password
                    );
                    setOpen(false);
                    setOpenMessage(false);
                    setPassword("");
                  }
                }}
              >
                {openMessage ? "Invia" : "Conferma e visualizza"}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
