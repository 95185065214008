import { Description, Search, VisibilityOff } from "@mui/icons-material";
import React, { useEffect, useState } from "react";

/*
<AllegatiList
  data={arrayOfObjects}//ogni singolo oggetto verrà inserito in una riga
  header={["Giorno", "Visita"]}//nome delle due colonne
  leftCard={["DataFilter"]}//specifico il nome della proprietà di cui il valore verrà inserito nella prima colonna
  rightCard={[//gli oggetti rappresentano le righe della Card di destra
    { title: true, prop: "Lavoratori" },//se viene aggiuntà la proprietà Title verrà messo per primo affiancato dal "titleCard"
    { prop: "Luogo", icon: <Place fontSize="12" /> },//riga classica con icona e nome della proprietà del componente
  ]}
  titleCard="Lavoratori: "//Stringa che viene affiancata al titolo
  component={
    <ModalNewDoc
      reloadAll={reloadAll}
      downloadCrypt={downloadFileCrypt}
      download={downloadFile}
    />
  }//componente a cui viene assegnato la props "el" e la funzione di "download" con i valori della riga corrispondente
  home={true}//nasconde determinate parti del componente per renderlo funzionale all'homepage
/>
*/

export const AllegatiList = ({ data, rightCard, component, home }) => {
  const [list, setList] = useState([]);
  const [listBk, setListBk] = useState([]);

  //Filtro
  const handleFilter = (e) => {
    if (e.target.value === "") return setList([...listBk]);
    //creo array di proprietà interne alle cards
    const propertyToFilter = [...rightCard.map((el) => el.prop)];
    const filtered = listBk.filter((row) => {
      let valuesOfRow = [];
      //scompongo l'oggetto con valori e proprietà
      for (const [key, value] of Object.entries(row)) {
        //controllo che le proprietà siano le stesse interne alle cards
        if (propertyToFilter.includes(key)) {
          if (value?.length !== "" && value !== null) {
            valuesOfRow = [...valuesOfRow, value.toUpperCase()];
          }
        }
      }
      //filtro controllando se l'array contiene i valori
      if (
        valuesOfRow.filter((str) => str.includes(e.target.value.toUpperCase()))
          .length !== 0
      )
        return true;
    });
    setList(filtered);
  };

  //aggiorno tutte le volte che viene aggiornato la props "data"
  useEffect(() => {
    (async () => {
      setList([...data]);
      setListBk([...data]);
    })();
  }, [data]);

  return (
    <>
      {
        //#region Sezione Filtro e Nomi Colonne
      }
      {!home && (
        <div className="w-full text-slate-500">
          <div className="flex items-center gap-4 my-2">
            <div className="relative w-full">
              <Search
                fontSize="large"
                className="absolute inset-y-0 start-0 top-[50%] -translate-y-[50%] flex items-center ps-2 pointer-events-none"
              />
              <input
                onChange={handleFilter}
                type="text"
                name="search"
                id="search"
                placeholder="Filtra"
                className="w-full px-4 py-2 ps-10 pe-10 text-lg text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus-visible:ring-blue-500 focus-visible:border-blue-500 focus:ring-blue-500 focus:border-blue-500"
              />
              <div className="text-gray-500 absolute inset-y-0 end-0 top-[50%] -translate-y-[50%] flex items-center pe-2 pointer-events-none">
                {"(" + list.length + ")"}
              </div>
            </div>
          </div>
        </div>
      )}
      {
        //#endregion
      }
      <>
        {list.length !== 0 ? (
          list.map((row) => {
            //clono il componente assegnando i valori della row al modulo download e assegno tutti i valori della riga alla proprietà el
            const downloadComp = React.Children.map(
              component,
              (child, index) => {
                return React.cloneElement(child, {
                  download: () =>
                    child.props.download(
                      row.ID,
                      row.Estensione,
                      row.Descrizione,
                      "download"
                    ),
                  el: row,
                });
              }
            );
            return (
              <>
                {
                  //#region Sezione Right Card
                }

                <div className="w-full  appear-effect" key={row.ID}>
                  <div className="w-full flex flex-row-reverse justify-between items-center bg-white border border-gray-300 rounded-md py-4 px-2 sm:px-4 my-2  relative overflow-x-auto">
                    {downloadComp}
                    <div className="flex items-center">
                      <div className="hidden sm:block">
                        <Description
                          sx={{ fontSize: { sm: 42, xs: 24 } }}
                          className="mr-4 text-primary-600"
                        />
                      </div>
                      <div>
                        {rightCard?.map((component) => {
                          //proprietà dell'oggetto
                          const property = component.prop;
                          const icon = component.icon;
                          //se è il titolo
                          if (component.title) {
                            return (
                              <>
                                <span className="text-sm sm:text-base font-semibold  ">
                                  {row[property]}
                                </span>
                                <br />
                              </>
                            );
                            //se è una riga classica
                          } else if (
                            row[property] !== "" &&
                            row[property] !== null
                          ) {
                            return (
                              <>
                                <span className="text-slate-400 mr-1">
                                  {icon}
                                </span>
                                <span className="text-xs sm:text-sm">
                                  {row[property]}
                                </span>
                                <br />
                              </>
                            );
                            //documento non ancora visualizzato perché campi vuoti
                          } else {
                            return (
                              <>
                                <span className="text-slate-400 mr-1">
                                  <VisibilityOff fontSize="medium" />
                                </span>
                                <span className="text-xs sm:text-sm">
                                  Non visualizzato
                                </span>
                                <br />
                              </>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                {
                  //#endregion
                }
              </>
            );
          })
        ) : (
          <>
            {
              //#region Sezione Componente Vuoto
            }
            <div className="w-full  appear-effect">
              <div className="w-full flex flex-row-reverse justify-between items-center border border-gray-300 bg-white rounded-md py-4 px-2 sm:px-4 my-2  relative overflow-x-auto">
                <div className="w-full flex items-center">
                  <div className="hidden sm:block">
                    <Description
                      sx={{ fontSize: { sm: 42, xs: 24 } }}
                      className="mr-4 text-primary-600"
                    />
                  </div>
                  <div>
                    <span className="text-sm sm:text-base font-semibold  ">
                      Nessun Allegato Presente
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {
              //#endregion
            }
          </>
        )}
      </>
    </>
  );
};
