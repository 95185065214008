import React, { useEffect, useState, useRef } from "react";
import unsplash from "../api/unsplash";
import { useNavigate } from "react-router-dom";

import {
  Box,
  IconButton,
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  DialogActions,
  Card,
  Grid,
  CardContent,
  Zoom,
  Autocomplete,
  LinearProgress,
  ButtonGroup,
  MenuItem,
} from "@mui/material";

import { Add, Delete, Edit, Close, Check } from "@mui/icons-material";

import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
  GridPagination,
  GridFooterContainer,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";

import GlobalSnackbar from "./Snackbar";
import Grow from "@mui/material/Grow";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Excel } from "../utils/Excel";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const Adempimenti = () => {
  const [adempimenti, setAdempimenti] = useState([]);
  const [categorieAdempimenti, setCategorieAdempimenti] = useState([]);
  const [adempimentiVisibili, setAdempimentiVisibili] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{ columnGap: 2, margin: { xs: 2, md: 1 } }}
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      width: 100,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <AdempimentiPopUp
            myFunction={handleSaveData}
            title="Modifica Adempimento"
            adempimenti={adempimenti}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
            operation="Edit"
            adempimentoSel={params.row}
            categorieAdempimenti={categorieAdempimenti}
          />
          <DeleteDialog adempimento={params.row} myFunction={handleDelete} />
        </>
      ),
    },
    {
      field: "Descrizione",
      headerName: "Descrizione",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Cadenza",
      headerName: "Cadenza",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
  ];

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleChange = (e) => {
    // filtro

    if (e.target.value == "") {
      setAdempimentiVisibili(adempimenti);
    } else {
      var adempimentiX = []; // popolo l'array
      var i;
      for (i = 0; i < adempimenti.length; i++) {
        if (
          adempimenti[i]?.Descrizione.toLowerCase().includes(
            e.target.value.toLowerCase()
          )
        ) {
          adempimentiX.push(adempimenti[i]);
        }
      }
      setAdempimentiVisibili(adempimentiX);
    }
  };

  const handleSaveData = async (adempimento, operazione) => {
    try {
      //AGGIUNGERE LA CREAZIONE DELLA SEDE

      if (operazione === "new") {
        let responseAd = await unsplash.post(
          "adempimenti",
          { adempimento: adempimento },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Adempimento creato con successo",
        });
        setOpenSnack(true);
      } else {
        let responseAd = await unsplash.put(
          "adempimenti",
          { adempimento: adempimento },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Adempimento modificato con successo",
        });
        setOpenSnack(true);
      }
      let response = await unsplash.get("adempimenti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      setAdempimenti(response.data.data);
      setAdempimentiVisibili(response.data.data);

      /*medico.new = true;
    medico.ID = responseMedico.data.message.insertId;
    let m = [...medici];
    m.unshift(medico); //aggiungo in prima posizione
    setMedici(m);
    setMediciVisibili(m);
    */
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante la creazione dell'Adempimento",
      });
      setOpenSnack(true);
    }
  };

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      try {
        let response = await unsplash.get("adempimenti", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        setAdempimenti(response.data.data);
        setAdempimentiVisibili(response.data.data);
        setLoading(false);

        let responseCategorieAdempimenti = await unsplash.get("adempimenti", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { categoria: true },
        });

        setCategorieAdempimenti(responseCategorieAdempimenti.data.data);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (ID) => {
    try {
      await unsplash.delete("adempimenti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: ID,
        },
      });

      let response = await unsplash.get("adempimenti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      setAdempimenti(response.data.data);
      setAdempimentiVisibili(response.data.data);

      setSnack({
        severity: "success",
        messaggio: "Adempimento eliminato con successo",
      });
      setOpenSnack(true);
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante la cancellazione",
      });
      setOpenSnack(true);
    }
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid item xs={12}>
        <Card sx={{ boxShadow: "none" }}>
          <CardContent>
            <div>
              <Grid
                container
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="flex-end"
              >
                <Grid item xs={12} md={4}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <AdempimentiPopUp
                      myFunction={handleSaveData}
                      title="Crea nuovo Adempimento"
                      adempimenti={adempimenti}
                      setSnack={setSnack}
                      setOpenSnack={setOpenSnack}
                      operation="new"
                      categorieAdempimenti={categorieAdempimenti}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Box
                sx={{
                  height: { xs: "75vh", md: "60vh", xl: "75vh" },
                  width: 1,
                }}
              >
                <DataGridPro
                  initialState={{ pinnedColumns: { left: ["Actions"] } }}
                  sx={{ fontSize: 12 }}
                  components={{
                    Toolbar: CustomToolbar,
                    Footer: CustomFooterPersonalized,
                  }}
                  slots={{
                    loadingOverlay: LinearProgress,
                  }}
                  loading={loading}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  rows={adempimentiVisibili}
                  columns={columns}
                  getRowId={(row) => row.ID}
                  //pageSize={100}
                  disableSelectionOnClick
                />
              </Box>
            </div>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

const AdempimentiPopUp = (props) => {
  const [open, setOpen] = useState(false);
  const [adempimento, setAdempimento] = useState();
  const [categoriaSel, setCategoriaSel] = useState(null);
  const [validation, setValidation] = useState();

  const handleClickOpen = () => {
    setValidation({ Descrizione: false });
    if (props.operation === "new") {
      setAdempimento({
        Descrizione: "",
        Cadenza: "",
        IstruzioniCompilazione: "",
        ParagrafoArticolo: "",
        RiferimentoNormativo: "",
        CategoriaAdempimento: false,
      });
      setCategoriaSel(null);
    } else {
      setAdempimento(props.adempimentoSel);

      setCategoriaSel(
        props.categorieAdempimenti?.filter(
          (c) => c.ID === props.adempimentoSel.CategoriaAdempimento
        )[0]
      );
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    let a = { ...adempimento };
    a[e.target.name] = e.target.value;
    setAdempimento(a);
  };

  const handleSave = async () => {
    props.myFunction(adempimento, props.operation);
  };

  return (
    <div>
      {props.operation === "Edit" ? (
        <IconButton aria-label="edit" onClick={handleClickOpen} sx={{ p: 0 }}>
          <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
        </IconButton>
      ) : (
        <Button
          startIcon={<Add />}
          onClick={handleClickOpen}
          id={props.id}
          sx={{ color: "white" }}
          variant="outlined"
          className="ButtonMiller"
        >
          Aggiungi
        </Button>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="md"
        TransitionComponent={Transition}
      >
        <DialogTitle sx={{ mx: "auto" }}>{props.title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                error={validation?.Descrizione}
                margin="dense"
                name="Descrizione"
                label="Descrizione"
                type="text"
                fullWidth
                value={adempimento?.Descrizione}
                /*InputProps={{
                  readOnly: props.operation === "Edit",
                }}*/
                onChange={handleChange}
                variant="outlined"
                helperText={validation?.Descrizione ? "Campo obbligatorio" : ""}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                name="Cadenza"
                label="Cadenza"
                type="number"
                fullWidth
                value={adempimento?.Cadenza}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                name="RiferimentoNormativo"
                label="Riferimento Normativo"
                type="text"
                fullWidth
                value={adempimento?.RiferimentoNormativo}
                /*InputProps={{
                  readOnly: props.operation === "Edit",
                }}*/
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                name="ParagrafoArticolo"
                label="Paragrafo Articolo"
                type="text"
                fullWidth
                value={adempimento?.ParagrafoArticolo}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Autocomplete
                id="combo-box-demo"
                options={props.categorieAdempimenti}
                fullWidth
                value={categoriaSel}
                getOptionLabel={(option) => option.Descrizione}
                onChange={(event, newValue) => {
                  setCategoriaSel(newValue);
                  if (newValue) {
                    let a = { ...adempimento };
                    a.CategoriaAdempimento = newValue.ID;
                    setAdempimento(a);
                  } else {
                    let a = { ...adempimento };
                    a.CategoriaAdempimento = null;
                    setAdempimento(a);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Categoria" />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                multiline
                margin="dense"
                name="IstruzioniCompilazione"
                label="Istruzioni Compilazione"
                type="number"
                fullWidth
                value={adempimento?.IstruzioniCompilazione}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={() => {
              handleSave();
              handleClose();
            }}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Adempimenti;

const DeleteDialog = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare l'adempimento selezionato?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare il seguente adempimento :
            <b>{props.adempimento.Descrizione}</b>
            
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={() => props.myFunction(props.adempimento.ID)}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
