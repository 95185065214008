import React, { useEffect, useState, useMemo } from "react";
import unsplash from "../api/unsplash";
import { useLocation, useNavigate } from "react-router-dom";
import {
  LinearProgress,
  Checkbox,
  Grid,
  TextField,
  Box,
  Card,
  CardContent,
} from "@mui/material";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import {
  Error,
  HourglassBottom,
  HourglassDisabled,
  ErrorOutline,
} from "@mui/icons-material";
import { LocalizationProvider,DatePicker,PickersDay,TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment as DateAdapter} from '@mui/x-date-pickers/AdapterMoment'
import moment from "moment-timezone";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const columns = [
  {
    filterable: false,
    headerName: "Azioni",
    field: "clessidera",
    width: 50,
    headerAlign: "center",
    align: "center",
    sort: "asc",

    renderCell: (params) => {
      return (
        <div>
          {
            /*1*/ moment(params.row.Data).format("YYYY-MM-DD") <
              moment(new Date()).format("YYYY-MM-DD") &&
            params.row.Esito <= 1 ? (
              <HourglassBottom sx={{ color: "#FF2768" }} />
            ) : params.row.Esito > 1 ? (
              <HourglassBottom sx={{ color: "#40C18F" }} />
            ) : (
              <HourglassBottom sx={{ color: "#FFC897" }} />
            )
          }
        </div>
      );
    },
  },
  {
    filterable: false,
    headerName: "**",
    field: "LimitazioniPassate",
    width: 50,
    headerAlign: "center",
    align: "center",
    sort: "asc",

    renderCell: (params) => {
      if (params.row.Esito === 3 || params.row.Esito === 4) {
        return (
          <div>
            <Error sx={{ color: "#FFC897" }} />
          </div>
        );
      } else {
        return "";
      }
    },
  },
  {
    filterable: false,
    headerName: "Idoneità",
    field: "Idoneita",
    width: 70,
    headerAlign: "center",
    align: "center",
    sort: "asc",

    renderCell: (params) => {
      if (params.row.Idoneita === 1) {
        return (
          <div>
            <input type="checkbox" checked></input>
          </div>
        );
      } else {
        return (
          <div>
            <input type="checkbox" disabled></input>
          </div>
        );
      }
    },

    /*renderCell: (params) =>{

        if(params.row.Idoneita === 1){
            return <div><Error sx={{color:"#FFC897"}} /></div>
        }else{
            return ""
        }
        
    }*/
  },
  {
    field: "Cliente",
    headerName: "Cliente",
    width: 200,

    headerAlign: "center",
    align: "center",
    sort: "asc",
  },
  {
    field: "ClienteAttuale",
    headerName: "Cliente Attuale",
    width: 200,
    //flex:1,
    headerAlign: "center",
    align: "center",
    sort: "asc",
  },
  {
    field: "LavoratoreCognome",
    headerName: "Cognome",
    width: 200,
    //flex:1,
    headerAlign: "center",
    align: "center",
    sort: "asc",
  },
  {
    field: "LavoratoreNome",
    headerName: "Nome",
    width: 200,
    //flex:1,
    headerAlign: "center",
    align: "center",
    sort: "asc",
  },
  {
    field: "Medico",
    headerName: "Medico",
    width: 200,
    //flex:1,
    headerAlign: "center",
    align: "center",
    sort: "asc",
  },
  {
    field: "Mansione",
    headerName: "Mansione",
    width: 200,
    //flex:1,
    headerAlign: "center",
    align: "center",
    sort: "asc",
  },
  {
    field: "Accertamenti",
    headerName: "Accertamenti",
    width: 200,
    //flex:1,
    headerAlign: "center",
    align: "center",
    sort: "asc",
  },
  {
    field: "EsitoDesc",
    headerName: "Esito",
    width: 200,
    //flex:1,
    headerAlign: "center",
    align: "center",
    sort: "asc",
  },
  {
    field: "TipoVDesc",
    headerName: "Tipo",
    width: 200,
    //flex:1,
    headerAlign: "center",
    align: "center",
    sort: "asc",
  },
  {
    field: "Data",
    headerName: "Data",
    width: 200,
    //flex:1,
    headerAlign: "center",
    align: "center",
    sort: "asc",
    renderCell: (params) => {
      return (
        <div>
          {params.row.Data
            ? moment(params.row.Data).format("DD/MM/YYYY HH:mm:SS")
            : ""}
        </div>
      );
    },
  },
  {
    field: "Scadenza",
    headerName: "Scadenza",
    width: 200,
    //flex:1,
    headerAlign: "center",
    align: "center",
    sort: "asc",
    renderCell: (params) => {
      return (
        <div>
          {params.row.Scadenza
            ? moment(params.row.Scadenza).format("DD/MM/YYYY")
            : ""}
        </div>
      );
    },
  },
  {
    field: "CF",
    headerName: "CF",
    width: 200,
    //flex:1,
    headerAlign: "center",
    align: "center",
    sort: "asc",
  },
  {
    field: "UnitaProduttivaAllegato3B",
    headerName: "Unità Produttiva",
    width: 200,
    //flex:1,
    headerAlign: "center",
    align: "center",
    sort: "asc",
  },
];

const VIsiteMedicheAssociaVisita = (props) => {
  const [visite, setVisite] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visiteBackup, setVisiteBackup] = useState([]);

  const [dal, setDal] = useState(
    moment(new Date()).subtract(6, "months").format("YYYY-MM-DD")
  );
  const [al, setAl] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [filtroClessidera, setFiltroClessidera] = useState({
    verde: true,
    arancione: true,
    rosso: true,
    alert: true,
  });
  const [filtroEtichette, setFiltroEtichette] = useState({
    Medico: "",
    Lavoratore: "",
    Mansione: "",
    Accertamenti: "",
  });

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      try {
        let response = await unsplash.get("visiteMediche", {
          params: {
            dal: moment(new Date()).subtract(6, "months").format("YYYY-MM-DD"),
            al: moment(new Date()).format("YYYY-MM-DD"),
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        setVisite(response.data.data);
        setLoading(false);
        setVisiteBackup(response.data.data);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  const loadVisite = async (dal, al) => {
    setLoading(true);
    if (dal && al) {
      let response = await unsplash.get("visiteMediche", {
        params: { dal: dal, al: al },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setVisite(response.data.data);
      setVisiteBackup(response.data.data);
      setLoading(false);
    }
  };

  const filterClessidera = (e, colore) => {
    let f = { ...filtroClessidera };
    f[colore] = e.target.checked;
    setFiltroClessidera(f);
    let visiteTemp = visiteBackup;
    if (!f.rosso) {
      visiteTemp = visiteTemp.filter(
        (v) =>
          v.Esito >= 1 ||
          moment(v.Data).format("YYYY-MM-DD") >
            moment(new Date()).format("YYYY-MM-DD")
      );
    }

    if (!f.verde) {
      visiteTemp = visiteTemp.filter((v) => v.Esito < 1);
    }

    if (!f.arancione) {
      visiteTemp = visiteTemp.filter(
        (v) =>
          moment(v.Data).format("YYYY-MM-DD") <
          moment(new Date()).format("YYYY-MM-DD")
      );
    }

    if (!f.alert) {
      visiteTemp = visiteTemp.filter((v) => v.Esito !== 3 && v.Esito !== 4);
    }

    setVisite(visiteTemp);

    /* let visiteTemp = visite.filter(v => v.Esito >=1);

setVisite(visiteTemp); */
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
          <Checkbox
            icon={<HourglassDisabled />}
            checked={filtroClessidera.rosso}
            checkedIcon={<HourglassBottom />}
            sx={{ color: "#FF2768!important" }}
            onClick={(e) => filterClessidera(e, "rosso")}
          />
          <Checkbox
            icon={<HourglassDisabled />}
            checked={filtroClessidera.arancione}
            checkedIcon={<HourglassBottom />}
            sx={{ color: "#FFC897!important" }}
            onClick={(e) => filterClessidera(e, "arancione")}
          />
          <Checkbox
            icon={<HourglassDisabled />}
            checked={filtroClessidera.verde}
            checkedIcon={<HourglassBottom />}
            sx={{ color: "#40C18F!important" }}
            onClick={(e) => filterClessidera(e, "verde")}
          />
          <Checkbox
            icon={<ErrorOutline />}
            checked={filtroClessidera.alert}
            checkedIcon={<Error />}
            sx={{ color: "#FFC897!important" }}
            onClick={(e) => filterClessidera(e, "alert")}
          />
        </Grid>
      </GridToolbarContainer>
    );
  }

  function filtraTutto(e, tipologia) {
    switch (tipologia) {
      case "Medico":
        if (
          e.target.value == "" &&
          filtroEtichette.Lavoratore == "" &&
          filtroEtichette.Mansione == "" &&
          filtroEtichette.Accertamenti == ""
        ) {
          setVisite(visiteBackup);
        } else {
          var visiteX = []; // popolo l'array
          var i;
          for (i = 0; i < visite.length; i++) {
            if (
              visite[i].Medico.toLowerCase().includes(
                e.target.value.toLowerCase()
              )
            ) {
              visiteX.push(visite[i]);
            }
          }
          setVisite(visiteX);
          let f = { ...filtroEtichette };
          f.Medico = e.target.value;
          setFiltroEtichette(f);
        }
        break;

      case "Lavoratore":
        if (
          e.target.value == "" &&
          filtroEtichette.Medico == "" &&
          filtroEtichette.Mansione == "" &&
          filtroEtichette.Accertamenti == ""
        ) {
          setVisite(visiteBackup);
        } else {
          var visiteX = []; // popolo l'array
          var i;
          for (i = 0; i < visite.length; i++) {
            if (
              visite[i].LavoratoreNome.toLowerCase().includes(
                e.target.value.toLowerCase()
              ) ||
              visite[i].LavoratoreCognome.toLowerCase().includes(
                e.target.value.toLowerCase()
              )
            ) {
              visiteX.push(visite[i]);
            }
          }
          setVisite(visiteX);
          let f = { ...filtroEtichette };
          f.Lavoratore = e.target.value;
          setFiltroEtichette(f);
        }
        break;
      case "Mansione":
        if (
          e.target.value == "" &&
          filtroEtichette.Lavoratore == "" &&
          filtroEtichette.Medico == "" &&
          filtroEtichette.Accertamenti == ""
        ) {
          setVisite(visiteBackup);
        } else {
          var visiteX = []; // popolo l'array
          var i;
          for (i = 0; i < visite.length; i++) {
            if (
              visite[i].Mansione.toLowerCase().includes(
                e.target.value.toLowerCase()
              )
            ) {
              visiteX.push(visite[i]);
            }
          }
          setVisite(visiteX);
          let f = { ...filtroEtichette };
          f.Mansione = e.target.value;
          setFiltroEtichette(f);
        }
        break;
      case "Accertamenti":
        if (
          e.target.value == "" &&
          filtroEtichette.Lavoratore == "" &&
          filtroEtichette.Mansione == "" &&
          filtroEtichette.Medico == ""
        ) {
          setVisite(visiteBackup);
        } else {
          var visiteX = []; // popolo l'array
          var i;
          for (i = 0; i < visite.length; i++) {
            if (
              visite[i].Accertamenti.toLowerCase().includes(
                e.target.value.toLowerCase()
              )
            ) {
              visiteX.push(visite[i]);
            }
          }
          setVisite(visiteX);
          let f = { ...filtroEtichette };
          f.Accertamenti = e.target.value;
          setFiltroEtichette(f);
        }
        break;
    }
  }

  return (
    <Grid item xs={12}>
      <div style={{ display: "None" }}>
        <ReactHTMLTableToExcel
          id="printDocVisite"
          className="download-table-xls-button"
          table="table-to-xls"
          filename={"Log"}
          sheet={"Log"}
          style={{ display: "None!important" }}
          buttonText="Download Log"
        />
      </div>
      <table
        id="table-to-xls"
        style={{ marginTop: 10, display: "None" }}
        cellSpacing={0}
        border={0}
      >
        <tr>
          <th>Idoneità</th>
          <th>Cliente</th>
          <th>Cliente Attuale</th>
          <th>Cognome</th>
          <th>Nome</th>
          <th>Medico</th>
          <th>Mansione</th>
          <th>Accertamenti</th>
          <th>Esito</th>
          <th>Tipologia visita</th> <th>Data</th>
          <th>Scadenza</th>
          <th>CF</th>
          <th>Unità Produttiva</th>
          <th>Note</th>
        </tr>
        {visite?.map((visita, index) => (
          <tr key={visita.ID}>
            <td>{visita.Idoneita === 0 ? "NO" : "SI"}</td>
            <td>{visita.Cliente}</td>
            <td>{visita.ClienteAttuale}</td>
            <td>{visita.LavoratoreCognome}</td>
            <td>{visita.LavoratoreNome}</td>
            <td>{visita.Medico}</td>
            <td>{visita.Mansione}</td>
            <td>{visita.Accertamenti}</td>
            <td>{visita.Esito}</td>
            <td>{visita.TipoVDesc}</td>
            <td>{visita.Data}</td>
            <td>{visita.Scadenza}</td>
            <td>{visita.CF}</td>
            <td>{visita.UnitaProduttivaAllegato3B}</td>
            <td>{visita.Note}</td>
          </tr>
        ))}
      </table>

      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  ampmInClock={false}
                  renderInput={(props) => (
                    <TextField size="small" fullWidth {...props} />
                  )}
                  label="Dal"
                  value={moment(dal)}
                  onChange={(newValue) => {
                    setDal(newValue ? newValue.format("YYYY-MM-DD") : null);
                    loadVisite(newValue.format("YYYY-MM-DD"), al);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  ampmInClock={false}
                  renderInput={(props) => (
                    <TextField size="small" fullWidth {...props} />
                  )}
                  label="Al"
                  value={moment(al)}
                  onChange={(newValue) => {
                    setAl(newValue ? newValue.format("YYYY-MM-DD") : null);
                    loadVisite(dal, newValue.format("YYYY-MM-DD"));
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                label="Lavoratore"
                fullWidth
                onChange={(e) => {
                  filtraTutto(e, "Lavoratore");
                  let f = { ...filtroEtichette };
                  f.Lavoratore = e.target.value;
                  setFiltroEtichette(f);
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                label="Medico"
                sx={{ marginRight: "10px" }}
                fullWidth
                onChange={(e) => {
                  filtraTutto(e, "Medico");
                }}
              />
            </Grid>

            <Grid item xs={2}>
              <TextField
                size="small"
                label="Mansione"
                fullWidth
                onChange={(e) => {
                  filtraTutto(e, "Mansione");
                  let f = { ...filtroEtichette };
                  f.Mansione = e.target.value;
                  setFiltroEtichette(f);
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                label="Accertamenti"
                fullWidth
                onChange={(e) => {
                  filtraTutto(e, "Accertamenti");
                  let f = { ...filtroEtichette };
                  f.Accertamenti = e.target.value;
                  setFiltroEtichette(f);
                }}
              />
            </Grid>
          </Grid>

          <Box sx={{ height: 700, width: 1 }}>
            <DataGridPro
              initialState={{ pinnedColumns: { left: ["Actions"] } }}
              sx={{
                ".MuiDataGrid-columnHeaderCheckbox ": { display: "none" },
                ".MuiDataGrid-cellCheckbox": { display: "none" },
                fontSize: 12,
              }}
              components={{
                Toolbar: CustomToolbar,
                Footer: CustomFooterPersonalized,
              }}
              slots={{
                loadingOverlay: LinearProgress,
              }}
              loading={loading}
              localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
              rows={visite}
              columns={columns}
              getRowId={(row) => row.ID}
              pageSize={100}
              onRowClick={async (params, event) => {
                props.setVisitaSel(params.row);
              }}
            />
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default VIsiteMedicheAssociaVisita;
