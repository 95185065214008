import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  Button,
  Typography,
  Grid,
  TextField,
  Card,
  CardContent,
  CircularProgress,
  Paper,
  Backdrop,
  Stack,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import unsplash from "../api/unsplash";
import { LocalizationProvider,DatePicker,PickersDay,TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment as DateAdapter} from '@mui/x-date-pickers/AdapterMoment'
import moment from "moment";
import momentTimezone from "moment-timezone";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GlobalSnackbar from "./Snackbar";
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import { Output, Print, WindowSharp } from "@mui/icons-material";

const PrefatturazioneCorsi = () => {
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const [clienti, setClienti] = useState([]);
  const [clienteSel, setClienteSel] = useState(null);
  const [date, setDate] = useState(new Date());
  const [corsi, setCorsi] = useState([]);
  const [open, setOpen] = useState(false);
  const [totale, setTotale] = useState(0);
  const [storicoFatturazioneCorsi, setStoricoFatturazioneCorsi] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      try {
        let response = await unsplash.get("clienti", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        setClienti(response.data.data);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  const generateFatturazione = async () => {
    // storicoFatturazioneCorsi.filter(s => s.PeriodoFatturazione === )
    let mesi = {
      1: "GENNAIO",
      2: "FEBBRAIO",
      3: "MARZO",
      4: "APRILE",
      5: "MAGGIO",
      6: "GIUGNO",
      7: "LUGLIO",
      8: "AGOSTO",
      9: "SETTEMBRE",
      10: "OTTOBRE",
      11: "NOVEMBRE",
      12: "DICEMBRE",
    };
    let meseSel = mesi[moment(date).format("M")];
    let annoSel = moment(date).format("YYYY");

    let check = storicoFatturazioneCorsi.filter(
      (s) => s.PeriodoFatturazione === meseSel && s.AnnoFatturazione === annoSel
    );
    if (check.length > 0) {
      setSnack({
        severity: "warning",
        messaggio:
          "Attenzione hai già eseguito la fatturazione per questo periodo",
      });
      setOpenSnack(true);

      return;
    }
    setOpen(true);

    let idCliente = clienteSel.ID;
    let dataControllo = moment(date).endOf("month").format("YYYY-MM-DD");

    try {
      let response = await unsplash.get("fatturazioneCorsi", {
        params: { cliente: idCliente, data: dataControllo },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      //setCorsi(response.data.data);

      calcoloPrefatturazione(response.data.data);
      setOpen(false);
    } catch {}
  };

  const calcoloPrefatturazione = (costiCorsi) => {
    //funzione per calcolare tutti i totali

    let totale = 0;

    for (let i = 0; i < costiCorsi.length; i++) {
      let totaleCorsoTipo = 0;

      for (let j = 0; j < costiCorsi[i].corsi.length; j++) {
        let totaleCorsoSelezionato = 0;
        let mostraInStampa = 0;
        if (costiCorsi[i].corsi[j].ModalitaFatturazione === 1) {
          //tariffa a lavoratore

          for (let k = 0; k < costiCorsi[i].corsi[j].Presenti.length; k++) {
            if (costiCorsi[i].corsi[j].Presenti[k].EraPresente === 1) {
              totaleCorsoSelezionato += costiCorsi[i].corsi[j].Tariffa;
              mostraInStampa = 1;
            }
          }
        } else {
          // FORFAIT
          mostraInStampa = 1;
          totaleCorsoSelezionato +=
            costiCorsi[i].corsi[j].OreTotaliCorso *
            costiCorsi[i].corsi[j].CostoForfait;
        }

        costiCorsi[i].corsi[j].totaleCorso = totaleCorsoSelezionato;
        totaleCorsoTipo += totaleCorsoSelezionato;
        costiCorsi[i].corsi[j].MostraInStampa = mostraInStampa;
      } //chiudo il singolo

      costiCorsi[i].totaleTipoCorso = totaleCorsoTipo;
      totale += totaleCorsoTipo;
    }
    costiCorsi.Totale = totale;
    setCorsi(costiCorsi);
  };

  const setPresenza = async (
    indexTipoCorso,
    indexCorsoSpecifico,
    indexLavoratore,
    presenza
  ) => {
    let c = [...corsi];

    c[indexTipoCorso].corsi[indexCorsoSpecifico].Presenti[
      indexLavoratore
    ].EraPresente = presenza === 1 ? 0 : 1; //se è presento switcho e viceversa

    let corsoLavoratore = {
      Presenza: presenza === 1 ? 0 : 1,
      Lavoratore:
        c[indexTipoCorso].corsi[indexCorsoSpecifico].Presenti[indexLavoratore]
          .Lavoratore,
      Corso:
        c[indexTipoCorso].corsi[indexCorsoSpecifico].Presenti[indexLavoratore]
          .Corso,
    };
    await unsplash.put(
      "fatturazioneCorsi",
      { corsoLavoratore: corsoLavoratore }, //Creo corso header STEP 1
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );

    calcoloPrefatturazione(c);
  };
  const rimuoviCorso = (indexTipoCorso, indexCorsoSpecifico) => {
    let c = [...corsi];

    c[indexTipoCorso].corsi.splice(indexCorsoSpecifico, 1);
    calcoloPrefatturazione(c);
  };

  const stampaReport = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    var img = new Image();
    img.src = require("../assets/images/MILLERLogo-copia.jpg");

    let mesi = {
      1: "GENNAIO",
      2: "FEBBRAIO",
      3: "MARZO",
      4: "APRILE",
      5: "MAGGIO",
      6: "GIUGNO",
      7: "LUGLIO",
      8: "AGOSTO",
      9: "SETTEMBRE",
      10: "OTTOBRE",
      11: "NOVEMBRE",
      12: "DICEMBRE",
    };

    doc.setFillColor(34, 100, 174);
    doc.rect(0, 0, doc.internal.pageSize.getWidth(), 90, "F");

    doc.addImage(img, "PNG", 10, 10, 220, 70);
    doc.setTextColor(255, 255, 255);

    doc.setFont(undefined, "bold");
    doc.setFontSize(13);
    doc.text(
      "FATTURAZIONE CORSI : " +
        mesi[moment(date).format("M")] +
        " " +
        moment(date).format("YYYY"),
      255,
      30
    );
    doc.text(
      doc.splitTextToSize(
        "CLIENTE : " + clienteSel.RagioneSociale.toUpperCase(),
        doc.internal.pageSize.getWidth() - 255
      ),
      255,
      50
    );
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(13);
    doc.text("ELENCO CORSI ", 250, 120);
    let content = {
      startY: 180,
      startX: 0,
      tableWidth: "auto",
      columnWidth: "auto",
      headStyles: { fillColor: [34, 100, 174] },
      footStyles: { fillColor: [34, 100, 174] },
      margin: { left: 0, right: 0 },
      // foot: [["", "", "", "Totale", Math.round(totale * 1e12) / 1e12]],
      showFoot: "lastPage",
    };

    for (let i = 0; i < corsi.length; i++) {
      let finalY = 120;

      if (doc.lastAutoTable.finalY) {
        finalY = doc.lastAutoTable.finalY;
      }

      doc.autoTable({
        useCss: true,
        startY: 20 + finalY,
        html: "#mytable" + i,
        headStyles: { fillColor: [124, 95, 240] },
      });
    }

    let finalY = 120;

    if (doc.lastAutoTable.finalY) {
      finalY = doc.lastAutoTable.finalY;
    }

    doc.autoTable({
      useCss: true,
      startY: 20 + finalY,
      html: "#footerTable",
      headStyles: { fillColor: [124, 95, 240] },
    });

    doc.output("pdfobjectnewwindow", "prefatturazione");
  };

  const confermaFatturazione = async () => {
    if (
      window.confirm("ATTENZIONE SEI SICURO DI VOLER GENERARE LA FATTURAZIONE?")
    ) {
      let arrayCorsi = [];
      let arrayLavoratori = [];
      let mesi = {
        1: "GENNAIO",
        2: "FEBBRAIO",
        3: "MARZO",
        4: "APRILE",
        5: "MAGGIO",
        6: "GIUGNO",
        7: "LUGLIO",
        8: "AGOSTO",
        9: "SETTEMBRE",
        10: "OTTOBRE",
        11: "NOVEMBRE",
        12: "DICEMBRE",
      };

      for (let i = 0; i < corsi.length; i++) {
        for (let j = 0; j < corsi[i].corsi.length; j++) {
          // stepp corsi

          if (corsi[i].corsi[j].MostraInStampa === 1) {
            // lo considero se c'è qualcosa così,qualcosa da fatturare e prendo l'id del corso
            if (arrayCorsi.indexOf(corsi[i].corsi[j].IDCorso) < 0) {
              arrayCorsi.push(corsi[i].corsi[j].IDCorso);
            }
            for (let k = 0; k < corsi[i].corsi[j].Presenti.length; k++) {
              //prendo gli id dei lavoratori
              if (corsi[i].corsi[j].Presenti[k].EraPresente === 1) {
                if (
                  arrayLavoratori.indexOf(
                    corsi[i].corsi[j].Presenti[k].Lavoratore
                  ) < 0
                ) {
                  arrayLavoratori.push(
                    corsi[i].corsi[j].Presenti[k].Lavoratore
                  );
                }
              }
            }
          }
        }
      }

      try {
        let response = await unsplash.post(
          "storicoFatturazioneCorsi",
          {
            cliente: clienteSel.ID,
            periodo: mesi[moment(date).format("M")],
            anno: moment(date).format("YYYY"),
          }, //Creo corso header STEP 1
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        let idStoricoPrefatturazione = response.data.message.insertId;

        await unsplash.put(
          "fatturazioneCorsi",
          {
            corsi: arrayCorsi,
            lavoratori: arrayLavoratori,
            idStoricoPrefatturazione: idStoricoPrefatturazione,
          }, //Creo corso header STEP 1
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        stampaReport();
        setSnack({
          severity: "success",
          messaggio: "Report stampato con successo",
        });
        setOpenSnack(true);
      } catch {
        setSnack({ severity: "error", messaggio: "Errore durante la stampa" });
        setOpenSnack(true);
      }
    }
  };

  const loadFatturazioneCorsi = async (ID) => {
    let responseFatturazione = await unsplash.get(
      "storicoFatturazioneCorsi",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { cliente: ID },
      } //props.idAzienda}}
    );

    setStoricoFatturazioneCorsi(responseFatturazione.data.data);
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <Stack>
          Calcolo
          <CircularProgress color="inherit" sx={{ marginLeft: "6px" }} />
        </Stack>
      </Backdrop>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              {" "}
              <Grid item xs={6} md={3}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DatePicker
                    views={["month", "year"]}
                    ampmInClock={false}
                    renderInput={(props) => <TextField fullWidth {...props} />}
                    label="Periodo"
                    value={moment(date)}
                    onChange={(newValue) => {
                      setDate(newValue ? newValue : null);
                      /*  setAccertamentiDaFatturare([]);*/
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={5}>
                <Autocomplete
                  id="combo-box-demo"
                  options={clienti}
                  fullWidth
                  getOptionLabel={(option) => option.RagioneSociale}
                  sx={{ marginBottom: "10px" }}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setClienteSel(newValue);
                      loadFatturazioneCorsi(newValue.ID);
                    } else {
                      setClienteSel(null);
                    }
                    setCorsi([]);

                    //handleChangeCliente(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Cliente"
                      //error={validation.Cliente}
                      /* helperText={
                          validation.Cliente ? "cliente obbligatorio" : ""
                        }*/
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  onClick={generateFatturazione}
                  className="ButtonMiller"
                  variant="contained"
                  sx={{ marginTop: "8px" }}
                  startIcon={<Output />}
                >
                  Genera
                </Button>
                <Button
                  onClick={stampaReport}
                  className="ButtonMiller"
                  variant="contained"
                  sx={{ marginTop: "8px", marginLeft: "10px", display: "None" }}
                  startIcon={<Print />}
                >
                  STAMPA
                </Button>
                <Button
                  onClick={confermaFatturazione}
                  className="ButtonMiller"
                  variant="contained"
                  sx={{
                    marginTop: "8px",
                    marginLeft: "10px",
                    display: corsi.length > 0 ? "" : "None",
                  }}
                  startIcon={<Print />}
                >
                  STAMPA FATTURA
                </Button>
              </Grid>
              <Grid item xs={12}>
                TOTALE : {corsi.Totale} €
                {corsi?.map((tipoCorso, indexTipoCorso) => (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="AccordionMiller"
                    >
                      <Typography>
                        {tipoCorso.DescrizioneCorso +
                          " - TOTALE " +
                          tipoCorso.totaleTipoCorso +
                          " €"}{" "}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {tipoCorso.corsi.map((corso, indexCorsoSpecifico) => (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon sx={{ color: "white" }} />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className="AccordionMiller"
                          >
                            <CloseIcon
                              className="closeIconMiller"
                              onClick={() =>
                                rimuoviCorso(
                                  indexTipoCorso,
                                  indexCorsoSpecifico
                                )
                              }
                            />
                            <Typography>
                              {"Inizio : " +
                                moment(corso.DataInizio).format("DD/MM/YYYY") +
                                " - Fine : " +
                                moment(corso.DataFine).format("DD/MM/YYYY") +
                                "  - Durata : " +
                                corso.OreTotaliCorso +
                                " h - " +
                                corso.ModalitaDescrizione +
                                " - " +
                                (corso.DescrizioneFatturazione
                                  ? corso.DescrizioneFatturazione
                                  : "Tariffa a Lavoratore") +
                                " - "}
                            </Typography>
                            <Typography>
                              {"Tariffa : " +
                                (corso.ModalitaFatturazione === 1
                                  ? corso.Tariffa + " €"
                                  : corso.CostoForfait + " €") +
                                (corso.Aggiornamento === 1
                                  ? " - Aggiornamento"
                                  : "") +
                                " - TOTALE : " +
                                corso.totaleCorso +
                                " €"}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Lavoratore</TableCell>
                                    <TableCell>Presenza</TableCell>
                                  </TableRow>
                                </TableHead>
                                {corso.Presenti.map((presente, index) => (
                                  <TableRow
                                    key={index}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell component="th" scope="row">
                                      <IconButton
                                        onClick={() =>
                                          setPresenza(
                                            indexTipoCorso,
                                            indexCorsoSpecifico,
                                            index,
                                            presente.EraPresente
                                          )
                                        }
                                      >
                                        <CircleIcon
                                          style={{
                                            color:
                                              presente.EraPresente === 0
                                                ? "#FF2768"
                                                : "#40C18F",
                                          }}
                                        />
                                      </IconButton>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {presente.NomeLavoratore}
                                    </TableCell>
                                    <TableCell>
                                      {presente.EraPresente === 0 ? "NO" : "SI"}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </Table>
                            </TableContainer>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {corsi.map((corso, index) => (
        <>
          <Table
            sx={{ minWidth: 650, border: "1px solid black", display: "None" }}
            aria-label="simple table"
            id={"mytable" + index}
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell className="tableHeaderMiller">
                  {corso.DescrizioneCorso}{" "}
                </TableCell>
                <TableCell
                  className="tableHeaderMiller"
                  sx={{ textAlign: "right!important" }}
                >
                  {" "}
                  {"TOTALE " + corso.totaleTipoCorso + " €"}
                </TableCell>
              </TableRow>
            </TableHead>

            {corso.corsi.map((corsoNelDettaglio) => {
              let head = "";

              if (corsoNelDettaglio.MostraInStampa === 1) {
                if (
                  corsoNelDettaglio.DataInizio === corsoNelDettaglio.DataFine
                ) {
                  head =
                    "Data Corso : " +
                    moment(corsoNelDettaglio.DataInizio).format("DD/MM/YYYY");
                } else {
                  head =
                    "Inizio : " +
                    moment(corsoNelDettaglio.DataInizio).format("DD/MM/YYYY") +
                    "Fine : " +
                    moment(corsoNelDettaglio.DataFine).format("DD/MM/YYYY");
                }

                return (
                  <>
                    <TableRow>
                      <TableCell colspan={3} className="tableSubHeaderMiller">
                        {head +
                          " Durata : " +
                          corsoNelDettaglio.OreTotaliCorso +
                          " h - " +
                          (corsoNelDettaglio.DescrizioneFatturazione
                            ? corsoNelDettaglio.DescrizioneFatturazione
                            : "Tariffa a Lavoratore") +
                          " - " +
                          corsoNelDettaglio.ModalitaDescrizione +
                          (corsoNelDettaglio.Aggiornamento === 1
                            ? " - Aggiornamento"
                            : "")}{" "}
                      </TableCell>
                    </TableRow>
                    {corsoNelDettaglio.Presenti.map((lavoratore) => {
                      if (
                        lavoratore.EraPresente === 1 &&
                        (!corsoNelDettaglio.DescrizioneFatturazione ||
                          corsoNelDettaglio.DescrizioneFatturazione ===
                            "Tariffa a lavoratore")
                      ) {
                        return (
                          <TableRow>
                            <TableCell className="tableTextMiller">
                              {lavoratore.NomeLavoratore}{" "}
                            </TableCell>
                            <TableCell className="tableTextMiller">
                              {corsoNelDettaglio.Tariffa + " €"}{" "}
                            </TableCell>
                          </TableRow>
                        );
                      }
                      if (
                        lavoratore.EraPresente === 1 &&
                        (corsoNelDettaglio.DescrizioneFatturazione ||
                          corsoNelDettaglio.DescrizioneFatturazione ===
                            "Forfait")
                      ) {
                        return (
                          <TableRow>
                            <TableCell colspan={3} className="tableTextMiller">
                              {lavoratore.NomeLavoratore}{" "}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    })}
                    <TableRow>
                      <TableCell
                        colspan={3}
                        className="tableHeaderMiller"
                        sx={{ textAlign: "right!important" }}
                      >
                        <b>
                          {(corsoNelDettaglio?.DescrizioneFatturazione ===
                          "Forfait"
                            ? "IMPORTO FORFAIT ORARIO " +
                              corsoNelDettaglio.CostoForfait +
                              " € -"
                            : "") +
                            " TOTALE CORSO " +
                            corsoNelDettaglio.totaleCorso +
                            " €"}{" "}
                        </b>
                      </TableCell>
                    </TableRow>
                  </>
                );
              }
            })}
          </Table>
        </>
      ))}

      <Table
        sx={{ minWidth: 650, border: "1px solid black", display: "none" }}
        aria-label="simple table"
        id={"footerTable"}
      >
        <TableHead>
          <TableRow>
            <TableCell
              colspan={3}
              className="tableHeaderMiller"
              sx={{ textAlign: "right!important" }}
            >
              {" TOTALE DA FATTURARE " + corsi.Totale + " €"}{" "}
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </>
  );
};

export default PrefatturazioneCorsi;
