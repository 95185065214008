import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import unsplash from "../api/unsplash";
import GlobalSnackbar from "./Snackbar";

import {
  Grid,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  Zoom,
  LinearProgress,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import { IconButton } from "@mui/material";

import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";

import {
  Add,
  Delete,
  Close,
  Check,
  Search,
  MenuBook,
} from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

let newCorsoAttestato = {
  Codice: "",
  Aggiornamento: 0,
  Attestato1: "",
  Attestato2: "",
  Attestato3: "",
};
const CorsiAttestati = () => {
  const [corsiAttestati, setCorsiAttestati] = useState([]);
  const [corsiAttestatiVisibili, setCorsiAttestatiVisibili] = useState([]);
  const [loading, setLoading] = useState(true);
  const [vistaCorsoAttestato, setVistaCorsoAttestato] = useState([]);
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: 70,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={async () => {
              await setVistaCorsoAttestato(params.row);
              document.getElementById("edit").click();
              handleClickOpen();
            }}
          >
            <EditIcon
              style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
            />
          </IconButton>

          {/* <IconButton
            aria-label="delete"
            onClick={async () => {
              await setVistaCorsoAttestato(params.row);
              toggleAlert();
            }}
          >
            <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
          </IconButton> */}
        </>
      ),
    },
    {
      field: "Codice",
      headerName: "Codice",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Aggiornamento",
      headerName: "Aggiornamento",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Attestato1",
      headerName: "Attestato 1",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Attestato2",
      headerName: "Attestato 2",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Attestato3",
      headerName: "Attestato 3",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  const reloadCorsi = async () => {
    try {
      let response = await unsplash.get("corsiAttestati", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      setCorsiAttestati(response.data.data);
      setCorsiAttestatiVisibili(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    (() => {
      reloadCorsi();
    })();
  }, []);

  const handleSave = async (corsoAttestato, operazione) => {
    if (operazione === "new") {
      try {
        await unsplash.post(
          "corsiAttestati",
          {
            corso: corsoAttestato,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Corso Attestato creato con successo",
        });
        setOpenSnack(true);
        reloadCorsi()
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante la creazione",
        });
        setOpenSnack(true);
      }
      handleClose();
    } else {
      if (corsoAttestato.Firma === null) {
        let copiaVistaCorsoAttestato = { ...corsoAttestato };
        copiaVistaCorsoAttestato.Firma = "";
        setVistaCorsoAttestato(copiaVistaCorsoAttestato);
      }
      try {
        await unsplash.put(
          "corsiAttestati",
          {
            corso: corsoAttestato,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Corso Attestato aggiornato con successo",
        });
        setOpenSnack(true);
        reloadCorsi()
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'aggiornamento",
        });
        setOpenSnack(true);
      }
      handleClose();
    }
  };
  const toggleAlert = () => {
    setOpenAlert(!openAlert);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid item xs={12}>
        <Card>
          <CardContent sx={{ p: { xs: 0 } }}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Grid item xs={12} md={4}>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <NewFromatori
                          id="edit"
                          corsoAttestato={vistaCorsoAttestato}
                          saveFunction={handleSave}
                          title="Modifica Corso Attestato"
                        />
                        <NewFromatori
                          id="new"
                          corsoAttestato={vistaCorsoAttestato}
                          saveFunction={handleSave}
                          title="Nuovo Corso Attestato"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      height: { xs: "75vh", md: "60vh", xl: "75vh" },
                      width: 1,
                    }}
                  >
                    <DataGridPro
                      initialState={{
                        pinnedColumns: { left: ["Actions"] },
                      }}
                      components={{
                        Toolbar: CustomToolbar,
                        Footer: CustomFooterPersonalized,
                      }}
                      slots={{
                        loadingOverlay: LinearProgress,
                      }}
                      loading={loading}
                      localeText={
                        itIT.components.MuiDataGrid.defaultProps.localeText
                      }
                      rowHeight={52}
                      rows={corsiAttestatiVisibili}
                      columns={columns}
                      getRowId={(row) => row.ID}
                      pageSize={20}
                      rowsPerPageOptions={[20]}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <DeleteCorsiAttestati
              corsoAttestato={vistaCorsoAttestato}
              openValue={openAlert}
              openFunction={toggleAlert}
            />
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default CorsiAttestati;

/* Eliminazione CorsiAttestati */
const DeleteCorsiAttestati = (props) => {
  const [openSnackDel, setOpenSnackDel] = useState(false);
  const [snackDel, setSnackDel] = useState({
    severity: "success",
    messaggio: "Eliminazione effettuata correttamente",
    open: false,
  });
  const handleCloseSnackDel = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackDel(false);
  };
  const handleDelete = async () => {
    try {
      await unsplash.delete(
        "corsiAttestati",
        { id: props.corsoAttestato.ID },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnackDel({
        severity: "success",
        messaggio: "Eliminazione effettuata correttamente",
      });
      setOpenSnackDel(true);
    } catch {
      setSnackDel({
        severity: "error",
        messaggio: "Errore durante l'eliminazione",
      });
      setOpenSnackDel(true);
    }
    /* setTimeout(() => {
      window.location.reload();
    }, 1000); */
  };
  {
    /* Dialog Alert Eliminazione CorsoAttestato */
  }
  return (
    <>
      <GlobalSnackbar
        severity={snackDel.severity}
        messaggio={snackDel.messaggio}
        openSnack={openSnackDel}
        handleCloseSnack={handleCloseSnackDel}
      />
      <Dialog
        open={props.openValue}
        onClose={props.openFunction}
        maxWidth="md"
        TransitionComponent={Transition}
      >
        <DialogTitle>
          Sei sicuro di voler eliminare {props.corsoAttestato.Nome}{" "}
          {props.corsoAttestato.Cognome}?
        </DialogTitle>
        <DialogContent
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button onClick={props.openFunction}>ANNULLA</Button>
          <Button onClick={handleDelete}>ELIMINA</Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

/* Creazione Nuovi CorsiAttestati */
const NewFromatori = (props) => {
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [checked, setChecked] = useState(false);
  const [corsi, setCorsi] = useState([]);
  const [corsoAttestato, setCorsoAttestato] = useState({
    Codice: "",
    Aggiornamento: 0,
    Attestato1: "",
    Attestato2: "",
    Attestato3: "",
  });

  const handleClickOpen = async () => {
    try {
      let response = await unsplash.get("anagraficaCorsi", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      const objCorsi = response.data.data;
      const cod = objCorsi.map((x) => x.Codice);

      setCorsi(cod);
    } catch (error) {
      console.error(error.message);
    }
    if (props.id === "edit") {
      setCorsoAttestato(props.corsoAttestato);
    } else if (props.id === "new") {
      setCorsoAttestato(newCorsoAttestato);
    }
    setOpen(true);
  };
  const handleClose = () => {
    setCorsoAttestato(newCorsoAttestato);
    setOpen(false);
  };
  const handleChange = (e) => {
    let copiaCorsoAttestato = { ...corsoAttestato };
    copiaCorsoAttestato[e.target.name] = e.target.value;
    setCorsoAttestato(copiaCorsoAttestato);
  };
  const handleSave = async () => {
    props.saveFunction(corsoAttestato, props.id);
    handleClose();
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
      >
        {props.id === "edit" ? (
          <Button
            startIcon={<Add />}
            onClick={handleClickOpen}
            id={props.id}
            sx={{ color: "white", display: "none" }}
            variant="outlined"
            className="ButtonMiller"
          >
            Aggiungi
          </Button>
        ) : (
          <Button
            startIcon={<Add />}
            onClick={handleClickOpen}
            id={props.id}
            sx={{ color: "white" }}
            variant="outlined"
            className="ButtonMiller"
          >
            Aggiungi
          </Button>
        )}
      </ButtonGroup>

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="lg"
          TransitionComponent={Transition}
        >
          {/* Title Container */}
          <DialogTitle
            sx={{
              mx: "auto",
            }}
          >
            {props.title}
          </DialogTitle>
          {/* Body Container */}
          <DialogContent>
            {/* Row 1
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  value={corsoAttestato.Nome}
                  id="outlined-basic"
                  label="Nome"
                  name="Nome"
                  onChange={handleChange}
                  fullWidth
                  sx={{ mt: 2 }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={corsoAttestato.Cognome}
                  id="outlined-basic"
                  label="Cognome"
                  name="Cognome"
                  onChange={handleChange}
                  fullWidth
                  sx={{ mt: 2 }}
                />
              </Grid>
            </Grid> */}
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="country-select-demo"
                    options={corsi}
                    value={corsoAttestato.Codice}
                    fullWidth
                    onChange={(e, newValue) => {
                      let c = { ...corsoAttestato };
                      c.Codice = newValue;
                      setCorsoAttestato(c);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        label="Corso"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    sx={{ mb: 0, display: "flex", justifyContent: "center" }}
                    control={
                      <Checkbox
                        name="Aggiornamento"
                        checked={
                          corsoAttestato.Aggiornamento === "1" ? true : false
                        }
                        onChange={(e) => {
                          let c = { ...corsoAttestato };
                          c.Aggiornamento = e.target.checked ? "1" : "0";
                          setCorsoAttestato(c);
                        }}
                      />
                    }
                    label="Aggiornamento"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={corsoAttestato.Attestato1}
                  id="outlined-basic"
                  label="Attestato1"
                  name="Attestato1"
                  onChange={handleChange}
                  fullWidth
                  sx={{ mt: 2 }}
                />
              </Grid>
            </Grid>
            {/* Row 3 */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  value={corsoAttestato.Attestato2}
                  id="outlined-basic"
                  label="Attestato2"
                  name="Attestato2"
                  onChange={handleChange}
                  fullWidth
                  sx={{ mt: 2 }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={corsoAttestato.Attestato3}
                  id="outlined-basic"
                  label="Attestato3"
                  name="Attestato3"
                  onChange={handleChange}
                  fullWidth
                  sx={{ mt: 2 }}
                />
              </Grid>
            </Grid>

            {/* Bottoni Salva & Esci */}
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Button
                  onClick={handleClose}
                  variant="contained"
                  className="ButtonMiller"
                  startIcon={<Close />}
                >
                  Chiudi
                </Button>
                <Button
                  onClick={handleSave}
                  variant="contained"
                  className="ButtonMiller"
                  startIcon={<Check />}
                >
                  Salva
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
};
