import React from "react";
import { Grid, Button, CardMedia } from "@mui/material";
import "../style/style.css";
import unsplash from "../api/unsplash";
import { login, loginPersistant } from "../utils";
import { useNavigate } from "react-router-dom";

import soundFile from "../assets/sounds/notification.mp3";
import GlobalSnackbar from "./Snackbar";
import Safety from "../assets/images/Safety_sx.png";
import Sfondo from "../assets/images/sfondo.png";
import { Hidden } from "@mui/material";

class Login extends React.Component {
  constructor() {
    super();

    this.state = {
      isChecked: false,
      passwordShown: false,
      openSnack: false,
      snack: {
        severity: "success",
        messaggio: "Salvataggio Effettuato correttamente",
        open: false,
      },
      icon: "bi bi-eye-slash-fill",
    };
  }
  handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ openSnack: false });
  };
  handleCheck = (e) => {
    this.setState({ isChecked: e.target.checked });
  };
  componentDidMount = () => {
    //Coloro lo sfondo solo qui
    //document.body.style.backgroundColor = "rgb(238, 242, 246)";
    document.body.style.backgroundImage = "url(" + Sfondo + ")";
    document.body.style.backgroundSize = "cover";
  };
  handleEyeClick = () => {
    this.setState({ passwordShown: !this.state.passwordShown });

    this.state.passwordShown
      ? this.setState({ icon: "bi bi-eye-slash-fill" })
      : this.setState({ icon: "bi bi-eye-fill" });
  };

  handleLogin = async (event) => {
    event.preventDefault();
    var md5 = require("md5");

    try {
      const response = await unsplash.post("login", {
        username: this.username.value,
        password: md5(this.password.value),
      });
      //this.setState({images:response.data});

      if (response.data.utente.data.length > 0) {
        if (response.data.utente.type === "Interno") {
          if (this.state.isChecked) {
            //per login dove si ricorda l'utente
            loginPersistant(response.data.utente.token);
          } else {
            login(
              response.data.utente.data[0].foto,
              response.data.utente.token
            );
          }
          sessionStorage.setItem(
            "Medicina",
            response.data.utente.data[0].Medicina
          );
          sessionStorage.setItem(
            "Sicurezza",
            response.data.utente.data[0].Sicurezza
          );
          sessionStorage.setItem(
            "Formazione",
            response.data.utente.data[0].Formazione
          );

          if (response.data.utente.nAccessi > 0) {
            sessionStorage.setItem("popup", false);
          } else {
            sessionStorage.setItem("popup", true);
          }

          await unsplash.post(
            "storico",
            {
              tabella: "No tabella",
              key: this.username.value,
              utente: this.username.value,
              after: [],
              before: [],
              operazione: "Login",
            },
            {
              headers: {
                Authorization: `Bearer ${response.data.utente.token}`,
              },
            }
          );

          sessionStorage.setItem(
            "username",
            response.data.utente.data[0].username
          );
          sessionStorage.setItem("idUsername", response.data.utente.data[0].ID);
          sessionStorage.setItem(
            "Autorizzazione",
            response.data.utente.data[0].Admin
          );
          sessionStorage.setItem(
            "tipologia",
            response.data.utente.data[0].tipoUtente
          );
          this.props.navigate("/");
          if (response.data.utente.tasks > 0) {
            let sound = new Audio(soundFile);
            sound.play();
          }
        }

        if (response.data.utente.type === "Cliente") {
          login(null, response.data.utente.token);
          sessionStorage.setItem("S", response.data.utente.data[0].Sicurezza);
          sessionStorage.setItem("F", response.data.utente.data[0].Formazione);
          sessionStorage.setItem("M", response.data.utente.data[0].Medicina);

          sessionStorage.setItem(
            "Cliente",
            response.data.utente.data[0].Cliente
          );
          sessionStorage.setItem(
            "Notarizzazione",
            response.data.utente.data[0].Notarizzazione
          );
          sessionStorage.setItem("User", this.username.value);
          this.props.navigate("/AreaClienti/Dashboard");
        }
      } else {
        this.setState({
          snack: { severity: "error", messaggio: "USERNAME O PASSWORD ERRATE" },
        });
        this.setState({ openSnack: true });
      }
    } catch {
      this.setState({
        snack: { severity: "error", messaggio: "USERNAME O PASSWORD ERRATE" },
      });
      this.setState({ openSnack: true });
    }
  };

  render() {
    return (
      <>
        <GlobalSnackbar
          severity={this.state.snack.severity}
          messaggio={this.state.snack.messaggio}
          openSnack={this.state.openSnack}
          handleCloseSnack={this.handleCloseSnack}
        />
        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="center"
          display="flex"
          minHeight="100vh"
          flexWrap="nowrap"
        >
          <Hidden mdDown>
            <Grid
              item
              xs={6}
              sx={{
                margin: "30px",
                borderRadius: "8px",
              }}
            >
              <CardMedia
                sx={{ borderRadius: "8px", height: "calc(100vh - 4rem)" }}
                component="img"
                image={Safety}
                alt="Safety"
              />
            </Grid>
          </Hidden>
          <Grid
            item
            xs={8}
            lg={3}
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexWrap="wrap"
            gap={4}
            style={{ margin: "0 auto" }}
          >
            <img
              src={require("../assets/images/logo.png")}
              className="max-w-full h-auto"
              width="500px"
              alt="f"
            />

            <form onSubmit={this.handleLogin} style={{ width: "100%" }}>
              <div className="form-outline mb-4">
                <label className="form-label  fs-5" htmlFor="username">
                  Username
                </label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  placeholder="Inserisci l'username"
                  ref={(username) => (this.username = username)}
                  className="block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded py-2 px-4 text-lg leading-normal rounded bg-white fs-6"
                />
              </div>

              <label className="form-label fs-5" htmlFor="password">
                Password
              </label>
              <div className="form-outline mb-4 relative flex items-stretch w-full">
                <input
                  type={this.state.passwordShown ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Inserisci la password"
                  ref={(password) => (this.password = password)}
                  className="block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded py-2 px-4 text-lg leading-normal rounded border-r-0 bg-white fs-6"
                />
                <span className="input-group-text bg-transparent">
                  <i
                    className={this.state.icon}
                    style={{ cursor: "pointer" }}
                    onClick={this.handleEyeClick}
                  ></i>
                </span>
              </div>

              <div className="flex justify-around items-center mb-4"></div>

              <Button
                type="submit"
                fullWidth
                size="large"
                variant="contained"
                onClick={this.handleLogin}
                style={{ padding: "8px 16px", backgroundColor: "#11526f" }}
              >
                Login{" "}
              </Button>
            </form>
          </Grid>
        </Grid>
      </>
    );
  }
}

function WithNavigate(props) {
  const navigate = useNavigate();

  return <Login {...props} navigate={navigate} />;
}

export default WithNavigate;

/*

SELECT
	* 
FROM
	Lavoratori 
WHERE
	DataInserimento = DATE(DATE_SUB(
	NOW(),
	INTERVAL 1 DAY))

SELECT
	COUNT(*) as tot 
FROM
	Storico
WHERE Operazione = "Login"
AND DATE(Date) = DATE(NOW())






*/
