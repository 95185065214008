/*eslint-disable*/
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AccountCircle,
  Folder,
  HealthAndSafety,
  Logout,
  School,
} from "@mui/icons-material";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@mui/material";
//import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";
//import UserDropdown from "components/Dropdowns/UserDropdown.js";

export default function Sidebar(props) {
  const navigate = useNavigate();
  const [collapseShow, setCollapseShow] = React.useState("hidden");

  const SidebarTooltip = withStyles({
    tooltip: {
      padding: 10,
      paddingRight: 15,
      color: "black",
      backgroundColor: "white",
      fontSize: 18,
      fontWeight: 500,
      margin: 10,
      boxShadow: "5px 2px 5px #11526F50",
    },
  })(Tooltip);

  return (
    <>
      <nav className="z-40 lg:left-0 lg:block fixed lg:top-0 lg:bottom-0 lg:w-24 bottom-0 w-full lg:overflow-y-auto lg:flex-row lg:flex-nowrap lg:overflow-hidden bg-primary-600 dark:bg-primary-800 flex flex-wrap items-center justify-between  p-4 lg:p-0 lg:py-4 shadow-md shadow-primary-600 border-t border-primary-600/50">
        <div className="lg:flex-col lg:items-stretch lg:min-h-full lg:flex-nowrap px-0 flex flex-wrap items-center justify-center lg:justify-between w-full mx-auto relative">

          {
            //#region Footer Mobile & Tablet
          }

          <div className="flex flex-wrap items-center gap-3 sm:gap-8 lg:hidden">
            <div className="flex flex-col justify-center mr-3">
              <HealthAndSafety
                sx={{ fontSize: { sm: 42, xs: 28 } }}
                onClick={() => navigate("/AreaMedico/Visita")}
                className={
                  "fas fa-tools mx-auto " +
                  (window.location.href.indexOf("/Visita") !== -1
                    ? "text-primary-100 dark:text-white"
                    : "text-white dark:text-white")
                }
              />
              <div className="text-sm w-full text-center text-white">
                Visite
              </div>
            </div>
            <div className="flex flex-col justify-center relative">
              {/* Cerchio sopra Homepage */}
              <div className="z-20 absolute -top-10 left-[50%] -translate-x-[50%] rounded-full h-16 sm:h-24 w-16 sm:w-24 bg-white shadow-[rgba(0,0,15,0.5)_1px_1px_10px_0px]"></div>
              <Link
                className="z-30 lg:block text-left lg:pb-4 mr-0 inline-block p-0 -mt-10"
                to="/AreaMedico/Home"
                onClick={() => {
                  props.setPage("Dashboard"), setCollapseShow("hidden");
                }}
              >
                <img
                  src={require("../../../assets/images/logo_Koala_min.png")}
                  className="h-16 sm:h-24"
                />
              </Link>
              {/* <span className="z-30 text-xs sm:text-sm w-full text-center dark:text-white">Home</span> */}
            </div>
            <div className="flex flex-col justify-center">
              <Folder
                sx={{ fontSize: { sm: 42, xs: 28 } }}
                onClick={() => navigate("/AreaMedico/Documenti")}
                className={
                  "fas fa-tools mx-auto " +
                  (window.location.href.indexOf("/Documenti") !== -1
                    ? "text-primary-100 dark:text-white"
                    : "text-white dark:text-white")
                }
              />
              <div className="text-sm w-full text-center text-white">
                Documenti
              </div>
            </div>
            
          </div>
          {
            //#endregion
          }
          <Link
            className="hidden lg:block text-left lg:pb-4 mr-0 whitespace-nowrap text-sm uppercase font-bold p-0"
            to="/AreaMedico/Home"
            onClick={() => {
              props.setPage("Dashboard"), setCollapseShow("hidden");
            }}
          >
            <img
              src={require("../../../assets/images/logo_Koala_min.png")}
              className="w-40 lg:w-16 mx-auto"
            />
          </Link>
          <div
            className={
              "hidden lg:flex lg:flex-col lg:items-stretch lg:opacity-100 lg:relative lg:mt-4 xl:mb-16 lg:mb-10 lg:justify-between absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded "
            }
          >
            <ul className="lg:flex-col lg:min-w-full lg:my-auto gap-y-5 flex flex-col list-none  ">
              <SidebarTooltip title="Visita" placement="right">
                <li
                  className={
                    "text-sidebar-hover lg:mx-4 relative group z-50 " +
                    (window.location.href.indexOf("/Visita") !== -1 &&
                      "bg-white/40")
                  }
                  onClick={() => {
                    navigate("/AreaMedico/Visita");
                    props.setPage("Visita");
                  }}
                >
                  <div
                    className={`absolute w-1.5 h-12 -right-4 top-[50%] -translate-y-[50%] rounded-l-md bg-white group-hover:opacity-100 duration-300 transition-opacity ${
                      window.location.href.indexOf("/Visita") !== -1
                        ? "opacity-100"
                        : "opacity-0"
                    }`}
                  ></div>

                  <HealthAndSafety
                    fontSize="large"
                    className={"fas fa-tools text-white "}
                  />
                </li>
              </SidebarTooltip>
              <SidebarTooltip title="Documenti" placement="right">
                <li
                  onClick={() => {
                    navigate("/AreaMedico/Documenti");
                    props.setPage("Documenti");
                  }}
                  className={
                    "text-sidebar-hover lg:mx-4 relative group " +
                    (window.location.href.indexOf("/Documenti") !== -1 &&
                      "bg-white/40")
                  }
                >
                  <div
                    className={`absolute w-1.5 h-12 -right-4 top-[50%] -translate-y-[50%] rounded-l-xl bg-white group-hover:opacity-100 duration-300 transition-opacity ${
                      window.location.href.indexOf("/Documenti") !== -1
                        ? "opacity-100"
                        : "opacity-0"
                    }`}
                  ></div>
                  <Folder
                    fontSize="large"
                    className={"fas fa-tools text-white "}
                  />
                  {/* Documenti */}
                </li>
              </SidebarTooltip>
            </ul>
            <SidebarTooltip title="Esci" placement="right">
              <li
                onClick={() => {
                  navigate("/AreaMedico/Login");
                }}
                className={"text-sidebar-hover lg:mx-4 relative group "}
              >
                <div
                  className={`absolute w-1.5 h-12 -right-4 top-[50%] -translate-y-[50%] rounded-l-xl bg-white opacity-0 group-hover:opacity-100 duration-300 transition-opacity`}
                ></div>
                <Logout
                  fontSize="large"
                  className={"fas fa-tools text-white "}
                />
                {/* Documenti */}
              </li>
            </SidebarTooltip>
          </div>

          
        </div>
      </nav>
    </>
  );
}
