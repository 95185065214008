import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Container, Grid, Paper, Button, CardMedia } from "@mui/material";
import unsplash from "../../api/unsplash";
import { login, loginPersistant } from "../../utils";
import { useNavigate } from "react-router-dom";

import GlobalSnackbar from "../Snackbar";
import Safety from "../../assets/images/Safety_sx.png";
import Sfondo from "../../assets/images/sfondo.png";
import { Hidden } from "@mui/material";

const style = { background: "#f5f8fa" };

const SetPasswordParita = () => {
  const [isGood, setIsGood] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [password, setPassword] = useState("");
  const [confermaPassword, setConfermaPassword] = useState("");
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const [icon, setIcon] = useState("bi bi-eye-slash-fill");

  const navigate = useNavigate();

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleCheck = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleEyeClick = () => {
    setPasswordShown(!passwordShown);
    setIcon(passwordShown ? "bi bi-eye-slash-fill" : "bi bi-eye-fill");
  };

  useEffect(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    try {
      let respToken = await unsplash.post(
        "portaleParita",
        { operation: "verifyToken", token: token },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
    } catch {
      setIsGood(false);
    }
  }, []);

  const savePassword = async (e) => {
    if (password !== confermaPassword) {
      setSnack({
        severity: "warning",
        messaggio: "Attenzione le password non coincidono",
      });
      setOpenSnack(true);
    } else {

      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");

      e.preventDefault();

      try {
        let respToken = await unsplash.post(
          "portaleParita",
          { operation: "changePassword", token: token, password: password },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        navigate("/AreaParita/Login");
      } catch (e) {
        //alert("POOOOOO");
      }
    }
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      {isGood ? (
        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="center"
          display="flex"
          minHeight="100vh"
          flexWrap="nowrap"
        >
          <Hidden mdDown>
            <Grid
              item
              xs={6}
              sx={{
                margin: "30px",
                borderRadius: "8px",
              }}
            >
              <CardMedia
                sx={{ borderRadius: "8px", height: "calc(100vh - 4rem)" }}
                component="img"
                image={Safety}
                alt="Safety"
              />
            </Grid>
          </Hidden>
          <Grid
            item
            xs={8}
            lg={3}
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexWrap="wrap"
            gap={4}
            style={{ margin: "0 auto" }}
          >
            <img
              src={require("../../assets/images/logo.png")}
              className="max-w-full h-auto"
              width="500px"
              alt="f"
            />

            <div
              //onSubmit={handleLogin}
              style={{ width: "100%" }}
            >
              <label className="form-label fs-5" htmlFor="password">
                Inserisci Password
              </label>
              <div className="form-outline mb-4 relative flex items-stretch w-full">
                <input
                  type={passwordShown ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Inserisci la password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded py-2 px-4 text-lg leading-normal rounded border-r-0 bg-white fs-6"
                />
                <span className="input-group-text bg-transparent">
                  <i
                    className={icon}
                    style={{ cursor: "pointer" }}
                    onClick={handleEyeClick}
                  ></i>
                </span>
              </div>
              <label className="form-label fs-5" htmlFor="password">
                Conferma Password
              </label>
              <div className="form-outline mb-4 relative flex items-stretch w-full">
                <input
                  type={passwordShown ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Inserisci la password"
                  value={confermaPassword}
                  onChange={(e) => setConfermaPassword(e.target.value)}
                  className="block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded py-2 px-4 text-lg leading-normal rounded border-r-0 bg-white fs-6"
                />
                <span className="input-group-text bg-transparent">
                  <i
                    className={icon}
                    style={{ cursor: "pointer" }}
                    onClick={handleEyeClick}
                  ></i>
                </span>
              </div>

              <div className="flex justify-around items-center mb-4"></div>

              <Button
                type="submit"
                fullWidth
                size="large"
                variant="contained"
                onClick={savePassword}
                style={{ padding: "8px 16px", backgroundColor: "#11526f" }}
              >
                CONFERMA
              </Button>
            </div>
          </Grid>
        </Grid>
      ) : (
        "ATTENZIONE LINK NON PIU VALIDO"
      )}
    </>
  );
};

export default SetPasswordParita;
