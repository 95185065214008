import React, { useEffect, useState, useRef } from "react";

import CssBaseline from "@mui/material/CssBaseline";
import {
  Popover,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  IconButton,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Button,
  Tooltip,
  Chip,
  Box,
  CircularProgress,
  AppBar,
  Typography,
  TextField,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormControl,
  CardHeader,
  Card,
  CardContent,
  Grid,
  ButtonGroup,
  Zoom,
  Alert,
  LinearProgress,
} from "@mui/material";
import unsplash from "../api/unsplash";
import StarIcon from "@mui/icons-material/Star";
import { useNavigate } from "react-router-dom";
import Charts from "./Charts";
import { PureComponent } from "react";
import moment from "moment";
import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import DettaglioCorsi from "./DettaglioCorsi";
import {
  Error,
  Comment,
  Info,
  Check,
  Add,
  Circle,
  SettingsSuggestTwoTone,
  ChecklistIcon,
  Close,
  CheckCircle,
  Notifications,
  PriorityHigh,
  Warning,
} from "@mui/icons-material";
import Preferiti from "./Preferiti";
import soundFile from "../assets/sounds/notification.mp3";
import { RiepilogativoVisite } from "./RiepilogativoVisite";
import GlobalSnackbar from "./Snackbar";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import pinImage from "../assets/images/custom_marker.png";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { RiepilogativoFormazione } from "./RiepilogativoFormazione";

mapboxgl.accessToken =
  "pk.eyJ1Ijoic2FmZXR5bWlsbGVyIiwiYSI6ImNsZjJjeXJ0czBoYTgzcXM5dHF4cHF5ZTIifQ._S6DjunEpFelVN8zfqPRpQ";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const columnSicurezzaLavoratoriCorsiInScadenza = [
  {
    field: "Cliente",
    headerName: "Cliente ",
    //width: 300,
    flex: 1,
    headerAlign: "center",
    align: "center",
    sort: "asc",
  },
  {
    field: "Cognome",
    headerName: "Cognome",
    //width: 400,
    flex: 1,
    headerAlign: "center",
    align: "center",
    sort: "asc",
  },
  {
    field: "Nome",
    headerName: "Nome",
    //width: 300,
    flex: 1,
    headerAlign: "center",
    align: "center",
    sort: "asc",
  },
  {
    field: "*",
    headerName: "Corsi",
    //width: 300,
    flex: 1,
    headerAlign: "center",
    align: "center",
    sort: "asc",
    renderCell: (params) => {
      return (
        <div>
          <Tooltip
            title={
              "Scaduto il " + moment(params.row.Scadenza).format("DD/MM/YYYY")
            }
          >
            <Chip
              label={params.row.Ruolo}
              size="small"
              sx={{
                width: "70px",
                marginLeft: "2px",
                backgroundColor: "#FF2768",
                color: "white",
              }}
            />
          </Tooltip>
        </div>
      );
    },
  },
  {
    field: "Scadenza",
    headerName: "Scadenza",
    //width: 300,
    flex: 1,
    headerAlign: "center",
    align: "center",
    sort: "asc",
    renderCell: (params) => {
      return (
        <div>
          {params.row.Scadenza
            ? moment(params.row.Scadenza).format("DD - MM - YYYY")
            : ""}
        </div>
      );
    },
  },
];

const columnsAdempimenti = [
  {
    field: "Actions",
    headerName: "Stato",
    width: 70,
    renderCell: (params) => {
      return <Error sx={{ color: "#FF2768" }} />;
    },
  },
  { field: "RagioneSociale", headerName: "Cliente", flex: 1 },
  { field: "Descrizione", headerName: "Descrizione", flex: 1 },
  { field: "Scadenza", headerName: "Validità", width: 90 },
  {
    field: "UltimaScadenza",
    headerName: "Data",
    width: 90,
    renderCell: (params) =>
      params.row.UltimaScadenza
        ? moment(params.row.UltimaScadenza).format("DD/MM/YYYY")
        : "",
  },
];

const geojson = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [9.282, 45.35],
      },
      properties: {
        title: "MillerTech S.R.L.",
        description: "MillerTech",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [12.492315569000603, 41.89021294124296],
      },
      properties: {
        title: "MillerTech S.R.L.",
        description: "SAN GIULIANO REGNA",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-122.414, 37.776],
      },
      properties: {
        title: "Mapbox",
        description: "San Francisco, California",
      },
    },
  ],
};

const Homepage = () => {
  const mapContainer = useRef(null);

  const [lng, setLng] = useState(12.40248391532296);
  const [lat, setLat] = useState(42.2590182651609);
  const [zoom, setZoom] = useState(5);
  const [tipologia, setTipologia] = useState(
    sessionStorage.getItem("tipologia")
  );
  const [preferiti, setPreferiti] = useState([]);
  const [lavoratoriCorsiInScadenza, setLavoratoriCorsiInScadenza] = useState(
    []
  );
  const [lavoratoriScadutiMedicina, setLavoratoriScadutiMedicina] = useState([]);
  const [adempimentiScadutiUtente, setAdempimentiScadutiUtente] = useState([]);
  const [corsiScadutiUtenti, setCorsiScadutiUtenti] = useState([]);
  const [lavoratoriImportatiZucchetti, setLavoratoriImportatiZucchetti] = useState([]);
  const [loadingCScad, setLoadingCScad] = useState(true);
  const [prossimiCorsi, setProssimiCorsi] = useState([]);
  const [loadingCorsi, setLoadingCorsi] = useState(true);
  const [corsiAttestati, setCorsiAttestati] = useState([
    { Attestato1: "", Attestato2: "", Attestato3: "" },
  ]);
  const [stampa, setStampa] = useState(true);
  const [anagraficaCorsi, setAnagraficaCorsi] = useState([]);
  const [modalita, setModalita] = useState([]);
  const [formatori, setFormatori] = useState([]);
  const [modalitaFatturazioneCorsi, setModalitaFatturazioneCorsi] = useState(
    []
  );
  const [user, setUser] = useState({});
  const [value, setValue] = useState(0);
  const [height, setHeight] = useState(0);
  const [adempimentiScaduti, setAdempimentiScaduti] = useState([]);
  const [loadingAdem, setLoadingAdem] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [newTasks, setNewTasks] = useState(0);
  const [audio, SetAudio] = useState(
    new Audio("../assets/sounds/notification.mp3")
  );

  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const navigate = useNavigate();

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }
  const columnsProssimiCorsi = [
    {
      field: "Actions",
      filterable: false,
      headerName: "Azioni",
      width: 70,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <DettaglioCorsi
            Title="Modifica Corso"
            Corsi={anagraficaCorsi}
            Modalita={modalita}
            Formatori={formatori}
            Operation="Edit"
            id="edit"
            corsoH={params.row}
            CorsiAttestati={corsiAttestati}
            Stampa={stampa}
            ModalitaFatturazioneCorsi={modalitaFatturazioneCorsi}
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
          />
        </>
      ),
    },
    {
      field: "Descrizione",
      headerName: "Descrizione",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Aggiornamento",
      headerName: "Agg.",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "ModalitaDescrizione",
      headerName: "Modalità",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Luogo",
      headerName: "Luogo",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "DataInizio",
      headerName: "Data Inizio",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => {
        return (
          <div>
            {params.row.DataInizio
              ? moment(params.row.DataInizio).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },
    {
      field: "DataFine",
      headerName: "Data Fine",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => {
        return (
          <div>
            {params.row.DataFine
              ? moment(params.row.DataFine).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },
    {
      field: "Note",
      headerName: "Note",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Durata",
      headerName: "Durata",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "nGiorni",
      headerName: "N° Giorni",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
  ];
  const handleCorsiAttestati = async (idCorso, agg) => {
    let res = await unsplash.get("corsiAttestati", {
      params: { idCorso: idCorso },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    if (res.data.data.length === 0) {
      setStampa(false);
    } else if (res.data.data.length === 2) {
      setStampa(true);
      var formatoriX = [];
      var i;
      for (i = 0; i < res.data.data.length; i++) {
        if (res.data.data[i].Aggiornamento == agg) {
          formatoriX.push(res.data.data[i]);
        }
        setCorsiAttestati(formatoriX);
      }
    } else {
      setCorsiAttestati(res.data.data);
      setStampa(true);
    }
  };

  const createTask = async (task) => {
    try {
      let response = await unsplash.post(
        "tasks",
        { task: task },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      loadTasks();
      setSnack({ severity: "success", messaggio: "Task creata con successo" });
      setOpenSnack(true);
    } catch {
      setSnack({ severity: "error", messaggio: "Errore durante la creazione" });
      setOpenSnack(true);
    }
  };

  const completaTask = async (tasks) => {
    try {
      let response = await unsplash.put(
        "tasks",
        { tasks: tasks.map((t) => t.ID), operazione: "Completa" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      loadTasks();

      setSnack({ severity: "success", messaggio: "Task completata" });
      setOpenSnack(true);
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante il completamento della task",
      });
      setOpenSnack(true);
    }
  };

  const loadSicurezza = async () => {
    let response = await unsplash.get("analysis", {
      params: { operazione: "sicurezzaLavoratoriCorsiInScadenza" },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    setLavoratoriCorsiInScadenza(response.data.data);
    setLoadingCScad(false);

    let responseProssimiCorsi = await unsplash.get("analysis", {
      params: { operazione: "prossimiCorsi" },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setProssimiCorsi(responseProssimiCorsi.data.data);
    setLoadingCorsi(false);

    let responseAdempimenti = await unsplash.get("analysis", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { operazione: "scadenzeAdempimenti" },
    });

    setAdempimentiScaduti(responseAdempimenti.data.data);
    setLoadingAdem(false);

    let responsecorsi = await unsplash.get(
      "anagraficaCorsi",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      } //props.idAzienda}}
    );

    setAnagraficaCorsi(responsecorsi.data.data);

    let responseFormatori = await unsplash.get(
      "formatori",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      } //props.idAzienda}}
    );
    setFormatori(responseFormatori.data.data);
    let responseModalitaCorsi = await unsplash.get(
      "modalitaCorsi",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      } //props.idAzienda}}
    );
    setModalita(responseModalitaCorsi.data.data);

    let responseModalitaFatturazioneCorsi = await unsplash.get(
      "modalitaFatturazioneCorsi",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      } //props.idAzienda}}
    );
    setModalitaFatturazioneCorsi(responseModalitaFatturazioneCorsi.data.data);
  };
  const loadTasks = async () => {
    let responseTasks = await unsplash.get("tasks", {
      params: { tipologia: "DaFare", user: sessionStorage.getItem("username") },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    setTasks(responseTasks.data.data);
    setNewTasks(
      responseTasks.data.data.filter((r) => r.Visionato === 0).length
    );
  };

  const loadPreferiti = async () => {
    let responsePreferiti = await unsplash.get("preferiti", {
      params: { user: sessionStorage.getItem("username") },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setPreferiti(responsePreferiti.data.data);
  };

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      try {
        loadTasks();

        let responsePreferiti = await unsplash.get("preferiti", {
          params: { user: sessionStorage.getItem("username") },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        setPreferiti(responsePreferiti.data.data);
        let responseLavoratoriZucchetti = await unsplash.get("lavoratori", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { id: sessionStorage.getItem("idUsername"),lavoratoriImportatiDaZucchetti:true },
        })
        setLavoratoriImportatiZucchetti(responseLavoratoriZucchetti.data.data)



        if (sessionStorage.getItem("Medicina") === "1"){ // MEDICINA SCADUTI

          let responseLavoratori = await unsplash.get("lavoratori", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
            params: { id: sessionStorage.getItem("idUsername"),lavoratoriScadutiMedicina:true },
          })
          setLavoratoriScadutiMedicina(responseLavoratori.data.data)

        }
    

        if (sessionStorage.getItem("Sicurezza") === "1"){// Sicurezza SCADUTI
          loadSicurezza();
          let responseSicurezz = await unsplash.get("analysis", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
            params: { user: sessionStorage.getItem("idUsername"),operazione:"scadenzeAdempimentiUtenteSicurezza" },
          })
          setAdempimentiScadutiUtente(responseSicurezz.data.data)


        }


        if (sessionStorage.getItem("Formazione") === "1"){// Formazione SCADUTI

          let responseLavoratori = await unsplash.get("analysis", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
            params: { user: sessionStorage.getItem("idUsername"),operazione:"scadenzeCorsiUtenteFormazione" },
          })
          setCorsiScadutiUtenti(responseLavoratori.data.data)

        }




        setValue(74);
      } catch (error) {
        console.error(error.message);
      }

      let response = await unsplash.get("utenti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      const user = response.data.data.filter(
        (utente) => sessionStorage.getItem("username") === utente.username
      );
      setUser(user[0]);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let responsePreferiti = await unsplash.get("sedi", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });


        let popup = sessionStorage.getItem("popup");
     
        
        if(popup === "true"){
        
          sessionStorage.setItem("popup",false);
          document.getElementById("popUpGiornaliero").click();
       }
       

        const map = new mapboxgl.Map({
          container: mapContainer.current,
          style: "mapbox://styles/mapbox/streets-v12",
          center: [lng, lat],
          zoom: zoom,
          pitch: 45,
          //bearing: 0,
        });

        map.on("load", function () {
          // Add an image to use as a custom marker
          /* map.loadImage(
            "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png",
            function (error, image) {
              if (error) throw error;
              map.addImage("custom-marker", image); */
          // Add a GeoJSON source with multiple points

          map.loadImage(pinImage, function (error, image) {
            if (error) throw error;
            map.addImage("custom-marker", image);

            map.addSource("points", {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: responsePreferiti.data.geoJson.features,
              },
            });
            // Add a symbol layer
            map.addLayer({
              id: "points",
              type: "symbol",
              source: "points",
              layout: {
                "icon-image": "custom-marker",
                // get the title name from the source's "title" property
                "text-field": ["get", "title"],
                "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                "text-offset": [0, 1.25],
                "text-anchor": "top",
              },
            });

            map.on("click", "points", (e) => {
              // Copy coordinates array.
              const coordinates = e.features[0].geometry.coordinates.slice();
              const description = e.features[0].properties.description;

              // Ensure that if the map is zoomed out such that multiple
              // copies of the feature are visible, the popup appears
              // over the copy being pointed to.
              while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
              }

              new mapboxgl.Popup()
                .setLngLat(coordinates)
                .setHTML(description)
                .addTo(map);
            });

            map.on("dblclick", "points", (e) => {
              // Copy coordinates array.
              /* MODIFICA QUI PER IL DOPPIO CLICK   navigate("/dettaglioSedi", {
                state: {
                  idC: e.features[0].properties.idCliente,
                  RagioneSociale: e.features[0].properties.title,
                  sedeId: e.features[0].properties.idSede,
                  bloccoSede: e.features[0].properties.description,
                },
              }); */
            });

            // Change the cursor to a pointer when the mouse is over the places layer.
            map.on("mouseenter", "points", () => {
              map.getCanvas().style.cursor = "pointer";
            });

            // Change it back to a pointer when it leaves.
            map.on("mouseleave", "points", () => {
              map.getCanvas().style.cursor = "";
            });
          });
        });

        // Add navigation control (the +/- zoom buttons)
        map.addControl(new mapboxgl.NavigationControl(), "top-right");
        map.setLayoutProperty("country-label", "text-field", [
          "get",
          "name_it",
        ]);
        // Clean up on unmount
        return () => map.remove();
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [lng, lat],
      pitch: 60,
      bearing: -60,
      zoom: zoom,
    });

    map.on("load", function () {
      // Add an image to use as a custom marker
      /* map.loadImage(
        "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png",
        function (error, image) {
          if (error) throw error;
          map.addImage("custom-marker", image); */
      // Add a GeoJSON source with multiple points

      map.loadImage(pinImage, function (error, image) {
        if (error) throw error;
        map.addImage("custom-marker", image);

        map.addSource("points", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: geojson.features,
          },
        });
        // Add a symbol layer
        map.addLayer({
          id: "points",
          type: "symbol",
          source: "points",
          layout: {
            "icon-image": "custom-marker",
            // get the title name from the source's "title" property
            "text-field": ["get", "title"],
            "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-offset": [0, 1.25],
            "text-anchor": "top",
          },
        });

        map.on("click", "points", (e) => {
          // Copy coordinates array.
          const coordinates = e.features[0].geometry.coordinates.slice();
          const description = e.features[0].properties.description;

          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(description)
            .addTo(map);
        });

        // Change the cursor to a pointer when the mouse is over the places layer.
        map.on("mouseenter", "points", () => {
          map.getCanvas().style.cursor = "pointer";
        });

        // Change it back to a pointer when it leaves.
        map.on("mouseleave", "points", () => {
          map.getCanvas().style.cursor = "";
        });
      });
    });

    
    // Add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), "top-right");


    // Clean up on unmount
    return () => map.remove();
  }, []);

  return (
    <>
      <PopUpGiornaliero user={user} adempimentiScadutiUtente={adempimentiScadutiUtente} corsiScadutiUtenti={corsiScadutiUtenti} lavoratoriScadutiMedicina={lavoratoriScadutiMedicina}  lavoratoriImportatiDaZucchetti={lavoratoriImportatiZucchetti}/>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <CssBaseline />

      <Grid
        container
      
        sx={{ display: "flex", flexWrap: "wrap", rowGap: "10px" }}
      >
        <Grid
          container
          spacing={2}
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Grid item xs={12} md={8} sx={{ p: 0 }}>
            <Charts tipologia={tipologia} user={user} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid item xs={12} marginBottom={2}>
              <Card
                sx={{
                  boxShadow: "none",
                  backgroundColor: "#11526F",
                  position: "relative",
                }}
              >
                <StarIcon
                  sx={{
                    fontSize: 140,
                    color: "#ffffff",
                    position: "absolute",
                    left: "-17px",
                    bottom: "-27px",
                    color: "rgb(255, 255, 255)",
                    transform: "rotate(25deg)",
                    opacity: 0.35,
                  }}
                />
                <CardHeader
                  sx={{ color: "white" }}
                  titleTypographyProps={{ variant: "h6" }}
                  //avatar={ <StarIcon sx={{color:"#FAFA33"}} />}
                  title="Preferiti"
                  action={<Preferiti loadPreferiti={loadPreferiti} />}
                />
                <CardContent sx={{ p: 0 }}>
                  <Grid
                    container
                    spacing={2}
                    sx={{ mb: preferiti.length === 0 ? 8 : 0 }}
                  >
                    {preferiti.map((p) => {
                      let func = "";

                      if (p.Tipologia === "Cliente") {
                        func = () =>
                          navigate("/dettaglioCliente", {
                            state: {
                              idC: p.Preferito,
                              RagioneSociale: p.RagioneSociale,
                            },
                          });
                      } else {
                        func = () => navigate("/" + p.Preferito);
                      }

                      return (
                        <Grid item xs={8} sx={{ m: "0 auto", p: 0 }}>
                          <Button
                            fullWidth
                            className="ButtonMillerPreferiti"
                            onClick={() => func()}
                          >
                            {p.Descrizione}
                          </Button>
                        </Grid>
                      );
                    })}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card
                sx={{
                  boxShadow: "none",
                  backgroundColor: "#11526F",
                  position: "relative",
                }}
              >
                <AssignmentTurnedInIcon
                  sx={{
                    fontSize: 140,
                    color: "#ffffff",
                    position: "absolute",
                    left: "-17px",
                    bottom: "-27px",
                    color: "rgb(255, 255, 255)",
                    transform: "rotate(25deg)",
                    opacity: 0.35,
                  }}
                />
                <CardHeader
                  titleTypographyProps={{ variant: "h6" }}
                  title={
                    <Badge
                      color="primary"
                      badgeContent={newTasks}
                      sx={{ paddingRight: "5px", color: "white" }}
                    >
                      Tasks
                    </Badge>
                  }
                  action={<AggiungiTask save={createTask} />}
                />

                <CardContent sx={{ paddingBottom: "10px", mx: "auto" }}>
                  {tasks.length > 0 ? (
                    <Tasks tasks={tasks} completaTask={completaTask} />
                  ) : (
                    <span style={{ color: "white", textAlign: "center" }}>
                      Nessuna Task presente
                    </span>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card sx={{ height: "400px", boxShadow: "none", width: "100%" }}>
          
              <div ref={mapContainer} className="map-container" >     </div>
            </Card>
          </Grid>
        </Grid>
        <Grid  container >
              <Grid item xs={12}>
          <Card sx={{ boxShadow: "none" }}>
                <CardHeader
                  titleTypographyProps={{ variant: "h6" }}
                  title="Recap allineamento Paghe"
                />
                <CardContent>
                <LavoratorImportatiZucch lavoratori={lavoratoriImportatiZucchetti} />
                </CardContent>
              </Card>
            </Grid>
            
          </Grid>
        {/* Formazione */}
        {user.Formazione ? (
          <Grid
        
            container
            columnGap={1}
            display="flex"
            sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}
          >
            <Grid xs={12} md={6}>
              <Card sx={{ boxShadow: "none" }}>
                <CardHeader
                  titleTypographyProps={{ variant: "h6" }}
                  title="Prossimi corsi"
                />
                <CardContent>
                  <Box
                    sx={{
                      height: 300,
                      width: 1,
                    }}
                  >
                    <DataGridPro
                      sx={{ fontSize: 12 }}
                      components={{
                        Toolbar: CustomToolbar,
                        Footer: CustomFooterPersonalized,
                      }}
                      slots={{
                        loadingOverlay: LinearProgress,
                      }}
                      loading={loadingCorsi}
                      localeText={
                        itIT.components.MuiDataGrid.defaultProps.localeText
                      }
                      initialState={{ pinnedColumns: { left: ["Actions"] } }}
                      rows={prossimiCorsi}
                      columns={columnsProssimiCorsi}
                      getRowId={(row) => row.ID}
                      pageSize={100}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={12} md={6}>
              <Card sx={{ boxShadow: "none" }}>
                <CardHeader
                  titleTypographyProps={{ variant: "h6" }}
                  title="Corsi Scaduti"
                />

                <CardContent>
                  <Box
                    sx={{
                      height: 300,
                      width: 1,
                    }}
                  >
                    <DataGridPro
                      sx={{ fontSize: 12 }}
                      components={{
                        Toolbar: CustomToolbar,
                        Footer: CustomFooterPersonalized,
                      }}
                      slots={{
                        loadingOverlay: LinearProgress,
                      }}
                      loading={loadingCScad}
                      localeText={
                        itIT.components.MuiDataGrid.defaultProps.localeText
                      }
                      rows={lavoratoriCorsiInScadenza}
                      columns={columnSicurezzaLavoratoriCorsiInScadenza}
                      getRowId={(row) => row.Lavoratore + "-" + row.Ruolo}
                      pageSize={100}
                      disableSelectionOnClick
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        {/* Sicurezza */}
        {user.Sicurezza ? (
          <>
            <Grid container>
              <Grid item xs={12}>
                <Card sx={{ boxShadow: "none" }}>
                  <CardHeader
                    titleTypographyProps={{ variant: "h6" }}
                    title="Adempimenti Scaduti"
                  />

                  <CardContent>
                    <Box
                      sx={{
                        height: 300,
                        width: 1,
                      }}
                    >
                      <DataGridPro
                        initialState={{ pinnedColumns: { left: ["Actions"] } }}
                        sx={{ fontSize: 12 }}
                        components={{
                          Toolbar: CustomToolbar,
                          Footer: CustomFooterPersonalized,
                        }}
                        slots={{
                          loadingOverlay: LinearProgress,
                        }}
                        loading={loadingAdem}
                        localeText={
                          itIT.components.MuiDataGrid.defaultProps.localeText
                        }
                        rows={adempimentiScaduti}
                        columns={columnsAdempimenti}
                        getRowId={(row) => row.ID}
                        pageSize={100}
                        disableSelectionOnClick
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Card sx={{ boxShadow: "none" }}>
                  <CardHeader
                    titleTypographyProps={{ variant: "h6" }}
                    title="Riepilogativo corsi"
                  />
                  <CardContent sx={{ p: 0 }}>
                    <RiepilogativoFormazione />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        ) : (
          <></>
        )}
        {/* Medicina */}

        {user.Medicina ? (
          <>
          <Grid container >
              <Grid item xs={12}>
          <Card sx={{ boxShadow: "none" }}>
                <CardHeader
                  titleTypographyProps={{ variant: "h6" }}
                  title="Lavoratori Scaduti o in Scadenza"
                />
                <CardContent>
                <LavoratoriScadutioInScadenza lavoratori={lavoratoriScadutiMedicina} />
                </CardContent>
              </Card>
            </Grid>
            
          </Grid>
          <Grid  container>
          <Grid item xs={12}>
              <Card sx={{ boxShadow: "none" }}>
                <CardHeader
                  titleTypographyProps={{ variant: "h6" }}
                  title="Riepilogativo visite"
                />
                <CardContent sx={{ p: 0 }}>
                  <RiepilogativoVisite />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          </> ) : (
          <></>
        )}
        {/* Mappa */}
      </Grid>
    </>
  );
};

export default Homepage;

//#region Tasks
const Tasks = (props) => {
  const [tasks, setTasks] = React.useState([]);
  const [priorita, setPriorita] = useState({
    1: "#9E0013",
    2: "#FF2768",
    3: "#FFC897",
    4: "#40C18F",
  });
  const [selezionati, setSelezionati] = useState([]);
  const [commento, setCommento] = useState("");
  const [titolo, setTitolo] = useState("");
  const [assegnatoDa, setAssegnatoDa] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElInfo, setAnchorElInfo] = useState(null);
  const open = Boolean(anchorEl);
  const openInfo = Boolean(anchorElInfo);
  const handleClick = (event, comm, titolo) => {
    setAnchorEl(event.currentTarget);
    setCommento(comm);
    setTitolo(titolo);
  };
  const handleClickInfo = (event, utente) => {
    setAnchorElInfo(event.currentTarget);
    setAssegnatoDa(utente);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElInfo(null);
  };

  const handleToggle = (index, val) => () => {
    let t = [...tasks];

    t[index].Completato = val === 1 ? 0 : 1;

    setTasks(t);

    setSelezionati(t.filter((task) => task.Completato === 1));
  };

  useEffect(() => setTasks(props.tasks), [props.tasks]);

  return (
    <>
      <List
        sx={{
          width: "100%",
          maxHeight: 125,
          backgroundColor: "rgba(255,255,255,0.8)",
          position: "relative",
          overflow: "auto",
          borderRadius: "4px",
        }}
      >
        {tasks.map((el, index) => {
          const labelId = `checkbox-list-label-${el.ID}`;

          return (
            <ListItem
              key={el.ID}
              secondaryAction={
                <ButtonGroup sx={{ background: "white" }}>
                  <IconButton
                    aria-label="comments"
                    onClick={(e) => handleClick(e, el.Commento, el.Titolo)}
                  >
                    <Comment sx={{ color: "#11526F" }} />
                  </IconButton>
                  <IconButton
                    aria-label="comments"
                    onClick={(e) => handleClickInfo(e, el.UtenteAssegnato)}
                  >
                    <Info sx={{ color: "#11526F" }} />
                  </IconButton>
                </ButtonGroup>
              }
              disablePadding
            >
              <ListItemButton
                role={undefined}
                onClick={handleToggle(index, el.Completato)}
                dense
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={el.Completato === 1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={
                    <>
                      <Circle
                        sx={{
                          color: priorita[el.Priorita],
                          width: "10px",
                          height: "10px",
                          marginRight: "5px",
                        }}
                      />{" "}
                      {el.Titolo}{" "}
                    </>
                  }
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>

      <div style={{ display: selezionati.length > 0 ? "" : "None" }}>
        <Grid
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CompletaTask
            selezionati={selezionati}
            save={props.completaTask}
            setSelezionati={setSelezionati}
          />
        </Grid>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Typography sx={{ p: 2 }}>
            <b>{titolo}</b>
            <br />
            {commento}
          </Typography>
        </Popover>
        <Popover
          open={openInfo}
          anchorEl={anchorElInfo}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Typography sx={{ p: 2 }}>Assegnato da: {assegnatoDa}</Typography>
        </Popover>
      </div>
    </>
  );
};

const AggiungiTask = (props) => {
  const [open, setOpen] = useState(false);
  const [task, setTask] = useState({});
  const [utenti, setUtenti] = useState([]);
  const handleClickOpen = async () => {
    let response = await unsplash.get("utenti", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setUtenti(response.data.data);

    setTask({
      Titolo: "",
      Commento: "",
      Priorita: null,
      UtenteAssegnato: null,
      UtenteCreato: sessionStorage.getItem("username"),
    });

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    let t = { ...task };
    t[e.target.name] = e.target.value;
    setTask(t);
  };

  const handleSaveTask = () => {
    props.save(task);
  };

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <Add sx={{ color: "white" }} />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="sm"
        TransitionComponent={Transition}
      >
        <DialogTitle>Crea Task</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                value={task.Titolo}
                margin="dense"
                name="Titolo"
                label="Titolo"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={task.Commento}
                margin="dense"
                name="Commento"
                label="Commento"
                type="text"
                fullWidth
                multiline
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Assegna A</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={task.UtenteAssegnato}
                  label="Assegna A"
                  name="UtenteAssegnato"
                  onChange={handleChange}
                >
                  {utenti.map((u) => (
                    <MenuItem key={u.username} value={u.username}>
                      {u.username}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Priorità</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={task.Priorita}
                  name="Priorita"
                  label="Assegna A"
                  onChange={handleChange}
                >
                  <MenuItem value={1}>
                    <Circle
                      sx={{
                        color: "#9E0013",
                        width: "10px",
                        height: "10px",
                        marginRight: "5px",
                      }}
                    />{" "}
                    Urgente
                  </MenuItem>
                  <MenuItem value={2}>
                    <Circle
                      sx={{
                        color: "#FF2768",
                        width: "10px",
                        height: "10px",
                        marginRight: "5px",
                      }}
                    />{" "}
                    Alta
                  </MenuItem>
                  <MenuItem value={3}>
                    <Circle
                      sx={{
                        color: "#FFC897",
                        width: "10px",
                        height: "10px",
                        marginRight: "5px",
                      }}
                    />{" "}
                    Media
                  </MenuItem>
                  <MenuItem value={4}>
                    <Circle
                      sx={{
                        color: "#40C18F",
                        width: "10px",
                        height: "10px",
                        marginRight: "5px",
                      }}
                    />{" "}
                    Bassa
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between", mx: 2 }}
        >
          <Button
            onClick={handleClose}
            className="ButtonMiller"
            variant="contained"
            startIcon={<Close />}
          >
            Annulla
          </Button>
          <Button
            onClick={handleSaveTask}
            className="ButtonMiller"
            variant="contained"
            startIcon={<Check />}
          >
            Crea
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const CompletaTask = (props) => {
  const [open, setOpen] = useState(false);
  const [task, setTask] = useState({});

  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveTask = () => {
    props.save(props.selezionati);
    props.setSelezionati([]);
  };

  return (
    <>
      <Button
        className="ButtonMillerPreferiti"
        onClick={handleClickOpen}
        startIcon={<Check />}
        size="small"
        sx={{ mt: 1 }}
      >
        Completa
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="sm"
        TransitionComponent={Transition}
      >
        <DialogTitle>Completa Task</DialogTitle>
        <DialogContent>
          Sei sicuro di voler segnare come completati i task selezionati?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            className="ButtonMiller"
            variant="contained"
          >
            Annulla
          </Button>
          <Button
            onClick={handleSaveTask}
            className="ButtonMiller"
            variant="contained"
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const LavoratorImportatiZucch = (props) => {
  const columnsLavoratori= [
    { field: "Nome", headerName: "Nome", flex: 1 },
    { field: "Cognome", headerName: "Cognome", flex: 1 },
    { field: "RagioneSociale", headerName: "Cliente", flex: 1 },
    { field: "Tipologia", headerName: "Movimento", flex: 1 },
    { field: "DataImportazione", headerName: "Data", flex: 1,renderCell:(params) => {return (params.row.DataImportazione?moment(params.row.DataImportazione).format("DD/MM/YYYY") :"")}},

  ];
  
const [lavoratori,setLavoratoriScaduti] = useState([])

useEffect( () => {

setLavoratoriScaduti(props.lavoratori)
},[props.lavoratori])

return(
  <Box
  sx={{
    height: 300,
    width: 1,
  }}
>
  <DataGridPro
    sx={{ fontSize: 12 }}
    
    slots={{
      loadingOverlay: LinearProgress,
    }}
   // loading={loadingCScad}
    localeText={
      itIT.components.MuiDataGrid.defaultProps.localeText
    }
    rows={lavoratori}
    columns={columnsLavoratori}
    getRowId={(row) => row.CF}
    pageSize={100}
    disableSelectionOnClick
  />



  
</Box>
)



}

const LavoratoriScadutioInScadenza = (props) => {
  const columnsLavoratoriScadutioInScadenza = [
    {
      field: "StatoVisita",
      headerName: "Stato",
      width: 20,
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <div>
          {params.row.StatoVisita === "OK" ? (
            <CheckCircle sx={{ color: "#40C18F" }} />
          ) : (
            <Error
              sx={{
                color:
                  params.row.StatoVisita === "Scadenza" || params.row.StatoVisita === "InScadenza"
                    ? "#FFC897"
                    : params.row.StatoVisita === "Parziale"
                    ? "#2265B1"
                    : "#FF2768",
              }}
            />
          )}
        </div>
      ),
    },
    { field: "Nome", headerName: "Nome", flex: 1 },
    { field: "Cognome", headerName: "Cognome", flex: 1 },
    { field: "RagioneSociale", headerName: "Cliente", flex: 1 },
    { field: "Scadenza", headerName: "Scadenza", width: 90 },

  ];
  
const [lavoratori,setLavoratoriScaduti] = useState([])

useEffect( () => {

setLavoratoriScaduti(props.lavoratori)
},[props.lavoratori])

return(
  <Box
  sx={{
    height: 300,
    width: 1,
  }}
>
  <DataGridPro
    sx={{ fontSize: 12 }}
    
    slots={{
      loadingOverlay: LinearProgress,
    }}
   // loading={loadingCScad}
    localeText={
      itIT.components.MuiDataGrid.defaultProps.localeText
    }
    rows={lavoratori}
    columns={columnsLavoratoriScadutioInScadenza}
    getRowId={(row) => row.CF}
    pageSize={100}
    disableSelectionOnClick
  />



  
</Box>
)



}


const CorsiScadutiUtente = (props) => {
  const columnsLavoratori= [
    { field: "Nome", headerName: "Nome", flex: 1 },
    { field: "Cognome", headerName: "Cognome", flex: 1 },
    { field: "RagioneSociale", headerName: "Cliente", flex: 1 },
    { field: "Ruolo", headerName: "Corso", flex: 1 },
    { field: "Scadenza", headerName: "Scadenza", flex: 1,renderCell:(params) => {return (params.row.Scadenza?moment(params.row.Scadenza).format("DD/MM/YYYY") :"")}},

  ];
  
const [lavoratori,setLavoratoriScaduti] = useState([])

useEffect( () => {

setLavoratoriScaduti(props.lavoratori)
},[props.lavoratori])

return(
  <Box
  sx={{
    height: 300,
    width: 1,
  }}
>
  <DataGridPro
    sx={{ fontSize: 12 }}
    
    slots={{
      loadingOverlay: LinearProgress,
    }}
   // loading={loadingCScad}
    localeText={
      itIT.components.MuiDataGrid.defaultProps.localeText
    }
    rows={lavoratori}
    columns={columnsLavoratori}
    getRowId={(row) => row.ID + row.Ruolo}
    pageSize={100}
    disableSelectionOnClick
  />



  
</Box>
)



}

const AdempimentiScadutiUtente = (props) => {
  const columnsLavoratori= [
    { field: "Descrizione", headerName: "Adempimento", flex: 1 },
    { field: "Scadenza", headerName: "Scadenza (Mesi)", flex: 1 },
    { field: "RagioneSociale", headerName: "Cliente", flex: 1 },
    { field: "UltimaScadenza", headerName: "UltimaScadenza", flex: 1,renderCell:(params) => {return (params.row.UltimaScadenza?moment(params.row.UltimaScadenza).format("DD/MM/YYYY") :"")}},

  ];
  
const [lavoratori,setLavoratoriScaduti] = useState([])

useEffect( () => {

setLavoratoriScaduti(props.lavoratori)
},[props.lavoratori])

return(
  <Box
  sx={{
    height: 300,
    width: 1,
  }}
>
  <DataGridPro
    sx={{ fontSize: 12 }}
    
    slots={{
      loadingOverlay: LinearProgress,
    }}
   // loading={loadingCScad}
    localeText={
      itIT.components.MuiDataGrid.defaultProps.localeText
    }
    rows={lavoratori}
    columns={columnsLavoratori}
    getRowId={(row) => row.IDCliente + row.ID}
    pageSize={100}
    disableSelectionOnClick
  />



  
</Box>
)



}




//#endregion Tasks
const PopUpGiornaliero = (props) => {
  const [open, setOpen] = useState(false);
  const [task, setTask] = useState({});
  const [lavoratori, setLavoratori] = useState([]);


  const handleClickOpen = async () => {
    let responseLavoratori = await unsplash.get("lavoratori", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: sessionStorage.getItem("idUsername"),popup:true },
    })

    setLavoratori(responseLavoratori.data.data)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const columnsLavoratori= [
    { field: "Nome", headerName: "Nome", flex: 1 },
    { field: "Cognome", headerName: "Cognome", flex: 1 },
    { field: "RagioneSociale", headerName: "Cliente", flex: 1 },

  ];



  return (
    <>
      <IconButton onClick={handleClickOpen}  id="popUpGiornaliero">
        <Add sx={{ color: "white",display:"none"}} />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <DialogTitle>Recap Giornaliero <Warning  fontSize="large"  className="animated-icon"/></DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
           
          <Grid item xs={12}>
          <b>Lavoratori inseriti in piattaforma</b>
          <Box
  sx={{
    height: 300,
    width: 1,
  }}
>
  <DataGridPro
    sx={{ fontSize: 12 }}
    
    slots={{
      loadingOverlay: LinearProgress,
    }}
   // loading={loadingCScad}
    localeText={
      itIT.components.MuiDataGrid.defaultProps.localeText
    }
    rows={lavoratori}
    columns={columnsLavoratori}
    getRowId={(row) => row.CF}
    pageSize={100}
    disableSelectionOnClick
  />



  
</Box>


          </Grid>
          <Grid item xs={12}>
          <b>Recap allineamento Paghe</b>
          <LavoratorImportatiZucch lavoratori={props.lavoratoriImportatiDaZucchetti} />


          </Grid>
          {sessionStorage.getItem("Medicina") ==="1" ? (
          <Grid item xs={12}>
          <b>Lavoratori in scadenza/scaduti</b>
          <LavoratoriScadutioInScadenza lavoratori={props.lavoratoriScadutiMedicina} />


          </Grid>):""}

          {sessionStorage.getItem("Formazione") ==="1" ? (
          <Grid item xs={12}>
          <b>Corsi in scadenza/scaduti</b>
          <CorsiScadutiUtente lavoratori={props.corsiScadutiUtenti} />


          </Grid>):""}
          {sessionStorage.getItem("Sicurezza") ==="1" ? (
          <Grid item xs={12}>
          <b>Adempimenti in scadenza/scaduti</b>
          <AdempimentiScadutiUtente lavoratori={props.adempimentiScadutiUtente} />

           
          </Grid>):""}
          
           
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between", mx: 2 }}
        >
          <Button
            onClick={handleClose}
            className="ButtonMiller"
            variant="contained"
            startIcon={<Close />}
          >
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};