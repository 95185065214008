import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import unsplash from "../../api/unsplash";
import {
  Button,
  StepButton,
  Box,
  Step,
  Stepper,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  Check,
  Settings,
  GroupAdd,
  VideoLabel,
  ArrowBack,
  ArrowForward,
} from "@mui/icons-material";

import ReactPlayer from "react-player";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <Settings />,
    2: <GroupAdd />,
    3: <VideoLabel />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = [
  "Video Introduzione Iter",
  "Video Prima Survey",
  "Video Seconda Survey",
];

const VideoParita = (props) => {
  const navigate = useNavigate();
  const [visite, setVisite] = useState([]);
  const [visitaSel, setVisitaSel] = useState(null);

  const [globalStep, setGlobalStep] = useState(0);
  const [handleLocked, setHandleLocked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = React.useState({});

  const [value, setValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const locked = (isZero) => {
    const cont = document?.getElementById("step-video");
    return (
      <div>
        <div className="absolute inset-0 z-50 w-full h-full bg-white/80 backdrop-blur-0">
          <div className="absolute left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] flex flex-col gap-5">
            <div className="container">
              <span
                onMouseOver={() => setHandleLocked(true)}
                onMouseOut={() => setHandleLocked(false)}
                onClick={() => {
                  navigate("/AreaParita/Home");
                }}
                className={`lock ${handleLocked ? "unlocked" : ""}`}
              ></span>
            </div>
            <div className="text-xl font-bold text-center">
              NON DISPONIBILE
              <br />
              NECESSARIO COMPLETARE LO STEP:
              <div
                className="text-primary-600 cursor-pointer underline"
                onClick={() => {
                  navigate("/AreaParita/Home");
                }}
              >
                {isZero
                  ? "1 - Video Introduzione Iter"
                  : globalStep +
                    1 +
                    " - " +
                    steps[globalStep === 0 ? 0 : globalStep]}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const mainContent = (step) => {
    let content;
    switch (step) {
      case 0:
        content = (
          <>
            {!globalStep && locked(!globalStep)}
            <ReactPlayer
              url="https://www.youtube.com/watch?v=Tu8qvD2PlVQ&pp=ygUMbWlsbGVyIGdyb3Vw"
              width={"100%"}
              //height={"300px"}
              style={{ borderRadius: "10px" }}
            />
          </>
        );

        break;
      case 1:
        content = (
          <>
            {(globalStep === 0 || !globalStep) && locked(!globalStep)}
            <ReactPlayer
              url="https://www.youtube.com/watch?v=Tu8qvD2PlVQ&pp=ygUMbWlsbGVyIGdyb3Vw"
              width={"100%"}
              //height={"300px"}
              style={{ borderRadius: "10px" }}
            />
          </>
        );

        break;
      case 2:
        content = (
          <>
            {(globalStep === 0 || globalStep === 1 || !globalStep) &&
              locked(!globalStep)}
            <ReactPlayer
              url="https://www.youtube.com/watch?v=Tu8qvD2PlVQ&pp=ygUMbWlsbGVyIGdyb3Vw"
              width={"100%"}
              //height={"300px"}
              style={{ borderRadius: "10px" }}
            />
          </>
        );

        break;
      default:
        break;
    }
    return content;
  };

  useEffect(() => {
    (async () => {
      let stepGetted;
      let response = await unsplash.post(
        "portaleParita",
        {
          operation: "getCliente",
          id: sessionStorage.getItem("Cliente"),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      stepGetted = response.data[0]?.Step;
      setGlobalStep(stepGetted);
    })();
  }, []);

  return (
    <>
      <div className="px-8 ">
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Tabs
            value={value}
            textColor="inherit"
            variant="scrollable"
            onChange={handleChangeTab}
            aria-label="basic tabs example"
          >
            <Tab label="Video Introduzione Iter" />
            <Tab label="Video Prima Survey" />
            <Tab label="Video Seconda Survey" />
          </Tabs>
        </Box>
        <div className="relative">
          <TabPanel value={value} index={0}>
            {globalStep === 0 && locked(true)}
            <ReactPlayer
              url="https://www.youtube.com/watch?v=Tu8qvD2PlVQ&pp=ygUMbWlsbGVyIGdyb3Vw"
              width={"100%"}
            />
          </TabPanel>
        </div>
        <div className="relative">
          <TabPanel value={value} index={1}>
            {globalStep < 2 && locked(false)}
            <ReactPlayer
              url="https://www.youtube.com/watch?v=Tu8qvD2PlVQ&pp=ygUMbWlsbGVyIGdyb3Vw"
              width={"100%"}
            />
          </TabPanel>
        </div>
        <div className="relative">
          <TabPanel value={value} index={2}>
            {globalStep < 4 && locked(false)}
            <ReactPlayer
              url="https://www.youtube.com/watch?v=Tu8qvD2PlVQ&pp=ygUMbWlsbGVyIGdyb3Vw"
              width={"100%"}
            />
          </TabPanel>
        </div>
        {/* <Box sx={{ width: "100%", px: 4 }}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <div>
            <React.Fragment>
              <div
                className="relative h-[60vh] max-h-[60vh] md:h-[55vh] md:max-h-[55vh] 2xl:h-[70vh] 2xl:max-h-[70vh] mt-5 overflow-y-auto"
                id="step-video"
              >
                {mainContent(activeStep)}
              </div>
              <div className="flex flex-row pt-2 gap-2">
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{
                    backgroundColor: activeStep === 0 ? "white" : "#11526f",
                  }}
                  variant={"contained"}
                  className={activeStep !== 0 && "ButtonMiller"}
                  startIcon={<ArrowBack />}
                >
                  Indietro
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button
                  onClick={handleNext}
                  variant="contained"
                  className="ButtonMiller"
                  startIcon={<ArrowForward />}
                >
                  Avanti
                </Button>
              </div>
            </React.Fragment>
          </div>
        </Box> */}
      </div>
    </>
  );
};

export default VideoParita;
