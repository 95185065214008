import React, { useEffect, useState } from "react";
import unsplash from "../../api/unsplash";
import {
  InputAdornment,
  Button,
  CircularProgress,
  TextField,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import "../../style/style.css";
import GlobalSnackbar from "../Snackbar";
import { Check } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Search } from "@mui/icons-material";

const FatturazioneMedico = (props) => {
  const [clientiMedico, setClientiMedico] = useState([]);
  const [clientiMedicoVisibili, setClientiMedicoVisibili] = useState([]);
  const [clientiMedicoAPI, setClientiMedicoAPI] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const columns = [
    {
      field: "RagioneSociale",
      headerName: "RagioneSociale",
      //width: 400,
      flex: 1,
      headerAlign: "Left",
      align: "Left",
      sort: "asc",
    },
    {
      field: "Tariffa",
      headerName: "Tariffa",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      editable: true,
      renderCell: (params) => {
        return <div>{params.row.Tariffa ? params.row.Tariffa : "0,00"}</div>;
      },
    },
  ];

  const handleFilter = (e) => {
    // filtro

    if (e.target.value == "") {
      setClientiMedicoVisibili(clientiMedico);
    } else {
      var clientiX = []; // popolo l'array
      var i;
      for (i = 0; i < clientiMedico.length; i++) {
        if (
          clientiMedico[i].RagioneSociale.toLowerCase().includes(
            e.target.value.toLowerCase()
          )
        ) {
          clientiX.push(clientiMedico[i]);
        }
      }
      setClientiMedicoVisibili(clientiX);
    }
  };

  useEffect(() => {
    async function fetchData() {
      let responseClientiMedico = await unsplash.get(
        "medici",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { medico: props.medico },
        } //props.idAzienda}}
      );

      setClientiMedico(responseClientiMedico.data.data);
      setClientiMedicoVisibili(responseClientiMedico.data.data);
    }

    fetchData();
  }, []);

  const handleSave = async () => {
    try {
      const responseClientiMedici = await unsplash.put(
        "medici",
        { clientiMedico: clientiMedicoAPI },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({
        severity: "success",
        messaggio: "Fatturazione inserita con successo",
      });
      setOpenSnack(true);
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante il salvataggio",
      });
      setOpenSnack(true);
    }
  };

  const handleSaveTariffe = async (index) => {
    try {
      const responseClientiMedici = await unsplash.put(
        "medici",
        {
          tariffeMedicoCliente: clientiMedicoVisibili[index].Tariffe,
          cliente: clientiMedicoVisibili[index].IDCliente,
          idMedico: props.medico,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({
        severity: "success",
        messaggio: "Tariffe salvate con successo",
      });
      setOpenSnack(true);
      loadData();
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante il salvataggio",
      });
      setOpenSnack(true);
    }
  };

  const loadData = async () => {
    setClientiMedico([]);
    let responseClientiMedico = await unsplash.get(
      "medici",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { medico: props.medico },
      } //props.idAzienda}}
    );

    setClientiMedico(responseClientiMedico.data.data);
    setClientiMedicoVisibili(responseClientiMedico.data.data);
  };

  return (
    <div>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      {clientiMedico.length === 0 ? (
        <CircularProgress />
      ) : (
        <>
          <div>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <TextField
                placeholder="Cerca ..."
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                size="small"
                fullWidth
                id="input-with-icon-textfield"
                onChange={handleFilter}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
            </Grid>
          </div>

          {/* 
<IconButton aria-label="edit">
    <Add 
    style={{height:"2rem",width:"2rem",color:"#2265B1"}}
    onClick= {() => document.getElementById("add").click()}
      />
  </IconButton>
*/}

          <div>
            {clientiMedicoVisibili.map((cli, index) => (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="AccordionMiller"
                >
                  <Typography>{cli.RagioneSociale}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {cli.Tariffe.map((acc, ind) => (
                      <>
                        <Grid item xs={6} lg={4}>
                          {acc.Accertamento}
                        </Grid>{" "}
                        <Grid item xs={4} lg={2}>
                          <input
                            value={acc.Tariffa}
                            size="6"
                            onChange={(e) => {
                              let cliente = [...clientiMedicoVisibili];
                              cliente[index].Tariffe[ind].Tariffa =
                                e.target.value;
                              setClientiMedicoVisibili(cliente);
                            }}
                          ></input>
                        </Grid>
                      </>
                    ))}
                  </Grid>
                  {cli.Tariffe.length > 0 ? (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}
                    >
                      <Button
                        label="Salva"
                        variant="contained"
                        className="ButtonMiller"
                        onClick={() => handleSaveTariffe(index)}
                        startIcon={<Check />}
                      >
                        Salva
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
          {/*<Button onClick={handleSave} variant="contained" className="ButtonMiller" sx={{marginTop:"10px"}}>Salva</Button>*/}
        </>
      )}
    </div>
  );
};

export default FatturazioneMedico;

/*

SELECT * FROM 
(SELECT
	RagioneSociale,Descrizione,IDCliente,Accertamenti.ID as IDAccertamento,Medico
FROM
	(
	SELECT
		Rel_ClientiMedici.*,
		RagioneSociale,
		Clienti.ID AS IDCliente 
	FROM
		Rel_ClientiMedici
		JOIN Clienti ON Rel_ClientiMedici.Cliente = Clienti.ID 
	WHERE
		Rel_ClientiMedici.Medico = 16
	ORDER BY
		RagioneSociale ASC 
	) AS t
	JOIN ProtocolloSanitario ON ProtocolloSanitario.Cliente = t.IDCliente
	JOIN Accertamenti ON Accertamenti.ID = ProtocolloSanitario.Accertamenti 
GROUP BY
	RagioneSociale,
	Descrizione ) as t LEFT JOIN TariffeMedicoClienteAccertamenti on t.IDCliente = TariffeMedicoClienteAccertamenti.Cliente AND t.IDAccertamento = TariffeMedicoClienteAccertamenti.Accertamento AND TariffeMedicoClienteAccertamenti.Medico = t.Medico

*/
