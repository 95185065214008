import React, { useEffect, useState } from "react";
import unsplash from "../api/unsplash";
import { useNavigate } from "react-router-dom";
import {
  InputLabel,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
  Button,
  TextField,
  DialogActions,
  Select,
  OutlinedInput,
  MenuItem,
  FormControl,
  Card,
  Grid,
  CardContent,
  ButtonGroup,
  Zoom,
} from "@mui/material";
import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
 GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import {
  Add,
  Circle,
  HealthAndSafety,
  Search,
  MenuBook,
  Star,
  MoreVert,
  ExpandMore,
  Check,
  Close,
} from "@mui/icons-material";
import GlobalSnackbar from "./Snackbar";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const columns = [
  {
    field: "new",
    headerName: "",
    width: 10,
    headerAlign: "center",
    align: "center",
    sort: "asc",
    renderCell: (
      params //mi aiuta a mettere il valore
    ) => (
      <>
        {params.row.new ? (
          <Circle sx={{ color: "#40C18F", width: "10px", height: "10px" }} />
        ) : (
          ""
        )}
      </>
    ),
  },
  {
    field: "RagioneSociale",
    headerName: "RagioneSociale",
    //width: 400,
    flex: 1,
    headerAlign: "center",
    align: "center",
    sort: "asc",
  },
  {
    field: "SedeIndirizzo",
    headerName: "Indirizzo",
    //width: 300,
    flex: 1,
    headerAlign: "center",
    align: "center",
    sort: "asc",
  },
  {
    field: "SedeLocalita",
    headerName: "Località",
    //width: 300,
    flex: 1,
    headerAlign: "center",
    align: "center",
    sort: "asc",
  },
  {
    field: "SedeCAP",
    headerName: "Cap",
    //width: 300,
    flex: 1,
    headerAlign: "center",
    align: "center",
    sort: "asc",
  },
];

const Preferiti = (props) => {
  const [open, setOpen] = useState(false);
  const [cliente, setCliente] = useState();
  const [validation, setValidation] = useState();
  const [preferiti, setPreferiti] = useState([]);
  const [pagine, setPagine] = useState([
    "Allegato3b",
    "AnagraficaCorsi",
    "Clienti",
    "Corsi",
    "CorsiAttestati",
    "Formatori",
    "Medici",
    "PrefatturazioneCorsi",
    "PrefatturazioneMedicina",
    "Scadenzario",
    "VisiteMediche",
  ]);
  const [clienti, setClienti] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleClickOpen = async () => {
    let response = await unsplash.get("clienti", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setClienti(response.data.data);

    let responsePreferiti = await unsplash.get("preferiti", {
      params: { user: sessionStorage.getItem("username") },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    let pref = responsePreferiti.data.data;

    for (let i = responsePreferiti.data.data.length; i < 3; i++) {
      pref.push({
        Preferito: "",
        Tipologia: "Pagina",
        RagioneSociale: null,
        Descrizione: "",
      });
    }

    setPreferiti(pref);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeTipo = (e, index) => {
    let p = [...preferiti];
    p[index].Tipologia = e.target.value;
    p[index].Preferito = "";
    p[index].IDAggiuntivo = null;
    p[index].Descrizione = "";
    p[index].RagioneSociale = null;
    setPreferiti(p);
  };

  const handleChangePreferito = (e, index) => {
    let p = [...preferiti];

    if (p[index].Tipologia === "Cliente") {
      p[index].Preferito = e.target.value;
      let ragSoc = clienti.filter((c) => c.ID === e.target.value)[0]
        .RagioneSociale;
      p[index].Descrizione = ragSoc;
      p[index].RagioneSociale = ragSoc;
    } else {
      p[index].Preferito = e.target.value;
      p[index].Descrizione = e.target.value;
    }

    //p[index].IDAggiuntivo = null;

    setPreferiti(p);
  };

  const handleChangeDescrizione = (e, index) => {
    let p = [...preferiti];
    p[index].Descrizione = e.target.value;
    setPreferiti(p);
  };

  const handleSave = async () => {
    try {
      let response = await unsplash.post(
        "preferiti",
        { preferiti: preferiti, utente: sessionStorage.getItem("username") },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      props.loadPreferiti();
      setSnack({
        severity: "success",
        messaggio: "Preferito creato con successo",
      });
      setOpenSnack(true);
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante la creazione del preferito",
      });
      setOpenSnack(true);
    }
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <IconButton aria-label="settings" onClick={handleClickOpen}>
        <MoreVert sx={{ color: "white" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="md"
        TransitionComponent={Transition}
      >
        <DialogTitle>Gestione preferiti</DialogTitle>
        <DialogContent>
          {preferiti.map((p, index) => (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore sx={{ color: "white" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="AccordionMiller"
              >
                <Typography>{"Preferito " + (index + 1)} </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  spacing={2}
                  key={index}
                  sx={{ marginTop: "10px" }}
                >
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel>Tipo</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        value={p.Tipologia}
                        label="Tipo"
                        onChange={(e) => handleChangeTipo(e, index)}
                      >
                        <MenuItem value={"Pagina"}>Pagina</MenuItem>
                        <MenuItem value={"Cliente"}>Cliente</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel>Preferito</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        value={p.Preferito}
                        label="Preferito"
                        onChange={(e) => handleChangePreferito(e, index)}
                      >
                        {preferiti[index].Tipologia === "Cliente"
                          ? clienti.map((c) => (
                              <MenuItem value={c.ID}>
                                {c.RagioneSociale}
                              </MenuItem>
                            ))
                          : pagine.map((p) => (
                              <MenuItem value={p}>{p}</MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      sx={{ marginTop: "0px" }}
                      
                      margin="dense"
                      name="Descrizione"
                      label="Descrizione"
                      type="text"
                      fullWidth
                      value={p.Descrizione}
                      onChange={(e) => handleChangeDescrizione(e, index)}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mx: 2,
          }}
        >
          <Button
            onClick={handleClose}
            className="ButtonMiller"
            variant="contained"
            startIcon={<Close />}
            >
            Annulla
          </Button>
          <Button
            onClick={handleSave}
            className="ButtonMiller"
            variant="contained"
            startIcon={<Check />}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default Preferiti;
