import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	TextField,
	Button,
	Typography,
	Avatar,
	Grid,
	Paper,
	Skeleton,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import koala from "../assets/images/logo_Koala_min.png";
import unsplash from "../api/unsplash";
import { useLocation } from "react-router-dom";

const Chatbot = () => {
	const location = useLocation();
	const [input, setInput] = useState("");
	const [newQ, setNewQ] = useState(false);
	const bottomRef = useRef(null);
	const [messages, setMessages] = useState([
		{
			role: "assistant",
			content:
				"Ciao, sono KoAI, il tuo collega virtuale. Come posso aiutarti oggi? Chiedimi qualsiasi cosa!",
		},
	]);

	const handleSend = () => {
		if (input.trim() !== "") {
			setInput("");
		}
		let m = [...messages];
		m.push({ role: "user", content: input });
		setMessages(m);
		setNewQ(true);
		askQuestion(m);
	};

	const askQuestion = async (m) => {
		const response = await unsplash.post(
			"chatbot",
			{
				messages: m,
				username: sessionStorage.getItem("username"),
				id: location.state.idC,
			},
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem("jwt")}`,
				},
			}
		);
		m.push(response.data.response);
		setMessages(m);
		setNewQ(false);
		bottomRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	const handleInputChange = (event) => {
		setInput(event.target.value);
	};

	useEffect(() => {
		// 👇️ scroll to bottom every time messages change
		bottomRef.current?.scrollIntoView({ behavior: "smooth" });
	}, [messages]);

	return (
		<Box
			sx={{
				height: { xs: "82vh", md: "72vh", xl: "82vh" },
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Box sx={{ flexGrow: 1, p: 2, overflow: "auto" }} id="messages-container">
				{messages.map((message) => (
					<Message message={message} />
				))}
				<div ref={bottomRef} />
				{newQ ? (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-start",
							mb: 2,
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<Avatar src={koala} sx={{ width: 56, height: 56 }} />
							<Paper
								variant="outlined"
								sx={{
									p: 2,
									ml: 1,
									mr: 0,
									backgroundColor: "#11526f",
									borderRadius: "20px 20px 20px 5px",
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
									}}
								>
									<Skeleton
										sx={{ bgcolor: "white", marginRight: "3px" }}
										variant="circular"
										width={10}
										height={10}
									/>
									<Skeleton
										sx={{ bgcolor: "white", marginRight: "3px" }}
										variant="circular"
										width={10}
										height={10}
									/>
									<Skeleton
										sx={{ bgcolor: "white", marginRight: "3px" }}
										variant="circular"
										width={10}
										height={10}
									/>
								</Box>
							</Paper>
						</Box>
					</Box>
				) : (
					""
				)}
			</Box>
			<Box
				sx={{
					p: 2,
					backgroundColor: "background.default",
					borderRadius: "8px",
				}}
				bottom="0px"
			>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						if (input !== "") {
							handleSend();
						}
					}}
					style={{ width: "100%" }}
				>
					<Grid container spacing={2}>
						<Grid item xs={10}>
							<TextField
								size="small"
								fullWidth
								label="Il tuo messaggio"
								variant="outlined"
								value={input}
								onChange={handleInputChange}
							/>
						</Grid>
						<Grid item xs={2}>
							<Button
								fullWidth
								className="ButtonMiller"
								color="primary"
								variant="contained"
								endIcon={<SendIcon />}
								onClick={() => {
									if (input !== "") {
										handleSend();
									}
								}}
							>
								Invia
							</Button>
						</Grid>
					</Grid>
				</form>
			</Box>
		</Box>
	);
};

const Message = ({ message }) => {
	const isBot = message.role === "assistant";

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: isBot ? "flex-start" : "flex-end",
				mb: 2,
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: isBot ? "row" : "row-reverse",
					alignItems: "center",
					overflow: "auto",
				}}
			>
				<Avatar
					src={isBot ? koala : localStorage.getItem("fotoProfilo")}
					sx={{ width: 56, height: 56 }}
				/>
				<Paper
					variant="outlined"
					sx={{
						p: 2,
						ml: isBot ? 1 : 0,
						mr: isBot ? 0 : 1,
						backgroundColor: isBot ? "#11526f" : "#8E75AC",
						borderRadius: isBot ? "20px 20px 20px 5px" : "20px 20px 5px 20px",
					}}
				>
					{/* Render each line of the content separately */}
					{message.content.split("\n").map((line, index) => (
						<Typography key={index} variant="body1" sx={{ color: "#ffffff" }}>
							{line}
						</Typography>
					))}
				</Paper>
			</Box>
		</Box>
	);
};

export default Chatbot;
