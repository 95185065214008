import React, { useEffect, useState } from "react";
import unsplash from "../api/unsplash";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import GlobalSnackbar from "./Snackbar";
import {
  IconButton,
  Button,
  ButtonGroup,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Autocomplete,
  Box,
  Typography,
  Zoom,
  LinearProgress,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import "../style/style.css";
import { Edit, Delete, Add, Close, Check, Print } from "@mui/icons-material";
import "jspdf-autotable";
import PropTypes from "prop-types";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { fontVerdanaNormal } from "../style/verdana-normalW";
import { fontVerdanaBold } from "../style/verdana-bold";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const CorsiSede = (props) => {
  const [corsiSede, setCorsiSede] = useState([]);
  const [loading, setLoading] = useState(true);
  const [corsi, setCorsi] = useState();
  const [vistaCorsi, setVistaCorsi] = useState();
  const [openSnack, setOpenSnack] = useState(false);
  const [corsoSel, setCorsoSel] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [tabellaFormazione, setTabellaFormazione] = useState([]);
  const [loadingStampeBk, setLoadingStampeBk] = useState(false);
  const [loadingStampe, setLoadingStampe] = useState(false);
  const [personeFormazione, setPersoneFormazione] = useState({
    Datore: null,
    RSPP: null,
    RLS: null,
  });

  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const toggleAlert = async (reload) => {
    if (reload) {
      setOpenAlert(!openAlert);
      let responsecorsiSede = await unsplash.get(
        "corsiSede",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { cliente: props.idSede },
        } //props.idSede}}
      );

      setCorsiSede(responsecorsiSede.data.data);
    } else {
      setOpenAlert(!openAlert);
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <FormDialog
            id="edit"
            operazione="Modifica"
            corsiSede={corsiSede}
            corsi={corsi}
            reloadData={loadData}
            saveFunction={handleSaveData}
            corsoSel={params.row}
          />
          <IconButton
            aria-label="delete"
            onClick={async () => {
              await setVistaCorsi(params.row);
              toggleAlert();
            }}
          >
            <Delete
              style={{ height: "2rem", width: "2rem", color: "#FF2768" }}
            />
          </IconButton>
        </>
      ),
    },
    {
      field: "Codice",
      headerName: "Codice",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Descrizione",
      headerName: "Descrizione",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
  ];

  const loadData = async () => {
    let responsecorsiSede = await unsplash.get("corsiSede", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { cliente: props.idSede },
    });
    let responsecorsiCliente = await unsplash.get("corsiCliente", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { cliente: props.idAzienda },
    });
    setCorsiSede(responsecorsiSede.data.data);

    const differenceCorsiClienti = responsecorsiCliente.data.data?.filter(
      (el) =>
        responsecorsiSede.data.data
          ?.map((mans) => mans.IDRel_ClientiCorsi)
          .indexOf(el.ID) === -1
    );
    setCorsi(differenceCorsiClienti);

    let response = await unsplash.get("organigrammi", {
      params: { sede: props.sede, cliente: props.idCliente },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    let ruoli = { ...personeFormazione };
    response.data.data.map((el) => {
      if (el.IDRuolo === 1) {
        ruoli.Datore = el.Nominativo;
      }
      if (el.IDRuolo === 2) {
        ruoli.RSPP = el.Nominativo;
      }
      if (el.IDRuolo === 4) {
        ruoli.RLS = el.Nominativo ? el.Nominativo : "";
      }
      setPersoneFormazione(ruoli);
    });

    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      loadData();
    })();
  }, []);

  const loadPianoFormazione = async () => {
    if (!personeFormazione.Datore) {
      setSnack({
        severity: "error",
        messaggio: "Datore di lavoro mancante",
      });
      setOpenSnack(true);
    } else if (personeFormazione.RLS === null) {
      setSnack({
        severity: "error",
        messaggio: "RLS mancante",
      });
      setOpenSnack(true);
    } else if (!personeFormazione.RSPP) {
      setSnack({
        severity: "error",
        messaggio: "RSPP mancante",
      });
      setOpenSnack(true);
    } else {
      setLoadingStampe(true);
      setLoadingStampeBk(true);
      let responseTabella = await unsplash.get("scadenzario", {
        params: {
          idSede: props.sede,
          idCliente: props.idCliente,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setTabellaFormazione(responseTabella.data.data);
      setLoadingStampe(false);
      setLoadingStampeBk(false);

      exportPDFFormazione(
        props.infoSede,
        props.logo,
        props.regSoc,
        props.sedeLoc,
        props.sedeInd,
        props.sedeCap,
        props.bloccoSede,
        props.sedeCF
        //params.row
      );
    }
  };

  const handleSaveData = async (corso, operazione) => {
    if (operazione === "Inserimento") {
      try {
        await unsplash.post(
          "corsiSede",
          { sede: props.idSede, corso: corso.ID },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Corso inserito con successo",
        });
        setOpenSnack(true);
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'inserimento",
        });
        setOpenSnack(true);
      }
    } else {
      try {
        let c = {
          ID: corso.IDRel_SediClientiCorsi,
          IDRel_ClientiCorsi: corso.ID,
          IDSede: corso.IDSede,
        };
        await unsplash.put(
          "corsiSede",
          { corso: c },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Corso Modificato con successo",
        });
        setOpenSnack(true);
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'inserimento",
        });
        setOpenSnack(true);
      }
    }

    loadData();
  };

  const exportPDFFormazione = (
    infoSede,
    logo,
    ragioneSociale,
    sedeLoc,
    sedeInd,
    sedeCap,
    bloccoSede,
    sedeCF,
    dirigente
  ) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size, true);
    //const imgLogo = new Image();
    var imgLogo = logo;
    var imgDef = require("../assets/images/MILLERLogo-copia.jpg");
    var dataCorrente = new Date();
    dataCorrente = moment(dataCorrente).format("DD/MM/YYYY");
    var annoCorrente = moment(new Date()).format("YYYY");
    //AGGIUNGO I FONT CHE HO IMPORTATO
    doc.addFileToVFS("verdana-normal.ttf", fontVerdanaNormal);
    doc.addFileToVFS("verdana-bold.ttf", fontVerdanaBold);
    doc.addFont("verdana-normal.ttf", "verdana", "normal");
    doc.addFont("verdana-bold.ttf", "verdana", "bold");

    doc.setTextColor(50, 62, 79);

    doc.setDrawColor(17, 82, 111);
    doc.setLineWidth(1);
    doc.setFillColor(255, 255, 255);

    doc.addImage(imgDef, "PNG", 13, 13, 300, 89); //da riguardare se così o al contrario

    doc.setFont("verdana", "bold");
    doc.setFontSize(24);
    doc.text(
      "PIANO DELLA FORMAZIONE",
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth("PIANO DELLA FORMAZIONE") *
          doc.internal.getFontSize()) /
          2,
      500
    );
    doc.text(
      "ANNUALE",
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth("ANNUALE") * doc.internal.getFontSize()) / 2,
      520
    );

    doc.setFontSize(18);
    doc.text(
      moment(new Date()).format("MM/YYYY"),
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(moment(new Date()).format("MM/YYYY")) *
          doc.internal.getFontSize()) /
          2,
      550
    );
    doc.autoTable({
      startY: 570,
      html: "#table-persone",
      useCss: true, //IMPORTANTISSIMO

      margin: { top: 40, right: 40, bottom: 60, left: 40 },
      rowPageBreak: "auto",
    });

    doc.setFont("verdana", "normal");
    //(IMG, extention, positionX, positionY, dimensionX, dimensionY)
    doc.setFontSize(9);
    doc.text(
      doc.splitTextToSize(
        `${ragioneSociale}`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`${ragioneSociale}`) *
          doc.internal.getFontSize() -
        40,
      120
    );
    doc.text(
      doc.splitTextToSize(
        `${sedeInd} - ${sedeCap}`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`${sedeInd} - ${sedeCap}`) *
          doc.internal.getFontSize() -
        40,
      132
    );
    doc.text(
      doc.splitTextToSize(
        `${bloccoSede}`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`${bloccoSede}`) * doc.internal.getFontSize() -
        40,
      144
    );

    doc.text(
      doc.splitTextToSize(`1`, doc.internal.pageSize.getWidth() - 80),
      560,
      800
    );

    doc.text(
      doc.splitTextToSize(
        `${ragioneSociale}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      780
    );

    doc.text(
      doc.splitTextToSize(
        `Piano della formazione annuale`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      788
    );
    doc.text(
      doc.splitTextToSize(
        `${dataCorrente}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      796
    );

    doc.addImage(
      imgLogo,
      "PNG",
      (doc.internal.pageSize.getWidth() - 135) / 2,
      350,
      155,
      87.5
    );

    doc.addPage();

    doc.addImage(imgDef, "PNG", 513, 13, 67, 20);

    // INIZIO TESTO
    doc.setFontSize(9);
    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(`SCHEDA 5`, doc.internal.pageSize.getWidth() - 80),
      33,
      50
    );
    doc.text(
      doc.splitTextToSize(
        `PIANO DELLA FORMAZIONE ANNUALE`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      65
    );
    doc.text(
      doc.splitTextToSize(
        `1 Scopo e campo di applicazione`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      85
    );
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Scopo del presente piano è definire la logica e la modalità di gestione delle tematiche riguardanti l’inserimento, l’addestramento, la formazione e il coinvolgimento nelle attività aziendali dei dipendenti in coerenza con gli obiettivi di Salute e Sicurezza che l’organizzazione si pone.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      100
    );
    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        `2 Responsabilità`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      135
    );
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `La responsabilità nella gestione delle tematiche sopra riportate (inserimento, addestramento, formazione) risulta in capo al Datore di Lavoro.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      150
    );
    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        `3 Modalità operative`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      175
    );
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Il responsabile dell’organizzazione con l’RSPP elabora annualmente il piano di formazione; il presente piano tiene conto, in particolare:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      190
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      33,
      210
    );
    doc.text(
      doc.splitTextToSize(
        `Dell’inserimento in azienda di nuovi dipendenti che necessitano di inquadramento/informazione in fase di inserimento, formazione preventiva, affiancamento operativo o addestramento tecnico-professionale;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      210
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      33,
      230
    );
    doc.text(
      doc.splitTextToSize(
        `Delle esigenze di qualificazione del personale addetto a compiti particolarmente per cui la normativa richiede una specifica formazione e/o addestramento;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      230
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      33,
      250
    );
    doc.text(
      doc.splitTextToSize(
        `Di quanto prescritto dalle leggi vigenti in materia di salute e sicurezza dei lavoratori.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      250
    );

    doc.text(
      doc.splitTextToSize(
        `La partecipazione dei dipendenti a qualsiasi programma di formazione, informazione e/o addestramento viene documentata sistematicamente mediante la registrazione sul modulo Registro delle Presenze.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      270
    );

    doc.text(
      doc.splitTextToSize(
        `Viene inoltre conservata la documentazione utilizzata in fase di formazione (dispense, documenti, ecc.).`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      300
    );

    doc.text(
      doc.splitTextToSize(
        `A seguito formazione, informazione e/o addestramento effettuati, viene verificata l’efficacia della formazione ricevuta dai dipendenti e, se necessario, ripianificata una formazione aggiuntiva.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      320
    );
    doc.text(
      doc.splitTextToSize(
        `Copia degli attestati è conservata presso l’ufficio del referente aziendale della sicurezza.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      340
    );

    doc.text(
      doc.splitTextToSize(
        `Gli interventi formativi si distinguono in:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      360
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      33,
      370
    );
    doc.text(
      doc.splitTextToSize(
        `Interventi di informazione aggiornamento e sensibilizzazione: consegna di opuscoli informativi o documenti inerenti tematiche generiche o specifiche in materia di Salute e Sicurezza sul lavoro;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      370
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      33,
      390
    );
    doc.text(
      doc.splitTextToSize(
        `Interventi di formazione: corsi, lezioni o seminari su argomenti specifici di interesse operativo o mirati alla crescita delle conoscenze e competenze dei partecipanti.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      390
    );

    doc.text(
      doc.splitTextToSize(
        `La formazione viene solitamente affidata a consulenti esterni esperti nelle differenti materie o direttamente all’RSPP.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      420
    );

    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        `Formazione per nuovi assunti o in occasione di cambio mansione`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      455
    );

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Per i nuovi assunti in coerenza con la posizione da ricoprire ed in collaborazione con il Datore di Lavoro viene predisposto un piano di inserimento in azienda che applica la logica prevalente dell’affiancamento operativo, tramite:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      475
    );

    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      33,
      515
    );
    doc.text(
      doc.splitTextToSize(
        `Consegna di un opuscolo informativo con informazioni generali in merito alla normativa vigente, ai rischi presenti nella realtà produttiva e alle pratiche di prevenzione dei rischi sul lavoro;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      515
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      33,
      535
    );
    doc.text(
      doc.splitTextToSize(
        `L’addestramento operativo e tecnico con supervisione del Datore di Lavoro o di un Responsabile / dei dipendenti più esperti, sull’attività da svolgere, sui rischi presenti nell’area di lavoro e sulle procedure o modalità operative da mettere in atto per ridurre i rischi per la Salute e la Sicurezza dei lavoratori;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      535
    );

    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      33,
      565
    );
    doc.text(
      doc.splitTextToSize(
        `Corso di formazione in materia di salute e sicurezza sul lavoro secondo quanto previsto dall’Accordo Stato – Regioni dicembre 2011.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      565
    );

    doc.text(
      doc.splitTextToSize(
        `Oltre a quanto sopra, se la mansione prevede formazione specifica per l’utilizzo di attrezzature o sostanze o per ricoprire determinati ruoli in ambito salute e sicurezza verranno erogato i corsi previsti dalla normativa vigente.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      585
    );
    doc.text(
      doc.splitTextToSize(
        `Al momento dell’elaborazione del piano, non è possibile prevedere le assunzioni o cambi di mansione che potranno essere fatti nel corso d’anno. Per tale motivo il piano non prevede i corsi sopra menzionati per i nuovi assunti che saranno comunque svolti secondo le previsioni normative.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      605
    );

    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        `Formazione dei dipendenti`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      650
    );

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Per il personale che lavora già da tempo nell’organizzazione viene predisposto un piano di formazione (riportato in allegato) con lo scopo di migliorare la consapevolezza dei dipendenti in merito ai rischi presenti e alle modalità di gestione del rischio.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      670
    );
    doc.text(
      doc.splitTextToSize(
        `Sono previsti inoltre corsi di formazione per ricoprire specifici incarichi all’interno dell’organizzazione (es. addetto squadra di emergenza, addetto squadra di primo soccorso, preposto, ecc.).`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      700
    );
    doc.text(
      doc.splitTextToSize(`2`, doc.internal.pageSize.getWidth() - 80),
      560,
      800
    );

    doc.setFontSize(8);
    doc.text(
      doc.splitTextToSize(
        `${ragioneSociale}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      780
    );
    doc.text(
      doc.splitTextToSize(
        `Piano della formazione annuale`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      788
    );
    doc.text(
      doc.splitTextToSize(
        `${dataCorrente}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      796
    );

    //terza pagina
    doc.addPage();

    doc.addImage(imgDef, "PNG", 513, 13, 67, 20);

    doc.setFontSize(9);
    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        `4 Coinvolgimento e sensibilizzazione`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      50
    );

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Il coinvolgimento di tutto il personale viene favorito con sistematicità dalla comunicazione ed informazione da parte del Datore di Lavoro. Più in specifico, l'attività di sensibilizzazione è promossa tramite la continua opera di coinvolgimento da parte del Responsabile nei differenti aspetti legati all’attività lavorativa ed in particolare nei confronti della Salute e Sicurezza dei lavoratori.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      70
    );
    doc.text(
      doc.splitTextToSize(
        `Inoltre, con particolare riferimento alle tematiche di Salute e Sicurezza nei luoghi di lavoro, si prevedono incontri del Servizio di Prevenzione e Protezione annuali per discutere di:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      110
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      33,
      130
    );
    doc.text(
      doc.splitTextToSize(
        `Documento di valutazione dei rischi;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      130
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      33,
      140
    );
    doc.text(
      doc.splitTextToSize(
        `Andamento degli infortuni, delle malattie professionali e della sorveglianza sanitaria;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      140
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      33,
      150
    );
    doc.text(
      doc.splitTextToSize(
        `Gestione e utilizzo dei Dispositivi di Protezione Individuale;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      150
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      33,
      160
    );
    doc.text(
      doc.splitTextToSize(
        `Programmi di formazione e informazione ai fini della Salute e Sicurezza dei lavoratori;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      160
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      33,
      170
    );
    doc.text(
      doc.splitTextToSize(
        `Obiettivi di miglioramento per la sicurezza dei dipendenti e procedure di sicurezza da adottare.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      170
    );
    doc.text(
      doc.splitTextToSize(
        `La consultazione dei lavoratori avviene durante gli incontri tra il Datore di Lavoro e/o l’RSPP e i dipendenti stessi durante i diversi sopralluoghi nei luoghi di lavoro.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      190
    );
    doc.text(
      doc.splitTextToSize(
        `Il Medico Competente viene consultato durante le riunioni periodiche del Servizio di Prevenzione e Protezione e ogniqualvolta se ne riscontri la necessità.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      210
    );
    doc.text(
      doc.splitTextToSize(
        `L’attività di consultazione dei lavoratori avviene anche tramite il coinvolgimento del Rappresentante dei Lavoratori per la Sicurezza (RLS) così come prescritto dalla normativa vigente, ed in particolare attraverso la riunione del Servizio di Prevenzione e Protezione (ex art. 35 D.Lgs. 81/08).`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      240
    );
    doc.text(
      doc.splitTextToSize(
        `Inoltre l’RLS viene consultato in ordine a tutte le tematiche collegate alla Valutazione dei Rischi presenti all’interno dell’Organizzazione.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      270
    );
    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        `5 Metodologia di compilazione della tabella`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      300
    );
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Nella tabella sotto riportata vengono elencati tutti i corsi in ambito sicurezza sul lavoro previsti dalla normativa vigente.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      315
    );
    doc.text(
      doc.splitTextToSize(
        `Nella colonna “assoggettamento” vengono crocettati i corsi a cui l’azienda è soggetta.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      335
    );
    doc.text(
      doc.splitTextToSize(
        `Nella colonna “applicabilità” vengono crocettati i corsi che l’azienda dovrà svolgere nell’anno a cui si riferisce il presente piano.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      345
    );
    doc.text(
      doc.splitTextToSize(
        `Non vengono considerati in tabella i corsi che dovranno svolgere eventuali neoassunti o lavoratori a cui sarà cambiata la mansione, come specificato nel capitolo 3.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      365
    );

    doc.text(
      doc.splitTextToSize(`3`, doc.internal.pageSize.getWidth() - 80),
      560,
      800
    );

    doc.setFontSize(8);
    doc.text(
      doc.splitTextToSize(
        `${ragioneSociale}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      780
    );
    doc.text(
      doc.splitTextToSize(
        `Piano della formazione annuale`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      788
    );
    doc.text(
      doc.splitTextToSize(
        `${dataCorrente}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      796
    );

    doc.addPage("a4", "landscape");

    doc.addImage(imgDef, "PNG", 763, 13, 67, 20);

    doc.setFontSize(12);
    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        `6 PIANIFICAZIONE DELLA FORMAZIONE anno ${annoCorrente}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      50
    );
    doc.setFont("verdana", "normal");

    doc.autoTable({
      startY: 70,
      html: "#table-formazione",
      useCss: true, //IMPORTANTISSIMO
      didDrawPage: function (column) {
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height;
        //Header
        doc.addImage(imgDef, "PNG", 763, 13, 67, 20);

        // Footer
        var str = doc.internal.getNumberOfPages();

        doc.setFontSize(9);
        doc.text(
          doc.splitTextToSize(str, doc.internal.pageSize.getWidth() - 80),
          805,
          565
        );
        doc.setFontSize(8);
        doc.text(
          doc.splitTextToSize(
            `${ragioneSociale}`,
            doc.internal.pageSize.getWidth() - 80
          ),
          33,
          560
        );
        doc.text(
          doc.splitTextToSize(
            `Piano della formazione annuale`,
            doc.internal.pageSize.getWidth() - 80
          ),
          33,
          568
        );
        doc.text(
          doc.splitTextToSize(
            `${dataCorrente}`,
            doc.internal.pageSize.getWidth() - 80
          ),
          33,
          576
        );
      },
      margin: { top: 40, right: 40, bottom: 60, left: 40 },
      rowPageBreak: "auto",
    });

    doc.output("pdfobjectnewwindow");
    doc.output(
      "save",
      ragioneSociale +
        "_" +
        "pianoformazione" +
        "_" +
        moment(new Date()).format("YYYY") +
        ".pdf"
    );
  };

  function cap(arr) {
    if (arr) {
      for (var i = 0; i < arr.length; i++) {
        arr[i] =
          arr[i].charAt(0).toUpperCase() + arr[i].substring(1).toLowerCase();
      }
      return arr.join(" ");
    }
  }

  return (
    <div>
      <table id="table-formazione" hidden>
        <thead>
          <tr>
            <th colspan="6">FORMAZIONE FIGURE ORGANIGRAMMA SICUREZZA</th>
          </tr>
          <tr>
            <th scope="col">CORSO</th>
            <th scope="col">RIFERIMENTO NORMATIVO</th>
            <th scope="col">DURATA CORSO</th>
            <th scope="col">AGGIORNAMENTO DURATA E FREQUENZA</th>
            <th scope="col">ASSOGGETTABILITA'</th>
            <th scope="col">APPLICABILITA'</th>
          </tr>
        </thead>
        <tbody>
          {tabellaFormazione?.map((el) => {
            return (
              <>
                <tr>
                  <td>{el?.Descrizione}</td>
                  <td
                    style={{
                      fontSize: 12,
                      padding: 1,
                    }}
                  >
                    {el?.RiferimentoNormativo}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {el?.Durata}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {el?.Frequenza}
                  </td>
                  <td
                    style={{
                      fontSize: 14,
                      backgroundColor:
                        el?.Codice === "FG"
                          ? "white"
                          : el?.Assoggettibilita === "Non Soggetta"
                          ? "#c3c3c3"
                          : "",
                    }}
                  >
                    {el?.Codice === "FG" ? "Soggetta" : el?.Assoggettibilita}
                  </td>
                  <td
                    style={{
                      fontSize: 14,
                      backgroundColor:
                        el?.Applicabilita === "Non Applicabile"
                          ? "#c3c3c3"
                          : "",
                    }}
                  >
                    {el?.Applicabilita}
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
      <table id="table-persone" hidden>
        <tr>
          <th scope="col">DATORE DI LAVORO</th>
          <th scope="col">RSPP</th>
          <th scope="col">RLS</th>
        </tr>
        <tr>
          <td>
            {personeFormazione?.Datore
              ? cap(personeFormazione?.Datore?.split(" "))
              : null}
          </td>
          <td>
            {personeFormazione?.RSPP
              ? cap(personeFormazione?.RSPP?.split(" "))
              : null}
          </td>
          <td>
            {personeFormazione?.RLS
              ? cap(personeFormazione?.RLS?.split(" "))
              : null}
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </table>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          overflowY: "hidden",
        }}
        open={loadingStampeBk}
        onClick={() => setLoadingStampeBk(false)}
      >
        <Grid
          container
          direction="row"
          gap={2}
          alignItems="center"
          justifyContent="center"
          flexWrap={"wrap"}
        >
          <div className="w-full text-center">
            Generazione Documento In Corso
          </div>
          <CircularProgress color="inherit" />
        </Grid>
      </Backdrop>
      <Box sx={{ width: "100%" }}>
        <Grid
          container
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
        >
          <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
          >
            <Button
              id="1"
              variant="contained"
              className={
                loadingStampe ? "ButtonMillerDisabled" : "ButtonMiller"
              }
              startIcon={<Print />}
              onClick={() => {
                if (loadingStampe) {
                  setSnack({
                    severity: "error",
                    messaggio: "Caricamento in corso...",
                  });
                  setOpenSnack(true);
                }else{
                  loadPianoFormazione();
                }
              }}
            >
              PIANO FORMAZIONE
            </Button>
            <Button
              className="ButtonMiller"
              startIcon={<Add />}
              onClick={() => document.getElementById("add").click()}
            >
              AGGIUNGI
            </Button>
          </ButtonGroup>
        </Grid>
        <FormDialog
          id="add"
          operazione="Inserimento"
          reloadData={loadData}
          corsiSede={corsiSede}
          corsi={corsi?.filter(
            (corso) => !corsiSede?.some((c) => c.Corso === corso.Codice)
          )}
          saveFunction={handleSaveData}
        />

        <DeleteCorsi
          corso={vistaCorsi}
          openValue={openAlert}
          openFunction={toggleAlert}
        />
        <div style={{ height: "60vh", width: "100%" }}>
          <DataGridPro
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            components={{
              Toolbar: CustomToolbar,
              Footer: CustomFooterPersonalized,
            }}
            slots={{
              loadingOverlay: LinearProgress,
            }}
            loading={loading}
            initialState={{ pinnedColumns: { left: ["Actions"] } }}
            rows={corsiSede}
            getRowId={(corsiSede) => corsiSede.ID}
            columns={columns}
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRowData = corsiSede.filter((row) =>
                selectedIDs.has(row.Codice)
              );
              setCorsoSel(selectedRowData[0]);
            }}
          />
        </div>
      </Box>
    </div>
  );
};

const FormDialog = (props) => {
  const [open, setOpen] = useState(false);
  const [corsoSel, setCorsoSel] = useState("");

  const handleClickOpen = () => {
    if (props.operazione === "Inserimento") {
      setCorsoSel({ Codice: "Codice", Descrizione: "Descrizione" });
    } else {
      let codCorso = props.corsiSede.filter(
        (c) => c.Codice === props.corsoSel.Codice
      )[0];
      setCorsoSel(codCorso);
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    props.saveFunction(corsoSel, props.operazione);
  };

  return (
    <>
      <IconButton
        aria-label="edit"
        onClick={handleClickOpen}
        id={props.id}
        style={{ display: props.id === "edit" ? "" : "none" }}
      >
        <Edit
          style={{
            height: "2rem",
            width: "2rem",
            color: "#2265B1",
          }}
        />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth="sm"
        TransitionComponent={Transition}
      >
        <DialogTitle>Associa Corso</DialogTitle>
        <DialogContent sx={{ margin: "10px" }}>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                id="combo-box-demo"
                options={props.corsi}
                value={corsoSel}
                //disabled={props.operazione === "Modifica"}
                getOptionLabel={(option) =>
                  option.Codice + " - " + option.Descrizione
                }
                fullWidth
                onChange={(event, newValue) => {
                  //mi porto dierto i valori dei dati della tabella Rel_SediClientiCorsi
                  setCorsoSel({
                    ...newValue,
                    IDSede: corsoSel.IDSede,
                    IDRel_SediClientiCorsi: corsoSel.ID,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Corso" />
                )}
                sx={{ mt: 1 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pl: 4,
            pr: 6,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={handleSave}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CorsiSede;

const DeleteCorsi = (props) => {
  const [openSnackDel, setOpenSnackDel] = useState(false);
  const [snackDel, setSnackDel] = useState({
    severity: "success",
    messaggio: "Eliminazione effettuata correttamente",
    open: false,
  });
  const handleCloseSnackDel = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackDel(false);
  };
  const handleDelete = async () => {
    try {
      await unsplash.delete("corsiSede", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: { id: props.corso.ID },
      });
      setSnackDel({
        severity: "success",
        messaggio: "Eliminazione effettuata correttamente",
      });
      setOpenSnackDel(true);
    } catch {
      setSnackDel({
        severity: "error",
        messaggio: "Errore durante l'eliminazione",
      });
      setOpenSnackDel(true);
    }
    props.openFunction(true);
  };
  return (
    <>
      <GlobalSnackbar
        severity={snackDel.severity}
        messaggio={snackDel.messaggio}
        openSnack={openSnackDel}
        handleCloseSnack={handleCloseSnackDel}
      />
      <Dialog
        open={props.openValue}
        onClose={props.openFunction}
        maxWidth="md"
        TransitionComponent={Transition}
      >
        <DialogTitle>Eliminazione Corso</DialogTitle>
        <DialogContent>
          Sei sicuro di voler eliminare <b>{props.corso?.Descrizione}</b>?
        </DialogContent>

        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button onClick={props.openFunction}>ANNULLA</Button>
          <Button onClick={handleDelete}>CONFERMA</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
