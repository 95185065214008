import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import unsplash from "../../api/unsplash";
import GlobalSnackbar from "../Snackbar";

import {
  Grid,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  ButtonGroup,
  Zoom,
  DialogActions,
  DialogContentText,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Autocomplete,
  LinearProgress,
} from "@mui/material";

import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";

import { IconButton } from "@mui/material";

import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";

import {
  Add,
  Delete,
  Close,
  Check,
  ConnectingAirportsOutlined,
  Circle,
} from "@mui/icons-material";

import moment from "moment-timezone";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { fontVerdanaNormal } from "../../style/verdana-normalW";
import { fontVerdanaBold } from "../../style/verdana-bold";
import { LocalizationProvider,DatePicker,PickersDay,TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment as DateAdapter} from '@mui/x-date-pickers/AdapterMoment'
import { CustomFooterPersonalized } from "../../utils/CustomFooterPersonalized";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

export const AddestramentoLavoratori = ({
  idLavoratore,
  lavoratore,
  cliente,
}) => {
  const [addestramentoLavoratori, setAddestramentoLavoratori] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openPDF, setOpenPDF] = useState(false);
  const [stampaDPI, setStampaDPI] = useState(false);
  const [durataTot, setDurataTot] = useState("");
  const [sede, setSede] = useState([]);
  const [dpi, setDpi] = useState([]);

  const [addestramentoSel, setAddestramentoSel] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [addestramentoSelezionato, setAddestramentoSelezionato] = useState([]);

  const [open, setOpen] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={async () => {
              await setAddestramentoSelezionato(params.row);
              document.getElementById("edit").click();
              handleClickOpen();
            }}
          >
            <EditIcon
              style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
            />
          </IconButton>

          <DeleteDialog addestramento={params.row} myFunction={handleDelete} />
        </>
      ),
    },
    {
      field: "data",
      headerName: "Data",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div>
            {params.row.data
              ? moment(params.row.data).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },
    {
      field: "orario_inizio",
      headerName: "Orario inizio",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        if (params.row.orario_inizio) {
          return (
            <div>
              {params.row.orario_inizio
                ? moment(
                    new Date("1970-01-01T" + params.row.orario_inizio)
                  ).format("HH:mm")
                : ""}
            </div>
          );
        }
      },
    },
    {
      field: "orario_fine",
      headerName: "Orario fine",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        if (params.row.orario_fine) {
          return (
            <div>
              {params.row.orario_fine
                ? moment(
                    new Date("1970-01-01T" + params.row.orario_fine)
                  ).format("HH:mm")
                : ""}
            </div>
          );
        }
      },
    },
    {
      field: "nome_cognome",
      headerName: "Nome e Cognome",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "mansione",
      headerName: "Mansione",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "id_dpi",
      headerName: "DPI",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        if (params.row.id_dpi === 0) {
          return (
            <div>
              <Circle
                sx={{ color: "#FF2768", width: "15px", height: "15px" }}
              />
            </div>
          );
        } else {
          return (
            <div>
              <Circle
                sx={{ color: "#40C18F", width: "15px", height: "15px" }}
              />
            </div>
          );
        }
      },
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const reloadAfterUpload = async () => {
    let response = await unsplash.get("addestramentoLavoratore", {
      params: {
        addestramento: idLavoratore,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    setAddestramentoLavoratori(response.data.data);
  };

  const handleSave = async (addestr, operazione) => {
    if (operazione === "new") {
      let add = { ...addestr };
      add.id_lavoratori = idLavoratore;

      try {
        await unsplash.post(
          "addestramentoLavoratore",
          {
            addestramento: add,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Addestramento creata con successo",
        });
        setOpenSnack(true);

        reloadAfterUpload();
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante la creazione",
        });
        setOpenSnack(true);
      }
      handleClose();
    } else {
      let add = { ...addestr };
      add.data = moment(add.data).format("YYYY-MM-DD");
      try {
        await unsplash.put(
          "addestramentoLavoratore",
          { addestramento: add },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Addestramento aggiornata con successo",
        });
        setOpenSnack(true);

        reloadAfterUpload();
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'aggiornamento",
        });
        setOpenSnack(true);
      }
      handleClose();
    }
  };

  const handleDelete = async (categ) => {
    let addestramentoDaEliminare = { ...categ };
    addestramentoDaEliminare.deleted = 1;
    try {
      await unsplash.put(
        "addestramentoLavoratore",
        { addestramento: addestramentoDaEliminare },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      setSnack({
        severity: "success",
        messaggio: "Addestramento eliminato con successo",
      });
      setOpenSnack(true);
      reloadAfterUpload();
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione dell'Addestramento",
      });
      setOpenSnack(true);
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
          {rowSelectionModel.length > 0 ? (
            <ButtonGroup
              variant="contained"
              className="ButtonMiller"
              aria-label="split button"
              style={{ margin: "4px 0" }}
            >
              <Button
                startIcon={<PrintIcon />}
                variant="contained"
                className="ButtonMiller"
                onClick={() => {
                  exportPDF();
                }}
              >
                STAMPA
              </Button>
              {stampaDPI ? (
                <>
                  <Button
                    startIcon={<PrintIcon />}
                    variant="contained"
                    className="ButtonMiller"
                    onClick={() => {
                      exportPDFDPI();
                    }}
                  >
                    STAMPA DPI
                  </Button>
                </>
              ) : (
                <></>
              )}
            </ButtonGroup>
          ) : (
            <></>
          )}
        </Grid>
      </GridToolbarContainer>
    );
  }
  function cap(arr) {
    if (arr) {
      for (var i = 0; i < arr.length; i++) {
        arr[i] =
          arr[i].charAt(0).toUpperCase() + arr[i].substring(1).toLowerCase();
      }
      return arr.join(" ");
    }
  }
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size, true);
    var imgDef = cliente.Logo;
    var dataCorrente = new Date();
    dataCorrente = moment(dataCorrente).format("DD/MM/YYYY");

    //AGGIUNGO I FONT CHE HO IMPORTATO
    doc.addFileToVFS("verdana-normal.ttf", fontVerdanaNormal);
    doc.addFileToVFS("verdana-bold.ttf", fontVerdanaBold);
    doc.addFont("verdana-normal.ttf", "verdana", "normal");
    doc.addFont("verdana-bold.ttf", "verdana", "bold");

    setOpenPDF(true);

    doc.setTextColor(50, 62, 79);

    doc.setDrawColor(17, 82, 111);
    doc.setLineWidth(1);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(32, 8, 130, 77, 8, 8); // Black sqaure with rounded corners
    doc.addImage(imgDef, "PNG", 37, 13, 120, 68); //da riguardare se così o al contrario

    doc.roundedRect(172, 8, 280, 77, 8, 8);
    doc.setFontSize(10);
    doc.setFont("verdana", "bold");
    var title1 = "Registro Interventi di addestramento";
    var title4 = "(Art. 36, 37 e 77 D.Lgs. 81/2008)";
    var title3 = "Rev.: 00";

    doc.text(
      title1,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title1) * doc.internal.getFontSize()) / 2,
      44
    );

    doc.text(
      title4,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title4) * doc.internal.getFontSize()) / 2,
      60
    );

    doc.setFontSize(9);
    doc.setFont("verdana", "bold");
    doc.text(
      title3,
      500 +
        15 -
        (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2,
      45
    );
    doc.roundedRect(462, 8, 100, 77, 8, 8);

    doc.text(
      dataCorrente,
      500 +
        15 -
        (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2,
      59
    );

    /* INIZIO TESTO */
    doc.setFontSize(10);
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `La ditta ${cliente?.RagioneSociale} presso la sede ${sede?.Via} ${sede?.Descrizione}, ${sede?.Cap} attraverso affiancamento a soggetti con adeguate conoscenze e competenze riportati nella tabella sotto, ha provveduto a fornire adeguato addestramento teorico-pratico e specifici riferimenti alla sicurezza e salute sul lavoro all’operatore:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      120
    );

    doc.setFont("verdana", "normal");

    doc.text(
      doc.splitTextToSize(
        `____________________`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      188
    );
    doc.text(
      doc.splitTextToSize(
        `(${cap(lavoratore?.Nome.split(" "))} ${cap(
          lavoratore?.Cognome.split(" ")
        )})`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      208
    );

    doc.text(
      `____________________`,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title1) * doc.internal.getFontSize()) / 2,
      188
    );
    doc.text(
      `(${lavoratore?.Descrizione})`,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title1) * doc.internal.getFontSize()) / 2,
      208
    );

    doc.text(
      doc.splitTextToSize(
        `Per la durata di ${durataTot}; come riportato nella tabella sottostante:`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      250
    );

    let height = 0;
    doc.autoTable({
      startY: 270,
      html: "#table-Addestramento",
      useCss: true, //IMPORTANTISSIMO
      tableWidth: "auto",
      didDrawPage: function (column) {
        const arrHeight = column.table.body.map((el) => el.height);
        height = arrHeight.reduce((total, amount) => total + amount);
        if (height > 521.89) {
          height = height - 421.89;
        } else {
          height = height + 270;
        }
      },
      margin: { top: 40, right: 5, bottom: 80, left: 5 },
      rowPageBreak: "auto",
    });

    doc.text(
      doc.splitTextToSize(
        `Al termine dell’attività si rilascia copia della presente a comprova dell’attività svolta.`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      height + 20
    );
    doc.text(
      doc.splitTextToSize(
        `Luogo ____________________ data ___/___/______`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      height + 80
    );

    doc.text(`____________________`, 33, height + 140);
    doc.text(`(Firma del lavoratore)`, 40, height + 160);
    doc.text(`____________________`, 197, height + 140);
    doc.text(`(Firma del Datore di`, 210, height + 160);
    doc.text(`lavoro o della direzione)`, 200, height + 172);

    doc.output("pdfobjectnewwindow", "DPI.pdf");
  };

  const exportPDFDPI = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size, true);
    var imgDef = cliente.Logo;
    var dataCorrente = new Date();
    dataCorrente = moment(dataCorrente).format("DD/MM/YYYY");

    //AGGIUNGO I FONT CHE HO IMPORTATO
    doc.addFileToVFS("verdana-normal.ttf", fontVerdanaNormal);
    doc.addFileToVFS("verdana-bold.ttf", fontVerdanaBold);
    doc.addFont("verdana-normal.ttf", "verdana", "normal");
    doc.addFont("verdana-bold.ttf", "verdana", "bold");

    setOpenPDF(true);

    doc.setTextColor(50, 62, 79);

    doc.setDrawColor(17, 82, 111);
    doc.setLineWidth(1);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(32, 8, 130, 77, 8, 8); // Black sqaure with rounded corners
    doc.addImage(imgDef, "PNG", 37, 13, 120, 68); //da riguardare se così o al contrario

    doc.roundedRect(172, 8, 280, 77, 8, 8);
    doc.setFontSize(10);
    doc.setFont("verdana", "bold");
    var title1 = "Registro formazione informazione";
    var title2 = "ed addestramento corretto utilizzo DPI";
    var title4 = "(Art. 36, 37 e 77 D.Lgs. 81/2008)";
    var title3 = "Rev.: 00";

    doc.text(
      title1,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title1) * doc.internal.getFontSize()) / 2,
      40
    );
    doc.text(
      title2,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title2) * doc.internal.getFontSize()) / 2,
      50
    );

    doc.text(
      title4,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title4) * doc.internal.getFontSize()) / 2,
      60
    );

    doc.setFontSize(9);
    doc.setFont("verdana", "bold");
    doc.text(
      title3,
      500 +
        15 -
        (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2,
      45
    );
    doc.roundedRect(462, 8, 100, 77, 8, 8);

    doc.text(
      dataCorrente,
      500 +
        15 -
        (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2,
      59
    );

    /* INIZIO TESTO */
    doc.setFontSize(10);
    doc.setFont("verdana", "normal");

    doc.text(
      doc.splitTextToSize(
        `La ditta ${cliente?.RagioneSociale} presso la sede ${sede?.Via} ${sede?.Descrizione}, ${sede?.Cap} attraverso affiancamento a soggetti con adeguate conoscenze e competenze riportati nella tabella sotto, ha provveduto a fornire adeguata formazione informazione ed  addestramento teorico-pratico per l’uso dei DPI, ai sensi degli artt. 36, 37, 77 D.Lgs. n. 81/2008:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      120
    );

    doc.setFont("verdana", "normal");

    doc.text(
      doc.splitTextToSize(
        `____________________`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      188
    );
    doc.text(
      doc.splitTextToSize(
        `(${cap(lavoratore?.Nome.split(" "))} ${cap(
          lavoratore?.Cognome.split(" ")
        )})`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      208
    );

    doc.text(
      `____________________`,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title1) * doc.internal.getFontSize()) / 2,
      188
    );
    doc.text(
      `(${lavoratore?.Descrizione})`,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title1) * doc.internal.getFontSize()) / 2,
      208
    );

    doc.text(
      doc.splitTextToSize(
        `Nel corso degli incontri, si è provveduto ad informare preliminarmente i lavoratori dei rischi dai quali i DPI utilizzati li proteggono come riportato nella tabella sottostante, per la durata di ${durataTot} per lo svolgimento della mansione ${lavoratore?.Descrizione}.`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      250
    );

    let height = 0;
    doc.autoTable({
      startY: 300,
      html: "#table-Addestramento-DPI",
      useCss: true, //IMPORTANTISSIMO
      tableWidth: "auto",
      didDrawPage: function (column) {
        const arrHeight = column.table.body.map((el) => el.height);
        height = arrHeight.reduce((total, amount) => total + amount);
        if (height > 521.89) {
          height = height - 421.89;
        } else {
          height = height + 320;
        }
      },
      margin: { top: 40, right: 5, bottom: 80, left: 5 },
      rowPageBreak: "auto",
    });

    doc.addPage();

    doc.setDrawColor(17, 82, 111);
    doc.setLineWidth(1);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(32, 8, 130, 77, 8, 8); // Black sqaure with rounded corners
    doc.addImage(imgDef, "PNG", 37, 13, 120, 68); //da riguardare se così o al contrario

    doc.roundedRect(172, 8, 280, 77, 8, 8);
    doc.setFontSize(10);
    doc.setFont("verdana", "bold");
    var title1 = "Registro formazione informazione";
    var title2 = "ed addestramento corretto utilizzo DPI";
    var title4 = "(Art. 36, 37 e 77 D.Lgs. 81/2008)";
    var title3 = "Rev.: 00";

    doc.text(
      title1,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title1) * doc.internal.getFontSize()) / 2,
      40
    );
    doc.text(
      title2,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title2) * doc.internal.getFontSize()) / 2,
      50
    );

    doc.text(
      title4,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title4) * doc.internal.getFontSize()) / 2,
      60
    );

    doc.setFontSize(9);
    doc.setFont("verdana", "bold");
    doc.text(
      title3,
      500 +
        15 -
        (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2,
      45
    );
    doc.roundedRect(462, 8, 100, 77, 8, 8);

    doc.text(
      dataCorrente,
      500 +
        15 -
        (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2,
      59
    );

    doc.setFontSize(10);
    doc.setFont("verdana", "normal");

    doc.text(
      doc.splitTextToSize(
        `Nel corso degli incontri, il formatore ha quindi erogato ai lavoratori, per ogni DPI messo a disposizione, istruzioni comprensibili ed una formazione e un adeguato e specifico addestramento circa l’uso corretto e l’utilizzo pratico dei DPI.`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      120
    );
    doc.text(
      doc.splitTextToSize(
        `In particolare, il datore di lavoro ha provveduto a destinare ogni DPI ad un uso personale e, nei casi in cui le circostanze richiedono l’uso di uno stesso DPI da parte di più persone, ha preso misure adeguate affinché tale uso non ponesse alcun problema sanitario e igienico ai vari utilizzatori.`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      160
    );

    doc.text(
      doc.splitTextToSize(
        `E’ stata infine erogata ai lavoratori utilizzanti i DPI la necessaria informazione e formazione atta a:`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      204
    );

    doc.text(
      doc.splitTextToSize(
        `a) consentire al datore di lavoro di mantenere in efficienza i DPI e assicurane le condizioni d’igiene, informandolo degli interventi che gli stessi necessitano per manutenzione, riparazioni e per le sostituzioni necessarie, secondo le eventuali indicazioni fornite dal fabbricante;`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      216
    );
    doc.text(
      doc.splitTextToSize(
        `b) provvedere a che i DPI siano utilizzati soltanto per gli usi previsti, salvo casi specifici ed eccezionali, conformemente alle informazioni del fabbricante`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      252
    );
    doc.text(
      doc.splitTextToSize(
        `In merito a quanto sopra dettagliato, il datore di lavoro ha reso disponibile nell’azienda informazioni adeguate su ogni DPI`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      282
    );
    doc.text(
      doc.splitTextToSize(
        `Al termine dell’attività si rilascia copia della presente a comprova dell’attività svolta.`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      312
    );

    doc.text(
      doc.splitTextToSize(
        `Luogo ____________________ data ___/___/______`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      360
    );

    doc.text(`____________________`, 33, 410);
    doc.text(`(Firma del lavoratore)`, 40, 430);
    doc.text(`____________________`, 197, 410);
    doc.text(`(Firma del Datore di`, 210, 430);
    doc.text(`lavoro o della direzione)`, 200, 442);

    doc.output("pdfobjectnewwindow", "DPI.pdf");
  };

  useEffect(() => {
    (async () => {
      try {
        let responseMansioniRischiDPI = await unsplash.get(
          "mansioniRischiDPI",
          {
            params: {
              All: true,
              IDMansioneSicurezza: lavoratore.MansioneSicurezza,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setDpi(responseMansioniRischiDPI.data.data);

        let response = await unsplash.get("addestramentoLavoratore", {
          params: {
            addestramento: idLavoratore,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });

        setAddestramentoLavoratori(response.data.data);
        setLoading(false);
        let responseSedi = await unsplash.get("sedi", {
          params: { id: lavoratore.Cliente },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        const sedi = responseSedi.data.data.filter(
          (el) => el.ID === lavoratore.Sede
        );
        if (sedi.length > 0) {
          setSede(sedi[0]);
        }

        let responseOrganigramma = await unsplash.get("organigrammi", {
          params: { sede: lavoratore.Sede, cliente: lavoratore.Cliente },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
      } catch (error) {
        console.error(error.message);
      }
    })();
  }, []);
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />

      <table id="table-Addestramento-DPI" hidden>
        <tr>
          <th scope="col">DPI</th>
          <th scope="col">DATA</th>
          <th scope="col">
            ORARIO
            <br /> INIZIO
          </th>
          <th scope="col">
            ORARIO
            <br /> FINE
          </th>
          <th scope="col">ESITO</th>
          <th scope="col">ADDESTRATORE</th>
          <th scope="col">
            FIRMA
            <br /> ADDESTRATORE
          </th>
        </tr>
        {addestramentoSel?.map((el) => {
          let arg = "";
          switch (el.argomento) {
            case 1:
              arg = "Utilizzo della macchina/attrezzatura";
              break;
            case 2:
              arg = "Svolgimento delle operazioni di";
              break;
            case 3:
              arg = "Applicazione delle seguenti procedure:";
              break;
            case 4:
              arg = "Utilizzo sostanze chimiche";
              break;
            case 5:
              arg = "Comportamento in caso di emergenza";
              break;
            case 6:
              arg = "Altro";
              break;
            default:
              arg = "";
              break;
          }
          let motivo = "";
          switch (el.motivo) {
            case 1:
              motivo = "Costituzione del rapporto di lavoro";
              break;
            case 2:
              motivo = "Trasferimento del lavoratore";
              break;
            case 3:
              motivo = "Cambiamento di mansioni del lavoratore";
              break;
            case 4:
              motivo =
                "Introduzione di nuove attrezzature di lavoro o di nuove tecnologie";
              break;
            case 5:
              motivo = "Introduzione di nuove sostanze e preparati pericolosi";
              break;
            case 6:
              motivo = "Introduzione di nuove sostanze e preparati pericolosi";
              break;
            case 7:
              motivo = "Altro";
              break;
            default:
              motivo = "";
              break;
          }
          let tipo = {};
          tipo = dpi?.filter((d) => d.ID === el?.id_dpi)[0];
          return (
            <>
              <tr>
                <td>{tipo?.Tipologia}</td>
                <td>{moment(el?.data).format("DD/MM/YYYY")}</td>
                <td>
                  {moment(new Date("1970-01-01T" + el?.orario_inizio)).format(
                    "HH:mm"
                  )}
                </td>
                <td>
                  {moment(new Date("1970-01-01T" + el?.orario_fine)).format(
                    "HH:mm"
                  )}
                </td>
                <td>{el?.esito === 0 ? "Positivo" : "Negativo"}</td>
                <td>{cap(el?.nome_cognome?.split(" "))}</td>
                <td></td>
              </tr>
            </>
          );
        })}
      </table>
      <table id="table-Addestramento" hidden>
        <tr>
          <th scope="col">DATA</th>
          <th scope="col">
            ORARIO
            <br /> INIZIO
          </th>
          <th scope="col">
            ORARIO
            <br /> FINE
          </th>
          <th scope="col">
            ARGOMENTO
            <br /> TRATTATO
          </th>
          <th scope="col">MOTIVO</th>
          <th scope="col">ESITO</th>
          <th scope="col">ADDESTRATORE</th>
          <th scope="col">
            FIRMA
            <br /> ADDESTRATORE
          </th>
        </tr>
        {addestramentoSel?.map((el) => {
          let arg = "";
          switch (el.argomento) {
            case 1:
              arg = "Utilizzo della macchina/attrezzatura";
              break;
            case 2:
              arg = "Svolgimento delle operazioni di";
              break;
            case 3:
              arg = "Applicazione delle seguenti procedure:";
              break;
            case 4:
              arg = "Utilizzo sostanze chimiche";
              break;
            case 5:
              arg = "Comportamento in caso di emergenza";
              break;
            case 6:
              arg = "Altro";
              break;
            default:
              arg = "";
              break;
          }
          let motivo = "";
          switch (el.motivo) {
            case 1:
              motivo = "Costituzione del rapporto di lavoro";
              break;
            case 2:
              motivo = "Trasferimento del lavoratore";
              break;
            case 3:
              motivo = "Cambiamento di mansioni del lavoratore";
              break;
            case 4:
              motivo =
                "Introduzione di nuove attrezzature di lavoro o di nuove tecnologie";
              break;
            case 5:
              motivo = "Introduzione di nuove sostanze e preparati pericolosi";
              break;
            case 6:
              motivo = "Introduzione di nuove sostanze e preparati pericolosi";
              break;
            case 7:
              motivo = "Altro";
              break;
            default:
              motivo = "";
              break;
          }
          return (
            <>
              <tr>
                <td>{moment(el?.data).format("DD/MM/YYYY")}</td>
                <td>
                  {moment(new Date("1970-01-01T" + el?.orario_inizio)).format(
                    "HH:mm"
                  )}
                </td>
                <td>
                  {moment(new Date("1970-01-01T" + el?.orario_fine)).format(
                    "HH:mm"
                  )}
                </td>
                <td>
                  {arg} {el?.argomento_note}
                </td>
                <td>
                  {motivo} {el?.motivo_note}
                </td>
                <td>{el?.esito === 0 ? "Positivo" : "Negativo"}</td>
                <td>{cap(el?.nome_cognome?.split(" "))}</td>
                <td></td>
              </tr>
            </>
          );
        })}
      </table>

      <Grid
        container
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="flex-end"
      >
        <NewAddestramento
          id="edit"
          addestramento={addestramentoSelezionato}
          //idMansione={lavoratore.MansioneSicurezza}
          dpi={dpi}
          saveFunction={handleSave}
          title="Modifica Addestramento"
        />
        <NewAddestramento
          id="new"
          addestramento={addestramentoSelezionato}
          //idMansione={lavoratore.MansioneSicurezza}
          dpi={dpi}
          saveFunction={handleSave}
          title="Nuovo Addestramento"
        />
      </Grid>
      <Box
        sx={{
          height: "58vh",
          width: 1,
        }}
      >
        <DataGridPro
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooterPersonalized,
          }}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={loading}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rowHeight={52}
          rows={addestramentoLavoratori}
          columns={columns}
          checkboxSelection
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = addestramentoLavoratori.filter((row) =>
              selectedIDs.has(row.id)
            );
            setRowSelectionModel(ids);

            const isDPI = selectedRowData.filter((el) => el.id_dpi === 0);
            if (isDPI.length > 0) {
              setStampaDPI(false);
            } else {
              setStampaDPI(true);
            }

            setAddestramentoSel(selectedRowData);

            let mins = 0;
            let h = 0;
            selectedRowData.map((el) => {
              var startTime = moment(el.orario_inizio, "HH:mm");
              var endTime = moment(el.orario_fine, "HH:mm");
              // calculate total duration
              var duration = moment.duration(endTime.diff(startTime));
              // duration in hours
              var hours = parseInt(duration.asHours());

              // duration in minutes
              var minutes = parseInt(duration.asMinutes()) % 60;

              //trovo modo per incrementare i minuti e le ore e dividere i miuti per 60
              mins = mins + minutes;
              while (mins >= 60) {
                mins = mins - 60;
                h = h + 1;
              }
              h = h + hours;
              setDurataTot(h + " ore e " + mins + " minuti");
            });
          }}
          rowSelectionModel={rowSelectionModel}
          getRowId={(row) => row.id}
          pageSize={20}
          rowsPerPageOptions={[20]}
          initialState={{
            pinnedColumns: { left: ["Actions"] },
          }}
          onCellClick={(params, e) => {
            if (params.field === "Actions") {
              e.stopPropagation();
            }
          }}
        />
      </Box>
    </>
  );
};

const NewAddestramento = (props) => {
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [checked, setChecked] = useState(false);
  const [orario_inizio, setOrario_inizio] = useState(null);

  const [dpiSel, setDpiSel] = useState(null);
  const [orario_fine, setOrario_fine] = useState(null);
  const [argomento, setArgomento] = useState("");
  const [addestramento, setAddestramento] = useState({
    data: null,
    orario_inizio: null,
    orario_fine: null,
    nome_cognome: "",
    id_dpi: 0,
    mansione: "",
    argomento: 0,
    argomento_note: "",
    motivo: 0,
    motivo_note: "",
    esito: 0,
  });

  const handleClickOpen = async () => {
    if (props.id === "edit") {
      setDpiSel(
        props.dpi?.filter((c) => c.ID === props.addestramento.id_dpi)[0]
      );
      setAddestramento(props.addestramento);
      setOrario_fine(
        moment(new Date("2022-09-02 " + props.addestramento.orario_fine))
      );
      setOrario_inizio(
        moment(new Date("2022-09-02 " + props.addestramento.orario_inizio))
      );
    } else {
      setDpiSel(null);
    }
    setOpen(true);
  };
  const handleClose = () => {
    /* setAddestramento(newAddestramento); */
    setOrario_inizio(null);
    setOrario_fine(null);
    setAddestramento({
      data: null,
      orario_inizio: null,
      orario_fine: null,
      nome_cognome: "",
      id_dpi: 0,
      mansione: "",
      argomento: 0,
      argomento_note: "",
      motivo: 0,
      motivo_note: "",
      esito: 0,
    });
    setOpen(false);
  };
  const handleChange = (e) => {
    let copiaAddestramento = { ...addestramento };
    copiaAddestramento[e.target.name] = e.target.value;
    setAddestramento(copiaAddestramento);
  };
  const handleSave = async () => {
    let add = { ...addestramento };
    add.orario_inizio = orario_inizio ? orario_inizio.format("HH:mm:ss") : null;
    add.orario_fine = orario_fine ? orario_fine.format("HH:mm:ss") : null;
    props.saveFunction(add, props.id);
    handleClose();
  };

  const handleChangeArgomento = (event) => {
    setArgomento(event.target.value);
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
      >
        {props.id === "edit" ? (
          <Button
            startIcon={<Add />}
            onClick={handleClickOpen}
            id={props.id}
            sx={{ color: "white", display: "none" }}
            variant="outlined"
            className="ButtonMiller"
          >
            Aggiungi
          </Button>
        ) : (
          <Button
            startIcon={<Add />}
            onClick={handleClickOpen}
            id={props.id}
            sx={{ color: "white" }}
            variant="outlined"
            className="ButtonMiller"
          >
            Aggiungi
          </Button>
        )}
      </ButtonGroup>

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="lg"
          TransitionComponent={Transition}
        >
          {/* Title Container */}
          <DialogTitle
            sx={{
              mx: "auto",
            }}
          >
            {props.title}
          </DialogTitle>
          {/* Body Container */}
          <DialogContent>
            {/* Row 2 */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DatePicker
                    ampmInClock={false}
                    renderInput={(props) => (
                      <TextField sx={{ mt: 1 }} fullWidth {...props} />
                    )}
                    label="Data"
                    value={moment(addestramento.data)}
                    onChange={(newValue) => {
                      let add = { ...addestramento };
                      add.data = newValue
                        ? moment(newValue).format("YYYY-MM-DD")
                        : null;
                      setAddestramento(add);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={addestramento.nome_cognome}
                  type="text"
                  id="outlined-basic"
                  label="Nome e Cognome"
                  name="nome_cognome"
                  onChange={handleChange}
                  fullWidth
                  sx={{ mt: 1 }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <TimePicker
                    ampmInClock={false}
                    renderInput={(props) => <TextField fullWidth {...props} />}
                    label="Orario inizio"
                    value={moment(orario_inizio)}
                    onChange={(newValue) => {
                      setOrario_inizio(newValue);
                      /* let add = { ...addestramento };
                      add.orario_inizio = newValue ? newValue : null;

                      setAddestramento(add); */
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <TimePicker
                    ampmInClock={false}
                    renderInput={(props) => <TextField fullWidth {...props} />}
                    label="Orario fine"
                    value={moment(orario_fine)}
                    onChange={(newValue) => {
                      setOrario_fine(newValue);
                      /* let add = { ...addestramento };
                      add.orario_fine = newValue
                        ? moment(newValue).format("HH:mm:ss")
                        : null;
                      setAddestramento(add); */
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={addestramento.mansione}
                  id="outlined-basic"
                  label="Mansione"
                  name="mansione"
                  onChange={handleChange}
                  fullWidth
                  sx={{ mt: 0 }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Esito
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={addestramento?.esito}
                    name="esito"
                    onChange={handleChange}
                    autoWidth
                    label="Esito"
                  >
                    <MenuItem value={0}>Positivo</MenuItem>
                    <MenuItem value={1}>Negativo</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Argomento
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={addestramento?.argomento}
                    name="argomento"
                    onChange={handleChange}
                    autoWidth
                    label="Argomento"
                  >
                    <MenuItem value={0}>
                      <em>Seleziona...</em>
                    </MenuItem>
                    <MenuItem value={1}>
                      Utilizzo della macchina/attrezzatura
                    </MenuItem>
                    <MenuItem value={2}>
                      Svolgimento delle operazioni di
                    </MenuItem>
                    <MenuItem value={3}>
                      Applicazione delle seguenti procedure:
                    </MenuItem>
                    <MenuItem value={4}>Utilizzo sostanze chimiche</MenuItem>
                    <MenuItem value={5}>
                      Comportamento in caso di emergenza
                    </MenuItem>
                    <MenuItem value={6}>Altro</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={addestramento.argomento_note}
                  type="text"
                  id="outlined-basic"
                  label="Note Argomento"
                  name="argomento_note"
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Motivo
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={addestramento?.motivo}
                    name="motivo"
                    onChange={handleChange}
                    autoWidth
                    label="Motivo"
                  >
                    <MenuItem value={0}>
                      <em>Seleziona...</em>
                    </MenuItem>
                    <MenuItem value={1}>
                      Costituzione del rapporto di lavoro
                    </MenuItem>
                    <MenuItem value={2}>Trasferimento del lavoratore</MenuItem>
                    <MenuItem value={3}>
                      Cambiamento di mansioni del lavoratore
                    </MenuItem>
                    <MenuItem value={4}>
                      Introduzione di nuove attrezzature di lavoro o di nuove
                      tecnologie
                    </MenuItem>
                    <MenuItem value={5}>
                      Introduzione di nuove sostanze e preparati pericolosi
                    </MenuItem>
                    <MenuItem value={6}>
                      Introduzione di nuove sostanze e preparati pericolosi
                    </MenuItem>
                    <MenuItem value={7}>Altro</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={addestramento.motivo_note}
                  type="text"
                  id="outlined-basic"
                  label="Note Motivo"
                  name="motivo_note"
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  options={props.dpi}
                  value={dpiSel}
                  name="id_dpi"
                  getOptionLabel={(option) => option.Tipologia}
                  onChange={(event, newValue) => {
                    setDpiSel(newValue);
                    if (newValue) {
                      let add = { ...addestramento };
                      add.id_dpi = newValue.ID;
                      setAddestramento(add);
                    } else {
                      let add = { ...addestramento };
                      add.id_dpi = null;
                      setAddestramento(add);
                    }
                  }}
                  id="combo-box-demo"
                  renderInput={(params) => (
                    <TextField {...params} label="DPI" />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mx: 2,
            }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Close />}
            >
              Chiudi
            </Button>
            <Button
              onClick={handleSave}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Check />}
            >
              Salva
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};
const DeleteDialog = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare la addestramento selezionata?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare la seguente addestramento
            :
            <b>
              {moment(props.addestramento?.data).format("YYYY-MM-DD")} -{" "}
              {props.addestramento.nome_cognome}
            </b>
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={() => props.myFunction(props.addestramento)}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
