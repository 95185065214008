import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { isLogin,isToken } from '../utils';

function PrivateRouteLavoratore({ children }) {
    const logok = isLogin();


    return logok ? children : <Navigate to="/AreaLavoratore/Login" />;
  }

export default PrivateRouteLavoratore;
