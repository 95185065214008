import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import unsplash from "../api/unsplash";
import Avatar from "@mui/material/Avatar";
import GlobalSnackbar from "./Snackbar";
import LinearProgress from "@mui/material/LinearProgress";
import Switch from "@mui/material/Switch";

import {
  Grid,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Input,
  CardActionArea,
  CardMedia,
  Divider,
  CardActions,
  InputAdornment,
  ButtonGroup,
  Tooltip,
  FormControlLabel,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Zoom,
  DialogContentText,
  DialogActions,
  Autocomplete,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import { IconButton } from "@mui/material";

import {
  DataGridPro,
  itIT,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";

import {
  Add,
  Delete,
  Close,
  Check,
  Search,
  MenuBook,
  Circle,
} from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

function CustomToolbarG() {
  return (
    <GridToolbarContainer>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <GridToolbarQuickFilter placeholder="Cerca..." />
      </Grid>
    </GridToolbarContainer>
  );
}

let newUtenteCliente = {
  Username: "",
  Password: "",
  Tipo: 4,
  Medico: null,
  Cliente: 0,
  Attivo: 1,
  VisualizzaScadenzeWeb: 0,
};
const UtentiClienti = () => {
  const [utentiClienti, setUtentiClienti] = useState([]);
  const [utentiClientiVisibili, setUtentiClientiVisibili] = useState([]);
  const [loading, setLoading] = useState(true);
  const [vistaUtenteCliente, setVistaUtenteCliente] = useState([]);

  const [filtro, setFiltro] = useState({
    attivo: true,
    disattivo: false,
  });
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: 130,
      renderCell: (params) => (
        <>
          <NewUtenti
            id="edit"
            utenteCliente={params.row}
            saveFunction={handleSave}
            title="Modifica Utente"
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
          />
          <DeleteDialog categoria={params.row} myFunction={handleDelete} />
        </>
      ),
    },
    {
      filterable: false,
      field: "*",
      headerName: "Stato",
      width: 70,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          {params.row.Attivo === 1 ? (
            <Tooltip title="Attivo">
              <Circle
                sx={{ color: "#40C18F", width: "15px", height: "15px" }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Disattivo">
              <Circle
                sx={{ color: "#FF2768", width: "15px", height: "15px" }}
              />
            </Tooltip>
          )}
        </>
      ),
    },
    {
      field: "Username",
      headerName: "Utente",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  const reloadAfterUpload = async () => {
    try {
      let response = await unsplash.get("utentiClienti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setUtentiClienti(response.data.data);
      setUtentiClientiVisibili(
        response.data.data.filter((a) => a.Attivo === 1)
      );
      setLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleDelete = async (categ) => {
    try {
      await unsplash.delete("utentiClienti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          username: categ.Username,
        },
      });

      setSnack({
        severity: "success",
        messaggio: "Utente eliminato con successo",
      });
      setOpenSnack(true);
      reloadAfterUpload();
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione",
      });
      setOpenSnack(true);
      reloadAfterUpload();
    }
    setOpen(false);
  };
  useEffect(() => {
    (() => {
      reloadAfterUpload();
    })();
  }, []);

  const handleSave = async (utenteCliente, operazione) => {
    if (operazione === "new") {
      var md5 = require("md5");
      let nuovoUtente = { ...utenteCliente };

      nuovoUtente.Password = md5(nuovoUtente.Password);
      try {
        await unsplash.post(
          "utentiClienti",
          {
            utentiClienti: nuovoUtente,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Utente Cliente creato con successo",
        });
        setOpenSnack(true);

        reloadAfterUpload();
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante la creazione",
        });
        setOpenSnack(true);
      }
      handleClose();
    } else {
      try {
        await unsplash.put(
          "utentiClienti",
          {
            utentiClienti: utenteCliente,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Utente Cliente aggiornato con successo",
        });
        setOpenSnack(true);

        reloadAfterUpload();
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'aggiornamento",
        });
        setOpenSnack(true);
      }
      handleClose();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const filter = (e, tipologia) => {
    let filtroUp = { ...filtro };
    filtroUp[tipologia] = e.target.checked;
    setFiltro(filtroUp);

    let form = utentiClienti;
    if (!filtroUp.attivo) {
      form = form.filter((f) => f.Attivo !== 1);
    }
    if (!filtroUp.disattivo) {
      form = form.filter((f) => f.Attivo !== 0);
    }
    setUtentiClientiVisibili(form);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
          <FormControlLabel
            sx={{
              marginBottom: "0px!important",
              fontSize: "13px",
              color: "#11526F",
            }}
            control={
              <Checkbox
                size="small"
                checked={filtro.attivo}
                onClick={(e) => filter(e, "attivo")}
              />
            }
            label="Attivi"
          />
          <FormControlLabel
            sx={{
              marginBottom: "0px!important",
              fontSize: "13px",
              color: "#11526F",
            }}
            control={
              <Checkbox
                size="small"
                name="Disattivi"
                checked={filtro.disattivo}
                onClick={(e) => filter(e, "disattivo")}
              />
            }
            label="Disattivi"
          />
        </Grid>
      </GridToolbarContainer>
    );
  }
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Grid item xs={12} md={8}></Grid>
                  <Grid item xs={12} md={4}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <NewUtenti
                        id="new"
                        utenteCliente={vistaUtenteCliente}
                        saveFunction={handleSave}
                        title="Nuovo Utente"
                        snack={snack}
                        openSnack={openSnack}
                        handleCloseSnack={handleCloseSnack}
                        setSnack={setSnack}
                        setOpenSnack={setOpenSnack}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    height: { xs: "75vh", md: "60vh", xl: "75vh" },
                    width: 1,
                  }}
                >
                  <DataGridPro
                    components={{
                      Toolbar: CustomToolbar,
                      Footer: CustomFooterPersonalized,
                    }}
                    slots={{
                      loadingOverlay: LinearProgress,
                    }}
                    loading={loading}
                    localeText={
                      itIT.components.MuiDataGrid.defaultProps.localeText
                    }
                    rowHeight={52}
                    rows={utentiClientiVisibili}
                    initialState={{ pinnedColumns: { left: ["Actions"] } }}
                    columns={columns}
                    getRowId={(row) => row.Username}
                    pageSize={20}
                    rowsPerPageOptions={[20]}
                    disableSelectionOnClick
                  />
                </Box>
              </Grid>
            </>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default UtentiClienti;

/* Creazione Nuovi UtentiClienti */
const NewUtenti = (props) => {
  const [open, setOpen] = useState(false);
  const [clientiAssociati, setClientiAssociati] = useState([]);
  const [loading, setLoading] = useState(true);
  const [utenteCliente, setUtenteCliente] = useState({
    Username: "",
    Cliente: "",
    Email: "",
    Dettaglio: 0,
    Attivo: 0,
  });

  const column = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <DialogClienti
            reloadData={() => reloadData()}
            id="edit"
            utente={params.row}
            saveFunction={handleSave}
            clientiAssociati={clientiAssociati.map((elm) => elm.Cliente)}
            cliente={props.utenteCliente.Cliente}
            title="Modifica Cliente"
          />
          <IconButton
            aria-label="delete"
            onClick={async () => {
              try {
                await unsplash.delete("utentiClienti", {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                  },
                  data: {
                    id: params.row.ID,
                  },
                });
                //modifica chiamata con nuova tabella relazionele REL_UTENTICLIENTI_SEDI
                reloadData();

                props.setSnack({
                  severity: "success",
                  messaggio: "Cliente eliminato con successo",
                });
                props.setOpenSnack(true);
              } catch (error) {
                props.setSnack({
                  severity: "error",
                  messaggio: "Errore durante l'eliminazione",
                });
                props.setOpenSnack(true);
              }
            }}
          >
            <Delete
              style={{ height: "2rem", width: "2rem", color: "#FF2768" }}
            />
          </IconButton>
        </>
      ),
    },
    {
      field: "RagioneSociale",
      headerName: "Cliente",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  const handleClickOpen = async () => {
    if (props.id === "edit") {
      setUtenteCliente({
        Username: props.utenteCliente.Username,
        Attivo: props.utenteCliente.Attivo,
        Email: props.utenteCliente.Email,
        Dettaglio: props.utenteCliente.Dettaglio,
      });
      let res = await unsplash.get("utentiClienti", {
        //ricarico i valori
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: {
          utente: props.utenteCliente.Username,
        },
      });
      setClientiAssociati(res.data.data);
      setLoading(false);
    } else if (props.id === "new") {
      setUtenteCliente(newUtenteCliente);
    }
    setOpen(true);
    /* try {
      let response = await unsplash.get("clienti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { all: "yes" },
      });
      const cli = response.data.data;
      setClienti(response.data.data);
      if (props.id !== "new") {
        setClienteSel(
          response.data.data?.filter(
            (c) => c.ID === props.utenteCliente.Cliente
          )
        );
        
      }
    } catch (error) {
      console.error(error.message);
    }
    if (props.id === "edit") {
      setUtenteCliente({
        Username: props.utenteCliente.Username,
        Attivo: props.utenteCliente.Attivo,
      });
      let res = await unsplash.get("utentiClienti", {
        //ricarico i valori
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: {
          relUtente: props.utenteCliente.Username,
        },
      });
      setClientiAssociati(res.data.data);
    } else if (props.id === "new") {
      setUtenteCliente(newUtenteCliente);
      setClienteSel(null);
    }
    setOpen(true); */
  };
  const handleClose = () => {
    setUtenteCliente(newUtenteCliente);
    setOpen(false);
  };
  const handleChange = (e) => {
    let copiaUtenteCliente = { ...utenteCliente };
    if (e.target.name === "Password") {
      copiaUtenteCliente[e.target.name] = e.target.value;
    } else {
      copiaUtenteCliente[e.target.name] = e.target.value;
    }

    setUtenteCliente(copiaUtenteCliente);
  };
  const handleSave = async () => {
    props.saveFunction(utenteCliente, props.id);
    handleClose();
  };

  const reloadData = async () => {
    let res = await unsplash.get("utentiClienti", {
      //ricarico i valori
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: {
        utente: props.utenteCliente.Username,
      },
    });
    setClientiAssociati(res.data.data);
  };

  return (
    <>
      {props.id === "edit" ? (
        <IconButton id={props.id} aria-label="edit" onClick={handleClickOpen}>
          <EditIcon
            style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
          />
        </IconButton>
      ) : (
        <Button
          startIcon={<Add />}
          onClick={handleClickOpen}
          id={props.id}
          sx={{ color: "white" }}
          variant="outlined"
          className="ButtonMiller"
        >
          Aggiungi
        </Button>
      )}

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="lg"
          TransitionComponent={Transition}
        >
          <DialogTitle
            sx={{
              mx: "auto",
            }}
          >
            {props.title}
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2} sx={{ alignItems: "flex-end" }}>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={props.id === "new" ? false : true}
                  value={utenteCliente.Username}
                  id="outlined-basic"
                  label="Utente"
                  name="Username"
                  onChange={handleChange}
                  fullWidth
                  sx={{ mt: 2 }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={utenteCliente.Email}
                  id="outlined-basic"
                  label="Email"
                  name="Email"
                  onChange={handleChange}
                  fullWidth
                  sx={{ mt: 2 }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={props.id === "new" ? 6 : 12}
                display={"flex"}
                justifyContent="center"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={utenteCliente?.Dettaglio === 1}
                      onChange={(e) =>
                        setUtenteCliente({
                          ...utenteCliente,
                          Dettaglio: e.target.checked ? 1 : 0,
                        })
                      }
                    />
                  }
                  label="Visualizza Dettaglio"
                />
              </Grid>

              {props.id === "new" ? (
                <>
                  <Grid item xs={12} md={6}>
                    <TextField
                      type="password"
                      value={utenteCliente.Password}
                      id="outlined-basic"
                      label="Password"
                      name="Password"
                      onChange={handleChange}
                      fullWidth
                      sx={{ mt: 2 }}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    container
                    xs={12}
                    sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
                  >
                    <DialogClienti
                      reloadData={reloadData}
                      id="new"
                      utente={utenteCliente.Username}
                      saveFunction={handleSave}
                      clientiAssociati={clientiAssociati.map(
                        (elm) => elm.Cliente
                      )}
                      cliente={props.utenteCliente.Cliente}
                      title="Nuovo Cliente"
                    />
                  </Grid>
                </>
              )}
            </Grid>

            {props.id === "edit" ? (
              <Box
                sx={{
                  height: 500,
                  width: 1,
                }}
              >
                <DataGridPro
                  initialState={{ pinnedColumns: { left: ["Actions"] } }}
                  components={{
                    Toolbar: CustomToolbarG,
                    Footer: CustomFooterPersonalized,
                  }}
                  slots={{
                    loadingOverlay: LinearProgress,
                  }}
                  loading={loading}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  rowHeight={52}
                  rows={clientiAssociati}
                  columns={column}
                  getRowId={(row) => row.ID}
                  pageSize={20}
                  rowsPerPageOptions={[20]}
                  disableSelectionOnClick
                />
              </Box>
            ) : (
              <></>
            )}

            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Button
                  onClick={handleClose}
                  variant="contained"
                  className="ButtonMiller"
                  startIcon={<Close />}
                >
                  Chiudi
                </Button>
                <Button
                  onClick={handleSave}
                  variant="contained"
                  className="ButtonMiller"
                  startIcon={<Check />}
                >
                  Salva
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
};

const DialogClienti = (props) => {
  const [open, setOpen] = useState(false);
  const [openSede, setOpenSede] = useState(false);
  const [clienti, setClienti] = useState([]);
  const [clienteSel, setClienteSel] = useState(null);

  const [cliente, setCliente] = useState(0);

  const [sediAssociate, setSediAssociate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sedi, setSedi] = useState([]);
  const [sediSel, setSediSel] = useState(null);
  const [sede, setSede] = useState(null);

  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const column = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "70",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="delete"
            onClick={async () => {
              try {
                await unsplash.delete("utentiClienti", {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                  },
                  data: {
                    sedeRel: params.row.ID,
                  },
                });

                setSnack({
                  severity: "success",
                  messaggio: "Sede eliminata con successo",
                });
                setOpenSnack(true);
              } catch (error) {
                setSnack({
                  severity: "error",
                  messaggio: "Errore durante l'eliminazione",
                });
                setOpenSnack(true);
              }
              loadAllSedi();
            }}
          >
            <Delete
              style={{ height: "2rem", width: "2rem", color: "#FF2768" }}
            />
          </IconButton>
        </>
      ),
    },
    {
      field: "Descrizione",
      headerName: "Descrizione",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  const loadAllSedi = async () => {
    let responseUtentiClienti = await unsplash.get("utentiClienti", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { relUtente: props.utente.ID },
    });
    setSediAssociate(responseUtentiClienti.data.data);
    setLoading(false);
    const onlyIDSediAssociate = responseUtentiClienti.data.data.map(
      (el) => el.ID_Sedi
    );

    let responseSedi = await unsplash.get("sedi", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: props.utente.Cliente },
    });
    let sediFiltrati = responseSedi.data.data.filter((c) => {
      return onlyIDSediAssociate.indexOf(c.ID) === -1;
    });

    setSedi(sediFiltrati);
  };

  const handleClickOpen = async () => {
    try {
      if (props.id === "edit") {
        setClienteSel(props.utente);
        loadAllSedi();
      }
      let response = await unsplash.get("clienti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { all: "yes" },
      });
      const cli = response.data.data;
      let clientiFiltrati = cli.filter((c) => {
        return props.clientiAssociati.indexOf(c.ID) === -1;
      });
      setClienti(clientiFiltrati.filter((el) => el.Cessato === 0));
    } catch (error) {
      console.error(error.message);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setClienteSel(null);
    setCliente(0);
    setOpen(false);
  };

  const handleSaveSedi = async () => {
    let sedeAssociata = {
      ID_Rel_UtentiClienti: props.utente.ID,
      ID_Sedi: sede,
    };
    try {
      await unsplash.post(
        "utentiClienti",
        {
          relUtente: sedeAssociata,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      setSnack({
        severity: "success",
        messaggio: "Sede associata con successo",
      });
      setOpenSnack(true);
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante la creazione",
      });
      setOpenSnack(true);
    }
    setSediSel(null);
    setOpenSede(false);
    loadAllSedi();
  };
  const handleSave = async () => {
    let nuovoCliente = {
      Utente: props.utente,
      Cliente: cliente,
    };
    try {
      await unsplash.post(
        "utentiClienti",
        {
          clienti: nuovoCliente,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      setSnack({
        severity: "success",
        messaggio: "Cliente aggiunto con successo",
      });
      setOpenSnack(true);
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante la creazione",
      });
      setOpenSnack(true);
    }
    props.reloadData();
    handleClose();
  };

  return (
    <>
      {props.id === "edit" ? (
        <IconButton id={props.id} aria-label="edit" onClick={handleClickOpen}>
          <EditIcon
            style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
          />
        </IconButton>
      ) : (
        <Button
          startIcon={<Add />}
          onClick={handleClickOpen}
          id={props.id}
          sx={{ color: "white" }}
          variant="outlined"
          className="ButtonMiller"
        >
          Aggiungi
        </Button>
      )}

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="lg"
          TransitionComponent={Transition}
        >
          <GlobalSnackbar
            severity={snack.severity}
            messaggio={snack.messaggio}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
          />
          <DialogTitle
            sx={{
              mx: "auto",
            }}
          >
            {props.title}
          </DialogTitle>
          <DialogContent>
            <Grid item xs={12} style={{ marginTop: "10px" }}>
              <Autocomplete
                id="combo-box-demo"
                options={clienti}
                fullWidth
                value={clienteSel}
                getOptionLabel={(option) => option.RagioneSociale}
                onChange={(event, newValue) => {
                  setClienteSel(newValue);
                  if (newValue) {
                    setCliente(newValue.ID);
                  }
                }}
                disabled={props.id === "new" ? false : true}
                renderInput={(params) => (
                  <TextField {...params} label="Cliente" />
                )}
              />
            </Grid>

            {props.id === "edit" ? (
              <>
                <Box
                  sx={{
                    height: 500,
                    width: 1,
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      mt: 2,
                    }}
                  >
                    <Button
                      onClick={() => setOpenSede(true)}
                      variant="contained"
                      className="ButtonMiller"
                      startIcon={<Add />}
                    >
                      Aggiungi
                    </Button>
                  </Grid>
                  <DataGridPro
                    initialState={{ pinnedColumns: { left: ["Actions"] } }}
                    components={{
                      Toolbar: CustomToolbarG,
                      Footer: CustomFooterPersonalized,
                    }}
                    slots={{
                      loadingOverlay: LinearProgress,
                    }}
                    loading={loading}
                    localeText={
                      itIT.components.MuiDataGrid.defaultProps.localeText
                    }
                    rowHeight={52}
                    rows={sediAssociate}
                    columns={column}
                    getRowId={(row) => row.ID}
                    pageSize={20}
                    rowsPerPageOptions={[20]}
                    disableSelectionOnClick
                  />
                </Box>
              </>
            ) : (
              <></>
            )}
          </DialogContent>
          <CardActions
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mx: 3,
              mt: 2,
            }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Close />}
            >
              Chiudi
            </Button>
            <Button
              onClick={handleSave}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Check />}
            >
              Salva
            </Button>
          </CardActions>
        </Dialog>

        <Dialog
          open={openSede}
          onClose={() => {
            setOpenSede(false);
          }}
          fullWidth={true}
          maxWidth="xs"
          TransitionComponent={Transition}
        >
          <DialogTitle
            sx={{
              mx: "auto",
            }}
          >
            {props.title}
          </DialogTitle>
          <DialogContent>
            <Grid item xs={12} style={{ marginTop: "10px" }}>
              <Autocomplete
                id="combo-box-demo"
                options={sedi}
                fullWidth
                value={sediSel}
                getOptionLabel={(option) => option.Descrizione}
                onChange={(event, newValue) => {
                  setSediSel(newValue);
                  if (newValue) {
                    setSede(newValue.ID);
                  }
                }}
                renderInput={(params) => <TextField {...params} label="Sede" />}
              />
            </Grid>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Button
                  onClick={() => setOpenSede(false)}
                  variant="contained"
                  className="ButtonMiller"
                  startIcon={<Close />}
                >
                  Chiudi
                </Button>
                <Button
                  onClick={handleSaveSedi}
                  variant="contained"
                  className="ButtonMiller"
                  startIcon={<Check />}
                >
                  Salva
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
};

const DeleteDialog = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare il cliente selezionato?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare il seguente cliente:
            <br />
            <b>{props.categoria.Username}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={() => props.myFunction(props.categoria)}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
