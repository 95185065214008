import { Folder, Search, Verified } from "@mui/icons-material";
import React, { useEffect, useState } from "react";

/*
<ContainerList
  data={arrayOfObjects}//ogni singolo oggetto verrà inserito in una riga
  header={["Giorno", "Visita"]}//nome delle due colonne
  leftCard={["DataFilter"]}//specifico il nome della proprietà di cui il valore verrà inserito nella prima colonna
  rightCard={[//gli oggetti rappresentano le righe della Card di destra
    { title: true, prop: "Lavoratori" },//se viene aggiuntà la proprietà Title verrà messo per primo affiancato dal "titleCard"
    { prop: "Luogo", icon: <Place fontSize="12" /> },//riga classica con icona e nome della proprietà del componente
  ]}
  titleCard="Lavoratori: "//Stringa che viene affiancata al titolo
  additionalFilter={["DataFilter"]}//aggiungo i campi che voglio filtrare oltre alle "props" indicati nei componenti della rightCard
  component={<DetailVisiteComponent />}//componente a cui viene assegnato la props "el" con i valori della riga corrispondente
  page="Formazione"//per l'utilizzo del componente in una pagina di formazione
  home={true}//nasconde determinate parti del componente per renderlo funzionale all'homepage
/>
*/

export const ContainerList = ({
  data,
  header,
  rightCard,
  leftCard,
  additionalFilter,
  titleCard,
  actions,
  page,
  home,
  component,
  otherFilter,
}) => {
  const [list, setList] = useState([]);
  const [listBk, setListBk] = useState([]);

  const handleFilter = (e) => {
    if (e.target.value === "") return setList([...listBk]);
    //creo array di proprietà interne alle cards
    const propertyToFilter = [
      ...additionalFilter,
      ...rightCard.map((el) => el.prop),
    ];

    const filtered = listBk.filter((row) => {
      let valuesOfRow = [];
      //scompongo l'oggetto con valori e proprietà
      for (const [key, value] of Object.entries(row)) {
        //controllo che le proprietà siano le stesse interne alle cards
        if (propertyToFilter.includes(key)) {
          if (value?.length !== "" && value !== null) {
            valuesOfRow = [...valuesOfRow, value?.toString()?.toUpperCase()];
          }
        }
      }
      //filtro controllando se l'array contiene i valori
      if (
        valuesOfRow.filter((str) => str.includes(e.target.value?.toUpperCase()))
          .length !== 0
      )
        return true;
    });
    setList(filtered);
  };

  const handleActionFormazione = (row) => {
    actions(row.Allegati, row.Estensione, row.Descrizione, "download");
  };
  //aggiorno tutte le volte che viene aggiornato la props "data"
  useEffect(() => {
    (async () => {
      setList([...data]);
      setListBk([...data]);
    })();
  }, [data]);

  return (
    <>
      {
        //#region Sezione Filtro e Nomi Colonne
      }

      {!home && (
        <>
          <div className="relative w-full flex text-slate-500">
            <Search
              fontSize="large"
              className="absolute inset-y-0 start-0 top-[50%] -translate-y-[50%] flex items-center ps-2 pointer-events-none"
            />
            <input
              onChange={handleFilter}
              type="text"
              name="search"
              id="search"
              placeholder={"Filtra"}
              className="w-full px-4 py-2 ps-10 pe-10 text-lg text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus-visible:ring-blue-500 focus-visible:border-blue-500 focus:ring-blue-500 focus:border-blue-500"
            />
            <div className="text-gray-500 absolute inset-y-0 end-0 top-[50%] -translate-y-[50%] flex items-center pe-2 pointer-events-none">
              {"(" + list.length + ")"}
            </div>
          </div>
          {otherFilter && (
            <div className="relative w-full grid grid-cols-2 py-4 gap-2">
              {otherFilter}
            </div>
          )}
          <div className="grid grid-cols-12 w-full text-slate-500 dark:text-white text-lg sm:text-xl">
            <div className="col-span-4 sm:col-span-3 p-4 order-2 sm:order-1">
              {header[0]}
            </div>
            <div className="col-span-6 sm:col-span-5 p-4 order-3 sm:order-2">
              {header[1]}
            </div>
          </div>
        </>
      )}
      {
        //#endregion
      }
      {list.length !== 0 ? (
        list.map((row) => {
          //clono il componente assegnando tutti i valori della riga alla proprietà el
          const folderComp = React.Children.map(component, (child, index) => {
            return React.cloneElement(child, {
              el: row,
            });
          });
          return (
            <>
              <div
                className="grid grid-cols-12 w-full appear-effect"
                key={row.ID}
              >
                {
                  //#region Sezione Left Card
                }
                {!home && (
                  <>
                    <div className="col-span-4 sm:col-span-3 border-r-4 border-primary-600 dark:border-slate-300 dark:text-slate-300 p-3 sm:p-6 relative">
                      {leftCard?.map((component) => {
                        //valore dell'array
                        const property = component;
                        if (
                          row[property]?.length !== "" &&
                          row[property] !== null
                        ) {
                          return (
                            <>
                              <span className="text-[14px] sm:text-base">
                                {row[property]}
                              </span>
                              <br />
                            </>
                          );
                        }
                      })}
                      {!home && (
                        <div className="absolute w-4 h-4 bg-primary-600 dark:bg-slate-300 rounded-full -right-[10px] top-2 "></div>
                      )}
                    </div>
                  </>
                )}
                {
                  //#endregion
                }

                {
                  //#region Sezione Right Card
                }

                <div
                  className={`${
                    !home
                      ? "col-span-8 sm:col-span-9"
                      : "col-span-12 sm:col-span-12"
                  } bg-white border border-gray-300 rounded-md p-2 sm:p-4 my-2 ml-3 mr-1 relative flex flex-row justify-between items-start overflow-x-auto`}
                >
                  <div>
                    {rightCard?.map((component) => {
                      const property = component.prop;
                      const icon = component.icon;
                      if (component.title) {
                        return (
                          <>
                            <span className="text-md sm:text-lg font-semibold">
                              {titleCard}
                            </span>
                            <span className="mr-12">{row[property]}</span>
                            <br />
                          </>
                        );
                      } else if (
                        row[property] !== "" &&
                        row[property] !== null
                      ) {
                        return (
                          <>
                            <span className="text-slate-400 mr-1">{icon}</span>
                            <span className="text-xs sm:text-sm">
                              {row[property]}
                            </span>
                            <br />
                          </>
                        );
                      }
                    })}
                  </div>
                  {
                  //#region Componente con azione esterna
                }

                  {page !== "Formazione" ? (
                    <div>{folderComp}</div>
                  ) : row.Allegati ? (
                    <Verified
                      sx={{ fontSize: { sm: 32, xs: 24 } }}
                      className=" text-primary-600 rounded-full right-2 top-2"
                      onClick={() => handleActionFormazione(row)}
                    />
                  ) : (
                    <div></div>
                  )}
                  {
                  //#endregion
                }
                </div>
                {
                  //#endregion
                }
              </div>
            </>
          );
        })
      ) : (
        <>
          {
            //#region Sezione Componente Vuoto
          }
          <div className="w-full  appear-effect">
            <div className="w-full flex flex-row-reverse justify-between items-center border border-gray-300 bg-white rounded-md py-4 px-2 sm:px-4 my-2  relative overflow-x-auto">
              <div className="w-full flex items-center">
                <div className="hidden sm:block">
                  <Folder
                    sx={{ fontSize: { sm: 42, xs: 24 } }}
                    className="mr-4 text-primary-600"
                  />
                </div>
                <div>
                  <span className="text-sm sm:text-base font-semibold  ">
                    Nessuna Visita Presente
                  </span>
                </div>
              </div>
            </div>
          </div>
          {
            //#endregion
          }
        </>
      )}
    </>
  );
};
