import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Tab,
  Tabs,
  Button,
  Typography,
  Grid,
  TextField,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormControl,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Input,
  CardActionArea,
  CardActions,
  Switch,
} from "@mui/material";
import PropTypes from "prop-types";
import unsplash from "../api/unsplash";
import moment from "moment";
import momentTimezone from "moment-timezone";
import GlobalSnackbar from "./Snackbar";

import "moment/locale/it";
import { LocalizationProvider,DatePicker,PickersDay,TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment as DateAdapter} from '@mui/x-date-pickers/AdapterMoment'

import {
  Euro,
  AttachFile,
  AccountCircle,
  Check,
  Engineering,
  ArrowBack,
  MeetingRoomIcon,
  Send,
} from "@mui/icons-material";
import Allegati from "./Allegati";
import FatturazioneMedico from "./DettaglioMedico/FatturazioneMedico";

const DettaglioMedico = () => {
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [medicoCopy, setMedicoCopy] = useState(location.state.medico);
  const [medico, setMedico] = useState(location.state.medico);
  const navigate = useNavigate();
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange = (e) => {
    let med = { ...medico };
    med[e.target.name] = e.target.value;
    setMedico(med);
  };

  const handleImage = async (e) => {
    const img = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(img);
    reader.onload = function () {
      document.getElementById("firmaFormatore").src = reader.result;
      let m = { ...medico };
      let foto = reader.result.replace("data:image/jpeg;base64,", "");
      foto = foto.replace("data:image/png;base64,", "");
      m.Firma = foto;
      setMedico(m);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleSave = async () => {
    let med = { ...medico };
    med.DataDiNascita = med.DataDiNascita
      ? moment(med.DataDiNascita).format("YYYY-MM-DD")
      : null;
    try {
      await unsplash.put(
        "medici",
        { medico: med },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({
        severity: "success",
        messaggio: "Medico aggiornato con successo",
      });
      setOpenSnack(true);
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'aggiornamento",
      });
      setOpenSnack(true);
    }
  };

  const handleSendCredential = async () => {
    try {
      let respLavoratore = await unsplash.post(
        "portaleMedici",
        { operation: "generateMail", medico: medico },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setOpenSnack(true);
      setSnack({
        severity: "success",
        messaggio: "Credenziali inviate con successo!",
      });
    } catch (e) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'invio delle credenziali",
      });
      setOpenSnack(true);
    }
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid item xs={12}>
        <Card sx={{ boxShadow: "none" }}>
          <CardHeader
            sx={{ textAlign: "center" }}
            title={medicoCopy.Nome + " " + medicoCopy.Cognome}
            titleTypographyProps={{ variant: "h4" }}
          />
        </Card>
        <Card sx={{ marginTop: "10px", boxShadow: "none" }}>
          <CardContent>
            <Box>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  textColor="inherit"
                  variant="scrollable"
                  onChange={handleChangeTab}
                  aria-label="basic tabs example"
                >
                  <Tab
                    icon={<AccountCircle />}
                    iconPosition="start"
                    label="Generale"
                    {...a11yProps(0)}
                  />
                  <Tab
                    icon={<AttachFile />}
                    iconPosition="start"
                    label="Allegati"
                    {...a11yProps(1)}
                  />
                  <Tab
                    icon={<Euro />}
                    iconPosition="start"
                    label="Clienti"
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                {/*GEnerale*/}
                {medico ? (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          className="ButtonMiller"
                          startIcon={<Send />}
                          onClick={handleSendCredential}
                        >
                          Invia Credenziali
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          id="outlined-basic"
                          label="Nome"
                          value={medico.Nome}
                          name="Nome"
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          id="outlined-basic"
                          label="Cognome"
                          value={medico.Cognome}
                          name="Cognome"
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          id="outlined-basic"
                          label="CF"
                          value={medico.CF}
                          name="CF"
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          id="outlined-basic"
                          label="Email"
                          value={medico.eMail}
                          name="eMail"
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          margin="dense"
                          name="Indirizzo"
                          label="Indirizzo"
                          type="text"
                          fullWidth
                          value={medico?.Indirizzo}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          margin="dense"
                          name="IndirizzoSecondario"
                          label="Indirizzo Secondario"
                          type="text"
                          fullWidth
                          value={medico?.IndirizzoSecondario}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Sesso
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={medico?.Sesso}
                            label="Sesso"
                            onChange={(e) =>
                              setMedico({
                                ...medico,
                                Sesso: e.target.value,
                              })
                            }
                          >
                            <MenuItem key={0} value={0}>
                              Maschio
                            </MenuItem>
                            <MenuItem key={1} value={1}>
                              Femmina
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <LocalizationProvider dateAdapter={DateAdapter}>
                          <DatePicker
                            ampmInClock={false}
                            renderInput={(props) => (
                              <TextField {...props} fullWidth />
                            )}
                            label="Data di Nascita"
                            value={moment(medico.DataDiNascita)}
                            onChange={(newValue) => {
                              let med = { ...medico };
                              med.DataDiNascita = newValue
                                ? newValue.format("YYYY-MM-DD")
                                : null;
                              setMedico(med);
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          id="outlined-basic"
                          label="Luogo di Nascita"
                          value={medico.LuogoDiNascita}
                          name="LuogoDiNascita"
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                          justifyContent: "start",
                          display: "flex",
                          alignItems: "center",
                        }}
                        fullWidth
                      >
                        Attivo:
                        <Switch
                          checked={medico?.Attivo === 1}
                          onChange={(e) => {
                            setMedico({
                              ...medico,
                              Attivo: medico?.Attivo === 1 ? 0 : 1,
                            });
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Card
                        sx={{
                          mt: 2,
                          alignContent: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Card
                          sx={{
                            py: 4,
                            backgroundColor: "#11526F",
                          }}
                        >
                          <Box
                            height="140"
                            src={
                              medico.Firma !== "" && medico.Firma
                                ? "data:image/png;base64," + medico.Firma
                                : require("../assets/images/MILLERLogo.jpg")
                            }
                            id="firmaFormatore"
                            alt="img"
                            component="img"
                            sx={{
                              height: "100%",
                              maxHeight: "250px",
                              mx: "auto",
                              display: "flex",
                            }}
                          />
                          <label
                            htmlFor="contained-button-file"
                            style={{ display: "none", margin: 0 }}
                          >
                            <Input
                              accept="image/*"
                              id="file"
                              name="Firma"
                              multiple
                              type="file"
                              onChange={(e) => handleImage(e)}
                              hidden
                            />
                          </label>
                        </Card>
                        <CardActions>
                          <Button
                            fullWidth
                            variant="text"
                            onClick={() => {
                              document.getElementById("file").click();
                            }}
                          >
                            Carica Foto
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      sx={{
                        display: "flex",
                        mt: 2,
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        variant="contained"
                        className="ButtonMiller"
                        startIcon={<ArrowBack />}
                        onClick={() => navigate(-1)}
                      >
                        INDIETRO
                      </Button>
                      <Button
                        variant="contained"
                        className="ButtonMiller"
                        startIcon={<Check />}
                        onClick={handleSave}
                      >
                        Salva
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <CircularProgress color="primary" />
                )}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {/*Protocollo sanitario*/}

                <Allegati
                  idMedico={location.state.medico.ID}
                  id={location.state.medico.ID}
                  colonna="Medico"
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <FatturazioneMedico medico={location.state.medico.ID} />
              </TabPanel>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default DettaglioMedico;
