import React, { useEffect, useState, useMemo } from "react";
import unsplash from "../../api/unsplash";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import {
  IconButton,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Autocomplete,
  ButtonGroup,
  FormControlLabel,
  Checkbox,
  Zoom,
  LinearProgress,
} from "@mui/material";
import "../../style/style.css";
import {
  Edit,
  Delete,
  Add,
  Download,
  Upload,
  Close,
  Check,
} from "@mui/icons-material";
import GlobalSnackbar from "../Snackbar";
import moment from "moment";
import { LocalizationProvider,DatePicker,PickersDay,TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment as DateAdapter} from '@mui/x-date-pickers/AdapterMoment'
import Dropzone from "react-dropzone";
import { useDropzone } from "react-dropzone";
import { CustomFooterPersonalized } from "../../utils/CustomFooterPersonalized";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const ext = {
  doc: "application/msword",
  dot: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  dotx: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  docm: "application/vnd.ms-word.document.macroEnabled.12",
  dotm: "application/vnd.ms-word.template.macroEnabled.12",
  xls: "application/vnd.ms-excel",
  xlt: "application/vnd.ms-excel",
  xla: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xltx: "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  xlsm: "application/vnd.ms-excel.sheet.macroEnabled.12",
  xltm: "application/vnd.ms-excel.template.macroEnabled.12",
  xlam: "application/vnd.ms-excel.addin.macroEnabled.12",
  xlsb: "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  ppt: "application/vnd.ms-powerpoint",
  pot: "application/vnd.ms-powerpoint",
  pps: "application/vnd.ms-powerpoint",
  ppa: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  potx: "application/vnd.openxmlformats-officedocument.presentationml.template",
  ppsx: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  ppam: "application/vnd.ms-powerpoint.addin.macroEnabled.12",
  pptm: "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  potm: "application/vnd.ms-powerpoint.template.macroEnabled.12",
  ppsm: "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
  mdb: "application/vnd.ms-access",
  pdf: "application/pdf",
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <GridToolbarQuickFilter placeholder="Cerca..." />
      </Grid>
    </GridToolbarContainer>
  );
}

const CorsiLavoratore = (props) => {
  const [corsiLavoratore, setCorsiLavoratore] = useState([]);
  const [loading, setLoading] = useState(true);
  const [corsi, setCorsi] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      headerAlign: "center",
      hide: false,
      width: "150",
      align: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <ButtonGroup>
          <CorsiDialog
            id="corsiEdit"
            title="Dettaglio Corso"
            idLavoratore={props.idLavoratore}
            tipo={params.row.Ruolo}
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
          />
          <DeleteDialog
            corsoToDelete={params.row}
            myFunction={() => refreshData()}
          />
        </ButtonGroup>
      ),
    },
    {
      field: "Ruolo",
      headerName: "Codice",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Descrizione",
      headerName: "Descrizione",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "BaseData",
      headerName: "Data Corso",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => {
        return (
          <div>
            {params.row.BaseData
              ? moment(params.row.BaseData).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },
    {
      field: "BaseScadenza",
      headerName: "Scadenza Corso",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => {
        return (
          <div>
            {params.row.BaseScadenza
              ? moment(params.row.BaseScadenza).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },
    {
      field: "AggiornamentoData",
      headerName: "Data Agg.",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => {
        return (
          <div>
            {params.row.AggiornamentoData
              ? moment(params.row.AggiornamentoData).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },
    {
      field: "AggiornamentoScadenza",
      headerName: "Scadenza Agg.",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => {
        return (
          <div>
            {params.row.AggiornamentoScadenza
              ? moment(params.row.AggiornamentoScadenza).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    async function fetchData() {
      let responsecorsiLavoratore = await unsplash.get(
        "corsiLavoratore",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { lavoratore: props.idLavoratore },
        } //props.idAzienda}}
      );

      setCorsiLavoratore(responsecorsiLavoratore.data.data);
      setLoading(false);

      let responsecorsi = await unsplash.get(
        "anagraficaCorsi",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { ID: props.idAzienda },
        } //props.idAzienda}}
      );

      setCorsi(responsecorsi.data.data);
    }

    fetchData();
  }, []);

  const handleSaveData = async (corso, operazione) => {
    try {
      //inserisco il nuovo
      try {
        let responseModifica = await unsplash.post(
          "corsiLavoratore",
          { lavoratore: props.idLavoratore, corso: corso.Codice },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        let responsecorsiLavoratore = await unsplash.get(
          "corsiLavoratore",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
            params: { lavoratore: props.idLavoratore },
          } //props.idAzienda}}
        );

        setCorsiLavoratore(responsecorsiLavoratore.data.data);
        setSnack({
          severity: "success",
          messaggio: "Corso inserito con successo",
        });
        setOpenSnack(true);
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'inserimento",
        });
        setOpenSnack(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteSede = async (id) => {
    try {
      await unsplash.delete("sedi", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: id,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const refreshData = async () => {
    let responsecorsiLavoratore = await unsplash.get(
      "corsiLavoratore",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { lavoratore: props.idLavoratore },
      } //props.idAzienda}}
    );

    setCorsiLavoratore(responsecorsiLavoratore.data.data);
  };
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={2}
      >
        <Button
          startIcon={<Add />}
          onClick={() => document.getElementById("addCorso").click()}
          id={props.id}
          sx={{ color: "white" }}
          variant="contained"
          className="ButtonMiller"
        >
          Aggiungi
        </Button>
      </Grid>

      <FormDialog
        id="addCorso"
        idLavoratore={props.idLavoratore}
        operazione="Inserimento"
        corsiLavoratore={corsiLavoratore}
        corsi={corsi.filter(
          (corso) => !corsiLavoratore?.some((c) => c.Ruolo === corso.Codice)
        )}
        saveFunction={handleSaveData}
      />

      <div style={{ height: "58vh", width: "100%" }}>
        <DataGridPro
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooterPersonalized,
          }}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={loading}
          initialState={{ pinnedColumns: { left: ["Actions"] } }}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rows={corsiLavoratore}
          getRowId={(corsiLavoratore) => corsiLavoratore.Ruolo}
          columns={columns}
        />
      </div>
    </>
  );
};

const FormDialog = (props) => {
  const [open, setOpen] = useState(false);
  const [corsoSel, setCorsoSel] = useState("");

  const handleClickOpen = () => {
    setCorsoSel("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    props.saveFunction(corsoSel, props.operazione);
    handleClose();
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        id={props.id}
        style={{ display: "None" }}
      >
        Open form dialog
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth="sm"
        TransitionComponent={Transition}
      >
        <DialogTitle>Associa</DialogTitle>
        <DialogContent>
          <Grid container xs={12}>
            <Grid item xs={12}>
              <Autocomplete
                id="combo-box-demo"
                options={props.corsi}
                getOptionLabel={(option) =>
                  option.Codice + " - " + option.Descrizione
                }
                fullWidth
                onChange={(event, newValue) => {
                  setCorsoSel(newValue);
                  //handleChangeCliente(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Corso" />
                )}
                sx={{ mt: 1 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 1,
            px: 3,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={handleSave}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const DeleteDialog = (props) => {
  const [open, setOpen] = React.useState(false);
  const [openSnack, setOpenSnack] = useState(false);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleClickOpen = () => {
    if (props.corsoToDelete) {
      setOpen(true);
    } else {
      setSnack({
        severity: "error",
        messaggio: "Seleziona un corso",
      });
      setOpenSnack(true);
    }
  };
  const deleteCorso = async (idLavoratore, desRuolo) => {
    try {
      await unsplash.delete("corsiLavoratore", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: { ruolo: desRuolo, lavoratore: idLavoratore },
      });
      setSnack({
        severity: "success",
        messaggio: "Corso eliminato con successo",
      });
      setOpenSnack(true);
      props.myFunction();
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione",
      });
      setOpenSnack(true);
    }
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <IconButton aria-label="edit" onClick={handleClickOpen} id="delete">
        <Delete
          style={{
            height: "2rem",
            width: "2rem",
            color: "#FF2768",
          }}
        />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
      >
        <DialogTitle id="alert-dialog-title">
          Sei sicuro di voler eliminare il corso associato?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare il corso selezionato?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pr: 2,
            pl: 2,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button
            onClick={() =>
              deleteCorso(props.corsoToDelete.ID, props.corsoToDelete.Ruolo)
            }
            
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const CorsiDialog = (props) => {
  const [loadingDoc, setLoadingDoc] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const [allegati, setAllegati] = useState([]);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: 70,
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() =>
              downloadFile(
                params.row.ID,
                params.row.Estensione,
                params.row.Descrizione,
                "download"
              )
            }
          >
            <Download
              style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
            />
          </IconButton>
        </>
      ),
    },
    {
      field: "Descrizione",
      headerName: "Descrizione",
      flex: 1,

      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "DataCorso",
      headerName: "Data Corso",
      flex: 1,

      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => {
        return (
          <div>
            {params.row.DataCorso
              ? moment(params.row.DataCorso).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
    loadAllegati();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadAllegati = async (id) => {
    let response = await unsplash.get(
      "allegati",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: {
          colonna: "DettaglioCorsoLavoratore",
          lavoratore: props.idLavoratore,
          tipo: props.tipo,
        },
      } //id lavoratore e la tipologia del corso
    );
    setAllegati(response.data.data);
    setLoading(false);
  };

  const downloadFile = async (id, estensione, descrizione, operazione) => {
    try {
      if (loadingDoc) {
        setSnack({
          severity: "error",
          messaggio:
            "Aspetta che la richiesta precedente sia completata prima di iniziare",
        });
        setOpenSnack(true);
      } else {
        setLoadingDoc(true);
        // Make the POST request to trigger the download and response from the server
        const response = await unsplash.post(
          "ftp",
          {
            file: id + estensione,
            nomeOriginale: descrizione.includes(estensione)
              ? descrizione
              : descrizione + estensione,
            operazione: "download",
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
            responseType: "blob",
          }
        );
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: ext[estensione.replace(".", "")] })
        );
        const link = document.createElement("a");
        link.href = url;
        if (operazione === "download") {
          link.setAttribute(
            "download",
            descrizione.includes(estensione)
              ? descrizione
              : descrizione + estensione
          );
          document.body.appendChild(link);
          link.click();
        }

        if (operazione === "visualizza") {
          if (estensione === ".pdf") {
            window.open(url);
          }
        }
        setLoadingDoc(false);
      }
    } catch (error) {
      let messaggio = "Errore durante la visualizzazione del file: ";
      if (operazione === "download") {
        messaggio = "Errore durante il download del file: ";
      }
      setOpenSnack(true);
      if (error.response) {
        if (error.response.status === 404) {
          messaggio = messaggio + "File non trovato.";
        } else if (error.response.status === 500) {
          messaggio =
            messaggio +
            "Errore interno del server. Si prega di riprovare più tardi.";
        } else {
          messaggio = messaggio + "Errore sconosciuto.";
        }
      } else if (error.request) {
        // La richiesta è stata effettuata ma non è stata ricevuta alcuna risposta
        messaggio =
          messaggio +
          "Nessuna risposta ricevuta dal server. Si prega di controllare la connessione a Internet.";
      } else {
        // Si è verificato qualche altro errore
        messaggio =
          messaggio +
          "Si è verificato un errore. Si prega di riprovare più tardi.";
      }
      setSnack({
        severity: "error",
        messaggio: messaggio,
      });
      console.error("Errore durante il download del file:", error);
      setLoadingDoc(false);
    }
  };

  return (
    <>
      <IconButton
        aria-label="edit"
        onClick={() => {
          handleClickOpen();
        }}
        id="delete"
      >
        <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth="md"
        maxWidth="md"
        TransitionComponent={Transition}
      >
        <GlobalSnackbar
          severity={snack.severity}
          messaggio={snack.messaggio}
          openSnack={openSnack}
          handleCloseSnack={handleCloseSnack}
        />
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent style={{ paddingTop: "20px" }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="flex-end"
              flexWrap="wrap"
            >
              <FileUploader
                snack={snack}
                openSnack={openSnack}
                setSnack={setSnack}
                setOpenSnack={setOpenSnack}
                codice={props.tipo}
                idLavoratore={props.idLavoratore}
                reload={() => loadAllegati()}
              />
              <div style={{ height: 400, width: "100%" }}>
                <DataGridPro
                  components={{
                    Toolbar: CustomToolbar,
                    Footer: CustomFooterPersonalized,
                  }}
                  slots={{
                    loadingOverlay: LinearProgress,
                  }}
                  loading={loading}
                  initialState={{ pinnedColumns: { left: ["Actions"] } }}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  rows={allegati}
                  getRowId={(allegati) => allegati.ID}
                  columns={columns}
                  disableSelectionOnClick
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            mt: 1,
            px: 3,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
          >
            Chiudi
          </Button>
          {/* <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={handleClose}
          >
            Salva
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CorsiLavoratore;

/*
SELECT
	
  *
FROM
  Allegati JOIN Corsi_H ON Allegati.Corso = Corsi_H.ID
WHERE
  Lavoratore = ? AND Corsi_H.Tipo  = ?



*/

const FileUploader = (props) => {
  const [open, setOpen] = useState(false);
  const {
    acceptedFiles,
    isDragAccept,
    isDragReject,
    isFocused,
    getRootProps,
    getInputProps,
  } = useDropzone();

  const [files, setFiles] = useState([]);
  const [dataCorso, setDataCorso] = useState(null);
  const [aggiornamento, setAggiornamento] = useState(0);

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const handleUpload = async () => {
    if (!dataCorso) {
      props.setSnack({
        severity: "error",
        messaggio: "Attenzione seleziona la data del corso",
      });
      props.setOpenSnack(true);
    } else {
      if (files.length === 0) {
        props.setSnack({
          severity: "error",
          messaggio: "Nessun file caricato",
        });
        props.setOpenSnack(true);
      } else {
        let corso = { Tipo: props.codice, Aggiornamento: aggiornamento };

        try {
          let responseCorso = await unsplash.post(
            "corsi",
            { corso: corso, tipo: "H" }, //Creo corso header STEP 1
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );
          let idCorso = responseCorso.data.message.insertId;
          let corsoRiga = {
            Corso: idCorso,
            Data: moment(dataCorso).format("YYYY-MM-DD"),
            Presenza: 1,
            Fatturato: 1,
            Lavoratore: props.idLavoratore,
            CorsoNonInterno: 1,
          };
          let responseCorsoRiga = await unsplash.post(
            "corsi",
            { corso: corsoRiga, tipo: "R" }, //Creo corso header STEP 1
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );

          const formData = new FormData();
          formData.append("colonna", "Corso");
          formData.append("ID", idCorso);
          formData.append("colonnaExtra", "Lavoratore");
          formData.append("extra", props.idLavoratore); //extra è da modificare con l'id del lavoratroe
          files.map((file) => {
            formData.append("file", file);
          });
          await unsplash
            .post("caricaDoc", formData, {
              headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            })
            .then((res) => {
              props.setSnack({
                severity: "success",
                messaggio: "File caricato con successo",
              });
              props.setOpenSnack(true);
              setOpen(false);
              props.reload();
            });
        } catch {
          props.setSnack({
            severity: "error",
            messaggio: "Errore nel caricamento del file",
          });
          props.setOpenSnack(true);
          setOpen(false);
        }
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      <Button
        startIcon={<Upload />}
        variant="contained"
        className="ButtonMiller"
        style={{ marginBottom: "10px" }}
        onClick={() => {
          setOpen(true);
          setFiles([]);
          setAggiornamento(0);
        }}
      >
        Carica
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>Carica File</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                sx={{ marginLeft: "10px" }}
                name="Aggiornamento"
                checked={aggiornamento === 1 ? true : false}
                onChange={(e) => {
                  setAggiornamento(e.target.checked ? 1 : 0);
                }}
              />
            }
            label="Aggiornamento"
          />
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DatePicker
              ampmInClock={false}
              renderInput={(props) => (
                <TextField
                  sx={{ marginBottom: "10px" }}
                  size="small"
                  fullWidth
                  {...props}
                />
              )}
              label="Data corso"
              value={moment(dataCorso)}
              onChange={(newValue) => {
                setDataCorso(newValue ? newValue.format("YYYY-MM-DD") : null);
              }}
            />
          </LocalizationProvider>

          <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Trascina e rilascia qui o fai clic per selezionare i file</p>
              </div>
            )}
          </Dropzone>
          <aside>
            <h4>Files</h4>
            {files?.map((file) => {
              return (
                <>
                  <ul>{file.path}</ul>
                </>
              );
            })}
          </aside>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 1,
            px: 3,
          }}
        >
          <Button onClick={handleClose}>Annulla</Button>
          <Button onClick={handleUpload}>Carica</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
