/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import {
  AccountCircle,
  CalendarMonth,
  Folder,
  Help,
  MedicalInformation,
  Menu,
  NoteAdd,
  Person,
} from "@mui/icons-material";
//import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";
//import UserDropdown from "components/Dropdowns/UserDropdown.js";

export default function Sidebar(props) {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  return (
    <>
      <nav className="z-40 md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden bg-white dark:bg-primary-800 flex flex-wrap items-center justify-between relative md:w-64 py-4 md:px-4">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-center md:justify-between w-full mx-auto relative">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black dark:text-white md:hidden px-3 py-1 absolute md:relative left-0 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white dark:bg-primary-800")}
          >
            <Menu />
          </button>
          {/* Logo */}
          <Link
            className="md:block text-left md:pb-4 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-0"
            to="/AreaClienti/Dashboard"
            onClick={() => {
              props.setPage("Dashboard"), setCollapseShow("hidden");
            }}
          >
            <img
              src={require("../../../assets/images/Miller.jpg")}
              className="w-40 md:w-fit"
            />
          </Link>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:w-full md:hidden block pb-4 border-b border-slate-200">
              <div className="flex flex-wrap items-center justify-center">
                <button
                  type="button"
                  className="cursor-pointer text-black dark:text-white md:hidden px-3 py-1 absolute md:relative left-0 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <Menu />
                </button>
                <Link
                  className="md:block text-left md:pb-4 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-0"
                  to="/AreaClienti/Dashboard"
                  onClick={() => {
                    props.setPage("Dashboard"), setCollapseShow("hidden");
                  }}
                >
                  <img
                    src={require("../../../assets/images/Miller.jpg")}
                    className="w-40 md:w-fit"
                  />
                </Link>
              </div>
            </div>
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center text-sidebar-clienti">
                <Link
                  className={
                    "link-sidebar " +
                    (window.location.href.indexOf("/ScadenzarioVisite") !== -1
                      ? "text-primary-100 hover:text-primary-600"
                      : "text-primary-600 hover:text-primary-600")
                  }
                  to="/AreaClienti/ScadenzarioVisite"
                  onClick={() => {
                    props.setPage("Scadenzario Visite"),
                      setCollapseShow("hidden");
                  }}
                >
                  <Person
                    className={
                      "fas fa-tools text-sm " +
                      (window.location.href.indexOf("/ScadenzarioVisite") !==
                      -1
                        ? "text-primary-100 dark:text-primary-100 hover:text-primary-100"
                        : "text-primary-600 dark:text-primary-100 hover:text-primary-100")
                    }
                  />{" "}
                  Scadenzario Visite
                </Link>
              </li>
              <li className="items-center text-sidebar-clienti">
                <Link
                  className={
                    "link-sidebar " +
                    (window.location.href.indexOf("/Corsi") !== -1
                      ? "text-primary-100 hover:text-primary-600"
                      : "text-primary-600 hover:text-primary-600")
                  }
                  to="/AreaClienti/Corsi"
                  onClick={() => {
                    props.setPage("Scadenzario corsi"),
                      setCollapseShow("hidden");
                  }}
                >
                  <CalendarMonth
                    className={
                      "fas fa-tools text-sm " +
                      (window.location.href.indexOf("/Corsi") !== -1
                        ? "text-primary-100 dark:text-primary-100"
                        : "text-primary-600 dark:text-primary-100")
                    }
                  />{" "}
                  Scadenzario corsi
                </Link>
              </li>
              <li className="items-center text-sidebar-clienti">
                <Link
                  className={
                    "link-sidebar " +
                    (window.location.href.indexOf("/Documenti") !== -1
                      ? "text-primary-100 hover:text-primary-600"
                      : "text-primary-600 hover:text-primary-600")
                  }
                  to="/AreaClienti/Documenti"
                  onClick={() => {
                    props.setPage("Documenti"), setCollapseShow("hidden");
                  }}
                >
                  <Folder
                    className={
                      "fas fa-tools text-sm " +
                      (window.location.href.indexOf("/Documenti") !== -1
                        ? "text-primary-100 dark:text-primary-100"
                        : "text-primary-600 dark:text-primary-100")
                    }
                  />{" "}
                  Documenti
                </Link>
              </li>

              <li className="items-center text-sidebar-clienti">
                <Link
                  className={
                    "link-sidebar " +
                    (window.location.href.indexOf("/VisiteMediche") !== -1
                      ? "text-primary-100 hover:text-primary-600"
                      : "text-primary-600 hover:text-primary-600")
                  }
                  to="/AreaClienti/VisiteMediche"
                  onClick={() => {
                    props.setPage("Visite Mediche"), setCollapseShow("hidden");
                  }}
                >
                  <MedicalInformation
                    className={
                      "fas fa-tools text-sm " +
                      (window.location.href.indexOf("/VisiteMediche") !== -1
                        ? "text-primary-100 dark:text-primary-100"
                        : "text-primary-600 dark:text-primary-100")
                    }
                  />{" "}
                  Visite Mediche
                </Link>
              </li>
              {/*
              <li className="items-center">
                <Link
            
                  className={
                    "link-sidebar " +
                    (window.location.href.indexOf("/idoneita") !== -1
                      ? "text-primary-100 hover:text-primary-600"
                      : "text-black hover:text-primary-600")
                  }
                  to="/AreaClienti/idoneita"
                  onClick={()=>props.setPage("Idoneità")}
                >
                  
                  <NoteAdd
                    className={
                      "fas fa-tools mr-2 text-sm " +
                      (window.location.href.indexOf("/idoneita") !== -1
                        ? "text-primary-100"
                        : "text-black")
                    }
                  />{" "}
              Idoneità
                </Link>
              </li>
                  */}
              {/*
              <li className="items-center">
                <Link
            
                  className={
                    "link-sidebar " +
                    (window.location.href.indexOf("/SorveglianzaSanitaria") !== -1
                      ? "text-primary-100 hover:text-primary-600"
                      : "text-black hover:text-primary-600")
                  }
                  to="/AreaClienti/SorveglianzaSanitaria"
                  onClick={()=>props.setPage("Sorveglianza sanitaria")}
                >
                  
                  <CalendarMonth
                    className={
                      "fas fa-tools mr-2 text-sm " +
                      (window.location.href.indexOf("/SorveglianzaSanitaria") !== -1
                        ? "text-primary-100"
                        : "text-black")
                    }
                  />{" "}
                  Sorveglianza Sanitaria
                </Link>
              </li>
                  */}
              <li className="h-full"></li>
              <li className="items-center text-sidebar-clienti">
                <Link
                  className={
                    "link-sidebar " +
                    (window.location.href.indexOf("/Help") !== -1
                      ? "text-primary-100 hover:text-primary-600"
                      : "text-primary-600 hover:text-primary-600")
                  }
                  to="/AreaClienti/Help"
                  onClick={() => {
                    props.setPage("Assistenza"), setCollapseShow("hidden");
                  }}
                >
                  <Help
                    className={
                      "fas fa-tools text-sm " +
                      (window.location.href.indexOf("/Help") !== -1
                        ? "text-primary-100 dark:text-primary-100"
                        : "text-primary-600 dark:text-primary-100")
                    }
                  />{" "}
                  Assistenza
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
