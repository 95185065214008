import React, { useState, useEffect } from "react";
import ClippedDrawer from "./AppContent";
import unsplash from "../../api/unsplash";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "Descrizione",
    headerName: "Cantiere",
    flex: 1,
  },
];

const ext = {
  doc: "application/msword",
  dot: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  dotx: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  docm: "application/vnd.ms-word.document.macroEnabled.12",
  dotm: "application/vnd.ms-word.template.macroEnabled.12",
  xls: "application/vnd.ms-excel",
  xlt: "application/vnd.ms-excel",
  xla: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xltx: "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  xlsm: "application/vnd.ms-excel.sheet.macroEnabled.12",
  xltm: "application/vnd.ms-excel.template.macroEnabled.12",
  xlam: "application/vnd.ms-excel.addin.macroEnabled.12",
  xlsb: "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  ppt: "application/vnd.ms-powerpoint",
  pot: "application/vnd.ms-powerpoint",
  pps: "application/vnd.ms-powerpoint",
  ppa: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  potx: "application/vnd.openxmlformats-officedocument.presentationml.template",
  ppsx: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  ppam: "application/vnd.ms-powerpoint.addin.macroEnabled.12",
  pptm: "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  potm: "application/vnd.ms-powerpoint.template.macroEnabled.12",
  ppsm: "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
  mdb: "application/vnd.ms-access",
  png: "application/png",
  pdf: "application/pdf",
};

const Sopralluoghi = () => {
  const [sopralluoghi, setSopralluoghi] = useState();

  useEffect(() => {
    const fetchData = async () => {
      let responseSopralluoghi = await unsplash.get("allegati", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: {
          colonna: "SopralluoghiUtente",
          user: sessionStorage.getItem("User"),
        },
      });
      setSopralluoghi(responseSopralluoghi.data.data);
      //setAllegati(responseSopralluoghi.data.data);
    };
    fetchData();
  }, []);

  return (
    <>
      {/* <!-- component -->
      
         <DataGrid
        rows={sopralluoghi}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[100]}
        getRowId={row=>row.Sede}
      />
      */}

      <div
        className="w-50 h-screen p-10
      "
      ></div>
    </>
  );
};

export default Sopralluoghi;
