import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Container, Grid, Paper, Button, CardMedia } from "@mui/material";
import unsplash from "../../api/unsplash";
import { login, loginPersistant } from "../../utils";
import { useNavigate } from "react-router-dom";

import GlobalSnackbar from "../Snackbar";
import Safety from "../../assets/images/med.jpg";
import Sfondo from "../../assets/images/sfondo.png";
import { Hidden } from "@mui/material";

const style = { background: "#f5f8fa" };

const LoginMedico = () => {
  const [isGood, setIsGood] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [password, setPassword] = useState(null);
  const [username, setUsername] = useState(null);

  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const [icon, setIcon] = useState("bi bi-eye-slash-fill");

  const navigate = useNavigate();

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleCheck = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleEyeClick = () => {
    setPasswordShown(!passwordShown);
    setIcon(passwordShown ? "bi bi-eye-slash-fill" : "bi bi-eye-fill");
  };

  const handleLogin = async (e) => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    e.preventDefault();

    try {
      let respToken = await unsplash.post(
        "portaleMedici",
        { operation: "Login", username: username, password: password },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      login(null, respToken.data.medico.token);
      sessionStorage.setItem("Cognome", respToken.data.medico.Cognome);
      sessionStorage.setItem("Medico", respToken.data.medico.ID);
      sessionStorage.setItem("Email", respToken.data.medico.Email);
      sessionStorage.setItem("CF", respToken.data.medico.CF);
      //sessionStorage.setItem("lavoratore",respToken.data.utente.lavoratore)
      navigate("/AreaMedico/Home");
    } catch (e) {
      setSnack({
        severity: "error",
        messaggio: "UTENTE O PASSWORD ERRATE",
      });
      setOpenSnack(true);
    }
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />

      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        display="flex"
        minHeight="100vh"
        flexWrap="nowrap"
        className="bg-cover bg-fixed bg-center bg-sfondo dark:bg-none"
      >
        <Hidden mdDown>
          <Grid
            item
            xs={6}
            sx={{
              margin: "30px",
              borderRadius: "8px",
            }}
          >
            <CardMedia
              sx={{ borderRadius: "8px", height: "calc(100vh - 4rem)" }}
              component="img"
              image={Safety}
              alt="Safety"
            />
          </Grid>
        </Hidden>
        <Grid
          item
          xs={8}
          lg={3}
          justifyContent="center"
          alignItems="center"
          display="flex"
          flexWrap="wrap"
          gap={4}
          style={{ margin: "0 auto" }}
        >
          <img
            src={require("../../assets/images/logo.png")}
            className="max-w-full h-auto"
            width="500px"
            alt="f"
          />

          <form
            //onSubmit={handleLogin}
            style={{ width: "100%" }}
          >
            <label className="form-label fs-5" htmlFor="password">
              Username
            </label>
            <div className="form-outline mb-4 relative flex items-stretch w-full">
              <input
                type="text"
                id="username"
                name="username"
                placeholder="Inserisci username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded py-2 px-4 text-lg leading-normal rounded border-r-0 bg-white fs-6"
              />
            </div>

            <label className="form-label fs-5" htmlFor="password">
              Password
            </label>
            <div className="form-outline mb-4 relative flex items-stretch w-full">
              <input
                type={passwordShown ? "text" : "password"}
                id="password"
                name="password"
                placeholder="Inserisci la password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded py-2 px-4 text-lg leading-normal rounded border-r-0 bg-white fs-6"
              />
              <span className="input-group-text bg-transparent">
                <i
                  className={icon}
                  style={{ cursor: "pointer" }}
                  onClick={handleEyeClick}
                ></i>
              </span>
            </div>

            <div className="flex justify-around items-center mb-4"></div>

            <Button
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              onClick={handleLogin}
              style={{ padding: "8px 16px", backgroundColor: "#11526f" }}
            >
              CONFERMA{" "}
            </Button>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default LoginMedico;
