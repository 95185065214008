import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import unsplash from "../api/unsplash";
import Avatar from "@mui/material/Avatar";
import GlobalSnackbar from "./Snackbar";
import LinearProgress from "@mui/material/LinearProgress";
import Switch from "@mui/material/Switch";

import {
  Grid,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Input,
  CardActionArea,
  CardMedia,
  Divider,
  CardActions,
  InputAdornment,
  ButtonGroup,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Zoom,
  DialogActions,
  DialogContentText,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import { IconButton } from "@mui/material";

import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";

import {
  Add,
  Delete,
  Close,
  Check,
  Search,
  MenuBook,
  FiberNew,
  Circle,
} from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const Miglioramenti = () => {
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [miglioramenti, setMiglioramenti] = useState([]);
  const [loading, setLoading] = useState(true);
  const [miglioramentoSelezionata, setMiglioramentoSelezionata] =
    useState(null);
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={async () => {
              await setMiglioramentoSelezionata(params.row);
              document.getElementById("edit").click();
              //handleClickOpen();
            }}
          >
            <EditIcon
              style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
            />
          </IconButton>

          <DeleteDialog miglioramento={params.row} myFunction={handleDelete} />
        </>
      ),
    },
    {
      field: "Miglioramento",
      headerName: "Miglioramento",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Descrizione",
      headerName: "Descrizione",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];
  const toggleAlert = () => {
    setOpenAlert(!openAlert);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    reloadAfterUpload();
  }, []);

  const reloadAfterUpload = async () => {
    try {
      let response = await unsplash.get("miglioramenti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      setMiglioramenti(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleSave = async (att, operazione) => {
    if (operazione === "new") {
      try {
        await unsplash.post(
          "miglioramenti",
          {
            miglioramento: att,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Miglioramento creato con successo",
        });
        setOpenSnack(true);

        reloadAfterUpload();
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante la creazione",
        });
        setOpenSnack(true);
      }
      handleClose();
    } else {
      try {
        await unsplash.put(
          "miglioramenti",
          {
            miglioramento: att,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Miglioramento aggiornato con successo",
        });
        setOpenSnack(true);

        reloadAfterUpload();
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'aggiornamento",
        });
        setOpenSnack(true);
      }
      //handleClose();
    }
  };

  const handleDelete = async (att) => {
    try {
      att.Eliminato = 1;
      await unsplash.put(
        "miglioramenti",
        {
          miglioramento: att,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      setSnack({
        severity: "success",
        messaggio: "Miglioramento eliminato con successo",
      });
      setOpenSnack(true);
      reloadAfterUpload();
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione del Miglioramento",
      });
      setOpenSnack(true);
    }
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid item xs={12}>
        <Card>
          <CardContent sx={{ p: { xs: 0 } }}>
            <div>
              {miglioramenti === undefined ? (
                ""
              ) : (
                <>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Grid
                          container
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <Grid item xs={12} md={4}>
                            <Grid
                              container
                              direction="row"
                              alignItems="center"
                              justifyContent="flex-end"
                            >
                              <NewMiglioramenti
                                id="edit"
                                miglioramento={miglioramentoSelezionata}
                                saveFunction={handleSave}
                                title="Modifica Miglioramento"
                              />
                              <NewMiglioramenti
                                id="new"
                                miglioramento={miglioramentoSelezionata}
                                saveFunction={handleSave}
                                title="Nuova Miglioramento"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Box
                          sx={{
                            height: { xs: "75vh", md: "60vh", xl: "75vh" },
                            width: 1,
                          }}
                        >
                          <DataGridPro
                            initialState={{
                              pinnedColumns: { left: ["Actions"] },
                            }}
                            components={{
                              Toolbar: CustomToolbar,
                              Footer: CustomFooterPersonalized,
                            }}
                            slots={{
                              loadingOverlay: LinearProgress,
                            }}
                            loading={loading}
                            localeText={
                              itIT.components.MuiDataGrid.defaultProps
                                .localeText
                            }
                            rowHeight={52}
                            rows={miglioramenti}
                            columns={columns}
                            getRowId={(row) => row.ID}
                            pageSize={20}
                            rowsPerPageOptions={[20]}
                            disableSelectionOnClick
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              )}
            </div>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default Miglioramenti;

const NewMiglioramenti = (props) => {
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [checked, setChecked] = useState(false);
  const [miglioramento, setMiglioramento] = useState({
    Miglioramento: "",
    Descrizione: "",
  });

  const handleClickOpen = () => {
    if (props.id === "edit") {
      setMiglioramento(props.miglioramento);
    } else {
      setMiglioramento({
        Miglioramento: "",
        Descrizione: "",
      });
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (e) => {
    let copiaMiglioramento = { ...miglioramento };
    copiaMiglioramento[e.target.name] = e.target.value;
    setMiglioramento(copiaMiglioramento);
  };
  const handleSave = async () => {
    props.saveFunction(miglioramento, props.id);
    handleClose();
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
      >
        {props.id === "edit" ? (
          <Button
            startIcon={<Add />}
            onClick={handleClickOpen}
            id={props.id}
            sx={{ color: "white", display: "none" }}
            variant="outlined"
            className="ButtonMiller"
          >
            Aggiungi
          </Button>
        ) : (
          <Button
            startIcon={<Add />}
            onClick={handleClickOpen}
            id={props.id}
            sx={{ color: "white" }}
            variant="outlined"
            className="ButtonMiller"
          >
            Aggiungi
          </Button>
        )}
      </ButtonGroup>

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="md"
          TransitionComponent={Transition}
        >
          {/* Title Container */}
          <DialogTitle
            sx={{
              mx: "auto",
            }}
          >
            {props.title}
          </DialogTitle>
          {/* Body Container */}
          <DialogContent>
            {/* Row 2 */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  value={miglioramento?.Miglioramento}
                  id="outlined-basic"
                  label="Miglioramento"
                  name="Miglioramento"
                  onChange={handleChange}
                  fullWidth
                  sx={{ mt: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={miglioramento?.Descrizione}
                  id="outlined-basic"
                  label="Descrizione"
                  name="Descrizione"
                  onChange={handleChange}
                  fullWidth
                  sx={{ mt: 2 }}
                />
              </Grid>
            </Grid>
            {/* Bottoni Salva & Esci */}
          </DialogContent>
          <DialogActions
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mx: 2,
            }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Close />}
            >
              Chiudi
            </Button>
            <Button
              onClick={handleSave}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Check />}
            >
              Salva
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};
const DeleteDialog = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare il miglioramento selezionato?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare:<br/>
            <b>{props.miglioramento.Miglioramento}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button
            onClick={() => props.myFunction(props.miglioramento)}
            
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
