const TOKEN_KEY = 'jwt';

export const login = (foto,token) => {

    localStorage.setItem('jwt', token);
    sessionStorage.setItem('logged', 'ok');
    localStorage.setItem('fotoProfilo',"data:image/png;base64, "+ foto);
}
export const loginPersistant = (token) => {
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem('logged', 'ok');
}

export const logout = () => {
    localStorage.removeItem('logged');
    sessionStorage.removeItem('fotoProfilo');
   
}

export const isLogin = () => {
    if (sessionStorage.getItem('logged') || localStorage.getItem('logged')  ) {
        return true;
    }

    return false;
}




const calcDataNascitaCF = (cf) => {

    let mesi = {
        "A": "Gennaio",
        "B": "Febbraio",
        "C": "Marzo",
        "D": "Aprile",
        "E": "Maggio",
        "H": "Giugno",
        "L": "Luglio",
        "M": "Agosto",
        "P": "Settembre",
        "R": "Ottobre",
        "S": "Novembre",
        "T": "Dicembre"
      }
      
      
      let annoNascita = cf.substring(6,8);
      let mese = mesi[cf.charAt(8)];
      let giornoNascita = parseInt(cf.substring(9,11)) > 40?parseInt(cf.substring(9,11)) -40 :parseInt(cf.substring(9,11));
      
      

}