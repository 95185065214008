import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import unsplash from "../../api/unsplash";
import {
  Box,
  TextField,
  Button,
  CardActionArea,
  CardMedia,
  Tabs,
  Tab,
  Typography,
  Divider,
  Zoom,
  Autocomplete,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Checkbox,
  FormControlLabel,
  Link,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment as DateAdapter } from "@mui/x-date-pickers/AdapterMoment";
import GlobalSnackbar from "../Snackbar";
import PropTypes from "prop-types";
import {
  Add,
  Check,
  Circle,
  Close,
  Delete,
  Download,
  Edit,
  RemoveRedEye,
  Upload,
} from "@mui/icons-material";
import moment from "moment";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../../utils/CustomFooterPersonalized";
import FileUploader from "../FileUploader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ext = {
  doc: "application/msword",
  dot: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  dotx: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  docm: "application/vnd.ms-word.document.macroEnabled.12",
  dotm: "application/vnd.ms-word.template.macroEnabled.12",
  xls: "application/vnd.ms-excel",
  xlt: "application/vnd.ms-excel",
  xla: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xltx: "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  xlsm: "application/vnd.ms-excel.sheet.macroEnabled.12",
  xltm: "application/vnd.ms-excel.template.macroEnabled.12",
  xlam: "application/vnd.ms-excel.addin.macroEnabled.12",
  xlsb: "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  ppt: "application/vnd.ms-powerpoint",
  pot: "application/vnd.ms-powerpoint",
  pps: "application/vnd.ms-powerpoint",
  ppa: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  potx: "application/vnd.openxmlformats-officedocument.presentationml.template",
  ppsx: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  ppam: "application/vnd.ms-powerpoint.addin.macroEnabled.12",
  pptm: "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  potm: "application/vnd.ms-powerpoint.template.macroEnabled.12",
  ppsm: "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
  mdb: "application/vnd.ms-access",
  png: "application/png",
  pdf: "application/pdf",
};

const ClienteParita = ({ savedProfilo, handleScroll }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [step, setStep] = useState(0);
  const [handleLocked, setHandleLocked] = useState(false);

  const [allegati, setAllegati] = useState([]);
  const [comitato, setComitato] = useState([]);
  const [mansionario, setMansionario] = useState([]);
  const [codiciAteco, setCodiciAteco] = useState([]);

  const [codiciAtecoSel, setCodiciAtecoSel] = useState(null);
  const [cliente, setCliente] = useState({
    Denominazione: "",
    RagioneSociale: "",
    SedeLegale: "",
    SedeAmministrativa: "",
    Telefono: "",
    Sito: "",
    Pec: "",
    Piva: "",
    Rea: "",
    CodiceAteco: 0,
    NomeRappresentante: "",
    CognomeRappresentante: "",
    DataNascitaRappresentante: null,
    LuogoNascitaRappresentante: "",
    Logo: "",
    NominativoResponsabile: "",
    Settore: "",
    Storia: "",
    Missione: "",
    BudgetAnnuale: 0,
  });

  const buttonSave = useRef(null);

  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <Mansionario
            id="edit"
            mansionario={params.row}
            saveFunction={handleSaveMansionario}
            title="Modifica Utente"
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
          />
          <DeleteDialog mansione={params.row} myFunction={handleDelete} />
        </>
      ),
    },
    {
      field: "Gruppo",
      headerName: "Gruppo",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Tipologia",
      headerName: "Tipologia Ccnl",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Livello",
      headerName: "Livello",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Ccnl",
      headerName: "Descrzione CCNL",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "AttivitaSvolta",
      headerName: "AttivitaSvolta",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Responsabilita",
      headerName: "Responsabilita",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Competenze",
      headerName: "Competenze",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];
  const columnsComitato = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <Comitato
            id="edit"
            comitato={params.row}
            saveFunction={handleSaveComitato}
            title="Modifica Membro"
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
          />
          <DeleteDialog
            comitato={params.row}
            myFunction={handleDeleteComitato}
          />
        </>
      ),
    },
    {
      field: "Nome",
      headerName: "Nome",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Cognome",
      headerName: "Cognome",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Responsabile",
      headerName: "Responsabile",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          {params.row.Responsabile === 1 && (
            <Circle sx={{ fontSize: 32, color: "#40C18F" }} />
          )}
        </>
      ),
    },
  ];
  const columnsAllegati = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() =>
              downloadFile(
                params.row.ID,
                params.row.Estensione,
                params.row.Descrizione,
                "download"
              )
            }
          >
            <Download
              style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
            />
          </IconButton>

          {params.row.Estensione?.toLowerCase() === ".pdf" && (
            <IconButton
              aria-label="edit"
              onClick={() =>
                downloadFile(
                  params.row.ID,
                  params.row.Estensione,
                  params.row.Descrizione,
                  "visualizza"
                )
              }
            >
              <RemoveRedEye
                style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
              />
            </IconButton>
          )}
        </>
      ),
    },
    { field: "Descrizione", headerName: "Descrizione", flex: 1 },
  ];

  const downloadFile = async (id, estensione, descrizione, operazione) => {
    try {
      // Make the POST request to trigger the download and response from the server
      const response = await unsplash.post(
        "ftp",
        {
          file: id + estensione,
          nomeOriginale: descrizione.includes(estensione)
            ? descrizione
            : descrizione + estensione,
          operazione: "download",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: ext[estensione.replace(".", "")] })
      );
      const link = document.createElement("a");
      link.href = url;
      if (operazione === "download") {
        link.setAttribute(
          "download",
          descrizione.includes(estensione)
            ? descrizione
            : descrizione + estensione
        );
        document.body.appendChild(link);
        link.click();
      }

      if (operazione === "visualizza") {
        if (estensione === ".pdf") {
          window.open(url);
        }
      }
    } catch (error) {
      let messaggio = "Errore durante la visualizzazione del file: ";
      if (operazione === "download") {
        messaggio = "Errore durante il download del file: ";
      }
      setOpenSnack(true);
      if (error.response) {
        if (error.response.status === 404) {
          messaggio = messaggio + "File non trovato.";
        } else if (error.response.status === 500) {
          messaggio =
            messaggio +
            "Errore interno del server. Si prega di riprovare più tardi.";
        } else {
          messaggio = messaggio + "Errore sconosciuto.";
        }
      } else if (error.request) {
        // La richiesta è stata effettuata ma non è stata ricevuta alcuna risposta
        messaggio =
          messaggio +
          "Nessuna risposta ricevuta dal server. Si prega di controllare la connessione a Internet.";
      } else {
        // Si è verificato qualche altro errore
        messaggio =
          messaggio +
          "Si è verificato un errore. Si prega di riprovare più tardi.";
      }
      setSnack({
        severity: "error",
        messaggio: messaggio,
      });
      console.error("Errore durante il download del file:", error);
    }
  };
  const handleDeleteFile = async (id, estensione, operazione) => {
    try {
      /* await unsplash.delete('allegati', {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('jwt')}`,
				},
				data: {
					id: id,
				},
			}); */
      await unsplash.post(
        "ftp",
        { file: id + estensione, operazione: "delete" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({
        severity: "success",
        messaggio: "Allegato eliminato con successo",
      });
      setOpenSnack(true);
      reloadAfterUpload();
    } catch (error) {
      console.error(error);
    }
  };

  function CustomToolbarG() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const handleChange = (e) =>
    setCliente({ ...cliente, [e.target.name]: e.target.value });

  const handleChangeImage = async (e) => {
    const file = e.target.files[0]; // prendo la foto caricata sempre nell'evento
    var reader = new FileReader();
    let logo;
    reader.readAsDataURL(file);
    reader.onload = function () {
      document.getElementById("Logo").src = reader.result;
      logo = reader.result.replace("data:image/png;base64,", "");
      logo = reader.result.replace("data:image/jpeg;base64,", "");
      setCliente({ ...cliente, Logo: logo });
    };
    reader.onerror = function (error) {};
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleDeleteComitato = async (ID) => {
    try {
      await unsplash.delete("comitatoParita", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: ID,
        },
      });
      loadComitato();
      setSnack({
        severity: "success",
        messaggio: "Adempimento eliminato con successo",
      });
      setOpenSnack(true);
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante la cancellazione",
      });
      setOpenSnack(true);
    }
  };
  const loadComitato = async () => {
    let responseComitato = await unsplash.get("comitatoParita", {
      params: { id: sessionStorage.getItem("Cliente") },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setComitato(responseComitato.data.data);
  };

  const handleSaveComitato = async (comitatoSel, operaizone) => {
    if (operaizone === "new") {
      try {
        await unsplash.post(
          "comitatoParita",
          {
            comitato: {
              ...comitatoSel,
              IDParitaCliente: sessionStorage.getItem("Cliente"),
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Membro creato con successo",
        });
        setOpenSnack(true);
        loadComitato();
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'aggiornamento",
        });
        setOpenSnack(true);
      }
    } else {
      try {
        await unsplash.put(
          "comitatoParita",
          {
            comitato: comitatoSel,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Membro aggiornato con successo",
        });
        setOpenSnack(true);
        loadComitato();
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'aggiornamento",
        });
        setOpenSnack(true);
      }
    }
  };

  const handleDelete = async (ID) => {
    try {
      await unsplash.delete("parita/mansionario", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: ID,
        },
      });
      loadMansionario();
      setSnack({
        severity: "success",
        messaggio: "Adempimento eliminato con successo",
      });
      setOpenSnack(true);
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante la cancellazione",
      });
      setOpenSnack(true);
    }
  };

  const reloadAfterUpload = async () => {
    let responseAziendali = await unsplash.get("allegati", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { colonna: "Parita", ID: sessionStorage.getItem("Cliente") },
    });
    setAllegati(responseAziendali.data.data);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const loadMansionario = async () => {
    let responseMansionario = await unsplash.get("parita/mansionario", {
      params: { IDCliente: sessionStorage.getItem("Cliente") },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setMansionario(responseMansionario.data.data);
  };
  const handleSave = async () => {
    try {
      await unsplash.put(
        "parita",
        {
          parita: cliente,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({
        severity: "success",
        messaggio: "Cliente aggiornato con successo",
      });
      setOpenSnack(true);
      savedProfilo();
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'aggiornamento",
      });
      setOpenSnack(true);
    }
  };
  const handleSaveMansionario = async (mansionario, operaizone) => {
    if (operaizone === "new") {
      try {
        await unsplash.post(
          "parita/mansionario",
          {
            mansione: {
              ...mansionario,
              IDParitaCliente: sessionStorage.getItem("Cliente"),
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Mansione creata con successo",
        });
        setOpenSnack(true);
        loadMansionario();
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'aggiornamento",
        });
        setOpenSnack(true);
      }
    } else {
      try {
        await unsplash.put(
          "parita/mansionario",
          {
            mansione: mansionario,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Mansione aggiornata con successo",
        });
        setOpenSnack(true);
        loadMansionario();
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'aggiornamento",
        });
        setOpenSnack(true);
      }
    }
  };

  const handleAncor = () => {
    if (buttonSave.current) {
      buttonSave.current.scrollIntoView({ behavior: "smooth" });
      // Add a transition effect for the scale
      buttonSave.current.style.transition = "transform 0.5s ease"; // Adjust duration as needed

      // Scale up the button
      setTimeout(() => {
        buttonSave.current.style.transform = "scale(1.2)";
      }, 400);

      // Scale back to normal
      setTimeout(() => {
        buttonSave.current.style.transform = "scale(1)";
      }, 900);
    }
  };

  useEffect(() => {
    (() => {
      if (handleScroll) {
        handleAncor();
      }
    })();
  }, [handleScroll]);
  useEffect(() => {
    (async () => {
      let stepGetted;
      let response = await unsplash.post(
        "portaleParita",
        {
          operation: "getCliente",
          id: sessionStorage.getItem("Cliente"),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      loadMansionario();
      loadComitato();
      reloadAfterUpload();
      let responseAteco = await unsplash.get("parita/ateco", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      setCodiciAteco(responseAteco.data.data);
      stepGetted = response.data[0]?.Step;
      setStep(stepGetted);
      setCliente(response.data[0]);
      if (response.data[0]?.CodiceAteco) {
        setCodiciAtecoSel(
          responseAteco.data.data?.filter(
            (codice) => codice.ID === response.data[0].CodiceAteco
          )[0]
        );
      }
    })();
  }, []);

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <>
        <div className="relative h-full ">
          {step === 0 && (
            <div className="absolute inset-0 z-50 w-full h-full bg-white/80 backdrop-blur-0">
              <div className="absolute left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] flex flex-col gap-5">
                <div className="container">
                  <span
                    onMouseOver={() => setHandleLocked(true)}
                    onMouseOut={() => setHandleLocked(false)}
                    onClick={() => {
                      navigate("/AreaParita/Home");
                    }}
                    className={`lock ${handleLocked ? "unlocked" : ""}`}
                  ></span>
                </div>
                <div className="text-xl font-bold text-center">
                  NON DISPONIBILE
                  <br />
                  NECESSARIO COMPLETARE LO STEP:
                  <div
                    className="text-primary-600 cursor-pointer underline"
                    onClick={() => navigate("/AreaParita/Home")}
                  >
                    1 - Video Introduzione Iter
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="px-8 h-[80vh]">
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Tabs
                value={value}
                textColor="inherit"
                variant="scrollable"
                onChange={handleChangeTab}
                aria-label="basic tabs example"
              >
                <Tab label="INFORMAZIONI AZIENDA" />
                <Tab label="Comitato" />
                <Tab label="Organigramma" />
                <Tab label="Mansionario" />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div className="w-full grid grid-cols-2 gap-3">
                <div className="col-span-2 flex flex-col">
                  <CardActionArea
                    onClick={() => {
                      document.getElementById("file").click();
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={
                        cliente.Logo !== ""
                          ? cliente.Logo.includes("data:image/")
                            ? cliente.Logo
                            : "data:image/png;base64," + cliente.Logo
                          : require("../../assets/images/MILLERLogo.jpg")
                      }
                      src={
                        cliente.Logo !== ""
                          ? cliente.Logo.includes("data:image/")
                            ? cliente.Logo
                            : "data:image/png;base64," + cliente.Logo
                          : require("../../assets/images/MILLERLogo.jpg")
                      }
                      id="Logo"
                      alt="img"
                      sx={{
                        height: "150px",
                        objectFit: "contain",
                        display: "flex",
                      }}
                    />
                  </CardActionArea>
                  <Button
                    fullWidth
                    className="ButtonMiller"
                    startIcon={<Upload />}
                    variant="contained"
                    onClick={() => {
                      document.getElementById("file").click();
                    }}
                  >
                    Carica Logo
                  </Button>
                  <input
                    type="file"
                    id="file"
                    accept="image/*"
                    onChange={(e) => handleChangeImage(e)}
                    style={{ display: "none" }}
                  />
                </div>
                <div className="col-span-2 md:col-span-1">
                  <TextField
                    value={cliente?.Denominazione}
                    id="outlined-basic"
                    label="Denominazione"
                    name="Denominazione"
                    onChange={handleChange}
                    fullWidth
                  />
                </div>
                <div className="col-span-2 md:col-span-1">
                  <TextField
                    value={cliente?.RagioneSociale}
                    id="outlined-basic"
                    label="Ragione Sociale"
                    name="RagioneSociale"
                    onChange={handleChange}
                    fullWidth
                  />
                </div>
                <div className="col-span-2 md:col-span-1">
                  <TextField
                    value={cliente?.SedeLegale}
                    id="outlined-basic"
                    label="Sede Legale"
                    name="SedeLegale"
                    onChange={handleChange}
                    fullWidth
                  />
                </div>
                <div className="col-span-2 md:col-span-1">
                  <TextField
                    value={cliente?.SedeAmministrativa}
                    id="outlined-basic"
                    label="Sede Amministrativa"
                    name="SedeAmministrativa"
                    onChange={handleChange}
                    fullWidth
                  />
                </div>
                <div className="col-span-2 md:col-span-1">
                  <TextField
                    value={cliente?.Telefono}
                    id="outlined-basic"
                    label="Telefono"
                    name="Telefono"
                    onChange={handleChange}
                    fullWidth
                  />
                </div>
                <div className="col-span-2 md:col-span-1">
                  <TextField
                    value={cliente?.Sito}
                    id="outlined-basic"
                    label="Sito Web"
                    name="Sito"
                    onChange={handleChange}
                    fullWidth
                  />
                </div>
                <div className="col-span-2 md:col-span-1">
                  <TextField
                    value={cliente?.Pec}
                    id="outlined-basic"
                    label="PEC"
                    name="Pec"
                    onChange={handleChange}
                    fullWidth
                  />
                </div>
                <div className="col-span-2 md:col-span-1">
                  <TextField
                    value={cliente?.Piva}
                    id="outlined-basic"
                    label="Partita IVA"
                    name="Piva"
                    onChange={handleChange}
                    fullWidth
                  />
                </div>
                <div className="col-span-2 md:col-span-1">
                  <TextField
                    value={cliente?.Rea}
                    id="outlined-basic"
                    label="REA"
                    name="Rea"
                    onChange={handleChange}
                    fullWidth
                  />
                </div>
                <div className="col-span-2 md:col-span-1">
                  <Autocomplete
                    id="combo-box-demo"
                    options={codiciAteco}
                    fullWidth
                    value={codiciAtecoSel}
                    getOptionLabel={(option) =>
                      option.Lettera +
                      " - " +
                      option.Codice +
                      " - " +
                      option.Descrizione
                    }
                    onChange={(event, newValue) => {
                      setCodiciAtecoSel(newValue);
                      if (newValue) {
                        setCliente({ ...cliente, CodiceAteco: newValue.ID });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Codice Ateco" />
                    )}
                  />
                </div>
                <div className="col-span-2">
                  <TextField
                    multiline
                    rows={3}
                    value={cliente?.Storia}
                    id="outlined-basic"
                    label="Storia"
                    name="Storia"
                    onChange={handleChange}
                    fullWidth
                  />
                </div>
                <div className="col-span-2">
                  <TextField
                    multiline
                    rows={3}
                    value={cliente?.Missione}
                    id="outlined-basic"
                    label="Missione"
                    name="Missione"
                    onChange={handleChange}
                    fullWidth
                  />
                </div>

                <div className="col-span-2 flex justify-end">
                  <Button
                    variant="contained"
                    className="ButtonMiller duration-300"
                    startIcon={<Check />}
                    onClick={handleSave}
                    ref={buttonSave}
                  >
                    Salva
                  </Button>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="w-full grid grid-cols-2 gap-3">
                <div className="col-span-2 md:col-span-1">
                  <TextField
                    value={cliente?.NomeRappresentante}
                    id="outlined-basic"
                    label="Nome Rappresentante"
                    name="NomeRappresentante"
                    onChange={handleChange}
                    fullWidth
                  />
                </div>
                <div className="col-span-2 md:col-span-1">
                  <TextField
                    value={cliente?.CognomeRappresentante}
                    id="outlined-basic"
                    label="Cognome Rappresentante"
                    name="CognomeRappresentante"
                    onChange={handleChange}
                    fullWidth
                  />
                </div>
                <div className="col-span-2 md:col-span-1">
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      ampmInClock={false}
                      value={
                        cliente?.DataNascitaRappresentante
                          ? moment(cliente?.DataNascitaRappresentante)
                          : null
                      }
                      renderInput={(props) => (
                        <TextField size="small" fullWidth {...props} />
                      )}
                      label="Data Di Nascita Rappresentante"
                      onChange={(newValue) => {
                        setCliente({
                          ...cliente,
                          DataNascitaRappresentante: newValue,
                        });
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <div className="col-span-2 md:col-span-1">
                  <TextField
                    value={cliente?.LuogoNascitaRappresentante}
                    id="outlined-basic"
                    label="Luogo Di Nascita Rappresentante"
                    name="LuogoNascitaRappresentante"
                    onChange={handleChange}
                    fullWidth
                  />
                </div>
                <div className="col-span-2 md:col-span-1">
                  <TextField
                    value={cliente?.BudgetAnnuale}
                    id="outlined-basic"
                    type="number"
                    label="Budget Annuale"
                    name="BudgetAnnuale"
                    onChange={handleChange}
                    fullWidth
                  />
                </div>
                <div className="col-span-2 flex justify-end">
                  <Button
                    variant="contained"
                    className="ButtonMiller"
                    startIcon={<Check />}
                    onClick={handleSave}
                  >
                    Salva
                  </Button>
                </div>
                <div className="col-span-2 mt-5">
                  <Divider />
                  <h1 className="text-2xl center uppercase text-center pt-2">
                    Membri del Comitato
                  </h1>
                </div>
                <div className="col-span-2 md:col-span-2">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    gap={2}
                  >
                    <Comitato
                      id="new"
                      saveFunction={handleSaveComitato}
                      title="Nuovo Membro"
                      snack={snack}
                      openSnack={openSnack}
                      handleCloseSnack={handleCloseSnack}
                      setSnack={setSnack}
                      setOpenSnack={setOpenSnack}
                    />
                  </Grid>
                  <Box sx={{ height: "60vh" }}>
                    <DataGridPro
                      components={{
                        Toolbar: CustomToolbarG,
                        Footer: CustomFooterPersonalized,
                      }}
                      localeText={
                        itIT.components.MuiDataGrid.defaultProps.localeText
                      }
                      rows={comitato}
                      columns={columnsComitato}
                      getRowId={(row) => row.ID}
                    />
                  </Box>
                </div>
                <div className="col-span-2 flex justify-end">
                  <Button
                    variant="contained"
                    className="ButtonMiller"
                    startIcon={<Check />}
                    onClick={handleSave}
                  >
                    Salva
                  </Button>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className="w-full flex justify-end">
                <FileUploader
                  colonna={"Parita"}
                  id={sessionStorage.getItem("Cliente")}
                  reloadAfterUpload={reloadAfterUpload}
                  Commento={
                    "All’interno dell’ organigramma è necessario inserire il responsabile della parità di genere e comitato"
                  }
                />
              </div>
              {/* {allegati.map((allegato) => {
                return (
                  <div className="w-1/2 my-2">{allegato?.Descrizione}</div>
                );
              })} */}
              <Box sx={{ height: "60vh", width: "100%" }}>
                <DataGridPro
                  components={{
                    Toolbar: CustomToolbar,
                    Footer: CustomFooterPersonalized,
                  }}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  columns={columnsAllegati}
                  rows={allegati}
                  getRowId={(allegati) => allegati.ID}
                />
              </Box>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                gap={2}
              >
                <Mansionario
                  id="new"
                  saveFunction={handleSaveMansionario}
                  title="Nuova Mansione"
                  snack={snack}
                  openSnack={openSnack}
                  handleCloseSnack={handleCloseSnack}
                  setSnack={setSnack}
                  setOpenSnack={setOpenSnack}
                />
              </Grid>
              <Box sx={{ height: "60vh" }}>
                <DataGridPro
                  components={{
                    Toolbar: CustomToolbarG,
                    Footer: CustomFooterPersonalized,
                  }}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  rows={mansionario}
                  columns={columns}
                  getRowId={(row) => row.ID}
                />
              </Box>
            </TabPanel>
          </div>
        </div>
      </>
    </>
  );
};

export default ClienteParita;

export const Mansionario = (props) => {
  const [open, setOpen] = useState(false);
  const [tipologie, setTipologie] = useState([]);
  const [tipologieSel, setTipologieSel] = useState(null);
  const [gruppi, setGruppi] = useState([]);
  const [gruppiSel, setGruppiSel] = useState(null);
  const [livelli, setLivelli] = useState([]);
  const [livelliSel, setLivelliSel] = useState(null);
  const [mansionario, setMansionario] = useState({
    IDTipologia: 0,
    IDLivello: 0,
    IDGruppo: 0,
    Ccnl: "",
    AttivitaSvolta: "",
    Responsabilita: "",
    Competenze: "",
    IDParitaCliente: 0,
  });

  const handleClickOpen = async () => {
    let responseTipologie = await unsplash.get("tipologieCcnlParita", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    let responseLivelli = await unsplash.get("livelliParita", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    let responseGruppi = await unsplash.get("gruppiParita", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    if (props.id === "edit") {
      setMansionario({
        ...props.mansionario,
      });
      setTipologieSel(
        responseTipologie.data.data?.filter(
          (gruppo) => gruppo.ID === props.mansionario.IDTipologia
        )[0]
      );

      setLivelliSel(
        responseLivelli.data.data?.filter(
          (livello) => livello.ID === props.mansionario.IDLivello
        )[0]
      );
      setGruppiSel(
        responseGruppi.data.data?.filter(
          (livello) => livello.ID === props.mansionario.IDGruppo
        )[0]
      );
    }
    setLivelli(responseLivelli.data.data);
    setTipologie(responseTipologie.data.data);
    setGruppi(responseGruppi.data.data);
    setOpen(true);
  };

  const handleClose = () => {
    setMansionario({
      Gruppo: "",
      IDGruppo: 0,
      Tipologia: "",
      IDTipologia: 0,
      IDLivello: 0,
      Ccnl: "",
      Livello: "",
      AttivitaSvolta: "",
      Responsabilita: "",
      Competenze: "",
      IDParitaCliente: 0,
    });
    setTipologieSel(null);
    setLivelliSel(null);
    setGruppiSel(null);
    setOpen(false);
  };

  const handleChange = (e) => {
    setMansionario({ ...mansionario, [e.target.name]: e.target.value });
  };
  const handleSave = async () => {
    props.saveFunction(mansionario, props.id);
    handleClose();
  };

  return (
    <>
      {props.id === "edit" ? (
        <IconButton id={props.id} aria-label="edit" onClick={handleClickOpen}>
          <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
        </IconButton>
      ) : (
        <Button
          startIcon={<Add />}
          onClick={handleClickOpen}
          id={props.id}
          sx={{ color: "white" }}
          variant="outlined"
          className="ButtonMiller"
        >
          Aggiungi
        </Button>
      )}

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="lg"
          TransitionComponent={Transition}
        >
          <DialogTitle
            sx={{
              mx: "auto",
            }}
          >
            {props.title}
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={6} md={3}>
                <Autocomplete
                  id="combo-box-demo"
                  options={tipologie}
                  fullWidth
                  value={tipologieSel}
                  getOptionLabel={(option) => option.Descrizione}
                  onChange={async (event, newValue) => {
                    let newMansionario = { ...mansionario };
                    setTipologieSel(newValue);
                    if (newValue) {
                      newMansionario = {
                        ...newMansionario,
                        IDTipologia: newValue.ID,
                      };

                      if (mansionario.IDLivello) {
                        let response = await unsplash.get("ccnlParita", {
                          params: {
                            idTipologia: newValue.ID,
                            idLivello: mansionario.IDLivello,
                          },
                          headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                              "jwt"
                            )}`,
                          },
                        });
                        if (response.data.data?.length > 0) {
                          newMansionario = {
                            ...newMansionario,
                            Ccnl: response.data.data[0]?.Descrizione,
                            IDCcnl: response.data.data[0]?.ID,
                          };
                        } else {
                          newMansionario = {
                            ...newMansionario,
                            Ccnl: "",
                            IDCcnl: null,
                          };
                        }
                      }
                      setMansionario(newMansionario);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Tipologia" />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <Autocomplete
                  id="combo-box-demo"
                  options={livelli}
                  fullWidth
                  value={livelliSel}
                  getOptionLabel={(option) => option.Descrizione}
                  onChange={async (event, newValue) => {
                    let newMansionario = { ...mansionario };
                    setLivelliSel(newValue);
                    if (newValue) {
                      newMansionario = {
                        ...newMansionario,
                        IDLivello: newValue.ID,
                      };

                      if (mansionario.IDTipologia) {
                        let response = await unsplash.get("ccnlParita", {
                          params: {
                            idLivello: newValue.ID,
                            idTipologia: mansionario.IDTipologia,
                          },
                          headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                              "jwt"
                            )}`,
                          },
                        });
                        if (response.data.data?.length > 0) {
                          newMansionario = {
                            ...newMansionario,
                            Ccnl: response.data.data[0]?.Descrizione,
                            IDCcnl: response.data.data[0]?.ID,
                          };
                        } else {
                          newMansionario = {
                            ...newMansionario,
                            Ccnl: "",
                            IDCcnl: null,
                          };
                        }
                      }
                    }
                    setMansionario(newMansionario);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Livello" />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Autocomplete
                  id="combo-box-demo"
                  options={gruppi}
                  fullWidth
                  value={gruppiSel}
                  getOptionLabel={(option) => option.Descrizione}
                  onChange={async (event, newValue) => {
                    let newMansionario = { ...mansionario };
                    setGruppiSel(newValue);
                    if (newValue) {
                      newMansionario = {
                        ...newMansionario,
                        IDGruppo: newValue.ID,
                      };
                      setMansionario(newMansionario);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Gruppo" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={mansionario?.AttivitaSvolta}
                  id="outlined-basic"
                  label="Attivita Svolta"
                  name="AttivitaSvolta"
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={mansionario?.Responsabilita}
                  id="outlined-basic"
                  label="Responsabilita"
                  name="Responsabilita"
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={mansionario?.Competenze}
                  id="outlined-basic"
                  label="Competenze"
                  name="Competenze"
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled
                  multiline
                  rows={5}
                  value={mansionario?.Ccnl}
                  id="outlined-basic"
                  label="Ccnl"
                  name="Ccnl"
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Button
                  onClick={handleClose}
                  variant="contained"
                  className="ButtonMiller"
                  startIcon={<Close />}
                >
                  Chiudi
                </Button>
                <Button
                  onClick={handleSave}
                  variant="contained"
                  className="ButtonMiller"
                  startIcon={<Check />}
                >
                  Salva
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
};

export const Comitato = (props) => {
  const [open, setOpen] = useState(false);
  const [comitato, setComitato] = useState({
    Nome: "",
    Cognome: "",
    Responsabile: 0,
  });

  const handleClickOpen = async () => {
    if (props.id === "edit") {
      setComitato({
        ...props.comitato,
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setComitato({
      Nome: "",
      Cognome: "",
      Responsabile: 0,
    });
    setOpen(false);
  };

  const handleChange = (e) => {
    setComitato({ ...comitato, [e.target.name]: e.target.value });
  };
  const handleSave = async () => {
    props.saveFunction(comitato, props.id);
    handleClose();
  };

  return (
    <>
      {props.id === "edit" ? (
        <IconButton id={props.id} aria-label="edit" onClick={handleClickOpen}>
          <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
        </IconButton>
      ) : (
        <Button
          startIcon={<Add />}
          onClick={handleClickOpen}
          id={props.id}
          sx={{ color: "white" }}
          variant="outlined"
          className="ButtonMiller"
        >
          Aggiungi
        </Button>
      )}

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="lg"
          TransitionComponent={Transition}
        >
          <DialogTitle
            sx={{
              mx: "auto",
            }}
          >
            {props.title}
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} md={6}>
                <TextField
                  value={comitato?.Nome}
                  id="outlined-basic"
                  label="Nome"
                  name="Nome"
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={comitato?.Cognome}
                  id="outlined-basic"
                  label="Cognome"
                  name="Cognome"
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={comitato?.Responsabile === 1}
                      onChange={(e) =>
                        setComitato({
                          ...comitato,
                          Responsabile: e.target.checked ? 1 : 0,
                        })
                      }
                    />
                  }
                  label="Responsabile"
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Button
                  onClick={handleClose}
                  variant="contained"
                  className="ButtonMiller"
                  startIcon={<Close />}
                >
                  Chiudi
                </Button>
                <Button
                  onClick={handleSave}
                  variant="contained"
                  className="ButtonMiller"
                  startIcon={<Check />}
                >
                  Salva
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
};

const DeleteDialog = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          {props.comitato
            ? "Sei sicuro di voler eliminare il membro selezionato?"
            : "Sei sicuro di voler eliminare la mansione selezionata?"}
        </DialogTitle>
        <DialogContent>
          {props.comitato ? (
            <DialogContentText textAlign={"center"}>
              Attenzione, sei sicuro di voler eliminare il seguente membro:
              <b>
                {" " + props.comitato?.Nome} - {props.comitato?.Cognome}
              </b>
            </DialogContentText>
          ) : (
            <DialogContentText textAlign={"center"}>
              Attenzione, sei sicuro di voler eliminare il seguente mansione:
              <b>
                {" " + props.mansione?.Gruppo} - {props.mansione?.Livello}
              </b>
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button
            onClick={() => {
              if (props.comitato) {
                props.myFunction(props.comitato.ID);
              } else {
                props.myFunction(props.mansione.ID);
              }
            }}
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
