import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import unsplash from "../api/unsplash";
import { useNavigate } from "react-router-dom";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import {
  TextField,
  Box,
  Typography,
  Tab,
  Tabs,
  Grid,
  Checkbox,
  FormControlLabel,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Button,
  CardMedia,
  Divider,
  CardActionArea,
  Tooltip,
  Avatar,
  Chip,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Popover,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  Zoom,
  LinearProgress,
} from "@mui/material";
import SmartToyIcon from "@mui/icons-material/SmartToy";

import Lavoratori from "./Lavoratori";
import {
  AccountCircle,
  AttachFile,
  Home,
  Work,
  Engineering,
  MedicalServices,
  Euro,
  School,
  LocationOn,
  Check,
  ArrowBack,
  People,
  Error,
  Info,
  Upload,
  Circle,
  NewReleases,
  CheckCircle,
  TimeToLeave,
} from "@mui/icons-material";
import SpeakerNotesRoundedIcon from "@mui/icons-material/SpeakerNotesRounded";

import { useLocation } from "react-router";
import Mansioni from "./Mansioni";
import Allegati from "./Allegati";
import moment from "moment";
import Sedi from "./Sedi";
import MediciCliente from "./MediciCliente";
import FatturazioneMedicina from "./FatturazioneMedicina";
import CorsiCliente from "./CorsiCliente";
import Sopralluoghi from "./DettaglioCliente/Sopralluoghi";
import GlobalSnackbar from "./Snackbar";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Memo from "./Memo";
import PercentIcon from "@mui/icons-material/Percent";
import "moment/locale/it";

import { LocalizationProvider,DatePicker,PickersDay,TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment as DateAdapter} from '@mui/x-date-pickers/AdapterMoment'
import Chatbot from "./Chatbot";
import PsychologyIcon from "@mui/icons-material/Psychology";
import ExtraMedici from "./ExtraMedici";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const ClientDetail = (props) => {
  const location = useLocation();
  const [cliente, setCliente] = useState({
    Logo: "",
    ID: 0,
    RagioneSociale: "",
    SedeLocalita: "",
    SedeIndirizzo: "",
    SedeCAP: "",
    UtenteSicurezza: "",
    UtenteAssociato: "",
    UtenteFormazione: "",
    TipologiaAzienda: "",
    CodiceIdent: "",
    Email: "",
    CF: "",
    PIVA: "",
    Telefono: "",
    CodiceATECO: "",
    CDC: "",
    SedeIndirizzo: "",
    SedeLocalita: "",
    SedeCAP: "",
    PostIndirizzo: "",
    PostLocalita: "",
    PostCAP: "",
    GestioneCorsi: "",
    GestioneSopralluoghi: "",
    InvioSollMedico: "",
    Notarizzazione: "",
    FatturaVisiteMediche: "",
    Cessato: "",
    Sicurezza: "",
    NomeReferente: "",
  });
  const [logo, setLogo] = useState("");
  const [oldCliente, setOldCliente] = useState();
  const [value, setValue] = React.useState(0);
  const [lavoratori, setLavoratori] = useState([]);
  const [loadingLav, setLoadingLav] = useState(true);
  const [loadingNuoviLav, setLoadingNuoviLav] = useState(true);
  const [nuoviLav, setNuoviLav] = useState([]);

  const [loadingVis, setLoadingVis] = useState(true);
  const [loadingNuov, setLoadingNuov] = useState(true);

  const [loadingScadutiMedicina, setLoadingScadutiMedicina] = useState(true);
  const [lavoratoriScadutiMedicina, setLavScadutiMedicina] = useState([]);

  const [utenti, setUtenti] = useState([]);
  const [utentiSicurezza, setUtentiSicurezza] = useState([]);
  const [utentiFormazione, setUtentiFormazione] = useState([]);
  const [memoVisibili, setMemoVisibili] = useState([]);
  const [loadingMemo, setLoadingMemo] = useState(true);
  const [user, setUser] = useState({
    Formazione: 1,
    Medicina: 1,
    Sicurezza: 1,
    username: "",
  });

  const [TipologiaAzienda, setTipologiaAzienda] = useState([
    { value: "SRL" },
    { value: "SRL Unipersonali" },
    { value: "SPA" },
    { value: "Cooperative" },
    { value: "Associazioni" },
    { value: "Ditte individuali" },
  ]);
  const [CodiceIdent, setCodiceIdent] = useState([
    { value: "Regione" },
    { value: "SDI" },
    { value: "PEC" },
    { value: "Amministratore (cliente)" },
  ]);
  const [lavoratoriCorsiInScadenza, setLavoratoriCorsiInScadenza] = useState(
    []
  );
  const [loading, setLoading] = useState(true);
  const [analysis, setAnalysis] = useState({
    totale: 0,
    formati: 0,
    nonFormati: 0,
    percentualeFormati: 0,
    percentualeNonFormati: 0,
  });
  const [loadingA, setLoadingA] = useState(true);

  const [analysisMedicina, setAnalysisMedicina] = useState({
    totale: 0,
    visitati: 0,
    nonVisitati: 0,
    percentualeVisitati: 0,
    percentualeNonVisitati: 0,
  });
  const [loadingM, setLoadingM] = useState(true);
  const [adempimentiScaduti, setAdempimentiScaduti] = useState([]);
  const [loadingScad, setLoadingScad] = useState(true);

  const navigate = useNavigate();
  const [openSnack, setOpenSnack] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openInfo = Boolean(anchorEl);

  const handNew = useRef(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const columnsMemo = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Info",
      width: 50,
      hide: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <Info
            sx={{ color: "#2265B1", width: "30px", height: "30px" }}
            onClick={(event) => setAnchorEl(event.currentTarget)}
          />
          <Popover
            open={openInfo}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Typography sx={{ p: 2, maxWidth: "400px" }}>
              <b> {params.row.Utente + ": "}</b> <br />
              {params.row.Nota}
            </Typography>
          </Popover>
        </>
      ),
    },
    {
      field: "Data",
      headerName: "Data",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          {params.row.Data ? moment(params.row.Data).format("DD/MM/YYYY") : ""}
        </>
      ),
    },
    {
      field: "Nota",
      headerName: "Nota",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];
  const columnSicurezzaLavoratoriCorsiInScadenza = [
    {
      field: "Cognome",
      headerName: "Cognome",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Nome",
      headerName: "Nome",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "*",
      headerName: "Corsi",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => {
        if (params.row.BaseOK === 1) {
          return (
            <div>
              <Tooltip
                title={
                  "Scaduto il " +
                  moment(params.row.Scadenza).format("DD/MM/YYYY")
                }
              >
                <Chip
                  label={params.row.Ruolo}
                  size="small"
                  sx={{
                    width: "70px",
                    marginLeft: "2px",
                    backgroundColor: "#FF2768",
                    color: "white",
                  }}
                />
              </Tooltip>
            </div>
          );
        } else {
          return (
            <div>
              <Tooltip title={"Corso base mancante"}>
                <Chip
                  label={params.row.Ruolo}
                  size="small"
                  sx={{
                    width: "70px",
                    marginLeft: "2px",
                    backgroundColor: "#2265B1",
                    color: "white",
                  }}
                />
              </Tooltip>
            </div>
          );
        }
      },
    },
    {
      field: "Scadenza",
      headerName: "Scadenza",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => {
        return (
          <div>
            {params.row.Scadenza
              ? moment(params.row.Scadenza).format("DD - MM - YYYY")
              : ""}
          </div>
        );
      },
    },
    {
      field: "DescrizioneCorso",
      headerName: "Descrizione",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
  ];
  const columnsAdempimenti = [
    {
      field: "*",
      headerName: "Stato",
      width: 50,
      renderCell: (params) => {
        return <Error sx={{ color: "#FF2768" }} />;
      },
    },
    { field: "Descrizione", headerName: "Descrizione", flex: 1 },
    { field: "Scadenza", headerName: "Validità", flex: 1 },
    {
      field: "UltimaScadenza",
      headerName: "Data",
      flex: 1,
      renderCell: (params) =>
        params.row.UltimaScadenza
          ? moment(params.row.UltimaScadenza).format("DD/MM/YYYY")
          : "",
    },
  ];

  const columnsNuoviLavoratori = [
    {
      field: "*",
      headerName: "Stato",
      width: 50,
      renderCell: (params) => {
        return <NewReleases sx={{ color: "#51E838" }} />;
      },
    },
    { field: "CF", headerName: "CF", flex: 1 },
    { field: "Nome", headerName: "Nome", flex: 1 },
    { field: "Cognome", headerName: "Cognome", flex: 1 },
  ];

  const handleAncor = () => {
    handNew.current.scrollIntoView({ behavior: "smooth" });
  };

  const columnsLavoratoriScadutioInScadenza = [
    {
      field: "StatoVisita",
      headerName: "Stato",
      width: 20,
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <div>
          {params.row.StatoVisita === "OK" ? (
            <CheckCircle sx={{ color: "#40C18F" }} />
          ) : (
            <Error
              sx={{
                color:
                  params.row.StatoVisita === "Scadenza" ||
                  params.row.StatoVisita === "InScadenza"
                    ? "#FFC897"
                    : params.row.StatoVisita === "Parziale"
                    ? "#2265B1"
                    : "#FF2768",
              }}
            />
          )}
        </div>
      ),
    },
    { field: "Nome", headerName: "Nome", flex: 1 },
    { field: "Cognome", headerName: "Cognome", flex: 1 },
    { field: "RagioneSociale", headerName: "Cliente", flex: 1 },
    { field: "Scadenza", headerName: "Scadenza", width: 90 },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleInputChange = (e) => {
    let copiaCliente = { ...cliente };
    copiaCliente[e.target.name] = e.target.value;

    setCliente(copiaCliente);
  };

  const handleCboxChange = (e) => {
    let copiaCliente = { ...cliente };
    if (e.target.name === "GestioneMedicina") {
      copiaCliente[e.target.name] = e.target.checked ? 1 : 0;

      copiaCliente["InvioSollMedico"] = e.target.checked ? 1 : 0;
      copiaCliente["FatturaVisiteMediche"] = e.target.checked ? 1 : 0;
    } else {
      copiaCliente[e.target.name] = e.target.checked ? 1 : 0;
    }

    setCliente(copiaCliente);
  };

  const getBase64 = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {};
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleChangeImage = async (e) => {
    const file = e.target.files[0]; // prendo la foto caricata sempre nell'evento
    var reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = function () {
      document.getElementById("Logo").src = reader.result;
      cliente.Logo = reader.result.replace("data:image/png;base64,", "");
      cliente.Logo = reader.result.replace("data:image/jpeg;base64,", "");
    };
    reader.onerror = function (error) {};
  };
  //save data
  const saveData = async () => {
    try {
      await unsplash
        .post(
          "updateClienti",
          { auth: "a5d42c3ed7977afa2d8e922569e7940b", cliente: cliente },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        )
        .then((res) => {});

      await unsplash
        .post(
          "storico",
          {
            tabella: "Clienti",
            key: cliente.ID,
            utente: sessionStorage.getItem("username"),
            after: cliente,
            before: oldCliente,
            operazione: "Modifica",
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        )
        .then((res) => {});
      setSnack({
        severity: "success",
        messaggio: "Cliente salvato con successo",
      });
      setOpenSnack(true);
      setOldCliente(cliente);
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante il salvataggio",
      });
      setOpenSnack(true);
    }
  };

  const handleFile = async () => {
    await unsplash
      .post(
        "ftp",
        { operazione: "download" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((res) => {
        window.open("http://localhost:3000/file");
      });
  };

  const reloadLavoratori = () => {
    let responseAziendali = unsplash.get("lavoratori", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: location.state.idC },
    });

    setLavoratori(responseAziendali);
    setLoadingLav(false);
  };

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      try {
        let response = await unsplash.post(
          "clientDetail",
          { auth: "a5d42c3ed7977afa2d8e922569e7940b", id: location.state.idC },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        await setCliente(response.data.data[0]);

        setOldCliente(response.data.data[0]);

        let resMemo = await unsplash.get("memo", {
          params: { idMemo: location.state.idC },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });

        setLoadingMemo(false);
        setMemoVisibili(resMemo.data.data);

        let resNuoviLav = await unsplash.get("lavoratori/nuovi", {
          params: { cliente: location.state.idC },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });

        setNuoviLav(resNuoviLav.data.data);
        setLoadingNuoviLav(false);

        let rescadutiMedicina = await unsplash.get(
          "lavoratori/scaduti-medicina",
          {
            params: { cliente: location.state.idC },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        setLavScadutiMedicina(rescadutiMedicina.data.data);
        setLoadingScadutiMedicina(false);

        let responseAziendali = await unsplash.get("lavoratori", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { id: location.state.idC },
        });

        setLavoratori(responseAziendali);

        let responseUtenti = await unsplash.get("utenti", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });

        const utentiMedicina = responseUtenti.data.data.filter(
          (elm) => elm.tipoUtente === "Medicina"
        );
        const utentiSicurezza = responseUtenti.data.data.filter(
          (elm) => elm.tipoUtente === "Sicurezza" && elm.username !== "federico"
        );
        const utentiFormazione = responseUtenti.data.data.filter(
          (elm) => elm.tipoUtente === "Formazione"
        );
        setUtenti(utentiMedicina);
        setUtentiSicurezza(utentiSicurezza);
        setUtentiFormazione(utentiFormazione);

        let responseAnalysis = await unsplash.get("analysis", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: {
            cliente: location.state.idC,
            operazione: "sicurezzaPercentualiCliente",
          },
        });

        setAnalysis(responseAnalysis.data.data);
        setLoadingA(false);

        let responseAdempimenti = await unsplash.get("analysis", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: {
            ID: location.state.idC,
            operazione: "scadenzeAdempimentiClienti",
          },
        });

        setAdempimentiScaduti(responseAdempimenti.data.data);
        setLoadingScad(false);

        let responseAnalysisCliente = await unsplash.get("analysis", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: {
            cliente: location.state.idC,
            operazione: "sicurezzaLavoratoriCorsiInScadenzaCliente",
          },
        });

        setLavoratoriCorsiInScadenza(responseAnalysisCliente.data.data);
        setLoading(false);

        let responseAnalysisMedicina = await unsplash.get("analysis", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: {
            cliente: location.state.idC,
            operazione: "medicinaPercentualiCliente",
          },
        });

        setAnalysisMedicina(responseAnalysisMedicina.data.data);
        setLoadingM(false);

        setLogo(document.getElementById("Logo").src);

        let responseUsr = await unsplash.get("utenti", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });

        const user = responseUsr.data.data.filter(
          (utente) => sessionStorage.getItem("username") === utente.username
        );
        setUser(user[0]);

        setLoadingScad(false);
        setLoadingNuov(false);
        setLoadingVis(false);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid item xs={12}>
        <Card sx={{ position: "relative", my: "auto" }}>
          <CardHeader
            sx={{ textAlign: "center" }}
            title={location.state.RagioneSociale}
            titleTypographyProps={{ variant: "h4" }}
          />
        </Card>

        <Card sx={{ marginTop: "10px" }}>
          <CardContent sx={{ p: 0 }}>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  variant="scrollable"
                >
                  <Tab
                    icon={<DashboardIcon />}
                    iconPosition="start"
                    label="Dashboard"
                  />
                  <Tab
                    icon={<Home />}
                    iconPosition="start"
                    label="Sedi"
                    style={{
                      display:
                        user.Formazione && !user.Medicina && !user.Sicurezza
                          ? "none"
                          : "",
                    }}
                  />
                  <Tab
                    icon={<Work />}
                    iconPosition="start"
                    label="Dati Aziendali"
                  />
                  <Tab
                    icon={<AccountCircle />}
                    iconPosition="start"
                    label="Lavoratori"
                  />
                  <Tab
                    icon={<AttachFile />}
                    iconPosition="start"
                    label="Allegati"
                    style={{
                      display:
                        user.Formazione && !user.Medicina && !user.Sicurezza
                          ? "none"
                          : "",
                    }}
                  />

                  <Tab
                    icon={<MedicalServices />}
                    iconPosition="start"
                    label="Medici"
                    style={{
                      display:
                        user.Formazione && !user.Medicina && !user.Sicurezza
                          ? "none"
                          : "",
                    }}
                  />

                  <Tab
                    icon={<Engineering />}
                    iconPosition="start"
                    label="Mansioni"
                    style={{
                      display:
                        user.Formazione && !user.Medicina && !user.Sicurezza
                          ? "none"
                          : "",
                    }}
                  />

                  <Tab
                    icon={<Euro />}
                    iconPosition="start"
                    label="Fatt. Medicina"
                    style={{
                      display:
                        user.Formazione && !user.Medicina && !user.Sicurezza
                          ? "none"
                          : "",
                    }}
                  />

                  <Tab
                    icon={<School />}
                    iconPosition="start"
                    label="Piano Corsi"
                    style={{
                      display: user.Medicina && !user.Formazione ? "none" : "",
                    }}
                  />
                  <Tab
                    icon={<LocationOn />}
                    iconPosition="start"
                    label="Sopralluoghi"
                  />
                  {cliente?.Gruppo === "Gavio" && (
                    <Tab
                      icon={<TimeToLeave />}
                      iconPosition="start"
                      label="EXTRA MEDICI"
                    />
                  )}
                  <Tab
                    icon={<SpeakerNotesRoundedIcon />}
                    iconPosition="start"
                    label="Memo"
                  />
                  <Tab
                    icon={<PsychologyIcon />}
                    iconPosition="start"
                    label="KoAI"
                  />
                </Tabs>
              </Box>
              <TabPanel
                value={value}
                index={0}
                style={{
                  marginTop: "5px",
                  backgroundColor: "#11526F",
                  borderRadius: "5px",
                }}
              >
                {analysis.length === 0 ? (
                  <div>
                    <center>
                      <CircularProgress color="primary" />
                    </center>
                  </div>
                ) : (
                  <>
                    <Grid container spacing={2}>
                      {!loadingNuoviLav & (nuoviLav.length > 0) ? (
                        <Grid xs={12} item>
                          <Button
                            className="Button"
                            sx={{ color: "white" }}
                            startIcon={
                              <NewReleases className="animated-icon-green" />
                            }
                            variant="text"
                            onClick={handleAncor}
                          >
                            Presenti Nuovi Lavoratori
                          </Button>
                        </Grid>
                      ) : (
                        ""
                      )}
                      {/* Formazione Lavoratori*/}
                      <Grid xs={12} md={6} item>
                        <Card sx={{ boxShadow: "none" }}>
                          <CardHeader title="Andamento formazione" />

                          <CardContent>
                            <Grid container spacing={1}>
                              <Grid
                                xs={12}
                                lg={4}
                                item
                                sx={{ my: "auto", padding: 0 }}
                              >
                                {/* {loadingA ? (
                                  <center>
                                    <CircularProgress
                                      sx={{
                                        marginLeft: "6px",
                                        color: "#2265B1",
                                      }}
                                    />
                                  </center>
                                ) : (
                                  <> */}
                                <center>
                                  <CircularProgressWithLabel
                                    variant="determinate"
                                    value={analysis.percentualeFormati}
                                    size={150}
                                  />
                                </center>
                                {/*  </>
                                )} */}
                              </Grid>
                              <Grid xs={12} lg={8} item>
                                <div className="flex flex-no-wrap items-center">
                                  <PercentIcon
                                    sx={{ fontSize: 55, color: "#11526F" }}
                                  />
                                  <div
                                    style={{ marginLeft: "5px" }}
                                    className="flex flex-wrap"
                                  >
                                    <span
                                      className="w-full fw-light fs-6"
                                      style={{
                                        color: "rgba(58, 53, 65, 0.6)",
                                      }}
                                    >
                                      Lavoratori formati
                                    </span>
                                    <h6
                                      className="fs-5 fw-bold"
                                      style={{ color: "#11526F" }}
                                    >
                                      {analysis.percentualeFormati}
                                    </h6>
                                  </div>
                                </div>
                                <Divider
                                  sx={{ color: "rgba(58, 53, 65, 0.6)" }}
                                />
                                <div
                                  className="flex flex-wrap  "
                                  style={{ padding: "15px 0" }}
                                >
                                  <div className="w-full flex flex-wrap items-center">
                                    <Avatar
                                      sx={{
                                        fontSize: 40,
                                        background: "#2265B1",
                                      }}
                                    >
                                      <People></People>
                                    </Avatar>
                                    <div
                                      style={{ marginLeft: "5px" }}
                                      className="flex flex-wrap"
                                    >
                                      <span
                                        style={{
                                          float: "left",
                                          marginRight: "5px",
                                          width: "100%",
                                          color: "rgba(58, 53, 65, 0.6)",
                                        }}
                                      >
                                        {" Totale corsisti"}
                                      </span>
                                      <span
                                        className="fs-6 fw-semibold"
                                        style={{ color: "#11526F" }}
                                      >
                                        {analysis.totale}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="w-full flex flex-wrap items-center">
                                    <Avatar
                                      sx={{
                                        fontSize: 40,
                                        background: "#40C18F",
                                      }}
                                    >
                                      <People></People>
                                    </Avatar>
                                    <div
                                      style={{ marginLeft: "5px" }}
                                      className="flex flex-wrap"
                                    >
                                      <span
                                        style={{
                                          float: "left",
                                          marginRight: "5px",
                                          width: "100%",
                                          color: "rgba(58, 53, 65, 0.6)",
                                        }}
                                      >
                                        {" Formazione completa"}
                                      </span>
                                      <span
                                        className="fs-6 fw-semibold"
                                        style={{ color: "#11526F" }}
                                      >
                                        {analysis.formati}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="w-full flex flex-wrap items-center">
                                    <Avatar
                                      sx={{
                                        fontSize: 40,
                                        background: "#FF2768",
                                      }}
                                    >
                                      <People></People>
                                    </Avatar>
                                    <div
                                      style={{ marginLeft: "5px" }}
                                      className="flex flex-wrap"
                                    >
                                      <span
                                        style={{
                                          float: "left",
                                          marginRight: "5px",
                                          width: "100%",
                                          color: "rgba(58, 53, 65, 0.6)",
                                        }}
                                      >
                                        {" Formazione incompleta"}
                                      </span>
                                      <span
                                        className="fs-6 fw-semibold"
                                        style={{ color: "#11526F" }}
                                      >
                                        {analysis.nonFormati}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                      {/* Formazione Visite */}{" "}
                      <Grid xs={12} md={6} item>
                        <Card sx={{ boxShadow: "none" }}>
                          <CardHeader title="Andamento sorveglianza" />

                          <CardContent>
                            <Grid container spacing={1}>
                              <Grid
                                xs={12}
                                lg={4}
                                item
                                sx={{ my: "auto", padding: 0 }}
                              >
                                {/* {loadingA ? (
                                  <center>
                                    <CircularProgress
                                      sx={{
                                        marginLeft: "6px",
                                        color: "#2265B1",
                                      }}
                                    />
                                  </center>
                                ) : (
                                  <> */}
                                <center>
                                  <CircularProgressWithLabel
                                    variant="determinate"
                                    value={analysisMedicina.percentualeVisitati}
                                    size={150}
                                  />
                                </center>
                                {/* </>
                                )} */}
                              </Grid>
                              <Grid xs={12} lg={8} item>
                                <div className="flex flex-no-wrap items-center">
                                  <PercentIcon
                                    sx={{ fontSize: 55, color: "#11526F" }}
                                  />
                                  <div
                                    style={{ marginLeft: "5px" }}
                                    className="flex flex-wrap"
                                  >
                                    <span
                                      className="w-full fw-light fs-6"
                                      style={{
                                        color: "rgba(58, 53, 65, 0.6)",
                                      }}
                                    >
                                      Lavoratori sorvegliati
                                    </span>
                                    <h6
                                      className="fs-5 fw-bold"
                                      style={{ color: "#11526F" }}
                                    >
                                      {analysisMedicina.percentualeVisitati}
                                    </h6>
                                  </div>
                                </div>
                                <Divider
                                  sx={{ color: "rgba(58, 53, 65, 0.6)" }}
                                />
                                <div
                                  className="flex flex-wrap  "
                                  style={{ padding: "15px 0" }}
                                >
                                  <div className="w-full flex flex-wrap items-center">
                                    <Avatar
                                      sx={{
                                        fontSize: 40,
                                        background: "#2265B1",
                                      }}
                                    >
                                      <People></People>
                                    </Avatar>
                                    <div
                                      style={{ marginLeft: "5px" }}
                                      className="flex flex-wrap"
                                    >
                                      <span
                                        style={{
                                          float: "left",
                                          marginRight: "5px",
                                          width: "100%",
                                          color: "rgba(58, 53, 65, 0.6)",
                                        }}
                                      >
                                        {" Totale sorvegliati"}
                                      </span>
                                      <span
                                        className="fs-6 fw-semibold"
                                        style={{ color: "#11526F" }}
                                      >
                                        {analysisMedicina.totale}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="w-full flex flex-wrap items-center">
                                    <Avatar
                                      sx={{
                                        fontSize: 40,
                                        background: "#40C18F",
                                      }}
                                    >
                                      <People></People>
                                    </Avatar>
                                    <div
                                      style={{ marginLeft: "5px" }}
                                      className="flex flex-wrap"
                                    >
                                      <span
                                        style={{
                                          float: "left",
                                          marginRight: "5px",
                                          width: "100%",
                                          color: "rgba(58, 53, 65, 0.6)",
                                        }}
                                      >
                                        {" Sorveglianza completa"}
                                      </span>
                                      <span
                                        className="fs-6 fw-semibold"
                                        style={{ color: "#11526F" }}
                                      >
                                        {analysisMedicina.visitati}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="w-full flex flex-wrap items-center">
                                    <Avatar
                                      sx={{
                                        fontSize: 40,
                                        background: "#FF2768",
                                      }}
                                    >
                                      <People></People>
                                    </Avatar>
                                    <div
                                      style={{ marginLeft: "5px" }}
                                      className="flex flex-wrap"
                                    >
                                      <span
                                        style={{
                                          float: "left",
                                          marginRight: "5px",
                                          width: "100%",
                                          color: "rgba(58, 53, 65, 0.6)",
                                        }}
                                      >
                                        {" Sorveglianza incompleta"}
                                      </span>
                                      <span
                                        className="fs-6 fw-semibold"
                                        style={{ color: "#11526F" }}
                                      >
                                        {analysisMedicina.nonVisitati}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                      {/* Corsi Scaduti */}{" "}
                      <Grid item xs={12} md={6}>
                        <Card sx={{ boxShadow: "none" }}>
                          <CardHeader title="Corsi Scaduti" />

                          <CardContent>
                            <Box
                              sx={{
                                height: 300,
                                width: 1,
                              }}
                            >
                              <DataGridPro
                                initialState={{
                                  pinnedColumns: { left: ["Actions"] },
                                }}
                                sx={{ fontSize: 12 }}
                                components={{
                                  Toolbar: CustomToolbar,
                                  Footer: CustomFooterPersonalized,
                                }}
                                slots={{
                                  loadingOverlay: LinearProgress,
                                }}
                                loading={loading}
                                localeText={
                                  itIT.components.MuiDataGrid.defaultProps
                                    .localeText
                                }
                                rows={lavoratoriCorsiInScadenza}
                                columns={
                                  columnSicurezzaLavoratoriCorsiInScadenza
                                }
                                getRowId={(row) =>
                                  row.Lavoratore + "-" + row.Ruolo
                                }
                                pageSize={100}
                                disableSelectionOnClick
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Card sx={{ boxShadow: "none" }}>
                          <CardHeader title="Adempimenti Scaduti" />

                          <CardContent>
                            <Box
                              sx={{
                                height: 300,
                                width: 1,
                              }}
                            >
                              <DataGridPro
                                sx={{ fontSize: 12 }}
                                components={{
                                  Toolbar: CustomToolbar,
                                  Footer: CustomFooterPersonalized,
                                }}
                                localeText={
                                  itIT.components.MuiDataGrid.defaultProps
                                    .localeText
                                }
                                slots={{
                                  loadingOverlay: LinearProgress,
                                }}
                                loading={loadingScad}
                                rows={adempimentiScaduti}
                                columns={columnsAdempimenti}
                                getRowId={(row) => row.ID}
                                pageSize={100}
                                disableSelectionOnClick
                                initialState={{
                                  pinnedColumns: { left: ["*"] },
                                }}
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                      {/* Visite scadute */}
                      <Grid item xs={12} md={6}>
                        <Card sx={{ boxShadow: "none" }}>
                          <CardHeader title="Visite Scadute" />
                          <CardContent>
                            <Box
                              sx={{
                                height: 300,
                                width: 1,
                              }}
                            >
                              <DataGridPro
                                initialState={{
                                  pinnedColumns: { left: ["Actions"] },
                                }}
                                sx={{ fontSize: 12 }}
                                components={{
                                  Toolbar: CustomToolbar,
                                  Footer: CustomFooterPersonalized,
                                }}
                                slots={{
                                  loadingOverlay: LinearProgress,
                                }}
                                loading={loadingScadutiMedicina}
                                localeText={
                                  itIT.components.MuiDataGrid.defaultProps
                                    .localeText
                                }
                                rows={lavoratoriScadutiMedicina}
                                columns={columnsLavoratoriScadutioInScadenza}
                                getRowId={(row) => row.ID}
                                pageSize={100}
                                disableSelectionOnClick
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Card sx={{ boxShadow: "none" }}>
                          <CardHeader title="Nuovi Lavoratori" />

                          <CardContent>
                            <Box
                              sx={{
                                height: 300,
                                width: 1,
                              }}
                            >
                              <DataGridPro
                                sx={{ fontSize: 12 }}
                                components={{
                                  Toolbar: CustomToolbar,
                                  Footer: CustomFooterPersonalized,
                                }}
                                localeText={
                                  itIT.components.MuiDataGrid.defaultProps
                                    .localeText
                                }
                                slots={{
                                  loadingOverlay: LinearProgress,
                                }}
                                loading={loadingNuoviLav}
                                rows={nuoviLav}
                                columns={columnsNuoviLavoratori}
                                ref={handNew}
                                getRowId={(row) => row.ID}
                                pageSize={100}
                                disableSelectionOnClick
                                initialState={{
                                  pinnedColumns: { left: ["*"] },
                                }}
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                      {/* Memo e Fatturazione */}{" "}
                      <Grid item xs={12} md={12}>
                        <Card sx={{ boxShadow: "none" }}>
                          <CardHeader title="Memo" />

                          <CardContent>
                            <Box
                              sx={{
                                height: 300,
                                width: 1,
                              }}
                            >
                              <DataGridPro
                                initialState={{
                                  pinnedColumns: { left: ["Actions"] },
                                }}
                                sx={{ fontSize: 12 }}
                                components={{
                                  Toolbar: CustomToolbar,
                                  Footer: CustomFooterPersonalized,
                                }}
                                slots={{
                                  loadingOverlay: LinearProgress,
                                }}
                                loading={loadingMemo}
                                localeText={
                                  itIT.components.MuiDataGrid.defaultProps
                                    .localeText
                                }
                                rows={memoVisibili}
                                columns={columnsMemo}
                                getRowId={(row) => row.ID}
                                pageSize={100}
                                disableSelectionOnClick
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                      {/* <Grid item xs={12} md={6}>
                        <Card sx={{ boxShadow: "none" }}>
                          <CardHeader title="Fatturato Cliente" />
                          <CardContent
                            sx={{
                              padding: 2,
                            }}
                          >
                            <Box
                              sx={{
                                height: 300,
                                width: 1,
                              }}
                            >
                              <SchedaFatturato />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid> */}
                    </Grid>
                  </>
                )}
              </TabPanel>
              <TabPanel
                value={value}
                index={1}
                style={{
                  display:
                    user.Formazione && !user.Medicina && !user.Sicurezza
                      ? "none"
                      : "",
                }}
              >
                <Sedi
                  screenSize={props.screenSize}
                  idAzienda={cliente.ID}
                  infoSede={cliente}
                  ragioneSociale={cliente.RagioneSociale}
                  sedeLoc={cliente.SedeLocalita}
                  sedeInd={cliente.SedeIndirizzo}
                  sedeCap={cliente.SedeCAP}
                  logo={
                    cliente.Logo !== ""
                      ? cliente.Logo.includes("data:image/")
                        ? cliente.Logo
                        : "data:image/png;base64," + cliente.Logo
                      : require("../assets/images/MILLERLogo.jpg")
                  }
                />
              </TabPanel>
              <TabPanel
                value={value}
                index={2}
                style={{
                  marginTop: "5px",
                  backgroundColor: "#11526F",
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#ffffff",
                    borderRadius: "5px",
                    p: 2,
                  }}
                >
                  <Card xs={12} container>
                    {/* Image Container*/}
                    <Grid container>
                      <Grid item xs={12} md={6} p={2}>
                        <Box
                          sx={{
                            alignContent: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <CardActionArea
                            onClick={() => {
                              document.getElementById("file").click();
                            }}
                          >
                            <CardMedia
                              component="img"
                              image={
                                cliente.Logo !== ""
                                  ? cliente.Logo.includes("data:image/")
                                    ? cliente.Logo
                                    : "data:image/png;base64," + cliente.Logo
                                  : require("../assets/images/MILLERLogo.jpg")
                              }
                              src={
                                cliente.Logo !== ""
                                  ? cliente.Logo.includes("data:image/")
                                    ? cliente.Logo
                                    : "data:image/png;base64," + cliente.Logo
                                  : require("../assets/images/MILLERLogo.jpg")
                              }
                              id="Logo"
                              alt="img"
                              sx={{
                                height: "150px",
                                objectFit: "contain",
                                display: "flex",
                              }}
                            />
                          </CardActionArea>
                        </Box>
                        <Box sx={{ alignItems: "flex-end" }}>
                          <Button
                            fullWidth
                            className="ButtonMiller"
                            startIcon={<Upload />}
                            variant="contained"
                            onClick={() => {
                              document.getElementById("file").click();
                            }}
                          >
                            Carica Logo
                          </Button>
                        </Box>
                        <input
                          type="file"
                          id="file"
                          accept="image/*"
                          onChange={(e) => handleChangeImage(e)}
                          style={{ display: "none" }}
                        />
                      </Grid>
                      {/* Right Top Container */}

                      <Grid
                        xs={12}
                        container
                        md={6}
                        p={0}
                        sx={{ mt: { xs: 2, md: 1 }, pl: 0.5 }}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Utente Sicurezza
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={cliente.UtenteSicurezza}
                            label="Utente Sicurezza"
                            onChange={(e) => {
                              let c = { ...cliente };
                              c.UtenteSicurezza = e.target.value;
                              setCliente(c);
                            }}
                          >
                            <MenuItem value={null}>Seleziona...</MenuItem>
                            {utentiSicurezza?.map((u) => (
                              <MenuItem value={u.ID}>{u.username}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Utente Medicina
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={cliente.UtenteAssociato}
                            label="Utente Associato"
                            onChange={(e) => {
                              let c = { ...cliente };
                              c.UtenteAssociato = e.target.value;
                              setCliente(c);
                            }}
                          >
                            <MenuItem value={null}>Seleziona...</MenuItem>
                            {utenti?.map((u) => (
                              <MenuItem value={u.ID}>{u.username}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Utente Formazione
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={cliente.UtenteFormazione}
                            label="Utente Formazione"
                            onChange={(e) => {
                              let c = { ...cliente };
                              c.UtenteFormazione = e.target.value;
                              setCliente(c);
                            }}
                          >
                            <MenuItem value={null}>Seleziona...</MenuItem>
                            {utentiFormazione?.map((u) => (
                              <MenuItem value={u.ID}>{u.username}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <hr
                      style={{
                        height: "2px",
                        marginTop: "5px",
                        marginBottom: "15px",
                        width: "100%",
                      }}
                    />

                    <Grid
                      container
                      sx={{
                        p: 0,
                        display: "flex",
                        columnGap: "10px",
                        flexWrap: { xs: "wrap", md: "nowrap" },
                        mt: 1,
                      }}
                    >
                      <Grid xs={12} item md={6} sx={{ mt: { xs: 1, md: 0 } }}>
                        <TextField
                          id="standard-basic"
                          fullWidth
                          onChange={(e) => handleInputChange(e)}
                          name="RagioneSociale"
                          label="Ragione Sociale"
                          defaultValue={cliente.RagioneSociale}
                        />
                      </Grid>
                      <Grid
                        container
                        xs={12}
                        md={6}
                        sx={{
                          p: 0,
                          display: "flex",
                          columnGap: "10px",
                          flexWrap: { xs: "wrap", md: "nowrap" },
                        }}
                      >
                        <Grid xs={12} item md={6} sx={{ mt: { xs: 1, md: 0 } }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Tipologia Azienda
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={cliente.TipologiaAzienda}
                              label="Tipologia Azienda"
                              onChange={(e) => {
                                let c = { ...cliente };
                                c.TipologiaAzienda = e.target.value;
                                setCliente(c);
                              }}
                            >
                              <MenuItem value={null}>Seleziona...</MenuItem>
                              {TipologiaAzienda?.map((u) => (
                                <MenuItem value={u.value}>{u.value}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid xs={12} item md={6} sx={{ mt: { xs: 1, md: 0 } }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Codice Identificativo
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={cliente.CodiceIdent}
                              label="Codice Identificativo"
                              onChange={(e) => {
                                let c = { ...cliente };
                                c.CodiceIdent = e.target.value;
                                setCliente(c);
                              }}
                            >
                              <MenuItem value={null}>Seleziona...</MenuItem>
                              {CodiceIdent?.map((u) => (
                                <MenuItem value={u.value}>{u.value}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* Prima Riga */}

                    <Grid
                      container
                      sx={{
                        p: 0,
                        display: "flex",
                        columnGap: "10px",
                        flexWrap: { xs: "wrap", md: "nowrap" },
                        mt: 1,
                      }}
                    >
                      <Grid
                        xs={12}
                        container
                        md={6}
                        sx={{
                          p: 0,
                          display: "flex",
                          columnGap: "10px",
                          flexWrap: { xs: "wrap", md: "nowrap" },
                        }}
                      >
                        <Grid xs={12} item md={6}>
                          <TextField
                            id="standard-basic"
                            fullWidth
                            onChange={(e) => handleInputChange(e)}
                            name="Email"
                            label="Email"
                            defaultValue={cliente.Email}
                          />
                        </Grid>
                        <Grid xs={12} item md={6}>
                          <TextField
                            id="standard-basic"
                            fullWidth
                            //onChange={(e) => handleInputChange(e)}
                            disabled={true}
                            name="NomeReferente"
                            label="Referente Paghe"
                            defaultValue={cliente.NomeReferente}
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid xs={12} item md={6} sx={{ mt: { xs: 1, md: 0 } }}>
                        <TextField
                          id="standard-basic"
                          fullWidth
                          onChange={(e) => handleInputChange(e)}
                          name="CF"
                          label="Codice Fiscale"
                          defaultValue={cliente.CF}
                        />
                      </Grid>
                    </Grid>

                    {/* Seconda Riga */}
                    <Grid
                      container
                      sx={{
                        p: 0,
                        display: "flex",
                        columnGap: "10px",
                        flexWrap: { xs: "wrap", md: "nowrap" },
                        mt: 1,
                      }}
                    >
                      <Grid xs={12} item md={6}>
                        <TextField
                          id="standard-basic"
                          fullWidth
                          onChange={(e) => handleInputChange(e)}
                          name="PIVA"
                          label="Partita IVA"
                          defaultValue={cliente.PIVA}
                        />
                      </Grid>
                      <Grid xs={12} item md={6} sx={{ mt: { xs: 1, md: 0 } }}>
                        <TextField
                          id="standard-basic"
                          fullWidth
                          onChange={(e) => handleInputChange(e)}
                          name="Telefono"
                          label="Telefono"
                          defaultValue={cliente.Telefono}
                        />
                      </Grid>
                    </Grid>
                    {/* Terza Riga */}
                    <Grid
                      container
                      sx={{
                        p: 0,
                        display: "flex",
                        columnGap: "10px",
                        flexWrap: { xs: "wrap", md: "nowrap" },
                        mt: 1,
                      }}
                    >
                      <Grid xs={12} item md={6}>
                        <TextField
                          id="standard-basic"
                          fullWidth
                          onChange={(e) => handleInputChange(e)}
                          name="CodiceATECO"
                          label="Codice Ateco"
                          defaultValue={cliente.CodiceATECO}
                        />
                      </Grid>
                      <Grid xs={12} item md={6} sx={{ mt: { xs: 1, md: 0 } }}>
                        <TextField
                          id="standard-basic"
                          fullWidth
                          onChange={(e) => handleInputChange(e)}
                          name="CDC"
                          label="Centro di costo"
                          defaultValue={cliente.CDC}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                  <hr
                    style={{
                      height: "2px",
                      marginTop: "10px",
                      marginBottom: "5px",
                      width: "100%",
                    }}
                  />
                  {/* Body Container */}
                  <Card
                    xs={12}
                    md={12}
                    container
                    sx={{
                      p: 0,
                      display: "flex",
                      flexWrap: { xs: "wrap", md: "nowrap" },
                      boxShadow: "none",
                      columnGap: { xs: 0, md: 1.5 },
                    }}
                  >
                    {/* Sede Container */}
                    <Grid
                      xs={12}
                      container
                      md={6}
                      rowGap={{ xs: 0, md: 2 }}
                      sx={{
                        height: "100%",
                        alignItems: "center",
                        display: "flex",
                        flexWrap: "wrap",
                        //mr: { xs: 0, md: 1 },
                        mt: 2,
                      }}
                    >
                      <Grid xs={12} item md={12}>
                        <TextField
                          id="standard-basic"
                          fullWidth
                          onChange={(e) => handleInputChange(e)}
                          name="SedeIndirizzo"
                          label="Sede Indirizzo"
                          defaultValue={cliente.SedeIndirizzo}
                        />
                      </Grid>
                      <Grid xs={12} item md={12} sx={{ mt: { xs: 1, md: 0 } }}>
                        <TextField
                          id="standard-basic"
                          fullWidth
                          onChange={(e) => handleInputChange(e)}
                          name="SedeLocalita"
                          label="Sede Località"
                          defaultValue={cliente.SedeLocalita}
                        />
                      </Grid>
                      <Grid xs={12} item md={12} sx={{ mt: { xs: 1, md: 0 } }}>
                        <TextField
                          id="standard-basic"
                          fullWidth
                          onChange={(e) => handleInputChange(e)}
                          name="SedeCAP"
                          label="Sede CAP"
                          defaultValue={cliente.SedeCAP}
                        />
                      </Grid>
                    </Grid>

                    {/* Posta Container */}
                    <Grid
                      xs={12}
                      container
                      md={6}
                      rowGap={{ xs: 0, md: 2 }}
                      sx={{
                        height: "100%",
                        alignItems: "center",
                        display: "flex",
                        flexWrap: "wrap",
                        //mx: { xs: 0, md: 1 },
                        mt: 2,
                      }}
                    >
                      <Grid xs={12} item md={12}>
                        <TextField
                          id="standard-basic"
                          fullWidth
                          onChange={(e) => handleInputChange(e)}
                          name="PostIndirizzo"
                          label="Posta Indirizzo"
                          defaultValue={cliente.PostIndirizzo}
                        />
                      </Grid>
                      <Grid xs={12} item md={12} sx={{ mt: { xs: 1, md: 0 } }}>
                        <TextField
                          id="standard-basic"
                          fullWidth
                          onChange={(e) => handleInputChange(e)}
                          name="PostLocalita"
                          label="Posta Località"
                          defaultValue={cliente.PostLocalita}
                        />
                      </Grid>
                      <Grid xs={12} item md={12} sx={{ mt: { xs: 1, md: 0 } }}>
                        <TextField
                          id="standard-basic"
                          fullWidth
                          onChange={(e) => handleInputChange(e)}
                          name="PostCAP"
                          label="Posta CAP"
                          defaultValue={cliente.PostCAP}
                        />
                      </Grid>
                    </Grid>
                  </Card>

                  <hr
                    style={{
                      height: "2px",
                      marginTop: "10px",
                      marginBottom: "5px",
                      width: "100%",
                    }}
                  />

                  {/* Checkbox Container */}
                  <Card>
                    <Grid
                      xs={12}
                      container
                      rowGap={{ xs: 0, md: 2 }}
                      sx={{
                        boxShadow: "none",
                        height: "100%",
                        alignItems: "center",
                        display: "flex",
                        flexWrap: "wrap",
                        ml: { xs: 0, md: 1 },
                        mt: 2,
                      }}
                    >
                      <Grid xs={12} item md={6}>
                        <FormControlLabel
                          sx={{ width: "100%" }}
                          control={
                            <Checkbox
                              name="GestioneMedicina"
                              checked={
                                cliente.GestioneMedicina === 1 ? true : false
                              }
                              onChange={(e) => handleCboxChange(e)}
                            />
                          }
                          label="Gestione Medicina"
                        />
                        <FormControlLabel
                          sx={{ width: "100%" }}
                          control={
                            <Checkbox
                              name="GestioneCorsi"
                              checked={
                                cliente.GestioneCorsi === 1 ? true : false
                              }
                              onChange={(e) => handleCboxChange(e)}
                            />
                          }
                          label="Corsi Formazione"
                        />
                        <FormControlLabel
                          sx={{ width: "100%" }}
                          control={
                            <Checkbox
                              name="GestioneSopralluoghi"
                              checked={
                                cliente.GestioneSopralluoghi === 1
                                  ? true
                                  : false
                              }
                              onChange={(e) => handleCboxChange(e)}
                            />
                          }
                          label="Gestione Sopralluoghi"
                        />
                        <FormControlLabel
                          sx={{ width: "100%" }}
                          control={
                            <Checkbox
                              name="InvioSollMedico"
                              checked={
                                cliente.InvioSollMedico === 1 ? true : false
                              }
                              onChange={(e) => handleCboxChange(e)}
                            />
                          }
                          label="Considera Sollecito Idoneità Medici"
                        />
                      </Grid>
                      <Grid xs={12} item md={6}>
                        <ModalNotarization
                          Notarizzato={cliente.Notarizzazione}
                          cliente={cliente}
                          setCliente={setCliente}
                          setOldCliente={setOldCliente}
                        />
                        <FormControlLabel
                          sx={{ width: "100%" }}
                          control={
                            <Checkbox
                              name="FatturaVisiteMediche"
                              checked={
                                cliente.FatturaVisiteMediche === 1
                                  ? true
                                  : false
                              }
                              onChange={(e) => handleCboxChange(e)}
                            />
                          }
                          label="Fattura Visite Mediche"
                        />
                        <FormControlLabel
                          sx={{ width: "100%" }}
                          control={
                            <Checkbox
                              name="Cessato"
                              checked={cliente.Cessato === 1 ? true : false}
                              onChange={(e) => handleCboxChange(e)}
                            />
                          }
                          label="Cessato"
                        />
                        <FormControlLabel
                          sx={{ width: "100%" }}
                          control={
                            <Checkbox
                              name="Sicurezza"
                              checked={cliente.Sicurezza === 1 ? true : false}
                              onChange={(e) => handleCboxChange(e)}
                            />
                          }
                          label="Sicurezza"
                        />
                      </Grid>
                    </Grid>
                  </Card>
                  <Grid
                    xs={12}
                    item
                    sx={{
                      display: "flex",
                      mt: 2,
                      justifyContent: "space-between",
                    }}
                    fullWidth
                  >
                    <Button
                      variant="contained"
                      className="ButtonMiller"
                      startIcon={<ArrowBack />}
                      onClick={() => navigate(-1)}
                    >
                      INDIETRO
                    </Button>
                    <Button
                      variant="contained"
                      className="ButtonMiller"
                      startIcon={<Check />}
                      onClick={() => saveData()}
                    >
                      Salva
                    </Button>
                  </Grid>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Lavoratori
                  screenSize={props.screenSize}
                  Lavoratori={lavoratori}
                  idAzienda={cliente.ID}
                  cliente={cliente}
                  loadLavoratori={reloadLavoratori}
                  loading={loadingLav}
                  ragioneSociale={location.state.RagioneSociale}
                />
              </TabPanel>
              <TabPanel
                value={value}
                index={4}
                style={{
                  display:
                    user.Formazione && !user.Medicina && !user.Sicurezza
                      ? "none"
                      : "",
                }}
              >
                <Allegati
                  screenSize={props.screenSize}
                  idAzienda={cliente.ID}
                  id={cliente.ID}
                  colonna="Cliente"
                />
              </TabPanel>
              <TabPanel
                value={value}
                index={5}
                style={{
                  display:
                    user.Formazione && !user.Medicina && !user.Sicurezza
                      ? "none"
                      : "",
                }}
              >
                <MediciCliente
                  screenSize={props.screenSize}
                  // idAzienda={cliente.ID}
                  idAzienda={location.state.idC}
                />
              </TabPanel>
              <TabPanel
                value={value}
                index={6}
                style={{
                  display:
                    user.Formazione && !user.Medicina && !user.Sicurezza
                      ? "none"
                      : "",
                }}
              >
                <Mansioni
                  idAzienda={cliente.ID}
                  ragioneSociale={location.state.RagioneSociale}
                />
              </TabPanel>
              <TabPanel
                value={value}
                index={7}
                style={{
                  display:
                    user.Formazione && !user.Medicina && !user.Sicurezza
                      ? "none"
                      : "",
                }}
              >
                <FatturazioneMedicina
                  idAzienda={cliente.ID}
                  cliente={cliente}
                />
              </TabPanel>
              <TabPanel
                value={value}
                index={8}
                style={{
                  display: user.Medicina && !user.Formazione ? "none" : "",
                }}
              >
                <CorsiCliente
                  idAzienda={cliente.ID}
                  RagioneSociale={cliente.RagioneSociale}
                />
              </TabPanel>
              <TabPanel
                value={value}
                index={9}
                style={{
                  display:
                    user.Formazione && !user.Medicina && !user.Sicurezza
                      ? "none"
                      : "",
                }}
              >
                <Sopralluoghi idAzienda={cliente.ID} />
              </TabPanel>

              {cliente?.Gruppo === "Gavio" && (
                <TabPanel value={value} index={10}>
                  <ExtraMedici idAzienda={cliente.ID} />
                </TabPanel>
              )}
              <TabPanel
                value={value}
                index={cliente?.Gruppo === "Gavio" ? 11 : 10}
              >
                <Memo idAzienda={cliente.ID} />
              </TabPanel>

              <TabPanel
                value={value}
                index={cliente?.Gruppo === "Gavio" ? 12 : 11}
              >
                <Chatbot />
              </TabPanel>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default ClientDetail;

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        thickness={7}
        {...props}
        sx={{
          color:
            props.value <= 50
              ? "#FF2768"
              : props.value <= 75
              ? "#2265B1"
              : "#40C18F",
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography component="div" color="text.secondary" variant="h5">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const ModalNotarization = (props) => {
  const [open, setOpen] = React.useState(false);
  const [cliente, setCliente] = useState(null);
  const handleClickOpen = () => {
    setOpen(true);
    setCliente(props.cliente);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    let copiaCliente = { ...cliente };
    copiaCliente[e.target.name] = e.target.value;

    setCliente(copiaCliente);
  };

  const handleSave = async (e) => {
    try {
      await unsplash
        .post(
          "updateClienti",
          { auth: "a5d42c3ed7977afa2d8e922569e7940b", cliente: cliente },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        )
        .then((res) => {});

      await unsplash
        .post(
          "storico",
          {
            tabella: "Clienti",
            key: cliente.ID,
            utente: sessionStorage.getItem("username"),
            after: cliente,
            before: props.cliente,
            operazione: "Modifica",
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        )
        .then((res) => {});
      /*  setSnack({
        severity: "success",
        messaggio: "Cliente salvato con successo",
      });
      setOpenSnack(true); */
      props.setOldCliente(cliente);
      props.setCliente(cliente);
    } catch {
      /* setSnack({
        severity: "error",
        messaggio: "Errore durante il salvataggio",
      });
      setOpenSnack(true); */
    }
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        className="ButtonMiller"
        startIcon={
          <Circle
            style={{ color: props.Notarizzato === 0 ? "#FF2768" : " #40C18F" }}
          />
        }
        onClick={() => handleClickOpen()}
      >
        {props.Notarizzato === 0
          ? "Notarizzazione non attiva"
          : "Notarizzazione attiva"}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          Gestione Notarizzazione
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ paddingTop: "10px" }}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Notarizzazione
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={cliente?.Notarizzazione}
                  label="Notarizzazione"
                  onChange={(e) => {
                    let c = { ...cliente };
                    c.Notarizzazione = e.target.value;
                    setCliente(c);
                  }}
                >
                  <MenuItem value={0}>No</MenuItem>

                  <MenuItem value={1}>Parziale</MenuItem>
                  <MenuItem value={2}>Completa</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="standard-basic"
                fullWidth
                onChange={(e) => handleInputChange(e)}
                name="NotarizzazioniMensili"
                label="Notarizzazioni Mensili"
                defaultValue={cliente?.NotarizzazioniMensili}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  ampmInClock={false}
                  renderInput={(props) => (
                    <TextField fullWidth required {...props} />
                  )}
                  label="Data Inizio Notarizzazione"
                  value={moment(cliente?.DataInizioNotarizzazione)}
                  onChange={(newValue) => {
                    let l = { ...cliente };
                    l.DataInizioNotarizzazione = newValue
                      ? newValue.format("YYYY-MM-DD")
                      : null;
                    setCliente(l);
                    //setDal(newValue?newValue.format("YYYY-MM-DD"):null);
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={handleSave}>Conferma</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};