import React, { useEffect, useState } from "react";
import unsplash from "../../api/unsplash";
import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import {
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Popover,
  DialogActions,
  Box,
  Typography,
  Step,
  StepLabel,
  Stepper,
  CircularProgress,
  ButtonBase,
  ButtonGroup,
  Zoom,
  DialogContentText,
  LinearProgress,
} from "@mui/material";
import VisiteMediche from "../VIsiteMediche";
import { Check, Close, CheckBox, Delete, Edit } from "@mui/icons-material";
import { LocalizationProvider,DatePicker,PickersDay,TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment as DateAdapter} from '@mui/x-date-pickers/AdapterMoment'

import momentTimezone from "moment-timezone";
import moment from "moment-timezone";
import GlobalSnackbar from "../Snackbar";
import { useLocation } from "react-router-dom";
import FileUploader from "../FileUploader";
import { CustomFooterPersonalized } from "../../utils/CustomFooterPersonalized";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const VisiteMedicheLavoratore = (props) => {
 
  
  const [visite, setVisite] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mansioni, setMansioni] = useState();
  const [medici, setMedici] = useState();
  const [esiti, setEsiti] = useState();
  const [tipologiaVisita, setTipologiaVisita] = useState();
  const [visitaDaEliminare, setVisitaDaEliminare] = useState(null);

  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      headerAlign: "center",
      hide: false,
      width: "150",
      align: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <ButtonGroup>
          <DettaglioVisitaMedica
            operation="new"
            title={"nuova visita medica"}
            visita={params.row}
            esiti={esiti}
            tipologiaVisita={tipologiaVisita}
            mansioni={mansioni}
            medici={medici}
            reloadVisite={reloadVisite}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
            lavoratore={props.datiLavoratore}
          />
          <DeleteDialog visita={params.row} myFunction={deleteVisita} />

          {/* <Button
            aria-label="edit"
            startIcon={<Delete />}
            onClick={deleteVisita}
            className="ButtonMillerError"
            variant="contained"
          ></Button> */}

          {/* <IconButton
            aria-label="edit"
            onClick={() => document.getElementById("delete").click()}
            id="delete"
          >
            <Delete
              style={{ height: "2rem", width: "2rem", color: "#FF2768" }}
            />
          </IconButton> */}
        </ButtonGroup>
      ),
    },
    {
      field: "Data",
      headerName: "Data",
      width: 170,
      renderCell: (params) =>
        moment(params.row.Data).format("DD/MM/YYYY HH:mm:00"),
    },
    { field: "MedicoNomeCompleto", headerName: "Medico", width: 200 },
    { field: "DescMansione", headerName: "Mansione", width: 300 },
    { field: "Accertamenti", headerName: "Accertamenti", width: 300 },
    { field: "Note", headerName: "Note", width: 300 },
    { field: "DescEsito", headerName: "Esito", width: 200 },
    { field: "DescTipoVisita", headerName: "Tipo", width: 150 },
    {
      filterable: false,
      field: "PresenzaUnitaMobile",
      headerName: "Presenza Unita Mobile",
      width: 200,
      renderCell: (params) => {
        if (params.row.PresenzaUnitaMobile === 1) {
          return (
            <div>
              <input type="checkbox" checked onClick={false}></input>
            </div>
          );
        } else {
          return (
            <div>
              <input type="checkbox" disabled onClick={false}></input>
            </div>
          );
        }
      },
    },
  ];

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      let responsVisite = await unsplash.get("visiteMediche", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { lavoratore: props.lavoratore },
      });
      setVisite(responsVisite.data.data);
      setLoading(false);

      let responseEsiti = await unsplash.get("esiti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setEsiti(responseEsiti.data.data);

      let responseTipologiaVisita = await unsplash.get("tipologiaVisita", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setTipologiaVisita(responseTipologiaVisita.data.data);

      let responseMansioni = await unsplash.get("mansioni", {
        params: { id: props.cliente },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      setMansioni(responseMansioni.data.data);

      let responseMediciCliente = await unsplash.get(
        "medici",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { id: props.cliente },
        } //props.idAzienda}}
      );
      setMedici(responseMediciCliente.data.data);
    };

    fetchData();
  }, []);

  const reloadVisite = async () => {
    let responsVisite = await unsplash.get("visiteMediche", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { lavoratore: props.lavoratore },
    });
    setVisite(responsVisite.data.data);
  };

  const deleteVisita = async () => {
    if (!visitaDaEliminare) {
      setSnack({
        severity: "error",
        messaggio: "Seleziona una visita",
      });
      setOpenSnack(true);
    } else {
      try {
        await unsplash.delete("visiteMediche", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          data: {
            visita: visitaDaEliminare[0].ID,
            lavoratore: props.lavoratore,
          },
        });

        reloadVisite();
        setSnack({
          severity: "success",
          messaggio: "Visita Medica eliminata con successo",
        });
        setOpenSnack(true);
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'eliminazione",
        });
        setOpenSnack(true);
      }
    }
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid xs={12} spacing={2}>
        <div style={{ height: "58vh", width: "100%" }}>
          <DataGridPro
            initialState={{ pinnedColumns: { left: ["Actions"] } }}
            components={{
              Toolbar: CustomToolbar,
              Footer: CustomFooterPersonalized,
            }}
            slots={{
              loadingOverlay: LinearProgress,
            }}
            loading={loading}
            disableColumnMenu={true}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            rows={visite}
            getRowId={(visite) => visite.ID}
            columns={columns}
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRowData = visite.filter((row) =>
                selectedIDs.has(row.ID)
              );

              setVisitaDaEliminare(selectedRowData);
            }}
          />
        </div>
      </Grid>
    </>
  );
};

export default VisiteMedicheLavoratore;

const DettaglioVisitaMedica = (props) => {
  const [open, setOpen] = useState(false);
  const [accertamenti, setAccertamenti] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectionModel, setSelectionModel] = useState([]);
  const [required, setRequired] = useState({
    medico: false,
    data: false,
    tipo: false,
  });
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const [visita, setVisita] = useState(null);
  const [orario, setOrario] = useState(null);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const columns = [
    { field: "Descrizione", headerName: "Accertamento", width: 300 },
    {
      field: "Scadenza",
      headerName: "Scadenza",
      type: "date",
      width: 150,
      editable: true,
      renderCell: (params) =>
        params.row.Scadenza
          ? moment(params.row.Scadenza).format("DD/MM/YYYY")
          : "",
    },
    {
      field: "Importo",
      headerName: "Importo €",
      type: "number",
      width: 120,
      editable: true,
      renderCell: (params) => (params.row.Importo ? params.row.Importo : 0),
    },
    {
      field: "ImportoPassivo",
      headerName: "ImportoPassivo €",
      type: "number",
      width: 150,
      editable: true,
      renderCell: (params) =>
        params.row.ImportoPassivo ? params.row.ImportoPassivo : 0,
    },
  ];

  const handleOrarioChange = (event) => {
    let d = { ...visita };
    setOrario(event); //.format("HH:mm")
    //utilizzo la forma abbreviata della condizione if (Guard clauses)
    if (event) {
      d.Data =
        moment(visita.Data).format("YYYY-MM-DD") +
        " " +
        event.format("HH:mm:ss");
    }
  };

  const loadAccertamenti = async (val, data, cliente) => {
    let responseAccertamenti = await unsplash.get("accertamenti", {
      params: { id: val, data: data, clienteAcc: cliente },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    setAccertamenti(responseAccertamenti.data.data);
    setLoading(false);
    setSelectionModel(
      responseAccertamenti.data.data.filter((acc) =>
        acc.VisitaMedica ? acc.ID : null
      )
    );
  };

  const handleClickOpen = async () => {
    setVisita(props.visita);

    setAccertamenti([]);

    loadAccertamenti(
      props.visita.ID,
      moment(props.visita.Data).format("YYYY-MM-DD"),
      props.visita.Cliente
    );

    await setRequired({ medico: false, data: false, tipo: false });
    setOrario(props.visita.Data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    /*let req ={...required};
      let checkOk=true; //lo uso per controllare che tutto sia ok
      if(medicoSel ==="" || medicoSel ===null){
        req.medico =true;
        checkOk=false;
      }else{req.medico =false}  
      if(tipologiaSel ==="" || tipologiaSel ===null){
        req.tipo =true;
        checkOk=false;
      }else{req.tipo =false} 
  
      if(dataSel ==="" || dataSel ===null){
        req.data =true;
        checkOk=false;
      }else{req.data =false} 
  
      setRequired(req)
      if(checkOk){
        visita.IDMedico,visita.Data,visita.IDMansione,visita.TipoVisita,visita.Esito,visita.Note,visita.ID
   */
    let vis = { ...visita };
    //vis.Data = moment(vis.Data).format("YYYY-MM-DD HH:mm:ss");
    vis.Data = moment(vis.Data).format("YYYY-MM-DD")
    ? moment(vis.Data).format("YYYY-MM-DD") +
      " " +
      moment(orario).format("HH:mm:ss")
    : null;
    try {
      await unsplash.put(
        "visiteMediche",
        { visita: vis, accertamenti: selectionModel },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      props.setSnack({
        severity: "success",
        messaggio: "Visita Medica modificata con successo",
      });
      props.setOpenSnack(true);

      handleClose();
      props.reloadVisite();
    } catch {
      props.setSnack({
        severity: "error",
        messaggio: "Errore durante il salvataggio della visita medica",
      });
      props.setOpenSnack(true);

      handleClose();
    }
  };

  const handleReorderCheck = async () => {
    let IDSelected = selectionModel.map((s) => s.ID);

    let top = [];
    let bottom = [];
    for (let i = 0; i < accertamenti.length; i++) {
      if (IDSelected.indexOf(accertamenti[i].ID) >= 0) {
        top.push(accertamenti[i]);
      } else {
        bottom.push(accertamenti[i]);
      }
    }

    setAccertamenti(top.concat(bottom));
    setLoading(false);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
          <Button
            size="small"
            onClick={() => handleReorderCheck()}
            startIcon={<CheckBox />}
            style={{ border: 0 }}
          >
            Riordina
          </Button>
        </Grid>
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <IconButton
        aria-label="edit"
        onClick={() => {
          handleClickOpen();
          /* setVisitaSel(params.row);
              document.getElementById("myDialog").click(); */
        }}
        id="delete"
      >
        <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
      </IconButton>
      {/* <Button
        variant="outlined"
        style={{ display: "None" }}
        onClick={handleClickOpen}
        id="myDialog"
      >
        Nuovo
      </Button> */}
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <DialogTitle>{"Modifica Visita "}</DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Grid container spacing={2}>
                <Grid container xs={12} justifyContent={"flex-end"}>
                  <FileUploader
                    visiteMedicheLavoratore={true}
                    colonna="VisitaMedica"
                    id={props.visita.ID}
                    colonnaExtra="Lavoratore"
                    extra={props.visita.Lavoratore}
                    description={
                      moment(props.visita.Data).format("DD-MM-YYYY") +
                      " - " +
                      props.lavoratore.Nome +
                      " " +
                      props.lavoratore.Cognome +
                      " - " +
                      props.lavoratore.CF
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  {required.tipo || required.data || required.medico ? (
                    <p style={{ color: "#FF2768" }}>
                      controlla i campi obbligatori!
                    </p>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Medico
                    </InputLabel>
                    <Select
                      error={required.medico}
                      helperText={required.medico ? "Medico obbligatorio" : ""}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={visita ? visita.IDMedico : null}
                      label="Medico"
                      onChange={(e) => {
                        let medico = props.medici.filter(
                          (medico) => medico.ID === e.target.value
                        )[0];
                        let text = medico.Nome + " " + medico.Cognome;
                        let v = { ...visita };
                        v.IDMedico = e.target.value;
                        v.Medico = text;
                        setVisita(v);
                      }}
                    >
                      {props.medici?.map((medico) =>
                        medico.ID !== -1 ? (
                          <MenuItem key={medico.ID} value={medico.ID}>
                            {medico.Nome + " " + medico.Cognome}
                          </MenuItem>
                        ) : null
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                      ampmInClock={false}
                      renderInput={(props) => (
                        <TextField {...props} error={required.data} />
                      )}
                      label="Data"
                      value={visita ? moment(visita.Data) : null}
                      onChange={(newValue) => {
                        let v = { ...visita };
                        if(newValue){
                          v.Data = newValue.format("YYYY-MM-DD HH:mm:SS");
                        }
                        setVisita(v);
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <TimePicker
                      ampmInClock={false}
                      renderInput={(props) => (
                        <TextField fullwidth {...props} error={required.ora} />
                      )}
                      label="Ora"
                      value={moment(orario)}
                      onChange={handleOrarioChange}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Mansione
                    </InputLabel>
                    <Select
                      error={required.tipo}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={visita ? visita.IDMansione : null}
                      label="Mansione"
                      helperText={required.data ? "Mansione obbligatoria" : ""}
                      onChange={(e) => {
                        let text = props.mansioni.filter(
                          (mansione) => mansione.ID === e.target.value
                        )[0].Descrizione;
                        let v = { ...visita };
                        v.IDMansione = e.target.value;
                        v.Mansione = text;
                        setVisita(v);
                      }}
                    >
                      {props.mansioni?.map((mansione) => (
                        <MenuItem key={mansione.ID} value={mansione.ID}>
                          {mansione.Descrizione}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Tipologia Visita
                    </InputLabel>
                    <Select
                      error={required.tipo}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={visita ? visita.TipoVisita : null}
                      label="Tipologia Visita"
                      helperText={required.data ? "Tipologia obbligatoria" : ""}
                      onChange={(e) => {
                        let text = props.tipologiaVisita.filter(
                          (tipologia) => tipologia.ID === e.target.value
                        )[0].Descrizione;
                        let v = { ...visita };
                        v.TipoVisita = e.target.value;
                        v.TipoVDesc = text;
                        setVisita(v);
                      }}
                    >
                      {props.tipologiaVisita?.map((tipologia) => (
                        <MenuItem key={tipologia.ID} value={tipologia.ID}>
                          {tipologia.Descrizione}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Luogo"
                    multiline
                    value={visita ? visita.Luogo : null}
                    onChange={(e) => {
                      let v = { ...visita };
                      v.Luogo = e.target.value;
                      setVisita(v);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div style={{ height: 400, width: "100%" }}>
                    <DataGridPro
                      initialState={{ pinnedColumns: { left: ["Actions"] } }}
                      components={{
                        Toolbar: CustomToolbar,
                        Footer: CustomFooterPersonalized,
                      }}
                      slots={{
                        loadingOverlay: LinearProgress,
                      }}
                      loading={loading}
                      disableSelectionOnClick
                      checkboxSelection
                      disableColumnMenu={true}
                      localeText={
                        itIT.components.MuiDataGrid.defaultProps.localeText
                      }
                      rows={accertamenti}
                      getRowId={(accertamenti) => accertamenti.ID}
                      columns={columns}
                      selectionModel={selectionModel?.map((sel) => sel.ID)}
                      onSelectionModelChange={(ids) => {
                        const selectedIDs = new Set(ids);
                        const selectedRowData = accertamenti.filter((row) =>
                          selectedIDs.has(row.ID)
                        );
                        if (selectedRowData) {
                          let allRow = [...selectedRowData];
                          if (
                            allRow[selectedRowData.length - 1] &&
                            !allRow[selectedRowData.length - 1].Scadenza
                          ) {
                            if (visita) {
                              allRow[selectedRowData.length - 1].Scadenza =
                                moment(visita.Data).format("YYYY-MM-DD");
                            } else {
                              allRow[selectedRowData.length - 1].Scadenza =
                                moment(new Date()).format("YYYY-MM-DD");
                            }
                          }
                          setSelectionModel(allRow);
                        }
                      }}
                      onCellEditCommit={(params, event) => {
                        let row = { ...params.row };
                        if (params.field === "Scadenza") {
                          row[params.field] = moment(params.value).format(
                            "YYYY-MM-DD"
                          );
                        } else {
                          row[params.field] = params.value;
                        }

                        let selAcc = [...selectionModel];
                        let findIndex = selAcc.findIndex(
                          (acc) => acc.ID == params.row.ID
                        ); //trovo la posizione
                        if (findIndex !== -1) {
                          selAcc.splice(findIndex, 1);
                        }
                        selAcc.push(row);
                        setSelectionModel(selAcc);
                      }}
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Esito</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={visita ? visita.Esito : null}
                      label="Esito"
                      onChange={(e) => {
                        let text = props.esiti.filter(
                          (esito) => esito.ID === e.target.value
                        )[0].Descrizione;
                        let v = { ...visita };
                        v.Esito = e.target.value;
                        v.EsitoDesc = text;
                        setVisita(v);
                      }}

                      //onChange={handleChange}
                    >
                      {props.esiti?.map((esito) => (
                        <MenuItem key={esito.ID} value={esito.ID}>
                          {esito.Descrizione}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Note"
                      multiline
                      value={visita ? visita.Note : null}
                      onChange={(e) => {
                        let v = { ...visita };
                        v.Note = e.target.value;
                        setVisita(v);
                      }}
                      sx={{ marginTop: "20px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            pr: 4,
            pl: 2,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
          >
            Esci
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={handleSave}
            color="success"
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const DeleteDialog = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare la visita selezionata?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare la seguente visita:
            <br />
            <b>{props.visita.Descrizione}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={() => props.myFunction(props.visita)}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
