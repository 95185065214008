import React, { useState, useEffect } from "react";
import {
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Box,
  Stepper,
  Step,
  StepButton,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  TextField,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Zoom,
  LinearProgress,
} from "@mui/material";
import { LocalizationProvider,DatePicker,PickersDay,TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment as DateAdapter} from '@mui/x-date-pickers/AdapterMoment'
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import {
  Add,
  Check,
  Upload,
  ArrowBack,
  ArrowForward,
  Close,
} from "@mui/icons-material";

import unsplash from "../api/unsplash";
import Sedi from "./Sedi";
import Mansioni from "./Mansioni";
import MediciCliente from "./MediciCliente";
import moment from "moment";
import FatturazioneMedicina from "./FatturazioneMedicina";
import GlobalSnackbar from "./Snackbar";
import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const steps = [
  "Dati cliente",
  "Medici",
  "Sedi",
  "Mansioni",
  "Lavoratori",
  "Fatturazione",
];
const stepsNoMedicina = [
  "Dati cliente",
  "Sedi",
  "Mansioni",
  "Lavoratori",
  "Fatturazione",
];

const ProceduraGuidata = (props) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [idAzienda, setIDAzienda] = useState(0);
  const [continueStep, setContinueStep] = useState(false);
  const [gestioneMedicina, setGestioneMedicina] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [clienti, setClienti] = useState(props.clienti);
  const [utenti, setUtenti] = useState([]);
  const [utentiSicurezza, setUtentiSicurezza] = useState([]);
  const [utentiFormazione, setUtentiFormazione] = useState([]);
  const [hashBlock, setHashBlock] = useState("");
  const navigate = useNavigate();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const totalSteps = () => {
    return gestioneMedicina ? steps.length : stepsNoMedicina.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          gestioneMedicina
          ? steps.findIndex((step, i) => !(i in completed))
          : stepsNoMedicina.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    /* if (!continueStep) {
      switch (activeStep) {
        case 0:
          setSnack({
            severity: "error",
            messaggio:
              "Inserisci i dati necessari alla creazione e premi su salva per poter continuare.",
          });
          setOpenSnack(true);
          break;
      }
    } else { */
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
    /* } */
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const renderElement = (index) => {
    if (gestioneMedicina) {
      switch (index) {
        case 0:
          return (
            <DatiCliente
              gestioneMedicina={setGestioneMedicina}
              handleComplete={handleComplete}
              handleBack={handleBack}
              setHashBlock={setHashBlock}
              clienti={clienti}
              setIDAzienda={setIDAzienda}
              functionContinue={setContinueStep}
              snack={snack}
              openSnack={openSnack}
              handleCloseSnack={handleCloseSnack}
              setSnack={setSnack}
              setOpenSnack={setOpenSnack}
              utenti={utenti}
              utentiSicurezza={utentiSicurezza}
              utentiFormazione={utentiFormazione}
            />
          );
        case 1:
          return (
            <MediciCliente
              proceduraGuidata={true}
              idAzienda={idAzienda}
              handleComplete={handleComplete}
              handleBack={handleBack}
              snack={snack}
              openSnack={openSnack}
              handleCloseSnack={handleCloseSnack}
              setSnack={setSnack}
              setOpenSnack={setOpenSnack}
            />
          );
        case 2:
          return (
            <Sedi
              proceduraGuidata={true}
              idAzienda={idAzienda}
              handleComplete={handleComplete}
              handleBack={handleBack}
              snack={snack}
              openSnack={openSnack}
              handleCloseSnack={handleCloseSnack}
              setSnack={setSnack}
              setOpenSnack={setOpenSnack}
            />
          );
        case 3:
          return (
            <Mansioni
              proceduraGuidata={true}
              idAzienda={idAzienda}
              handleComplete={handleComplete}
              handleBack={handleBack}
              snack={snack}
              openSnack={openSnack}
              handleCloseSnack={handleCloseSnack}
              setSnack={setSnack}
              setOpenSnack={setOpenSnack}
            />
          );
          break;
        case 4:
          return (
            <Lavoratori
              proceduraGuidata={true}
              idAzienda={idAzienda}
              handleComplete={handleComplete}
              handleBack={handleBack}
              snack={snack}
              openSnack={openSnack}
              handleCloseSnack={handleCloseSnack}
              setSnack={setSnack}
              setOpenSnack={setOpenSnack}
            />
          );
        case 5:
          return (
            <FatturazioneMedicina
              proceduraGuidata={true}
              idAzienda={idAzienda}
              handleComplete={handleComplete}
              handleBack={handleBack}
              snack={snack}
              openSnack={openSnack}
              handleCloseSnack={handleCloseSnack}
              setSnack={setSnack}
              setOpenSnack={setOpenSnack}
            />
          );
      }
    } else {
      switch (index) {
        case 0:
          return (
            <DatiCliente
              gestioneMedicina={setGestioneMedicina}
              handleComplete={handleComplete}
              handleBack={handleBack}
              setHashBlock={setHashBlock}
              clienti={clienti}
              setIDAzienda={setIDAzienda}
              functionContinue={setContinueStep}
              snack={snack}
              openSnack={openSnack}
              handleCloseSnack={handleCloseSnack}
              setSnack={setSnack}
              setOpenSnack={setOpenSnack}
              utenti={utenti}
              utentiSicurezza={utentiSicurezza}
              utentiFormazione={utentiFormazione}
            />
          );

        case 1:
          return (
            <Sedi
              proceduraGuidata={true}
              gestioneMedicina={true}
              idAzienda={idAzienda}
              handleComplete={handleComplete}
              handleBack={handleBack}
              snack={snack}
              openSnack={openSnack}
              handleCloseSnack={handleCloseSnack}
              setSnack={setSnack}
              setOpenSnack={setOpenSnack}
            />
          );
        case 2:
          return (
            <Mansioni
              proceduraGuidata={true}
              idAzienda={idAzienda}
              handleComplete={handleComplete}
              handleBack={handleBack}
              snack={snack}
              openSnack={openSnack}
              handleCloseSnack={handleCloseSnack}
              setSnack={setSnack}
              setOpenSnack={setOpenSnack}
            />
          );
          break;
        case 3:
          return (
            <Lavoratori
              proceduraGuidata={true}
              idAzienda={idAzienda}
              handleComplete={handleComplete}
              handleBack={handleBack}
              snack={snack}
              openSnack={openSnack}
              handleCloseSnack={handleCloseSnack}
              setSnack={setSnack}
              setOpenSnack={setOpenSnack}
            />
          );
        case 4:
          return (
            <FatturazioneMedicina
              proceduraGuidata={true}
              idAzienda={idAzienda}
              handleComplete={handleComplete}
              handleBack={handleBack}
              snack={snack}
              openSnack={openSnack}
              handleCloseSnack={handleCloseSnack}
              setSnack={setSnack}
              setOpenSnack={setOpenSnack}
            />
          );
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let response = await unsplash.get("clienti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setClienti(response.data.data);

      let responseUtenti = await unsplash.get("utenti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      const utentiMedicina = responseUtenti.data.data.filter(
        (elm) => elm.tipoUtente === "Medicina"
      );
      const utentiSicurezza = responseUtenti.data.data.filter(
        (elm) => elm.tipoUtente === "Sicurezza" && elm.username !== "federico"
      );
      const utentiFormazione = responseUtenti.data.data.filter(
        (elm) => elm.tipoUtente === "Formazione"
      );
      setHashBlock("");
      setUtenti(utentiMedicina);
      setUtentiSicurezza(utentiSicurezza);
      setUtentiFormazione(utentiFormazione);
    };
    fetchData();
  }, []);

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Box sx={{ width: "100%" }}>
              <Stepper
                nonLinear
                activeStep={activeStep}
                sx={{
                  justifyContent: "center",
                  gap: 2,
                  flexWrap: { xs: "wrap", md: "nowrap" },
                }}
              >
                {gestioneMedicina
                  ? steps.map((label, index) => (
                      <Step key={label} completed={completed[index]}>
                        <StepButton
                          color="inherit"
                          sx={{ cursor: "default!important" }} //onClick={handleStep(index)}
                        >
                          <Typography
                            sx={{
                              margin: "auto",
                              fontSize: { xs: "16px", lg: "24px" },
                            }}
                          >
                            {label}
                          </Typography>
                        </StepButton>
                      </Step>
                    ))
                  : stepsNoMedicina.map((label, index) => (
                      <Step key={label} completed={completed[index]} sx={{}}>
                        <StepButton
                          color="inherit"
                          sx={{ cursor: "default!important" }} //onClick={handleStep(index)}
                        >
                          <Typography
                            sx={{
                              margin: "auto",
                              fontSize: { xs: "14px", md: "16px", lg: "24px" },
                            }}
                          >
                            {label}
                          </Typography>
                        </StepButton>
                      </Step>
                    ))}
              </Stepper>
              <div>
                {allStepsCompleted() ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 6, mb: 1, textAlign: "center" }}>
                      <h2>Procedura Terminata con successo</h2>
                      {/*La procedura è stata notarizzata con successo
                       <br /> ({hashBlock}) <br />
                      <Button
                        className="ButtonMiller"
                        startIcon={<Search />}
                        onClick={() =>
                          document.getElementById("checkHash").click()
                        }
                        variant="contained"
                      >
                        Verifica notarizzazione
                      </Button>
                      <a
                        id="checkHash"
                        style={{ display: "None" }}
                        href={"https://testnet.algoexplorer.io/tx/" + hashBlock}
                        target="_blank"
                      >
                        Verifica notarizzazione
                      </a> */}
                      {/*
                      <Button
                        className="ButtonMiller"
                        startIcon={<History />}
                        onClick={() => document.getElementById("storico").click()}
                        variant="contained"
                      >
                       Storico
                      </Button>
                      <a id="storico"  style={{display:"None"}} href={"https://notarizer.millergroup.it/login"} target="_blank">Verifica notarizzazione</a>
                      </ButtonGroup> 
                */}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        pt: 2,
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        onClick={() => navigate("/Clienti")}
                        variant="contained"
                        startIcon={<Close />}
                        className="ButtonMiller"
                      >
                        Chiudi
                      </Button>
                      <Button
                        onClick={handleReset}
                        variant="contained"
                        startIcon={<Check />}
                        className="ButtonMiller"
                      >
                        Nuova procedura
                      </Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div style={{ marginTop: "20px" }}>
                      {renderElement(activeStep)}
                    </div>

                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      {/*{hashBlock ? (
                        <Circle
                          sx={{
                            width: "10px",
                            height: "10px",
                            color: "#40C18F",
                            margin: "auto",
                          }}
                        />
                      ) : (
                        ""
                      )}
                      <Box sx={{ flex: "1 1 auto" }} /> */}

                      {/* {activeStep !== steps.length &&
                        (completed[activeStep] ? (
                          <Typography
                            variant="caption"
                            sx={{ display: "inline-block" }}
                          >
                            Step {activeStep + 1} già completato
                          </Typography>
                        ) : (
                          <Button onClick={handleComplete}>
                            {completedSteps() === totalSteps() - 1
                              ? "Fine"
                              : "Completa Passaggio"}
                          </Button>
                        ))} */}
                    </Box>
                  </React.Fragment>
                )}
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default ProceduraGuidata;

const DatiCliente = (props) => {
  const [utenti, setUtenti] = useState([]);
  const [TipologiaAzienda, setTipologiaAzienda] = useState([
    { value: "SRL" },
    { value: "SRL Unipersonali" },
    { value: "SPA" },
    { value: "Cooperative" },
    { value: "Associazioni" },
    { value: "Ditte individuali" },
  ]);
  const [CodiceIdent, setCodiceIdent] = useState([
    { value: "Regione" },
    { value: "SDI" },
    { value: "PEC" },
    { value: "Amministratore (cliente)" },
  ]);

  const [cliente, setCliente] = useState({
    RagioneSociale: "",
    FatturaVisiteMediche: "",
    Email: "",
    CF: "",
    PIVA: "",
    Telefono: "",
    SedeIndirizzo: "",
    SedeLocalita: "",
    SedeCAP: "",
    CDC: "",
    PostIndirizzo: "",
    PostLocalita: "",
    PostCAP: "",
    CodiceATECO: "",
    GestioneMedicina: "",
    GestioneCorsi: "",
    GestioneSopralluoghi: "",
    InvioSollMedico: "",
    Sicurezza: "",
    Logo: "",
    UtenteAssociato: null,
    TipologiaAzienda: "",
    CodiceIdent: "",
    Sicurezza: "",
    UtenteSicurezza: null,
    UtenteFormazione: null,
  });
  const [textErrorCF, setTextErrorCF] = useState("Codice Fiscale obbligatorio");

  const [validation, setValidation] = useState({
    RagioneSociale: false,
    SedeIndirizzo: false,
    SedeLocalita: false,
    SedeCAP: false,
    CF: false,
  });
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    let copiaCliente = { ...cliente };
    copiaCliente[e.target.name] = e.target.value;

    setCliente(copiaCliente);
  };

  const handleCboxChange = (e) => {
    let copiaCliente = { ...cliente };
    if (e.target.name === "GestioneMedicina") {
      copiaCliente[e.target.name] = e.target.checked ? 1 : 0;
      props.gestioneMedicina(e.target.checked ? true : false);
      copiaCliente["InvioSollMedico"] = e.target.checked ? 1 : 0;
      copiaCliente["FatturaVisiteMediche"] = e.target.checked ? 1 : 0;
    } else {
      copiaCliente[e.target.name] = e.target.checked ? 1 : 0;
    }

    setCliente(copiaCliente);
  };

  const getBase64 = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {};
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleChangeImage = async (e) => {
    const file = e.target.files[0]; // prendo la foto caricata sempre nell'evento
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function () {
      document.getElementById("Logo").src = reader.result;
      cliente.Logo = reader.result.replace("data:image/png;base64,", "");
      cliente.Logo = reader.result.replace("data:image/jpeg;base64,", "");
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const saveData = async () => {
    let v = { ...validation };
    let check = false;
    //CHECK CAMPI OBBLIGATORI
    if (cliente.RagioneSociale == "" || cliente.RagioneSociale == null) {
      v.RagioneSociale = true;
      check = true;
    } else {
      v.RagioneSociale = false;
    }

    if (cliente.CF == "" || cliente.CF == null) {
      v.CF = true;
      check = true;

      setTextErrorCF("Codice Fiscale obbligatorio");
    } else if (
      props.clienti.filter(
        (c) => c.CF?.replaceAll(" ", "") === cliente.CF.replaceAll(" ", "")
      ).length > 0
    ) {
      v.CF = true;
      check = true;
      setTextErrorCF(
        "Attenzione il Codice Fiscale risulta già associato ad un altro cliente"
      );
    } else {
      v.CF = false;
    }

    if (cliente.SedeIndirizzo == "" || cliente.SedeIndirizzo == null) {
      v.SedeIndirizzo = true;
      check = true;
    } else {
      v.SedeIndirizzo = false;
    }

    if (cliente.SedeLocalita == "" || cliente.SedeLocalita == null) {
      v.SedeLocalita = true;
      check = true;
    } else {
      v.SedeLocalita = false;
    }

    if (cliente.SedeCAP == "" || cliente.SedeCAP == null) {
      v.SedeCAP = true;
      check = true;
    } else {
      v.SedeCAP = false;
    }

    setValidation(v);

    if (check) {
      props.setSnack({
        severity: "error",
        messaggio: "Compilare i campi obbligatori correttamente",
      });
      props.setOpenSnack(true);
      return;
    }

    try {
      await unsplash
        .post(
          "clienti",
          { auth: "a5d42c3ed7977afa2d8e922569e7940b", cliente: cliente },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        )
        .then(async (res) => {
          props.setIDAzienda(res.data.message.insertId);

          //props.functionContinue(true);
          props.handleComplete();

          await unsplash.post(
            "storico",
            {
              tabella: "Clienti",
              key: res.data.message.insertId,
              utente: sessionStorage.getItem("username"),
              after: cliente,
              before: [],
              operazione: "Inserimento",
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );
        });
      props.functionContinue(true); //CANCELLA

      props.setSnack({
        severity: "success",
        messaggio: "Dati del cliente salvati corretamente",
      });
      props.setOpenSnack(true);

      let text =
        "Inserimento del cliente: " +
        cliente.RagioneSociale +
        " sulla piattaforma di miller sicurezza";

      // let resJWT = await axios.post("https://notarizer.millergroup.it/api/login", {
      //   email: "mhip@millergroup.it",
      //   password: "12345",
      // }); //login e prendo jwt

      let bchain = await unsplash.post(
        "spartanHash",
        { content: text, testnet: 1, note: text, synchronous: 1 },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
            "Access-Control-Allow-Headers":
              "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
            Token: `eyJhbGciOiAiSFMyNTYiLCJ0eXAiOiAiSldUIn0.eyJpZCI6NDY4OSwidXNlcm5hbWUiOiJTYWZldHkiLCJlbWFpbCI6InNhZmV0eUBtaWxsZXJncm91cC5pdCIsIndhbGxldCI6IlRCVTRRS0JNUVFLMlpVNjZENzQ0NFhQTkw0SVNVV0ZONzZaTTRRVk1QTkZJT09YSEFWWjJCR0dEVFEiLCJjcmVkaXRzIjowLCJ0ZXN0bmV0X2NyZWRpdHMiOjAsInN0YXR1cyI6IkFjdGl2ZSJ9.b4YeeBY6nZI2BNRPXfZAa7r32bGBnO-hIUzrZrHrjk0`,
          },
        }
      );

      props.setHashBlock(bchain.data.data.tx_id);
    } catch {
      props.setSnack({
        severity: "error",
        messaggio: "Errore durante la creazione",
      });
      props.setOpenSnack(true);
    }
  };

  return (
    <>
      <GlobalSnackbar
        severity={props.snack.severity}
        messaggio={props.snack.messaggio}
        openSnack={props.openSnack}
        handleCloseSnack={props.handleCloseSnack}
      />
      <Box
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "5px",
          p: 2,
        }}
      >
        <Card xs={12} container>
          <Grid container>
            <Grid item xs={12} md={6} p={2}>
              <Box
                sx={{
                  alignContent: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardActionArea
                  onClick={() => {
                    document.getElementById("file").click();
                  }}
                >
                  <CardMedia
                    component="img"
                    height="140"
                    alt="img"
                    image={
                      cliente.Logo !== ""
                        ? "data:image/png;base64," + cliente.Logo
                        : require("../assets/images/MILLERLogo.jpg")
                    }
                    src={
                      cliente.Logo !== ""
                        ? "data:image/png;base64," + cliente.Logo
                        : require("../assets/images/MILLERLogo.jpg")
                    }
                    sx={{
                      height: "150px",
                      objectFit: "contain",
                      display: "flex",
                    }}
                    id="Logo"
                  />
                </CardActionArea>
              </Box>
              <Box sx={{ alignItems: "flex-end" }}>
                <Button
                  fullWidth
                  className="ButtonMiller"
                  startIcon={<Upload />}
                  variant="contained"
                  onClick={() => {
                    document.getElementById("file").click();
                  }}
                >
                  Carica Logo
                </Button>
              </Box>
              <input
                type="file"
                id="file"
                accept="image/*"
                onChange={(e) => handleChangeImage(e)}
                style={{ display: "none" }}
              />
            </Grid>

            <Grid
              xs={12}
              container
              md={6}
              p={0.5}
              sx={{ mt: { xs: 1, md: 0 }, gap: 1 }}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Utente Medicina
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={cliente.UtenteAssociato}
                  label="Utente Associato"
                  onChange={(e) => {
                    let c = { ...cliente };
                    c.UtenteAssociato = e.target.value;
                    setCliente(c);
                  }}
                >
                  <MenuItem value={null}>Seleziona...</MenuItem>
                  {props.utenti?.map((u) => (
                    <MenuItem value={u.ID}>{u.username}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Utente Sicurezza
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={cliente.UtenteSicurezza}
                  label="Utente Sicurezza"
                  onChange={(e) => {
                    let c = { ...cliente };
                    c.UtenteSicurezza = e.target.value;
                    setCliente(c);
                  }}
                >
                  <MenuItem value={null}>Seleziona...</MenuItem>
                  {props.utentiSicurezza?.map((u) => (
                    <MenuItem value={u.ID}>{u.username}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Utente Formazione
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={cliente.UtenteFormazione}
                  label="Utente Formazione"
                  onChange={(e) => {
                    let c = { ...cliente };
                    c.UtenteFormazione = e.target.value;
                    setCliente(c);
                  }}
                >
                  <MenuItem value={null}>Seleziona...</MenuItem>
                  {props.utentiFormazione?.map((u) => (
                    <MenuItem value={u.ID}>{u.username}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Card>
        <hr
          style={{
            height: "2px",
            marginTop: "10px",
            marginBottom: "15px",
            width: "100%",
          }}
        />
        <Card>
          <Grid
            container
            sx={{
              p: 0,
              display: "flex",
              columnGap: "10px",
              flexWrap: { xs: "wrap", md: "nowrap" },
              mt: 1,
            }}
          >
            <Grid
              xs={12}
              item
              md={6}
              sx={{
                mt: { xs: 1, md: 0 },
              }}
            >
              <TextField
                id="standard-basic"
                fullWidth
                onChange={(e) => handleInputChange(e)}
                name="RagioneSociale"
                label="Ragione Sociale*"
                error={validation.RagioneSociale}
                helperText={
                  validation.RagioneSociale
                    ? "Ragione Sociale obbligatorio"
                    : ""
                }
              />
            </Grid>
            <Grid
              xs={12}
              container
              md={6}
              sx={{
                mt: { xs: 1, md: 0 },
                p: 0,
                display: "flex",
                columnGap: "10px",
                flexWrap: { xs: "wrap", md: "nowrap" },
              }}
            >
              <Grid xs={12} item md={6} sx={{ mt: { xs: 1, md: 0 } }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Tipologia Azienda
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={cliente.TipologiaAzienda}
                    label="Tipologia Azienda"
                    onChange={(e) => {
                      let c = { ...cliente };
                      c.TipologiaAzienda = e.target.value;
                      setCliente(c);
                    }}
                  >
                    <MenuItem value={null}>Seleziona...</MenuItem>
                    {TipologiaAzienda?.map((u) => (
                      <MenuItem value={u.value}>{u.value}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid xs={12} item md={6} sx={{ mt: { xs: 1, md: 0 } }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Codice Identificativo
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={cliente.CodiceIdent}
                    label="Codice Identificativo"
                    onChange={(e) => {
                      let c = { ...cliente };
                      c.CodiceIdent = e.target.value;
                      setCliente(c);
                    }}
                  >
                    <MenuItem value={null}>Seleziona...</MenuItem>
                    {CodiceIdent?.map((u) => (
                      <MenuItem value={u.value}>{u.value}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              p: 0,
              display: "flex",
              columnGap: "10px",
              flexWrap: { xs: "wrap", md: "nowrap" },
              mt: 1,
            }}
          >
            <Grid xs={12} item md={6} sx={{ mt: { xs: 1, md: 0 } }}>
              <TextField
                id="standard-basic"
                fullWidth
                onChange={(e) => handleInputChange(e)}
                name="Email"
                label="Email"
              />
            </Grid>
            <Grid xs={12} item md={6} sx={{ mt: { xs: 1, md: 0 } }}>
              <TextField
                id="standard-basic"
                fullWidth
                onChange={(e) => handleInputChange(e)}
                name="CF"
                type="text"
                label="Codice Fiscale*"
                error={validation.CF}
                helperText={validation.CF ? textErrorCF : ""}
              />
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              p: 0,
              display: "flex",
              columnGap: "10px",
              flexWrap: { xs: "wrap", md: "nowrap" },
              mt: 1,
            }}
          >
            <Grid item xs={12} md={6}>
              <TextField
                id="standard-basic"
                fullWidth
                onChange={(e) => handleInputChange(e)}
                name="PIVA"
                label="Partita IVA"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="standard-basic"
                fullWidth
                onChange={(e) => handleInputChange(e)}
                name="Telefono"
                label="Telefono"
              />
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              p: 0,
              display: "flex",
              columnGap: "10px",
              flexWrap: { xs: "wrap", md: "nowrap" },
              mt: 1,
            }}
          >
            <Grid item xs={12} md={6}>
              <TextField
                id="standard-basic"
                fullWidth
                onChange={(e) => handleInputChange(e)}
                name="SedeIndirizzo"
                label="Sede Indirizzo*"
                error={validation.SedeIndirizzo}
                helperText={
                  validation.SedeIndirizzo ? "Sede Indirizzo obbligatorio" : ""
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="standard-basic"
                fullWidth
                onChange={(e) => handleInputChange(e)}
                name="SedeLocalita"
                label="Sede Località*"
                error={validation.SedeLocalita}
                helperText={
                  validation.SedeLocalita ? "Sede Localita obbligatorio" : ""
                }
              />
            </Grid>
          </Grid>
        </Card>
        <hr
          style={{
            height: "2px",
            marginTop: "10px",
            marginBottom: "5px",
            width: "100%",
          }}
        />
        <Card>
          <Grid
            container
            sx={{
              p: 0,
              display: "flex",
              columnGap: "10px",
              flexWrap: { xs: "wrap", md: "nowrap" },
              mt: 1,
            }}
          >
            <Grid item xs={12} md={6}>
              <TextField
                id="standard-basic"
                fullWidth
                onChange={(e) => handleInputChange(e)}
                name="SedeCAP"
                label="Sede CAP*"
                error={validation.SedeCAP}
                helperText={validation.SedeCAP ? "CAP obbligatorio" : ""}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="standard-basic"
                fullWidth
                onChange={(e) => handleInputChange(e)}
                name="CDC"
                label="Centro di costo"
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              p: 0,
              display: "flex",
              columnGap: "10px",
              flexWrap: { xs: "wrap", md: "nowrap" },
              mt: 1,
            }}
          >
            <Grid item xs={12} md={6}>
              <TextField
                id="standard-basic"
                fullWidth
                onChange={(e) => handleInputChange(e)}
                name="PostIndirizzo"
                label="Posta Indirizzo"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="standard-basic"
                fullWidth
                onChange={(e) => handleInputChange(e)}
                name="PostLocalita"
                label="Posta Località"
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              p: 0,
              display: "flex",
              columnGap: "10px",
              flexWrap: { xs: "wrap", md: "nowrap" },
              mt: 1,
            }}
          >
            <Grid item xs={12} md={6}>
              <TextField
                id="standard-basic"
                fullWidth
                onChange={(e) => handleInputChange(e)}
                name="PostCAP"
                label="Posta CAP"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="standard-basic"
                fullWidth
                onChange={(e) => handleInputChange(e)}
                name="CodiceATECO"
                label="Codice Ateco"
              />
            </Grid>
          </Grid>
        </Card>
        <hr
          style={{
            height: "2px",
            marginTop: "10px",
            marginBottom: "5px",
            width: "100%",
          }}
        />
        <Card>
          <Grid
            xs={12}
            container
            rowGap={{ xs: 0, md: 2 }}
            sx={{
              boxShadow: "none",
              height: "100%",
              alignItems: "center",
              display: "flex",
              flexWrap: "wrap",
              ml: { xs: 0, md: 1 },
              mt: 2,
            }}
          >
            <Grid xs={12} item md={6}>
              <FormControlLabel
                sx={{ width: "100%" }}
                control={
                  <Checkbox
                    name="GestioneMedicina"
                    onChange={(e) => handleCboxChange(e)}
                  />
                }
                label="Gestione Medicina"
              />
              <FormControlLabel
                sx={{ width: "100%" }}
                control={
                  <Checkbox
                    name="GestioneCorsi"
                    onChange={(e) => handleCboxChange(e)}
                  />
                }
                label="Corsi Formazione"
              />
              <FormControlLabel
                sx={{ width: "100%" }}
                control={
                  <Checkbox
                    name="GestioneSopralluoghi"
                    onChange={(e) => handleCboxChange(e)}
                  />
                }
                label="Gestione Sopralluoghi"
              />
            </Grid>
            <Grid xs={12} item md={6}>
              <FormControlLabel
                sx={{ width: "100%" }}
                control={
                  <Checkbox
                    checked={cliente.InvioSollMedico === 1 ? true : false}
                    name="InvioSollMedico"
                    onChange={(e) => handleCboxChange(e)}
                  />
                }
                label="Considera Sollecito Idoneità Medici"
              />
              <FormControlLabel
                sx={{ width: "100%" }}
                control={
                  <Checkbox
                    checked={cliente.FatturaVisiteMediche === 1 ? true : false}
                    name="FatturaVisiteMediche"
                    onChange={(e) => handleCboxChange(e)}
                  />
                }
                label="Fattura Visite Mediche"
              />
              <FormControlLabel
                sx={{ width: "100%" }}
                control={
                  <Checkbox
                    checked={cliente.Sicurezza === 1 ? true : false}
                    name="Sicurezza"
                    onChange={(e) => handleCboxChange(e)}
                  />
                }
                label="Sicurezza"
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
              px: 1,
            }}
            fullWidth
          >
            <Button
              variant="contained"
              className="ButtonMiller"
              startIcon={<Close />}
              //onClick={() => props.handleBack()}
              onClick={() => navigate("/Clienti")}
            >
              Chiudi
            </Button>
            <Button
              variant="contained"
              className="ButtonMiller"
              startIcon={<Check />}
              onClick={saveData}
            >
              Salva e Completa
            </Button>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

const Lavoratori = (props) => {
  const [lavoratori, setLavoratori] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mansioni, setMansioni] = useState([]);
  const [sedi, setSedi] = useState([]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      field: "Cognome",
      headerName: "Cognome",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Nome",
      headerName: "Nome",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "CF",
      headerName: "Codice Fiscale",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "DataAssunzione",
      headerName: "Data di Assunzione",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => {
        return (
          <div>
            {params.row.DataAssunzione
              ? moment(params.row.DataAssunzione).format("DD - MM - YYYY")
              : ""}
          </div>
        );
      },
    },
    /*{
      field: "Mansione",
      headerName: "Mansione",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },*/
    ,
  ];

  useEffect(() => {
    async function fetchData() {
      let responseMansioni = await unsplash.get("mansioni", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { id: props.idAzienda },
      });
      setMansioni(responseMansioni.data.data);

      let responseSedi = await unsplash.get("sedi", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { id: props.idAzienda },
      });
      setSedi(responseSedi.data.data);

      let responseAziendali = await unsplash.get("lavoratori", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { id: props.idAzienda },
      });

      setLavoratori(responseAziendali.data.data);
      setLoading(false);
    }
    fetchData();
  }, []);

  const handleSave = async (lavoratore) => {
    let l = { ...lavoratore };
    l.Cliente = props.idAzienda;
    try {
      let responseLavoratore = await unsplash.post(
        "lavoratori",
        { lavoratore: l },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      l.ID = responseLavoratore.data.message.insertId;
      await unsplash.post(
        "storico",
        {
          tabella: "Lavoratori",
          key: responseLavoratore.data.message.insertId,
          utente: sessionStorage.getItem("username"),
          after: l,
          before: [],
          operazione: "Inserimento",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      setLavoratori((currentLavoratori) => [...currentLavoratori, l]);
      props.setSnack({
        severity: "success",
        messaggio: "Lavoratore inserito con successo",
      });
      props.setOpenSnack(true);
    } catch {
      console.log("ERRORE");
    }
  };

  return (
    <div>
      <GlobalSnackbar
        severity={props.snack.severity}
        messaggio={props.snack.messaggio}
        openSnack={props.openSnack}
        handleCloseSnack={props.handleCloseSnack}
      />
      <ModalLavoratori
        id="add"
        mansioni={mansioni}
        sedi={sedi}
        idAzienda={props.idAzienda}
        saveFunction={handleSave}
      />
      {/* <IconButton
        aria-label="edit"
        onClick={() => document.getElementById("add").click()}
      >
        <Add style={{ height: "2rem", width: "2rem", color: "#2264ae" }} />
      </IconButton> */}
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ mb: 1 }}
      >
        <Button
          startIcon={<Add />}
          onClick={() => document.getElementById("add").click()}
          sx={{ color: "white" }}
          variant="outlined"
          className="ButtonMiller"
        >
          Aggiungi
        </Button>
      </Grid>
      <div style={{ height: "60vh", width: "100%" }}>
        <DataGridPro
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooterPersonalized,
          }}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={loading}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rows={lavoratori}
          getRowId={(lavoratori) => lavoratori.ID}
          columns={columns}
          disableSelectionOnClick
        />
      </div>
      {props?.proceduraGuidata ? (
        <>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
            }}
            fullWidth
          >
            <Button
              variant="contained"
              className="ButtonMiller"
              startIcon={<ArrowBack />}
              onClick={() => props.handleBack()}
            >
              Indietro
            </Button>
            <Button
              variant="contained"
              className="ButtonMiller"
              endIcon={<ArrowForward />}
              onClick={() => {
                if (lavoratori.length > 0) {
                  props.handleComplete();
                } else {
                  props.setSnack({
                    severity: "error",
                    messaggio: "Aggiungi almeno una Lavoratore",
                  });
                  props.setOpenSnack(true);
                }
              }}
            >
              Completa Passaggio
            </Button>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

const ModalLavoratori = (props) => {
  const [open, setOpen] = useState(false);
  const [mansioniSicurezza, setMansioniSicurezza] = useState([]);
  const [lavoratore, setLavoratore] = useState({
    Nome: "",
    Cognome: "",
    CF: "",
    Mansione: null,
    DataDiNascita: null,
    LuogoDiNascita: "",
    DataAssunzione: null,
    DataTermine: null,
    Telefono: "",
    Email: "",
    Sesso: "",
    MalattiaProfessionale: 0,
    StoricoGPCSC: 0,
    DescrizioneMalattiaProfessionale: "",
    Distaccato: "",
    GPCSC: "",
    GPCSCspesevive: "",
    DtaCostiGestione: null,
  });
  const [validation, setValidation] = useState({
    DataAssunzione: false,
    Nome: false,
    Cognome: false,
    Mansione: false,
  });

  const handleClickOpen = () => {
    setValidation({
      DataAssunzione: false,
      Nome: false,
      Cognome: false,
      Mansione: false,
    });
    setLavoratore({
      Nome: "",
      Cognome: "",
      CF: "",
      Mansione: 0,
      MansioneSicurezza: 0,
      DataDiNascita: null,
      LuogoDiNascita: "",
      DataAssunzione: null,
      DataTermine: null,
      Telefono: "",
      Email: "",
      Sesso: "",
      MalattiaProfessionale: 0,
      StoricoGPCSC: 0,
      DescrizioneMalattiaProfessionale: "",
      Distaccato: "",
      GPCSC: "",
      GPCSCspesevive: "",
      DtaCostiGestione: null,
      Somministrato: 0,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (e) => {
    let l = { ...lavoratore };
    l[e.target.name] = e.target.value;
    setLavoratore(l);
  };
  const loadMansioneSicurezza = async (idSede) => {
    let responseMansioniSicurezza = await unsplash.get("mansioniSicurezza", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: props.idAzienda, sede: idSede },
    });
    setMansioniSicurezza(responseMansioniSicurezza.data.data);
  };

  const handleSave = () => {
    let v = { ...validation };
    let check = false;
    if (lavoratore.Nome === "" || lavoratore.Nome === null) {
      v.Nome = true;
      check = true;
    } else {
      v.Nome = false;
    }

    if (lavoratore.Cognome === "" || lavoratore.Cognome === null) {
      v.Cognome = true;
      check = true;
    } else {
      v.Cognome = false;
    }

    if (
      lavoratore.DataAssunzione === "" ||
      lavoratore.DataAssunzione === null
    ) {
      v.DataAssunzione = true;
      check = true;
    } else {
      v.DataAssunzione = false;
    }

    if (lavoratore.Mansione === 0 || lavoratore.Mansione === null) {
      v.Mansione = true;
      check = true;
    } else {
      v.Mansione = false;
    }

    setValidation(v);
    if (check) {
      return;
    }

    props.saveFunction(lavoratore);
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        id={props.id}
        style={{ display: "None" }}
      >
        Open form dialog
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth="md"
        maxWidth="md"
        TransitionComponent={Transition}
      >
        <DialogTitle>Nuova Sede</DialogTitle>
        <DialogContent>
          <Grid container xs={12} spacing={2} mt={1}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-name-label">Sede</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  onChange={(e) => {
                    let l = { ...lavoratore };
                    l.Sede = e.target.value;
                    setLavoratore(l);
                    loadMansioneSicurezza(e.target.value);
                  }}
                  input={<OutlinedInput label="Name" />}
                >
                  {props.sedi?.map((sede) => (
                    <MenuItem key={sede.ID} value={sede.ID}>
                      {sede.Descrizione + " - " + sede.Blocco}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl required fullWidth error={validation.Mansione}>
                <InputLabel id="demo-multiple-name-label">Mansione</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  onChange={(e) => {
                    let l = { ...lavoratore };

                    l.Mansione = e.target.value;
                    setLavoratore(l);
                  }}
                  input={<OutlinedInput label="Mansione" />}
                >
                  {props.mansioni?.map((mansione) => (
                    <MenuItem key={mansione.ID} value={mansione.ID}>
                      {mansione.Descrizione}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  style={{ display: validation.Mansione ? "" : "None" }}
                >
                  Mansione obbligatoria
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                error={validation.Nome}
                required
                value={lavoratore.Nome}
                margin="dense"
                name="Nome"
                label="Nome"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
                helperText={validation.Nome ? "Nome obbligatorio" : ""}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                error={validation.Cognome}
                value={lavoratore.Cognome}
                margin="dense"
                name="Cognome"
                label="Cognome"
                type="text"
                required
                fullWidth
                variant="outlined"
                onChange={handleChange}
                helperText={validation.Cognome ? "Cognome obbligatorio" : ""}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={lavoratore.CF}
                margin="dense"
                name="CF"
                label="Codice Fiscale"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
                inputProps={{ maxLength: 16 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  ampmInClock={false}
                  renderInput={(props) => (
                    <TextField
                      fullWidth
                      required
                      sx={{ marginTop: "8px" }}
                      {...props}
                      error={validation.DataAssunzione}
                      helperText={
                        validation.DataAssunzione
                          ? "Data assunzione obbligatoria"
                          : ""
                      }
                    />
                  )}
                  label="Data Assunzione"
                  value={moment(lavoratore.DataAssunzione)}
                  onChange={(newValue) => {
                    let l = { ...lavoratore };
                    l.DataAssunzione = newValue
                      ? newValue.format("YYYY-MM-DD")
                      : null;
                    setLavoratore(l);
                    //setDal(newValue?newValue.format("YYYY-MM-DD"):null);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth sx={{ marginTop: "8px" }}>
                <InputLabel id="Sesso">Sesso</InputLabel>
                <Select
                  label="Sesso"
                  labelId="Sesso"
                  id="demo-simple-select"
                  value={lavoratore.Sesso}
                  onChange={(e) => {
                    let l = { ...lavoratore };
                    l.Sesso = e.target.value;
                    setLavoratore(l);
                  }}
                >
                  <MenuItem value={1}>Maschio</MenuItem>
                  <MenuItem value={2}>Femmina</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  ampmInClock={false}
                  renderInput={(props) => (
                    <TextField fullWidth sx={{ marginTop: "8px" }} {...props} />
                  )}
                  label="Data Termine"
                  value={moment(lavoratore.DataTermine)}
                  onChange={(newValue) => {
                    let l = { ...lavoratore };
                    l.DataTermine = newValue
                      ? newValue.format("YYYY-MM-DD")
                      : null;
                    setLavoratore(l);
                    //setDal(newValue?newValue.format("YYYY-MM-DD"):null);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={lavoratore.Email}
                margin="dense"
                name="Email"
                label="Email"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  ampmInClock={false}
                  renderInput={(props) => (
                    <TextField fullWidth sx={{ marginTop: "8px" }} {...props} />
                  )}
                  label="Data Di Nascita"
                  value={moment(lavoratore.DataDiNascita)}
                  onChange={(newValue) => {
                    let l = { ...lavoratore };
                    l.DataDiNascita = newValue
                      ? newValue.format("YYYY-MM-DD")
                      : null;
                    setLavoratore(l);
                    //setDal(newValue?newValue.format("YYYY-MM-DD"):null);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={lavoratore.Telefono}
                margin="dense"
                name="Telefono"
                label="Telefono"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                value={lavoratore.LuogoDiNascita}
                margin="dense"
                name="LuogoDiNascita"
                label="Luogo di Nascita"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={lavoratore.Distaccato}
                margin="dense"
                name="Distaccato"
                label="Distaccato"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  ampmInClock={false}
                  renderInput={(props) => (
                    <TextField fullWidth sx={{ marginTop: "8px" }} {...props} />
                  )}
                  label="Data Fatturazione Costi Gestione"
                  value={moment(lavoratore.DtaCostiGestione)}
                  onChange={(newValue) => {
                    let l = { ...lavoratore };
                    l.DtaCostiGestione = newValue
                      ? newValue.format("YYYY-MM-DD")
                      : null;
                    setLavoratore(l);
                    //setDal(newValue?newValue.format("YYYY-MM-DD"):null);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={lavoratore.GPCSC}
                margin="dense"
                name="GPCSC"
                label="Cartella Sanitaria Cessati"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
                helperText={validation ? "Blocco obbligatorio" : ""}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={lavoratore.GPCSCspesevive}
                margin="dense"
                name="GPCSCspesevive"
                label="Cartella Sanitaria Cessati (Spese Vive)"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="StoricoGPCSC"
                    checked={lavoratore.StoricoGPCSC === 1 ? true : false}
                    onChange={(e) => {
                      let l = { ...lavoratore };
                      l.StoricoGPCSC = e.target.checked ? 1 : 0;
                      setLavoratore(l);
                    }}
                  />
                }
                label="Storico Gestione Cartella Sanitaria Cessati"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    name="MalattiaProfessionale"
                    checked={
                      lavoratore.MalattiaProfessionale === 1 ? true : false
                    }
                    onChange={(e) => {
                      let l = { ...lavoratore };
                      l.MalattiaProfessionale = e.target.checked ? 1 : 0;
                      setLavoratore(l);
                    }}
                  />
                }
                label="Malattia Professionale"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                value={lavoratore.DescrizioneMalattiaProfessionale}
                margin="dense"
                name="DescrizioneMalattiaProfessionale"
                label="Descrizione Malattia Professionale"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={lavoratore.Note}
                margin="dense"
                name="Note"
                label="Note"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={handleSave}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
