import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import Sidebar from "./Sidebar/Sidebar";
import { Switcher } from "../../utils/Switcher";
import { BreadcrumbContainer } from "../../utils/BreadcrumbContainer";
import { AccountCircle } from "@mui/icons-material";

export default function AppContentLavoratori() {
  const [openProfile, setOpenProfile] = useState(true);
  const [burgerMenu, setBurgerMenu] = useState(false);
  const [pathname, setPathname] = useState("");
  const [link, setLink] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState("Scadenzario Visite");
  const [coords, setCoords] = useState({}); // takes current button coordinates
  const [isOn, setOn] = useState(false);

  const handleClick = (link) => {
    navigate(`/${link}`, { state: link });
  };
  useEffect(() => {
    const fetchData = async () => {};
    fetchData();
  }, []);
  useEffect(() => {
    let pathnameNoBackslash = "";
    let linkBread = "";
    if (location.pathname === "/") {
      pathnameNoBackslash = "Dashboard";
    } else if (location.pathname.includes("dettaglio")) {
      pathnameNoBackslash = location.pathname.replace("/", "");

      let firstStep = location.pathname.split("dettaglio");
      linkBread = "Dettaglio " + firstStep[1];
    } else {
      pathnameNoBackslash = location.pathname.replace("/", "");
    }
    pathnameNoBackslash = pathnameNoBackslash.replace("AreaLavoratore/", "");
    setLink(linkBread);
    setPathname(pathnameNoBackslash);
  }, [navigate]);
  return (
    <>
      <Sidebar setPage={setPage} />
      <Switcher />

      <div
        /* style={{height: "100dvh"}} */ className="lg:ml-24 h-screen dark:bg-primary-600 flex flex-col p-4 bg-cover bg-fixed bg-center bg-sfondo dark:bg-none"
      >
        <div className="px-0 lg:px-10 mx-auto w-full flex ">
          {/* Navbar */}
          <nav className=" top-0 left-0 w-full z-10 bg-transparent lg:flex-row lg:flex-nowrap lg:justify-start flex items-center pb-4">
            <div className="w-full mx-auto items-center flex justify-between lg:flex-nowrap flex-wrap z-10">
              <div className="hidden md:block">
                <BreadcrumbContainer
                  pathname={pathname}
                  link={link}
                  homeLink={"Home"}
                />
              </div>
              <ul className="flex-col lg:flex-row list-none items-center hidden lg:flex ">
                <div
                  className="text-black m-2 p-2 transition-all rounded-lg cursor-pointer hover:bg-[rgb(238,242,246)] hover:dark:bg-primary-500 dark:text-white flex gap-2 items-center"
                  onClick={() => navigate("/AreaLavoratore/Profilo")}
                >
                  <AccountCircle sx={{ fontSize: 30 }} />
                  {/*<div>
                    Benvenuto,  {sessionStorage.getItem("Nome") + " " + sessionStorage.getItem("Cognome")}
                    <span className="font-semibold">
                      {" " + sessionStorage.getItem("User")}!
                    </span>
                  </div>*/}
                </div>
              </ul>
            </div>
          </nav>
          {/* End Navbar */}
        </div>
        <div className="overflow-y-auto mt-[34px] md:mt-0 lg:mb-0 mb-[80px]">
          <Outlet />
        </div>
      </div>
    </>
  );
}
