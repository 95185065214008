import React, { useEffect, useState, useMemo, useRef } from "react";
import unsplash from "../api/unsplash";
import { useLocation, useNavigate } from "react-router-dom";

import {
  LinearProgress,
  Checkbox,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Popover,
  DialogActions,
  Box,
  Typography,
  Step,
  StepLabel,
  Stepper,
  CircularProgress,
  OutlinedInput,
  Card,
  CardContent,
  ButtonGroup,
  Tooltip,
  Zoom,
} from "@mui/material";
import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import {
  Add,
  CheckBox,
  Circle,
  Delete,
  Edit,
  Flight,
} from "@mui/icons-material";
import GlobalSnackbar from "./Snackbar";
import {
  Error,
  HourglassBottom,
  HourglassDisabled,
  Print,
  ErrorOutline,
  InsertDriveFile,
  UploadFile,
  Check,
  Close,
  Email,
  ArrowDropUp,
  Person,
} from "@mui/icons-material";

import {
  LocalizationProvider,
  DatePicker,
  PickersDay,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import momentTimezone from "moment-timezone";
import moment from "moment-timezone";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Dropzone from "react-dropzone";
import DropzoneComponent from "react-dropzone-component";
import { useDropzone } from "react-dropzone";
import Upload from "@mui/icons-material/Upload";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GroupsIcon from "@mui/icons-material/Groups";
import PrintIcon from "@mui/icons-material/Print";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import Grow from "@mui/material/Grow";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const VisiteMediche = () => {
  const navigate = useNavigate();
  const [visite, setVisite] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visiteBackup, setVisiteBackup] = useState([]);
  const [mansioni, setMansioni] = useState([]);
  const [medici, setMedici] = useState([]);
  const [visitaSel, setVisitaSel] = useState([]);
  const [esiti, setEsiti] = useState();
  const [tipologiaVisita, setTipologiaVisita] = useState();
  const [dal, setDal] = useState(
    moment(new Date()).subtract(6, "months").format("YYYY-MM-DD")
  );
  const [al, setAl] = useState(moment(new Date()).format("YYYY-MM-DD"));

  const [visiteSelezionate, setVisiteSelezionate] = useState([]);
  const [visiteSelezionateAPI, setVisiteSelezionateAPI] = useState([]); //quelle da mandare per le API
  const [filtroClessidera, setFiltroClessidera] = useState({
    verde: true,
    arancione: true,
    rosso: true,
    alert: true,
  });
  const [filtroEtichette, setFiltroEtichette] = useState({
    Medico: "",
    Lavoratore: "",
    Mansione: "",
    Accertamenti: "",
    Cliente: "",
    Blocco: "",
  });

  const [openButtonReport, setOpenButtonReport] = useState(false);
  const [reportLavoratori, setReportLavoratori] = useState([]);
  const [reportReferenti, setReportReferenti] = useState([]);

  const [missingEmailLav, setMissingEmailLav] = useState([]);
  const [missingEmailRef, setMissingEmailRef] = useState([]);

  const [optionReport, setOprionReport] = useState("");

  const anchorRefReport = useRef(null);
  const [selectedIndexReport, setSelectedIndexReport] = useState(1);
  const [expanded, setExpanded] = useState(false);

  const [loadingEmail, setLoadingEmail] = useState(false);

  const [changeDialog, setChangeDialog] = useState(false);
  const [open, setOpen] = useState(false);

  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const columns = [
    {
      filterable: false,
      headerName: "Azioni",
      field: "Actions",
      width: 70,
      headerAlign: "center",
      align: "center",
      sort: "asc",

      renderCell: (params) => {
        return (
          <div>
            <IconButton
              aria-label="edit"
              onClick={async () => {
                await setVisitaSel(params.row);
                document.getElementById("myDialog").click();
              }}
            >
              <Edit
                style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
              />
            </IconButton>
          </div>
        );
      },
    },
    {
      filterable: false,
      headerName: "*",
      field: "clessidera",
      width: 70,
      headerAlign: "center",
      align: "center",
      sort: "asc",

      renderCell: (params) => {
        return (
          <div>
            {
              /*1*/ moment(params.row.Data).format("YYYY-MM-DD") <
                moment(new Date()).format("YYYY-MM-DD") &&
              params.row.Esito <= 1 ? (
                <HourglassBottom sx={{ color: "#FF2768" }} />
              ) : params.row.Esito > 1 ? (
                <HourglassBottom sx={{ color: "#40C18F" }} />
              ) : (
                <HourglassBottom sx={{ color: "#FFC897" }} />
              )
            }
          </div>
        );
      },
    },
    {
      field: "D",
      headerName: "D",
      width: 10,

      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <div>
          {params.row.RagioneSocialeDa ? (
            <Tooltip title={"Distaccato da " + params.row.RagioneSocialeDa}>
              <Flight
                className="hover:cursor-pointer"
                sx={{ rotate: "135deg", color: "#9059C3" }}
              />
            </Tooltip>
          ) : (
            ""
          )}
        </div>
      ),
      ///<Flight  sx={{rotate:"135deg",, color: "#9059C3"}}/>
    },
    {
      filterable: false,
      headerName: "**",
      field: "LimitazioniPassate",
      width: 50,
      headerAlign: "center",
      align: "center",
      sort: "asc",

      renderCell: (params) => {
        if (params.row.Esito === 3 || params.row.Esito === 4) {
          return (
            <div>
              <Error sx={{ color: "#FFC897" }} />
            </div>
          );
        } else {
          return "";
        }
      },
    },
    {
      filterable: false,
      headerName: "Idoneità",
      field: "Idoneita",
      width: 70,
      headerAlign: "center",
      align: "center",
      sort: "asc",

      renderCell: (params) => {
        if (params.row.Idoneita === 1) {
          return (
            <div>
              <input type="checkbox" checked></input>
            </div>
          );
        } else {
          return (
            <div>
              <input type="checkbox" disabled></input>
            </div>
          );
        }
      },

      /*renderCell: (params) =>{
  
          if(params.row.Idoneita === 1){
              return <div><Error sx={{color:"#FFC897"}} /></div>
          }else{
              return ""
          }
          
      }*/
    },
    {
      field: "Cliente",
      headerName: "Cliente",
      width: 200,

      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.Cliente}>
            <div className="firstLetterUppercase">{params.row.Cliente}</div>
          </Tooltip>
        );
      },
    },
    {
      field: "Blocco",
      headerName: "Blocco",
      width: 200,

      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "ClienteAttuale",
      headerName: "Cliente Attuale",
      width: 200,
      //flex:1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Categoria",
      headerName: "Categoria",
      width: 200,
      //flex:1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "LavoratoreCognome",
      headerName: "Cognome",
      width: 200,
      //flex:1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "LavoratoreNome",
      headerName: "Nome",
      width: 200,
      //flex:1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Medico",
      headerName: "Medico",
      width: 200,
      //flex:1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Mansione",
      headerName: "Mansione",
      width: 200,
      //flex:1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Accertamenti",
      headerName: "Accertamenti",
      width: 200,
      //flex:1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "EsitoDesc",
      headerName: "Esito",
      width: 200,
      //flex:1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "TipoVDesc",
      headerName: "Tipo",
      width: 200,
      //flex:1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Data",
      headerName: "Data",
      width: 200,
      //flex:1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => {
        return (
          <div>
            {params.row.Data
              ? moment(params.row.Data).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },
    {
      field: "Scadenza",
      headerName: "Scadenza",
      width: 200,
      //flex:1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => {
        return (
          <div>
            {params.row.Scadenza
              ? moment(params.row.Scadenza).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },
    {
      field: "CF",
      headerName: "CF",
      width: 200,
      //flex:1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "UnitaProduttivaAllegato3B",
      headerName: "Unità Produttiva",
      width: 200,
      //flex:1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "DataDiNascita",
      headerName: "Data Di Nascita",
      width: 100,
      //flex:1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => {
        return (
          <div>
            {params.row.DataDiNascita
              ? moment(params.row.DataDiNascita).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },
    {
      field: "LuogoDiNascita",
      headerName: "Luogo Di Nascita",
      width: 100,
      //flex:1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
  ];

  const optionsReport = [
    {
      icon: <GroupsIcon />,
      text: "DIPENDENTI",
      ind: 4,
    },
    {
      icon: <Person />,
      text: "REFERENTI",
      ind: 5,
    },
  ];

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      try {
        let response = await unsplash.get("visiteMediche", {
          params: {
            dal: moment(new Date()).subtract(6, "months").format("YYYY-MM-DD"),
            al: moment(new Date()).format("YYYY-MM-DD"),
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        setVisite(response.data.data);
        setLoading(false);
        setVisiteBackup(response.data.data);
        let responseEsiti = await unsplash.get("esiti", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        setEsiti(responseEsiti.data.data);

        let responseTipologiaVisita = await unsplash.get("tipologiaVisita", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        setTipologiaVisita(responseTipologiaVisita.data.data);

        let responseMansioni = await unsplash.get("mansioni", {
          params: { id: -1 },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });

        setMansioni(responseMansioni.data.data);

        let responseMedici = await unsplash.get("medici", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });

        setMedici(responseMedici.data.data?.filter((el) => el?.Attivo === 1));
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  const loadVisite = async (dal, al) => {
    if (dal && al) {
      let response = await unsplash.get("visiteMediche", {
        params: { dal: dal, al: al },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      let visiteTemp = response.data.data;

      if (filtroEtichette.Medico !== "") {
        visiteTemp = visiteTemp.filter((v) =>
          v.Medico.toLowerCase().includes(filtroEtichette.Medico.toLowerCase())
        );
      }

      if (filtroEtichette.Lavoratore !== "") {
        visiteTemp = visiteTemp.filter((v) =>
          v.LavoratoreNome.toLowerCase().includes(
            filtroEtichette.Lavoratore.toLowerCase() ||
              v.LavoratoreCognome.toLowerCase().includes(
                filtroEtichette.Lavoratore.toLowerCase()
              )
          )
        );
      }
      if (filtroEtichette.Mansione !== "") {
        visiteTemp = visiteTemp.filter((v) =>
          v.Mansione.toLowerCase().includes(
            filtroEtichette.Mansione.toLowerCase()
          )
        );
      }
      if (filtroEtichette.Accertamenti !== "") {
        visiteTemp = visiteTemp.filter((v) =>
          v.Accertamenti.toLowerCase().includes(
            filtroEtichette.Accertamenti.toLowerCase()
          )
        );
      }
      if (filtroEtichette.Cliente !== "") {
        visiteTemp = visiteTemp.filter((v) =>
          v.Cliente.toLowerCase().includes(
            filtroEtichette.Cliente.toLowerCase()
          )
        );
      }
      if (filtroEtichette.Blocco !== "") {
        visiteTemp = visiteTemp.filter((v) =>
          v.Blocco.toLowerCase().includes(filtroEtichette.Blocco.toLowerCase())
        );
      }

      setVisite(visiteTemp);
      setVisiteBackup(response.data.data);
    }
  };

  const updateData = (visitaAggiornata, accertamenti) => {
    let vis = { ...visitaAggiornata };
    vis.Data = moment(vis.Data).format("YYYY-MM-DD HH:mm:ss");
    let accText = accertamenti.map((acc) => acc.Descrizione);
    vis.Accertamenti = accText.join();

    let visiteTemp = [...visite];
    const index = visiteTemp.findIndex((v) => {
      return v.ID === vis.ID;
    });

    if (index > -1) {
      visiteTemp.splice(index, 1); // Rimuovo solo un elemento
      visiteTemp.splice(index, 0, vis); //serve per aggiungere in posizione index, l'elemento dopo lo 0. Lo 0 serve per non eliminare nulla
    }
    setVisite(visiteTemp);
  };

  const printIdoneita = async () => {
    let response = await unsplash.get("visiteMediche", {
      params: { visite: visiteSelezionateAPI },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    let visite = [...visiteSelezionate];
    for (let i = 0; i < response.data.res.length; i++) {
      visite[i].Rischi = response.data.res[i].Rischi;
      visite[i].DescRischi = response.data.res[i].DescRischi;
      visite[i].ScadenzaAnni = response.data.res[i].ScadenzaAnni;
    }
    navigate("/Idoneita", { state: { visite: visite } });
  };

  const filterClessidera = (e, colore) => {
    let f = { ...filtroClessidera };
    f[colore] = e.target.checked;
    setFiltroClessidera(f);
    let visiteTemp = visiteBackup;
    if (!f.rosso) {
      visiteTemp = visiteTemp.filter(
        (v) =>
          v.Esito >= 1 ||
          moment(v.Data).format("YYYY-MM-DD") >
            moment(new Date()).format("YYYY-MM-DD")
      );
    }

    if (!f.verde) {
      visiteTemp = visiteTemp.filter((v) => v.Esito < 1);
    }

    if (!f.arancione) {
      visiteTemp = visiteTemp.filter(
        (v) =>
          moment(v.Data).format("YYYY-MM-DD") <
          moment(new Date()).format("YYYY-MM-DD")
      );
    }

    if (!f.alert) {
      visiteTemp = visiteTemp.filter((v) => v.Esito !== 3 && v.Esito !== 4);
    }

    setVisite(visiteTemp);

    /* let visiteTemp = visite.filter(v => v.Esito >=1);

setVisite(visiteTemp); */
  };

  const deleteVisita = async () => {
    try {
      await unsplash.delete("visiteMediche", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          elencoVisite: visiteSelezionate,
        },
      });
      loadVisite(dal, al);
      setSnack({
        severity: "success",
        messaggio: "Eliminazione eseguita con successo",
      });
      setOpenSnack(true);
      //reloadVisite();
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione",
      });
      setOpenSnack(true);
    }
  };

  const hadleReport = async (type, visArr) => {
    let noEmailLav = [];
    let noEmailRef = [];
    let lav = [];
    let ref = [];
    if (visArr.length !== 0) {
      if (type === "dipendenti") {
        for (let i = 0; i < visArr.length; i++) {
          const vis = visArr[i];
          if (vis?.Email === null || vis?.Email === "") {
            noEmailLav = noEmailLav.every((e) => e.ID !== vis?.ID)
              ? [
                  ...noEmailLav,
                  {
                    nome: vis?.LavoratoreNome,
                    cognome: vis?.LavoratoreCognome,
                    cf: vis?.CF,
                    ID: vis?.ID,
                  },
                ]
              : [...noEmailLav.filter((f) => f.ID === vis?.ID)];
          } else {
            //let thereIsDigiuno = selectionModel.filter((sel) => sel.Digiuno === 1);
            //se la visita non esiste
            lav = lav.every((e) => e.ID !== vis?.ID)
              ? [
                  //aggiungo la visita tenendo lo storico dei lavoratori
                  ...lav,
                  {
                    ID: vis?.ID,
                    giorno: moment(vis?.Data).format("YYYY/MM/DD"),
                    ora: moment(vis?.Data).format("HH:mm"),
                    luogo: vis?.Luogo,
                    medico: vis?.Medico,
                    nome: vis?.LavoratoreNome,
                    cognome: vis?.LavoratoreCognome,
                    email: vis?.Email,
                    conter: vis?.Gruppo === "Conter" ? vis?.IDMansione : 0,
                    sede: vis?.SedeDesc,
                    cf: vis?.CF,
                    mansione: vis?.Mansione,
                    digiuno: vis?.Digiuno === 1 ? true : false,
                  },
                ]
              : [...lav.filter((ee) => ee.ID === vis?.ID)]; //altrimenti lascio invariato
          }
        }
        setReportReferenti(ref);
        setMissingEmailRef(noEmailRef);
        setReportLavoratori(lav);
        setMissingEmailLav(noEmailLav);

        setOpen(true);
        setLoadingEmail(true);
        if (lav.length !== 0) {
          try {
            await unsplash.post(
              "report",
              {
                type: "visiteMassive",
                dipendenti: lav,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
              }
            );
            setSnack({
              severity: "success",
              messaggio: "Report inviato con successo",
            });
            setOpenSnack(true);
          } catch (error) {
            setSnack({
              severity: "error",
              messaggio: "Errore durante l'invio del report",
            });
            setOpenSnack(true);
          }
        } else {
          setSnack({
            severity: "error",
            messaggio: "Controllare l'indirizzo email dei dipendenti",
          });
          setOpenSnack(true);
        }
        setLoadingEmail(false);
      } else {
        for (let i = 0; i < visArr.length; i++) {
          const vis = visArr[i];
          //se l'email del referente non esiste
          if (vis?.EmailReferente === null || vis?.EmailReferente === "") {
            noEmailRef = noEmailLav.every((e) => e.ID !== vis?.ID)
              ? [
                  ...noEmailLav,
                  {
                    ID: vis?.ID,
                    referente: vis?.Referente,
                    sede: vis?.SedeDesc,
                  },
                ]
              : [...noEmailLav.filter((f) => f.ID === vis?.ID)];
          } else {
            //controllo se è necessario il digiuno
            let dig =
              vis?.IDMansione === 1787 ||
              vis?.IDMansione === 1728 ||
              vis?.IDMansione === 1797 ||
              vis?.IDMansione === 1798 ||
              vis?.IDMansione === 1799 ||
              vis?.IDMansione === 763 ||
              vis?.IDMansione === 1800 ||
              vis?.IDMansione === 1801 ||
              vis?.IDMansione === 1792 ||
              vis?.IDMansione === 1793
                ? "SI"
                : vis?.Digiuno === 1
                ? "SI"
                : "NO";
            //se esiste aggiungo il referente con il relativo lavoratore all'interno
            if (ref.every((e) => e.email !== vis?.EmailReferente)) {
              ref = [
                ...ref,
                {
                  email: vis?.EmailReferente,
                  referente: vis?.Referente,
                  sede: vis?.SedeDesc,
                  lavoratori: [
                    {
                      ID: vis?.ID,
                      giorno: moment(vis?.Data).format("YYYY/MM/DD"),
                      ora: moment(vis?.Data).format("HH:mm"),
                      luogo: vis?.Luogo,
                      medico: vis?.Medico,
                      nome: vis?.LavoratoreNome,
                      cognome: vis?.LavoratoreCognome,
                      email: vis?.Email,
                      conter: vis?.Gruppo === "Conter" ? vis?.IDMansione : 0,
                      sede: vis?.SedeDesc,
                      cf: vis?.CF,
                      mansione: vis?.Mansione,
                      digiuno: dig,
                    },
                  ],
                  giorno: moment(vis?.Data).format("YYYY/MM/DD"),
                  ora: moment(vis?.Data).format("HH:mm"),
                  luogo: vis?.Luogo,
                  medico: vis?.Medico,
                  conter: vis?.Gruppo === "Conter" ? vis?.IDMansione : 0,
                },
              ];
            } else {
              const refFiltered = ref.filter(
                (r) => r.email === vis?.EmailReferente
              );
              //altrimenti già il referente carico solo la visita all'interno del referente
              ref = [
                //lascio invariati gli oggetti dell'array che non hanno la stessa email referente
                ...ref.filter((r) => r.email !== vis?.EmailReferente),
                //prendo l'oggetto con la stessa email referente
                {
                  ...refFiltered[0],
                  //sovrascrivo la proprietà dei lavoratori aggiungendo la visita che sto iterando
                  lavoratori: refFiltered[0]?.lavoratori.every(
                    //se la visita non esiste la aggiungo
                    (e) => e.ID !== vis?.ID
                  )
                    ? [
                        ...refFiltered[0]?.lavoratori,
                        {
                          ID: vis?.ID,
                          giorno: moment(vis?.Data).format("YYYY/MM/DD"),
                          ora: moment(vis?.Data).format("HH:mm"),
                          luogo: vis?.Luogo,
                          medico: vis?.Medico,
                          nome: vis?.LavoratoreNome,
                          cognome: vis?.LavoratoreCognome,
                          email: vis?.Email,
                          conter:
                            vis?.Gruppo === "Conter" ? vis?.IDMansione : 0,
                          sede: vis?.SedeDesc,
                          cf: vis?.CF,
                          mansione: vis?.Mansione,
                          digiuno: dig,
                        },
                      ]
                    : [
                        //altrimenti lascio invariato l'array dei lavoratori
                        ...refFiltered[0]?.lavoratori.filter(
                          (fe) => fe.ID === vis?.ID
                        ),
                      ],
                },
              ];
            }
          }
        }
        setReportReferenti(ref);
        setMissingEmailRef(noEmailRef);

        setReportLavoratori(lav);
        setMissingEmailLav(noEmailLav);

        setOpen(true);
        setLoadingEmail(true);
        if (ref.length !== 0) {
          try {
            await unsplash.post(
              "report",
              {
                type: "visiteMassive",
                referenti: ref,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
              }
            );
            setSnack({
              severity: "success",
              messaggio: "Report inviato con successo",
            });
            setOpenSnack(true);
          } catch (error) {
            setSnack({
              severity: "error",
              messaggio: "Errore durante l'invio del report",
            });
            setOpenSnack(true);
          }
        } else {
          setSnack({
            severity: "error",
            messaggio: "Controllare l'indirizzo email del referente",
          });
          setOpenSnack(true);
        }
        setLoadingEmail(false);
      }
    } else {
      setMissingEmailLav([]);
      setMissingEmailRef([]);
      setReportReferenti([]);
      setReportLavoratori([]);
    }
  };

  const handleClose = () => {
    loadVisite(dal, al);
    setMissingEmailLav([]);
    setMissingEmailRef([]);
    setReportReferenti([]);
    setReportLavoratori([]);
    setOpen(false);
    setChangeDialog(false);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          marginBottom={2}
        >
          <div className="w-full">
            <GridToolbarQuickFilter placeholder="Cerca..." />
            <Checkbox
              icon={<HourglassDisabled />}
              checked={filtroClessidera.rosso}
              checkedIcon={<HourglassBottom />}
              sx={{ color: "#FF2768!important" }}
              onClick={(e) => filterClessidera(e, "rosso")}
            />
            <Checkbox
              icon={<HourglassDisabled />}
              checked={filtroClessidera.arancione}
              checkedIcon={<HourglassBottom />}
              sx={{ color: "#FFC897!important" }}
              onClick={(e) => filterClessidera(e, "arancione")}
            />
            <Checkbox
              icon={<HourglassDisabled />}
              checked={filtroClessidera.verde}
              checkedIcon={<HourglassBottom />}
              sx={{ color: "#40C18F!important" }}
              onClick={(e) => filterClessidera(e, "verde")}
            />
            <Checkbox
              icon={<ErrorOutline />}
              checked={filtroClessidera.alert}
              checkedIcon={<Error />}
              sx={{ color: "#FFC897!important" }}
              onClick={(e) => filterClessidera(e, "alert")}
            />
          </div>
          <div className="w-full flex h-fit">
            <ImportIdoneita />
            <Button
              variant="contained"
              size="small"
              onClick={printIdoneita}
              endIcon={<Print />}
              className="ButtonMillerTextWhite"
              sx={{ marginRight: "10px", marginLeft: "10px" }}
            >
              Stampa Idoneità
            </Button>
            <Button
              sx={{ marginRight: "10px" }}
              className="ButtonMillerTextWhite"
              size="small"
              endIcon={<InsertDriveFile />}
              variant={"contained"}
              onClick={() => document.getElementById("printDocVisite").click()}
            >
              Esporta in Excel
            </Button>
            {/* <div className="flex items-center w-fit"> */}
            <ButtonGroup
              variant="contained"
              className="mr-2"
              ref={anchorRefReport}
              aria-label="split button"
              style={{
                justifyItems: "center",
                display: visiteSelezionate.length > 0 ? "" : "None",
              }}
            >
              <Button
                size="small"
                startIcon={<Email />}
                variant="contained"
                className="ButtonMiller"
                onClick={handleToggleButtonReport}
              >
                REPORT
              </Button>
              <Button
                variant="contained"
                className="ButtonMiller"
                size="small"
                aria-controls={
                  openButtonReport ? "split-button-menu" : undefined
                }
                aria-expanded={openButtonReport ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggleButtonReport}
              >
                {openButtonReport ? <ArrowDropUp /> : <ArrowDropDownIcon />}
              </Button>
            </ButtonGroup>
            <Popper
              sx={{
                zIndex: 100,
              }}
              open={openButtonReport}
              anchorEl={anchorRefReport.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps}>
                  <Paper sx={{ ml: "10px" }}>
                    <ClickAwayListener onClickAway={handleCloseButtonReport}>
                      <MenuList id="split-button-menu" autoFocusItem>
                        {optionsReport.map((option, index) => (
                          <MenuItem
                            key={option}
                            selected={option.ind === selectedIndexReport}
                            onClick={(event) => {
                              handleMenuItemClickReport(event, option.ind);
                            }}
                          >
                            <span style={{ color: "#11526f" }}>
                              {option.icon} {option.text}
                            </span>
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <div style={{ display: "none" }}>
              <Button
                id="4"
                variant="contained"
                className="ButtonMiller"
                onClick={() => {
                  hadleReport("dipendenti", visiteSelezionate);
                  setOprionReport("dipendenti");
                }}
              >
                Dipendente
              </Button>

              <Button
                id="5"
                variant="contained"
                className="ButtonMiller"
                //sx={{ display: props.operation === "Edit" ? "" : "None" }}
                onClick={() => {
                  hadleReport("referenti", visiteSelezionate);
                  setOprionReport("referenti");
                }}
              >
                Referenti
              </Button>
            </div>
            {/* </div> */}
            <Button
              size="small"
              variant="contained"
              className="ButtonMillerError"
              style={{
                color: "white!important",
                display: visiteSelezionate.length > 0 ? "" : "None",
              }}
              startIcon={<Delete />}
              onClick={() => deleteVisita()}
            >
              {" "}
              Elimina
            </Button>
          </div>
        </Grid>
      </GridToolbarContainer>
    );
  }

  function filtraTutto(e, tipologia) {
    switch (tipologia) {
      case "Medico":
        if (
          e.target.value == "" &&
          filtroEtichette.Lavoratore == "" &&
          filtroEtichette.Mansione == "" &&
          filtroEtichette.Accertamenti == "" &&
          filtroEtichette.Cliente == "" &&
          filtroEtichette.Blocco == ""
        ) {
          setVisite(visiteBackup);
        } else {
          var visiteX = []; // popolo l'array
          var i;
          for (i = 0; i < visite.length; i++) {
            if (
              visite[i].Medico.toLowerCase().includes(
                e.target.value.toLowerCase()
              )
            ) {
              visiteX.push(visite[i]);
            }
          }
          setVisite(visiteX);
          let f = { ...filtroEtichette };
          f.Medico = e.target.value;
          setFiltroEtichette(f);
        }
        break;

      case "Lavoratore":
        if (
          e.target.value == "" &&
          filtroEtichette.Medico == "" &&
          filtroEtichette.Mansione == "" &&
          filtroEtichette.Accertamenti == "" &&
          filtroEtichette.Cliente == "" &&
          filtroEtichette.Blocco == ""
        ) {
          setVisite(visiteBackup);
        } else {
          var visiteX = []; // popolo l'array
          var i;
          for (i = 0; i < visite.length; i++) {
            if (
              visite[i].LavoratoreNome.toLowerCase().includes(
                e.target.value.toLowerCase()
              ) ||
              visite[i].LavoratoreCognome.toLowerCase().includes(
                e.target.value.toLowerCase()
              )
            ) {
              visiteX.push(visite[i]);
            }
          }
          setVisite(visiteX);
          let f = { ...filtroEtichette };
          f.Lavoratore = e.target.value;
          setFiltroEtichette(f);
        }
        break;
      case "Mansione":
        if (
          e.target.value == "" &&
          filtroEtichette.Lavoratore == "" &&
          filtroEtichette.Medico == "" &&
          filtroEtichette.Accertamenti == "" &&
          filtroEtichette.Cliente == "" &&
          filtroEtichette.Blocco == ""
        ) {
          setVisite(visiteBackup);
        } else {
          var visiteX = []; // popolo l'array
          var i;
          for (i = 0; i < visite.length; i++) {
            if (
              visite[i].Mansione?.toLowerCase().includes(
                e.target.value.toLowerCase()
              )
            ) {
              visiteX.push(visite[i]);
            }
          }
          setVisite(visiteX);
          let f = { ...filtroEtichette };
          f.Mansione = e.target.value;
          setFiltroEtichette(f);
        }
        break;
      case "Accertamenti":
        if (
          e.target.value == "" &&
          filtroEtichette.Lavoratore == "" &&
          filtroEtichette.Mansione == "" &&
          filtroEtichette.Medico == "" &&
          filtroEtichette.Cliente == "" &&
          filtroEtichette.Blocco == ""
        ) {
          setVisite(visiteBackup);
        } else {
          var visiteX = []; // popolo l'array
          var i;
          for (i = 0; i < visite.length; i++) {
            if (
              visite[i].Accertamenti?.toLowerCase().includes(
                e.target.value.toLowerCase()
              )
            ) {
              visiteX.push(visite[i]);
            }
          }
          setVisite(visiteX);
          let f = { ...filtroEtichette };
          f.Accertamenti = e.target.value;
          setFiltroEtichette(f);
        }
        break;

      case "Cliente":
        if (
          e.target.value == "" &&
          filtroEtichette.Lavoratore == "" &&
          filtroEtichette.Mansione == "" &&
          filtroEtichette.Medico == "" &&
          filtroEtichette.Accertamenti == "" &&
          filtroEtichette.Blocco == ""
        ) {
          setVisite(visiteBackup);
        } else {
          var visiteX = []; // popolo l'array
          var i;
          for (i = 0; i < visite.length; i++) {
            if (
              visite[i].Cliente.toLowerCase().includes(
                e.target.value.toLowerCase()
              )
            ) {
              visiteX.push(visite[i]);
            }
          }
          setVisite(visiteX);
          let f = { ...filtroEtichette };
          f.Cliente = e.target.value;
          setFiltroEtichette(f);
        }
        break;

      case "Blocco":
        if (
          e.target.value == "" &&
          filtroEtichette.Lavoratore == "" &&
          filtroEtichette.Mansione == "" &&
          filtroEtichette.Medico == "" &&
          filtroEtichette.Accertamenti == "" &&
          filtroEtichette.Cliente == ""
        ) {
          setVisite(visiteBackup);
        } else {
          var visiteX = []; // popolo l'array
          var i;
          for (i = 0; i < visite.length; i++) {
            if (
              visite[i].Blocco?.toLowerCase().includes(
                e.target.value.toLowerCase()
              )
            ) {
              visiteX.push(visite[i]);
            }
          }
          setVisite(visiteX);
          let f = { ...filtroEtichette };
          f.Blocco = e.target.value;
          setFiltroEtichette(f);
        }
        break;
    }
  }

  const handleToggleButtonReport = () => {
    setOpenButtonReport(!openButtonReport);
  };

  const handleCloseButtonReport = (event) => {
    if (
      anchorRefReport.current &&
      anchorRefReport.current.contains(event.target)
    ) {
      return;
    }
    setOpenButtonReport(false);
  };

  const handleMenuItemClickReport = (event, index) => {
    document.getElementById(`${index}`).click();
    //setSelectedIndex(index);
    setOpenButtonReport(false);
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid item xs={12}>
        <div style={{ display: "None" }}>
          <ReactHTMLTableToExcel
            id="printDocVisite"
            className="download-table-xls-button"
            table="table-to-xls"
            filename={"Log"}
            sheet={"Log"}
            style={{ display: "None!important" }}
            buttonText="Download Log"
          />
        </div>
        <table
          id="table-to-xls"
          style={{ marginTop: 10, display: "None" }}
          cellSpacing={0}
          border={0}
        >
          <tr>
            <th>Idoneità</th>
            <th>Cliente</th>
            <th>Cliente Attuale</th>
            <th>Categoria</th>
            <th>Cognome</th>
            <th>Nome</th>
            <th>Medico</th>
            <th>Mansione</th>
            <th>Accertamenti</th>
            <th>Esito</th>
            <th>Tipologia visita</th> <th>Data</th>
            <th>Scadenza</th>
            <th>CF</th>
            <th>Unità Produttiva</th>
            <th>Note</th>
          </tr>
          {visite?.map((visita, index) => (
            <tr key={visita.ID}>
              <td>{visita.Idoneita === 0 ? "NO" : "SI"}</td>
              <td>{visita.Cliente}</td>
              <td>{visita.ClienteAttuale}</td>
              <td>{visita.Categoria}</td>
              <td>{visita.LavoratoreCognome}</td>
              <td>{visita.LavoratoreNome}</td>
              <td>{visita.Medico}</td>
              <td>{visita.Mansione}</td>
              <td>{visita.Accertamenti}</td>
              <td>{visita.EsitoDesc}</td>
              <td>{visita.TipoVDesc}</td>
              <td>{moment(visita.Data).format("DD/MM/YYYY")}</td>
              <td>
                {visita.Scadenza
                  ? moment(visita.Scadenza).format("DD/MM/YYYY")
                  : ""}
              </td>
              <td>{visita.CF}</td>
              <td>{visita.UnitaProduttivaAllegato3B}</td>
              <td>{visita.Note}</td>
            </tr>
          ))}
        </table>

        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    ampmInClock={false}
                    renderInput={(props) => (
                      <TextField size="small" fullWidth {...props} />
                    )}
                    label="Dal"
                    value={moment(dal)}
                    onChange={(newValue) => {
                      setDal(newValue ? newValue.format("YYYY-MM-DD") : null);
                      loadVisite(newValue.format("YYYY-MM-DD"), al);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    ampmInClock={false}
                    renderInput={(props) => (
                      <TextField size="small" fullWidth {...props} />
                    )}
                    label="Al"
                    value={moment(al)}
                    onChange={(newValue) => {
                      setAl(newValue ? newValue.format("YYYY-MM-DD") : null);
                      loadVisite(dal, newValue.format("YYYY-MM-DD"));
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>

            <DettaglioVisitaMedica
              operation="new"
              title={"nuova visita medica"}
              visita={visitaSel}
              esiti={esiti}
              tipologiaVisita={tipologiaVisita}
              mansioni={mansioni}
              medici={medici}
              updateData={updateData}
            />

            <Box
              sx={{ height: { xs: "75vh", md: "60vh", xl: "75vh" }, width: 1 }}
            >
              <DataGridPro
                initialState={{ pinnedColumns: { left: ["Actions"] } }}
                sx={{
                  ".MuiDataGrid-columnHeaderCheckbox ": { display: "none" },
                  ".MuiDataGrid-cellCheckbox": { display: "none" },
                  fontSize: 12,
                }}
                components={{
                  Toolbar: CustomToolbar,
                  Footer: CustomFooterPersonalized,
                }}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                slots={{
                  loadingOverlay: LinearProgress,
                }}
                loading={loading}
                rows={visite}
                columns={columns}
                getRowId={(row) => row.ID}
                pageSize={100}
                checkboxSelection
                onSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRowData = visite.filter((row) =>
                    selectedIDs.has(row.ID)
                  );
                  setVisiteSelezionate(selectedRowData);
                  let dataAPI = selectedRowData.map((v) => {
                    return {
                      IDMansione: v.IDMansione,
                      Data: v.Data,
                      IDCliente: v.IDCliente,
                      ID: v.ID,
                    };
                  });
                  setVisiteSelezionateAPI(dataAPI);
                }}
                onCellClick={(params, e) => {
                  if (params.field === "Actions") {
                    e.stopPropagation();
                  }
                }}
                /* onRowDoubleClick={async (params, event) => {
                      await setVisitaSel(params.row);
                      document.getElementById("myDialog").click();
                    }} */
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="md"
        TransitionComponent={Transition}
      >
        {/* Title Container */}
        <DialogTitle
          sx={{
            mx: "auto",
          }}
        >
          Resoconto Invio Report
        </DialogTitle>
        {/* Body Container */}
        <DialogContent className="max-h-60 md:max-h-96 overflow-auto h-full w-full ">
          {loadingEmail ? (
            <div className="flex justify-center">
              <CircularProgress />
            </div>
          ) : (
            <>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "whitesmoke" }} />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: "40%" }}>
                    Email inviate ai {optionReport}:
                  </Typography>
                  <b style={{ width: "5%" }}>
                    {optionReport === "dipendenti"
                      ? reportLavoratori.length
                      : reportReferenti.length}
                  </b>
                  {optionReport === "dipendenti" ? (
                    <>
                      {reportLavoratori.length !== 0 ? (
                        <Circle
                          sx={{
                            color: "#40C18F",
                          }}
                        >
                          {" "}
                        </Circle>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <>
                      {reportReferenti.length !== 0 ? (
                        <Circle
                          sx={{
                            color: "#40C18F",
                          }}
                        >
                          {" "}
                        </Circle>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  {optionReport === "dipendenti" ? (
                    <>
                      {reportLavoratori.length > 0 ? (
                        <>
                          {reportLavoratori.map((el, index) => (
                            <div key={index}>
                              -
                              <b>
                                {el.cf === "" || el.cf === null
                                  ? "Senza CF"
                                  : el.cf + ", " + el.cognome + " " + el.nome}
                              </b>
                            </div>
                          ))}
                        </>
                      ) : (
                        <>
                          <div className="text-lg">Nessuna email inviata</div>
                          <div className="">
                            Controllare gli indirizzi inseriti!
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {reportReferenti.length > 0 ? (
                        <>
                          {reportReferenti.map((el, index) => (
                            <div key={index}>
                              -
                              <b>
                                {el.referente === "" || el.referente === null
                                  ? "Senza nome" + ", " + el.sede
                                  : el.referente + ", " + el.sede}
                              </b>
                            </div>
                          ))}
                        </>
                      ) : (
                        <>
                          <div className="text-lg">Nessuna email inviata</div>
                          <div className="">
                            Controllare gli indirizzi inseriti!
                          </div>
                        </>
                      )}
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "whitesmoke" }} />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography sx={{ width: "40%" }}>
                    Email mancanti {optionReport}:
                  </Typography>
                  <b style={{ width: "5%" }}>
                    {optionReport === "dipendenti"
                      ? missingEmailLav.length
                      : missingEmailRef.length}
                  </b>
                  {optionReport === "dipendenti" ? (
                    <>
                      {missingEmailLav.length !== 0 ? (
                        <Circle
                          sx={{
                            color: "#FF2768",
                          }}
                        >
                          {" "}
                        </Circle>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <>
                      {missingEmailRef.length !== 0 ? (
                        <Circle
                          sx={{
                            color: "#FF2768",
                          }}
                        >
                          {" "}
                        </Circle>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  {optionReport === "dipendenti" ? (
                    <>
                      {missingEmailLav.length > 0 ? (
                        <>
                          {missingEmailLav.map((el, index) => (
                            <div key={index}>
                              -
                              <b>
                                {el.cf === "" || el.cf === null
                                  ? "Senza CF" +
                                    ", " +
                                    el.cognome +
                                    " " +
                                    el.nome
                                  : el.cf + ", " + el.cognome + " " + el.nome}
                              </b>
                            </div>
                          ))}
                        </>
                      ) : (
                        <>
                          <div className="text-lg">Nessuna email inviata</div>
                          <div className="">
                            Controllare gli indirizzi inseriti!
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {missingEmailRef.length > 0 ? (
                        <>
                          {missingEmailRef.map((el, index) => (
                            <div key={index}>
                              -
                              <b>
                                {el.referente === "" || el.referente === null
                                  ? "Senza nome" + ", " + el.sede
                                  : el.referente + ", " + el.sede}
                              </b>
                            </div>
                          ))}
                        </>
                      ) : (
                        <>
                          <div className="text-lg">Nessuna email inviata</div>
                          <div className="">
                            Controllare gli indirizzi inseriti!
                          </div>
                        </>
                      )}
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </DialogContent>
        <DialogActions
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mx: 2,
          }}
        >
          <Button
            onClick={handleClose}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
          >
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VisiteMediche;

const DettaglioVisitaMedica = (props) => {
  const [open, setOpen] = useState(false);
  const [accertamenti, setAccertamenti] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectionModel, setSelectionModel] = useState([]);
  const [openButtonReport, setOpenButtonReport] = useState(false);
  const anchorRefReport = useRef(null);
  const [selectedIndexReport, setSelectedIndexReport] = useState(1);
  const [required, setRequired] = useState({
    medico: false,
    data: false,
    tipo: false,
  });
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const [visita, setVisita] = useState(null);
  const [orario, setOrario] = useState(null);

  const [openReport, setOpenReport] = useState(false);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const columns = [
    { field: "Descrizione", headerName: "Accertamento", width: 300 },
    {
      field: "Scadenza",
      headerName: "Scadenza",
      type: "date",
      width: 150,
      editable: true,

      renderCell: (params) =>
        params.row.Scadenza
          ? moment(params.row.Scadenza).format("YYYY-MM-DD")
          : "",
    },
    {
      field: "Importo",
      headerName: "Importo €",
      type: "number",
      width: 120,
      editable: true,
      renderCell: (params) => (params.row.Importo ? params.row.Importo : 0),
    },
    {
      field: "ImportoPassivo",
      headerName: "Importo Passivo €",
      type: "number",
      width: 150,
      editable: true,
      renderCell: (params) =>
        params.row.ImportoPassivo ? params.row.ImportoPassivo : 0,
    },
  ];

  const optionsReport = [
    {
      icon: <GroupsIcon />,
      text: "DIPENDENTI",
      ind: 4,
    },
    {
      icon: <Person />,
      text: "REFERENTI",
      ind: 5,
    },
  ];

  const loadAccertamenti = async (val, data, cliente) => {
    let responseAccertamenti = await unsplash.get("accertamenti", {
      params: { id: val, data: data, clienteAcc: cliente },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setAccertamenti(responseAccertamenti.data.data);
    setSelectionModel(
      responseAccertamenti.data.data.filter((acc) =>
        acc.VisitaMedica ? acc.ID : null
      )
    );
    setLoading(false);
  };

  const handleClickOpen = async () => {
    setVisita(props.visita);
    setAccertamenti([]);

    loadAccertamenti(
      props.visita.ID,
      moment(props.visita.Data).format("YYYY-MM-DD"),
      props.visita.IDCliente
    );

    await setRequired({ medico: false, data: false, tipo: false });
    setOrario(props.visita.Data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setVisita(null);
  };

  const handleSave = async () => {
    /*let req ={...required};
      let checkOk=true; //lo uso per controllare che tutto sia ok
      if(medicoSel ==="" || medicoSel ===null){
        req.medico =true;
        checkOk=false;
      }else{req.medico =false}  
      if(tipologiaSel ==="" || tipologiaSel ===null){
        req.tipo =true;
        checkOk=false;
      }else{req.tipo =false} 
  
      if(dataSel ==="" || dataSel ===null){
        req.data =true;
        checkOk=false;
      }else{req.data =false} 
  
      setRequired(req)
      if(checkOk){
   */
    let vis = { ...visita };
    vis.Data = vis.Data
      ? moment(vis.Data).format("YYYY-MM-DD") +
        " " +
        moment(orario).format("HH:mm:ss")
      : null;
    props.updateData(vis, selectionModel);
    try {
      let response = await unsplash.put(
        "visiteMediche",
        { visita: vis, accertamenti: selectionModel },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({
        severity: "success",
        messaggio: "Visita Medica modificata con successo",
      });
      setOpenSnack(true);
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante il salvataggio della visita medica",
      });
      setOpenSnack(true);
    }
  };

  const handleReorderCheck = async () => {
    let IDSelected = selectionModel.map((s) => s.ID);

    let top = [];
    let bottom = [];
    for (let i = 0; i < accertamenti.length; i++) {
      if (IDSelected.indexOf(accertamenti[i].ID) >= 0) {
        top.push(accertamenti[i]);
      } else {
        bottom.push(accertamenti[i]);
      }
    }

    setAccertamenti(top.concat(bottom));
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          gap={2}
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
          <Button
            size="small"
            onClick={() => handleReorderCheck()}
            startIcon={<CheckBox />}
          >
            Riordina{" "}
          </Button>
        </Grid>
      </GridToolbarContainer>
    );
  }

  const handleOrarioChange = (event) => {
    let d = { ...visita };
    setOrario(event); //.format("HH:mm")
    //utilizzo la forma abbreviata della condizione if (Guard clauses)
    if (event) {
      d.Data =
        moment(visita.Data).format("YYYY-MM-DD") +
        " " +
        event.format("HH:mm:ss");
    }
  };

  return (
    <div>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Button
        variant="outlined"
        style={{ display: "None" }}
        onClick={handleClickOpen}
        id="myDialog"
      >
        Nuovo
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <DialogTitle>
          {"Modifica Visita " +
            props.visita.LavoratoreCognome +
            " " +
            props.visita.LavoratoreNome +
            " - " +
            props.visita.Cliente}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {required.tipo || required.data || required.medico ? (
                    <p style={{ color: "#FF2768" }}>
                      controlla i campi obbligatori!
                    </p>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Medico
                    </InputLabel>
                    <Select
                      error={required.medico}
                      helperText={required.medico ? "Medico obbligatorio" : ""}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={visita ? visita.IDMedico : null}
                      label="Medico"
                      onChange={(e) => {
                        let medico = props.medici.filter(
                          (medico) => medico.ID === e.target.value
                        )[0];
                        let text = medico.Nome + " " + medico.Cognome;
                        let v = { ...visita };
                        v.IDMedico = e.target.value;
                        v.Medico = text;
                        setVisita(v);
                      }}
                    >
                      {props.medici?.map((medico) =>
                        medico.ID !== -1 ? (
                          <MenuItem key={medico.ID} value={medico.ID}>
                            {medico.Nome + " " + medico.Cognome}
                          </MenuItem>
                        ) : null
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      ampmInClock={false}
                      renderInput={(props) => (
                        <TextField fullwidth {...props} error={required.data} />
                      )}
                      label="Data"
                      value={visita ? moment(visita.Data) : null}
                      onChange={(newValue) => {
                        let v = { ...visita };
                        if (newValue) {
                          v.Data = newValue.format("YYYY-MM-DD HH:mm:SS");
                        }
                        setVisita(v);
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                      ampmInClock={false}
                      renderInput={(props) => (
                        <TextField fullwidth {...props} error={required.ora} />
                      )}
                      label="Ora"
                      value={moment(orario)}
                      onChange={handleOrarioChange}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Mansione
                    </InputLabel>
                    <Select
                      error={required.tipo}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={visita ? visita.IDMansione : null}
                      label="Mansione"
                      helperText={required.data ? "Mansione obbligatoria" : ""}
                      onChange={(e) => {
                        let text = props.mansioni.filter(
                          (mansione) => mansione.ID === e.target.value
                        )[0].Descrizione;
                        let v = { ...visita };
                        v.IDMansione = e.target.value;
                        v.Mansione = text;
                        setVisita(v);
                      }}
                    >
                      {props.mansioni?.map((mansione) => (
                        <MenuItem key={mansione.ID} value={mansione.ID}>
                          {mansione.Descrizione}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Tipologia Visita
                    </InputLabel>
                    <Select
                      error={required.tipo}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={visita ? visita.TipoVisita : null}
                      label="Tipologia Visita"
                      helperText={required.data ? "Tipologia obbligatoria" : ""}
                      onChange={(e) => {
                        let text = props.tipologiaVisita.filter(
                          (tipologia) => tipologia.ID === e.target.value
                        )[0].Descrizione;
                        let v = { ...visita };
                        v.TipoVisita = e.target.value;
                        v.TipoVDesc = text;
                        setVisita(v);
                      }}
                    >
                      {props.tipologiaVisita?.map((tipologia) => (
                        <MenuItem key={tipologia.ID} value={tipologia.ID}>
                          {tipologia.Descrizione}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Luogo"
                    multiline
                    value={visita ? visita.Luogo : null}
                    onChange={(e) => {
                      let v = { ...visita };
                      v.Luogo = e.target.value;
                      setVisita(v);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div style={{ height: 400, width: "100%" }}>
                    <DataGridPro
                      initialState={{ pinnedColumns: { left: ["Actions"] } }}
                      components={{
                        Toolbar: CustomToolbar,
                        Footer: CustomFooterPersonalized,
                      }}
                      slots={{
                        loadingOverlay: LinearProgress,
                      }}
                      loading={loading}
                      disableSelectionOnClick
                      checkboxSelection
                      disableColumnMenu={true}
                      localeText={
                        itIT.components.MuiDataGrid.defaultProps.localeText
                      }
                      rows={accertamenti}
                      getRowId={(accertamenti) => accertamenti.ID}
                      columns={columns}
                      selectionModel={selectionModel?.map((sel) => sel.ID)}
                      onSelectionModelChange={(ids) => {
                        const selectedIDs = new Set(ids);
                        const selectedRowData = accertamenti.filter((row) =>
                          selectedIDs.has(row.ID)
                        );

                        setSelectionModel(selectedRowData);
                      }}
                      onCellEditCommit={(params, event) => {
                        let row = { ...params.row };
                        if (params.field === "Scadenza") {
                          row[params.field] = params.value
                            ? moment
                                .utc(params.value)
                                .local()
                                .format("YYYY-MM-DD")
                            : null;
                        } else {
                          row[params.field] = params.value;
                        }

                        let selAcc = [...selectionModel];
                        let findIndex = selAcc.findIndex(
                          (acc) => acc.ID == params.row.ID
                        ); //trovo la posizione
                        if (findIndex !== -1) {
                          selAcc.splice(findIndex, 1);
                        }
                        selAcc.push(row);
                        setSelectionModel(selAcc);
                      }}
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Esito</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={visita ? visita.Esito : null}
                      label="Esito"
                      onChange={(e) => {
                        let text = props.esiti.filter(
                          (esito) => esito.ID === e.target.value
                        )[0].Descrizione;
                        let v = { ...visita };
                        v.Esito = e.target.value;
                        v.EsitoDesc = text;
                        setVisita(v);
                      }}

                      //onChange={handleChange}
                    >
                      {props.esiti?.map((esito) => (
                        <MenuItem key={esito.ID} value={esito.ID}>
                          {esito.Descrizione}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Note"
                    multiline
                    value={visita ? visita.Note : null}
                    onChange={(e) => {
                      let v = { ...visita };
                      v.Note = e.target.value;
                      setVisita(v);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mx: 2,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
          >
            Esci
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={handleSave}
            color="success"
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const ImportIdoneita = () => {
  const [open, setOpen] = useState(false);
  const {
    acceptedFiles,
    isDragAccept,
    isDragReject,
    isFocused,
    getRootProps,
    getInputProps,
  } = useDropzone();
  const [files, setFiles] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const handleUpload = async () => {
    if (files.length === 0) {
      setSnack({ severity: "error", messaggio: "Nessun file selezionato" });
      setOpenSnack(true);
    } else {
      const formData = new FormData();

      files.map((file) => {
        formData.append("file", file);
      });
      try {
        await unsplash
          .post("caricaMassivo", formData, {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          })
          .then((res) => {
            setSnack({
              severity: "success",
              messaggio: "File caricato con successo",
            });
            setOpenSnack(true);
          });
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante il caricamento",
        });
        setOpenSnack(true);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Button
        className="ButtonMillerTextWhite"
        size="small"
        endIcon={<UploadFile />}
        variant={"contained"}
        onClick={() => {
          setOpen(true);
          setFiles([]);
        }}
        sx={{ color: "white!important" }}
      >
        Importa Idoneità
      </Button>

      <>
        <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <DialogTitle>Carica File</DialogTitle>
          <DialogContent>
            <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  <p>
                    Trascina e rilascia qui o fai clic per selezionare i file
                  </p>
                </div>
              )}
            </Dropzone>
            <aside>
              <h4 style={{ marginTop: "10px", textAlign: "center" }}>Files</h4>
              {files?.map((file) => {
                return (
                  <>
                    <ul style={{ textAlign: "center", padding: 0 }}>
                      {file.path}
                    </ul>
                  </>
                );
              })}
            </aside>
          </DialogContent>
          <DialogActions
            xs={12}
            item
            sx={{
              display: "flex",
              mt: 2,
              justifyContent: "space-between",
              px: 2,
            }}
            fullWidth
          >
            <Button
              onClick={handleUpload}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Upload />}
            >
              Carica
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Close />}
            >
              Annulla
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
};
