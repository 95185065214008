import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import unsplash from "../../api/unsplash";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  itIT,
} from "@mui/x-data-grid-pro";
import {
  IconButton,
  Grid,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  Toolbar,
  Skeleton,
} from "@mui/material";
import {
  Download,
  RemoveRedEye,
  Delete,
  Close,
  Check,
  ContactsOutlined,
  VpnKey,
  FileDownloadDone,
  Folder,
  ArrowDropDown,
  LocationOn,
  WorkspacePremium,
  VerifiedUser,
  Place,
  Timer,
  CalendarMonth,
} from "@mui/icons-material";
import GlobalSnackbar from "../Snackbar";
import Edit from "@mui/icons-material/Edit";
import moment from "moment";
import { Tooltip } from "recharts";
import { CustomFooterPersonalized } from "../../utils/CustomFooterPersonalized";
import { ModalNewDoc } from "./AllegatiLavoratori";
import { ContainerList } from "./ContainerList";
import { useRef } from "react";
import { AllegatiList } from "./AllegatiList";

const ext = {
  doc: "application/msword",
  dot: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  dotx: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  docm: "application/vnd.ms-word.document.macroEnabled.12",
  dotm: "application/vnd.ms-word.template.macroEnabled.12",
  xls: "application/vnd.ms-excel",
  xlt: "application/vnd.ms-excel",
  xla: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xltx: "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  xlsm: "application/vnd.ms-excel.sheet.macroEnabled.12",
  xltm: "application/vnd.ms-excel.template.macroEnabled.12",
  xlam: "application/vnd.ms-excel.addin.macroEnabled.12",
  xlsb: "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  ppt: "application/vnd.ms-powerpoint",
  pot: "application/vnd.ms-powerpoint",
  pps: "application/vnd.ms-powerpoint",
  ppa: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  potx: "application/vnd.openxmlformats-officedocument.presentationml.template",
  ppsx: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  ppam: "application/vnd.ms-powerpoint.addin.macroEnabled.12",
  pptm: "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  potm: "application/vnd.ms-powerpoint.template.macroEnabled.12",
  ppsm: "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
  mdb: "application/vnd.ms-access",
  png: "application/png",
  pdf: "application/pdf",
};

const MedicinaLavoratore = (props) => {
  const [visite, setVisite] = useState([]);
  const [visitaSel, setVisitaSel] = useState(null);

  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);

  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const [allegato, setAllegato] = useState();

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          padding={2}
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }
  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "100",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => <ModalAllegati el={params.row} />,
    },
    {
      field: "Data",
      headerName: "Data",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) =>
        params.row.Data
          ? moment(params.row.Data).format("DD/MM/YYYY HH:mm")
          : "",
    },
    {
      field: "MedicoNomeCompleto",
      headerName: "Medico",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Accertamenti",
      headerName: "Accertamenti",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "DescEsito",
      headerName: "Esito",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Luogo",
      headerName: "Luogo",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
  ];

  const listAction = async (params) => {
    await setVisitaSel(params);
    document.getElementById("containerList").click();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let respData = await unsplash.post(
          "portaleLavoratori",
          {
            operation: "GetAll",
            lavoratore: sessionStorage.getItem("lavoratore"),
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        setVisite(respData.data.visite);
        setLoading(false);
      } catch {
        setVisite([]);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="lg:hidden">
        {!loading ? (
          <>
            <ContainerList
              data={visite.map((visita) => {
                //Accorpo i giorni uguali
                return {
                  ...visita,
                  DataFilter: moment(visita.Data).format("DD/MM/YYYY HH:mm"),
                  Data: [
                    <b>{moment(visita.Data).format("DD/MM/YYYY")}</b>,
                    <br />,
                    "Alle ",
                    <b>{moment(visita.Data).format("HH:mm")}</b>,
                  ],
                };
              })}
              header={["Giorno", "Visita"]}
              leftCard={["Data"]}
              additionalFilter={["DataFilter"]}
              rightCard={[
                { title: true, prop: "MedicoNomeCompleto" },
                { prop: "Luogo", icon: <Place fontSize="12" /> },
                { prop: "DescEsito", icon: <VerifiedUser fontSize="12" /> },
              ]}
              titleCard="Medico: "
              actions={listAction}
              component={<ModalAllegati />}
            />
          </>
        ) : <div className="flex flex-wrap">
        <div className="flex w-full gap-x-2">
          <Skeleton variant="rounded" width="20%" height={50} />
          <Skeleton variant="rectangular" width="4px" height={60} />
          <Skeleton variant="rounded" width="80%" height={50} />
        </div>
        <div className="flex w-full gap-x-2">
          <Skeleton variant="rounded" width="20%" height={50} />
          <Skeleton variant="rectangular" width="4px" height={60} />
          <Skeleton variant="rounded" width="80%" height={50} />
        </div>
        <div className="flex w-full gap-x-2">
          <Skeleton variant="rounded" width="20%" height={50} />
          <Skeleton variant="rectangular" width="4px" height={60} />
          <Skeleton variant="rounded" width="80%" height={50} />
        </div>
        <div className="flex w-full gap-x-2">
          <Skeleton variant="rounded" width="20%" height={50} />
          <Skeleton variant="rectangular" width="4px" height={60} />
          <Skeleton variant="rounded" width="80%" height={50} />
        </div>
        <div className="flex w-full gap-x-2">
          <Skeleton variant="rounded" width="20%" height={50} />
          <Skeleton variant="rectangular" width="4px" height={60} />
          <Skeleton variant="rounded" width="80%" height={50} />
        </div>
        <div className="flex w-full gap-x-2">
          <Skeleton variant="rounded" width="20%" height={50} />
          <Skeleton variant="rectangular" width="4px" height={60} />
          <Skeleton variant="rounded" width="80%" height={50} />
        </div>
        <div className="flex w-full gap-x-2">
          <Skeleton variant="rounded" width="20%" height={50} />
          <Skeleton variant="rectangular" width="4px" height={60} />
          <Skeleton variant="rounded" width="80%" height={50} />
        </div>
      </div>}
      </div>
      {visite === undefined ? (
        ""
      ) : (
        <>
          <div className="h-[75vh] md:h-[80vh] w-full lg:block hidden">
            <DataGridPro
              components={{
                Toolbar: CustomToolbar,
                Footer: CustomFooterPersonalized,
              }}
              sx={{ fontSize: 12, backgroundColor: "white" }}
              localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
              rows={visite}
              columns={columns}
              getRowId={(row) => row.ID}
              pageSize={100}
              disableSelectionOnClick
            />
          </div>
        </>
      )}
    </>
  );
};

export default MedicinaLavoratore;

const ModalAllegati = (props) => {
  const [open, setOpen] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [allegati, setAllegati] = useState([]);
  const [allegatoSel, setAllegatoSel] = useState(null);
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const columnsAllegati = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "100",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <ModalNewDoc
          el={params.row}
          reloadAll={reloadAll}
          download={() =>
            downloadFile(
              params.row.ID,
              params.row.Estensione,
              params.row.Descrizione,
              "download"
            )
          }
          downloadCrypt={downloadFileCrypt}
        />
      ),
    },
    {
      filterable: false,
      field: "DataVisualizzazione",
      headerName: "Data Visualizzazione",
      width: "150",
      renderCell: (params) =>
        params.row.DataVisualizzazione ? (
          <>
            {moment(params.row.DataVisualizzazione).format("DD/MM/YYYY")}
            {/* <Tooltip
            label={moment(params.row.DataVisualizzazione).format("DD/MM/YYYY")}
            >
            {" "}
            <FileDownloadDone style={{ color: "#2265B1" }} />
          </Tooltip> */}
          </>
        ) : (
          ""
        ),
    },

    {
      field: "Descrizione",
      headerName: "Descrizione",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) =>
        params.row?.Criptato === 1
          ? params.row.Descrizione.replace(
              params.row.Estensione,
              params.row.EstensioneOriginale
            )
          : params.row.Descrizione,
    },
  ];
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          padding={2}
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const downloadFile = async (id, estensione, descrizione, operazione) => {
    try {
      const response = await unsplash.post(
        "ftp",
        {
          file: id + estensione,
          nomeOriginale: descrizione.includes(estensione)
            ? descrizione
            : descrizione + estensione,
          operazione: "download",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: ext[estensione.replace(".", "")] })
      );
      const link = document.createElement("a");
      link.href = url;
      if (operazione === "download") {
        link.setAttribute(
          "download",
          descrizione.includes(estensione)
            ? descrizione
            : descrizione + estensione
        );
        document.body.appendChild(link);
        link.click();
      }
      setSnack({
        severity: "success",
        messaggio: "File scaricato con successo",
      });
      setOpenSnack(true);
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante il dowload del file",
      });
      setOpenSnack(true);
    }
  };

  const downloadFileCrypt = async (
    id,
    estensione,
    descrizione,
    operazione,
    criptato,
    estensioneOriginale,
    psw
  ) => {
    //let psw;

    /* if (criptato === 1) {
      psw = window.prompt(
        "Inserisci la tua password per scaricare questo documento"
      );
    } */
    try {
      const response = await unsplash.post(
        "ftp",
        {
          file: id + estensione,
          nomeOriginale: descrizione.includes(estensione)
            ? descrizione
            : descrizione + estensione,
          operazione: "downloadCrypt",
          estensioneOriginale: estensioneOriginale,
          password: psw,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: ext[estensione.replace(".", "")] })
      );
      const link = document.createElement("a");
      link.href = url;
      if (operazione === "download") {
        link.setAttribute(
          "download",
          descrizione.replace(estensione, estensioneOriginale)
        );
        document.body.appendChild(link);
        link.click();
      }
      setSnack({
        severity: "success",
        messaggio: "File scaricato con successo",
      });
      setOpenSnack(true);
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante il dowload del file",
      });
      setOpenSnack(true);
    }
  };

  const reloadAll = async () => {
    try {
      let respData = await unsplash.post(
        "portaleLavoratori",
        {
          operation: "GetAll",
          lavoratore: sessionStorage.getItem("lavoratore"),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      setAllegati(
        respData.data.allegati.filter((elm) => elm.VisitaMedica === props.el.ID)
      );
    } catch {
      setAllegati([]);
    }
  };

  const handleClickOpen = async () => {
    reloadAll();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const listAction = async (params) => {
    await setAllegatoSel(params);
    document.querySelector("#allegatoList").click();
  };

  return (
    <>
      <IconButton
        aria-label="edit"
        onClick={handleClickOpen}
        id={"containerList"}
        style={{ display: props?.hidden ? "none" : "flex" }}
      >
        <Folder style={{ height: "2rem", width: "2rem", color: "#11526f" }} />
      </IconButton>
      {props.el?.Criptato === 1 ? (
        <IconButton aria-label="edit">
          <VpnKey style={{ color: "#11526f" }} />
        </IconButton>
      ) : (
        <></>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={"md"}
        maxWidth={"md"}
      >
        <GlobalSnackbar
          severity={snack.severity}
          messaggio={snack.messaggio}
          openSnack={openSnack}
          handleCloseSnack={handleCloseSnack}
        />
        <div className="py-6 px-6 lg:px-8">
          <h3 className="mb-4 text-xl font-medium text-center text-black">
            {openMessage ? "Controllo" : "Allegati"}
          </h3>
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="absolute top-4 right-5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="authentication-modal"
          >
            <svg
              aria-hidden="true"
              className="w-7 h-7"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="w-full">
            <div className="h-[45vh] md:h-[50vh] w-full lg:block hidden">
              <DataGridPro
                components={{
                  Toolbar: CustomToolbar,
                  Footer: CustomFooterPersonalized,
                }}
                sx={{ fontSize: 12, backgroundColor: "white" }}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                rows={allegati}
                columns={columnsAllegati}
                getRowId={(row) => row.ID}
                pageSize={100}
                disableSelectionOnClick
              />
            </div>
            <div className="lg:hidden block">
              {allegati?.length > 0 ? (
                <>
                  <AllegatiList
                    actions={listAction}
                    data={allegati.map((al) => {
                      return {
                        ...al,
                        DataVisualizzazione: al.DataVisualizzazione
                          ? moment(al.DataVisualizzazione).format("DD/MM/YYYY")
                          : "",
                      };
                    })}
                    rightCard={[
                      { title: true, prop: "Descrizione" },
                      {
                        prop: "DataVisualizzazione",
                        icon: <CalendarMonth fontSize="medium" />,
                      },
                    ]}
                    component={
                      <ModalNewDoc
                        reloadAll={reloadAll}
                        downloadCrypt={downloadFileCrypt}
                        download={downloadFile}
                      />
                    }
                  />
                </>
              ) : (
                <div className="flex flex-wrap">
                  <div className="flex w-full gap-x-2">
                    <Skeleton variant="rounded" width="20%" height={50} />
                    <Skeleton variant="rectangular" width="4px" height={60} />
                    <Skeleton variant="rounded" width="80%" height={50} />
                  </div>
                  <div className="flex w-full gap-x-2">
                    <Skeleton variant="rounded" width="20%" height={50} />
                    <Skeleton variant="rectangular" width="4px" height={60} />
                    <Skeleton variant="rounded" width="80%" height={50} />
                  </div>
                  <div className="flex w-full gap-x-2">
                    <Skeleton variant="rounded" width="20%" height={50} />
                    <Skeleton variant="rectangular" width="4px" height={60} />
                    <Skeleton variant="rounded" width="80%" height={50} />
                  </div>
                  <div className="flex w-full gap-x-2">
                    <Skeleton variant="rounded" width="20%" height={50} />
                    <Skeleton variant="rectangular" width="4px" height={60} />
                    <Skeleton variant="rounded" width="80%" height={50} />
                  </div>
                </div>
              )}
            </div>
            <div className="flex justify-between mt-4 ">
              <Button
                className="ButtonMiller"
                variant="contained"
                startIcon={<Close />}
                //className="bg-primary-600 text-white p-2 hover:bg-primary-100 mb-2 transition-all duration-300 ease-in-out"
                onClick={handleClose}
              >
                Chiudi
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
