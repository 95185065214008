import React, { useEffect, useState } from "react";
import unsplash from "../../api/unsplash";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Box,
  Zoom,
  LinearProgress,
} from "@mui/material";
import moment from "moment";
import GlobalSnackbar from "../Snackbar";
import { LocalizationProvider,DatePicker,PickersDay,TimePicker,DateTimePicker } from '@mui/x-date-pickers'
import { AdapterMoment as DateAdapter} from '@mui/x-date-pickers/AdapterMoment'
import { Check, Close } from "@mui/icons-material";
import AddchartIcon from "@mui/icons-material/Addchart";
import { CustomFooterPersonalized } from "../../utils/CustomFooterPersonalized";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <GridToolbarQuickFilter placeholder="Cerca..." />
      </Grid>
    </GridToolbarContainer>
  );
}

const ProtocolloSanitarioLavoratore = (props) => {
  const columns = [
    { field: "NomeAccertamento", headerName: "Accertamento", width: 200 },
    { field: "Cadenza", headerName: "Cadenza", flex: 1 },
    { field: "LineeGuida", headerName: "Linee Guida", flex: 1 },
    {
      field: "Data",
      headerName: "Ultima Visita",
      flex: 1,
      cellClassName: (params) => {
        if (params.value === "" || params.value === null)
          return "AccertamentoGiallo";
      },
      renderCell: (params) => {
        return (
          <div>
            {params.row.Data
              ? moment(params.row.Data).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },
    {
      field: "Descrizione",
      headerName: "Esito",
      flex: 1,
      cellClassName: (params) => {
        if (params.value !== "Idoneo alla mansione")
          return "AccertamentoGiallo";
      },
    },
    {
      field: "Scadenza",
      headerName: "Scadenza",
      flex: 1,
      cellClassName: (params) => {
        let scad = new Date(moment(params.value).format("YYYY-MM-DD"));
        let d1 = new Date();
        let d2 = new Date(
          moment(d1.setDate(d1.getDate() + 60)).format("YYYY-MM-DD")
        );
        if (params.value === null || params.value === "") {
          return "AccertamentoRosso";
        }
        if (scad.getTime() < d1.getTime()) {
          return "AccertamentoRosso";
        }
        if (scad.getTime() < d2.getTime()) {
          return "AccertamentoGiallo";
        }
      },
      renderCell: (params) => {
        return (
          <div>
            {params.row.Scadenza
              ? moment(params.row.Scadenza).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },
  ];

  const [protocollo, setProtocollo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [medici, setMedici] = useState();
  const [esiti, setEsiti] = useState();
  const [tipologiaVisita, setTipologiaVisita] = useState();
  const [accertamentiScaduti, setAccertamentiScaduti] = useState();

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      let responseProtocollo = await unsplash.get("protocolloSanitario", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { lavoratore: props.lavoratore.ID },
      });
      setProtocollo(responseProtocollo.data.data);
      setLoading(false);

      let d1 = new Date();
      let d2 = new Date(
        moment(d1.setDate(d1.getDate() + 60)).format("YYYY-MM-DD")
      );

      let acs = responseProtocollo.data.data.filter(
        (p) =>
          p.Scadenza === null ||
          new Date(moment(p.Scadenza).format("YYYY-MM-DD")).getTime() <
            d2.getTime() ||
          p.Data === null
      ); // solo accertamenti scaduti
      setAccertamentiScaduti(acs);

      let responseMedici = await unsplash.get("medici", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { id: props.idAzienda },
      });
      setMedici(responseMedici.data.data);

      let responseEsiti = await unsplash.get("esiti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setEsiti(responseEsiti.data.data);

      let responseTipologiaVisita = await unsplash.get("tipologiaVisita", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setTipologiaVisita(responseTipologiaVisita.data.data);
    };

    fetchData();
  }, []);

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={2}
      >
        <VisiteScadute
          lavoratore={props.lavoratore}
          medici={medici}
          esiti={esiti}
          tipologiaVisita={tipologiaVisita}
          cliente={props.idAzienda}
          accertamentiScaduti={accertamentiScaduti}
        />
      </Grid>
      <div style={{ height: "58vh", width: "100%" }}>
        <DataGridPro
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooterPersonalized,
          }}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={loading}
          disableSelectionOnClick
          disableColumnMenu={true}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rows={protocollo}
          getRowId={(protocollo) => protocollo.Accertamenti}
          columns={columns}
        />
      </div>
    </>
  );
};

export default ProtocolloSanitarioLavoratore;

const VisiteScadute = (props) => {
  const [medicoSel, setMedicoSel] = useState("");
  const [esitoSel, setEsitoSel] = useState("");
  const [luogoSel, setLuogoSel] = useState("");
  const [tipologiaSel, setTipologiaSel] = useState("");
  const [dataSel, setDataSel] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [accertamenti, setAccertamenti] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectionModel, setSelectionModel] = useState([]);
  const [required, setRequired] = useState({
    medico: false,
    data: false,
    tipo: false,
  });
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const columns = [
    {
      field: "Descrizione",
      headerName: "Accertamento",
      width: 300,
      sort: "ASC",
    },
    {
      field: "Scadenza",
      headerName: "Scadenza",
      type: "date",
      width: 150,
      editable: true,
      renderCell: (params) =>
        params.row.Scadenza
          ? moment(params.row.Scadenza).format("DD/MM/YYYY")
          : "",
    },
    {
      field: "Importo",
      headerName: "Importo €",
      type: "number",
      width: 120,
      editable: true,
      renderCell: (params) => (params.row.Importo ? params.row.Importo : 0),
    },
    {
      field: "ImportoPassivo",
      headerName: "Importo Passivo €",
      type: "number",
      width: 150,
      editable: true,
      renderCell: (params) =>
        params.row.ImportoPassivo ? params.row.ImportoPassivo : 0,
    },
  ];

  const loadVisitaLavoratore = async (val) => {
    let responseAccertamenti = await unsplash.get("accertamenti", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { cliente: props.cliente, ultimaVisita: 0 },
    });

    let allAccertamenti = responseAccertamenti.data.data;
    let arrayOnlyID = allAccertamenti.map((acc) => acc.ID);

    for (let i = 0; i < props.accertamentiScaduti.length; i++) {
      let index = arrayOnlyID.indexOf(
        props.accertamentiScaduti[i].Accertamenti
      );

      allAccertamenti[index].Scadenza = moment(dataSel).add(
        props.accertamentiScaduti[i].Cadenze,
        "M"
      );
    }

    setSelectionModel(
      props.accertamentiScaduti.map((acc) => {
        return { ID: acc.Accertamenti };
      })
    );
    setAccertamenti(allAccertamenti);
    setLoading(false);
  };

  const handleClickOpen = async () => {
    await setRequired({ medico: false, data: false, tipo: false });
    loadVisitaLavoratore(null);

    setDataSel(new Date());
    setOpen(true);
    await setMedicoSel("");
    await setEsitoSel("");
    await setLuogoSel("");
    await setTipologiaSel("");
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    let req = { ...required };
    let checkOk = true; //lo uso per controllare che tutto sia ok
    if (medicoSel === "" || medicoSel === null) {
      req.medico = true;
      checkOk = false;
    } else {
      req.medico = false;
    }
    if (tipologiaSel === "" || tipologiaSel === null) {
      req.tipo = true;
      checkOk = false;
    } else {
      req.tipo = false;
    }

    if (dataSel === "" || dataSel === null) {
      req.data = true;
      checkOk = false;
    } else {
      req.data = false;
    }

    setRequired(req);
    if (checkOk) {
      try {
        let response = await unsplash.post(
          "visiteMediche",
          {
            accertamenti: selectionModel,
            medico: medicoSel,
            cliente: props.cliente,
            mansione: props.lavoratore.Mansione,
            data: dataSel,
            esito: esitoSel,
            luogo: luogoSel,
            tipoVisita: tipologiaSel,
            lavoratore: props.lavoratore.ID,
            vecchiaVisita: props.lavoratore.IDUltimaVisita,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Visita Medica creata con successo",
        });
        setOpenSnack(true);
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante la creazione",
        });
        setOpenSnack(true);
      }
    }
  };

  return (
    <div>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          className="ButtonMiller"
          onClick={handleClickOpen}
          id="myDialog"
          sx={{ mb: 1 }}
          startIcon={<AddchartIcon />}
        >
          Programma visite scadute
        </Button>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <DialogTitle>Visite Scadute</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {required.tipo || required.data || required.medico ? (
                  <p style={{ color: "#FF2768" }}>
                    controlla i campi obbligatori!
                  </p>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Medico</InputLabel>
                  <Select
                    error={required.medico}
                    helperText={required.medico ? "Medico obbligatorio" : ""}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={medicoSel}
                    label="Medico"
                    onChange={(e) => setMedicoSel(e.target.value)}
                  >
                    {props.medici?.map((medico) => (
                      <MenuItem key={medico.ID} value={medico.ID}>
                        {medico.Descrizione}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DateTimePicker
                    ampmInClock={false}
                    renderInput={(props) => (
                      <TextField {...props} error={required.data} fullWidth />
                    )}
                    label="Data e ora"
                    value={moment(dataSel)}
                    onChange={(newValue) => {
                      setDataSel(newValue);

                      if (newValue) {
                        let allAccertamenti = accertamenti;
                        let arrayOnlyID = allAccertamenti.map((acc) => acc.ID);

                        for (
                          let i = 0;
                          i < props.accertamentiScaduti.length;
                          i++
                        ) {
                          let index = arrayOnlyID.indexOf(
                            props.accertamentiScaduti[i].Accertamenti
                          );
                          allAccertamenti[index].Scadenza = moment(
                            newValue
                          ).add(props.accertamentiScaduti[i].Cadenze, "M");
                        }
                        setAccertamenti(allAccertamenti);
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Tipologia Visita
                  </InputLabel>
                  <Select
                    error={required.tipo}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={tipologiaSel}
                    label="Tipologia Visita"
                    helperText={required.data ? "Tipologia obbligatoria" : ""}
                    onChange={(e) => setTipologiaSel(e.target.value)}
                  >
                    {props.tipologiaVisita?.map((tipologia) => (
                      <MenuItem key={tipologia.ID} value={tipologia.ID}>
                        {tipologia.Descrizione}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Luogo"
                  multiline
                  value={luogoSel}
                  onChange={(e) => setLuogoSel(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{ height: 400, width: "100%" }}>
                  <DataGridPro
                    components={{
                      Toolbar: CustomToolbar,
                      Footer: CustomFooterPersonalized,
                    }}
                    slots={{
                      loadingOverlay: LinearProgress,
                    }}
                    loading={loading}
                    disableSelectionOnClick
                    checkboxSelection
                    disableColumnMenu={true}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: "Descrizione", sort: "asc" }],
                      },
                    }}
                    localeText={
                      itIT.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={accertamenti}
                    getRowId={(accertamenti) => accertamenti.ID}
                    columns={columns}
                    selectionModel={selectionModel?.map((sel) => sel.ID)}
                    onSelectionModelChange={(ids) => {
                      const selectedIDs = new Set(ids);
                      const selectedRowData = accertamenti.filter((row) =>
                        selectedIDs.has(row.ID)
                      );

                      setSelectionModel(selectedRowData);
                    }}
                    onCellEditCommit={(params, event) => {
                      let row = { ...params.row };
                      if (params.field === "Scadenza") {
                        row[params.field] = moment(params.value).format(
                          "YYYY-MM-DD"
                        );
                      } else {
                        row[params.field] = params.value;
                      }

                      let selAcc = [...selectionModel];
                      let findIndex = selAcc.findIndex(
                        (acc) => acc.ID == params.row.ID
                      ); //trovo la posizione
                      if (findIndex !== -1) {
                        selAcc.splice(findIndex, 1);
                      }
                      selAcc.push(row);
                      setSelectionModel(selAcc);
                    }}
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Esito</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={esitoSel}
                    label="Esito"
                    onChange={(e) => setEsitoSel(e.target.value)}

                    //onChange={handleChange}
                  >
                    {props.esiti?.map((esito) => (
                      <MenuItem key={esito.ID} value={esito.ID}>
                        {esito.Descrizione}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
          fullWidth
        >
          <Button
            onClick={handleClose}
            color="error"
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
          >
            Esci
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            color="success"
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
