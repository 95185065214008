import React, { useEffect, useState } from "react";
import unsplash from "../api/unsplash";
import {
	DataGridPro,
	itIT,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
	GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import GlobalSnackbar from "./Snackbar";
import {
	IconButton,
	Button,
	ButtonGroup,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	Autocomplete,
	Box,
	Tabs,
	Typography,
	Tab,
	InputAdornment,
	List,
	ListItemButton,
	ListItemText,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	Zoom,
	LinearProgress,
} from "@mui/material";
import "../style/style.css";
import {
	Edit,
	Delete,
	Add,
	Close,
	Check,
	Search,
	ExpandMore,
	Circle,
	PictureAsPdf,
	History,
} from "@mui/icons-material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import PropTypes from "prop-types";
import ReactHtmlTableToExcel from "react-html-table-to-excel";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const CorsiCliente = (props) => {
	const [corsiCliente, setCorsiCliente] = useState([]);
	const [loading, setLoading] = useState(true);
	const [corsiClienteExport, setCorsiClienteExport] = useState([]);
	const [corsi, setCorsi] = useState();
	const [vistaCorsi, setVistaCorsi] = useState();
	const [openSnack, setOpenSnack] = useState(false);
	const [corsoSel, setCorsoSel] = useState(false);
	const [storicoFatturazioneCorsi, setStoricoFatturazioneCorsi] = useState([]);
	const [storicoFatturazioneCorsiBackup, setStoricoFatturazioneCorsiBackup] =
		useState([]);
	const [value, setValue] = useState(0);
	const [selectedIndex, setSelectedIndex] = useState(null);
	const [corsiDaFatturare, setCorsiDaFatturare] = useState([]);
	const [openAlert, setOpenAlert] = useState(false);

	const [snack, setSnack] = useState({
		severity: "success",
		messaggio: "Salvataggio Effettuato correttamente",
		open: false,
	});
	const handleCloseSnack = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnack(false);
	};

	const toggleAlert = async (reload) => {
		if (reload) {
			setOpenAlert(!openAlert);
			let responsecorsiCliente = await unsplash.get(
				"corsiCliente",
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("jwt")}`,
					},
					params: { cliente: props.idAzienda },
				} //props.idAzienda}}
			);

			setCorsiCliente(responsecorsiCliente.data.data);
		} else {
			setOpenAlert(!openAlert);
		}
	};

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<Grid
					container
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
				>
					<GridToolbarQuickFilter placeholder="Cerca..." />
				</Grid>
			</GridToolbarContainer>
		);
	}

	const columns = [
		{
			filterable: false,
			field: "Actions",
			headerName: "Azioni",
			hide: false,
			width: "130",
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<>
					<FormDialog
						id="edit"
						operazione="Modifica"
						corsiCliente={corsiCliente}
						corsi={corsiCliente}
						reloadData={loadData}
						saveFunction={handleSaveData}
						corsoSel={params.row}
					/>
					<IconButton
						aria-label="delete"
						onClick={async () => {
							await setVistaCorsi(params.row);
							toggleAlert();
						}}
					>
						<Delete
							style={{ height: "2rem", width: "2rem", color: "#FF2768" }}
						/>
					</IconButton>
				</>
			),
		},
		{
			field: "Codice",
			headerName: "Codice",
			//width: 400,
			flex: 1,
			headerAlign: "center",
			align: "center",
			sort: "asc",
		},
		{
			field: "Descrizione",
			headerName: "Descrizione",
			//width: 300,
			flex: 1,
			headerAlign: "center",
			align: "center",
			sort: "asc",
		},
		{
			field: "Importo",
			headerName: "Importo",
			//width: 300,
			flex: 1,
			headerAlign: "center",
			align: "center",
			sort: "asc",
		},
		{
			field: "ImportoAggiornamento",
			headerName: "Aggiornamento",
			//width: 300,
			flex: 1,
			headerAlign: "center",
			align: "center",
			sort: "asc",
		},
		{
			field: "ImportoEL",
			headerName: "Importo E-Learning",
			//width: 300,
			flex: 1,
			headerAlign: "center",
			align: "center",
			sort: "asc",
		},
		{
			field: "ImportoAggiornamentoEL",
			headerName: "Aggironmento E-Learning",
			//width: 300,
			flex: 1,
			headerAlign: "center",
			align: "center",
			sort: "asc",
		},
	];

	const loadData = async () => {
		let responsecorsiCliente = await unsplash.get(
			"corsiCliente",
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem("jwt")}`,
				},
				params: { cliente: props.idAzienda },
			} //props.idAzienda}}
		);

		setCorsiCliente(responsecorsiCliente.data.data);
		setLoading(false);
	};

	useEffect(() => {
		async function fetchData() {
			loadData();

			let responsecorsiClienteExport = await unsplash.get("corsiCliente", {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("jwt")}`,
				},
				params: { cliente: props.idAzienda, export: true },
			});

			setCorsiClienteExport(responsecorsiClienteExport.data.data);

			let responsecorsi = await unsplash.get(
				"anagraficaCorsi",
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("jwt")}`,
					},
				} //props.idAzienda}}
			);

			setCorsi(responsecorsi.data.data);

			let responseFatturazione = await unsplash.get(
				"storicoFatturazioneCorsi",
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("jwt")}`,
					},
					params: { cliente: props.idAzienda },
				} //props.idAzienda}}
			);

			setStoricoFatturazioneCorsi(responseFatturazione.data.data);
			setStoricoFatturazioneCorsiBackup(responseFatturazione.data.data);
		}

		fetchData();
	}, []);

	const handleSaveData = async (corso, importi, operazione) => {
		try {
			//inserisco il nuovo
			try {
				let dettaglioCorso = {
					ID: corso.ID,
					Corso: corso.Codice,
					Importo: importi.Importo,
					ImportoAggiornamento: importi.ImportoAggiornamento,
					ImportoEL: importi.ImportoEL,
					ImportoAggiornamentoEL: importi.ImportoAggiornamentoEL,
					ScadenzaPersonalizzata: importi.ScadenzaPersonalizzata,
				};

				if (operazione === "Inserimento") {
					let responseInsert = await unsplash.post(
						"corsiCliente",
						{ cliente: props.idAzienda, corso: dettaglioCorso },
						{
							headers: {
								Authorization: `Bearer ${localStorage.getItem("jwt")}`,
							},
						}
					);
				} else {
					let responseModify = await unsplash.put(
						"corsiCliente",
						{ cliente: props.idAzienda, corso: dettaglioCorso },
						{
							headers: {
								Authorization: `Bearer ${localStorage.getItem("jwt")}`,
							},
						}
					);
				}

				let responsecorsiCliente = await unsplash.get(
					"corsiCliente",
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem("jwt")}`,
						},
						params: { cliente: props.idAzienda },
					} //props.idAzienda}}
				);

				setCorsiCliente(responsecorsiCliente.data.data);
				setSnack({
					severity: "success",
					messaggio:
						operazione === "Inserimento"
							? "Corso inserito con successo"
							: "Corso Modificato con successo",
				});
				setOpenSnack(true);
			} catch {
				setSnack({
					severity: "error",
					messaggio: "Errore durante l'inserimento",
				});
				setOpenSnack(true);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleListItemClick = async (event, index) => {
		setSelectedIndex(index);
		let corsoSelezionato = storicoFatturazioneCorsi[index];

		let response = await unsplash.get("storicoFatturazioneCorsi", {
			params: {
				ID: corsoSelezionato.ID,
				cliente: props.idAzienda,
			},
			headers: {
				Authorization: `Bearer ${localStorage.getItem("jwt")}`,
			},
		});
		calcoloPrefatturazione(response.data.data);
	};

	const calcoloPrefatturazione = (costiCorsi) => {
		//funzione per calcolare tutti i totali

		let totale = 0;

		for (let i = 0; i < costiCorsi.length; i++) {
			let totaleCorsoTipo = 0;

			for (let j = 0; j < costiCorsi[i].corsi.length; j++) {
				let totaleCorsoSelezionato = 0;
				let mostraInStampa = 0;
				if (costiCorsi[i].corsi[j].ModalitaFatturazione === 1) {
					//tariffa a lavoratore

					for (let k = 0; k < costiCorsi[i].corsi[j].Presenti.length; k++) {
						if (costiCorsi[i].corsi[j].Presenti[k].EraPresente === 1) {
							totaleCorsoSelezionato += costiCorsi[i].corsi[j].Tariffa;
							mostraInStampa = 1;
						}
					}
				} else {
					// FORFAIT
					mostraInStampa = 1;
					totaleCorsoSelezionato +=
						costiCorsi[i].corsi[j].OreTotaliCorso *
						costiCorsi[i].corsi[j].CostoForfait;
				}

				costiCorsi[i].corsi[j].totaleCorso = totaleCorsoSelezionato;
				totaleCorsoTipo += totaleCorsoSelezionato;
				costiCorsi[i].corsi[j].MostraInStampa = mostraInStampa;
			} //chiudo il singolo

			costiCorsi[i].totaleTipoCorso = totaleCorsoTipo;
			totale += totaleCorsoTipo;
		}
		costiCorsi.Totale = totale;
		setCorsiDaFatturare(costiCorsi);
	};

	const stampaReport = () => {
		const unit = "pt";
		const size = "A4"; // Use A1, A2, A3 or A4
		const orientation = "portrait"; // portrait or landscape

		const marginLeft = 40;
		const doc = new jsPDF(orientation, unit, size);
		var img = new Image();
		img.src = require("../assets/images/MILLERLogo-copia.jpg");

		let mesi = {
			1: "GENNAIO",
			2: "FEBBRAIO",
			3: "MARZO",
			4: "APRILE",
			5: "MAGGIO",
			6: "GIUGNO",
			7: "LUGLIO",
			8: "AGOSTO",
			9: "SETTEMBRE",
			10: "OTTOBRE",
			11: "NOVEMBRE",
			12: "DICEMBRE",
		};

		doc.setFillColor(34, 100, 174);
		doc.rect(0, 0, doc.internal.pageSize.getWidth(), 90, "F");

		doc.addImage(img, "PNG", 10, 10, 220, 70);
		doc.setTextColor(255, 255, 255);

		doc.setFont(undefined, "bold");
		doc.setFontSize(13);
		doc.text(
			"FATTURAZIONE CORSI : " +
				storicoFatturazioneCorsi[selectedIndex].PeriodoFatturazione +
				" " +
				storicoFatturazioneCorsi[selectedIndex].AnnoFatturazione,
			255,
			30
		);
		doc.text(
			doc.splitTextToSize(
				"CLIENTE : " + props.RagioneSociale,
				doc.internal.pageSize.getWidth() - 255
			),
			255,
			50
		);
		doc.setTextColor(0, 0, 0);
		doc.setFontSize(13);
		doc.text("ELENCO CORSI ", 250, 120);
		let content = {
			startY: 180,
			startX: 0,
			tableWidth: "auto",
			columnWidth: "auto",
			headStyles: { fillColor: [34, 100, 174] },
			footStyles: { fillColor: [34, 100, 174] },
			margin: { left: 0, right: 0 },
			// foot: [["", "", "", "Totale", Math.round(totale * 1e12) / 1e12]],
			showFoot: "lastPage",
		};

		for (let i = 0; i < corsiDaFatturare.length; i++) {
			let finalY = 120;

			if (doc.lastAutoTable.finalY) {
				finalY = doc.lastAutoTable.finalY;
			}

			doc.autoTable({
				useCss: true,
				startY: 20 + finalY,
				html: "#mytable" + i,
				headStyles: { fillColor: [124, 95, 240] },
			});
		}

		let finalY = 120;

		if (doc.lastAutoTable.finalY) {
			finalY = doc.lastAutoTable.finalY;
		}

		doc.autoTable({
			useCss: true,
			startY: 20 + finalY,
			html: "#footerTable",
			headStyles: { fillColor: [124, 95, 240] },
		});

		doc.output("pdfobjectnewwindow", "prefatturazione");
	};

	const handleAnnullaStampa = async () => {
		try {
			let responseModify = await unsplash.put(
				"storicoFatturazioneCorsi",
				{
					cliente: props.idAzienda,
					ID: storicoFatturazioneCorsi[selectedIndex].ID,
				},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("jwt")}`,
					},
				}
			);

			setCorsiDaFatturare([]);

			let responseFatturazione = await unsplash.get(
				"storicoFatturazioneCorsi",
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("jwt")}`,
					},
					params: { cliente: props.idAzienda },
				} //props.idAzienda}}
			);

			setStoricoFatturazioneCorsi(responseFatturazione.data.data);
			setStoricoFatturazioneCorsiBackup(responseFatturazione.data.data);
			setSelectedIndex(null);

			setSnack({
				severity: "success",
				messaggio: "Fatturazione annullata con successo",
			});
			setOpenSnack(true);
		} catch {
			setSnack({
				severity: "error",
				messaggio: "Errore durante l'annullamento ",
			});
			setOpenSnack(true);
		}
	};

	const downloadStorico = () => {
		document.getElementById("printLavoratori").click();
	};

	const handleSearch = (e) => {
		// filtro

		setSelectedIndex(null);
		//setAccertamentiStorico([]);
		if (e.target.value == "") {
			setStoricoFatturazioneCorsi(storicoFatturazioneCorsiBackup);
		} else {
			var storicoX = []; // popolo l'array
			var i;
			for (i = 0; i < storicoFatturazioneCorsiBackup.length; i++) {
				if (
					storicoFatturazioneCorsiBackup[
						i
					].PeriodoFatturazione.toLowerCase().includes(
						e.target.value.toLowerCase()
					) ||
					storicoFatturazioneCorsiBackup[
						i
					].AnnoFatturazione.toLowerCase().includes(
						e.target.value.toLowerCase()
					)
				) {
					storicoX.push(storicoFatturazioneCorsiBackup[i]);
				}
			}
			setStoricoFatturazioneCorsi(storicoX);
		}
	};

	return (
		<div>
			<GlobalSnackbar
				severity={snack.severity}
				messaggio={snack.messaggio}
				openSnack={openSnack}
				handleCloseSnack={handleCloseSnack}
			/>
			<ReactHtmlTableToExcel
				id="printLavoratori"
				className="download-table-xls-button-none"
				table="table-to-xls"
				filename={"Scadenzario"}
				sheet={"Scadenzario"}
				style={{ display: "None!important" }}
				buttonText="Download Log"
			/>
			<table
				id="table-to-xls"
				style={{ marginTop: 10, display: "none" }}
				cellSpacing={0}
				border={0}
			>
				<tr>
					{corsiClienteExport.length > 0
						? Object.keys(corsiClienteExport[0])?.map((c, index) => (
								<th key={index}>{c}</th>
						  ))
						: ""}
				</tr>
				{corsiClienteExport?.map((c, index) => (
					<tr key={index}>
						{Object.keys(c).map((o, index) => (
							<>
								{o === "Nome" ||
								o === "Cognome" ||
								o === "Mansione" ||
								o === "Sede" ? (
									<td key={index}>{c[o]}</td>
								) : c[o].Data ? (
									<td key={index} style={{ background: c[o].ColoreScadenza }}>
										{moment(c[o].Data).format("DD/MM/YYYY")}
									</td>
								) : (
									<td
										key={index}
										style={{ background: c[o].ColoreScadenza }}
									></td>
								)}
							</>
						))}
					</tr>
				))}
			</table>
			<Box sx={{ width: "100%" }}>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label="basic tabs example"
						variant="scrollable"
					>
						<Tab iconPosition="start" label="Corsi" {...a11yProps(0)} />
						<Tab
							iconPosition="start"
							label="Storico Fatturazione"
							{...a11yProps(1)}
						/>
					</Tabs>
				</Box>
				<TabPanel value={value} index={0}>
					<Grid
						container
						direction="row"
						spacing={2}
						alignItems="center"
						justifyContent="flex-end"
					>
						<ButtonGroup
							variant="contained"
							aria-label="outlined primary button group"
							sx={{ mb: 1 }}
						>
							<Button
								className="ButtonMiller"
								startIcon={<Add />}
								onClick={() => document.getElementById("add").click()}
							>
								AGGIUNGI
							</Button>
							<Button
								className="ButtonMiller"
								startIcon={<History />}
								onClick={downloadStorico}
							>
								Scadenzario Cliente
							</Button>
						</ButtonGroup>
					</Grid>
					<FormDialog
						id="add"
						operazione="Inserimento"
						reloadData={loadData}
						corsiCliente={corsiCliente}
						corsi={corsi?.filter(
							(corso) => !corsiCliente?.some((c) => c.Corso === corso.Codice)
						)}
						saveFunction={handleSaveData}
					/>

					<DeleteCorsi
						corso={vistaCorsi}
						openValue={openAlert}
						openFunction={toggleAlert}
					/>
					<div style={{ height: 400, width: "100%" }}>
						<DataGridPro
							localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
							components={{
								Toolbar: CustomToolbar,
								Footer: CustomFooterPersonalized,
							}}
							slots={{
								loadingOverlay: LinearProgress,
							}}
							loading={loading}
							initialState={{ pinnedColumns: { left: ["Actions"] } }}
							rows={corsiCliente}
							getRowId={(corsiCliente) => corsiCliente.Codice}
							columns={columns}
							onSelectionModelChange={(ids) => {
								const selectedIDs = new Set(ids);
								const selectedRowData = corsiCliente.filter((row) =>
									selectedIDs.has(row.Codice)
								);
								setCorsoSel(selectedRowData[0]);
							}}
						/>
					</div>
				</TabPanel>
				<TabPanel value={value} index={1}>
					<Grid container spacing={2}>
						<Grid item xs={3}>
							<TextField
								sx={{
									"& legend": { display: "none" },
									"& fieldset": { top: 0 },
								}}
								size="small"
								fullWidth
								id="input-with-icon-textfield"
								onChange={handleSearch}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Search />
										</InputAdornment>
									),
								}}
								variant="outlined"
							/>
							{storicoFatturazioneCorsi.length <= 0 ? (
								<>
									<br /> <br />
									Nessuna prefatturazione presente
								</>
							) : (
								""
							)}
							<Box
								sx={{
									width: "100%",
									maxWidth: 360,
									bgcolor: "background.paper",
								}}
							>
								<List
									component="nav"
									aria-label="main mailbox folders"
									sx={{
										width: "100%",
										maxWidth: 360,
										bgcolor: "background.paper",
										position: "relative",
										overflow: "auto",
										direction: "rtl",
										maxHeight: "55vh",
										marginTop: 1,
										padding: 0,
										borderRadius: "4px",
										"& ul": { padding: 0 },
									}}
								>
									{storicoFatturazioneCorsi.map((t, index) => {
										return (
											<ListItemButton
												selected={selectedIndex === index}
												onClick={(event) => handleListItemClick(event, index)}
												sx={{
													background: "#2265B1",
													color: "white",
													"&:hover": { backgroundColor: "#11526F" },
													"&$selected": {
														backgroundColor: "#FF2768!important",
													},
												}}
												classes={{ selected: "selectedList" }}
											>
												<ListItemText
													primary={
														t.PeriodoFatturazione + " " + t.AnnoFatturazione
													}
												/>
											</ListItemButton>
										);
									})}
								</List>
							</Box>
						</Grid>

						<Grid item xs={9}>
							{corsiDaFatturare.length > 0 ? (
								<>
									<div className="mt-2 mb-4">
										TOTALE : <b>{corsiDaFatturare.Totale}€</b>
									</div>

									<Button
										startIcon={<PictureAsPdf />}
										size="small"
										onClick={stampaReport}
										className="ButtonMiller"
										variant="contained"
										sx={{ marginRight: "10px" }}
									>
										Stampa Fattura
									</Button>
									<Button
										startIcon={<Close />}
										size="small"
										onClick={handleAnnullaStampa}
										color="error"
										variant="contained"
									>
										Annulla Stampa
									</Button>
								</>
							) : (
								""
							)}
							<div className="my-2">
								{corsiDaFatturare?.map((tipoCorso, indexTipoCorso) => (
									<Accordion>
										<AccordionSummary
											expandIcon={<ExpandMore sx={{ color: "white" }} />}
											aria-controls="panel1a-content"
											id="panel1a-header"
											className="AccordionMiller"
										>
											<Typography>
												{tipoCorso.DescrizioneCorso +
													" - TOTALE " +
													tipoCorso.totaleTipoCorso +
													" €"}{" "}
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											{tipoCorso.corsi.map((corso, indexCorsoSpecifico) => (
												<Accordion>
													<AccordionSummary
														expandIcon={<ExpandMore sx={{ color: "white" }} />}
														aria-controls="panel1a-content"
														id="panel1a-header"
														className="AccordionMiller"
													>
														<Typography>
															{"Inizio : " +
																moment(corso.DataInizio).format("DD/MM/YYYY") +
																" - Fine : " +
																moment(corso.DataFine).format("DD/MM/YYYY") +
																"  - Durata : " +
																corso.OreTotaliCorso +
																" h - " +
																corso.ModalitaDescrizione +
																" - " +
																(corso.DescrizioneFatturazione
																	? corso.DescrizioneFatturazione
																	: "Tariffa a Lavoratore") +
																" - "}
														</Typography>
														<Typography>
															{"Tariffa : " +
																(corso.ModalitaFatturazione === 1
																	? corso.Tariffa + " €"
																	: corso.CostoForfait + " €") +
																(corso.Aggiornamento === 1
																	? " - Aggiornamento"
																	: "") +
																" - TOTALE : " +
																corso.totaleCorso +
																" €"}
														</Typography>
													</AccordionSummary>
													<AccordionDetails>
														<TableContainer component={Paper}>
															<Table
																sx={{ minWidth: 650 }}
																aria-label="simple table"
															>
																<TableHead>
																	<TableRow>
																		<TableCell></TableCell>
																		<TableCell>Lavoratore</TableCell>
																		<TableCell>Presenza</TableCell>
																	</TableRow>
																</TableHead>
																{corso.Presenti.map((presente, index) => (
																	<TableRow
																		key={index}
																		sx={{
																			"&:last-child td, &:last-child th": {
																				border: 0,
																			},
																		}}
																	>
																		<TableCell component="th" scope="row">
																			<Circle
																				style={{
																					color:
																						presente.EraPresente === 0
																							? "#FF2768"
																							: "#40C18F",
																				}}
																			/>
																		</TableCell>
																		<TableCell component="th" scope="row">
																			{presente.NomeLavoratore}
																		</TableCell>
																		<TableCell>
																			{presente.EraPresente === 0 ? "NO" : "SI"}
																		</TableCell>
																	</TableRow>
																))}
															</Table>
														</TableContainer>
													</AccordionDetails>
												</Accordion>
											))}
										</AccordionDetails>
									</Accordion>
								))}

								{corsiDaFatturare.map((corso, index) => (
									<>
										<Table
											sx={{
												minWidth: 650,
												border: "1px solid black",
												display: "None",
											}}
											aria-label="simple table"
											id={"mytable" + index}
											size="small"
										>
											<TableHead>
												<TableRow>
													<TableCell className="tableHeaderMiller">
														{corso.DescrizioneCorso}{" "}
													</TableCell>
													<TableCell
														className="tableHeaderMiller"
														sx={{ textAlign: "right!important" }}
													>
														{" "}
														{"TOTALE " + corso.totaleTipoCorso + " €"}
													</TableCell>
												</TableRow>
											</TableHead>

											{corso.corsi.map((corsoNelDettaglio) => {
												let head = "";

												if (corsoNelDettaglio.MostraInStampa === 1) {
													if (
														corsoNelDettaglio.DataInizio ===
														corsoNelDettaglio.DataFine
													) {
														head =
															"Data Corso : " +
															moment(corsoNelDettaglio.DataInizio).format(
																"DD/MM/YYYY"
															);
													} else {
														head =
															"Inizio : " +
															moment(corsoNelDettaglio.DataInizio).format(
																"DD/MM/YYYY"
															) +
															"Fine : " +
															moment(corsoNelDettaglio.DataFine).format(
																"DD/MM/YYYY"
															);
													}

													return (
														<>
															<TableRow>
																<TableCell
																	colspan={3}
																	className="tableSubHeaderMiller"
																>
																	{head +
																		" Durata : " +
																		corsoNelDettaglio.OreTotaliCorso +
																		" h - " +
																		(corsoNelDettaglio.DescrizioneFatturazione
																			? corsoNelDettaglio.DescrizioneFatturazione
																			: "Tariffa a Lavoratore") +
																		" - " +
																		corsoNelDettaglio.ModalitaDescrizione +
																		(corsoNelDettaglio.Aggiornamento === 1
																			? " - Aggiornamento"
																			: "")}{" "}
																</TableCell>
															</TableRow>
															{corsoNelDettaglio.Presenti.map((lavoratore) => {
																if (
																	lavoratore.EraPresente === 1 &&
																	(!corsoNelDettaglio.DescrizioneFatturazione ||
																		corsoNelDettaglio.DescrizioneFatturazione ===
																			"Tariffa a lavoratore")
																) {
																	return (
																		<TableRow>
																			<TableCell className="tableTextMiller">
																				{lavoratore.NomeLavoratore}{" "}
																			</TableCell>
																			<TableCell className="tableTextMiller">
																				{corsoNelDettaglio.Tariffa + " €"}{" "}
																			</TableCell>
																		</TableRow>
																	);
																}
																if (
																	lavoratore.EraPresente === 1 &&
																	(corsoNelDettaglio.DescrizioneFatturazione ||
																		corsoNelDettaglio.DescrizioneFatturazione ===
																			"Forfait")
																) {
																	return (
																		<TableRow>
																			<TableCell
																				colspan={3}
																				className="tableTextMiller"
																			>
																				{lavoratore.NomeLavoratore}{" "}
																			</TableCell>
																		</TableRow>
																	);
																}
															})}
															<TableRow>
																<TableCell
																	colspan={3}
																	className="tableHeaderMiller"
																	sx={{ textAlign: "right!important" }}
																>
																	<b>
																		{(corsoNelDettaglio?.DescrizioneFatturazione ===
																		"Forfait"
																			? "IMPORTO FORFAIT ORARIO " +
																			  corsoNelDettaglio.CostoForfait +
																			  " € -"
																			: "") +
																			" TOTALE CORSO " +
																			corsoNelDettaglio.totaleCorso +
																			" €"}{" "}
																	</b>
																</TableCell>
															</TableRow>
														</>
													);
												}
											})}
										</Table>
									</>
								))}
							</div>
							<Table
								sx={{
									minWidth: 650,
									border: "1px solid black",
									display: "none",
								}}
								aria-label="simple table"
								id={"footerTable"}
							>
								<TableHead>
									<TableRow>
										<TableCell
											colspan={3}
											className="tableHeaderMiller"
											sx={{ textAlign: "right!important" }}
										>
											{" TOTALE DA FATTURARE " + corsiDaFatturare.Totale + " €"}{" "}
										</TableCell>
									</TableRow>
								</TableHead>
							</Table>
						</Grid>
					</Grid>
				</TabPanel>
			</Box>
		</div>
	);
};

const FormDialog = (props) => {
	const [open, setOpen] = useState(false);
	const [corsoSel, setCorsoSel] = useState("");
	const [scadenza, setScadenza] = useState("");
	const [importi, setImporti] = useState({
		Importo: 0.0,
		ImportoAggiornamento: 0.0,
		ImportoEL: 0.0,
		ImportoAggiornamentoEL: 0.0,
		ScadenzaPersonalizzata: 0,
	});

	const handleClickOpen = () => {
		if (props.operazione === "Inserimento") {
			setCorsoSel({ Codice: "Codice", Descrizione: "Descrizione" });
			setImporti({
				Importo: 0.0,
				ImportoAggiornamento: 0.0,
				ImportoEL: 0.0,
				ImportoAggiornamentoEL: 0.0,
				ScadenzaPersonalizzata: 0,
			});
		} else {
			let codCorso = props.corsi.filter(
				(c) => c.Corso === props.corsoSel.Codice
			)[0];
			setCorsoSel(codCorso);
			setImporti({
				Importo: props.corsoSel.Importo,
				ImportoAggiornamento: props.corsoSel.ImportoAggiornamento,
				ImportoEL: props.corsoSel.ImportoEL,
				ImportoAggiornamentoEL: props.corsoSel.ImportoAggiornamentoEL,
				ScadenzaPersonalizzata: props.corsoSel.ScadenzaPersonalizzata,
			});
		}

		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSave = () => {
		props.saveFunction(corsoSel, importi, props.operazione);
	};

	const handleChangeImporti = (e) => {
		let i = { ...importi };
		i[e.target.name] = e.target.value;
		setImporti(i);
	};

	return (
		<>
			<IconButton
				aria-label="edit"
				onClick={handleClickOpen}
				id={props.id}
				style={{ display: props.id === "edit" ? "" : "none" }}
			>
				<Edit
					style={{
						height: "2rem",
						width: "2rem",
						color: "#2265B1",
					}}
				/>
			</IconButton>
			<Dialog
				open={open}
				onClose={handleClose}
				fullWidth="sm"
				TransitionComponent={Transition}
			>
				<DialogTitle>Associa Corso</DialogTitle>
				<DialogContent sx={{ margin: "10px" }}>
					<Grid container xs={12} spacing={2}>
						<Grid item xs={12}>
							<Autocomplete
								id="combo-box-demo"
								options={props.corsi}
								value={corsoSel}
								disabled={props.operazione === "Modifica"}
								getOptionLabel={(option) =>
									option.Codice + " - " + option.Descrizione
								}
								fullWidth
								onChange={(event, newValue) => {
									setCorsoSel(newValue);
									if (newValue?.PeriodicitaAggiornamento === 1) {
										setScadenza(newValue.Scadenza);
									}
									//handleChangeCliente(newValue);
								}}
								renderInput={(params) => (
									<TextField {...params} fullWidth label="Corso" />
								)}
								sx={{ mt: 1 }}
							/>
						</Grid>
						{corsoSel?.PeriodicitaAggiornamento === 1 ? (
							<Grid item xs={12}>
								<TextField
									value={importi.ScadenzaPersonalizzata}
									margin="dense"
									name="ScadenzaPersonalizzata"
									label="Scadenza Personalizzata"
									type="number"
									fullWidth
									variant="outlined"
									onChange={handleChangeImporti}
								/>
							</Grid>
						) : (
							<></>
						)}
						<Grid item xs={6}>
							<TextField
								value={importi.Importo}
								margin="dense"
								name="Importo"
								label="Importo"
								type="number"
								fullWidth
								variant="outlined"
								onChange={handleChangeImporti}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								value={importi.ImportoAggiornamento}
								margin="dense"
								name="ImportoAggiornamento"
								label="Aggiornamento"
								type="number"
								fullWidth
								variant="outlined"
								onChange={handleChangeImporti}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								value={importi.ImportoEL}
								margin="dense"
								name="ImportoEL"
								label="Importo E-Learning"
								type="number"
								fullWidth
								variant="outlined"
								onChange={handleChangeImporti}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								value={importi.ImportoAggiornamentoEL}
								margin="dense"
								name="ImportoAggiornamentoEL"
								label="Aggiornamento E-Learning"
								type="number"
								fullWidth
								onChange={handleChangeImporti}
								variant="outlined"
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions
					sx={{
						display: "flex",
						justifyContent: "space-between",
						pl: 4,
						pr: 6,
					}}
				>
					<Button
						variant="contained"
						className="ButtonMiller"
						startIcon={<Close />}
						onClick={handleClose}
					>
						Chiudi
					</Button>
					<Button
						variant="contained"
						className="ButtonMiller"
						startIcon={<Check />}
						onClick={handleSave}
					>
						Salva
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

const DeleteDialog = (props) => {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<IconButton aria-label="edit" onClick={handleClickOpen}>
				<Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
			</IconButton>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				TransitionComponent={Transition}
			>
				<DialogTitle id="alert-dialog-title">
					Sei sicuro di voler eliminare la sede selezionata?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Attenzione, sei sicuro di voler eliminare la seguente sede:
						<br />
						<b>{props.sede + " - " + props.blocco}</b>?
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{
						display: "flex",
						justifyContent: "space-between",
						px: 2,
					}}
				>
					<Button onClick={handleClose}>annulla</Button>
					<Button onClick={() => props.myFunction(props.id)}>Conferma</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default CorsiCliente;

const DeleteCorsi = (props) => {
	const [openSnackDel, setOpenSnackDel] = useState(false);
	const [snackDel, setSnackDel] = useState({
		severity: "success",
		messaggio: "Eliminazione effettuata correttamente",
		open: false,
	});
	const handleCloseSnackDel = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnackDel(false);
	};
	const handleDelete = async () => {
		try {
			await unsplash.delete("corsiCliente", {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("jwt")}`,
				},
				data: { id: props.corso.ID },
			});
			setSnackDel({
				severity: "success",
				messaggio: "Eliminazione effettuata correttamente",
			});
			setOpenSnackDel(true);
		} catch {
			setSnackDel({
				severity: "error",
				messaggio: "Errore durante l'eliminazione",
			});
			setOpenSnackDel(true);
		}
		props.openFunction(true);
	};
	return (
		<>
			<GlobalSnackbar
				severity={snackDel.severity}
				messaggio={snackDel.messaggio}
				openSnack={openSnackDel}
				handleCloseSnack={handleCloseSnackDel}
			/>
			<Dialog
				open={props.openValue}
				onClose={props.openFunction}
				maxWidth="md"
				TransitionComponent={Transition}
			>
				{props.corso ? (
					<DialogTitle>
						Sei sicuro di voler eliminare {props.corso.Descrizione}?
					</DialogTitle>
				) : (
					<DialogTitle>Sei sicuro di voler eliminare?</DialogTitle>
				)}
				<DialogContent
					sx={{ display: "flex", justifyContent: "space-between" }}
				>
					<Button onClick={props.openFunction}>ANNULLA</Button>
					<Button onClick={handleDelete}>ELIMINA</Button>
				</DialogContent>
			</Dialog>
		</>
	);
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}
