import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import unsplash from "../../api/unsplash";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import {
  IconButton,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Autocomplete,
  Zoom,
  LinearProgress,
  Box,
} from "@mui/material";
import "../../style/style.css";
import {
  Edit,
  Delete,
  Add,
  Download,
  RemoveRedEye,
  Close,
  Check,
} from "@mui/icons-material";
import { LocalizationProvider,DatePicker,PickersDay,TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment as DateAdapter} from '@mui/x-date-pickers/AdapterMoment'
import moment from "moment-timezone";
import GlobalSnackbar from "../Snackbar";
import Allegati from "../Allegati";
import { CustomFooterPersonalized } from "../../utils/CustomFooterPersonalized";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <GridToolbarQuickFilter placeholder="Cerca..." />
      </Grid>
    </GridToolbarContainer>
  );
}

const ext = {
  doc: "application/msword",
  dot: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  dotx: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  docm: "application/vnd.ms-word.document.macroEnabled.12",
  dotm: "application/vnd.ms-word.template.macroEnabled.12",
  xls: "application/vnd.ms-excel",
  xlt: "application/vnd.ms-excel",
  xla: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xltx: "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  xlsm: "application/vnd.ms-excel.sheet.macroEnabled.12",
  xltm: "application/vnd.ms-excel.template.macroEnabled.12",
  xlam: "application/vnd.ms-excel.addin.macroEnabled.12",
  xlsb: "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  ppt: "application/vnd.ms-powerpoint",
  pot: "application/vnd.ms-powerpoint",
  pps: "application/vnd.ms-powerpoint",
  ppa: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  potx: "application/vnd.openxmlformats-officedocument.presentationml.template",
  ppsx: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  ppam: "application/vnd.ms-powerpoint.addin.macroEnabled.12",
  pptm: "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  potm: "application/vnd.ms-powerpoint.template.macroEnabled.12",
  ppsm: "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
  mdb: "application/vnd.ms-access",
  pdf: "application/pdf",
};

const Sopralluoghi = (props) => {
  const [sopralluoghi, setSopralluoghi] = useState([]);
  const [loading, setLoading] = useState(true);
  const [corsi, setCorsi] = useState();
  const [sedi, setSedi] = useState();
  const [medici, setMedici] = useState();
  const [datiFatturazione, setDatiFatturazione] = useState(null);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <FormDialog
            id="editElenco"
            operazione="Edit"
            title="Elenco sopralluoghi"
            sede={params.row}
            corsi={[]}
            saveFunction={handleSaveData}
            loadSopralluoghi={loadSopralluoghi}
            sedi={sedi}
            medici={medici}
          />
          {/* <IconButton
            aria-label="delete"
            onClick={async () => {
              await setVistaCorsi(params.row);
              toggleAlert();
            }}
          >
            <Delete
              style={{ height: "2rem", width: "2rem", color: "#FF2768" }}
            />
          </IconButton> */}
        </>
      ),
    },
    {
      field: "Descrizione",
      headerName: "Sede",
      flex: 1,

      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Ubicazione",
      headerName: "Città",
      flex: 1,

      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Via",
      headerName: "Via",
      flex: 1,

      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Cap",
      headerName: "Cap",
      flex: 1,

      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "UltimaScadenza",
      headerName: "Scadenza",
      flex: 1,

      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => {
        let today = moment(new Date()).format("YYYY-MM-DD");
        let scadenza = params.row.UltimaScadenza
          ? moment(params.row.UltimaScadenza).format("YYYY-MM-DD")
          : null;
        let color =
          today < scadenza
            ? moment(params.row.UltimaScadenza).diff(
                moment(new Date()),
                "days"
              ) <= 60
              ? "orange"
              : "green"
            : "red";
        return (
          <div style={{ color: color }}>
            {params.row.UltimaScadenza
              ? moment(params.row.UltimaScadenza).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },
  ];
  const loadSopralluoghi = async () => {
    let responseTariffe = await unsplash.get("fatturazioneMedicina", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { cliente: props.idAzienda },
    });
    setDatiFatturazione(responseTariffe.data.data.datiCliente[0]);
    let responsecorsiCliente = await unsplash.get("sopralluoghi", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: props.idAzienda },
    });
    setSopralluoghi(responsecorsiCliente.data.data);
    setLoading(false);
    let responsecorsi = await unsplash.get(
      "anagraficaCorsi",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      } //props.idAzienda}}
    );

    setCorsi(responsecorsi.data.data);

    let responseSedi = await unsplash.get("sedi", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: props.idAzienda },
    });

    setSedi(responseSedi.data.data);

    let responseMedici = await unsplash.get(
      "medici",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { id: props.idAzienda },
      } //props.idAzienda}}
    );

    setMedici(responseMedici.data.data);
  };

  useEffect(() => {
    (() => {
      loadSopralluoghi();
    })();
  }, []);

  const handleSaveData = async (sopralluogo, operazione) => {
    try {
      if (operazione === "New") {
        sopralluogo.Cliente = props.idAzienda;

        await unsplash.post(
          "sopralluoghi",
          { sopralluogo: sopralluogo },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Sopralluogo inserito con successo",
        });
        setOpenSnack(true);
        loadSopralluoghi();
      } else {
        let sop = { ...sopralluogo };
        sop.Data = moment(sop.Data).format("YYYY-MM-DD");
        sop.Scadenza = moment(sop.Scadenza).format("YYYY-MM-DD");
        await unsplash.put(
          "sopralluoghi",
          { sopralluogo: sop },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        setTimeout(async () => {
          let responseSopralluoghi = await unsplash.get(
            "sopralluoghi",
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
              params: { id: props.idAzienda },
            } //props.idAzienda}}
          );

          setSopralluoghi(responseSopralluoghi.data.data);
          setLoading(false);
        }, 1000);
        setSnack({
          severity: "success",
          messaggio: "Sopralluogo aggiornato con successo",
        });
        setOpenSnack(true);
      }

      //inserisco il nuovo
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'inserimento",
      });
      setOpenSnack(true);
    }
  };

  const deleteSede = async (id) => {
    try {
      await unsplash.delete("sedi", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: id,
        },
      });

      await unsplash.get("sedi", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { id: props.idAzienda },
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />

      <Grid
        container
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="flex-end"
      >
        <FormDialogSopralluoghi
          id="add"
          operazione="New"
          title="Crea Sopralluogo"
          corsiCliente={sopralluoghi}
          corsi={[]}
          saveFunction={handleSaveData}
          sedi={sedi}
          medici={medici}
          Importo={datiFatturazione?.ImportoSopralluogo}
          Rimborso={datiFatturazione?.RimborsoSopralluogo}
        />
        <Button
          startIcon={<Add />}
          onClick={() => document.getElementById("add").click()}
          sx={{ color: "white" }}
          variant="outlined"
          className="ButtonMiller"
        >
          AGGIUNGI
        </Button>
      </Grid>

      <Box sx={{ height: "60vh", width: "100%" }}>
        <DataGridPro
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooterPersonalized,
          }}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={loading}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rows={sopralluoghi}
          getRowId={(sopralluoghi) => sopralluoghi.ID}
          columns={columns}
          initialState={{
            pinnedColumns: { left: ["Actions"] },
          }}
        />
      </Box>
    </>
  );
};

const FormDialog = (props) => {
  const [open, setOpen] = useState(false);
  const [sopralluoghi, setSopralluoghi] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <FormDialogSopralluoghi
            style={{ display: "None" }}
            id="edit"
            operazione="Edit"
            title="Modifica Sopralluogo"
            sopralluogo={params.row}
            //corsiCliente={sopralluoghi}
            corsi={[]}
            //saveFunction={props.saveFunction}
            saveFunction={saveData}
            sedi={props.sedi}
            medici={props.medici}
            loadSopralluoghi={loadSopralluoghi}
          />
          <DeleteDialog sopralluogo={params.row} myFunction={handleDelete} />
        </>
      ),
    },
    {
      field: "Data",
      headerName: "Data",
      flex: 1,

      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => {
        return (
          <div>
            {params.row.Data
              ? moment(params.row.Data).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },
    {
      field: "Scadenza",
      headerName: "Scadenza",
      flex: 1,

      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => {
        return (
          <div>
            {params.row.Scadenza
              ? moment(params.row.Scadenza).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },
    { field: "Rimborso", headerName: "Rimborso", width: 100 },
    { field: "Importo", headerName: "Importo", width: 200 },
    { field: "NomeMedico", headerName: "Nome Medico", width: 200 },
  ];

  const handleClickOpen = () => {
    loadSopralluoghi();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveData = async (sopralluogo) => {
    try {
      let sop = { ...sopralluogo };
      sop.Data = moment(sop.Data).format("YYYY-MM-DD");
      sop.Scadenza = moment(sop.Scadenza).format("YYYY-MM-DD");
      await unsplash.put(
        "sopralluoghi",
        { sopralluogo: sop },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      setSnack({
        severity: "success",
        messaggio: "Sopralluogo aggiornato con successo",
      });
      setOpenSnack(true);
      loadSopralluoghi();
      props.loadSopralluoghi();
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'inserimento",
      });
      setOpenSnack(true);
    }
  };

  const handleDelete = async (ID) => {
    try {
      await unsplash.delete("sopralluoghi", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: ID,
        },
      });

      loadSopralluoghi();
      props.loadSopralluoghi();
      setSnack({
        severity: "success",
        messaggio: "Sopralluogo eliminato con successo",
      });
      setOpenSnack(true);
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante la cancellazione",
      });
      setOpenSnack(true);
    }
  };

  const loadSopralluoghi = async () => {
    let response = await unsplash.get("sopralluoghi", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { sede: props.sede.ID },
    });
    setSopralluoghi(response.data.data);
    setLoading(false);
  };

  return (
    <div>
      {/* <FormDialogSopralluoghi
        style={{ display: "None" }}
        id="edit"
        operazione="Edit"
        title="Modifica Sopralluogo"
        sopralluogo={sopralluogoSel}
        //corsiCliente={sopralluoghi}
        corsi={[]}
        //saveFunction={handleSaveData}
        sedi={props.sedi}
        medici={props.medici}
      /> */}
      <IconButton aria-label="edit" onClick={handleClickOpen} id={props.id}>
        <Edit
          style={{
            height: "2rem",
            width: "2rem",
            color: "#2265B1",
            display: props.operazione === "Edit" ? "" : "none",
          }}
        />
      </IconButton>
      {/*<EditName id="editDescrizione" operazione="Modifica" allegato={allegato} saveFunction={handleSaveData}/>*/}
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        id={props.id}
        style={{ display: "None" }}
      >
        Open form dialog
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth="lg"
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <GlobalSnackbar
          severity={snack.severity}
          messaggio={snack.messaggio}
          openSnack={openSnack}
          handleCloseSnack={handleCloseSnack}
        />
        <DialogTitle>{props.title + " " + props.sede.Descrizione}</DialogTitle>
        <DialogContent style={{ paddingTop: "20px" }}>
          <div style={{ height: 400, width: "100%" }}>
            <DataGridPro
              components={{
                Toolbar: CustomToolbar,
                Footer: CustomFooterPersonalized,
              }}
              slots={{
                loadingOverlay: LinearProgress,
              }}
              loading={loading}
              localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
              rows={sopralluoghi}
              getRowId={(s) => s.ID}
              columns={columns}
              disableSelectionOnClick
              initialState={{
                pinnedColumns: { left: ["Actions"] },
              }}
              /* onRowDoubleClick={async (params, event) => {
                await setSopralluogoSel(params.row);
                document.getElementById("edit").click();
              }} */
            />
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            mt: 2,
            justifyContent: "flex-start",
            px: 3,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
          >
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const DeleteDialog = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
      >
        <DialogTitle id="alert-dialog-title">
          Sei sicuro di voler eliminare la sede selezionata?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare la seguente sopralluogo:{" "}
            <br />
            Data <b>{moment(props?.sopralluogo?.Data).format("YYYY-MM-DD")}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 2,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={() => props.myFunction(props.sopralluogo?.ID)}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const FormDialogSopralluoghi = (props) => {
  const [open, setOpen] = useState(false);
  const [medicoSel, setMedicoSel] = useState(null);
  const [sedeSel, setSedeSel] = useState(null);
  const [allegato, setAllegato] = useState();
  const [sopralluogo, setSopralluogo] = useState({
    Cantiere: null,
    Data: null,
    Scadenza: null,
    Durata: 0,
    Importo: 0,
    Medico: null,
    Cliente: 0,
  });
  const [allegati, setAllegati] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const columns = [
    {
      filterable: false,
      field: "Azioni",
      headerName: "Azioni",
      hide: false,
      width: "200",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={async () => {
              await setAllegato({
                ID: params.row.ID,
                Descrizione: params.row.Descrizione,
              });
              document.getElementById("editDescrizione").click();
            }}
          >
            <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
          </IconButton>
          <IconButton
            aria-label="edit"
            onClick={() =>
              downloadFile(
                params.row.ID,
                params.row.Estensione,
                params.row.Descrizione,
                "download"
              )
            }
          >
            <Download
              style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
            />
          </IconButton>
          {params.row.Estensione === ".pdf" ? (
            <IconButton
              aria-label="edit"
              onClick={() =>
                downloadFile(
                  params.row.ID,
                  params.row.Estensione,
                  params.row.Descrizione,
                  "visualizza"
                )
              }
            >
              <RemoveRedEye
                style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
              />
            </IconButton>
          ) : (
            ""
          )}
          <IconButton
            aria-label="edit"
            onClick={() =>
              handleDelete(params.row.ID, params.row.Estensione, "delete")
            }
          >
            <Delete
              style={{ height: "2rem", width: "2rem", color: "#FF2768" }}
            />
          </IconButton>
        </>
      ),
    },
    { field: "Descrizione", headerName: "Descrizione", width: 500 },
    { field: "Estensione", headerName: "Estensione", width: 200 },
    { field: "ID", headerName: "", hide: true, width: "130" },
  ];

  const downloadFile = async (id, estensione, descrizione, operazione) => {
    await unsplash.post(
      "ftp",
      {
        file: id + estensione,
        nomeOriginale: descrizione.includes(estensione)
          ? descrizione
          : descrizione + estensione,
        operazione: "download",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );

    await unsplash
      .get("ftp", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        responseType: "blob",
        params: {
          nomeOriginale: descrizione.includes(estensione)
            ? descrizione
            : descrizione + estensione,
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: ext[estensione.replace(".", "")] })
        ); //prendo il file, lo rendo blob lo aggancio a un link e scarico

        const link = document.createElement("a");
        link.href = url;
        if (operazione === "download") {
          link.setAttribute("download", descrizione + estensione);
          document.body.appendChild(link);
          link.click();
        }
        if (operazione === "visualizza") {
          var win = window.open();
          win.document.write(
            '<iframe width="560" height="315" src="https://docs.google.com/gview?url=' +
              url +
              '&embedded=true" frameborder="0" allowfullscreen></iframe>'
          );
        }
      });
  };

  const handleClickOpen = () => {
    if (props.operazione === "New") {
      setSopralluogo({
        Cantiere: null,
        Data: null,
        Scadenza: null,
        Durata: 0,
        Importo: props.Importo ? props.Importo : 0,
        Rimborso: props.Rimborso ? props.Rimborso : 0,
        Medico: null,
        Cliente: 0,
      });
      setMedicoSel(null);
      setSedeSel(null);
    } else {
      setSopralluogo(props.sopralluogo);
      if (props.sopralluogo) {
        setMedicoSel(
          props.medici.filter((m) => m.ID === props.sopralluogo.Medico)[0]
        );

        let sede = props.sedi.filter(
          (s) => s.Descrizione === props.sopralluogo.Cantiere
        );

        setSedeSel(
          props.sedi.filter((s) => s.Descrizione === props.sopralluogo.Sede)[0]
        );
        //loadAllegati(props.sopralluogo.ID);
      }
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadAllegati = async (id) => {
    let response = await unsplash.get("allegati", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { colonna: "Sopralluogo", ID: id },
    });
    setAllegati(response.data.data);
    setLoading(false);
  };

  const handleSave = () => {
    props.saveFunction(sopralluogo, props.operazione);
    /* if (props.operazione === "Edit") {
      props.loadSopralluoghi();
    } */
    setOpen(false);
  };

  const handleDelete = async (id, estensione, operazione) => {
    try {
      await unsplash.delete("allegati", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: id,
        },
      });
      await unsplash.post(
        "ftp",
        { file: id + estensione, operazione: "delete" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({
        severity: "success",
        messaggio: "Soprslluogo eliminato con successo",
      });
      setOpenSnack(true);
      loadAllegati(props.sopralluogo.ID);
    } catch {}
  };

  const handleSaveData = async (allegato) => {
    try {
      let responseModifica = await unsplash.put(
        "allegati",
        { nome: allegato.Descrizione, id: allegato.ID },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({
        severity: "success",
        messaggio: "Modifica effettuata con successo",
      });
      setOpenSnack(true);
      loadAllegati(props.sopralluogo.ID);
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante la modifica",
      });
      setOpenSnack(true);
    }
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <IconButton aria-label="edit" onClick={handleClickOpen} id={props.id}>
        <Edit
          style={{
            height: "2rem",
            width: "2rem",
            color: "#2265B1",
            display: props.operazione === "Edit" ? "" : "none",
          }}
        />
      </IconButton>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        id={props.id}
        style={{ display: "None" }}
      >
        Open form dialog
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth="md"
        maxWidth="md"
        TransitionComponent={Transition}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent style={{ paddingTop: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box-demo"
                options={props.sedi}
                value={sedeSel}
                getOptionLabel={(option) => option.Descrizione}
                fullWidth
                onChange={(event, newValue) => {
                  let s = { ...sopralluogo };
                  s.Cantiere = newValue ? newValue.ID : null;
                  setSedeSel(newValue);
                  setSopralluogo(s);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Sede" />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box-demo"
                value={medicoSel}
                options={props.medici}
                getOptionLabel={(option) => option.Nome + " " + option.Cognome}
                fullWidth
                onChange={(event, newValue) => {
                  let s = { ...sopralluogo };
                  s.Medico = newValue ? newValue.ID : null;
                  setSopralluogo(s);
                  setMedicoSel(newValue);
                  //handleChangeCliente(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Medici" />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  ampmInClock={false}
                  renderInput={(props) => (
                    <TextField
                      sx={{ marginRight: "10px" }}
                      {...props}
                      fullWidth
                    />
                  )}
                  label="Data"
                  value={moment(sopralluogo?.Data)}
                  onChange={(newValue) => {
                    let s = { ...sopralluogo };
                    s.Data = newValue ? newValue.format("YYYY-MM-DD") : null;
                    s.Scadenza = newValue
                      ? newValue.add(1, "Y").format("YYYY-MM-DD")
                      : null;
                    setSopralluogo(s);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  value={moment(sopralluogo?.Scadenza)}
                  ampmInClock={false}
                  renderInput={(props) => (
                    <TextField
                      sx={{ marginRight: "10px" }}
                      {...props}
                      fullWidth
                    />
                  )}
                  label="Scadenza"
                  onChange={(newValue) => {
                    let s = { ...sopralluogo };
                    s.Scadenza = newValue
                      ? newValue.format("YYYY-MM-DD")
                      : null;
                    setSopralluogo(s);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="standard-basic"
                fullWidth
                type="number"
                value={sopralluogo?.Importo}
                name="Importo"
                label="Importo"
                onChange={(e) => {
                  let s = { ...sopralluogo };
                  s.Importo = e.target.value;
                  setSopralluogo(s);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="standard-basic"
                fullWidth
                type="number"
                value={sopralluogo?.Rimborso}
                name="Rimborso"
                label="Rimborso"
                onChange={(e) => {
                  let s = { ...sopralluogo };
                  s.Rimborso = e.target.value;
                  setSopralluogo(s);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="standard-basic"
                fullWidth
                type="number"
                value={sopralluogo?.Durata}
                name="Durata"
                label="Durata"
                onChange={(e) => {
                  let s = { ...sopralluogo };
                  s.Durata = e.target.value;
                  setSopralluogo(s);
                }}
              />
            </Grid>
            {props.operazione === "Edit" ? (
              <Grid item xs={12}>
                Documenti :{" "}
                {/*<FileUploader colonna="Sopralluogo" id={sopralluogo.ID} reloadAfterUpload={() => loadAllegati(props.sopralluogo.ID)}/> pò*/}
                <br />
                <Allegati
                  id={sopralluogo?.ID}
                  colonna="Sopralluogo"
                  idSopralluogo={sopralluogo?.ID}
                  loadAllegati={loadAllegati}
                />
                <div style={{ height: 200, width: "100%", display: "None" }}>
                  <DataGridPro
                    components={{
                      Toolbar: CustomToolbar,
                      Footer: CustomFooterPersonalized,
                    }}
                    slots={{
                      loadingOverlay: LinearProgress,
                    }}
                    loading={loading}
                    localeText={
                      itIT.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={allegati}
                    getRowId={(allegati) => allegati.ID}
                    columns={columns}
                    initialState={{ pinnedColumns: { left: ["Actions"] } }}
                    disableSelectionOnClick
                  />
                </div>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={handleSave}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default Sopralluoghi;
