import React, { useState, useEffect } from "react";

import moment from "moment";
import "moment/locale/it";
import {
  LocalizationProvider,
  DatePicker,
  PickersDay,
} from "@mui/x-date-pickers";
import { AdapterMoment as DateAdapter } from "@mui/x-date-pickers/AdapterMoment";

import unsplash from "../../api/unsplash";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  LinearProgress,
  Badge,
} from "@mui/material";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  itIT,
} from "@mui/x-data-grid-pro";

import { Cancel, CheckCircle } from "@mui/icons-material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { CustomFooterPersonalized } from "../../utils/CustomFooterPersonalized";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const VisiteMediche = () => {
  const [value, setValue] = useState(new Date());
  const [daysWithDot, setDaysWithDot] = useState([]);
  const [visiteVisibili, setVisiteVisibili] = useState([]);
  const [visiteVisibiliBk, setVisiteVisibiliBk] = useState([]);

  const [allVisite, setAllVisite] = useState([]);
  const [loading, setLoading] = useState(true);
  const [enableAllVisite, setEnableAllVisite] = useState(false);

  const [columns, setColumns] = useState([]);

  const [filterClienti, setFilterClienti] = useState([]);

  const loadVisite = async () => {
    setValue(new Date());
    let responseVisite = await unsplash.get("visiteMediche", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { utente: sessionStorage.getItem("User") },
    });
    setDaysWithDot(
      responseVisite.data.data.map((v) => moment(v.DATA).format("YYYY-MM-DD"))
    );

    if (sessionStorage.getItem("Dettaglio") === "1") {
      setVisiteVisibili(
        responseVisite.data.data.filter(
          (v) =>
            moment(v.DATA).format("YYYY-MM-DD") ===
              moment(new Date()).format("YYYY-MM-DD") &&
            sessionStorage.getItem("Email") === v.EmailReferente
        )
      );
      setVisiteVisibiliBk(
        responseVisite.data.data.filter(
          (v) =>
            moment(v.DATA).format("YYYY-MM-DD") ===
              moment(new Date()).format("YYYY-MM-DD") &&
            sessionStorage.getItem("Email") === v.EmailReferente
        )
      );
      setAllVisite(
        responseVisite.data.data.filter(
          (v) => sessionStorage.getItem("Email") === v.EmailReferente
        )
      );
      const ragSoc = responseVisite.data.data
        .filter((v) => sessionStorage.getItem("Email") === v.EmailReferente)
        .map((el) => el.Cliente);
      const a = new Set(ragSoc);
      setFilterClienti([...a]);
    } else {
      setVisiteVisibili(
        responseVisite.data.data.filter(
          (v) =>
            moment(v.DATA).format("YYYY-MM-DD") ===
            moment(new Date()).format("YYYY-MM-DD")
        )
      );
      setVisiteVisibiliBk(
        responseVisite.data.data.filter(
          (v) =>
            moment(v.DATA).format("YYYY-MM-DD") ===
            moment(new Date()).format("YYYY-MM-DD")
        )
      );
      setAllVisite(responseVisite.data.data);
      const ragSoc = responseVisite.data.data.map((el) => el.Cliente);
      const a = new Set(ragSoc);
      setFilterClienti([...a]);
    }

    setColumns([
      {
        field: "CF",
        headerName: "CF",
        headerAlign: "center",
        align: "center",
        width: 200,
      },
      {
        field: "DescrizioneSede",
        headerName: "Sede",
        headerAlign: "center",
        align: "center",
        width: 200,
      },
      {
        field: "Cliente",
        headerName: "Ragione Sociale",
        headerAlign: "center",
        align: "center",
        width: 200,
      },
      {
        field: "LavoratoreCognome",
        headerName: "Cognome",
        headerAlign: "center",
        align: "center",
        width: 200,
      },
      {
        field: "LavoratoreNome",
        headerName: "Nome",
        headerAlign: "center",
        align: "center",
        width: 200,
      },
      //condizione per vedere se il gruppo del cliente è Conter
      ...(responseVisite.data.data[0]?.Gruppo === "Conter"
        ? [
            {
              field: "Mansione",
              headerName: "Classe di Rischio",
              headerAlign: "center",
              align: "center",
              width: 150,
            },
            {
              field: "Somministrato",
              headerName: "Somministrato",
              headerAlign: "center",
              align: "center",
              width: 100,
              renderCell: (params) => {
                if (params.row.Somministrato === 1)
                  return <CheckCircle sx={{ color: "#51E838" }} />;
                if (
                  params.row.Somministrato === 0 ||
                  params.row.Somministrato === null
                )
                  return <Cancel sx={{ color: "#ff5757" }} />;
              },
            },
          ]
        : [
            {
              field: "Mansione",
              headerName: "Mansione",
              headerAlign: "center",
              align: "center",
              width: 150,
            },
          ]),
      {
        field: "DATA",
        headerName: "Data",
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <div>
            {params.row.DATA
              ? moment(params.row.DATA).format("DD/MM/YYYY")
              : ""}
          </div>
        ),
        width: 150,
      },
      {
        field: "Medico",
        headerName: "Medico",
        headerAlign: "center",
        align: "center",
        width: 200,
      },
      {
        field: "Accertamenti",
        headerName: "Accertamenti",
        headerAlign: "center",
        align: "center",
        width: 200,
      },
      {
        field: "EsitoDesc",
        headerName: "Esito",
        headerAlign: "center",
        align: "center",
        width: 200,
      },
    ]);
    setLoading(false);
  };

  useEffect(() => {
    (() => {
      loadVisite();
    })();
  }, []);

  const renderDayInPicker = (
    date,
    selectedDate,
    dayInCurrentMonth,
    dayComponent
  ) => {
    if (daysWithDot.includes(date.format("YYYY/MM/DD"))) {
      return (
        <div
          className={{
            position: "relative",
          }}
        >
          {dayComponent}
          <div
            className={{
              position: "absolute",
              height: 0,
              width: 0,
              border: "2px solid",
              borderRadius: 4,
              borderColor: "blue",
              right: "50%",
              transform: "translateX(1px)",
              top: "80%",
            }}
          />
        </div>
      );
    }
  };

  const updateVisiteVisibili = (data) => {
    if (data) {
      setVisiteVisibili(
        allVisite.filter(
          (v) =>
            moment(v.DATA).format("YYYY-MM-DD") ===
            moment(data).format("YYYY-MM-DD")
        )
      );
      setVisiteVisibiliBk(
        allVisite.filter(
          (v) =>
            moment(v.DATA).format("YYYY-MM-DD") ===
            moment(data).format("YYYY-MM-DD")
        )
      );
    }
  };

  function CustomToolbar() {
    return (
      <div className="flex flex-wrap justify-center md:justify-start my-2">
        <GridToolbarContainer sx={{ display: "flex", flexWrap: "wrap" }}>
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </GridToolbarContainer>
      </div>
    );
  }

  return (
    <div className=" flex flex-wrap flex-1 bg-white p-4  shadow-lg rounded-md border border-slate-200">
      <div className="flex w-full items-center flex-wrap md:flex-nowrap gap-4">
        <Autocomplete
          multiple
          id="checkboxes-tags-demo"
          options={filterClienti}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          onChange={(event, newValue) => {
            if (newValue.length > 0)
              return enableAllVisite
                ? setVisiteVisibili(
                    //controllo se visualizza tutti è flaggato
                    allVisite.filter((el) => newValue.includes(el.Cliente))
                  )
                : setVisiteVisibili(
                    //se è flaggato controllo
                    visiteVisibiliBk.filter((el) =>
                      newValue.includes(el.Cliente)
                    )
                  );
            return setVisiteVisibili(visiteVisibiliBk);
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          size="medium"
          style={{ width: "50%" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Clienti"
              placeholder="Ragioni Sociali"
            />
          )}
        />
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DatePicker
            renderDay={(day, selectedDate, DayComponentProps) => {
              const isSelected =
                !DayComponentProps.outsideCurrentMonth &&
                daysWithDot.includes(day.format("YYYY-MM-DD"));

              return (
                <Badge
                  key={day.toString()}
                  overlap="circular"
                  badgeContent={isSelected ? "⚕️" : undefined}
                >
                  <PickersDay {...DayComponentProps} />
                </Badge>
              );
            }}
            disabled={enableAllVisite}
            label="Giorno"
            value={moment(value)}
            onChange={(newValue) => {
              setValue(newValue);
              updateVisiteVisibili(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <FormControlLabel
          control={
            <Checkbox
              checked={enableAllVisite}
              onClick={async () => {
                if (!enableAllVisite) {
                  setEnableAllVisite(true);
                  /* let responseVisite = await unsplash.get("visiteMediche", {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                    params: { utente: sessionStorage.getItem("User") },
                  }); 
                  setVisiteVisibiliBk(responseVisite.data.data);
                  */
                  setVisiteVisibili(allVisite);
                } else {
                  //loadVisite();
                  setVisiteVisibili(visiteVisibiliBk);
                  setEnableAllVisite(false);
                }
              }}
            />
          }
          label="Visualizza tutti"
        />
      </div>

      <div className="h-[70vh] md:h-[80vh] w-full">
        <DataGridPro
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          loading={loading}
          columns={columns}
          rows={visiteVisibili}
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooterPersonalized,
          }}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          getRowId={(row) => row.ID}
        />
      </div>
    </div>
  );
};

export default VisiteMediche;
