import React, { useEffect, useState } from "react";
import unsplash from "../../api/unsplash";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { LocalizationProvider,DatePicker,PickersDay,TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment as DateAdapter} from '@mui/x-date-pickers/AdapterMoment'

import {
  Edit,
  Delete,
  Add,
  Check,
  Close,
  CheckCircle,
  Error,
  History,
} from "@mui/icons-material";
import {
  TextField,
  Button,
  Box,
  Autocomplete,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Zoom,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import GlobalSnackbar from "../Snackbar";
import moment from "moment";
import { CustomFooterPersonalized } from "../../utils/CustomFooterPersonalized";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <GridToolbarQuickFilter placeholder="Cerca..." />
      </Grid>
    </GridToolbarContainer>
  );
}
const AdempimentiCliente = (props) => {
  const [adempimenti, setAdempimenti] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categorieAdempimenti, setCategorieAdempimenti] = useState([]);
  const [elencoAdempimenti, setElencoAdempimenti] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: 200,
      headerAlign: "center",
      align: "left",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <ModalAdd
            selezionato={params.row.ID}
            cliente={props.idAzienda}
            addAdempimento={addAdempimento}
            howTo={params.row.IstruzioniCompilazione}
          />
          <ModalEdit
            check={false}
            selezionato={params.row}
            cliente={props.idAzienda}
            sede={props.sede}
            editAdempimento={editAdempimento}

            //  handleSaving(operazione, mansione, after, before)
          />
          <ModalStorico
            check={false}
            categoria={params.row.Categoria}
            selezionato={params.row.ID}
            cliente={props.idAzienda}
            sede={props.sede}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
            loadAdempimenti={loadAdempimenti}
            //  handleSaving(operazione, mansione, after, before)
          />
          <ModalDelete
            check={false}
            selezionato={params.row}
            cliente={props.idAzienda}
            sede={props.sede}
            deleteAdempimento={deleteAdempimento}
            //  handleSaving(operazione, mansione, after, before)
          />
        </>
      ),
    },
    {
      field: "",
      headerName: "Stato",
      width: 70,
      renderCell: (params) => {
        let scadenza = params.row.Scadenza;
        if (scadenza == 0 && params.row.UltimaScadenza) {
          return <CheckCircle sx={{ color: "#40C18F" }} />;
        } else if (!params.row.UltimaScadenza) {
          return <Error sx={{ color: "#FF2768" }} />;
        } else {
          let dateTemp = moment(params.row.UltimaScadenza).add(scadenza, "M");

          if (
            dateTemp.format("YYYY-MM-DD") <
            moment(new Date()).format("YYYY-MM-DD")
          ) {
            return <Error sx={{ color: "#FF2768" }} />;
          } else {
            return <CheckCircle sx={{ color: "#40C18F" }} />;
          }
        }
      },
    },
    { field: "Categoria", headerName: "Categoria", width: 150 },
    { field: "Descrizione", headerName: "Descrizione", flex: 1 },
    { field: "Scadenza", headerName: "Validità", width: 90 },
    {
      field: "UltimaScadenza",
      headerName: "Data",
      width: 90,
      renderCell: (params) =>
        params.row.UltimaScadenza
          ? moment(params.row.UltimaScadenza).format("DD/MM/YYYY")
          : "",
    },
  ];

  useEffect(async () => {
    //carico le mansioni del cliente

    let responseAdempimenti = await unsplash.get("adempimenti", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: props.idAzienda, sede: props.sede },
    });

    await setAdempimenti(responseAdempimenti.data.data);
    setLoading(false);
    let responseAdempimentiElenco = await unsplash.get("adempimenti", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    setElencoAdempimenti(responseAdempimentiElenco.data.data);

    let responseCategorieAdempimenti = await unsplash.get("adempimenti", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { categoria: true },
    });

    setCategorieAdempimenti(responseCategorieAdempimenti.data.data);
  }, []);

  const handleSaving = async (adempimento, operazione, categoria) => {
    if (operazione === "Inserimento") {
      try {
        let adempTemp = {
          Adempimento: adempimento.ID,
          Scadenza: adempimento.Cadenza ? adempimento.Cadenza : 0,
        };

        await unsplash.post(
          "adempimenti",
          {
            adempimento: adempTemp,
            cliente: props.idAzienda,
            sede: props.sede,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        let responseAdempimenti = await unsplash.get("adempimenti", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { id: props.idAzienda, sede: props.sede },
        });

        await setAdempimenti(responseAdempimenti.data.data);
        setSnack({
          severity: "success",
          messaggio: "Adempimento inserito con successo",
        });
        setOpenSnack(true);
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'inserimento",
        });
        setOpenSnack(true);
      }
    }
  };

  const addAdempimento = async (data, adempimento, note) => {
    try {
      await unsplash.post(
        "adempimenti",
        {
          adempimento: adempimento,
          data: data,
          cliente: props.idAzienda,
          sede: props.sede,
          note: note,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      let responseAdempimenti = await unsplash.get("adempimenti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { id: props.idAzienda, sede: props.sede },
      });

      await setAdempimenti(responseAdempimenti.data.data);
      setSnack({
        severity: "success",
        messaggio: "Adempimento inserito con successo",
      });
      setOpenSnack(true);
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'inserimento",
      });
      setOpenSnack(true);
    }
  };

  const editAdempimento = async (adempimento) => {
    try {
      await unsplash.put(
        "adempimenti",
        { adempimento: adempimento, updateRel: true },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      let responseAdempimenti = await unsplash.get("adempimenti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { id: props.idAzienda, sede: props.sede },
      });

      await setAdempimenti(responseAdempimenti.data.data);
      setSnack({
        severity: "success",
        messaggio: "Adempimento modificato con successo",
      });
      setOpenSnack(true);
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'inserimento",
      });
      setOpenSnack(true);
    }
  };

  const loadAdempimenti = async () => {
    let responseAdempimenti = await unsplash.get("adempimenti", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: props.idAzienda, sede: props.sede },
    });

    await setAdempimenti(responseAdempimenti.data.data);
  };

  const deleteAdempimento = async (adempimento) => {
    try {
      await unsplash.delete("adempimenti", {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        data: { adempimento: adempimento, updateRel: true },
      });

      let responseAdempimenti = await unsplash.get("adempimenti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { id: props.idAzienda, sede: props.sede },
      });

      await setAdempimenti(responseAdempimenti.data.data);
      setSnack({
        severity: "success",
        messaggio: "Adempimento eliminato con successo",
      });
      setOpenSnack(true);
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'inserimento",
      });
      setOpenSnack(true);
    }
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid
        container
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="flex-end"
      >
        <Modal
          check={true}
          title="Aggiungi"
          tipologia="Inserimento"
          elencoAdempimenti={elencoAdempimenti}
          adempimenti={adempimenti}
          categorieAdempimenti={categorieAdempimenti}
          selezionata="NO"
          cliente={props.idAzienda}
          saveFunction={handleSaving}
        />
      </Grid>
      <Box sx={{ height: "60vh", width: 1 }}>
        <DataGridPro
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooterPersonalized,
          }}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={loading}
          rows={adempimenti}
          getRowId={(adempimenti) => adempimenti.ID}
          columns={columns}
          initialState={{ pinnedColumns: { left: ["Actions"] } }}
          disableSelectionOnClick
        />
      </Box>
    </>
  );
};

//MODAL
const Modal = (props) => {
  const [open, setOpen] = useState(false);
  const [adempimentoSel, setAdempimentoSel] = useState(null);
  const [categoriaSel, setCategoriaSel] = useState(false);
  const [adempimenti, setAdempimenti] = useState([]);
  const [adempimentiBackup, setAdempimentiBackup] = useState([]);
  const handleClickOpen = () => {
    let ad = getDifference(
      props.elencoAdempimenti,
      props.adempimenti,
      "ID",
      "ID"
    );
    setAdempimentoSel(null);
    setAdempimenti(ad);
    setAdempimentiBackup(ad);
    setCategoriaSel(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    props.saveFunction(adempimentoSel, props.tipologia, categoriaSel);
    let ad = getDifference(adempimentiBackup, [adempimentoSel], "ID", "ID");

    setAdempimenti(
      ad.filter((v) => v.CategoriaAdempimento === categoriaSel.ID)
    );
    setAdempimentiBackup(ad);
    setAdempimentoSel(null);
  };

  return (
    <div>
      <Button
        startIcon={<Add />}
        variant="contained"
        onClick={handleClickOpen}
        id={props.id}
        className="ButtonMiller"
      >
        Aggiungi
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth="sm"
        TransitionComponent={Transition}
      >
        <DialogTitle>Associa</DialogTitle>
        <DialogContent>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                id="combo-box-demo"
                options={props.categorieAdempimenti}
                getOptionLabel={(option) => option.Descrizione}
                fullWidth
                onChange={(event, newValue) => {
                  if (newValue) {
                    setAdempimenti(
                      adempimentiBackup.filter(
                        (v) => v.CategoriaAdempimento === newValue.ID
                      )
                    );
                    setCategoriaSel(newValue);
                    setAdempimentoSel(null);
                  } else {
                    setAdempimenti(adempimentiBackup);
                    setCategoriaSel(false);
                    setAdempimentoSel(null);
                  }
                  //handleChangeCliente(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Categoria" />
                )}
                sx={{ mt: 1 }}
              />
            </Grid>
            <Grid item xs={9}>
              <Autocomplete
                style={{ display: categoriaSel ? "" : "None" }}
                id="combo-box-demo"
                options={adempimenti}
                getOptionLabel={(option) => option.Descrizione}
                fullWidth
                value={adempimentoSel}
                onChange={(event, newValue) => {
                  setAdempimentoSel(newValue);
                  //handleChangeCliente(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Adempimento" />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{ display: categoriaSel ? "" : "None" }}
                value={adempimentoSel ? adempimentoSel.Cadenza : 0}
                name="Scadenza"
                label="Scadenza"
                type="text"
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  let a = { ...adempimentoSel };
                  a.Cadenza = e.target.value;
                  setAdempimentoSel(a);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 1,
            px: 3,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={handleSave}
          >
            Associa
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const ModalAdd = (props) => {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(null);
  const [note, setNote] = useState(null);
  const handleClickOpen = () => {
    setDate(null);
    setNote(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    props.addAdempimento(date, props.selezionato, note);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <Add style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth="sm"
        TransitionComponent={Transition}
      >
        <DialogTitle>Inserisci nuova Data</DialogTitle>
        <DialogContent style={{ paddingTop: "20px" }}>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  ampmInClock={false}
                  renderInput={(props) => <TextField {...props} fullWidth />}
                  label="inserisci"
                  value={moment(date)}
                  onChange={(newValue) => {
                    setDate(newValue ? newValue.format("YYYY-MM-DD") : null);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={note}
                name="Note"
                label="Note"
                type="text"
                fullWidth
                variant="outlined"
                onChange={(e) => setNote(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              {props.howTo}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 1,
            px: 3,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={handleSave}
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const ModalStorico = (props) => {
  const [open, setOpen] = useState(false);
  const [adempimentoSel, setAdempimentoSel] = useState("");
  const [storico, setStorico] = useState([]);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      field: "Actions",
      headerName: "Azioni",
      align: "center",
      headerAlign: "center",
      width: 70,
      renderCell: (params) => (
        <ModalDeleteAdempimentoStorico
          selezionato={params.row}
          deleteAdempimento={deleteAdempimento}
          //  handleSaving(operazione, mansione, after, before)
        />
      ),
    },
    {
      field: "Data",
      headerName: "Data",
      align: "center",
      headerAlign: "center",
      width: 130,
      renderCell: (params) =>
        params.row.Data ? moment(params.row.Data).format("DD/MM/YYYY") : "",
    },
    {
      field: "Note",
      headerName: "Note",
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.Note}>{params.row.Note}</Tooltip>
      ),
    },
  ];
  const handleClickOpen = async () => {
    let responseAdempimenti = await unsplash.get("adempimenti", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: {
        cliente: props.cliente,
        adempimento: props.selezionato,
        sede: props.sede,
      },
    });
    setStorico(responseAdempimenti.data.data);
    setLoading(false);
    setAdempimentoSel("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteAdempimento = async (adempimento) => {
    try {
      await unsplash.delete("adempimenti", {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        data: { adempimento: adempimento, updateEffettuato: true },
      });

      let responseAdempimenti = await unsplash.get("adempimenti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: {
          cliente: props.cliente,
          adempimento: props.selezionato,
          sede: props.sede,
        },
      });

      setStorico(responseAdempimenti.data.data);
      setAdempimentoSel("");
      props.loadAdempimenti();
      props.setSnack({
        severity: "success",
        messaggio: "Adempimento eliminato con successo",
      });
      props.setOpenSnack(true);
    } catch {
      props.setSnack({
        severity: "error",
        messaggio: "Errore durante l'inserimento",
      });
      props.setOpenSnack(true);
    }
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <History style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={"xl"}
        TransitionComponent={Transition}
      >
        <DialogTitle>Storico - {props.categoria}</DialogTitle>
        <DialogContent>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={12}>
              <div style={{ height: 400, width: "100%" }}>
                <DataGridPro
                  style={{ marginTop: "10px" }}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  components={{
                    Toolbar: CustomToolbar,
                    Footer: CustomFooterPersonalized,
                  }}
                  slots={{
                    loadingOverlay: LinearProgress,
                  }}
                  loading={loading}
                  rows={storico}
                  getRowId={(storico) => storico.IDEffettuato}
                  columns={columns}
                  initialState={{ pinnedColumns: { left: ["Actions"] } }}
                  disableSelectionOnClick
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 1,
            px: 3,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
          >
            Chiudi
          </Button>
          {/*<Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={handleSave}
          >
            Associa
        </Button>*/}
        </DialogActions>
      </Dialog>
    </div>
  );
};

const ModalEdit = (props) => {
  const [open, setOpen] = useState(false);
  const [adempimentoSel, setAdempimentoSel] = useState(null);

  const handleClickOpen = () => {
    setAdempimentoSel(props.selezionato);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    props.editAdempimento(adempimentoSel);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth="sm"
        TransitionComponent={Transition}
      >
        <DialogTitle>{props.selezionato.Descrizione}</DialogTitle>
        <DialogContent style={{ paddingTop: "20px" }}>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={12}>
              <TextField
                value={adempimentoSel ? adempimentoSel.Scadenza : 0}
                name="Scadenza"
                label="Modifica scadenza"
                type="number"
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  let a = { ...adempimentoSel };
                  a.Scadenza = e.target.value;
                  setAdempimentoSel(a);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 1,
            px: 3,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={handleSave}
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const ModalDelete = (props) => {
  const [open, setOpen] = useState(false);
  const [adempimentoSel, setAdempimentoSel] = useState(null);

  const handleClickOpen = () => {
    setAdempimentoSel(props.selezionato);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    props.deleteAdempimento(adempimentoSel);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth="sm"
        TransitionComponent={Transition}
      >
        <DialogTitle>Rimuovi Adempimento</DialogTitle>
        <DialogContent style={{ paddingTop: "20px" }}>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={12}>
              Sei sicuro di voler rimuove l'adempimento:{" "}
              {props.selezionato.Descrizione}?
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 1,
            px: 3,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={handleDelete}
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const ModalDeleteAdempimentoStorico = (props) => {
  const [open, setOpen] = useState(false);
  const [adempimentoSel, setAdempimentoSel] = useState(null);

  const handleClickOpen = () => {
    setAdempimentoSel(props.selezionato);

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    props.deleteAdempimento(adempimentoSel);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth="sm"
        TransitionComponent={Transition}
      >
        <DialogTitle>Rimuovi Adempimento</DialogTitle>
        <DialogContent style={{ paddingTop: "20px" }}>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={12}>
              Sei sicuro di voler rimuove l'adempimento effettuato in data:{" "}
              {moment(adempimentoSel?.Data).format("DD/MM/YYYY")}, inserito con
              le seguenti note: {adempimentoSel?.Note}?
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 1,
            px: 3,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={handleDelete}
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdempimentiCliente;

function getDifference(array1, array2, prop1, prop2) {
  //riutilizzabile per differenze tra due array di oggetti
  return array1.filter((object1) => {
    return !array2.some((object2) => {
      return object1[prop1] === object2[prop2];
    });
  });
}
