import React, { useEffect, useState } from "react";
import unsplash from "../api/unsplash";
import { useLocation, useNavigate } from "react-router-dom";

import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import GlobalSnackbar from "./Snackbar";
import Allegati from "./Allegati";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import moment from "moment";
import momentTimezone from "moment-timezone";
import "moment/locale/it";
import { LocalizationProvider,DatePicker,PickersDay,TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment as DateAdapter} from '@mui/x-date-pickers/AdapterMoment'
import {
  Add,
  HealthAndSafety,
  WarningOutlined,
  Delete,
  HourglassBottom,
  Error,
  CheckCircle,
  ErrorOutline,
  CheckCircleOutline,
  MenuBook,
  Close,
  Check,
  ArrowForward,
  DoneAll,
  PersonOff,
  Person,
  History,
  CheckBox,
  InsertDriveFile,
} from "@mui/icons-material";
import {
  LinearProgress,
  ButtonGroup,
  OutlinedInput,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Popover,
  DialogActions,
  Box,
  Typography,
  Step,
  StepLabel,
  Stepper,
  CircularProgress,
  Tooltip,
  Backdrop,
  Card,
  CardContent,
  Zoom,
  Chip,
  ListItemText,
} from "@mui/material";
import DettaglioLavoratore from "./DettaglioLavoratore/DettaglioLavoratore";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const Distaccati = () => {
  const [skip, setSkip] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [visite, setVisite] = useState([]);
  const [visisteBackup, setVisiteBackup] = useState([]);
  const [tipologiaVisita, setTipologiaVisita] = useState();
  const [filtro, setFiltro] = useState({
    verde: true,
    arancione: true,
    rosso: true,
    viola: true,
  });
  const [accertamenti, setAccertamenti] = useState([]);
  const [accertamentiSel, setAccertamentiSel] = useState([]);
  const [lavoratore, setLavoratore] = useState(0);
  const [lavoratoriSel, setLavoratoriSel] = useState([]);
  const [medici, setMedici] = useState();
  const [esiti, setEsiti] = useState();
  const [scadenze, setScadenze] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filtroEtichette, setFiltroEtichette] = useState({
    Medico: "",
    Lavoratore: "",
    Blocco: "",
    Cliente: "",
  });

  const [filtroClessidera, setFiltroClessidera] = useState({
    verde: true,
    arancione: true,
    rosso: true,
    blu: true,
  });

  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      headerAlign: "center",
      hide: false,
      width: "100",
      align: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <ButtonGroup>
          <DettaglioLavoratore
            lavoratore={params.row}
            cliente={params.row.Cliente}
            loadLavoratori={loadLavoratori}
          />
        </ButtonGroup>
      ),
    },
    {
      field: "Cognome",
      headerName: "Cognome",
      width: 200,
      //flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Nome",
      headerName: "Nome",
      width: 200,
      //flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "RagioneSociale",
      headerName: "Cliente attuale",
      width: 200,
      //flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },

    {
      field: "Distaccato",
      width: 200,
      //flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "RagioneSocialeDa",
      headerName: "Distaccato Da",
      width: 200,
      //flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "RagioneSocialeIn",
      headerName: "Distaccato In",
      width: 200,
      //flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "DataDistaccamento",
      headerName: "Data Distaccamento",
      width: 200,
      //flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => {
        return params.row.DataDistaccamento
          ? moment(params.row.DataDistaccamento).format("DD/MM/YYYY")
          : "";
      },
    },
    {
      field: "Percentuale",
      headerName: "Percentuale",
      width: 200,
      //flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => {
        return params.row.Percentuale ? params.row.Percentuale + " %" : "";
      },
    },
    {
      field: "Note",
      headerName: "Note",
      width: 200,
      //flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
  ];

  const filterClessidera = (e, colore) => {
    let f = { ...filtroClessidera };
    f[colore] = e.target.checked;
    setFiltroClessidera(f);
    let lavTemp = visisteBackup;
    if (!f.rosso) {
      lavTemp = lavTemp.filter(
        (v) =>
          v.StatoVisita !== "Scadenza" &&
          v.StatoVisita !== "AccertamentoMancante"
      );
    }

    if (!f.verde) {
      lavTemp = lavTemp.filter((v) => v.StatoVisita !== "OK");
    }

    if (!f.arancione) {
      lavTemp = lavTemp.filter((v) => v.StatoVisita !== "InScadenza");
    }

    if (!f.blu) {
      lavTemp = lavTemp.filter((v) => v.StatoVisita !== "Parziale");
    }

    setScadenze(lavTemp);

    /* let visiteTemp = visite.filter(v => v.Esito >=1);

setVisite(visiteTemp); */
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
          <Button
            variant="text"
            size="small"
            startIcon={<InsertDriveFile />}
            onClick={() =>
              document.getElementById("printDocScadenzario").click()
            }
          >
            Scarica Excel
          </Button>
        </Grid>
      </GridToolbarContainer>
    );
  }

  const massiveVisit = (e) => {
    if (lavoratoriSel.length > 0) {
      setSkip(false);
      document.getElementById("myDialog").click();
    } else {
      //se vuoto mostro l'alert
      setAnchorEl(e.currentTarget);
    }
  };

  const filterData = (e, colore, accertamenti) => {
    let f = { ...filtro };
    if (colore) {
      f[colore] = e.target.checked;
      setFiltro(f);
    }
    let scadenzeTemp = visisteBackup;

    let checkArr = []; //metto i colori che in base al flag popolo

    if (f.verde) {
      checkArr.push("verde");
    }
    if (f.arancione) {
      checkArr.push("arancione");
    }
    if (f.rosso) {
      checkArr.push("rosso");
    }

    if (f.viola) {
      checkArr.push("viola");
    }

    if (
      f.rosso &&
      f.verde &&
      f.viola &&
      f.arancione &&
      accertamenti.length === 0
    ) {
    } else {
      if (accertamenti.length > 0) {
        scadenzeTemp = scadenzeTemp.filter(
          (s) =>
            s.Accertamenti.filter(
              (sf) =>
                checkArr.includes(sf.Colore) &&
                accertamenti.includes(sf.Accertamento)
            ).length > 0
        );
      } else {
        scadenzeTemp = scadenzeTemp.filter(
          (s) =>
            s.Accertamenti.filter((sf) => checkArr.includes(sf.Colore)).length >
            0
        );
      }
    }

    setVisite(scadenzeTemp);
    setScadenze(scadenzeTemp);
    /* let visiteTemp = visite.filter(v => v.Esito >=1);
  
  setVisite(visiteTemp); */
  };

  const loadLavoratori = async () => {
    try {
      let responseScadenzario = await unsplash.get("lavoratori", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { scandenziario: "SI" },
      });

      setScadenze(responseScadenzario.data.data);
      setVisiteBackup(responseScadenzario.data.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      try {
        let response = await unsplash.get("anagraficaAccertamenti", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });

        setAccertamenti(response.data.data);
        let responseScadenzario = await unsplash.get("distacco", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        setScadenze(responseScadenzario.data.data);
        setLoading(false);
        setVisiteBackup(responseScadenzario.data.data);

        let responseEsiti = await unsplash.get("esiti", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        setEsiti(responseEsiti.data.data);

        let responseTipologiaVisita = await unsplash.get("tipologiaVisita", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        setTipologiaVisita(responseTipologiaVisita.data.data);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  const handleChangeCorsiSel = (event) => {
    setAccertamentiSel(event.target.value);
    filterData("", "", event.target.value);
  };

  return (
    <>
      {" "}
      {/*<GlobalSnackbar severity={snack.severity} messaggio={snack.messaggio} openSnack={openSnack} handleCloseSnack={handleCloseSnack}/>*/}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <div>
              {visite === undefined ? (
                <p>
                  {" "}
                  <center>
                    <CircularProgress />
                  </center>
                </p>
              ) : (
                <>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {/* <Grid item xs={2}>
                      <TextField
                        size="small"
                        label="Lavoratore"
                        fullWidth
                        onChange={(e) => {
                          filtraTutto(e, "Lavoratore");
                          let f = { ...filtroEtichette };
                          f.Lavoratore = e.target.value;
                          setFiltroEtichette(f);
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        size="small"
                        label="Medico"
                        sx={{ marginRight: "10px" }}
                        fullWidth
                        onChange={(e) => {
                          filtraTutto(e, "Medico");
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        size="small"
                        label="Cliente"
                        sx={{ marginRight: "10px" }}
                        fullWidth
                        onChange={(e) => {
                          filtraTutto(e, "Cliente");
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        size="small"
                        label="Blocco"
                        sx={{ marginRight: "10px" }}
                        fullWidth
                        onChange={(e) => {
                          filtraTutto(e, "Blocco");
                        }}
                      />
                    </Grid> */}
                    <Grid item xs={2}></Grid>
                  </Grid>
                  <MyDialog
                    loadLavoratori={loadLavoratori}
                    lavoratori={lavoratoriSel}
                    medici={medici}
                    esiti={esiti}
                    tipologiaVisita={tipologiaVisita}
                    // cliente={props.idAzienda}
                  />
                  <PopUpCorsi lavoratore={lavoratore} />
                  <Box
                    sx={{
                      height: { xs: "80vh", md: "70vh", xl: "80vh" },
                      width: 1,
                    }}
                  >
                    <DataGridPro
                      sx={{ fontSize: 12 }}
                      components={{
                        Toolbar: CustomToolbar,
                        Footer: CustomFooterPersonalized,
                      }}
                      slots={{
                        loadingOverlay: LinearProgress,
                      }}
                      loading={loading}
                      initialState={{ pinnedColumns: { left: ["Actions"] } }}
                      localeText={
                        itIT.components.MuiDataGrid.defaultProps.localeText
                      }
                      rows={scadenze}
                      columns={columns}
                      getRowId={(row) => row.ID}
                      pageSize={100}
                      checkboxSelection
                      onSelectionModelChange={(ids) => {
                        const selectedIDs = new Set(ids);
                        const selectedRowData = scadenze.filter((row) =>
                          selectedIDs.has(row.ID)
                        );
                        setLavoratoriSel(selectedRowData);
                      }}
                      onCellClick={(params, e) => {
                        if (params.field === "Actions") {
                          e.stopPropagation();
                        }
                      }}
                    />
                  </Box>
                  <div style={{ display: "None" }}>
                    <ReactHtmlTableToExcel
                      id="printDocScadenzario"
                      className="download-table-xls-button"
                      table="table-to-xls"
                      filename={"Log"}
                      sheet={"Log"}
                      style={{ display: "None!important" }}
                      buttonText="Download Log"
                    />
                  </div>
                  <table
                    id="table-to-xls"
                    style={{ marginTop: 10, display: "None" }}
                    cellSpacing={0}
                    border={0}
                  >
                    <tr>
                      <th>Cognome</th>
                      <th>Nome</th>
                      <th>Distaccato</th>
                      <th>Distaccato Da</th>
                      <th>Distaccato In</th>
                      <th>Data Distaccamento</th>
                      <th>Percentuale</th>
                      <th>Note</th>
                    </tr>
                    {lavoratoriSel?.map((lav, index) => (
                      <tr key={lav.ID}>
                        <td>{lav.Cognome}</td>
                        <td>{lav.Nome}</td>
                        <td>{lav.Distaccato}</td>
                        <td>{lav.RagioneSocialeDa}</td>
                        <td>{lav.RagioneSocialeIn}</td>

                        <td>
                          {lav.DataDistaccamento
                            ? moment(lav.DataDistaccamento).format(
                                "DD - MM - YYYY"
                              )
                            : ""}
                        </td>
                        <td>{lav.Percentuale}</td>
                        <td>{lav.Note}</td>
                      </tr>
                    ))}
                  </table>
                </>
              )}
            </div>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default Distaccati;

const PopUpCorsi = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        aria-label="edit"
        id="allegati"
        onClick={handleClickOpen}
        sx={{ display: "None" }}
      >
        Allegati{" "}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={"md"}
        maxWidth={"md"}
        TransitionComponent={Transition}
      >
        <DialogTitle sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
          Allegati
        </DialogTitle>
        <DialogContent sx={{ marginTop: "30px" }}>
          <Allegati
            idLavoratore={props.lavoratore}
            id={props.lavoratore}
            colonna="Lavoratore"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={() => props.myFunction(props.id)} >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const MyDialog = (props) => {
  const [medicoSel, setMedicoSel] = useState("");
  const [esitoSel, setEsitoSel] = useState("");
  const [tipologiaSel, setTipologiaSel] = useState("");
  const [dataSel, setDataSel] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [accertamenti, setAccertamenti] = useState([]);
  const [loading, setLoading] = useState(true);
  const [medici, setMedici] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedACS, setSelectedACS] = useState([]);
  const [noteSel, setNoteSel] = useState("");
  const [luogoSel, setLuogoSel] = useState("");
  const [required, setRequired] = useState({
    medico: false,
    data: false,
    tipo: false,
  });
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const columns = [
    { field: "Descrizione", headerName: "Accertamento", width: 300 },
    {
      field: "Scadenza",
      headerName: "Scadenza",
      type: "date",
      width: 150,
      editable: true,
      renderCell: (params) =>
        params.row.Scadenza
          ? moment(params.row.Scadenza).format("DD/MM/YYYY")
          : "",
    },
    {
      field: "Importo",
      headerName: "Importo €",
      type: "number",
      width: 120,
      editable: true,
      renderCell: (params) => (params.row.Importo ? params.row.Importo : 0),
    },
    {
      field: "ImportoPassivo",
      headerName: "Importo Passivo €",
      type: "number",
      width: 150,
      editable: true,
      renderCell: (params) =>
        params.row.ImportoPassivo ? params.row.ImportoPassivo : 0,
    },
  ];

  const loadVisitaLavoratore = async (val) => {
    let responseMedici = await unsplash.get("medici", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: props.lavoratori[val].Cliente },
    });
    setMedici(responseMedici.data.data);

    let responseProtocollo = await unsplash.get("protocolloSanitario", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { lavoratore: props.lavoratori[val].ID },
    });

    let year = 0;
    if (props.lavoratori[val].CF) {
      year = ageFromCf(props.lavoratori[val].CF);
    }
    let d1 = new Date();
    let d2 = new Date(
      moment(d1.setDate(d1.getDate() + 60)).format("YYYY-MM-DD")
    );

    let acs = responseProtocollo.data.data.filter(
      (p) =>
        p.Scadenza === null ||
        new Date(moment(p.Scadenza).format("YYYY-MM-DD")).getTime() <
          d2.getTime() ||
        p.Data === null
    ); // solo accertamenti scaduti
    setSelectedACS(acs);

    let responseAccertamenti = await unsplash.get("accertamenti", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { cliente: props.lavoratori[val].Cliente, ultimaVisita: 0 },
    });

    let allAccertamenti = responseAccertamenti.data.data;
    let arrayOnlyID = allAccertamenti.map((acc) => acc.ID);

    for (let i = 0; i < acs.length; i++) {
      let index = arrayOnlyID.indexOf(acs[i].Accertamenti);
      if (index !== -1) {
        if (
          props.lavoratori[val].Mansione === 631 &&
          (acs[i].Accertamenti === 40 || acs[i].Accertamenti === 42)
        ) {
          // SE è VDT e Accertamento = Visita generale
          if (year > 50) {
            allAccertamenti[index].Scadenza = moment(dataSel).add(24, "M");
            acs[i].Scadenza = moment(dataSel)
              .add(acs[i].Cadenze, "M")
              .format("YYYY-MM-DD");
          } else {
            allAccertamenti[index].Scadenza = moment(dataSel).add(60, "M");
            acs[i].Scadenza = moment(dataSel)
              .add(acs[i].Cadenze, "M")
              .format("YYYY-MM-DD");
          }
        } else {
          allAccertamenti[index].Scadenza = moment(dataSel).add(
            acs[i].Cadenze,
            "M"
          );
          acs[i].Scadenza = moment(dataSel)
            .add(acs[i].Cadenze, "M")
            .format("YYYY-MM-DD");
        }
      }
    }

    //setSelectionModel(acs.map(acc => {return {ID:acc.Accertamenti}}))
    setSelectionModel(
      acs.map((acc) => {
        return { ID: acc.Accertamenti, Scadenza: acc.Scadenza };
      })
    );
    setAccertamenti(allAccertamenti);
    setLoading(false);
  };

  const handleClickOpen = async () => {
    await setRequired({ medico: false, data: false, tipo: false });
    loadVisitaLavoratore(0);
    setActiveStep(0);
    await setDataSel(new Date());
    setOpen(true);
    await setMedicoSel("");
    await setEsitoSel("");
    await setLuogoSel("");
    await setTipologiaSel("");
    await setNoteSel("");
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleNext = async () => {
    //qua faccio le operazioni ogni volta
    let newSkipped = skipped;

    if (activeStep + 1 < props.lavoratori.length) {
      loadVisitaLavoratore(activeStep + 1);
    }
    await setRequired({ medico: false, data: false, tipo: false });
    //await setDataSel(new Date());
    //await setMedicoSel("");
    await setNoteSel("");
    await setEsitoSel("");
    await setLuogoSel("");
    await setTipologiaSel("");
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    /*if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
   
    */
    //qui devo caricare con la query i dati
    if (activeStep === props.lavoratori.length - 1) {
      props.loadLavoratori();
    }
  };

  const handleSave = async () => {
    let req = { ...required };
    let checkOk = true; //lo uso per controllare che tutto sia ok
    if (medicoSel === "" || medicoSel === null) {
      req.medico = true;
      checkOk = false;
    } else {
      req.medico = false;
    }
    if (tipologiaSel === "" || tipologiaSel === null) {
      req.tipo = true;
      checkOk = false;
    } else {
      req.tipo = false;
    }

    if (dataSel === "" || dataSel === null) {
      req.data = true;
      checkOk = false;
    } else {
      req.data = false;
    }

    setRequired(req);
    if (checkOk) {
      try {
        let response = await unsplash.post(
          "visiteMediche",
          {
            accertamenti: selectionModel,
            medico: medicoSel,
            cliente: props.lavoratori[activeStep].Cliente,
            mansione: props.lavoratori[activeStep].Mansione,
            data: moment(dataSel).format("YYYY-MM-DD"),
            esito: esitoSel,
            luogo: luogoSel,
            note: noteSel,
            tipoVisita: tipologiaSel,
            lavoratore: props.lavoratori[activeStep].ID,
            vecchiaVisita: props.lavoratori[activeStep].IDUltimaVisita,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Visita Medica inserita con successo",
        });
        setOpenSnack(true);
        //setOpen(false);
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'inserimento",
        });
        setOpenSnack(true);
        //setOpen(false);
      }
    }
  };

  const handleReorderCheck = async () => {
    let IDSelected = selectionModel.map((s) => s.ID);

    let top = [];
    let bottom = [];
    for (let i = 0; i < accertamenti.length; i++) {
      if (IDSelected.indexOf(accertamenti[i].ID) >= 0) {
        top.push(accertamenti[i]);
      } else {
        bottom.push(accertamenti[i]);
      }
    }

    setAccertamenti(top.concat(bottom));
    setLoading(false);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
          <Button
            size="small"
            onClick={() => handleReorderCheck()}
            startIcon={<CheckBox />}
          >
            Riordina
          </Button>
        </Grid>
      </GridToolbarContainer>
    );
  }

  return (
    <div>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Button
        variant="outlined"
        sx={{ display: "none" }}
        onClick={handleClickOpen}
        id="myDialog"
      >
        Open form dialog
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <DialogTitle>Visita Massiva</DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
              {props.lavoratori.map((lav, index) => {
                const stepProps = {};
                const labelProps = {};
                stepProps.completed = false;
                //if (isStepSkipped(index)) {

                //}
                return (
                  <Step
                    key={lav.ID}
                    {...stepProps}
                    sx={{
                      display:
                        props.lavoratori[activeStep]?.ID === lav.ID
                          ? "Block"
                          : "None",
                    }}
                  >
                    <StepLabel {...labelProps}>
                      {lav.Cognome + " " + lav.Nome + " - " + lav.Descrizione}{" "}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === props.lavoratori.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  Visita Massive completate!
                </Typography>
                {/*<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>ESCI</Button>
      </Box> pulsante esci al momento non ci serve*/}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {required.tipo || required.data || required.medico ? (
                        <p style={{ color: "#FF2768" }}>
                          controlla i campi obbligatori!
                        </p>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Medico
                        </InputLabel>
                        <Select
                          error={required.medico}
                          helperText={
                            required.medico ? "Medico obbligatorio" : ""
                          }
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={medicoSel}
                          label="Medico"
                          onChange={(e) => setMedicoSel(e.target.value)}
                        >
                          {medici?.map((medico) => (
                            <MenuItem key={medico.ID} value={medico.ID}>
                              {medico.Descrizione}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <DatePicker
                          ampmInClock={false}
                          renderInput={(props) => (
                            <TextField {...props} error={required.data} />
                          )}
                          label="Data e ora"
                          value={moment(dataSel)}
                          onChange={(newValue) => {
                            setDataSel(newValue);
                            if (newValue) {
                              let allAccertamenti = accertamenti;
                              let arrayOnlyID = allAccertamenti.map(
                                (acc) => acc.ID
                              );

                              let idProtocolli = selectedACS.map(
                                (s) => s.Accertamenti
                              ); // prendo gli id nel protocollo
                              for (let i = 0; i < selectionModel.length; i++) {
                                let presente = idProtocolli.indexOf(
                                  selectionModel[i].ID
                                );

                                let index = arrayOnlyID.indexOf(
                                  selectionModel[i].ID
                                );

                                if (presente >= 0) {
                                  allAccertamenti[index].Scadenza = moment(
                                    newValue
                                  ).add(selectedACS[presente].Cadenze, "M");
                                  selectionModel[i].Scadenza = moment(
                                    newValue
                                  ).add(selectedACS[presente].Cadenze, "M");
                                } else {
                                  allAccertamenti[index].Scadenza =
                                    moment(newValue);
                                  selectionModel[i].Scadenza = moment(newValue);
                                }
                              }
                              setAccertamenti(allAccertamenti);
                            }
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Tipologia Visita
                        </InputLabel>
                        <Select
                          error={required.tipo}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={tipologiaSel}
                          label="Tipologia Visita"
                          helperText={
                            required.data ? "Tipologia obbligatoria" : ""
                          }
                          onChange={(e) => setTipologiaSel(e.target.value)}
                        >
                          {props.tipologiaVisita?.map((tipologia) => (
                            <MenuItem key={tipologia.ID} value={tipologia.ID}>
                              {tipologia.Descrizione}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ height: 400, width: "100%" }}>
                        <DataGridPro
                          components={{
                            Toolbar: CustomToolbar,
                            Footer: CustomFooterPersonalized,
                          }}
                          slots={{
                            loadingOverlay: LinearProgress,
                          }}
                          loading={loading}
                          disableSelectionOnClick
                          checkboxSelection
                          disableColumnMenu={true}
                          localeText={
                            itIT.components.MuiDataGrid.defaultProps.localeText
                          }
                          rows={accertamenti}
                          getRowId={(accertamenti) => accertamenti.ID}
                          columns={columns}
                          selectionModel={selectionModel?.map((sel) => sel.ID)}
                          onSelectionModelChange={(ids) => {
                            const selectedIDs = new Set(ids);
                            const selectedRowData = accertamenti.filter((row) =>
                              selectedIDs.has(row.ID)
                            );
                            //qua fai la magia con le date

                            let allAccertamenti = accertamenti;
                            let arrayOnlyID = allAccertamenti.map(
                              (acc) => acc.ID
                            );

                            let idProtocolli = selectedACS.map(
                              (s) => s.Accertamenti
                            ); // prendo gli id nel protocollo
                            for (let i = 0; i < selectedRowData.length; i++) {
                              let presente = idProtocolli.indexOf(
                                selectedRowData[i].ID
                              );

                              let index = arrayOnlyID.indexOf(
                                selectedRowData[i].ID
                              );

                              if (presente >= 0) {
                                allAccertamenti[index].Scadenza = moment(
                                  dataSel
                                ).add(selectedACS[presente].Cadenze, "M");
                              } else {
                                allAccertamenti[index].Scadenza =
                                  moment(dataSel);
                              }
                            }
                            setAccertamenti(allAccertamenti);

                            setSelectionModel(selectedRowData);
                          }}
                          onCellEditCommit={(params, event) => {
                            let row = { ...params.row };
                            if (params.field === "Scadenza") {
                              row[params.field] = moment(params.value).format(
                                "YYYY-MM-DD"
                              );
                            } else {
                              row[params.field] = params.value;
                            }

                            let selAcc = [...selectionModel];
                            let findIndex = selAcc.findIndex(
                              (acc) => acc.ID == params.row.ID
                            ); //trovo la posizione
                            if (findIndex !== -1) {
                              selAcc.splice(findIndex, 1);
                            }
                            selAcc.push(row);
                            setSelectionModel(selAcc);
                          }}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Esito
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={esitoSel}
                          label="Esito"
                          onChange={(e) => setEsitoSel(e.target.value)}

                          //onChange={handleChange}
                        >
                          {props.esiti?.map((esito) => (
                            <MenuItem key={esito.ID} value={esito.ID}>
                              {esito.Descrizione}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Luogo"
                        multiline
                        value={luogoSel}
                        onChange={(e) => setLuogoSel(e.target.value)}
                        sx={{ marginTop: "20px" }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Note"
                        multiline
                        value={noteSel}
                        onChange={(e) => setNoteSel(e.target.value)}
                        sx={{ marginTop: "20px" }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </React.Fragment>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid
            xs={12}
            item
            sx={{
              display: "flex",
              mt: 2,
              justifyContent: "space-between",
              px: 2,
            }}
            fullWidth
          >
            <Button
              variant="contained"
              className="ButtonMiller"
              startIcon={<Close />}
              onClick={handleClose}
              color="error"
            >
              Esci
            </Button>

            {/* <Button onClick={handleNext}>
              {activeStep === props.lavoratori.length - 1 ? "Fine" : "Avanti"}
            </Button> */}
            {activeStep === props.lavoratori.length - 1 ? (
              <Button
                variant="contained"
                className="ButtonMiller"
                startIcon={<DoneAll />}
                onClick={handleNext}
                color="success"
              >
                Fine
              </Button>
            ) : (
              <Button
                variant="contained"
                className="ButtonMiller"
                startIcon={<ArrowForward />}
                onClick={handleNext}
                color="success"
              >
                Avanti
              </Button>
            )}

            <Button
              variant="contained"
              className="ButtonMiller"
              startIcon={<Check />}
              onClick={handleSave}
              color="success"
            >
              Salva
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const ageFromCf = (cf) => {
  let mesi = {
    A: 0,
    B: 1,
    C: 2,
    D: 3,
    E: 4,
    H: 5,
    L: 6,
    M: 7,
    P: 8,
    R: 9,
    S: 10,
    T: 11,
  };

  let annoNascita = cf.substring(6, 8);
  let mese = mesi[cf.charAt(8)];
  let giornoNascita =
    parseInt(cf.substring(9, 11)) > 40
      ? parseInt(cf.substring(9, 11)) - 40
      : parseInt(cf.substring(9, 11));
  let yearTwoDigits = parseInt(new Date().getFullYear().toString().substr(-2));

  let year =
    yearTwoDigits > annoNascita
      ? 2000 + parseInt(annoNascita)
      : 1900 + parseInt(annoNascita);

  return calcAge(new Date(year, mese, giornoNascita));
  function calcAge(birthday) {
    birthday = new Date(birthday);
    let today = new Date();

    var years = today.getFullYear() - birthday.getFullYear();

    if (
      today.getMonth() < birthday.getMonth() ||
      (today.getMonth() == birthday.getMonth() &&
        today.getDate() < birthday.getDate())
    ) {
      years--;
    }

    return years;
  }
};
