import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import unsplash from "../api/unsplash";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
 GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import {
  IconButton,
  Grid,
  DialogContentText,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  Card,
  CardContent,
  Box,
  Zoom,
  LinearProgress,
} from "@mui/material";
import { Download, Delete, Close, Check, PushPin } from "@mui/icons-material";
import GlobalSnackbar from "./Snackbar";
import Edit from "@mui/icons-material/Edit";
import moment from "moment";
import VIsiteMedicheAssociaVisita from "./VIsiteMedicheAssociaVisita";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});
const ext = {
  doc: "application/msword",
  dot: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  dotx: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  docm: "application/vnd.ms-word.document.macroEnabled.12",
  dotm: "application/vnd.ms-word.template.macroEnabled.12",
  xls: "application/vnd.ms-excel",
  xlt: "application/vnd.ms-excel",
  xla: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xltx: "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  xlsm: "application/vnd.ms-excel.sheet.macroEnabled.12",
  xltm: "application/vnd.ms-excel.template.macroEnabled.12",
  xlam: "application/vnd.ms-excel.addin.macroEnabled.12",
  xlsb: "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  ppt: "application/vnd.ms-powerpoint",
  pot: "application/vnd.ms-powerpoint",
  pps: "application/vnd.ms-powerpoint",
  ppa: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  potx: "application/vnd.openxmlformats-officedocument.presentationml.template",
  ppsx: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  ppam: "application/vnd.ms-powerpoint.addin.macroEnabled.12",
  pptm: "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  potm: "application/vnd.ms-powerpoint.template.macroEnabled.12",
  ppsm: "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
  mdb: "application/vnd.ms-access",
  png: "application/png",
  pdf: "application/pdf",
};

const Anomalie = (props) => {
  const [allegati, setAllegati] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openBackdrop, setOpenBackDrop] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const [allegato, setAllegato] = useState();
  const [anoamliaSel, setAnomaliaSel] = useState();
  const [currentBlob, setCurrentBlob] = useState(null);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const downloadFile = async (id, estensione, descrizione, operazione) => {
    let r = await unsplash.post(
      "ftp",
      {
        file: id + estensione,
        nomeOriginale: descrizione.includes(estensione)
          ? descrizione
          : descrizione + estensione,
        operazione: "download",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );

    if (r.data === "errore") {
      setCurrentBlob("errore");
      return;
    }
    await unsplash
      .get("ftp", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        responseType: "blob",
        params: {
          nomeOriginale: descrizione.includes(estensione)
            ? descrizione
            : descrizione + estensione,
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: ext[estensione.replace(".", "")] })
        ); //prendo il file, lo rendo blob lo aggancio a un link e scarico

        const link = document.createElement("a");
        link.href = url;
        if (operazione === "download") {
          link.setAttribute(
            "download",
            descrizione.includes(estensione)
              ? descrizione
              : descrizione + estensione
          );
          document.body.appendChild(link);
          link.click();
        }
        if (operazione === "visualizza") {
          if (estensione === ".pdf") {
            setCurrentBlob(url);
          } /*else{

        var img = document.createElement('img');
        let image = window.URL.createObjectURL(new Blob([response.data]));
        img.src = image;
        img.onload = function() {
          var w = img.width;
          var h = img.height;
        
        }
        

       
       
        document.getElementById("prova").innerHTML = "<img src="+image+" />";
        setOpenBackDrop(true);
      }*/
        }
      });
  };

  const handleClose = () => {
    setOpenBackDrop(false);
  };

  const handleDelete = async (id, estensione, operazione) => {
    try {
      await unsplash.delete("allegati", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: id,
        },
      });

      await unsplash.post(
        "ftp",
        { file: id + estensione, operazione: "delete" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setCurrentBlob(null);
      setSnack({
        severity: "success",
        messaggio: "Anomalia eliminata con successo",
      });
      setOpenSnack(true);
      reloadAfterUpload();
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione dell'Anomalia",
      });
      setOpenSnack(true);
    }
  };

  const handleSaveData = async (allegato) => {
    try {
      let responseModifica = await unsplash.put(
        "allegati",
        { nome: allegato.Descrizione, id: allegato.ID },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({
        severity: "success",
        messaggio: "Modifica effettuata con successo",
      });
      setOpenSnack(true);
      reloadAfterUpload();
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante la modifica",
      });
      setOpenSnack(true);
    }
  };
  const handleSaveAssociamento = async (visita) => {
    try {
      let responseModifica = await unsplash.put(
        "anomalie",
        {
          visitaMedica: visita.ID,
          lavoratore: visita.IDLavoratore,
          allegato: anoamliaSel.ID,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({
        severity: "success",
        messaggio: "Visita associata effettuata con successo",
      });
      setOpenSnack(true);
      setCurrentBlob(null);
      reloadAfterUpload();
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante la modifica",
      });
      setOpenSnack(true);
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..."/>
        </Grid>
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      filterable: false,
      field: "Azioni",
      headerName: "Azioni",
      hide: false,
      width: 150,
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={async () => {
              await setAllegato({
                ID: params.row.ID,
                Descrizione: params.row.Descrizione,
              });
              document.getElementById("editDoc").click();
            }}
          >
            <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
          </IconButton>
          <IconButton
            aria-label="edit"
            onClick={() =>
              downloadFile(
                params.row.ID,
                params.row.Estensione,
                params.row.Descrizione,
                "download"
              )
            }
          >
            <Download
              style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
            />
          </IconButton>

          {/* <IconButton
            aria-label="edit"
            onClick={() =>
              handleDelete(params.row.ID, params.row.Estensione, "delete")
            }
          >
            <Delete
              style={{ height: "2rem", width: "2rem", color: "#FF2768" }}
            />
          </IconButton> */}
          <DeleteDialog anomalia={params.row} myFunction={handleDelete} />
        </>
      ),
    },
    { field: "ID", headerName: "ID", flex: 1 },
    { field: "Descrizione", headerName: "Descrizione", flex: 1 },

    {
      field: "DataInserimento",
      headerName: "Data Inserimento",
      flex: 1,
      renderCell: (params) => (
        <div>{moment(params.row.DataInserimento).format("DD/MM/YYYY")}</div>
      ),
    },
  ];

  useEffect(async () => {
    let responseAziendali = await unsplash.get("allegati", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { colonna: "Anomalie" },
    });
    setAllegati(responseAziendali.data.data);
    setLoading(false);
  }, []);

  const reloadAfterUpload = async () => {
    let responseAziendali = await unsplash.get("allegati", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { colonna: "Anomalie" },
    });

    setAllegati(responseAziendali.data.data);
  };

  return (
    <>
      <Grid item xs={12}>
        <EditName
          id="editDoc"
          operazione="Modifica"
          allegato={allegato}
          saveFunction={handleSaveData}
        />
        <GlobalSnackbar
          severity={snack.severity}
          messaggio={snack.messaggio}
          openSnack={openSnack}
          handleCloseSnack={handleCloseSnack}
        />

        <Card sx={{ boxShadow: "none" }}>
          <CardContent>
            <Box
              sx={{
                height: { xs: "80vh", md: "70vh", xl: "80vh" },
                width: 1,
              }}
            >
              <DataGridPro
              components={{
                Toolbar: CustomToolbar,
                  Footer: CustomFooterPersonalized,
              }}
              slots={{
                loadingOverlay: LinearProgress,
              }}
              loading={loading}
                initialState={{ pinnedColumns: { left: ["Azioni"] } }}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                rows={allegati}
                getRowId={(allegati) => allegati.ID}
                columns={columns}
                onRowClick={(params) => {
                  setCurrentBlob(null);
                  downloadFile(
                    params.row.ID,
                    params.row.Estensione,
                    params.row.Descrizione,
                    "visualizza"
                  );
                  setAnomaliaSel(params.row);
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ boxShadow: "none" }}>
          <CardContent>
            {currentBlob ? (
              currentBlob === "errore" ? (
                <div style={{ width: "100%" }}>
                  <center>
                    <VisiteMedichePop
                      handleSaveAssociamento={handleSaveAssociamento}
                    />
                    <br />
                    <b>NESSUN FILE TROVATO LEGATO A QUESTA ANOMALIA</b>
                  </center>
                </div>
              ) : (
                <>
                  <center>
                    <VisiteMedichePop
                      handleSaveAssociamento={handleSaveAssociamento}
                    />
                  </center>
                  <iframe
                    src={currentBlob}
                    title="Idoneità"
                    style={{
                      width: "100%",
                      height: "600px",
                      marginTop: "10px",
                    }}
                  ></iframe>
                </>
              )
            ) : (
              ""
            )}
          </CardContent>
        </Card>
      </Grid>
    </>
  );

  /*lavoratori?.map(lavoratore =>
  
  
  
  <p>{lavoratore.Nome} {lavoratore.Cognome} </p>)*/
};

export default Anomalie;

const EditName = (props) => {
  const [open, setOpen] = useState(false);
  const [allegato, setAllegato] = useState({
    Descrizione: "",
    ID: "",
  });
  const [validation, setValidation] = useState(false);
  const handleClickOpen = () => {
    setValidation(false);
    setAllegato(props.allegato);
    setOpen(true);
  };

  const handleClose = () => {
    //setSede(newSede);

    setOpen(false);
  };
  const handleChange = (e) => {
    let a = { ...allegato };
    a[e.target.name] = e.target.value;
    setAllegato(a);
  };

  const handleSave = () => {
    if (allegato.Descrizione === "" || allegato.Descrizione === null) {
      setValidation(true);
    } else {
      props.saveFunction(allegato);
    }
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        id={props.id}
        style={{ display: "None" }}
      >
        Open form dialog
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        fullWidth
      >
        <DialogTitle>{props.operazione}</DialogTitle>
        <DialogContent>
          <Grid container xs={12}>
            <Grid item xs={12}>
              <TextField
                value={allegato.Descrizione}
                error={validation}
                helperText={validation ? "Descrizione obbligatoria" : ""}
                margin="dense"
                name="Descrizione"
                label="Descrizione"
                type="text"
                fullWidth
                required
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={handleSave}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const VisiteMedichePop = (props) => {
  const [open, setOpen] = React.useState(false);
  const [visitaSel, setVisitaSel] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    props.handleSaveAssociamento(visitaSel);
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        className="ButtonMiller"
        startIcon={<PushPin />}
        onClick={handleClickOpen}
      >
        Associa Visita
      </Button>
      <Dialog
        TransitionComponent={Transition}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth="lg"
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">Associa Visita</DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <DialogContentText id="alert-dialog-description">
            <VIsiteMedicheAssociaVisita setVisitaSel={setVisitaSel} />
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: 2,
            paddingLeft: 2,
            paddingRight: 4,
          }}
        >
          <Button
            onClick={handleClose}
            variant="contained"
            className="ButtonMiller"
          >
            Chiudi
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            className="ButtonMiller"
          >
            Associa
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const DeleteDialog = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare l'anomalia selezionato?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare il seguente anomalia :
            <br />
            <b>{props.anomalia.Descrizione}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button
            onClick={() =>
              props.myFunction(
                props.anomalia.ID,
                props.anomalia.Estensione,
                "delete"
              )
            }
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
