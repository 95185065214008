import React, { useEffect, useState } from "react";
import unsplash from "../api/unsplash";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import {
  IconButton,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  ButtonGroup,
  Autocomplete,
  Zoom,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import "../style/style.css";
import jsPDF from "jspdf";
import { Edit, Delete, Add, Close, Check, Print } from "@mui/icons-material";
import GlobalSnackbar from "./Snackbar";
import { LocalizationProvider,DatePicker,PickersDay,TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment as DateAdapter} from '@mui/x-date-pickers/AdapterMoment'
import { fontVerdanaNormal } from "../style/verdana-normalW";
import { fontVerdanaBold } from "../style/verdana-bold";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

let newDettaglioMedicina = {
  Blocco: "",
  Referente: "",
  Medico: null,
  Categoria: "",
  EmailReferente: "",
  TelefonoReferente: "",
};
const DettaglioMedicina = (props) => {
  const [dettaglioMedicina, setDettaglioMedicina] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sedeEdit, setSedeEdit] = useState([]);
  const [infoSede, setInfoSede] = useState([]);

  const [dialogStampa, setDialogStampa] = useState(false);
  const [medicoSel, setMedicoSel] = useState(null);
  const [datiStampa, setDatiStampa] = useState({
    Medico: "",
    DatoreDiLavoro: "",
    Indirizzo: "",
    Data: null,
  });

  const [openSnack, setOpenSnack] = useState(false);
  const [mediciCliente, setMediciCliente] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "200",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={async () => {
              await setSedeEdit({
                ID: params.row.ID,
                Blocco: params.row.Blocco,
                Referente: params.row.Referente,
                Medico: params.row.Medico,
                Categoria: params.row.Categoria,
                EmailReferente: params.row.EmailReferente,
                TelefonoReferente: params.row.TelefonoReferente,
              });
              document.getElementById("edit").click();
            }}
          >
            <Edit style={{ height: "2rem", width: "2rem", color: "#2264ae" }} />
          </IconButton>
          <DeleteDialog
            id={params.row.ID}
            blocco={params.row.Blocco}
            sede={params.row.Descrizione}
            myFunction={deleteSede}
          />
        </>
      ),
    },

    { field: "Blocco", headerName: "Blocco", width: 200 },
    { field: "NomeMedico", headerName: "Medico", hide: false, width: "200" },
    { field: "Referente", headerName: "Referente", width: 200 },
    { field: "Categoria", headerName: "Categoria", width: 200 },
    { field: "EmailReferente", headerName: "Email Referente", width: "200" },
    {
      field: "TelefonoReferente",
      headerName: "Telefono Referente",
      width: 200,
    },
  ];

  const handleSaveData = async (
    dettaglioMedicina,
    operazione,
    dettaglioMedicinaBackup
  ) => {
    try {
      if (operazione === "Inserimento") {
        //inserisco il nuovo
        try {
          dettaglioMedicina.IDSede = props.sede;
          let response = await unsplash.post(
            "dettaglioMedicina",
            { dettaglioMedicina: dettaglioMedicina },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );

          dettaglioMedicina.ID = response.data.message.insertId;

          await unsplash.post(
            "storico",
            {
              tabella: "DettaglioMedicina",
              key: dettaglioMedicina.ID,
              utente: sessionStorage.getItem("username"),
              after: dettaglioMedicina,
              before: [],
              operazione: "Inserimento",
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );

          setSnack({
            severity: "success",
            messaggio: "Dettaglio creato con successo",
          });
          setOpenSnack(true);
        } catch {
          setSnack({
            severity: "error",
            messaggio: "Errore durante l'inserimento",
          });
          setOpenSnack(true);
        }
      }

      if (operazione === "Modifica") {
        //inserisco il nuovo
        try {
          let responseModifica = await unsplash.put(
            "dettaglioMedicina",
            { dettaglioMedicina: dettaglioMedicina },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );

          await unsplash.post(
            "storico",
            {
              tabella: "DettaglioMedicina",
              key: dettaglioMedicina.ID,
              utente: sessionStorage.getItem("username"),
              after: dettaglioMedicina,
              before: dettaglioMedicinaBackup,
              operazione: "Modifica",
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );

          setSnack({
            severity: "success",
            messaggio: "Modifica effettuata con successo",
          });
          setOpenSnack(true);
        } catch {
          setSnack({
            severity: "error",
            messaggio: "Errore durante la modifica",
          });
          setOpenSnack(true);
        }
      }

      let responseSedi = await unsplash.get("dettaglioMedicina", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { id: props.sede },
      });

      setDettaglioMedicina(responseSedi.data.data);
    } catch (error) {}
  };

  const deleteSede = async (id) => {
    try {
      await unsplash.delete("dettaglioMedicina", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: id,
        },
      });

      let responseSedi = await unsplash.get("dettaglioMedicina", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { id: props.sede },
      });

      setDettaglioMedicina(responseSedi.data.data);
      setSnack({
        severity: "success",
        messaggio: "Dettaglio medicina eliminato con successo",
      });
      setOpenSnack(true);
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione",
      });
      setOpenSnack(true);
    }
  };

  const exportPDFNomininaMC = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size, true);
    const imgLogo = new Image();
    var imgDef = props.logo;
    if (props.logo?.split("/")[0] === "data:image") {
      doc.addImage(
        imgDef,
        "PNG",
        doc.internal.pageSize.width / 2 - 160 / 2,
        10,
        160,
        90
      ); //da riguardare se così o al contrario
    }

    //AGGIUNGO I FONT CHE HO IMPORTATO
    doc.addFileToVFS("verdana-normal.ttf", fontVerdanaNormal);
    doc.addFileToVFS("verdana-bold.ttf", fontVerdanaBold);
    doc.addFont("verdana-normal.ttf", "verdana", "normal");
    doc.addFont("verdana-bold.ttf", "verdana", "bold");

    doc.setFont("verdana", "bold");
    doc.setFontSize(11);
    let sesso = "";
    if (medicoSel?.Sesso === 0) {
      sesso = "Egregio Dott.";
    } else if (medicoSel?.Sesso === 1) {
      sesso = "Egregia Dott.ssa";
    }
    doc.text(
      doc.splitTextToSize(`${sesso}`, doc.internal.pageSize.getWidth() - 60),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`${sesso}`) * doc.internal.getFontSize() -
        40,
      130
    );
    doc.text(
      doc.splitTextToSize(
        `${medicoSel?.Cognome + " " + medicoSel?.Nome}`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(
          `${medicoSel?.Cognome + " " + medicoSel?.Nome}`
        ) *
          doc.internal.getFontSize() -
        40,
      145
    );
    doc.text(
      doc.splitTextToSize(
        `${datiStampa?.Indirizzo ? datiStampa?.Indirizzo : ""}`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(
          `${datiStampa?.Indirizzo ? datiStampa?.Indirizzo : ""}`
        ) *
          doc.internal.getFontSize() -
        40,
      160
    );

    doc.text(
      doc.splitTextToSize(
        `Oggetto: designazione del Medico Competente ${
          medicoSel.MedicoCoordinatore === 0 ? "coordinato" : "coordinatore"
        } secondo quanto previsto dal Decreto Legislativo 81/2008 in materia di tutela della salute e della sicurezza nei luoghi di lavoro`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      195
    );

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `In relazione a quanto stabilito dall’art. 2, comma 1, lettera h), dall’art. 18, comma 1, lettera a), nonché dall’art. 25 e dal Titolo I, Capo III, Sezione V del D. Lgs. 81/2008 ed in considerazione delle Sue qualifiche professionali, il sottoscritto ${datiStampa.DatoreDiLavoro} in qualità di Datore di Lavoro ai sensi del D. Lgs. 81/08, con la presente La nomina formalmente Medico Competente della società ${props.RagioneSociale}.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      250
    );
    doc.text(
      doc.splitTextToSize(
        `SEDE: ${props.bloccoSede}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      326
    );
    doc.text(
      doc.splitTextToSize(
        `Per lo svolgimento dell’incarico affidatoLe sarà informato circa:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      347
    );

    doc.text(
      doc.splitTextToSize(`a)`, doc.internal.pageSize.getWidth() - 80),
      45,
      368
    );
    doc.text(
      doc.splitTextToSize(
        `La natura dei rischi;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      65,
      368
    );
    doc.text(
      doc.splitTextToSize(`b)`, doc.internal.pageSize.getWidth() - 80),
      45,
      379
    );
    doc.text(
      doc.splitTextToSize(
        `L’organizzazione del lavoro, la programmazione e l’attuazione delle misure preventive e protettive;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      65,
      379
    );
    doc.text(
      doc.splitTextToSize(`c)`, doc.internal.pageSize.getWidth() - 80),
      45,
      402
    );
    doc.text(
      doc.splitTextToSize(
        `La descrizione degli impianti e dei processi produttivi;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      65,
      402
    );
    doc.text(
      doc.splitTextToSize(`d)`, doc.internal.pageSize.getWidth() - 80),
      45,
      413
    );
    doc.text(
      doc.splitTextToSize(
        `I dati riguardanti le malattie professionali e quelli relativi agli infortuni sul lavoro che comportino un’assenza dal lavoro di almeno un giorno, escluso quello dell’evento;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      65,
      413
    );
    doc.text(
      doc.splitTextToSize(`e)`, doc.internal.pageSize.getWidth() - 80),
      45,
      435
    );
    doc.text(
      doc.splitTextToSize(
        `I provvedimenti adottati dagli organi di vigilanza.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      65,
      435
    );

    doc.text(
      doc.splitTextToSize(
        `Le precisiamo che i compiti relativi a tale incarico sono relativi a quanto previsto dalla normativa vigente in materia di salute e sicurezza sul lavoro. In particolare dovrà:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      456
    );

    //ELENCO PUNTATO
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      488
    );
    doc.text(
      doc.splitTextToSize(
        "Collaborare con il datore di lavoro e con il servizio di prevenzione e protezione alla valutazione dei rischi anche ai fini della programmazione, ove necessario, della sorveglianza sanitaria, alla predisposizione della attuazione delle misure per la tutela della salute e della integrità psico-fisica dei lavoratori, all’attività di formazione e informazione nei confronti dei lavoratori, per la parte di competenza, e alla organizzazione del servizio di primo soccorso considerando i particolari tipi di lavorazione ed esposizione e le peculiari modalità organizzative del lavoro. Collaborare inoltre alla attuazione e valorizzazione di programmi volontari di “promozione della salute”, secondo i principi della responsabilità sociale;",
        doc.internal.pageSize.getWidth() - 100
      ),
      65,
      488
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      600
    );
    doc.text(
      doc.splitTextToSize(
        "Programmare ed effettuare la sorveglianza sanitaria di cui all’Art. 41 del D. Lgs. 81/2008 attraverso protocolli sanitari definiti in funzione dei rischi specifici e tenendo in considerazione gli indirizzi scientifici più avanzati;",
        doc.internal.pageSize.getWidth() - 100
      ),
      65,
      600
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      636
    );
    doc.text(
      doc.splitTextToSize(
        `Istituire, aggiornare e custodire, sotto la propria responsabilità, una cartella sanitaria e di rischio per ogni lavoratore sottoposto a sorveglianza sanitaria; tale cartella è conservata con salvaguardia del decreto professionale e, salvo il tempo strettamente necessario per l’esecuzione della sorveglianza sanitaria e la trascrizione dei relativi risultati, presso ${
          datiStampa?.Indirizzo ? datiStampa?.Indirizzo : ""
        };`,
        doc.internal.pageSize.getWidth() - 100
      ),
      65,
      636
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      697
    );
    doc.text(
      doc.splitTextToSize(
        `Consegnare al datore di lavoro, alla cessazione dell’incarico, la documentazione sanitaria in suo possesso, nel rispetto delle disposizioni secondo i termini del vigente D.Lgs. 196/2003 in materia di privacy e del Regolamento europeo 679/2016 – GDPR-, e con salvaguardia del segreto professionale;`,
        doc.internal.pageSize.getWidth() - 100
      ),
      65,
      697
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      744
    );
    doc.text(
      doc.splitTextToSize(
        `Consegnare al lavoratore, alla cessazione del rapporto di lavoro, copia della cartella sanitaria e di rischio e fornirgli le informazioni necessarie relative alla conservazione della medesima; l’originale della cartella sanitaria e di rischio va conservata, nel rispetto di quanto disposto del vigente D.Lgs. 196/2003 in materia di privacy e del Regolamento europeo 679/2016 – GDPR, da parte del datore di lavoro, per almeno dieci anni, salvo il diverso termine previsto da altre disposizioni del presente decreto;`,
        doc.internal.pageSize.getWidth() - 100
      ),
      65,
      744
    );
    //NUOVA PAGINA
    doc.addPage();
    if (props.logo?.split("/")[0] === "data:image") {
      doc.addImage(
        imgDef,
        "PNG",
        doc.internal.pageSize.width / 2 - 160 / 2,
        10,
        160,
        90
      ); //da riguardare se così o al contrario
    }
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      150
    );
    doc.text(
      doc.splitTextToSize(
        `Fornire informazioni ai lavoratori sul significato della sorveglianza sanitaria cui sono sottoposti e, nel caso di esposizione ad agenti con effetti a lungo termine, sulla necessità di sottoporsi ad accertamenti sanitari anche dopo la cessazione dell’attività che comporta l’esposizione a tali agenti; fornire a richiesta, informazioni analoghe ai rappresentanti dei lavoratori per la sicurezza;`,
        doc.internal.pageSize.getWidth() - 100
      ),
      65,
      150
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      211
    );
    doc.text(
      doc.splitTextToSize(
        `Informare ogni lavoratore interessato dei risultati della sorveglianza sanitaria di cui all’Art. 41 del D. Lgs. 81/2008 e, a richiesta dello stesso, rilasciargli copia della documentazione sanitaria;`,
        doc.internal.pageSize.getWidth() - 100
      ),
      65,
      211
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      246
    );
    doc.text(
      doc.splitTextToSize(
        `Comunicare per iscritto, in occasione delle riunioni di cui all’Art. 35 del D. Lgs. 81/2008, al datore di lavoro, al Responsabile del Servizio di Prevenzione e Protezione dai rischi, ai Rappresentanti dei Lavoratori per la Sicurezza, i risultati anonimi collettivi della sorveglianza sanitaria effettuata e fornire indicazioni sul significato di detti risultati ai fini dell’attuazione delle misure per la tutela della salute e della integrità psico-fisica dei lavoratori;`,
        doc.internal.pageSize.getWidth() - 100
      ),
      65,
      246
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      320
    );
    doc.text(
      doc.splitTextToSize(
        `Visitare gli ambienti di lavoro almeno una volta all’anno o a cadenza diversa, che stabilisce in base alla valutazione dei rischi; la indicazione di una periodicità diversa dall’annuale deve essere comunicata al datore di lavoro ai fini della sua applicazione nel documento di valutazione dei rischi;`,
        doc.internal.pageSize.getWidth() - 100
      ),
      65,
      320
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      368
    );
    doc.text(
      doc.splitTextToSize(
        `Partecipare alla programmazione del controllo dell’esposizione dei lavoratori i cui risultati gli sono forniti con tempestività ai fini della valutazione del rischio e della sorveglianza sanitaria;`,
        doc.internal.pageSize.getWidth() - 100
      ),
      65,
      368
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      404
    );
    doc.text(
      doc.splitTextToSize(
        `In occasione delle visite di assunzione, richiede al lavoratore la cartella sanitaria rilasciata dal precedente datore di lavoro e tiene conto del suo contenuto ai fini della formulazione del giudizio di idoneità;`,
        doc.internal.pageSize.getWidth() - 100
      ),
      65,
      404
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      440
    );
    doc.text(
      doc.splitTextToSize(
        `In caso di impedimento per gravi e motivate ragioni, comunica per iscritto al datore di lavoro il nominativo di un sostituto, in possesso dei requisiti di cui all’articolo 38, per l’adempimento degli obblighi di legge durante il relativo intervallo temporale specificato.`,
        doc.internal.pageSize.getWidth() - 100
      ),
      65,
      440
    );

    //FOOTER
    doc.text(
      doc.splitTextToSize(
        `Ella è dunque tenuto all’espletamento di tutti i compiti definiti all’Art. 25 e al Titolo I, Capo III, Sezione V del D. Lgs. 81/2008 secondo le modalità e le condizioni di svolgimento dell’attività di Medico Competente precisate dall’Art. 39 del D. Lgs. 81/2008.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      493
    );
    doc.text(
      doc.splitTextToSize(
        `L’accettazione a tale nomina Le comporta l’assoggettamento a stabilite sanzioni in caso di mancato adempimento degli impegni da Lei assunti così come stabilito dall’Art. 58 del D. Lgs. 81/2008.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      536
    );
    doc.text(
      doc.splitTextToSize(
        `Voglia restituire copia della presente firmata quale accettazione da parte Sua della nomina a Medico Competente.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      579
    );
    doc.text(
      doc.splitTextToSize(
        `Il Datore di Lavoro`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`Il Datore di Lavoro`) *
          doc.internal.getFontSize() -
        40,
      621
    );
    doc.text(
      doc.splitTextToSize(
        `________________________`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`________________________`) *
          doc.internal.getFontSize() -
        40,
      646
    );

    doc.text(
      doc.splitTextToSize(
        `Per accettazione di quanto sopra e per conferma della piena conoscenza circa i compiti, le funzioni e gli obblighi inerenti la presente nomina qui accetta.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      676
    );
    doc.text(
      doc.splitTextToSize(
        `Il Medico Competente`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`Il Medico Competente`) *
          doc.internal.getFontSize() -
        40,
      718
    );
    doc.text(
      doc.splitTextToSize(
        `________________________`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`________________________`) *
          doc.internal.getFontSize() -
        40,
      743
    );
    doc.text(
      doc.splitTextToSize(
        `Luogo ${props.bloccoSede}, data ${
          datiStampa.Data ? moment(datiStampa.Data).format("DD/MM/YYYY") : ""
        }`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      773
    );
    doc.output("pdfobjectnewwindow", "attestato.pdf");
    setDialogStampa(false);
    setDatiStampa({
      Medico: "",
      DatoreDiLavoro: "",
      Indirizzo: "",
      Data: null,
    });
    setMedicoSel(null);
  };

  useEffect(() => {
    async function fetchData() {
      let responseSedi = await unsplash.get("dettaglioMedicina", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { id: props.sede },
      });
      setDettaglioMedicina(responseSedi.data.data);
      setLoading(false);
      setInfoSede(props.ragioneSociale);

      let responseMediciCliente = await unsplash.get(
        "medici",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { id: props.idAzienda },
        }
      );

      setMediciCliente(responseMediciCliente.data.data);
    }
    fetchData();
  }, []);
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid
        container
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="flex-end"
      >
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
        >
          <Button
            startIcon={<Add />}
            sx={{ color: "white" }}
            variant="outlined"
            className="ButtonMiller"
            onClick={() => document.getElementById("add").click()}
          >
            AGGIUNGI
          </Button>
          <Button
            startIcon={<Print />}
            sx={{ color: "white" }}
            variant="outlined"
            className="ButtonMiller"
            onClick={() => setDialogStampa(true)}
          >
            STAMPA
          </Button>
        </ButtonGroup>
      </Grid>
      <FormDialog
        id="edit"
        operazione="Modifica"
        dettaglioMedicina={sedeEdit}
        saveFunction={handleSaveData}
        mediciCliente={mediciCliente}
      />
      <FormDialog
        id="add"
        operazione="Inserimento"
        dettaglioMedicina={newDettaglioMedicina}
        saveFunction={handleSaveData}
        mediciCliente={mediciCliente}
      />
      <div style={{ height: "60vh", width: "100%" }}>
        <DataGridPro
          initialState={{ pinnedColumns: { left: ["Actions"] } }}
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooterPersonalized,
          }}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={loading}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rows={dettaglioMedicina}
          getRowId={(d) => d.ID}
          columns={columns}
        />
      </div>
      <Dialog
        open={dialogStampa}
        onClose={() => setDialogStampa(false)}
        TransitionComponent={Transition}
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle style={{ justifyContent: "center", display: "flex" }}>
          Stampa Nomina Medico
        </DialogTitle>
        <DialogContent>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={12} marginTop={1}>
              <Autocomplete
                id="combo-box-demo"
                options={mediciCliente}
                getOptionLabel={(option) => option.Nome + " " + option.Cognome}
                fullWidth
                onChange={(event, newValue) => {
                  if (mediciCliente?.length === 1) {
                    setMedicoSel({ ...newValue, MedicoCoordinatore: 1 });
                  } else {
                    setMedicoSel(newValue);
                  }
                  if (!medicoSel?.IndirizzoSecondario) {
                    setDatiStampa({
                      ...datiStampa,
                      Medico: newValue
                        ? newValue.Nome + " " + newValue.Cognome
                        : null,
                      Indirizzo: newValue?.Indirizzo,
                    });
                  } else {
                    setDatiStampa({
                      ...datiStampa,
                      Medico: newValue
                        ? newValue.Nome + " " + newValue.Cognome
                        : null,
                    });
                  }
                  /* let d = { ...dettaglioMedicina };
                  d.Medico = newValue.ID;
                  setDettaglioMedicina(d); */
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Medico" />
                )}
              />
            </Grid>
            {medicoSel?.IndirizzoSecondario && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Indirizzo
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={datiStampa?.Indirizzo}
                    label="Indirizzo"
                    onChange={(e) =>
                      setDatiStampa({
                        ...datiStampa,
                        Indirizzo: e.target.value,
                      })
                    }
                  >
                    <MenuItem key={0} value={medicoSel?.Indirizzo}>
                      {medicoSel?.Indirizzo}
                    </MenuItem>
                    <MenuItem key={1} value={medicoSel?.IndirizzoSecondario}>
                      {medicoSel?.IndirizzoSecondario}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                value={datiStampa.DatoreDiLavoro}
                margin="dense"
                name="DatoreDiLavoro"
                label="Datore Di Lavoro"
                type="text"
                fullWidth
                variant="outlined"
                onChange={(e) =>
                  setDatiStampa({
                    ...datiStampa,
                    DatoreDiLavoro: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  ampmInClock={false}
                  renderInput={(props) => <TextField {...props} fullWidth />}
                  label="Data"
                  value={moment(datiStampa.Data)}
                  onChange={(newValue) =>
                    setDatiStampa({
                      ...datiStampa,
                      Data: newValue ? newValue.format("YYYY/MM/DD") : null,
                    })
                  }
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
            paddingRight: 5,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={() => {
              setDatiStampa({
                Medico: "",
                DatoreDiLavoro: "",
                Indirizzo: "",
                Data: null,
              });
              setMedicoSel(null);
              setDialogStampa(false);
            }}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={() => {
              if (datiStampa.Medico === "") {
                setSnack({
                  severity: "error",
                  messaggio: "Medico Obbligatorio!",
                });
                setOpenSnack(true);
              } else if (datiStampa.DatoreDiLavoro === "") {
                setSnack({
                  severity: "error",
                  messaggio: "Datore Di Lavoro Obbligatorio!",
                });
                setOpenSnack(true);
              } else if (!datiStampa.Data) {
                setSnack({
                  severity: "error",
                  messaggio: "Data Obbligatoria!",
                });
                setOpenSnack(true);
              } else {
                exportPDFNomininaMC();
              }
            }}
          >
            STAMPA
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DettaglioMedicina;

const FormDialog = (props) => {
  const [open, setOpen] = useState(false);
  const [medicoSel, setMedicoSel] = useState(false);
  const [dettaglioMedicina, setDettaglioMedicina] = useState({
    Blocco: "",
    Referente: "",
    Medico: null,
    Categoria: "",
    EmailReferente: "",
    TelefonoReferente: "",
  });
  const [dettaglioMedicinaBackup, setDettaglioMedicinaBackup] = useState(null);

  const [validation, setValidation] = useState(false);
  const handleClickOpen = () => {
    setValidation(false);
    setDettaglioMedicina(props.dettaglioMedicina);
    setDettaglioMedicinaBackup(props.dettaglioMedicina);
    if (props.operazione === "Modifica") {
      setMedicoSel(
        props.mediciCliente.filter(
          (m) => m.ID === props.dettaglioMedicina.Medico
        )[0]
      );
    } else {
      setMedicoSel(null);
    }

    setOpen(true);
  };

  const handleClose = () => {
    setDettaglioMedicinaBackup(newDettaglioMedicina);

    setOpen(false);
  };
  const handleChange = (e) => {
    let d = { ...dettaglioMedicina };
    d[e.target.name] = e.target.value;
    setDettaglioMedicina(d);
  };

  const handleSave = () => {
    if (dettaglioMedicina.Blocco === "" || dettaglioMedicina.Blocco === null) {
      setValidation(true);
    } else {
      props.saveFunction(
        dettaglioMedicina,
        props.operazione,
        dettaglioMedicinaBackup
      );
      setOpen(false);
    }
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        id={props.id}
        style={{ display: "None" }}
      >
        Open form dialog
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle style={{ justifyContent: "center", display: "flex" }}>
          {props.operazione} Dettaglio Medicina
        </DialogTitle>
        <DialogContent>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={6}>
              <TextField
                error={validation}
                value={dettaglioMedicina.Blocco}
                margin="dense"
                name="Blocco"
                label="Blocco"
                type="text"
                required
                fullWidth
                variant="outlined"
                onChange={handleChange}
                helperText={validation ? "Blocco obbligatorio" : ""}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={dettaglioMedicina.Categoria}
                margin="dense"
                name="Categoria"
                label="Categoria"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={medicoSel}
                id="combo-box-demo"
                options={props.mediciCliente}
                getOptionLabel={(option) => option.Nome + " " + option.Cognome}
                fullWidth
                onChange={(event, newValue) => {
                  setMedicoSel(newValue);
                  let d = { ...dettaglioMedicina };
                  d.Medico = newValue.ID;
                  setDettaglioMedicina(d);
                  //handleChangeCliente(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Medico" />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                value={dettaglioMedicina.Referente}
                margin="dense"
                name="Referente"
                label="Referente"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={dettaglioMedicina.TelefonoReferente}
                margin="dense"
                name="TelefonoReferente"
                label="Telefono Referente"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={dettaglioMedicina.EmailReferente}
                margin="dense"
                name="EmailReferente"
                label="Email Referente"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
            paddingRight: 5,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={handleSave}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const DeleteDialog = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        aria-label="edit"
        onClick={handleClickOpen}
        TransitionComponent={Transition}
      >
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Sei sicuro di voler eliminare la sede selezionata?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare il seguente dettaglio
            medicina:
            <b>{props.blocco}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 2,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={() => props.myFunction(props.id)}>Conferma</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
