import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import unsplash from "../api/unsplash";
import Avatar from "@mui/material/Avatar";
import GlobalSnackbar from "./Snackbar";
import LinearProgress from "@mui/material/LinearProgress";
import Switch from "@mui/material/Switch";

import {
  Grid,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Input,
  CardActionArea,
  CardMedia,
  Divider,
  CardActions,
  InputAdornment,
  ButtonGroup,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Zoom,
  DialogActions,
  DialogContentText,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import { IconButton } from "@mui/material";

import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";

import {
  Add,
  Delete,
  Close,
  Check,
  Search,
  MenuBook,
  FiberNew,
  Circle,
} from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

export const AnagraficaRischi = () => {
  const [anagraficaRischi, setAnagraficaRischi] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anagraficaSelezionata, setAnagraficaSelezionata] = useState([]);

  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={async () => {
              await setAnagraficaSelezionata(params.row);
              document.getElementById("edit").click();
              handleClickOpen();
            }}
          >
            <EditIcon
              style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
            />
          </IconButton>

          <DeleteDialog categoria={params.row} myFunction={handleDelete} />
        </>
      ),
    },
    {
      field: "Descrizione",
      headerName: "Descrizione",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "RiferimentoLegge",
      headerName: "RiferimentoLegge",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  const toggleAlert = () => {
    setOpenAlert(!openAlert);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const reloadAfterUpload = async () => {
    let response = await unsplash.get("anagraficaRischi", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    setAnagraficaRischi(response.data.data);
  };

  const handleSave = async (categ, operazione) => {
    if (operazione === "new") {
      try {
        await unsplash.post(
          "anagraficaRischi",
          {
            categoria: categ,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Rischio creato con successo",
        });
        setOpenSnack(true);

        reloadAfterUpload();
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante la creazione",
        });
        setOpenSnack(true);
      }
      handleClose();
    } else {
      try {
        await unsplash.put(
          "anagraficaRischi",
          { categoria: categ },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Rischio aggiornato con successo",
        });
        setOpenSnack(true);

        reloadAfterUpload();
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'aggiornamento",
        });
        setOpenSnack(true);
      }
      handleClose();
    }
  };

  const handleDelete = async (categ) => {
    try {
      await unsplash.delete("anagraficaRischi", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: categ,
        },
      });

      setSnack({
        severity: "success",
        messaggio: "Rischio eliminato con successo",
      });
      setOpenSnack(true);
      reloadAfterUpload();
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione del Rischio",
      });
      setOpenSnack(true);
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    (async () => {
      try {
        let response = await unsplash.get("anagraficaRischi", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });

        setAnagraficaRischi(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error(error.message);
      }
    })();
  }, []);
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid item xs={12}>
        <Card>
          <CardContent sx={{ p: { xs: 0 } }}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Grid item xs={12} md={4}>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <NewFromatori
                          id="edit"
                          categoria={anagraficaSelezionata}
                          saveFunction={handleSave}
                          title="Modifica Anagrafica"
                        />
                        <NewFromatori
                          id="new"
                          categoria={anagraficaSelezionata}
                          saveFunction={handleSave}
                          title="Nuova Anagrafica"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      height: { xs: "75vh", md: "60vh", xl: "75vh" },
                      width: 1,
                    }}
                  >
                    <DataGridPro
                      initialState={{
                        pinnedColumns: { left: ["Actions"] },
                      }}
                      components={{
                        Toolbar: CustomToolbar,
                        Footer: CustomFooterPersonalized,
                      }}
                      slots={{
                        loadingOverlay: LinearProgress,
                      }}
                      loading={loading}
                      localeText={
                        itIT.components.MuiDataGrid.defaultProps.localeText
                      }
                      rowHeight={52}
                      rows={anagraficaRischi}
                      columns={columns}
                      getRowId={(row) => row.ID}
                      pageSize={20}
                      rowsPerPageOptions={[20]}
                      disableSelectionOnClick
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

/* Creazione Nuovi Formatori */
const NewFromatori = (props) => {
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [checked, setChecked] = useState(false);
  const [categoria, setAnagrafica] = useState({
    Descrizione: "",
    RiferimentoLegge: "",
  });

  const handleClickOpen = () => {
    if (props.id === "edit") {
      setAnagrafica(props.categoria);
    } /* else if (props.id === "new") {
      setAnagrafica(newAnagrafica);
    } */
    setOpen(true);
  };
  const handleClose = () => {
    /* setAnagrafica(newAnagrafica); */
    setOpen(false);
  };
  const handleChange = (e) => {
    let copiaAnagrafica = { ...categoria };
    copiaAnagrafica[e.target.name] = e.target.value;
    setAnagrafica(copiaAnagrafica);
  };
  const handleSave = async () => {
    props.saveFunction(categoria, props.id);
    handleClose();
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
      >
        {props.id === "edit" ? (
          <Button
            startIcon={<Add />}
            onClick={handleClickOpen}
            id={props.id}
            sx={{ color: "white", display: "none" }}
            variant="outlined"
            className="ButtonMiller"
          >
            Aggiungi
          </Button>
        ) : (
          <Button
            startIcon={<Add />}
            onClick={handleClickOpen}
            id={props.id}
            sx={{ color: "white" }}
            variant="outlined"
            className="ButtonMiller"
          >
            Aggiungi
          </Button>
        )}
      </ButtonGroup>

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="xs"
          TransitionComponent={Transition}
        >
          {/* Title Container */}
          <DialogTitle
            sx={{
              mx: "auto",
            }}
          >
            {props.title}
          </DialogTitle>
          {/* Body Container */}
          <DialogContent>
            {/* Row 2 */}
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  value={categoria.Descrizione}
                  id="outlined-basic"
                  label="Descrizione"
                  name="Descrizione"
                  onChange={handleChange}
                  fullWidth
                  sx={{ mt: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={categoria.RiferimentoLegge}
                  id="outlined-basic"
                  label="RiferimentoLegge"
                  name="RiferimentoLegge"
                  onChange={handleChange}
                  fullWidth
                  sx={{ mt: 2 }}
                />
              </Grid>
            </Grid>
            {/* Bottoni Salva & Esci */}
          </DialogContent>
          <DialogActions
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mx: 2,
            }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Close />}
            >
              Chiudi
            </Button>
            <Button
              onClick={handleSave}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Check />}
            >
              Salva
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};
const DeleteDialog = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare il rischio selezionato?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare il seguente rischio :
            <br />
            <b>{props.categoria.Descrizione}</b>
            
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button
            onClick={() => props.myFunction(props.categoria.ID)}
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
