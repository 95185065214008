import {
  Box,
  Typography,
  Grid,
  OutlinedInput,
  TextField,
  Select,
  MenuItem,
  List,
  ListItemButton,
  ListemItemIcon,
  ListItemText,
  InputAdornment,
  FormControl,
  InputLabel,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Switch, 
  LinearProgress,
  DialogContentText,
  ButtonGroup,
  Zoom,
  Divider,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import unsplash from "../api/unsplash";
import { LocalizationProvider,DatePicker,PickersDay,TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment as DateAdapter} from '@mui/x-date-pickers/AdapterMoment'
import moment from "moment";
import {
  Add,
  ArrowBack,
  Circle,
  ConnectingAirportsOutlined,
  CropRotateSharp,
  Edit,
  TableChart,
} from "@mui/icons-material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import jsPDF from "jspdf";
import "jspdf-autotable";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloseIcon from "@mui/icons-material/Close";
import { Search, Close, Check } from "@mui/icons-material";
import GlobalSnackbar from "./Snackbar";
import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import ReactHtmlTableToExcel from "react-html-table-to-excel";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const columnsStorico = [
  {
    field: "DATA",
    headerName: "Data",
    flex: 1,
    headerAlign: "center",
    align: "center",
    renderCell: (
      params //mi aiuta a mettere il valore
    ) => (
      <div>
        {params.row.DATA ? moment(params.row.DATA).format("DD/MM/YYYY") : ""}
      </div>
    ),
  },
  {
    field: "Medico",
    headerName: "Medico",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "NomeCompleto",
    headerName: "Lavoratore",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "SedeDesc",
    headerName: "Sede",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "Accertamento",
    headerName: "Accertamento",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "Importo",
    headerName: "Importo",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
];
const columnsSopralluoghi = [
  {
    field: "Data",
    headerName: "Data",
    flex: 1,
    headerAlign: "center",
    align: "center",
    renderCell: (
      params //mi aiuta a mettere il valore
    ) => (
      <div>
        {params.row.Data ? moment(params.row.Data).format("DD/MM/YYYY") : ""}
      </div>
    ),
  },
  {
    field: "Indirizzo",
    headerName: "Indirizzo",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "Importo",
    headerName: "Importo",
    flex: 1,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <>{params.formattedValue ? params.formattedValue + " €" : 0 + "€"}</>
    ),
  },
  {
    field: "Rimborso",
    headerName: "Rimborso",
    flex: 1,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <>{params.formattedValue ? params.formattedValue + " €" : 0 + "€"}</>
    ),
  },
];

const FatturazioneMedicina = (props) => {
  const [saveBlocked, setSaveBlocked] = useState(false);

  const [tariffeAccertamenti, setTariffeAccertamenti] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingStorico, setLoadingStorico] = useState(false);
  const [loadingStoricoSel, setLoadingStoricoSel] = useState(false);
  const [tariffeAccertamentiAPI, setTariffeAccertamentiAPI] = useState([]);
  const [datiCliente, setDatiCliente] = useState({
    Importo: 0,
    ImportoAnnuale: 0,
    ImportoSopralluogo: 0,
    RimborsoSopralluogo: 0,
    Decorrenza: null,
  });
  const [accertamenti, setAccertamenti] = useState([]);
  const [accertamentoSel, setAccertamentoSel] = useState([]);
  const [importo, setImporto] = useState(0);
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = useState(false);
  const [openSopralluoghi, setOpenSopralluoghi] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [totale, setTotale] = useState(0);
  const [selectedIndexSopralluoghi, setSelectedIndexSopralluoghi] =
    useState(null);
  const [storicoMedicina, setStoricoMedicina] = useState([]);
  const [storicoMedicinaBackup, setStoricoMedicinaBackup] = useState([]);
  const [storicoSopralluoghi, setStoricoSopralluoghi] = useState([]);
  const [storicoSopralluoghiBackup, setStoricoSopralluoghiBackup] = useState(
    []
  );
  const [accertamentiStorico, setAccertamentiStorico] = useState([]);
  const [sopralluoghiStoricoSel, setSopralluoghiStoricoSel] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      width: 100,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <ModifcaImporto
            id="edit"
            dati={params.row}
            myFunction={handleModify}
            saveBlocked={saveBlocked}
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
          />
        </>
      ),
    },
    { field: "Descrizione", headerName: "Accertamento", flex: 1 },
    { field: "Importo", headerName: "Importo", width: 200 },
  ];

  function CustomToolbarP() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          marginBottom={1}
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
          {accertamentiStorico.length > 0 ? (
            <>
              <Button
                startIcon={<PictureAsPdfIcon />}
                size="small"
                onClick={() => exportPDF(false)}
                variant="contained"
                sx={{ marginRight: "10px" }}
                id="buttonFatturazioneMedica"
              >
                Stampa Fattura
              </Button>
              <Button
                startIcon={<PictureAsPdfIcon />}
                size="small"
                onClick={() => exportPDF(true)}
                variant="contained"
                sx={{ marginRight: "10px" }}
                id="buttonFatturazioneMedica"
              >
                Stampa Fattura con sede
              </Button>
              {/* {props.cliente?.Gruppo === "Conter" && ( */}
                <Button
                  startIcon={<TableChart />}
                  size="small"
                  onClick={() =>
                    document.getElementById("printExcelPrefatturazione").click()
                  }
                  variant="contained"
                  sx={{ marginRight: "10px" }}
                  id="buttonFatturazioneExcel"
                >
                  Stampa Excel
                </Button>
              {/* )} */}
              <Button
                startIcon={<CloseIcon />}
                size="small"
                onClick={handleClickOpen}
                color="error"
                variant="contained"
                id="buttonFatturazioneMedicaError"
              >
                Annulla Stampa
              </Button>
            </>
          ) : (
            ""
          )}
          {sopralluoghiStoricoSel.length > 0 ? (
            <>
              <Button
                startIcon={<PictureAsPdfIcon />}
                size="small"
                onClick={exportPDFSopralluoghi}
                variant="contained"
                sx={{ marginRight: "10px" }}
                id="buttonFatturazioneMedica"
              >
                Stampa Fattura
              </Button>
              <Button
                startIcon={<CloseIcon />}
                size="small"
                onClick={handleClickOpenSopralluoghi}
                color="error"
                variant="contained"
                id="buttonFatturazioneMedicaError"
              >
                Annulla Stampa
              </Button>
            </>
          ) : (
            ""
          )}
        </Grid>
      </GridToolbarContainer>
    );
  }

  const handleListItemClick = async (event, index) => {
    setLoadingStorico(true);
    setSelectedIndex(index);

    let response = await unsplash.get("storicoFatturazioneMedicina", {
      params: {
        cliente: props.idAzienda,
        anno: storicoMedicina[index].AnnoFatturazione,
        periodo: storicoMedicina[index].PeriodoFatturazione,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setAccertamentiStorico(response.data.data);

    setLoadingStorico(false);
    let tot = 0;

    response.data.data?.forEach((el) => {
      if (el.Importo) {
        tot += Number(el.Importo);
      }
    });
    setTotale(tot);
  };

  const handleListItemClickSopralluoghi = async (event, index) => {
    setLoadingStoricoSel(true);
    setSelectedIndexSopralluoghi(index);

    let response = await unsplash.get("storicoFatturazioneSopralluoghi", {
      params: {
        cliente: props.idAzienda,
        anno: storicoSopralluoghi[index].AnnoFatturazione,
        periodo: storicoSopralluoghi[index].PeriodoFatturazione,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setSopralluoghiStoricoSel(response.data.data);
    setLoadingStoricoSel(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setImporto(0);
    setOpen(true);
  };
  const handleClickOpenSopralluoghi = () => {
    setImporto(0);
    setOpenSopralluoghi(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadAccertamenti = async (acc) => {
    let responseAccertamenti = await unsplash.get("accertamenti", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    var c = responseAccertamenti.data.data.filter(function (primo) {
      return !acc.find(function (secondo) {
        return primo.ID === secondo.Accertamenti;
      });
    });
    setAccertamenti(c);
  };

  const addAccertamenti = async () => {
    try {
      let protocollo = {
        Cliente: props.idAzienda,
        Mansione: null,
        Accertamenti: accertamentoSel,
        Cadenze: "",
        LineeGuida: "",
      };
      let responseAccertamenti = await unsplash.post(
        "protocolloSanitario",
        { protocollo: protocollo },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      let responseTariffe = await unsplash.get("fatturazioneMedicina", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { cliente: props.idAzienda },
      });
      setDatiCliente(responseTariffe.data.data.datiCliente[0]);
      setTariffeAccertamenti(responseTariffe.data.data.accertamenti);
      setTariffeAccertamentiAPI(responseTariffe.data.data.accertamenti);
      loadAccertamenti(responseTariffe.data.data.accertamenti);

      setSnack({
        severity: "success",
        messaggio: "Inserimento avvenuto con successo",
      });
      setOpenSnack(true);
      setOpen(false);
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'inserimento",
      });
      setOpenSnack(true);
      setOpen(false);
    }
  };

  const loadStoricoMedicina = async () => {
    let responseStoricoMedicina = await unsplash.get(
      "storicoFatturazioneMedicina",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { cliente: props.idAzienda },
      }
    );

    setStoricoMedicina(responseStoricoMedicina.data.data);
    setStoricoMedicinaBackup(responseStoricoMedicina.data.data);
  };

  const loadStoricoSopralluoghi = async () => {
    let responseStoricoSopralluoghi = await unsplash.get(
      "storicoFatturazioneSopralluoghi",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { cliente: props.idAzienda },
      }
    );

    setStoricoSopralluoghi(responseStoricoSopralluoghi.data.data);
    setStoricoSopralluoghiBackup(responseStoricoSopralluoghi.data.data);
  };

  const reloadAccertamenti = async () => {
    let responseTariffe = await unsplash.get("fatturazioneMedicina", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { cliente: props.idAzienda },
    });
    setDatiCliente(responseTariffe.data.data.datiCliente[0]);
    setTariffeAccertamenti(responseTariffe.data.data.accertamenti);
    setTariffeAccertamentiAPI(responseTariffe.data.data.accertamenti);
    loadAccertamenti(responseTariffe.data.data.accertamenti);
    loadStoricoMedicina();
    setLoading(false);
  };

  const exportPDFSopralluoghi = (sede) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    var img = new Image();
    img.src = require("../assets/images/diagnofisic.png");

    doc.setFontSize(20);

    const title = "Prefatturazione Attiva";
    const headers = ["Sede", "Data", "Rimborso", "Importo", "Totale"];

    let data = [];

    data = sopralluoghiStoricoSel.map((el) => [
      el.Indirizzo,
      moment(el.Data).format("DD/MM/YYYY"),
      el.Rimborso ? el.Rimborso : 0,
      el.Importo ? el.Importo : 0,
      el.Importo
        ? el.Importo + (el.Rimborso ? el.Rimborso : 0)
        : 0 + (el.Rimborso ? el.Rimborso : 0),
    ]);

    let totale = 0;

    sopralluoghiStoricoSel.forEach((el) => {
      if (el.Importo) {
        totale += Number(el.Importo) + el.Rimborso;
      }
    });

    let cad = ["Mensile", "Bimestrale", "Trimestrale"];

    let content = {
      startY: 180,
      startX: 0,
      tableWidth: "auto",
      columnWidth: "auto",
      headStyles: { fillColor: [34, 100, 174] },
      footStyles: { fillColor: [34, 100, 174] },
      margin: { left: 0, right: 0 },
      foot: [["", "", "", "Totale", Math.round(totale * 1e12) / 1e12]],
      showFoot: "lastPage",
    };

    let p = 0;

    switch (
      storicoSopralluoghi[selectedIndexSopralluoghi].PeriodoFatturazione
    ) {
      case "Gennaio":
        p = 1;
        break;
      case "Febbraio":
      case "Gennaio - Febbraio":
        p = 2;
        break;
      case "Marzo":
      case "Gennaio - Febbraio - Marzo":
        p = 3;
        break;
      case "Aprile":
      case "Marzo - Aprile":
        p = 4;
        break;
      case "Maggio":
        p = 5;
        break;
      case "Giugno":
      case "Maggio - Giugno":
      case "Aprile - Maggio - Giugno":
        p = 6;
        break;
      case "Luglio":
        p = 7;
        break;
      case "Agosto":
      case "Luglio - Agosto":
        p = 8;
        break;
      case "Settembre":
      case "Luglio - Agosto - Settembre":
        p = 9;
        break;
      case "Ottobre":
      case "Settembre - Ottobre":
        p = 10;
        break;
      case "Novembre":
        p = 11;
        break;
      case "Dicembre":
      case "Novembre - Dicembre":
      case "Ottobre - Novembre - Dicembre":
        p = 12;
        break;
    }

    let firstDay = moment(
      new Date(
        storicoSopralluoghi[selectedIndexSopralluoghi].AnnoFatturazione,
        p - props.cliente.Cadenza,
        1
      )
    ).format("DD/MM/YYYY");
    let lastDay = moment(
      new Date(
        storicoSopralluoghi[selectedIndexSopralluoghi].AnnoFatturazione,
        p,
        0
      )
    ).format("DD/MM/YYYY");

    //storicoMedicina[index].AnnoFatturazione,periodo:storicoMedicina[index].PeriodoFatturazione

    doc.setFillColor(34, 100, 174);
    doc.rect(0, 0, doc.internal.pageSize.getWidth(), 120, "F"); //x,y,largh,altezza
    doc.addImage(img, "PNG", 10, 10, 220, 60);
    img.src = require("../assets/images/diagnoIndirizzo.png");
    doc.addImage(
      img,
      "PNG",
      doc.internal.pageSize.getWidth() - 190,
      10,
      180,
      100
    );
    doc.setTextColor(255, 255, 255);
    doc.text(title, 10, 90);
    doc.setFontSize(11);
    doc.text(moment(new Date()).format("DD/MM/YYYY"), 10, 110);
    doc.setFontSize(12);
    doc.setTextColor(40);
    doc.text("Cliente ", 10, 140);
    doc.setFont(undefined, "bold");
    doc.text(props.cliente.RagioneSociale, 55, 140);
    doc.setFont(undefined, "normal");
    doc.text("Decorrenza ", doc.internal.pageSize.getWidth() - 200, 140);
    doc.setFont(undefined, "bold");
    doc.text(
      props.cliente.Decorrenza
        ? moment(props.cliente.Decorrenza).format("DD/MM/YYYY")
        : "",
      doc.internal.pageSize.getWidth() - 130,
      140
    );
    doc.setFont(undefined, "normal");
    doc.text("Cadenza ", 10, 170);
    doc.setFont(undefined, "bold");
    doc.text(cad[props.cliente.Cadenza - 1], 70, 170);
    doc.setFont(undefined, "normal");
    doc.text("Dal ", 360, 170);
    doc.setFont(undefined, "bold");
    doc.text(firstDay, 380, 170);
    doc.setFont(undefined, "normal");
    doc.text("Al ", 450, 170);
    doc.setFont(undefined, "bold");
    doc.text(lastDay, 470, 170);

    //image,formato,x,y,larghezza,altezza
    doc.autoTable(headers, data, content);
    doc.output("pdfobjectnewwindow", "prefatturazione");
  };

  const sbloccaFatturazioneSopralluoghi = async () => {
    const responseMansioniNew = await unsplash.put(
      "storicoFatturazioneSopralluoghi",
      {
        cliente: props.idAzienda,
        anno: storicoSopralluoghi[selectedIndexSopralluoghi].AnnoFatturazione,
        periodo:
          storicoSopralluoghi[selectedIndexSopralluoghi].PeriodoFatturazione,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );

    await unsplash.delete("storicoFatturazioneSopralluoghi", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      data: {
        id: storicoSopralluoghi[selectedIndexSopralluoghi].ID,
      },
    });

    setSelectedIndexSopralluoghi(null);
    setOpenSopralluoghi(false);
    setSopralluoghiStoricoSel([]);
    loadStoricoSopralluoghi();
  };

  useEffect(() => {
    async function fetchData() {
      if (props?.idAzienda !== 0) {
        reloadAccertamenti();
        loadStoricoSopralluoghi();
      }
    }
    fetchData();
  }, [props?.idAzienda]);

  const handleSave = async (importo, tariffeAcc) => {
    setSaveBlocked(true);
    if (importo) {
      let datiCli = { ...datiCliente };
      //datiCli.Importo = importo;
      try {
        await unsplash.post(
          "fatturazioneMedicina",
          {
            datiCliente: datiCli,
            tariffeAccertamenti: tariffeAcc,
            cliente: props.idAzienda,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Fatturazione inserita con successo",
        });
        setOpenSnack(true);
        setTimeout(() => {
          reloadAccertamenti();
        }, 1000);
      } catch (e) {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'inserimento",
        });
        setOpenSnack(true);
      } finally {
        setSaveBlocked(false);
      }
    } else {
      try {
        await unsplash.post(
          "fatturazioneMedicina",
          {
            datiCliente: {
              ...datiCliente,
              Decorrenza: datiCliente.Decorrenza
                ? moment(datiCliente.Decorrenza).format("YYYY-MM-DD")
                : null,
            },
            tariffeAccertamenti: tariffeAccertamentiAPI,
            cliente: props.idAzienda,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Fatturazione inserita con successo",
        });
        setOpenSnack(true);
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'inserimento",
        });
        setOpenSnack(true);
      } finally {
        setSaveBlocked(false);
      }
    }
  };

  const handleModify = async (dati) => {
    let row = { ...dati };
    let selAcc = [...tariffeAccertamentiAPI];
    const accertamentiUpdated = selAcc?.map((acc) => {
      let accertamento = { ...acc };
      if (!acc.ID) {
        if (acc.Accertamenti === dati.Accertamenti) {
          accertamento = { ...row };
        }
      } else {
        if (acc.ID === dati.ID) {
          accertamento = { ...row };
        }
      }
      return accertamento;
    });
    //OLD VERSION
    /* let findIndex = selAcc.findIndex((acc) => acc.ID == dati.ID); //trovo la posizione

    if (findIndex !== -1) {
      selAcc.splice(findIndex, 1);
    }
    selAcc.push(row);
    setTariffeAccertamentiAPI(selAcc); */

    //breackpoint
    setTariffeAccertamentiAPI(accertamentiUpdated);
    handleSave(row.Importo, accertamentiUpdated);
  };

  const exportPDF = (sede) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size, true);
    var img = new Image();
    img.src = require("../assets/images/diagnofisic.png");

    doc.setFontSize(20);

    const title = "Prefatturazione Attiva";
    const headers = sede
      ? ["Data", "Medico", "Lavoratore", "Accertamento", "Sede", "Importo"]
      : ["Data", "Medico", "Lavoratore", "Accertamento", "Importo"];
    let data = [];
    if (sede) {
      data = accertamentiStorico?.map((el) => [
        el?.DATA ? moment(el?.DATA).format("DD/MM/YYYY") : "",
        el?.Medico,
        el?.NomeCompleto,
        el?.Accertamento,
        el?.SedeDesc,
        el?.Importo ? el.Importo + " €" : "0 €",
      ]);
    } else {
      data = accertamentiStorico?.map((el) => [
        moment(el?.DATA).format("DD/MM/YYYY"),
        el?.Medico,
        el?.NomeCompleto,
        el?.Accertamento,
        el?.Importo ? el.Importo + " €" : "0 €",
      ]);
    }

    let totale = 0;

    accertamentiStorico.forEach((el) => {
      if (el.Importo) {
        totale += Number(el.Importo);
      }
    });

    let cad = ["Mensile", "Bimestrale", "Trimestrale"];

    let content = {
      startY: 180,
      startX: 0,
      tableWidth: "auto",
      columnWidth: "auto",
      headStyles: { fillColor: [34, 100, 174] },
      footStyles: { fillColor: [34, 100, 174] },
      margin: { left: 0, right: 0 },
      foot: [
        [
          "",
          "",
          "",
          "Totale",
          totale % 1 === 0 ? totale + " €" : totale.toFixed(2) + " €",
        ],
      ],
      showFoot: "lastPage",
    };

    let p = 0;

    switch (storicoMedicina[selectedIndex].PeriodoFatturazione) {
      case "Gennaio":
        p = 1;
        break;
      case "Febbraio":
      case "Gennaio - Febbraio":
        p = 2;
        break;
      case "Marzo":
      case "Gennaio - Febbraio - Marzo":
        p = 3;
        break;
      case "Aprile":
      case "Marzo - Aprile":
        p = 4;
        break;
      case "Maggio":
        p = 5;
        break;
      case "Giugno":
      case "Maggio - Giugno":
      case "Aprile - Maggio - Giugno":
        p = 6;
        break;
      case "Luglio":
        p = 7;
        break;
      case "Agosto":
      case "Luglio - Agosto":
        p = 8;
        break;
      case "Settembre":
      case "Luglio - Agosto - Settembre":
        p = 9;
        break;
      case "Ottobre":
      case "Settembre - Ottobre":
        p = 10;
        break;
      case "Novembre":
        p = 11;
        break;
      case "Dicembre":
      case "Novembre - Dicembre":
      case "Ottobre - Novembre - Dicembre":
        p = 12;
        break;
    }

    let firstDay = moment(
      new Date(
        storicoMedicina[selectedIndex].AnnoFatturazione,
        p - props.cliente.Cadenza,
        1
      )
    ).format("DD/MM/YYYY");
    let lastDay = moment(
      new Date(storicoMedicina[selectedIndex].AnnoFatturazione, p, 0)
    ).format("DD/MM/YYYY");

    //storicoMedicina[index].AnnoFatturazione,periodo:storicoMedicina[index].PeriodoFatturazione

    doc.setFillColor(34, 100, 174);
    doc.rect(0, 0, doc.internal.pageSize.getWidth(), 120, "F"); //x,y,largh,altezza
    doc.addImage(img, "PNG", 10, 10, 220, 60);
    img.src = require("../assets/images/diagnoIndirizzo.png");
    doc.addImage(
      img,
      "PNG",
      doc.internal.pageSize.getWidth() - 190,
      10,
      180,
      100
    );
    doc.setTextColor(255, 255, 255);
    doc.text(title, 10, 90);
    doc.setFontSize(11);
    doc.text(moment(new Date()).format("DD/MM/YYYY"), 10, 110);
    doc.setFontSize(12);
    doc.setTextColor(40);
    doc.text("Cliente ", 10, 140);
    doc.setFont(undefined, "bold");
    doc.text(props.cliente.RagioneSociale, 55, 140);
    doc.setFont(undefined, "normal");
    doc.text("Decorrenza ", doc.internal.pageSize.getWidth() - 200, 140);
    doc.setFont(undefined, "bold");
    doc.text(
      props.cliente.Decorrenza
        ? moment(props.cliente.Decorrenza).format("DD/MM/YYYY")
        : "",
      doc.internal.pageSize.getWidth() - 130,
      140
    );
    doc.setFont(undefined, "normal");
    doc.text("Cadenza ", 10, 170);
    doc.setFont(undefined, "bold");
    doc.text(cad[props.cliente.Cadenza - 1], 70, 170);
    doc.setFont(undefined, "normal");
    doc.text("Dal ", 360, 170);
    doc.setFont(undefined, "bold");
    doc.text(firstDay, 380, 170);
    doc.setFont(undefined, "normal");
    doc.text("Al ", 450, 170);
    doc.setFont(undefined, "bold");
    doc.text(lastDay, 470, 170);

    //image,formato,x,y,larghezza,altezza
    doc.autoTable(headers, data, content);
    doc.output("pdfobjectnewwindow", "prefatturazione");
  };

  const sbloccaFatturazione = async () => {
    const responseMansioniNew = await unsplash.put(
      "storicoFatturazioneMedicina",
      {
        cliente: props.idAzienda,
        anno: storicoMedicina[selectedIndex].AnnoFatturazione,
        periodo: storicoMedicina[selectedIndex].PeriodoFatturazione,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );

    await unsplash.delete("storicoFatturazioneMedicina", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      data: {
        id: storicoMedicina[selectedIndex].ID,
      },
    });

    setSelectedIndex(null);
    setOpen(false);
    setAccertamentiStorico([]);
    loadStoricoMedicina();
  };

  const importoFormatted = (importo) => {
    let numberString = importo.toString();
    return numberString?.replace(".", ",");
  };
  const handleSearch = (e) => {
    // filtro

    setSelectedIndex(null);
    setAccertamentiStorico([]);
    if (e.target.value == "") {
      setStoricoMedicina(storicoMedicinaBackup);
    } else {
      var storicoX = []; // popolo l'array
      var i;
      for (i = 0; i < storicoMedicinaBackup.length; i++) {
        if (
          storicoMedicinaBackup[i].PeriodoFatturazione.toLowerCase().includes(
            e.target.value.toLowerCase()
          ) ||
          storicoMedicinaBackup[i].AnnoFatturazione.toLowerCase().includes(
            e.target.value.toLowerCase()
          )
        ) {
          storicoX.push(storicoMedicinaBackup[i]);
        }
      }
      setStoricoMedicina(storicoX);
    }
  };
  const handleSearchSopralluoghi = (e) => {
    // filtro

    setSelectedIndex(null);
    setAccertamentiStorico([]);
    if (e.target.value == "") {
      setStoricoSopralluoghi(storicoSopralluoghiBackup);
    } else {
      var storicoX = []; // popolo l'array
      var i;
      for (i = 0; i < storicoSopralluoghiBackup.length; i++) {
        if (
          storicoSopralluoghiBackup[
            i
          ].PeriodoFatturazione.toLowerCase().includes(
            e.target.value.toLowerCase()
          ) ||
          storicoSopralluoghiBackup[i].AnnoFatturazione.toLowerCase().includes(
            e.target.value.toLowerCase()
          )
        ) {
          storicoX.push(storicoSopralluoghiBackup[i]);
        }
      }
      setStoricoSopralluoghi(storicoX);
    }
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      {props?.proceduraGuidata ? (
        <>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Importo"
                fullWidth
                type="number"
                value={datiCliente.Importo}
                onChange={(e) => {
                  let dc = { ...datiCliente };
                  dc.Importo = e.target.value;
                  setDatiCliente(dc);
                }}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Importo Annuale"
                type="number"
                fullWidth
                value={datiCliente.ImportoAnnuale}
                onChange={(e) => {
                  let dc = { ...datiCliente };
                  dc.ImportoAnnuale = e.target.value;
                  setDatiCliente(dc);
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel id="Cadenza">Cadenza</InputLabel>
                <Select
                  label="Cadenza"
                  labelId="Cadenza"
                  id="demo-simple-select"
                  value={datiCliente.Cadenza}
                  onChange={(e) => {
                    let dc = { ...datiCliente };
                    dc.Cadenza = e.target.value;
                    setDatiCliente(dc);
                  }}
                >
                  <MenuItem value={1}>Mensile</MenuItem>
                  <MenuItem value={2}>Bimensile</MenuItem>
                  <MenuItem value={3}>Trimestrale</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Importo Sopralluogo"
                type="number"
                fullWidth
                value={datiCliente.ImportoSopralluogo}
                onChange={(e) => {
                  let dc = { ...datiCliente };
                  dc.ImportoSopralluogo = e.target.value;
                  setDatiCliente(dc);
                }}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Rimborso Sopralluogo"
                type="number"
                fullWidth
                value={datiCliente.RimborsoSopralluogo}
                onChange={(e) => {
                  let dc = { ...datiCliente };
                  dc.RimborsoSopralluogo = e.target.value;
                  setDatiCliente(dc);
                }}
              />
            </Grid>

            <Grid item md={4}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  ampmInClock={false}
                  renderInput={(props) => <TextField {...props} fullWidth />}
                  label="Decorrenza"
                  value={moment(datiCliente.Decorrenza).format("YYYY-MM-DD")}
                  onChange={(newValue) => {
                    //setAl(newValue?newValue.format("YYYY-MM-DD"):null);
                    let dc = { ...datiCliente };
                    dc.Decorrenza = newValue
                      ? newValue.format("YYYY-MM-DD")
                      : null;
                    setDatiCliente(dc);
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <div>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <ButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                    sx={{ mt: 2 }}
                  >
                    <Button
                      startIcon={<Add />}
                      onClick={handleClickOpen}
                      className="ButtonMiller"
                    >
                      AGGIUNGI
                    </Button>
                  </ButtonGroup>
                </Grid>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  fullWidth={true}
                  maxWidth="md"
                  TransitionComponent={Transition}
                >
                  <DialogTitle>Inserisci nuovo Accertamento</DialogTitle>
                  <DialogContent>
                    <Grid container>
                      <Grid item xs={12}>
                        <FormControl fullWidth sx={{ my: 2 }}>
                          <InputLabel id="demo-multiple-name-label">
                            Accertamento
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            input={<OutlinedInput label="Accetamento" />}
                            onChange={(e) => {
                              setAccertamentoSel(e.target.value);
                            }}
                          >
                            {accertamenti?.map((accertamento) => (
                              <MenuItem
                                key={accertamento.ID}
                                value={accertamento.ID}
                              >
                                {accertamento.Descrizione}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Grid
                      xs={12}
                      item
                      sx={{
                        display: "flex",
                        mt: 2,
                        justifyContent: "space-between",
                        px: 2,
                      }}
                      fullWidth
                    >
                      <Button
                        variant="contained"
                        className="ButtonMiller"
                        startIcon={<Close />}
                        onClick={handleClose}
                      >
                        Chiudi
                      </Button>
                      <Button
                        variant="contained"
                        className="ButtonMiller"
                        startIcon={<Check />}
                        onClick={addAccertamenti}
                      >
                        Salva
                      </Button>
                    </Grid>
                  </DialogActions>
                </Dialog>
              </div>
            </Grid>

            <Grid item xs={12}>
              <div style={{ height: 400, width: "100%" }}>
                <DataGridPro
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  initialState={{ pinnedColumns: { left: ["Actions"] } }}
                  components={{
                    Toolbar: CustomToolbarP,
                    Footer: CustomFooterPersonalized,
                  }}
                  slots={{
                    loadingOverlay: LinearProgress,
                  }}
                  loading={loading}
                  rows={tariffeAccertamenti}
                  getRowId={(tariffeAccertamenti) =>
                    tariffeAccertamenti.Accertamenti
                  }
                  columns={columns}
                  disableSelectionOnClick
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                mt: 2,
                justifyContent: "space-between",
              }}
              fullWidth
            >
              <Button
                onClick={() => props.handleBack()}
                variant="contained"
                startIcon={<ArrowBack />}
                className="ButtonMiller"
              >
                Indietro
              </Button>
              <Button
                onClick={() => {
                  //breackpoint
                  //handleSave(null);
                  props.handleComplete();
                }}
                variant="contained"
                startIcon={<Check />}
                className="ButtonMiller"
              >
                Salva e Completa procedura
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
              >
                <Tab iconPosition="start" label="Listino prezzi medicina" />
                <Tab iconPosition="start" label="Accertamenti" />
                <Tab iconPosition="start" label="Storico Visite" />
                <Tab iconPosition="start" label="Storico Sopralluoghi" />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {/* IMPOSTAZIONI FATTURAZIONE */}
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label="Importo"
                    type="number"
                    fullWidth
                    value={datiCliente ? datiCliente.Importo : "0"}
                    onChange={(e) => {
                      let dc = { ...datiCliente };
                      dc.Importo = e.target.value;
                      setDatiCliente(dc);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label="Importo Annuale"
                    type="number"
                    fullWidth
                    value={datiCliente ? datiCliente.ImportoAnnuale : "0"}
                    onChange={(e) => {
                      let dc = { ...datiCliente };
                      dc.ImportoAnnuale = e.target.value;
                      setDatiCliente(dc);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel id="Cadenza">Cadenza</InputLabel>
                    <Select
                      label="Cadenza"
                      labelId="Cadenza"
                      id="demo-simple-select"
                      value={
                        datiCliente
                          ? datiCliente.Cadenza
                            ? datiCliente.Cadenza
                            : 0
                          : 0
                      }
                      onChange={(e) => {
                        let dc = { ...datiCliente };
                        dc.Cadenza = e.target.value;
                        setDatiCliente(dc);
                      }}
                    >
                      <MenuItem value={1}>Mensile</MenuItem>
                      <MenuItem value={2}>Bimensile</MenuItem>
                      <MenuItem value={3}>Trimestrale</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label="Importo Sopralluogo"
                    fullWidth
                    type="number"
                    value={datiCliente ? datiCliente.ImportoSopralluogo : "0"}
                    onChange={(e) => {
                      let dc = { ...datiCliente };
                      dc.ImportoSopralluogo = e.target.value;
                      setDatiCliente(dc);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label="Rimborso Sopralluogo"
                    fullWidth
                    type="number"
                    value={datiCliente ? datiCliente.RimborsoSopralluogo : "0"}
                    onChange={(e) => {
                      let dc = { ...datiCliente };
                      dc.RimborsoSopralluogo = e.target.value;
                      setDatiCliente(dc);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <LocalizationProvider dateAdapter={DateAdapter}>                 
                    <DatePicker
                      ampmInClock={false}
                      renderInput={(props) => (
                        <TextField {...props} fullWidth />
                      )}
                      label="Decorrenza"
                      value={
                        
                         datiCliente.Decorrenza !== null
                            ? moment(datiCliente.Decorrenza)
                            : null
                       
                      }
                      onChange={(newValue) => {
                        //setAl(newValue?newValue.format("YYYY-MM-DD"):null);
                        let dc = { ...datiCliente };
                        dc.Decorrenza = newValue
                          ? newValue.format("YYYY-MM-DD")
                          : null;
                        setDatiCliente(dc);
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    mt: 2,
                    justifyContent: "flex-end",
                  }}
                  fullWidth
                >
                  <Button
                    //breackpoint
                    onClick={() => handleSave(null)}
                    variant="contained"
                    startIcon={<Check />}
                    className="ButtonMiller"
                  >
                    Salva
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ height: { xs: "0vh", md: "25vh" } }}
                ></Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              {/* IMPOSTAZIONI FATTURAZIONE */}
              <Grid container spacing={2}>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <ButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                    sx={{ mt: 2 }}
                  >
                    <Button
                      startIcon={<Add />}
                      onClick={handleClickOpen}
                      className="ButtonMiller"
                    >
                      AGGIUNGI
                    </Button>
                  </ButtonGroup>
                </Grid>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  fullWidth={true}
                  maxWidth="md"
                  TransitionComponent={Transition}
                >
                  <DialogTitle>Inserisci nuovo Accertamento</DialogTitle>
                  <DialogContent>
                    <Grid container>
                      <Grid item xs={12}>
                        <FormControl fullWidth sx={{ my: 2 }}>
                          <InputLabel id="demo-multiple-name-label">
                            Accertamento
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            input={<OutlinedInput label="Accetamento" />}
                            onChange={(e) => {
                              setAccertamentoSel(e.target.value);
                            }}
                          >
                            {accertamenti?.map((accertamento) => (
                              <MenuItem
                                key={accertamento.ID}
                                value={accertamento.ID}
                              >
                                {accertamento.Descrizione}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Grid
                      xs={12}
                      item
                      sx={{
                        display: "flex",
                        mt: 2,
                        justifyContent: "space-between",
                        px: 2,
                      }}
                      fullWidth
                    >
                      <Button
                        variant="contained"
                        className="ButtonMiller"
                        startIcon={<Close />}
                        onClick={handleClose}
                      >
                        Chiudi
                      </Button>
                      <Button
                        variant="contained"
                        className="ButtonMiller"
                        startIcon={<Check />}
                        onClick={addAccertamenti}
                      >
                        Salva
                      </Button>
                    </Grid>
                  </DialogActions>
                </Dialog>
                <Box sx={{ height: "50vh", width: "100%" }}>
                  <DataGridPro
                    localeText={
                      itIT.components.MuiDataGrid.defaultProps.localeText
                    }
                    components={{
                      Toolbar: CustomToolbarP,
                      Footer: CustomFooterPersonalized,
                    }}
                    slots={{
                      loadingOverlay: LinearProgress,
                    }}
                    loading={loading}
                    initialState={{ pinnedColumns: { left: ["Actions"] } }}
                    rows={tariffeAccertamenti}
                    getRowId={(tariffeAccertamenti) =>
                      tariffeAccertamenti.Accertamenti
                    }
                    columns={columns}
                    disableSelectionOnClick
                  />
                </Box>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
              {/* STORICO FATTURAZIONE */}
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextField
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    size="small"
                    fullWidth
                    id="input-with-icon-textfield"
                    onChange={handleSearch}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                  {storicoMedicina.length <= 0 ? (
                    <>
                      <br /> <br />
                      Nessuna prefatturazione presente
                    </>
                  ) : (
                    ""
                  )}
                  <Box
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                  >
                    <List
                      component="nav"
                      aria-label="main mailbox folders"
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                        position: "relative",
                        overflow: "auto",
                        direction: "rtl",
                        maxHeight: "55vh",
                        marginTop: 1,
                        padding: 0,
                        borderRadius: "4px",
                        "& ul": { padding: 0 },
                      }}
                    >
                      {storicoMedicina.map((t, index) => {
                        return (
                          <ListItemButton
                            selected={selectedIndex === index}
                            onClick={(event) =>
                              handleListItemClick(event, index)
                            }
                            sx={{
                              background: "#2265B1",
                              color: "white",
                              "&:hover": { backgroundColor: "#11526F" },
                              "&$selected": {
                                backgroundColor: "#FF2768!important",
                              },
                            }}
                            classes={{ selected: "selectedList" }}
                          >
                            <ListItemText
                              primary={
                                t.PeriodoFatturazione + " " + t.AnnoFatturazione
                              }
                            />
                          </ListItemButton>
                        );
                      })}
                    </List>
                  </Box>
                </Grid>
                <Grid item xs={9}>
                  {selectedIndex !== null ? (
                    <h5>
                      Periodo Selezionato:{" "}
                      {storicoMedicina[selectedIndex].PeriodoFatturazione +
                        " " +
                        storicoMedicina[selectedIndex].AnnoFatturazione +
                        " "}
                    </h5>
                  ) : (
                    ""
                  )}
                  <Dialog
                    open={open}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    TransitionComponent={Transition}
                  >
                    <DialogTitle>ATTENZIONE</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-slide-description">
                        Sei sicuro di voler eliminare la prefatturazione ?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Annulla</Button>
                      <Button onClick={sbloccaFatturazione}>Conferma</Button>
                    </DialogActions>
                  </Dialog>
                  <div className="hidden">
                    <ReactHtmlTableToExcel
                      id="printExcelPrefatturazione"
                      className="download-table-xls-button"
                      table="table-to-xls"
                      filename={"Prefatturazione"}
                      sheet={"Prefatturazione"}
                      style={{ display: "None!important" }}
                      buttonText="Download Prefatturazione"
                    />
                    <table id="table-to-xls">
                      <thead>
                        <tr>
                          {[
                            "Data",
                            "Medico",
                            "Tipologia",
                            "Lavoratore",
                            "Accertamento",
                            "Sede",
                            "Importo",
                          ].map((header, index) => (
                            <th key={index}>{header}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {accertamentiStorico
                          ?.map((el) => [
                            el?.DATA
                              ? moment(el?.DATA).format("DD/MM/YYYY")
                              : "",
                            el?.Medico,
                            el?.Tipologia,
                            el?.NomeCompleto,
                            el?.Accertamento,
                            el?.SedeDesc,
                            el?.Importo
                              ? importoFormatted(el.Importo) + "€"
                              : "0 €",
                          ])
                          .map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              {row.map((cell, cellIndex) => (
                                <td
                                  style={{ textAlign: "center" }}
                                  key={cellIndex}
                                >
                                  {cell}
                                </td>
                              ))}
                            </tr>
                          ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td
                            colSpan={
                              [
                                "Data",
                                "Medico",
                                "Tipologia",
                                "Lavoratore",
                                "Accertamento",
                                "Sede",
                                "Importo",
                              ].length - 2
                            }
                          ></td>
                          <td>Totale</td>
                          <td>
                            {totale % 1 === 0
                              ? totale + " €"
                              : totale.toFixed(2) + " €"}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div style={{ height: "60vh", width: "100%" }}>
                    <DataGridPro
                      components={{
                        Toolbar: CustomToolbar,
                        Footer: CustomFooterPersonalized,
                      }}
                      slots={{
                        loadingOverlay: LinearProgress,
                      }}
                      loading={loadingStorico}
                      localeText={
                        itIT.components.MuiDataGrid.defaultProps.localeText
                      }
                      rows={accertamentiStorico}
                      getRowId={(accertamentiStorico) =>
                        accertamentiStorico.idVisAccertamento
                      }
                      columns={columnsStorico}
                      disableSelectionOnClick
                    />
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextField
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    fullWidth
                    size="small"
                    id="input-with-icon-textfield"
                    onChange={handleSearchSopralluoghi}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                  {storicoSopralluoghi.length <= 0 ? (
                    <>
                      <br /> <br />
                      Nessuna prefatturazione presente
                    </>
                  ) : (
                    ""
                  )}
                  <Box
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                  >
                    <List
                      component="nav"
                      aria-label="main mailbox folders"
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                        position: "relative",
                        overflow: "auto",
                        direction: "rtl",
                        maxHeight: "55vh",
                        marginTop: 1,
                        padding: 0,
                        borderRadius: "4px",
                        "& ul": { padding: 0 },
                      }}
                    >
                      {storicoSopralluoghi.map((t, index) => {
                        return (
                          <ListItemButton
                            selected={selectedIndexSopralluoghi === index}
                            onClick={(event) =>
                              handleListItemClickSopralluoghi(event, index)
                            }
                            sx={{
                              background: "#2265B1",
                              color: "white",
                              "&:hover": { backgroundColor: "#11526F" },
                              "&$selected": {
                                backgroundColor: "#FF2768!important",
                              },
                            }}
                            classes={{ selected: "selectedList" }}
                          >
                            <ListItemText
                              primary={
                                t.PeriodoFatturazione + " " + t.AnnoFatturazione
                              }
                            />
                          </ListItemButton>
                        );
                      })}
                    </List>
                  </Box>
                </Grid>
                <Grid item xs={9}>
                  {selectedIndexSopralluoghi !== null ? (
                    <h5>
                      Periodo Selezionato:{" "}
                      {storicoSopralluoghi[selectedIndexSopralluoghi]
                        .PeriodoFatturazione +
                        " " +
                        storicoSopralluoghi[selectedIndexSopralluoghi]
                          .AnnoFatturazione +
                        " "}
                    </h5>
                  ) : (
                    ""
                  )}
                  <Dialog
                    open={openSopralluoghi}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    TransitionComponent={Transition}
                  >
                    <DialogTitle>ATTENZIONE</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-slide-description">
                        Sei sicuro di voler eliminare la prefatturazione ?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setOpenSopralluoghi(false)}>
                        Annulla
                      </Button>
                      <Button onClick={sbloccaFatturazioneSopralluoghi}>
                        Conferma
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <div style={{ height: "60vh", width: "100%" }}>
                    <DataGridPro
                      components={{
                        Toolbar: CustomToolbar,
                        Footer: CustomFooterPersonalized,
                      }}
                      slots={{
                        loadingOverlay: LinearProgress,
                      }}
                      loading={loadingStoricoSel}
                      localeText={
                        itIT.components.MuiDataGrid.defaultProps.localeText
                      }
                      rows={sopralluoghiStoricoSel}
                      getRowId={(s) => s.ID}
                      columns={columnsSopralluoghi}
                      disableSelectionOnClick
                    />
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
          </Box>
        </>
      )}
    </>
  );
};

export default FatturazioneMedicina;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ModifcaImporto = (props) => {
  const [open, setOpen] = useState(false);
  const [importo, setImporto] = useState({ Accertamento: "", Importo: "" });

  const handleClickOpen = () => {
    setImporto(props.dati);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <IconButton aria-label="edit" onClick={handleClickOpen} id={props.id}>
        <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="md"
        TransitionComponent={Transition}
      >
        <DialogTitle>Modifica Accertamento</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={12} md={6}>
              <TextField
                value={importo.Descrizione}
                id="outlined-basic"
                label="Accertamento"
                name="Accertamento"
                disabled
                fullWidth
                type="text"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={importo.Importo}
                id="outlined-basic"
                label="Importo"
                name="Importo"
                fullWidth
                type="number"
                onChange={(e) => {
                  let imp = { ...importo };
                  imp.Importo = e.target.value;
                  setImporto(imp);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            xs={12}
            item
            sx={{
              display: "flex",
              mt: 2,
              justifyContent: "space-between",
              px: 2,
            }}
            fullWidth
          >
            <Button
              variant="contained"
              className="ButtonMiller"
              startIcon={<Close />}
              onClick={handleClose}
            >
              Chiudi
            </Button>
            <Button
              disabled={props.saveBlocked}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Check />}
              onClick={() => {
                if (!importo.Importo) {
                  props.setSnack({
                    severity: "error",
                    messaggio: "Inserisci un importo",
                  });
                  props.setOpenSnack(true);
                } else {
                  props.myFunction(importo);
                  handleClose();
                }
              }}
            >
              Salva
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
