import React, { useEffect, useState, useRef } from "react";
import unsplash from "../api/unsplash";
import {
	DataGridPro,
	itIT,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
	GridToolbarQuickFilter,
	GridFooterContainer,
	GridRowParams,
} from "@mui/x-data-grid-pro";
import { saveAs } from "file-saver";

import {
	IconButton,
	Grid,
	Backdrop,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Button,
	DialogActions,
	Zoom,
	DialogContentText,
	Tooltip,
	LinearProgress,
	Box,
	ButtonGroup,
	MenuItem,
} from "@mui/material";
import FileUploader from "./FileUploader";
import {
	Download,
	RemoveRedEye,
	Delete,
	Close,
	Check,
	Link,
} from "@mui/icons-material";
import GlobalSnackbar from "./Snackbar";
import Edit from "@mui/icons-material/Edit";
import moment from "moment";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import FolderOffOutlinedIcon from "@mui/icons-material/FolderOffOutlined";
import Grow from "@mui/material/Grow";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import { useLocation } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";

import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});
const ext = {
	doc: "application/msword",
	dot: "application/msword",
	docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	dotx: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
	docm: "application/vnd.ms-word.document.macroEnabled.12",
	dotm: "application/vnd.ms-word.template.macroEnabled.12",
	xls: "application/vnd.ms-excel",
	xlt: "application/vnd.ms-excel",
	xla: "application/vnd.ms-excel",
	xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	xltx: "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
	xlsm: "application/vnd.ms-excel.sheet.macroEnabled.12",
	xltm: "application/vnd.ms-excel.template.macroEnabled.12",
	xlam: "application/vnd.ms-excel.addin.macroEnabled.12",
	xlsb: "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
	ppt: "application/vnd.ms-powerpoint",
	pot: "application/vnd.ms-powerpoint",
	pps: "application/vnd.ms-powerpoint",
	ppa: "application/vnd.ms-powerpoint",
	pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
	potx: "application/vnd.openxmlformats-officedocument.presentationml.template",
	ppsx: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
	ppam: "application/vnd.ms-powerpoint.addin.macroEnabled.12",
	pptm: "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
	potm: "application/vnd.ms-powerpoint.template.macroEnabled.12",
	ppsm: "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
	mdb: "application/vnd.ms-access",
	png: "application/png",
	pdf: "application/pdf",
};

const Allegati = (props) => {
	const [allegati, setAllegati] = useState([]);

	const [loading, setLoading] = useState(true);
	const [openBackdrop, setOpenBackDrop] = useState(false);
	const [loadingDoc, setLoadingDoc] = useState(false);
	const anchorRef = useRef(null);
	const [openButton, setOpenButton] = useState(false);
	const location = useLocation();
	const [addCartella, setIsAddCartella] = useState(false);
	const [removeCartella, setIsRemoveCartella] = useState(false);
	const [associaFile, setIsAssocia] = useState(false);
	const [cartelle, setCartelle] = useState([]);
	const [isEditCartella, setIsEditCartella] = useState(false);
	const [cartella, setCartella] = useState({
		nome: "",
		ID_Padre: null,
	});
	const [cartellaSel, setCartellaSel] = useState({
		ID: null,
		ID_Figlio: null,
		nomeCartella: "",
		nomeSottocartella: "",
	});
	const [scaricaButtonDisabled, setScaricaButtonDisabled] = useState(false);

	const [rowSelectionModel, setRowSelectionModel] = useState([]);
	const [allegatiSel, setAllegatiSel] = useState([]);

	const [openSnack, setOpenSnack] = useState(false);
	const [snack, setSnack] = useState({
		severity: "success",
		messaggio: "Salvataggio Effettuato correttamente",
		open: false,
	});
	const [allegato, setAllegato] = useState();
	const [scaricaAllegati, setIsScaricaAllegati] = useState(false);
	const [sottocartelle, setSottocartelle] = useState([]);

	const handleCloseSnack = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnack(false);
	};

	const downloadFile = async (id, estensione, descrizione, operazione) => {
		try {
			if (loadingDoc) {
				setSnack({
					severity: "error",
					messaggio:
						"Aspetta che la richiesta precedente sia completata prima di iniziare",
				});
				setOpenSnack(true);
			} else {
				setLoadingDoc(true);
				// Make the POST request to trigger the download and response from the server
				const response = await unsplash.post(
					"ftp",
					{
						file: id + estensione,
						nomeOriginale: descrizione.includes(estensione)
							? descrizione
							: descrizione + estensione,
						operazione: "download",
					},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem("jwt")}`,
						},
						responseType: "blob",
					}
				);
				const url = window.URL.createObjectURL(
					new Blob([response.data], { type: ext[estensione.replace(".", "")] })
				);
				const link = document.createElement("a");
				link.href = url;
				if (operazione === "download") {
					link.setAttribute(
						"download",
						descrizione.includes(estensione)
							? descrizione
							: descrizione + estensione
					);
					document.body.appendChild(link);
					link.click();
				}

				if (operazione === "visualizza") {
					if (estensione === ".pdf") {
						window.open(url);
					}
				}
				setLoadingDoc(false);
			}
		} catch (error) {
			let messaggio = "Errore durante la visualizzazione del file: ";
			if (operazione === "download") {
				messaggio = "Errore durante il download del file: ";
			}
			setOpenSnack(true);
			if (error.response) {
				if (error.response.status === 404) {
					messaggio = messaggio + "File non trovato.";
				} else if (error.response.status === 500) {
					messaggio =
						messaggio +
						"Errore interno del server. Si prega di riprovare più tardi.";
				} else {
					messaggio = messaggio + "Errore sconosciuto.";
				}
			} else if (error.request) {
				// La richiesta è stata effettuata ma non è stata ricevuta alcuna risposta
				messaggio =
					messaggio +
					"Nessuna risposta ricevuta dal server. Si prega di controllare la connessione a Internet.";
			} else {
				// Si è verificato qualche altro errore
				messaggio =
					messaggio +
					"Si è verificato un errore. Si prega di riprovare più tardi.";
			}
			setSnack({
				severity: "error",
				messaggio: messaggio,
			});
			console.error("Errore durante il download del file:", error);
			setLoadingDoc(false);
		}
	};

	const handleClose = () => {
		setOpenBackDrop(false);
	};
	const handleCloseEdit = () => {
		setCartellaSel({
			ID: null,
			ID_Figlio: null,
			nomeCartella: "",
			nomeSottocartella: "",
		});
		setRowSelectionModel([]);
		setAllegatiSel([]);
		setSottocartelle([]);
		setIsEditCartella(false);
	};

	const handleCloseAdd = () => {
		setCartella({
			nome: "",
			ID_Padre: null,
		});
		setIsAddCartella(false);
	};

	const handleCloseRemove = () => {
		setCartellaSel({
			ID: null,
			ID_Figlio: null,
		});
		setRowSelectionModel([]);
		setAllegatiSel([]);
		setSottocartelle([]);
		setIsRemoveCartella(false);
	};
	const handleRemoveCartella = async () => {
		if (cartellaSel.ID === null) {
			setSnack({
				severity: "error",
				messaggio: "Per favore, compila tutti i campi obbligatori.",
			});

			setOpenSnack(true);
		} else {
			try {
				const response = await unsplash.put(
					"cartelle/rimuovi",
					{
						ID: cartellaSel.ID,
						ID_Figlio: cartellaSel.ID_Figlio,
					},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem("jwt")}`,
						},
					}
				);

				// Check if the response was successful (status code 2xx)
				if (response.status >= 200 && response.status < 300) {
					setSnack({
						severity: "success",
						messaggio: "Cartella rimossa con successo ",
					});
					reloadAfterUpload();
				} else {
					// Handle non-successful response
					setSnack({
						severity: "error",
						messaggio: "Errore durante associazione",
					});
				}

				setOpenSnack(true);
			} catch (error) {
				// Handle network or other errors
				console.error("Error during API request:", error);
				setSnack({
					severity: "error",
					messaggio: "Errore durante associazione",
				});
				setOpenSnack(true);
			} finally {
				handleCloseRemove();
			}
		}
	};

	const handleSaveAssocia = async () => {
		if (cartellaSel.ID === null) {
			setSnack({
				severity: "error",
				messaggio: "Per favore, compila tutti i campi obbligatori.",
			});

			setOpenSnack(true);
		} else {
			try {
				const response = await unsplash.put(
					"allegati/associa",
					{
						ID: cartellaSel.ID,
						ID_Figlio: cartellaSel.ID_Figlio,
						allegatiSel,
					},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem("jwt")}`,
						},
					}
				);

				// Check if the response was successful (status code 2xx)
				if (response.status >= 200 && response.status < 300) {
					reloadAfterUpload();
					setSnack({
						severity: "success",
						messaggio: "Associazione effettuata con successo",
					});
				} else {
					// Handle non-successful response
					setSnack({
						severity: "error",
						messaggio: "Errore durante associazione",
					});
				}

				setOpenSnack(true);
			} catch (error) {
				// Handle network or other errors
				console.error("Error during API request:", error);
				setSnack({
					severity: "error",
					messaggio: "Errore durante associazione",
				});
				setOpenSnack(true);
			} finally {
				handleCloseAssocia();
			}
		}
	};

	const handleDelete = async (id, estensione, operazione) => {
		try {
			/* await unsplash.delete('allegati', {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('jwt')}`,
				},
				data: {
					id: id,
				},
			}); */
			await unsplash.post(
				"ftp",
				{ file: id + estensione, operazione: "delete" },
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("jwt")}`,
					},
				}
			);
			setSnack({
				severity: "success",
				messaggio: "Allegato eliminato con successo",
			});
			setOpenSnack(true);
			reloadAfterUpload();
		} catch (error) {
			console.error(error);
		}
	};

	const handleSaveData = async (allegato) => {
		try {
			let responseModifica = await unsplash.put(
				"allegati",
				{ nome: allegato.Descrizione, id: allegato.ID },
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("jwt")}`,
					},
				}
			);
			setSnack({
				severity: "success",
				messaggio: "Modifica effettuata con successo",
			});
			setOpenSnack(true);
			reloadAfterUpload();
		} catch {
			setSnack({
				severity: "error",
				messaggio: "Errore durante la modifica",
			});
			setOpenSnack(true);
		}
	};

	const handleCheck = async (params) => {
		//MAIN NET
		//let link ="https://algoexplorer.io/tx/"
		//TESTNET
		let link = "https://testnet.algoexplorer.io/tx/";
		/* if(!params.row.Tx_id && !params.row.IDNotarizzazione){
      alert("Doc non notarizzato")
    }
    else  */
		if (params.row.IDNotarizzazione && !params.row.Tx_id) {
			//put notarize
			let responseID = await unsplash.put(
				"notarize",
				{ id: params.row.IDNotarizzazione },
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("jwt")}`,
					},
				}
			);
			if (responseID.data !== "") {
				link = link + responseID.data;
				window.open(link);
			} else {
				console.error(responseID);
			}
		} else if (params.row.IDNotarizzazione && params.row.Tx_id) {
			link = link + params.row.Tx_id;
			window.open(link);
		}
	};

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<Grid
					container
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
				>
					<GridToolbarQuickFilter placeholder="Cerca..." />
				</Grid>
			</GridToolbarContainer>
		);
	}

	const columns = [
		{
			filterable: false,
			field: "Actions",
			headerName: "Azioni",
			hide: false,
			width: "200",
			renderCell: (
				params //mi aiuta a mettere il valore
			) => (
				<Grid>
					<EditName
						id="editDoc"
						operazione="Modifica"
						allegato={params.row}
						saveFunction={handleSaveData}
					/>
					{/* <IconButton
            aria-label="edit"
            onClick={async () => {
              setAllegato({
                ID: params.row.ID,
                Descrizione: params.row.Descrizione,
              });
              document.getElementById("editDoc").click();
            }}
          >
            <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
          </IconButton> */}
					<IconButton
						aria-label="edit"
						onClick={() =>
							downloadFile(
								params.row.ID,
								params.row.Estensione,
								params.row.Descrizione,
								"download"
							)
						}
					>
						<Download
							style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
						/>
					</IconButton>
					{params.row.Estensione?.toLowerCase() === ".pdf" ? (
						<IconButton
							aria-label="edit"
							onClick={() =>
								downloadFile(
									params.row.ID,
									params.row.Estensione,
									params.row.Descrizione,
									"visualizza"
								)
							}
						>
							<RemoveRedEye
								style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
							/>
						</IconButton>
					) : (
						""
					)}
					{/* <IconButton
            aria-label="edit"
            onClick={() =>
              handleDelete(params.row.ID, params.row.Estensione, "delete")
            }
          >
            <Delete
              style={{ height: "2rem", width: "2rem", color: "#FF2768" }}
            />
          </IconButton> */}
					<DeleteDialog
						categoria={params.row}
						myFunction={() =>
							handleDelete(params.row.ID, params.row.Estensione, "delete")
						}
					/>
				</Grid>
			),
		},
		{ field: "Descrizione", headerName: "Descrizione", flex: 1 },
		{ field: "Estensione", headerName: "Estensione", flex: 1 },
		{
			field: "IDNotarizzazione",
			headerName: "Notarizzato",
			flex: 1,
			renderCell: (params) => (
				<Tooltip
					title={
						params.row.IDNotarizzazione
							? "Notarizzato il " +
							  moment(params.row.DataNotarizzazione).format("DD/MM/YYYY")
							: "Documento non notarizzato"
					}
				>
					<IconButton onClick={() => handleCheck(params)}>
						<Link
							style={{ color: params.row.IDNotarizzazione ? "green" : "red" }}
						/>
					</IconButton>
				</Tooltip>
			),
		},
		{ field: "ID", headerName: "", hide: true, flex: 1 },
	];

	useEffect(() => {
		const fetchData = async () => {
			try {
				const {
					idAzienda,
					idMedico,
					idLavoratore,
					idSopralluogo,
					id,
					colonna,
				} = props;

				let idToUpdate = 0;
				let colonnaToUpdate = "";

				if (idAzienda > 0) {
					idToUpdate = idAzienda;
					colonnaToUpdate = "Cliente";
				} else if (idMedico > 0) {
					idToUpdate = idMedico;
					colonnaToUpdate = "Medico";
				} else if (idLavoratore > 0) {
					idToUpdate = idLavoratore;
					colonnaToUpdate = "Lavoratore";
				} else if (idSopralluogo > 0) {
					idToUpdate = idSopralluogo;
					colonnaToUpdate = "Sopralluogo";
				}

				const params =
					colonna === "Sede" && idAzienda > 0
						? { colonna: "Sede", ID: id, ID_CLIENTE: idAzienda }
						: { colonna: colonnaToUpdate, ID: idToUpdate };

				const [responseAziendali, responseCartelle] = await Promise.all([
					unsplash.get("allegati", {
						headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
						params,
					}),

					props.id > 0 &&
					["Cliente", "Sede", "Medico", "Lavoratore"].includes(props.colonna)
						? unsplash.get("cartelle", {
								headers: {
									Authorization: `Bearer ${localStorage.getItem("jwt")}`,
								},
								params,
						  })
						: Promise.resolve({ data: { data: [] } }),
				]);

				/* const updatedAllegati = responseAziendali.data.data.map((item) => {
					return {
						...item,
						Sede: colonna === 'Sede' ? location.state.bloccoSede : '',
					};
				}); */
				setAllegati(responseAziendali.data.data);
				setCartelle(responseCartelle.data.data);
				setLoading(false);
			} catch (error) {
				console.error(error);
				// Handle errors appropriately (e.g., set an error state, log, or show a notification)
			}
		};

		fetchData();
	}, [props.idAzienda]);

	const reloadAfterUpload = async () => {
		let id = 0;
		let colonna = "";
		let params = { colonna: "", ID: 0 };

		if (props.idAzienda > 0) {
			id = props.idAzienda;
			colonna = "Cliente";
		}
		if (props.idMedico > 0) {
			id = props.idMedico;
			colonna = "Medico";
		}
		if (props.idLavoratore > 0) {
			id = props.idLavoratore;
			colonna = "Lavoratore";
		}

		if (props.idSopralluogo > 0) {
			id = props.idSopralluogo;
			colonna = "Sopralluogo";
		}
		if (props.idAzienda > 0 && props.colonna === "Sede") {
			params = { colonna: "Sede", ID: props.id, ID_CLIENTE: props.idAzienda };
		} else {
			params = { colonna, ID: id };
		}

		let responseAziendali = await unsplash.get("allegati", {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("jwt")}`,
			},
			params: params,
		});
		//THIS BECAUSE FUNCTION GETS PASSED TO OTHER
		if (
			props.id > 0 &&
			["Cliente", "Sede", "Medico", "Lavoratore"].includes(props.colonna)
		) {
			try {
				let responseCartelle = await unsplash.get("cartelle", {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("jwt")}`,
					},
					params: params,
				});
				setCartelle(responseCartelle.data.data);
			} catch (error) {
				console.log(error);
			}
		} /* else if (props.colonna === "Sopralluogo") {
      props.loadAllegati();
    } */
		setAllegati(responseAziendali.data.data);

		/* console.log(props);
		if (props.idAzienda > 0) {
			const updatedAllegati = responseAziendali.data.data.map((item) => {
				return {
					...item,
					Sede:
						props.colonna === 'Sede'
							? location.state.bloccoSede
							: location.state.RagioneSociale,
				};
			});
			setAllegati(updatedAllegati);
		} else {
			setAllegati(responseAziendali.data.data);
		} */
	};

	const handleCreate = async () => {
		if (cartella.nome === "") {
			setSnack({
				severity: "error",
				messaggio: "Per favore, compila tutti i campi obbligatori.",
			});

			setOpenSnack(true);
		} else if (
			cartelle.some(
				(item) => item.Nome.toLowerCase() === cartella.nome.toLowerCase()
			)
		) {
			setSnack({
				severity: "error",
				messaggio: "Inserisci un Nome diverso",
			});
			setOpenSnack(true);
		} else {
			try {
				let requestBody = {
					colonna: props.colonna,
					id: props.id,
					nome: cartella.nome,
					ID_Padre: cartella.ID_Padre,
				};

				if (props.colonna === "Sede") {
					requestBody = {
						...requestBody,
						idAzienda: props.idAzienda,
					};
				}

				const responseCreate = await unsplash.post("cartelle", requestBody, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("jwt")}`,
					},
				});
				reloadAfterUpload();
				handleCloseAdd();
				setSnack({
					severity: "success",
					messaggio: "Cartella creata con sucesso ",
				});
				setOpenSnack(true);
			} catch (error) {
				setSnack({
					severity: "error",
					messaggio: "Errore durante la creazione della cartella ",
				});
				setOpenSnack(true);

				console.error(error);
			}
		}
	};

	const handleOpenRemoveCartella = () => {
		setIsRemoveCartella(true);
	};

	const handleAssociaFile = () => {
		setIsAssocia(true);
	};

	const handleOpenEdit = () => {
		setIsEditCartella(true);
	};

	const handleCloseAssocia = () => {
		setCartellaSel({
			ID: null,
			ID_Figlio: null,
		});
		setRowSelectionModel([]);
		setAllegatiSel([]);
		setSottocartelle([]);
		setIsAssocia(false);
	};

	const handleSaveEdit = async () => {
		try {
			// Check if required fields are filled
			if (
				cartellaSel.ID === null ||
				cartellaSel.nomeCartella.trim() === "" ||
				(cartellaSel.ID_Figlio !== null &&
					cartellaSel.nomeSottocartella.trim() === "")
			) {
				setSnack({
					severity: "error",
					messaggio: "Per favore, compila tutti i campi obbligatori.",
				});
			} else {
				// Check if the names remain unchanged
				const isCartellaNameUnchanged = cartelle.some(
					(c) =>
						c.ID === cartellaSel.ID &&
						c.Nome === cartellaSel.nomeCartella.trim()
				);

				const isSottocartellaNameUnchanged =
					cartellaSel.ID_Figlio !== null &&
					sottocartelle.some(
						(s) =>
							s.ID === cartellaSel.ID_Figlio &&
							s.Nome === cartellaSel.nomeSottocartella.trim()
					);

				if (!(isCartellaNameUnchanged && isSottocartellaNameUnchanged)) {
					const cartellaExists = cartelle.some(
						(c) =>
							c.Nome === cartellaSel.nomeCartella.trim() &&
							c.ID !== cartellaSel.ID
					);

					const sottocartellaExists =
						cartellaSel.ID_Figlio !== null &&
						sottocartelle.some(
							(s) =>
								s.Nome === cartellaSel.nomeSottocartella.trim() &&
								s.ID !== cartellaSel.ID_Figlio
						);

					if (cartellaExists || sottocartellaExists) {
						setSnack({
							severity: "error",
							messaggio:
								"Nome già presente. Per favore, inserisci un nome unico.",
						});
						return;
					}
				}
				const response = await unsplash.put(
					"cartelle",
					{ cartellaSel },
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem("jwt")}`,
						},
					}
				);

				setSnack({
					severity: "success",
					messaggio: "Modifiche salvate con successo.",
				});

				await reloadAfterUpload();
				handleCloseEdit();
			}
		} catch (error) {
			// Handle API call errors
			console.error("API Error:", error);

			setSnack({
				severity: "error",
				messaggio: "Si è verificato un errore durante il salvataggio.",
			});
		} finally {
			setOpenSnack(true);
		}
	};

	const handleScaricaAllegati = async () => {
		try {
			document.body.classList.add("waiting");
			setScaricaButtonDisabled(true);
			let nome;

			if (props.colonna === "Cliente") {
				nome = location.state.RagioneSociale;
			} else if (props.colonna === "Sede") {
				nome = location.state.bloccoSede;
			} else if (props.colonna === "Lavoratore") {
				nome = props.nomeLav;
			}

			// First API call
			const responseGenera = await unsplash.get(`allegati/genera`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("jwt")}`,
				},
				params: { colonna: props?.colonna, ID: props?.id, nome },
			});

			// Second API call, only if the first one is successful
			if (responseGenera) {
				const response = await unsplash.get(`allegati/scarica`, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("jwt")}`,
					},
					params: { nomeFile: responseGenera.data },
					responseType: "blob",
				});

				const blob = new Blob([response.data], {
					type: "application/zip",
				});
				const blobUrl = URL.createObjectURL(blob);
				const downloadLink = document.createElement("a");
				downloadLink.href = blobUrl;
				downloadLink.download = `${nome} - ${new Date()
					.toISOString()
					.slice(0, 10)}.zip`;

				// Append the anchor element to the document
				document.body.appendChild(downloadLink);

				// Trigger a click on the anchor element
				downloadLink.click();

				// Remove the anchor element from the document
				document.body.removeChild(downloadLink);
			}

			setSnack({
				severity: "success",
				messaggio: "Allegati scaricati con successo",
			});
		} catch (error) {
			console.error("Error during file download:", error);
			setSnack({
				severity: "error",
				messaggio: "Errore durante la scarica dei allegati",
			});
		} finally {
			document.body.classList.remove("waiting");
			setOpenSnack(true);
			setScaricaButtonDisabled(false);
		}
	};

	if (
		props.id > 0 &&
		["Cliente", "Sede", "Medico", "Lavoratore"].includes(props.colonna)
	) {
		columns.unshift(
			{
				field: "Cartella_Nome",
				headerName: "Cartella",
				flex: 1,
			},
			{
				field: "SottoCartella_Nome",
				headerName: "Sottocartella",
				flex: 1,
			}
		);
	}
	if (props.idAzienda > 0) {
		columns.unshift({
			field: "nomeSede",
			headerName: "Sede",
			flex: 1,
		});
	}

	return (
		<>
			<GlobalSnackbar
				severity={snack.severity}
				messaggio={snack.messaggio}
				openSnack={openSnack}
				handleCloseSnack={handleCloseSnack}
			/>
			<Grid
				container
				direction="row"
				alignItems="center"
				justifyContent="flex-end"
				spacing={2}
			>
				<ButtonGroup
					variant="contained"
					aria-label="outlined primary button group"
				>
					<div className="">
						{props.id > 0 &&
							["Cliente", "Sede", "Medico", "Lavoratore"].includes(
								props.colonna
							) && (
								<>
									{rowSelectionModel.length > 0 && (
										<Button
											className="ButtonMiller"
											startIcon={<DriveFolderUploadOutlinedIcon />}
											// OPEN MODAL ON CLICK
											onClick={() => {
												handleAssociaFile();
											}}
										>
											Associa file
										</Button>
									)}
									<Button
										className="ButtonMiller"
										startIcon={<FolderOffOutlinedIcon />}
										// OPEN MODAL ON CLICK
										onClick={handleOpenRemoveCartella}
									>
										Elimina cartella
									</Button>

									<Button
										className="ButtonMiller"
										startIcon={<FolderOpenOutlinedIcon />}
										// OPEN MODAL ON CLICK
										onClick={() => {
											handleOpenEdit();
										}}
									>
										Modifica Cartella
									</Button>
									<Button
										className="ButtonMiller"
										startIcon={<CreateNewFolderOutlinedIcon />}
										// OPEN MODAL ON CLICK
										onClick={() => {
											setIsAddCartella(true);
										}}
									>
										Crea cartella
									</Button>
								</>
							)}
						{props.id > 0 &&
							["Cliente", "Sede", "Lavoratore"].includes(props.colonna) && (
								<Button
									variant="contained"
									className="ButtonMiller items-end"
									onClick={() => setIsScaricaAllegati(true)}
									startIcon={<DownloadIcon />}
									disabled={scaricaButtonDisabled}
								>
									Scarica Allegati {props.colonna}
								</Button>
							)}
					</div>

					{props.idAzienda > 0 && props.colonna === "Sede" ? (
						<FileUploader
							colonna={props.colonna}
							id={props.id}
							reloadAfterUpload={reloadAfterUpload}
							extra={props.idAzienda}
							colonnaExtra={"Cliente"}
						/>
					) : (
						// Render the second FileUploader without additional fields
						<FileUploader
							colonna={props.colonna}
							id={props.id}
							reloadAfterUpload={reloadAfterUpload}
							idAzienda={props.idAzienda}
						/>
					)}
				</ButtonGroup>
			</Grid>
			<Box sx={{ height: "60vh", width: "100%" }}>
				<DataGridPro
					components={{
						Toolbar: CustomToolbar,
						Footer: CustomFooterPersonalized,
					}}
					localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
					slots={{
						loadingOverlay: LinearProgress,
					}}
					columns={columns}
					rows={allegati}
					loading={loading}
					rowSelectionModel={rowSelectionModel}
					isRowSelectable={(params) =>
						params.row.cartella !== null || params.row.sottocartella !== null
					}
					checkboxSelection
					onSelectionModelChange={(ids) => {
						const selectedIDs = new Set(ids);
						const selectedRowData = allegati.filter((row) =>
							selectedIDs.has(row.ID)
						);

						setRowSelectionModel(ids);

						setAllegatiSel(selectedIDs.size > 0 ? selectedRowData : []);
					}}
					getRowId={(allegati) => allegati.ID}
					//disableSelectionOnClick
					initialState={{ pinnedColumns: { left: ["Actions"] } }}
				/>
			</Box>
			<Dialog
				fullWidth
				maxWidth="md"
				open={addCartella}
				onClose={handleCloseAdd}
				TransitionComponent={Transition}
			>
				<DialogTitle>Crea Cartella</DialogTitle>

				<DialogContent>
					<Grid container>
						<TextField
							className="my-2"
							id="standard-basic"
							fullWidth
							margin="normal"
							onChange={(event) => {
								setCartella({
									...cartella,
									nome: event.target.value,
								});
							}}
							name="RagioneSociale"
							label="Nome della cartella *"
							defaultValue={""}
						/>
						{cartelle.length > 0 && (
							<TextField
								id="outlined-select-currency"
								select
								margin="normal"
								fullWidth
								label="Cartella Padre"
								value={cartella.ID_Padre} // Specify the selected value
								onChange={(event) => {
									setCartella({
										...cartella,
										ID_Padre: event.target.value,
									});
								}}
							>
								<MenuItem value={null}>Nessuna</MenuItem>
								{cartelle
									.filter((option) => option.ID_Padre === null)
									.map((option) => (
										<MenuItem key={option.ID} value={option.ID}>
											{option.Nome}
										</MenuItem>
									))}
							</TextField>
						)}
					</Grid>
				</DialogContent>
				<DialogActions
					sx={{
						display: "flex",
						justifyContent: "space-between",
						px: 3,
					}}
				>
					<Button
						variant="contained"
						className="ButtonMiller"
						startIcon={<Close />}
						onClick={handleCloseAdd}
					>
						Chiudi
					</Button>
					<Button
						variant="contained"
						className="ButtonMiller"
						startIcon={<Check />}
						onClick={handleCreate}
					>
						Salva
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				fullWidth
				maxWidth="md"
				open={associaFile}
				onClose={handleCloseAssocia}
				TransitionComponent={Transition}
			>
				<DialogTitle>Associazione File</DialogTitle>
				<DialogContentText
					id="alert-dialog-description"
					className="flex justify-center"
				>
					<b className="my-2">
						Attenzione, sei sicuro di voler associare i seguenti documenti
					</b>
					<br />
				</DialogContentText>
				{allegatiSel.map((item, index) => (
					<div key={index} className="flex flex-row justify-center">
						{item.Descrizione}
					</div>
				))}
				<DialogContent>
					<Grid container>
						<TextField
							id="outlined-select-currency"
							select
							margin="normal"
							fullWidth
							label="Cartella *"
							value={cartellaSel.ID}
							onChange={(event) => {
								const selectedCartellaID = event.target.value;
								const filteredSottocartelle = cartelle.filter(
									(option) => option.ID_Padre === selectedCartellaID
								);
								setSottocartelle(filteredSottocartelle);

								let updatedCartellaSel = { ...cartellaSel };
								updatedCartellaSel.ID = selectedCartellaID;
								setCartellaSel(updatedCartellaSel);
							}}
						>
							<MenuItem value={null}>Nessuna</MenuItem>

							{cartelle
								.filter((option) => option.ID_Padre === null) // Filter out items with ID_Padre === null
								.map((option) => (
									<MenuItem key={option.ID} value={option.ID}>
										{option.Nome}
									</MenuItem>
								))}
						</TextField>

						<TextField
							id="outlined-select-currency"
							select
							margin="normal"
							fullWidth
							label="Sottocartella"
							disabled={sottocartelle.length === 0}
							value={cartellaSel.ID_Figlio} // Specify the selected value
							onChange={(event) => {
								const selectedCartellaID = event.target.value;
								let updatedCartellaSel = { ...cartellaSel };
								updatedCartellaSel.ID_Figlio = selectedCartellaID;
								setCartellaSel(updatedCartellaSel);
							}}
						>
							<MenuItem value={null}>Nessuna</MenuItem>
							{sottocartelle.map((option) => (
								<MenuItem key={option.ID} value={option.ID}>
									{option.Nome}
								</MenuItem>
							))}
						</TextField>
					</Grid>
				</DialogContent>
				<DialogActions
					sx={{
						display: "flex",
						justifyContent: "space-between",
						px: 3,
					}}
				>
					<Button
						variant="contained"
						className="ButtonMiller"
						startIcon={<Close />}
						onClick={handleCloseAssocia}
					>
						Chiudi
					</Button>
					<Button
						variant="contained"
						className="ButtonMiller"
						startIcon={<Check />}
						onClick={handleSaveAssocia}
					>
						Salva
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				fullWidth
				maxWidth="md"
				open={removeCartella}
				onClose={handleCloseRemove}
				TransitionComponent={Transition}
			>
				<DialogTitle>Elimina Cartella</DialogTitle>
				<DialogContentText
					id="alert-dialog-description"
					className="flex justify-center flex-col items-center text-center"
				>
					{cartellaSel.ID_Figlio === null && cartellaSel.ID !== null && (
						<div>
							<b className="">
								Attenzione, sei sicuro di voler eliminare la cartella e
								disassociare tutti i figle associati ad essa, tutte le sue
								sottocartelle verrano eliminate.
							</b>
							<br />

							<b className="text-lg">I FILE NON VERRANO ELIMINATI !</b>
						</div>
					)}
					{cartellaSel.ID_Figlio !== null && (
						<div>
							<b className="">
								Attenzione, sei sicuro di voler eliminare la sottocartella e
								disassociare tutti i figle associati ad essa.
							</b>
							<br />
							<b className="text-lg">I FILE NON VERRANO ELIMINATI !</b>
						</div>
					)}
				</DialogContentText>
				<DialogContent>
					<Grid container>
						<TextField
							id="outlined-select-currency"
							select
							margin="normal"
							fullWidth
							label="Cartella *"
							value={cartellaSel.ID}
							onChange={(event) => {
								const selectedCartellaID = event.target.value;

								const filteredSottocartelle = cartelle.filter(
									(option) => option.ID_Padre === selectedCartellaID
								);

								setSottocartelle(filteredSottocartelle);

								let updatedCartellaSel = { ...cartellaSel };
								updatedCartellaSel.ID = selectedCartellaID;
								setCartellaSel(updatedCartellaSel);
							}}
						>
							<MenuItem value={null}>Nessuna</MenuItem>

							{cartelle
								.filter((option) => option.ID_Padre === null) // Filter out items with ID_Padre === null
								.map((option) => (
									<MenuItem key={option.ID} value={option.ID}>
										{option.Nome}
									</MenuItem>
								))}
						</TextField>

						<TextField
							id="outlined-select-currency"
							select
							margin="normal"
							fullWidth
							label="Sottocartella"
							disabled={sottocartelle.length === 0}
							value={cartellaSel.ID_Figlio} // Specify the selected value
							onChange={(event) => {
								const selectedCartellaID = event.target.value;
								let updatedCartellaSel = { ...cartellaSel };
								updatedCartellaSel.ID_Figlio = selectedCartellaID;
								setCartellaSel(updatedCartellaSel);
							}}
						>
							<MenuItem value={null}>Nessuna</MenuItem>
							{sottocartelle.map((option) => (
								<MenuItem key={option.ID} value={option.ID}>
									{option.Nome}
								</MenuItem>
							))}
						</TextField>
					</Grid>
				</DialogContent>
				<DialogActions
					sx={{
						display: "flex",
						justifyContent: "space-between",
						px: 3,
					}}
				>
					<Button
						variant="contained"
						className="ButtonMiller"
						startIcon={<Close />}
						onClick={handleCloseRemove}
					>
						Chiudi
					</Button>
					<Button
						variant="contained"
						className="ButtonMiller"
						startIcon={<Check />}
						onClick={handleRemoveCartella}
					>
						Salva
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				fullWidth
				maxWidth="md"
				open={isEditCartella}
				onClose={handleCloseEdit}
				TransitionComponent={Transition}
			>
				<DialogTitle>Modifica Cartella</DialogTitle>
				<DialogContentText
					id="alert-dialog-description"
					className="flex justify-center flex-col items-center text-center"
				>
					Seleziona la cartella a cui desideri cambiare il nome
				</DialogContentText>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={cartellaSel.ID ? 6 : 12}>
							<TextField
								id="outlined-select-currency"
								select
								margin="normal"
								fullWidth
								label="Cartella *"
								value={cartellaSel.ID}
								onChange={(event) => {
									const selectedCartellaID = event.target.value;

									const filteredSottocartelle = cartelle.filter(
										(option) => option.ID_Padre === selectedCartellaID
									);

									setSottocartelle(filteredSottocartelle);

									let updatedCartellaSel = {
										...cartellaSel,
										ID: selectedCartellaID,
									};

									if (selectedCartellaID === null) {
										// If "Nessuna" is selected, set ID_Figlio and nomeSottocartella to null and ""
										updatedCartellaSel.ID_Figlio = null;
										updatedCartellaSel.nomeSottocartella = "";
									} else {
										// Check if the currently selected Sottocartella is still valid
										if (
											!filteredSottocartelle.some(
												(s) => s.ID === cartellaSel.ID_Figlio
											)
										) {
											// If not, reset ID_Figlio and nomeSottocartella
											updatedCartellaSel.ID_Figlio = null;
											updatedCartellaSel.nomeSottocartella = "";
										}

										// Set nomeCartella based on the selected ID
										const initialNomeCartella =
											cartelle.find((c) => c.ID === selectedCartellaID)?.Nome ||
											"";
										updatedCartellaSel.nomeCartella = initialNomeCartella;
									}

									setCartellaSel(updatedCartellaSel);
								}}
							>
								<MenuItem value={null}>Nessuna</MenuItem>

								{cartelle
									.filter((option) => option.ID_Padre === null) // Filter out items with ID_Padre === null
									.map((option) => (
										<MenuItem key={option.ID} value={option.ID}>
											{option.Nome}
										</MenuItem>
									))}
							</TextField>
						</Grid>

						<Grid item xs={cartellaSel.ID ? 6 : 0}>
							{cartellaSel.ID !== null && (
								<TextField
									id="outlined-select-currency"
									margin="normal"
									label="Nome cartella *"
									fullWidth
									value={cartellaSel.nomeCartella}
									onChange={(event) => {
										const selectedCartellaID = event.target.value;
										let updatedCartellaSel = { ...cartellaSel };
										updatedCartellaSel.nomeCartella = selectedCartellaID;
										setCartellaSel(updatedCartellaSel);
									}}
								></TextField>
							)}
						</Grid>

						<Grid item xs={cartellaSel.ID_Figlio ? 6 : 12}>
							<TextField
								id="outlined-select-currency"
								select
								margin="normal"
								fullWidth
								label="Sottocartella"
								disabled={sottocartelle.length === 0}
								value={cartellaSel.ID_Figlio} // Specify the selected value
								onChange={(event) => {
									const selectedSottocartellaID = event.target.value;
									let updatedCartellaSel = {
										...cartellaSel,
										ID_Figlio: selectedSottocartellaID,
									};

									// Set nomeSottocartella based on the selected ID_Figlio
									const initialNomeSottocartella =
										sottocartelle.find((s) => s.ID === selectedSottocartellaID)
											?.Nome || "";
									updatedCartellaSel.nomeSottocartella =
										initialNomeSottocartella;

									setCartellaSel(updatedCartellaSel);
								}}
							>
								<MenuItem value={null}>Nessuna</MenuItem>
								{sottocartelle.map((option) => (
									<MenuItem key={option.ID} value={option.ID}>
										{option.Nome}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid item xs={cartellaSel.ID_Figlio ? 6 : 0}>
							{cartellaSel.ID_Figlio !== null && (
								<TextField
									id="outlined-select-currency"
									margin="normal"
									label="Nome Sottocartella *"
									fullWidth
									value={cartellaSel.nomeSottocartella} // Specify the selected value
									onChange={(event) => {
										const selectedCartellaID = event.target.value;
										let updatedCartellaSel = { ...cartellaSel };
										updatedCartellaSel.nomeSottocartella = selectedCartellaID;
										setCartellaSel(updatedCartellaSel);
									}}
								></TextField>
							)}
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions
					sx={{
						display: "flex",
						justifyContent: "space-between",
						px: 3,
					}}
				>
					<Button
						variant="contained"
						className="ButtonMiller"
						startIcon={<Close />}
						onClick={handleCloseEdit}
					>
						Chiudi
					</Button>
					<Button
						variant="contained"
						className="ButtonMiller"
						startIcon={<Check />}
						onClick={handleSaveEdit}
					>
						Salva
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={scaricaAllegati}
				fullWidth
				maxWidth={"sm"}
				onClick={() => {
					setIsScaricaAllegati(false);
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				TransitionComponent={Transition}
			>
				<DialogTitle id="alert-dialog-title">
					Scarica Allegati {props.colonna}
				</DialogTitle>
				<DialogContentText
					id="alert-dialog-description"
					className="p-4 text-center"
				>
					Attenzione, sei sicuro di voler scaricare tutti i documenti{" "}
					{props.colonna === "Sede" ? "della" : "del"} {props.colonna + " : "}
					{props.colonna === "Cliente"
						? location.state.RagioneSociale
						: props.colonna === "Sede"
						? location.state.bloccoSede
						: props.nomeLav}
					<br />
				</DialogContentText>

				<DialogContent></DialogContent>
				<DialogActions
					sx={{
						display: "flex",
						justifyContent: "space-between",
						px: 3,
					}}
				>
					<Button
						variant="contained"
						className="ButtonMiller"
						onClick={() => {
							setIsScaricaAllegati(false);
						}}
						sx={{ width: "25%" }}
						startIcon={<Close />}
					>
						Chiudi
					</Button>
					<Button
						variant="contained"
						className="ButtonMiller"
						onClick={handleScaricaAllegati}
						sx={{ width: "25%" }}
						startIcon={<Check />}
					>
						Conferma
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default Allegati;

const EditName = (props) => {
	const [open, setOpen] = useState(false);
	const [allegato, setAllegato] = useState({
		Descrizione: "",
		ID: "",
	});
	const [validation, setValidation] = useState(false);
	const handleClickOpen = () => {
		setValidation(false);
		setAllegato(props.allegato);
		setOpen(true);
	};

	const handleClose = () => {
		//setSede(newSede);

		setOpen(false);
	};
	const handleChange = (e) => {
		let a = { ...allegato };
		a[e.target.name] = e.target.value;
		setAllegato(a);
	};

	const handleSave = () => {
		if (allegato.Descrizione === "" || allegato.Descrizione === null) {
			setValidation(true);
		} else {
			props.saveFunction(allegato);
		}
	};

	return (
		<>
			<IconButton aria-label="edit" onClick={handleClickOpen} id={props.id}>
				<Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
			</IconButton>
			<Dialog
				fullWidth
				maxWidth="md"
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<DialogTitle>{props.operazione}</DialogTitle>
				<DialogContent>
					<Grid container>
						<TextField
							value={allegato.Descrizione}
							error={validation}
							helperText={validation ? "Descrizione obbligatoria" : ""}
							margin="dense"
							name="Descrizione"
							label="Descrizione"
							type="text"
							fullWidth
							required
							variant="outlined"
							onChange={handleChange}
						/>
					</Grid>
				</DialogContent>
				<DialogActions
					sx={{
						display: "flex",
						justifyContent: "space-between",
						px: 3,
					}}
				>
					<Button
						variant="contained"
						className="ButtonMiller"
						startIcon={<Close />}
						onClick={handleClose}
					>
						Chiudi
					</Button>
					<Button
						variant="contained"
						className="ButtonMiller"
						startIcon={<Check />}
						onClick={handleSave}
					>
						Salva
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
const DeleteDialog = (props) => {
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<IconButton aria-label="edit" onClick={handleClickOpen}>
				<Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
			</IconButton>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
					Sei sicuro di voler eliminare l'allegato selezionato?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Attenzione, sei sicuro di voler eliminare il seguente allegato :
						<b>{props.categoria.Descrizione}</b>
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{
						display: "flex",
						justifyContent: "space-between",
						px: 3,
					}}
				>
					<Button onClick={handleClose}>annulla</Button>
					<Button onClick={() => props.myFunction()}>Conferma</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
