import React, { useState } from "react";
import unsplash from "../api/unsplash";
import {
  Checkbox,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  TextField,
  DialogActions,
  Grid,
  ButtonGroup,
  FormControlLabel,
  Zoom,
  IconButton,
  LinearProgress,
  Paper,
  Alert,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  Skeleton,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
 GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import { Add, Group, Edit, Euro, People, Badge, BlurOn } from "@mui/icons-material";
import GlobalSnackbar from "./Snackbar";
import EditIcon from "@mui/icons-material/Edit";
import { Delete, Close, Check } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});


const datiPacchetti ={

1:{Nome:"Starter"},
2:{Nome:"Professional"},
3:{Nome:"Enterprise"}

}




const Consumi = (props) => {
  const [open, setOpen] = useState(false);
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [utenteSel, setUtenteSel] = useState(null);
  const [totaleLavoratori, setTotaleLavoratori] = useState(0);
  const [totaleAmbienti, setTotaleAmbienti] = useState(0);
  const [totaleUtenti, setTotaleUtenti] = useState(0);
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..."/>
        </Grid>
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      filterable: false,

      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <ModificaUtente
            utente={params.row}
            handleLoadUtenti={handleLoadUtenti}
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
          />
          <DeleteDialog
            categoria={params.row}
            myFunction={handleDelete}
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
          />
        </>
      ),
    },
    {
      field: "username",
      headerName: "Username",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "tipoUtente",
      headerName: "Tipologia",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          {params.row.Sicurezza ? "Sicurezza" : ""}{" "}
          {params.row.Medicina ? "Medicina" : ""}{" "}
          {params.row.Formazione ? "Formazione" : ""}
        </>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Admin",
      headerName: "Admin",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
  ];

  const handleClickOpen = async () => {
    let response = await unsplash.get("settings", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setSettings(response.data.data[0]);


    let responsePanoramica = await unsplash.get("settings/panoramica", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    setLoading(false);
    setUtenteSel(null);
    setTotaleLavoratori(responsePanoramica.data.data.TotLavoratori);
    setTotaleUtenti(responsePanoramica.data.data.TotUtenti)
    setTotaleAmbienti(responsePanoramica.data.data.TotAmbienti)
    setOpen(true);

  };

  const handleLoadUtenti = async () => {
    let response = await unsplash.get("settings", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setSettings(response.data.data);
    setUtenteSel(null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (utente) => {
    let u = { ...utente };
    u.Attivo = 0;
    try {
      let response = await unsplash.put(
        "utenti",
        { utente: u, operazione: "elimina" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      handleLoadUtenti();
      setSnack({
        severity: "success",
        messaggio: "Utente eliminato con successo",
      });
      setOpenSnack(true);
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione",
      });
      setOpenSnack(true);
    }
  };

  //p[index].IDAggiuntivo = null;

  return (
    <>
      <Button
        startIcon={<Euro />}
        className="ButtonMiller"
        fullWidth
        variant="contained"
        onClick={handleClickOpen}
      >
       Panoramica 
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="sm"
        TransitionComponent={Transition}
        
      >
        <GlobalSnackbar
          severity={snack.severity}
          messaggio={snack.messaggio}
          openSnack={openSnack}
          handleCloseSnack={handleCloseSnack}
        />
        <DialogTitle>Panoramica </DialogTitle>
        <DialogContent>
        <Grid container spacing={2}>
        <Grid item xs={12} > 
          <Paper sx={{ p: 2,background:"#11526F",color:"white",justifyContent:"center",display:"flex"}} elevation={0} >
          {"Pacchetto " + datiPacchetti[settings?settings.Pacchetto:1].Nome}
          </Paper>
       
       </Grid> 
       <Grid item xs={4} > 
          <Paper sx={{ p: 2,background:"#11526F",color:"white" }} elevation={0}>
            <List sx={{ py: 0 }}>
              <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                <ListItemAvatar>
                  <Badge />
                </ListItemAvatar>
                <ListItemText sx={{ py: 0 }} secondaryTypographyProps={{color:"white"}}primary={totaleLavoratori} secondary={"Lavoratori"} />
              </ListItem>
            </List>
          </Paper>
       
       </Grid> 
       <Grid item xs={4} >
       <Paper sx={{ p: 2,background:"#11526F",color:"white" }} elevation={0}>
            <List sx={{ py: 0 }}>
              <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                <ListItemAvatar>
                  <People />
                </ListItemAvatar>
                <ListItemText sx={{ py: 0 }} secondaryTypographyProps={{color:"white"}} primary={totaleUtenti} secondary={"Utenti"} />
              </ListItem>
            </List>
            </Paper>
        
       </Grid>
       <Grid item xs={4} >
       <Paper sx={{ p: 2,background:"#11526F",color:"white" }} elevation={0}>
            <List sx={{ py: 0 }}>
              <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                <ListItemAvatar>
                  <People />
                </ListItemAvatar>
                <ListItemText sx={{ py: 0 }} secondaryTypographyProps={{color:"white"}} primary={totaleAmbienti} secondary={"Ambienti"} />
              </ListItem>
            </List>
            </Paper>
    
      </Grid>

    </Grid>
        </DialogContent>
       

        <DialogActions sx={{ marginLeft: 2, marginRight: "auto", display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={handleClose}
            className="ButtonMiller"
            variant="contained"
            startIcon={<Close />}
          >
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default Consumi;

const CreaUtente = (props) => {
  const [open, setOpen] = useState(false);
  const [utente, setUtente] = useState();
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleChange = (e) => {
    let u = { ...utente };
    u[e.target.name] = e.target.value;
    setUtente(u);
  };

  const handleClickOpen = async () => {
    setUtente({
      username: "",
      //tipoUtente: "Sicurezza",
      Admin: 0,
      email: "",
      Medicina: 0,
      Formazione: 0,
      Sicurezza: 0,
    });

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //p[index].IDAggiuntivo = null;

  const handleSave = async () => {
    try {
      let response = await unsplash.post(
        "utenti",
        { utente: utente },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      setOpen(false);
      props.handleLoadUtenti();
      setSnack({
        severity: "success",
        messaggio: "Utente creato con successo",
      });
      setOpenSnack(true);
    } catch {
      setSnack({ severity: "error", messaggio: "Errore durante la creazione" });
      setOpenSnack(true);
    }
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Button
        startIcon={<Add />}
        className="ButtonMiller"
        variant="contained"
        onClick={handleClickOpen}
      >
        Aggiungi
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="md"
        TransitionComponent={Transition}
      >
        <DialogTitle>Aggiungi Utente</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                //error={validation?.RagioneSociale}
                
                margin="dense"
                name="username"
                label="Username"
                type="text"
                fullWidth
                value={utente?.username}
                onChange={handleChange}
                variant="outlined"
                //helperText={validation?.RagioneSociale?"Campo obbligatorio":""}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                //error={validation?.RagioneSociale}
                
                margin="dense"
                name="email"
                label="Email"
                type="text"
                fullWidth
                value={utente?.email}
                onChange={handleChange}
                variant="outlined"
                //helperText={validation?.RagioneSociale?"Campo obbligatorio":""}
              />
            </Grid>
            {/* <Grid item xs={6}>
              Tipo Utente
              <select
                name="Tipologia"
                className="SelectFromSettings"
                id="tipologia"
                onChange={(e) => {
                  let u = { ...utente };
                  u.tipoUtente = e.target.value;
                  setUtente(u);
                }}
                value={utente?.tipoUtente}
              >
                <option value="Sicurezza">Sicurezza</option>
                <option value="Medicina">Medicina</option>
                <option value="Formazione">Formazione</option>
              </select>
            </Grid> */}
            <Grid item xs={12}>
              <label htmlFor="subtitle">
                <b> Tipologia Utente:</b>
              </label>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", flexWrap: "wrap" }}
              style={{ paddingTop: "0px" }}
            >
              <FormControlLabel
                sx={{ flexGrow: 1, m: 0 }}
                control={
                  <Checkbox
                    checked={utente?.Sicurezza === 1}
                    onChange={(e) => {
                      let u = { ...utente };
                      u.Sicurezza = e.target.checked ? 1 : 0;
                      setUtente(u);
                    }}
                  />
                }
                label="Sicurezza"
              />
              <FormControlLabel
                sx={{ flexGrow: 1, m: 0 }}
                control={
                  <Checkbox
                    checked={utente?.Medicina === 1}
                    onChange={(e) => {
                      let u = { ...utente };
                      u.Medicina = e.target.checked ? 1 : 0;
                      setUtente(u);
                    }}
                  />
                }
                label="Medicina"
              />
              <FormControlLabel
                sx={{ flexGrow: 1, m: 0 }}
                control={
                  <Checkbox
                    checked={utente?.Formazione === 1}
                    onChange={(e) => {
                      let u = { ...utente };
                      u.Formazione = e.target.checked ? 1 : 0;
                      setUtente(u);
                    }}
                  />
                }
                label="Formazione"
              />
              <FormControlLabel
                sx={{ flexGrow: 1, m: 0 }}
                control={
                  <Checkbox
                    checked={utente?.Admin === 1}
                    onChange={(e) => {
                      let u = { ...utente };
                      u.Admin = e.target.checked ? 1 : 0;
                      setUtente(u);
                    }}
                  />
                }
                label="Amministratore"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between", mx: 2 }}
        >
          <Button
            onClick={handleClose}
            className="ButtonMiller"
            variant="contained"
            startIcon={<Close />}
          >
            Chiudi
          </Button>
          <Button
            onClick={handleSave}
            className="ButtonMiller"
            variant="contained"
            startIcon={<Check />}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ModificaUtente = (props) => {
  const [open, setOpen] = useState(false);
  const [utente, setUtente] = useState();
  /* const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  }); */

  const handleChange = (e) => {
    let u = { ...utente };
    u[e.target.name] = e.target.value;
    setUtente(u);
  };

  const handleClickOpen = async () => {
    setUtente(props.utente);

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //p[index].IDAggiuntivo = null;

  const handleSave = async () => {
    try {
      let u = { ...utente };
      u.Attivo = 1;

      let response = await unsplash.put(
        "utenti",
        { utente: u },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      setOpen(false);
      props.handleLoadUtenti();
      props.setSnack({
        severity: "success",
        messaggio: "Utente modificato con successo",
      });
      props.setOpenSnack(true);
    } catch {
      props.setSnack({
        severity: "error",
        messaggio: "Errore durante la modifica",
      });
      props.setOpenSnack(true);
    }
  };
  const handleDelete = async () => {
    try {
      let u = { ...utente };
      u.Attivo = 0;

      let response = await unsplash.put(
        "utenti",
        { utente: u, operazione: "elimina" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      setOpen(false);
      props.handleLoadUtenti();
      props.setSnack({
        severity: "success",
        messaggio: "Utente eliminato con successo",
      });
      props.setOpenSnack(true);
    } catch {
      props.setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione",
      });
      props.setOpenSnack(true);
    }
  };

  return (
    <>
      {/* <GlobalSnackbar
        severity={props.snack.severity}
        messaggio={props.snack.messaggio}
        openSnack={props.openSnack}
        handleCloseSnack={props.handleCloseSnack}
      /> */}
      <IconButton
        aria-label="edit"
        onClick={() => handleClickOpen()}
        id="editUser"
      >
        <EditIcon style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="md"
        TransitionComponent={Transition}
      >
        <DialogTitle>Modifica Utente</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                //error={validation?.RagioneSociale}
                
                margin="dense"
                name="username"
                label="Username"
                type="text"
                fullWidth
                value={utente?.username}
                onChange={handleChange}
                variant="outlined"
                //helperText={validation?.RagioneSociale?"Campo obbligatorio":""}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                //error={validation?.RagioneSociale}
                
                margin="dense"
                name="email"
                label="Email"
                type="text"
                fullWidth
                value={utente?.email}
                onChange={handleChange}
                variant="outlined"
                //helperText={validation?.RagioneSociale?"Campo obbligatorio":""}
              />
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="subtitle">
                <b> Tipologia Utente:</b>
              </label>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", flexWrap: "wrap" }}
              style={{ paddingTop: "0px" }}
            >
              <FormControlLabel
                sx={{ flexGrow: 1, m: 0 }}
                control={
                  <Checkbox
                    checked={utente?.Sicurezza === 1}
                    onChange={(e) => {
                      let u = { ...utente };
                      u.Sicurezza = e.target.checked ? 1 : 0;
                      setUtente(u);
                    }}
                  />
                }
                label="Sicurezza"
              />
              <FormControlLabel
                sx={{ flexGrow: 1, m: 0 }}
                control={
                  <Checkbox
                    checked={utente?.Medicina === 1}
                    onChange={(e) => {
                      let u = { ...utente };
                      u.Medicina = e.target.checked ? 1 : 0;
                      setUtente(u);
                    }}
                  />
                }
                label="Medicina"
              />
              <FormControlLabel
                sx={{ flexGrow: 1, m: 0 }}
                control={
                  <Checkbox
                    checked={utente?.Formazione === 1}
                    onChange={(e) => {
                      let u = { ...utente };
                      u.Formazione = e.target.checked ? 1 : 0;
                      setUtente(u);
                    }}
                  />
                }
                label="Formazione"
              />
              <FormControlLabel
                sx={{ flexGrow: 1, m: 0 }}
                control={
                  <Checkbox
                    checked={utente?.Admin === 1}
                    onChange={(e) => {
                      let u = { ...utente };
                      u.Admin = e.target.checked ? 1 : 0;
                      setUtente(u);
                    }}
                  />
                }
                label="Amministratore"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between", mx: 2 }}
        >
          <Button
            onClick={handleClose}
            className="ButtonMiller"
            variant="contained"
            startIcon={<Close />}
          >
            Chiudi
          </Button>

          <Button
            onClick={handleSave}
            className="ButtonMiller"
            variant="contained"
            startIcon={<Check />}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DeleteDialog = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare l'utente selezionata?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare il seguente utente:
            <br /><b>{props.categoria.username}</b>?
            
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={() => props.myFunction(props.categoria)}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
