import React, { useEffect, useState, useRef } from "react";
import unsplash from "../api/unsplash";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
  GridFooterContainer,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import {
  IconButton,
  ButtonGroup,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Autocomplete,
  Zoom,
  LinearProgress,
  MenuItem,
} from "@mui/material";
import "../style/style.css";
import {
  Delete,
  Add,
  Close,
  Check,
  ArrowBack,
  ArrowForward,
} from "@mui/icons-material";
import moment from "moment";
import GlobalSnackbar from "./Snackbar";
import MedicalInformationOutlinedIcon from "@mui/icons-material/MedicalInformationOutlined";

import Grow from "@mui/material/Grow";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

let newSede = {
  Descrizione: "",
  Blocco: "",
  Referente: "",
  UnitaProduttiva: "",
  Ubicazione: "",
};
const MediciCliente = (props) => {
  const [mediciCliente, setMediciCliente] = useState([]);
  const [medici, setMedici] = useState([]);
  const [loading, setLoading] = useState(true);
  const [presenzaCoordinatore, setPresenzaCoordinatore] = useState(false);
  const [medicoCoord, setMedicoCoord] = useState(false);

  const anchorRef = useRef(null);
  const [openButton, setOpenButton] = useState(false);

  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const handleToggleButton = () => {
    setOpenButton(!openButton);
  };
  const handleCloseButton = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenButton(false);
  };

  function CustomFooter() {
    return (
      <GridFooterContainer
        sx={{
          display: "flex",
          justifyContent: { xs: "center", md: "flex-start" },
          px: 2,
          flexWrap: { xs: "wrap", md: "nowrap" },
        }}
      >
        {props.screenSize > "768" ? (
          <>
            <GridToolbarColumnsButton
              sx={{ color: "#11526F" }}
              style={{ border: 0 }}
            />
            <GridToolbarFilterButton
              sx={{ color: "#11526F" }}
              style={{ border: 0 }}
            />
            <GridToolbarDensitySelector
              sx={{ color: "#11526F" }}
              style={{ border: 0 }}
            />
            <GridToolbarExport
              sx={{ color: "#11526F" }}
              style={{ border: 0 }}
            />
          </>
        ) : (
          <>
            <ButtonGroup
              aria-label="split button"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                ref={anchorRef}
                startIcon={
                  <MoreVertIcon style={{ height: "28px", width: "28px" }} />
                }
                variant="contained"
                className="ButtonToolbarWrapped"
                onClick={() => {
                  handleToggleButton();
                }}
                size="small"
              >
                <span
                  style={{
                    margin: "0  0 0 -10px",
                    alignSelf: "center",
                    fontSize: "16px",
                    textTransform: "capitalize",
                  }}
                >
                  Opzioni tabella
                </span>
              </Button>
              <Popper
                sx={{
                  zIndex: 100,
                }}
                open={openButton}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow {...TransitionProps}>
                    <Paper sx={{ width: "181px" }}>
                      <ClickAwayListener onClickAway={handleCloseButton}>
                        <MenuList id="split-button-menu" Item sx={{ p: 0 }}>
                          <MenuItem
                            key={1}
                            onClick={handleToggleButton}
                            sx={{ p: 0 }}
                          >
                            <GridToolbarColumnsButton
                              sx={{ color: "#11526F" }}
                              style={{
                                border: 0,
                                width: "100%",
                                padding: "10px 32px 10px 20px ",
                                justifyContent: "flex-start",
                              }}
                            />
                          </MenuItem>
                          <MenuItem
                            key={0}
                            onClick={handleToggleButton}
                            sx={{ p: 0 }}
                          >
                            <GridToolbarFilterButton
                              sx={{ color: "#11526F" }}
                              style={{
                                border: 0,
                                width: "100%",
                                padding: "10px 32px 10px 15px ",

                                justifyContent: "flex-start",
                              }}
                              fullWidth
                            />
                          </MenuItem>

                          <MenuItem key={2} sx={{ p: 0 }}>
                            <GridToolbarDensitySelector
                              sx={{ color: "#11526F" }}
                              style={{
                                border: 0,
                                width: "100%",
                                padding: "10px 32px 10px 18px ",

                                justifyContent: "flex-start",
                              }}
                            />
                          </MenuItem>
                          <MenuItem key={3} sx={{ p: 0 }}>
                            <GridToolbarExport
                              sx={{ color: "#11526F" }}
                              style={{
                                border: 0,
                                width: "100%",
                                padding: "10px 32px 10px 18px ",

                                justifyContent: "flex-start",
                              }}
                            />
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </ButtonGroup>
          </>
        )}
        {/*  */}
      </GridFooterContainer>
    );
  }

  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      width: "90",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <DeleteDialog id={params.row.ID} myFunction={deleteMedico} />
          {presenzaCoordinatore && params.row.MedicoCoordinatore === 1 ? (
            <DeassociateDialog
              medicoSelezionato={params.row}
              idAzienda={props.idAzienda}
              handleLoad={handleLoad}
              snack={snack}
              openSnack={openSnack}
              handleCloseSnack={handleCloseSnack}
              setSnack={setSnack}
              setOpenSnack={setOpenSnack}
            />
          ) : presenzaCoordinatore && params.row.MedicoCoordinatore === 0 ? (
            <></>
          ) : (
            <AssociateDialog
              medicoSelezionato={params.row}
              idAzienda={props.idAzienda}
              handleLoad={handleLoad}
              snack={snack}
              openSnack={openSnack}
              handleCloseSnack={handleCloseSnack}
              setSnack={setSnack}
              setOpenSnack={setOpenSnack}
            />
          )}
        </>
      ),
    },
    {
      field: "Cognome",
      headerName: "Cognome",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Nome",
      headerName: "Nome",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "CF",
      headerName: "Codice Fiscale",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "DataDiNascita",
      headerName: "Data di Nascita",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => {
        return (
          <div>
            {params.row.DataDiNascita
              ? moment(params.row.DataDiNascita).format("DD - MM - YYYY")
              : ""}
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
  ];

  const handleLoad = async () => {
    let responseMediciCliente = await unsplash.get(
      "medici",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { id: props.idAzienda },
      } //props.idAzienda}}
    );

    setMediciCliente(responseMediciCliente.data.data);
    const medicoCoord = responseMediciCliente.data.data.filter(
      (el) => el.MedicoCoordinatore === 1
    );
    if (medicoCoord.length > 0) {
      setMedicoCoord(medicoCoord);
      setPresenzaCoordinatore(true);
    } else {
      setPresenzaCoordinatore(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      let responseMediciCliente = await unsplash.get(
        "medici",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { id: props.idAzienda },
        } //props.idAzienda}}
      );

      setMediciCliente(responseMediciCliente.data.data);
      setLoading(false);
      const medicoCoord = responseMediciCliente.data.data.filter(
        (el) => el.MedicoCoordinatore === 1
      );
      if (medicoCoord.length > 0) {
        setMedicoCoord(medicoCoord);
        setPresenzaCoordinatore(true);
      }

      let responseMedici = await unsplash.get(
        "medici",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        } //props.idAzienda}}
      );

      setMedici(responseMedici.data.data?.filter((med) => med.Attivo === 1));
    }

    fetchData();
  }, []);

  const handleSaveData = async (medico, operazione) => {
    try {
      let responseModifica = await unsplash.post(
        "medici",
        { cliente: props.idAzienda, medico: medico.ID },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      let responseMediciCliente = await unsplash.get(
        "medici",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { id: props.idAzienda },
        } //props.idAzienda}}
      );

      setMediciCliente(responseMediciCliente.data.data);

      setSnack({
        severity: "success",
        messaggio: "Medico inserito con successo",
      });
      setOpenSnack(true);
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'inserimento",
      });
      setOpenSnack(true);
    }
  };

  const deleteMedico = async (id) => {
    try {
      await unsplash.delete("medici", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          medico: id,
          cliente: props.idAzienda,
        },
      });
      setSnack({
        severity: "success",
        messaggio: "Medico eliminato con successo",
      });
      setOpenSnack(true);
      let responseMediciCliente = await unsplash.get(
        "medici",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { id: props.idAzienda },
        } //props.idAzienda}}
      );

      setMediciCliente(responseMediciCliente.data.data);

      let responseMedici = await unsplash.get(
        "medici",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        } //props.idAzienda}}
      );

      setMedici(responseMedici.data.data);
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione",
      });
      setOpenSnack(true);
    }
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />

      <Grid
        container
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="flex-end"
      >
        {/* <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
        > */}
        <FormDialog
          id="add"
          operazione="Inserimento"
          mediciCliente={mediciCliente}
          medici={medici.filter(
            (m) => !mediciCliente.some((medico) => medico.ID === m.ID)
          )}
          sede={newSede}
          saveFunction={handleSaveData}
        />
        <Button
          startIcon={<Add />}
          onClick={() => document.getElementById("add").click()}
          sx={{ color: "white" }}
          variant="outlined"
          className="ButtonMiller"
        >
          AGGIUNGI
        </Button>
        {/* </ButtonGroup> */}
      </Grid>
      <div style={{ height: "60vh", width: "100%" }}>
        <DataGridPro
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooterPersonalized,
          }}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={loading}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rows={mediciCliente}
          getRowId={(mediciCliente) => mediciCliente.ID}
          columns={columns}
          disableSelectionOnClick
          initialState={{
            sorting: {
              sortModel: [{ field: "Cognome", sort: "ASC" }],
              pinnedColumns: { left: ["Actions"] },
            },
          }}
        />
      </div>
      {props?.proceduraGuidata ? (
        <>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
              px: 1,
            }}
            fullWidth
          >
            <div></div>
            <Button
              variant="contained"
              className="ButtonMiller"
              endIcon={<ArrowForward />}
              onClick={() => props.handleComplete()}
            >
              Completa Passaggio
            </Button>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default MediciCliente;

const FormDialog = (props) => {
  const [open, setOpen] = useState(false);
  const [medicoSel, setMedicoSel] = useState("");
  const [medico, setMedico] = useState([]);

  const handleClickOpen = () => {
    setMedicoSel("");
    let medici = props.medici;
    const mediciSenzaAnomalie = medici.filter((elm) => elm.ID !== -1);
    setMedico(mediciSenzaAnomalie);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    props.saveFunction(medicoSel, props.operazione);
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        id={props.id}
        style={{ display: "None" }}
      >
        Open form dialog
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth="sm"
        TransitionComponent={Transition}
      >
        <DialogTitle>Associa</DialogTitle>
        <DialogContent>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                id="combo-box-demo"
                options={medico}
                getOptionLabel={(option) => option.Nome + " " + option.Cognome}
                style={{ marginTop: "5px" }}
                fullWidth
                onChange={(event, newValue) => {
                  setMedicoSel(newValue);
                  //handleChangeCliente(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Medico" />
                )}
                sx={{ mt: 1 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 1,
            px: 3,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={handleSave}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const DeleteDialog = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
      >
        <DialogTitle id="alert-dialog-title">
          Rimozione relazione medico
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler rimuovere il medico selezionato ?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 2,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={() => props.myFunction(props.id)}>Conferma</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const AssociateDialog = ({
  medicoSelezionato,
  idAzienda,
  handleLoad,
  snack,
  openSnack,
  handleCloseSnack,
  setSnack,
  setOpenSnack,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAssocia = async () => {
    try {
      let responseModifica = await unsplash.put(
        "medici/associaMedicoCoordinatore",
        {
          MedicoCoordinatore: 1,
          ID_cliente: idAzienda,
          ID_medico: medicoSelezionato.ID,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      handleLoad();
      setSnack({
        severity: "success",
        messaggio: "Medico associato con successo",
      });
      setOpenSnack(true);
      setOpen(false);
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durrante l'associazione del medico",
      });
      setOpenSnack(true);
      setOpen(false);
    }
  };

  return (
    <div>
      <>
        <IconButton aria-label="edit" onClick={handleClickOpen}>
          <MedicalInformationOutlinedIcon
            style={{ height: "2rem", width: "2rem", color: "#11526F" }}
          />
        </IconButton>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          TransitionComponent={Transition}
        >
          <DialogTitle id="alert-dialog-title">
            Associazione Medico Coordinatore{" "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Attenzione, sei sicuro di voler impostare il medico:{" "}
              <b>{medicoSelezionato?.Descrizione}</b> come il medico
              coordinatore ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Annulla</Button>
            <Button onClick={handleAssocia}>Conferma</Button>
          </DialogActions>
        </Dialog>
      </>
    </div>
  );
};

const DeassociateDialog = ({
  medicoSelezionato,
  idAzienda,
  handleLoad,
  snack,
  openSnack,
  handleCloseSnack,
  setSnack,
  setOpenSnack,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAssocia = async () => {
    try {
      let responseModifica = await unsplash.put(
        "medici/associaMedicoCoordinatore",
        {
          MedicoCoordinatore: 0,
          ID_cliente: idAzienda,
          ID_medico: medicoSelezionato.ID,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      handleLoad();
      setSnack({
        severity: "success",
        messaggio: "Medico disassociato con successo",
      });
      setOpenSnack(true);
      setOpen(false);
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante la disassociazione del medico",
      });
      setOpenSnack(true);
      setOpen(false);
    }
  };

  return (
    <div>
      <>
        <IconButton aria-label="edit" onClick={handleClickOpen}>
          <MedicalInformationOutlinedIcon
            style={{ height: "2rem", width: "2rem", color: "#11526F" }}
          />
        </IconButton>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          TransitionComponent={Transition}
        >
          <DialogTitle id="alert-dialog-title">
            Disassociazione Medico Coordinatore{" "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Attenzione, sei sicuro di voler dissociare il medico coordinatore:{" "}
              <b>{medicoSelezionato?.Descrizione}</b> ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Annulla</Button>
            <Button onClick={handleAssocia}>Conferma</Button>
          </DialogActions>
        </Dialog>
      </>
    </div>
  );
};
