import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  LinearProgress,
  FormHelperText,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Box,
  Tab,
  Tabs,
  Button,
  Typography,
  Grid,
  TextField,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormControl,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Paper,
  ButtonGroup,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import PropTypes from "prop-types";
import unsplash from "../api/unsplash";
import { LocalizationProvider,DatePicker,PickersDay,TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment as DateAdapter} from '@mui/x-date-pickers/AdapterMoment'
import moment from "moment";
import momentTimezone from "moment-timezone";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import GlobalSnackbar from "./Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import { Delete, Upload } from "@mui/icons-material";

const PrefatturazioneMedicina = () => {
  const [value, setValue] = useState(0);
  const [clienti, setClienti] = useState([]);
  const [clientiBackup, setClientiBackup] = useState([]);
  const [periodo, setPeriodo] = useState([]);
  const [periodoSel, setPeriodoSel] = useState(null);
  const [clienteSel, setClienteSel] = useState(null);
  const [accertamentiDaFatturare, setAccertamentiDaFatturare] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSop, setLoadingSop] = useState(false);

  const [openEliminaPref, setOpenEliminaPref] = useState(false);
  const [idPref, setIdPref] = useState(null);

  const [year, setYear] = useState([new Date().getFullYear()]);
  const [validation, setValidation] = useState({
    Cliente: false,
    Periodo: false,
    Anno: false,
  });
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      field: "Actions",
      headerName: "Azioni",
      filterable: false,
      renderCell: (params) => (
        <div>
          <Tooltip title="Elimina">
            <IconButton
              onClick={() => {
                setOpenEliminaPref(true);
                setIdPref(params.id);
              }}
              aria-label="edit"
            >
              <Delete
                style={{ height: "2rem", width: "2rem", color: "#FF2768" }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Rimuovi">
            <IconButton
              onClick={() => removeFromCurrent(params.id)}
              aria-label="edit"
            >
              <CloseIcon style={{ color: "#FF2768" }} />
            </IconButton>
          </Tooltip>
          {/* <Button
            sx={{ color: "#FF2768" }}
            onClick={() => removeFromCurrent(params.id)}
          >
            Rimuovi
          </Button> */}
        </div>
      ),
    },
    {
      field: "DATA",
      headerName: "Data",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <div>
          {params.row.DATA ? moment(params.row.DATA).format("DD/MM/YYYY") : ""}
        </div>
      ),
    },
    {
      field: "Medico",
      headerName: "Medico",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "NomeCompleto",
      headerName: "Lavoratore",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Accertamento",
      headerName: "Accertamento",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Importo",
      headerName: "Importo",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <>{params.formattedValue + " €"}</>, //formatted value è il valore corrente
    },
  ];

  const columnsSopralluoghi = [
    {
      field: "Actions",
      headerName: "Azioni",
      filterable: false,
      renderCell: (params) => (
        <div>
          <Button
            sx={{ color: "#FF2768" }}
            onClick={() => removeFromCurrentSopralluoghi(params.id)}
          >
            Rimuovi
          </Button>
        </div>
      ),
    },
    {
      field: "Data",
      headerName: "Data",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <div>
          {params.row.Data ? moment(params.row.Data).format("DD/MM/YYYY") : ""}
        </div>
      ),
    },
    {
      field: "Indirizzo",
      headerName: "Indirizzo",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Importo",
      headerName: "Importo",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>{params.formattedValue ? params.formattedValue + " €" : 0 + "€"}</>
      ),
    },
    {
      field: "Rimborso",
      headerName: "Rimborso",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>{params.formattedValue ? params.formattedValue + " €" : 0 + "€"}</>
      ),
    },
  ];

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      try {
        let response = await unsplash.get("clienti", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        setClienti(
          response.data.data.filter((cli) => cli.FatturaVisiteMediche === 1)
        );
        setClientiBackup(response.data.data);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    setAccertamentiDaFatturare([]);
    switch (newValue) {
      case 0:
        setClienti(
          clientiBackup.filter((cli) => cli.FatturaVisiteMediche === 1)
        );
        break;

      case 2:
        setClienti(
          clientiBackup.filter((cli) => cli.GestioneSopralluoghi === 1)
        );
        break;
    }
  };

  const loadPeriodo = (cadenza) => {
    setPeriodoSel(null);
    setClienteSel(null);
    switch (cadenza) {
      case 1:
        setPeriodo([
          { Mese: "Gennaio", value: 1 },
          { Mese: "Febbraio", value: 2 },
          { Mese: "Marzo", value: 3 },
          { Mese: "Aprile", value: 4 },
          { Mese: "Maggio", value: 5 },
          { Mese: "Giugno", value: 6 },
          { Mese: "Luglio", value: 7 },
          { Mese: "Agosto", value: 8 },
          { Mese: "Settembre", value: 9 },
          { Mese: "Ottobre", value: 10 },
          { Mese: "Novembre", value: 11 },
          { Mese: "Dicembre", value: 12 },
        ]);
        break;
      case 2:
        setPeriodo([
          { Mese: "Gennaio - Febbraio", value: 2 },
          { Mese: "Marzo - Aprile", value: 4 },
          { Mese: "Maggio - Giugno", value: 6 },
          { Mese: "Luglio - Agosto", value: 8 },
          { Mese: "Settembre - Ottobre", value: 10 },
          { Mese: "Novembre - Dicembre", value: 12 },
        ]);
        break;
      case 3:
        setPeriodo([
          { Mese: "Gennaio - Febbraio - Marzo", value: 3 },
          { Mese: "Aprile - Maggio - Giugno", value: 6 },
          { Mese: "Luglio - Agosto - Settembre", value: 9 },
          { Mese: "Ottobre - Novembre - Dicembre", value: 12 },
        ]);
        break;
    }
  };

  const loadFatturazione = async (tipo) => {
    if (tipo === "attiva") {
      setLoading(true);
    } else {
      setLoadingSop(true);
    }

    let v = { ...validation };
    let check = false;
    if (clienteSel === null) {
      v.Cliente = true;
      check = true;
    } else {
      v.Cliente = false;
    }

    if (periodoSel === "" || periodoSel === null) {
      v.Periodo = true;
      check = true;
    } else {
      v.Periodo = false;
    }

    if (year === "" || year === null) {
      v.Anno = true;
      check = true;
    } else {
      v.Anno = false;
    }

    setValidation(v);
    if (check) {
      return;
    }

    let lastDay = moment(new Date(year, periodoSel, 0)).format("YYYY-MM-DD");

    let response = await unsplash.get("stampaFatturazioneMedicina", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { cliente: clienteSel.ID, data: lastDay, tipo: tipo },
    });
    setAccertamentiDaFatturare(response.data.data);
    if (tipo === "attiva") {
      setLoading(false);
    } else {
      setLoadingSop(false);
    }
  };

  const loadFatturazioneSopralluoghi = async () => {
    let v = { ...validation };
    let check = false;
    if (clienteSel === null) {
      v.Cliente = true;
      check = true;
    } else {
      v.Cliente = false;
    }

    if (periodoSel === "" || periodoSel === null) {
      v.Periodo = true;
      check = true;
    } else {
      v.Periodo = false;
    }

    if (year === "" || year === null) {
      v.Anno = true;
      check = true;
    } else {
      v.Anno = false;
    }

    setValidation(v);
    if (check) {
      return;
    }

    let lastDay = moment(new Date(year, periodoSel, 0)).format("YYYY-MM-DD");

    let response = await unsplash.get("stampaFatturazioneMedicina", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { cliente: clienteSel.ID, data: lastDay, tipo: "sopralluoghi" },
    });
    setAccertamentiDaFatturare(response.data.data);
  };

  const removeFromCurrentSopralluoghi = (id) => {
    let acc = [...accertamentiDaFatturare];

    acc = acc.filter((a) => a.ID !== id);
    setAccertamentiDaFatturare(acc);
  };
  const removeFromCurrent = (id) => {
    let acc = [...accertamentiDaFatturare];

    acc = acc.filter((a) => a.idVisAccertamento !== id);
    setAccertamentiDaFatturare(acc);
  };

  const deletePrefatturazione = async (idPref) => {
    try {
      await unsplash.delete("stampaFatturazioneMedicina", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: { id: idPref },
      });
      setSnack({
        severity: "success",
        messaggio: "Eliminazione conclusa con successo",
      });
      setOpenEliminaPref(false);
      setOpenSnack(true);
      loadFatturazione("attiva");
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione",
      });
      setOpenSnack(true);
    }
  };
  const stampaFattura = async (sede) => {
    let p = periodo.filter((p) => p.value === periodoSel)[0];

    let response = await unsplash.get("storicoFatturazioneMedicina", {
      params: {
        cliente: clienteSel.ID,
        anno: year,
        periodo: p.Mese,
        tipologia: "check",
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    if (response.data.data[0].TOT === 1) {
      //check se esiste fatturazione
      setSnack({
        severity: "error",
        messaggio:
          "ATTENZIONE, per il periodo : " +
          p.Mese +
          " - " +
          year +
          " è già stata effettuata la prefatturazione. Annullare prima quella già esistente",
      });
      setOpenSnack(true);
      return;
    } else {
      if (window.confirm("ATTENZIONE VUOI STAMPARE LA PREFATTURAZIONE?")) {
        try {
          let acc = accertamentiDaFatturare.map((acc) => acc.idVisAccertamento);
          //prendo periodo di fatturazione
          const responseFatturazione = await unsplash.post(
            "storicoFatturazioneMedicina",
            { cliente: clienteSel.ID, periodo: p.Mese, anno: year },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );

          const responseClientiMedici = await unsplash.put(
            "stampaFatturazioneMedicina",
            {
              accertamentiDaFatturare: acc.join(),
              periodo: p.Mese,
              anno: year,
              tipo: "attiva",
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );

          exportPDF(sede);
        } catch {
          setSnack({
            severity: "error",
            messaggio: "Errore durante il salvataggio",
          });
          setOpenSnack(true);
        }
      }
    }
  };

  const stampaFatturaSopralluoghi = async (sede) => {
    let p = periodo.filter((p) => p.value === periodoSel)[0];

    let response = await unsplash.get("storicoFatturazioneSopralluoghi", {
      params: {
        cliente: clienteSel.ID,
        anno: year,
        periodo: p.Mese,
        tipologia: "check",
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    if (response.data.data[0].TOT === 1) {
      //check se esiste fatturazione
      setSnack({
        severity: "error",
        messaggio:
          "ATTENZIONE, per il periodo : " +
          p.Mese +
          " - " +
          year +
          " è già stata effettuata la prefatturazione. Annullare prima quella già esistente",
      });
      setOpenSnack(true);
      return;
    } else {
      if (window.confirm("ATTENZIONE VUOI STAMPARE LA PREFATTURAZIONE?")) {
        try {
          let acc = accertamentiDaFatturare.map((acc) => acc.ID);
          //prendo periodo di fatturazione
          const responseFatturazione = await unsplash.post(
            "storicoFatturazioneSopralluoghi",
            { cliente: clienteSel.ID, periodo: p.Mese, anno: year },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );

          const responseClientiMedici = await unsplash.put(
            "stampaFatturazioneMedicina",
            {
              sopralluoghiDaFatturare: acc,
              periodo: p.Mese,
              anno: year,
              tipo: "sopralluoghi",
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );

          exportPDFSopralluoghi(sede);
        } catch {
          setSnack({
            severity: "error",
            messaggio: "Errore durante il salvataggio",
          });
          setOpenSnack(true);
        }
      }
    }
  };

  const exportPDF = (sede) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    var img = new Image();
    img.src = require("../assets/images/diagnofisic.png");

    doc.setFontSize(20);

    const title = "Prefatturazione Attiva";
    const headers = sede
      ? ["Data", "Medico", "Lavoratore", "Accertamento", "Sede", "Importo"]
      : ["Data", "Medico", "Lavoratore", "Accertamento", "Importo"];

    let data = [];

    if (sede) {
      data = accertamentiDaFatturare.map((el) => [
        moment(el.DATA).format("DD/MM/YYYY"),
        el.Medico,
        el.NomeCompleto,
        el.Accertamento,
        el.SedeDesc,
        el.Importo ? el.Importo : "0.00",
      ]);
    } else {
      data = accertamentiDaFatturare.map((el) => [
        moment(el.DATA).format("DD/MM/YYYY"),
        el.Medico,
        el.NomeCompleto,
        el.Accertamento,
        el.Importo ? el.Importo : "0.00",
      ]);
    }
    let totale = 0;

    accertamentiDaFatturare.forEach((el) => {
      if (el.Importo) {
        totale += Number(el.Importo);
      }
    });

    let cad = ["Mensile", "Bimestrale", "Trimestrale"];

    let content = {
      startY: 180,
      startX: 0,
      tableWidth: "auto",
      columnWidth: "auto",
      headStyles: { fillColor: [34, 100, 174] },
      footStyles: { fillColor: [34, 100, 174] },
      margin: { left: 0, right: 0 },
      foot: [["", "", "", "Totale", totale.toFixed(2)]],
      showFoot: "lastPage",
    };

    let firstDay = moment(
      new Date(year, periodoSel - clienteSel.Cadenza, 1)
    ).format("DD/MM/YYYY");
    let lastDay = moment(new Date(year, periodoSel, 0)).format("DD/MM/YYYY");

    doc.setFillColor(34, 100, 174);
    doc.rect(0, 0, doc.internal.pageSize.getWidth(), 120, "F"); //x,y,largh,altezza
    doc.addImage(img, "PNG", 10, 10, 220, 60);
    img.src = require("../assets/images/diagnoIndirizzo.png");
    doc.addImage(
      img,
      "PNG",
      doc.internal.pageSize.getWidth() - 190,
      10,
      180,
      100
    );
    doc.setTextColor(255, 255, 255);
    doc.text(title, 10, 90);
    doc.setFontSize(11);
    doc.text(moment(new Date()).format("DD/MM/YYYY"), 10, 110);
    doc.setFontSize(12);
    doc.setTextColor(40);
    doc.text("Cliente ", 10, 140);
    doc.setFont(undefined, "bold");
    doc.text(clienteSel.RagioneSociale, 55, 140);
    doc.setFont(undefined, "normal");
    doc.text("Decorrenza ", doc.internal.pageSize.getWidth() - 200, 140);
    doc.setFont(undefined, "bold");
    doc.text(
      clienteSel.Decorrenza
        ? moment(clienteSel.Decorrenza).format("DD/MM/YYYY")
        : "",
      doc.internal.pageSize.getWidth() - 130,
      140
    );
    doc.setFont(undefined, "normal");
    doc.text("Cadenza ", 10, 170);
    doc.setFont(undefined, "bold");
    doc.text(cad[clienteSel.Cadenza - 1], 70, 170);
    doc.setFont(undefined, "normal");
    doc.text("Dal ", 360, 170);
    doc.setFont(undefined, "bold");
    doc.text(firstDay, 380, 170);
    doc.setFont(undefined, "normal");
    doc.text("Al ", 450, 170);
    doc.setFont(undefined, "bold");
    doc.text(lastDay, 470, 170);

    //image,formato,x,y,larghezza,altezza
    doc.autoTable(headers, data, content);
    doc.output("pdfobjectnewwindow", "prefatturazione");
  };

  const exportPDFSopralluoghi = (sede) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    var img = new Image();
    img.src = require("../assets/images/diagnofisic.png");

    doc.setFontSize(20);

    const title = "Prefatturazione Attiva";
    const headers = ["Sede", "Data", "Rimborso", "Importo", "Totale"];

    let data = [];

    data = accertamentiDaFatturare.map((el) => [
      el.Indirizzo,
      moment(el.Data).format("DD/MM/YYYY"),
      el.Rimborso ? el.Rimborso : "0.00",
      el.Importo ? el.Importo : "0.00",
      el.Importo
        ? el.Importo + (el.Rimborso ? el.Rimborso : 0)
        : 0 + (el.Rimborso ? el.Rimborso : 0),
    ]);

    let totale = 0;

    accertamentiDaFatturare.forEach((el) => {
      if (el.Importo) {
        totale += Number(el.Importo) + el.Rimborso;
      }
    });
    let cad = ["Mensile", "Bimestrale", "Trimestrale"];

    let content = {
      startY: 180,
      startX: 0,
      tableWidth: "auto",
      columnWidth: "auto",
      headStyles: { fillColor: [34, 100, 174] },
      footStyles: { fillColor: [34, 100, 174] },
      margin: { left: 0, right: 0 },
      foot: [["", "", "", "Totale", totale.toFixed(2)]],
      showFoot: "lastPage",
    };

    let firstDay = moment(
      new Date(year, periodoSel - clienteSel.Cadenza, 1)
    ).format("DD/MM/YYYY");
    let lastDay = moment(new Date(year, periodoSel, 0)).format("DD/MM/YYYY");

    doc.setFillColor(34, 100, 174);
    doc.rect(0, 0, doc.internal.pageSize.getWidth(), 120, "F"); //x,y,largh,altezza
    doc.addImage(img, "PNG", 10, 10, 220, 60);
    img.src = require("../assets/images/diagnoIndirizzo.png");
    doc.addImage(
      img,
      "PNG",
      doc.internal.pageSize.getWidth() - 190,
      10,
      180,
      100
    );
    doc.setTextColor(255, 255, 255);
    doc.text(title, 10, 90);
    doc.setFontSize(11);
    doc.text(moment(new Date()).format("DD/MM/YYYY"), 10, 110);
    doc.setFontSize(12);
    doc.setTextColor(40);
    doc.text("Cliente ", 10, 140);
    doc.setFont(undefined, "bold");
    doc.text(clienteSel.RagioneSociale, 55, 140);
    doc.setFont(undefined, "normal");
    doc.text("Decorrenza ", doc.internal.pageSize.getWidth() - 200, 140);
    doc.setFont(undefined, "bold");
    doc.text(
      clienteSel.Decorrenza
        ? moment(clienteSel.Decorrenza).format("DD/MM/YYYY")
        : "",
      doc.internal.pageSize.getWidth() - 130,
      140
    );
    doc.setFont(undefined, "normal");
    doc.text("Cadenza ", 10, 170);
    doc.setFont(undefined, "bold");
    doc.text(cad[clienteSel.Cadenza - 1], 70, 170);
    doc.setFont(undefined, "normal");
    doc.text("Dal ", 360, 170);
    doc.setFont(undefined, "bold");
    doc.text(firstDay, 380, 170);
    doc.setFont(undefined, "normal");
    doc.text("Al ", 450, 170);
    doc.setFont(undefined, "bold");
    doc.text(lastDay, 470, 170);

    //image,formato,x,y,larghezza,altezza
    doc.autoTable(headers, data, content);
    doc.output("pdfobjectnewwindow", "prefatturazione");
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid item xs={12}>
        <Card>
          <CardContent sx={{ p: { xs: 0 } }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                textColor="inherit"
                variant="scrollable"
                onChange={handleChangeTab}
                aria-label="basic tabs example"
              >
                <Tab label="Attiva" {...a11yProps(0)} />
                <Tab label="Passiva" {...a11yProps(1)} />
                <Tab label="Sopralluoghi" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {/*Attiva*/}
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={clienti}
                    fullWidth
                    getOptionLabel={(option) => option.RagioneSociale}
                    sx={{ marginBottom: "10px" }}
                    onChange={(event, newValue) => {
                      setAccertamentiDaFatturare([]);
                      if (newValue) {
                        //loadFatturazione(newValue.ID,newValue.Cadenza);
                        loadPeriodo(newValue.Cadenza);
                        setClienteSel(newValue);
                      } else {
                        setClienteSel(null);
                      }

                      //handleChangeCliente(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Cliente"
                        error={validation.Cliente}
                        helperText={
                          validation.Cliente ? "cliente obbligatorio" : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4} md={3}>
                  <FormControl fullWidth error={validation.Periodo}>
                    <InputLabel id="Cadenza">Periodo</InputLabel>
                    <Select
                      label="Cadenza"
                      labelId="Cadenza"
                      id="demo-simple-select"
                      value={periodoSel}
                      onChange={(e) => {
                        setPeriodoSel(e.target.value);
                        setAccertamentiDaFatturare([]);
                      }}
                    >
                      {periodo
                        ? periodo.map((p) => (
                            <MenuItem value={p.value}>{p.Mese}</MenuItem>
                          ))
                        : ""}
                    </Select>
                    <FormHelperText
                      style={{ display: validation.Periodo ? "" : "None" }}
                    >
                      Periodo obbligatorio
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4} md={2}>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                      views={["year"]}
                      ampmInClock={false}
                      renderInput={(props) => (
                        <TextField fullWidth {...props} />
                      )}
                      label="Anno"
                      value={moment(year)}
                      onChange={(newValue) => {
                        setYear(newValue ? newValue.format("YYYY") : null);
                        setAccertamentiDaFatturare([]);
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={1}>
                  <Button
                    variant="contained"
                    className="ButtonMiller"
                    sx={{ marginTop: "8px" }}
                    onClick={() => loadFatturazione("attiva")}
                    startIcon={<Upload />}
                  >
                    Carica
                  </Button>
                </Grid>
              </Grid>
              <Dialog
                open={openEliminaPref}
                onClose={() => setOpenEliminaPref(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
                  Sei sicuro di voler eliminare definitivamente la
                  prefatturazione selezionata?
                </DialogTitle>
                {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare la seguente prefatturazione :
            <br />
            {props.adempimento.Descrizione}
          </DialogContentText>
        </DialogContent> */}
                <DialogActions
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    px: 3,
                  }}
                >
                  <Button onClick={() => setOpenEliminaPref(false)}>
                    annulla
                  </Button>
                  <Button
                    onClick={() => deletePrefatturazione(idPref)}
                    
                  >
                    Conferma
                  </Button>
                </DialogActions>
              </Dialog>
              <Box
                sx={{
                  height: { xs: "65vh", md: "50vh", xl: "65vh" },
                  width: "100%",
                }}
              >
                <DataGridPro
                  initialState={{ pinnedColumns: { left: ["Actions"] } }}
                  components={{
                    Toolbar: CustomToolbar,
                    Footer: CustomFooterPersonalized,
                  }}
                  slots={{
                    loadingOverlay: LinearProgress,
                  }}
                  loading={loading}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  rows={accertamentiDaFatturare}
                  getRowId={(accertamentiDaFatturare) =>
                    accertamentiDaFatturare.idVisAccertamento
                  }
                  columns={columns}
                />
              </Box>
              <ButtonGroup>
                {" "}
                <Button
                  onClick={() => stampaFattura(false)}
                  sx={{
                    marginTop: "10px",
                    display: accertamentiDaFatturare.length > 0 ? "" : "None",
                  }}
                  className="ButtonMiller"
                  variant="contained"
                >
                  STAMPA
                </Button>
                <Button
                  onClick={() => stampaFattura(true)}
                  sx={{
                    marginTop: "10px",
                    display: accertamentiDaFatturare.length > 0 ? "" : "None",
                  }}
                  className="ButtonMiller"
                  variant="contained"
                >
                  STAMPA CON SEDE
                </Button>
              </ButtonGroup>
            </TabPanel>
            <TabPanel value={value} index={1}>
              {/*Passiva*/}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {/*Attiva*/}
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={clienti}
                    fullWidth
                    getOptionLabel={(option) => option.RagioneSociale}
                    sx={{ marginBottom: "10px" }}
                    onChange={(event, newValue) => {
                      setAccertamentiDaFatturare([]);
                      if (newValue) {
                        //loadFatturazione(newValue.ID,newValue.Cadenza);
                        loadPeriodo(newValue.Cadenza);
                        setClienteSel(newValue);
                      } else {
                        setClienteSel(null);
                      }

                      //handleChangeCliente(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Cliente"
                        error={validation.Cliente}
                        helperText={
                          validation.Cliente ? "cliente obbligatorio" : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4} md={3}>
                  <FormControl fullWidth error={validation.Periodo}>
                    <InputLabel id="Cadenza">Periodo</InputLabel>
                    <Select
                      label="Cadenza"
                      labelId="Cadenza"
                      id="demo-simple-select"
                      value={periodoSel}
                      onChange={(e) => {
                        setPeriodoSel(e.target.value);
                        setAccertamentiDaFatturare([]);
                      }}
                    >
                      {periodo
                        ? periodo.map((p) => (
                            <MenuItem value={p.value}>{p.Mese}</MenuItem>
                          ))
                        : ""}
                    </Select>
                    <FormHelperText
                      style={{ display: validation.Periodo ? "" : "None" }}
                    >
                      Periodo obbligatorio
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4} md={2}>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                      views={["year"]}
                      ampmInClock={false}
                      renderInput={(props) => (
                        <TextField fullWidth {...props} />
                      )}
                      label="Anno"
                      value={moment(year)}
                      onChange={(newValue) => {
                        setYear(newValue ? newValue.format("YYYY") : null);
                        setAccertamentiDaFatturare([]);
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={1}>
                  <Button
                    variant="contained"
                    className="ButtonMiller"
                    sx={{ marginTop: "8px" }}
                    onClick={() => loadFatturazione("sopralluoghi")}
                    startIcon={<Upload />}
                  >
                    Carica
                  </Button>
                </Grid>
              </Grid>
              <Box
                sx={{
                  height: { xs: "65vh", md: "50vh", xl: "65vh" },
                  width: "100%",
                }}
              >
                <DataGridPro
                  initialState={{ pinnedColumns: { left: ["Actions"] } }}
                  components={{
                    Toolbar: CustomToolbar,
                    Footer: CustomFooterPersonalized,
                  }}
                  slots={{
                    loadingOverlay: LinearProgress,
                  }}
                  loading={loadingSop}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  rows={accertamentiDaFatturare}
                  getRowId={(accertamentiDaFatturare) =>
                    accertamentiDaFatturare.ID
                  }
                  columns={columnsSopralluoghi}
                />
              </Box>
              <ButtonGroup>
                {" "}
                <Button
                  onClick={() => stampaFatturaSopralluoghi()}
                  sx={{
                    marginTop: "10px",
                    display: accertamentiDaFatturare.length > 0 ? "" : "None",
                  }}
                  className="ButtonMiller"
                  variant="contained"
                >
                  STAMPA
                </Button>
              </ButtonGroup>
            </TabPanel>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default PrefatturazioneMedicina;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/*

<Grid item xs={12}>

              <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table" id="tabellaIdoneita">
                <TableHead sx={{backgroundColor:"#2265B1"}}>
                  <TableRow>
                  <TableCell sx={{color:"white"}}>*</TableCell>
                    <TableCell sx={{color:"white"}}>Data</TableCell>
                    <TableCell align="right" sx={{color:"white"}}>Medico</TableCell>
                    <TableCell align="right" sx={{color:"white"}}>Lavoratore</TableCell>
                    <TableCell align="right" sx={{color:"white"}}>Accertamento</TableCell>
                    <TableCell align="right" sx={{color:"white"}}>Importo</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {accertamentiDaFatturare.map((row,index) => (
                        <TableRow
                          key={row.idVisAccertamento}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell><Button sx={{color:"#FF2768"}} onClick={() => removeFromCurrent(index)}>Rimuovi</Button></TableCell>
                          <TableCell component="th" scope="row">
                            {moment(row.DATA).format("DD/MM/YYYY")}
                          </TableCell>
                          
                          <TableCell align="right">{row.Medico}</TableCell>
                          <TableCell align="right">{row.NomeCompleto}</TableCell>
                          <TableCell align="right">{row.Accertamento}</TableCell>
                          <TableCell align="right">{row.Importo}</TableCell>
                          
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  </TableContainer>
                      </Grid>




*/
