import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Avatar,
  Grid,
  Paper,
  Skeleton,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import koala from "../assets/images/logo_Koala_min.png";
import unsplash from "../api/unsplash";

const CollegaVirtuale = () => {
  const [input, setInput] = useState("");
  const [newQ, setNewQ] = useState(false);
  const bottomRef = useRef(null);
  const [messages, setMessages] = useState([
    {
      id: 1,
      text: "Ciao, sono KoAI, il tuo collega virtuale. Come posso aiutarti oggi? Chiedimi qualsiasi cosa!",
      sender: "bot",
    },
  ]);

  const handleSend = () => {
    if (input.trim() !== "") {
      setInput("");
    }
    let m = [...messages];
    m.push({ sender: "user", text: input });
    setMessages(m);
    setNewQ(true);
    askKoAI(m, input);
  };

  const askKoAI = async (m, input) => {
    const response = await unsplash.post(
      "openAi",
      {
        message: input,
        username: sessionStorage.getItem("username"),
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );

    m.push({ sender: "bot", text: response.data.choices[0]?.message.content });
    setMessages(m);
    setNewQ(false);
bottomRef.current?.scrollIntoView({behavior: 'smooth'});
  };

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({behavior: 'smooth'});
  }, [messages]);

  return (
    <Box
      sx={{
        height: { xs: "82vh", md: "72vh", xl: "82vh" },
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ flexGrow: 1, p: 2, overflow: "auto" }} id="messages-container">
        {messages.map((message) => (
          <Message key={message.id} message={message} />
        ))}
        <div ref={bottomRef} />
        {newQ ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              mb: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Avatar src={koala} sx={{ width: 56, height: 56 }} />
              <Paper
                variant="outlined"
                sx={{
                  p: 2,
                  ml: 1,
                  mr: 0,
                  backgroundColor: "#11526f",
                  borderRadius: "20px 20px 20px 5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Skeleton
                    sx={{ bgcolor: "white", marginRight: "3px" }}
                    variant="circular"
                    width={10}
                    height={10}
                  />
                  <Skeleton
                    sx={{ bgcolor: "white", marginRight: "3px" }}
                    variant="circular"
                    width={10}
                    height={10}
                  />
                  <Skeleton
                    sx={{ bgcolor: "white", marginRight: "3px" }}
                    variant="circular"
                    width={10}
                    height={10}
                  />
                </Box>
              </Paper>
            </Box>
          </Box>
        ) : (
          ""
        )}
      </Box>
      <Box sx={{ p: 2, backgroundColor: "background.default", borderRadius: "8px" }} bottom="0px">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if(input !== ""){
              handleSend();
            }
          }}
          style={{ width: "100%" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <TextField
                size="small"
                fullWidth
                label="Il tuo messaggio"
                variant="outlined"
                value={input}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                fullWidth
                className="ButtonMiller"
                color="primary"
                variant="contained"
                endIcon={<SendIcon />}
                onClick={()=>{
                  if(input !== ""){
                    handleSend()
                  }
                }}
              >
                Invia
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

const Message = ({ message }) => {
  const isBot = message.sender === "bot";

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isBot ? "flex-start" : "flex-end",
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isBot ? "row" : "row-reverse",
          alignItems: "center",
        }}
      >
        <Avatar
          src={isBot ? koala : localStorage.getItem("fotoProfilo")}
          sx={{ width: 56, height: 56 }}
        />
        <Paper
          variant="outlined"
          sx={{
            p: 2,
            ml: isBot ? 1 : 0,
            mr: isBot ? 0 : 1,
            backgroundColor: isBot ? "#11526f" : "#8E75AC",

            borderRadius: isBot ? "20px 20px 20px 5px" : "20px 20px 5px 20px",
          }}
        >
          <Typography variant="body1" sx={{ color: "#ffffff" }}>
            {message.text}
          </Typography>
        </Paper>
      </Box>
    </Box>
  );
};

export default CollegaVirtuale;

/*

import { Check } from "@mui/icons-material";
import { Autocomplete, Card, CardContent, Grid, TextField,Button, Skeleton } from "@mui/material";
import React,{useState} from "react";
import unsplash from "../api/unsplash";




const CollegaVirtuale = () =>{
const [opzioneSel,setOpzioneSel] = useState(null);
const [richiesta,setRichiesta] = useState(null);
const [lingua,setLingua] = useState(null);
const [loading,setLoading] = useState(false);
const [risposta,setRisposta] = useState("");
const opzioni = [{Opzione:"Scrivere una mail"},{Opzione:"Correggere un testo"},{Opzione:"Tradurre in italiano"},{Opzione:"Tradurre in un'altra lingua"},{Opzione:"Informarti su nuove leggi"},{Opzione:"Creare un elenco"},{Opzione:"Creare un recap da appunti di una riunione/call"}];



const handleRichiesta = async() => {

    if(!opzioneSel){
        alert("selezionare un'opzione valida")
        return;
    }
    setLoading(true);
    let intestazione = "";
    setRisposta("");

    switch(opzioneSel?.Opzione){

       case "Scrivere una mail":
        
       intestazione="Scrivere una mail con seguenti info: "
       break;
       case "Correggere un testo":
        intestazione="Correggi testo seguente:" 
       break;
       case "Tradurre in italiano":
        intestazione="Traduci in italiano la frase:"  
       break;
       case "Tradurre in un'altra lingua":
        intestazione="Traduci in "+lingua+" la frase:" 
       break;
       case "Informarti su nuove leggi":
       intestazione="Cerca informazioni sulla legge:" 
       break;
       case "Creare un elenco": 
       intestazione="Cerca un elenco con queste info:" 
       case "Creare un recap da appunti di una riunione/call": 
       intestazione="Convert my short hand into a first-hand account of the meeting in italian:" 
       break;





    }    
    const response = await unsplash.post("openAi", {
        message: intestazione +"\n"+richiesta
      });


      setRisposta(response.data.choices[0]?.message.content)
    

      setLoading(false);
     

}



return (


<Grid item xs={12}>
        <Card>
          <CardContent>
          
             Ciao, {sessionStorage.getItem("username")}!<br></br> Sono il tuo collega virtuale. Seleziona un'opzione dal menù a tendina, sarò felice di aiutarti!<br/>
             <Grid container spacing={2} > 
             <Grid item xs={12}>
             <Autocomplete
                id="combo-box-demo"
                options={opzioni}
               getOptionLabel={(option) => option.Opzione}
               value={opzioneSel}
                style={{ marginTop: "5px" }}
                fullWidth
                onChange={(event, newValue) => {
                  
                  setOpzioneSel(newValue);
               
                 setLingua("");
                 setRichiesta("");
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Hai bisogno di aiuto per ..." />
                )} />
             </Grid>
            {(opzioneSel?.Opzione ==="Tradurre in un'altra lingua" )?
            <>
            <Grid item xs={4}>
                  <TextField fullWidth label="Lingua" multiline  value={lingua}
                  onChange={(e)=> setLingua(e.target.value)}
                  
                  
                  
                  />
                  
                  </Grid>
                  <Grid item xs={8}>
                  <TextField fullWidth label="Richiesta" multiline   inputProps={{ maxLength: 500 }} value={richiesta}
                  onChange={(e)=> setRichiesta(e.target.value)}
                  
                  
                  
                  />
                  
                  </Grid>
            </>:<>

            <Grid item xs={12}>
                  <TextField fullWidth label="Richiesta" multiline   inputProps={{ maxLength: 500 }} value={richiesta}
                  onChange={(e)=> setRichiesta(e.target.value)}
                  
                  
                  
                  />
                  
                  </Grid>
            </>
            
        }
             <Grid item xs={12}>
              <div>
             {risposta?  <>Risposta: <br></br></> :<> </>}
             
                {loading?
                <>
                Elaboro risposta <br/>
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
                </>:""}

                {risposta.split("\n").map(el =>
                    <><p>{el}</p></>
                    
                    )}


              </div>
              </Grid>
        
             <Grid item xs={12}>
             <Button
             variant="contained"
                        className="ButtonMiller"
                        startIcon={<Check />}
                       onClick={handleRichiesta}
                      >
                      Chiedi
                      </Button>
                      </Grid >
                      </Grid>  
          </CardContent>
        </Card>
     
      </Grid>

)

}




export default CollegaVirtuale






<Grid item xs={12}>
        <Card>
          <CardContent>
          
             Ciao, {sessionStorage.getItem("username")}!<br></br> Sono il tuo collega virtuale. Seleziona un'opzione dal menù a tendina, sarò felice di aiutarti!<br/>
             <Grid container spacing={2} > 
             <Grid item xs={12}>
             <Autocomplete
                id="combo-box-demo"
                options={opzioni}
               getOptionLabel={(option) => option.Opzione}
               value={opzioneSel}
                style={{ marginTop: "5px" }}
                fullWidth
                onChange={(event, newValue) => {
                  
                  setOpzioneSel(newValue);
               
                 setLingua("");
                 setRichiesta("");
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Hai bisogno di aiuto per ..." />
                )} />
             </Grid>
            {(opzioneSel?.Opzione ==="Tradurre in un'altra lingua" )?
            <>
            <Grid item xs={4}>
                  <TextField fullWidth label="Lingua" multiline  value={lingua}
                  onChange={(e)=> setLingua(e.target.value)}
                  
                  
                  
                  />
                  
                  </Grid>
                  <Grid item xs={8}>
                  <TextField fullWidth label="Richiesta" multiline   inputProps={{ maxLength: 500 }} value={richiesta}
                  onChange={(e)=> setRichiesta(e.target.value)}
                  
                  
                  
                  />
                  
                  </Grid>
            </>:<>

            <Grid item xs={12}>
                  <TextField fullWidth label="Richiesta" multiline   inputProps={{ maxLength: 500 }} value={richiesta}
                  onChange={(e)=> setRichiesta(e.target.value)}
                  
                  
                  
                  />
                  
                  </Grid>
            </>
            
        }
             <Grid item xs={12}>
              <div>
             {risposta?  <>Risposta: <br></br></> :<> </>}
             
                {loading?
                <>
                Elaboro risposta <br/>
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
                </>:""}

                {risposta.split("\n").map(el =>
                    <><p>{el}</p></>
                    
                    )}


              </div>
              </Grid>
        
             <Grid item xs={12}>
             <Button
             variant="contained"
                        className="ButtonMiller"
                        startIcon={<Check />}
                       onClick={handleRichiesta}
                      >
                      Chiedi
                      </Button>
                      </Grid >
                      </Grid>  
          </CardContent>
        </Card>
     
      </Grid>


*/
