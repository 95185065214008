import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import unsplash from "../../api/unsplash";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  itIT,
} from "@mui/x-data-grid-pro";
import {
  IconButton,
  Grid,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  Toolbar,
  Skeleton,
} from "@mui/material";
import {
  Download,
  RemoveRedEye,
  Delete,
  Close,
  Check,
  ContactsOutlined,
  VpnKey,
  FileDownloadDone,
  ArrowDropDown,
  Place,
  School,
  Timer,
} from "@mui/icons-material";
import GlobalSnackbar from "../Snackbar";
import Edit from "@mui/icons-material/Edit";
import moment from "moment";
import { Tooltip } from "recharts";
import { CustomFooterPersonalized } from "../../utils/CustomFooterPersonalized";
import VerifiedIcon from "@mui/icons-material/Verified";
import { ContainerList } from "./ContainerList";

const ext = {
  doc: "application/msword",
  dot: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  dotx: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  docm: "application/vnd.ms-word.document.macroEnabled.12",
  dotm: "application/vnd.ms-word.template.macroEnabled.12",
  xls: "application/vnd.ms-excel",
  xlt: "application/vnd.ms-excel",
  xla: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xltx: "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  xlsm: "application/vnd.ms-excel.sheet.macroEnabled.12",
  xltm: "application/vnd.ms-excel.template.macroEnabled.12",
  xlam: "application/vnd.ms-excel.addin.macroEnabled.12",
  xlsb: "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  ppt: "application/vnd.ms-powerpoint",
  pot: "application/vnd.ms-powerpoint",
  pps: "application/vnd.ms-powerpoint",
  ppa: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  potx: "application/vnd.openxmlformats-officedocument.presentationml.template",
  ppsx: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  ppam: "application/vnd.ms-powerpoint.addin.macroEnabled.12",
  pptm: "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  potm: "application/vnd.ms-powerpoint.template.macroEnabled.12",
  ppsm: "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
  mdb: "application/vnd.ms-access",
  png: "application/png",
  pdf: "application/pdf",
};

const FormazioneLavoratore = (props) => {
  const [corsi, setCorsi] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openBackdrop, setOpenBackDrop] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          padding={2}
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const columnsCorsi = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "100",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          {params.row.Allegati ? (
            <IconButton
              aria-label="edit"
              onClick={() => {
                if (params.row.Allegati) {
                  downloadFile(
                    params.row.Allegati,
                    params.row.Estensione,
                    params.row.Descrizione,
                    "download"
                  );
                } else {
                  setSnack({
                    severity: "error",
                    messaggio: "Errore durante il dowload del file",
                  });
                  setOpenSnack(true);
                }
              }}
              sx={{ p: 0 }}
            >
              <VerifiedIcon
                style={{ height: "2rem", width: "2rem", color: "#11526f" }}
              />
            </IconButton>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      field: "DataInizio",
      headerName: "Data Inizio",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) =>
        params.row.DataInizio
          ? moment(params.row.DataInizio).format("DD/MM/YYYY")
          : "",
    },
    {
      field: "DataFine",
      headerName: "Data Fine",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) =>
        params.row.DataFine
          ? moment(params.row.DataFine).format("DD/MM/YYYY")
          : "",
    },
    {
      field: "Tipo",
      headerName: "Tipologia",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Luogo",
      headerName: "Luogo",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Modalita",
      headerName: "Modalita",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },

    {
      field: "Durata",
      headerName: "Durata",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Aggiornamento",
      headerName: "Aggiornamento",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Note",
      headerName: "Note",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
  ];

  const downloadFile = async (id, estensione, descrizione, operazione) => {
    try {
      const response = await unsplash.post(
        "ftp",
        {
          file: id + estensione,
          nomeOriginale: descrizione.includes(estensione)
            ? descrizione
            : descrizione + estensione,
          operazione: "download",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: ext[estensione.replace(".", "")] })
      );
      const link = document.createElement("a");
      link.href = url;
      if (operazione === "download") {
        link.setAttribute(
          "download",
          descrizione.includes(estensione)
            ? descrizione
            : descrizione + estensione
        );
        document.body.appendChild(link);
        link.click();
      }
      setSnack({
        severity: "success",
        messaggio: "Certificato scaricato con successo",
      });
      setOpenSnack(true);
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante il download",
      });
      setOpenSnack(true);
    }
  };

  const downloadFileCrypt = async (
    id,
    estensione,
    descrizione,
    operazione,
    criptato,
    estensioneOriginale
  ) => {
    let psw;

    if (criptato === 1) {
      psw = window.prompt(
        "Inserisci la tua password per scaricare questo documento"
      );
    }

    const response = await unsplash.post(
      "ftp",
      {
        file: id + estensione,
        nomeOriginale: descrizione.includes(estensione)
          ? descrizione
          : descrizione + estensione,
        operazione: "downloadCrypt",
        estensioneOriginale: estensioneOriginale,
        password: psw,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        responseType: "blob",
      }
    );
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: ext[estensione.replace(".", "")] })
    );
    const link = document.createElement("a");
    link.href = url;
    if (operazione === "download") {
      link.setAttribute(
        "download",
        descrizione.replace(estensione, estensioneOriginale)
      );
      document.body.appendChild(link);
      link.click();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        let respData = await unsplash.post(
          "portaleLavoratori",
          {
            operation: "GetAll",
            lavoratore: sessionStorage.getItem("lavoratore"),
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
          );
          
          setCorsi(respData.data.corsi);
          setLoading(false)
      } catch {
        setCorsi([]);
      }
    };
    fetchData();
  }, []);

  const reloadAll = async () => {
    let respData = await unsplash.post(
      "portaleLavoratori",
      { operation: "GetAll", lavoratore: sessionStorage.getItem("lavoratore") },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );

    setCorsi(respData.data.corsi);
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <div className="lg:hidden">
        {!loading ? (
          <ContainerList
            page="Formazione"
            data={corsi.map((corso) => {
              //Accorpo i giorni uguali
              if (corso.DataInizio === corso.DataFine) {
                return {
                  ...corso,
                  DataFilter: moment(corso.DataInizio).format("DD/MM/YYYY"),
                  DataInizio: [moment(corso.DataInizio).format("DD/MM/YYYY")],
                  Durata: corso.Durata ? corso.Durata + "h" : null,
                };
              } else {
                //inserisco i due giorni che vanno a capo
                return {
                  ...corso,
                  DataFilter:
                    moment(corso.DataInizio).format("DD/MM/YYYY") +
                    " " +
                    moment(corso.DataFine).format("DD/MM/YYYY"),
                  DataInizio: [
                    "Dal " + moment(corso.DataInizio).format("DD/MM/YYYY"),
                    <br />,
                    "Al " + moment(corso.DataFine).format("DD/MM/YYYY"),
                  ],
                  Durata: corso.Durata ? corso.Durata + "h" : null,
                };
              }
            })}
            header={["Giorni", "Corsi"]}
            leftCard={["DataInizio"]}
            additionalFilter={["DataFilter"]}
            rightCard={[
              { title: true, prop: "DescCorso" },
              { prop: "Luogo", icon: <Place fontSize="12" /> },
              { prop: "Modalita", icon: <School fontSize="12" /> },
              { prop: "Durata", icon: <Timer fontSize="12" /> },
            ]}
            titleCard="Tipo: "
            actions={downloadFile}
          />
        ) : (
          <div className="flex flex-wrap gap-2">
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
          </div>
        )}
      </div>
      {corsi === undefined ? (
        ""
      ) : (
        <>
          <div className="h-[75vh] md:h-[80vh] w-full lg:block hidden">
            <DataGridPro
              components={{
                Toolbar: CustomToolbar,
                Footer: CustomFooterPersonalized,
              }}
              sx={{ fontSize: 12, backgroundColor: "white" }}
              localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
              rows={corsi}
              columns={columnsCorsi}
              getRowId={(row) => row.ID}
              pageSize={100}
              disableSelectionOnClick
            />
          </div>
        </>
      )}
    </>
  );
};

export default FormazioneLavoratore;
