//npm i react-toggle-dark-mode
//darkMode: "class",
//useDarkSide
import { useState } from "react";
import useDarkSide from "./useDarkSide";
import { DarkModeSwitch } from "react-toggle-dark-mode";

export const Switcher = () => {
  const [colorTheme, setTheme] = useDarkSide();
  const [darkSide, setDarkSide] = useState(
    colorTheme === "light" ? true : false
  );

  const toggleDarkMode = (checked) => {
    setTheme(colorTheme);
    setDarkSide(checked);
  };

  return (
    <>
      <DarkModeSwitch
        className="absolute right-5 top-5 lg:top-8 z-50 hover:scale-105"
        checked={darkSide}
        onChange={toggleDarkMode}
        size={30}
      />
    </>
  );
};
