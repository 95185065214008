import React, { useEffect, useState, useRef } from "react";
import unsplash from "../api/unsplash";
import { useNavigate } from "react-router-dom";

import {
  Checkbox,
  InputAdornment,
  FormControlLabel,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
  Button,
  TextField,
  DialogActions,
  Select,
  OutlinedInput,
  MenuItem,
  FormControl,
  Card,
  Grid,
  CardContent,
  ButtonGroup,
  Tooltip,
  Zoom,
  LinearProgress,
  Snackbar,
} from "@mui/material";
import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
  GridFooterContainer,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import {
  Add,
  Circle,
  HealthAndSafety,
  Search,
  MenuBook,
  FiberNew,
  Close,
  Check,
} from "@mui/icons-material";
import GlobalSnackbar from "./Snackbar";

import Grow from "@mui/material/Grow";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Excel } from "../utils/Excel";
import { createPortal } from "react-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const Clienti = ({ screenSize }) => {
  const [clienti, setClienti] = useState([]);
  const [test,setTesto] = useState(false)
  const [clientiVisibili, setClientiVisibili] = useState([]);
  const [loading, setLoading] = useState(true);
  const [valueFilter, setValueFilter] = useState("");

  const anchorRef = useRef(null);
  const [openButton, setOpenButton] = useState(false);

  const navigate = useNavigate();
  const [openSnack, setOpenSnack] = useState(false);
  const [filtro, setFiltro] = useState({
    medicina: true,
    cessati: false,
    attivi: true,
  });

  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const columns = [
    {
      filterable: false,
      field: "new",
      headerName: "",
      width: 10,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          {params.row.new ? (
            <FiberNew
              sx={{ color: "#40C18F", width: "25px", height: "25px" }}
            />
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      filterable: false,
      field: "Cessato",
      headerName: "Stato",
      //width: 400,
      width: screenSize < "768" ? 50 : 70,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => (
        <>
          {params.row.Cessato === 1 ? (
            <Tooltip title="Cessato">
              <Circle
                sx={{ color: "#FF2768", width: "15px", height: "15px" }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Attivo">
              <Circle
                sx={{ color: "#40C18F", width: "15px", height: "15px" }}
              />
            </Tooltip>
          )}
        </>
      ),
    },

    {
      field: "RagioneSociale",
      headerName: "Ragione Sociale",
      width: 150,
      flex: screenSize < "768" ? 0 : 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "SedeIndirizzo",
      headerName: "Indirizzo",
      width: 150,
      flex: screenSize < "768" ? 0 : 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "SedeLocalita",
      headerName: "Località",
      width: 150,
      flex: screenSize < "768" ? 0 : 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "SedeCAP",
      headerName: "Cap",
      width: 150,
      flex: screenSize < "768" ? 0 : 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
  ];
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleToggleButton = () => {
    setOpenButton(!openButton);
  };
  const handleCloseButton = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenButton(false);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{ columnGap: 2, margin: { xs: 2, md: 1 } }}
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
          <FormControlLabel
            sx={{
              marginBottom: "0px!important",
              fontSize: "13px!important",
              color: "#11526F",
            }}
            control={
              <Checkbox
                size="small"
                sx={{ fontSize: "13px!important", color: "#11526F" }}
                checked={filtro.medicina}
                onClick={(e) => filter(e, "medicina")}
              />
            }
            label="Medicina"
          />
          <FormControlLabel
            sx={{
              marginBottom: "0px!important",
              fontSize: "13px",
              color: "#11526F",
            }}
            control={
              <Checkbox
                size="small"
                checked={filtro.attivi}
                onClick={(e) => filter(e, "attivi")}
              />
            }
            label="Attivi"
          />
          <FormControlLabel
            sx={{
              marginBottom: "0px!important",
              fontSize: "13px",
              color: "#11526F",
            }}
            control={
              <Checkbox
                size="small"
                checked={filtro.cessati}
                onClick={(e) => filter(e, "cessati")}
              />
            }
            label="Cessati"
          />
          <Excel
            requiredColumn={"RagioneSociale"}
            getAllTableData={"Clienti"}
            filterColumn={[
              "RagioneSociale",
              "SedeIndirizzo",
              "SedeLocalita",
              "SedeCAP",
            ]}
            //thereIsData={["DataDiNascita"]}
            excelHeader={[
              "RagioneSociale",
              "SedeIndirizzo",
              "SedeLocalita",
              "SedeCAP",
            ]}
            reloadData={async () => {
              let response = await unsplash.get("clienti", {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
                params: { all: "yes" },
              });
              setClienti(response.data.data);
              setClientiVisibili(
                response.data.data.filter((c) => c.Cessato !== 1)
              );
            }}
            fileName={"Clienti"}
            handleSave={async (importedData) => {
              try {
                await unsplash.post(
                  "excel",
                  {
                    excel: importedData,
                    table: "Clienti",
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                  }
                );
              } catch {
                setSnack({
                  severity: "error",
                  messaggio: "Errore durante l'importazione",
                });
                setOpenSnack(true);
              }
            }}
          />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const filter = (e, tipologia) => {
    let f = { ...filtro };
    f[tipologia] = e.target.checked;
    setFiltro(f);

    let clientiTemp = clienti;

    var clientiX = []; // popolo l'array
    var i;
    for (i = 0; i < clienti.length; i++) {
      if (
        clienti[i].RagioneSociale.toLowerCase().includes(
          valueFilter.toLowerCase()
        )
      ) {
        clientiX.push(clienti[i]);
      }

      if (!f.medicina) {
        clientiX = clientiX.filter((c) => c.GestioneMedicina === 1);
      }

      if (!f.cessati) {
        clientiX = clientiX.filter((c) => c.Cessato !== 1);
      }

      if (!f.attivi) {
        clientiX = clientiX.filter((c) => c.Cessato !== 0);
      }
    }
    setClientiVisibili(clientiX);
  };

  //importante per l'api l'arrayvuoto
  const handleChange = (e) => {
    setValueFilter(e.target.value);
    // filtro

    //setFiltro({medicina:false});
    let arrayFiltrato = clienti;
    if (e.target.value == "") {
      if (!filtro.medicina) {
        arrayFiltrato = arrayFiltrato.filter((c) => c.GestioneMedicina === 1);
      }

      if (!filtro.cessati) {
        arrayFiltrato = arrayFiltrato.filter((c) => c.Cessato !== 1);
      }

      if (!filtro.attivi) {
        arrayFiltrato = arrayFiltrato.filter((c) => c.Cessato !== 0);
      }
      setClientiVisibili(arrayFiltrato);
    } else {
      var clientiX = []; // popolo l'array
      var i;
      for (i = 0; i < clienti.length; i++) {
        if (
          clienti[i].RagioneSociale.toLowerCase().includes(
            e.target.value.toLowerCase()
          )
        ) {
          clientiX.push(clienti[i]);
        }

        if (!filtro.medicina) {
          clientiX = clientiX.filter((c) => c.GestioneMedicina === 1);
        }

        if (!filtro.cessati) {
          clientiX = clientiX.filter((c) => c.Cessato !== 1);
        }

        if (!filtro.attivi) {
          clientiX = clientiX.filter((c) => c.Cessato !== 0);
        }
      }
      setClientiVisibili(clientiX);
    }
  };

  const handleSaveData = async (cliente) => {
    try {
      //AGGIUNGERE LA CREAZIONE DELLA SEDE
      let responseCliente = await unsplash.post(
        "clienti",
        { cliente: cliente },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      cliente.new = true;
      cliente.ID = responseCliente.data.message.insertId;
      let c = [...clienti];
      c.unshift(cliente); //aggiungo in prima posizione

      setClienti(c);
      setClientiVisibili(c);

      await unsplash.post(
        "storico",
        {
          tabella: "Clienti",
          key: cliente.ID,
          utente: sessionStorage.getItem("username"),
          after: cliente,
          before: [],
          operazione: "Inserimento",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      setSnack({
        severity: "success",
        messaggio: "Cliente creato con successo",
      });
      setOpenSnack(true);
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante la creazione del Cliente",
      });
      setOpenSnack(true);
    }
  };

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      try {
        let response = await unsplash.get("clienti", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { all: "yes" },
        });
        setClienti(response.data.data);
        setClientiVisibili(response.data.data.filter((c) => c.Cessato !== 1));
        setLoading(false);


      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {}, [screenSize]);

  return (
    <>
      {" "}
      
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <div>
              <Grid
                container
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="flex-end"
              >
                <Grid item xs={12} md={4}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <ButtonGroup
                      variant="contained"
                      aria-label="outlined primary button group"
                    >
                      <Button
                        className="ButtonMiller"
                        startIcon={<MenuBook />}
                        onClick={() => navigate("/ProceduraGuidata")}
                      >
                        Procedura Guidata
                      </Button>
                      <NewClientModal myFunction={handleSaveData} />
                    </ButtonGroup>
                  </Grid>
                </Grid>
              </Grid>

              <Box
                sx={{
                  height: { xs: "75vh", md: "60vh", xl: "75vh" },
                  width: 1,
                }}
              >
                <DataGridPro
                  sx={{ fontSize: 12 }}
                  components={{
                    Toolbar: CustomToolbar,
                    Footer: CustomFooterPersonalized,
                  }}
                  slots={{
                    loadingOverlay: LinearProgress,
                  }}
                  loading={loading}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  pagination={false}
                  rows={clientiVisibili}
                  columns={columns}
                  getRowId={(row) => row.ID}
                  pageSize={100}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "matricola", sort: "desc" }],
                    },
                    pinnedColumns: { left: ["Cessato"] },
                  }}
                  disableSelectionOnClick
                  onRowClick={async (params, event) => {
                    let idCliente = params.row.ID;
                    let ragSoc = params.row.RagioneSociale;

                    navigate("/dettaglioCliente", {
                      state: { idC: idCliente, RagioneSociale: ragSoc },
                    });
                  }}
                />
              </Box>
            </div>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default Clienti;

const NewClientModal = (props) => {
  const [open, setOpen] = useState(false);
  const [cliente, setCliente] = useState();
  const [validation, setValidation] = useState();

  const handleClickOpen = () => {
    setCliente({
      RagioneSociale: "",
      SedeIndirizzo: "",
      SedeLocalita: "",
      SedeCAP: "",
    });
    setValidation({
      RagioneSociale: false,
      SedeIndirizzo: false,
      SedeLocalita: false,
      SedeCAP: false,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    let c = { ...cliente };
    c[e.target.name] = e.target.value;
    setCliente(c);
  };

  const handleSave = async () => {
    var check = false;
    let val = { ...validation };
    if (cliente.RagioneSociale === "" || cliente.RagioneSociale === null) {
      val.RagioneSociale = true;
      check = true;
    } else {
      val.RagioneSociale = false;
    }
    if (cliente.SedeIndirizzo === "" || cliente.SedeIndirizzo === null) {
      val.SedeIndirizzo = true;
      check = true;
    } else {
      val.SedeIndirizzo = false;
    }
    if (cliente.SedeLocalita === "" || cliente.SedeLocalita === null) {
      val.SedeLocalita = true;
      check = true;
    } else {
      val.SedeLocalita = false;
    }

    if (cliente.SedeCAP === "" || cliente.SedeCAP === null) {
      val.SedeCAP = true;
      check = true;
    } else {
      val.SedeCAP = false;
    }

    setValidation(val);

    if (!check) {
      props.myFunction(cliente);
    }
  };

  return (
    <>
      <Button
        startIcon={<Add />}
        onClick={handleClickOpen}
        className="ButtonMiller"
      >
        Aggiungi
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="md"
        TransitionComponent={Transition}
      >
        <DialogTitle>Inserisci nuovo cliente</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                error={validation?.RagioneSociale}
                margin="dense"
                name="RagioneSociale"
                label="Ragione Sociale"
                type="text"
                fullWidth
                value={cliente?.RagioneSociale}
                onChange={handleChange}
                variant="outlined"
                helperText={
                  validation?.RagioneSociale ? "Campo obbligatorio" : ""
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                error={validation?.SedeIndirizzo}
                
                margin="dense"
                name="SedeIndirizzo"
                label="Indirizzo"
                type="text"
                fullWidth
                value={cliente?.SedeIndirizzo}
                onChange={handleChange}
                variant="outlined"
                helperText={
                  validation?.SedeIndirizzo ? "Campo obbligatorio" : ""
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                error={validation?.SedeLocalita}
                
                margin="dense"
                name="SedeLocalita"
                label="Località"
                type="text"
                fullWidth
                value={cliente?.SedeLocalita}
                onChange={handleChange}
                variant="outlined"
                helperText={
                  validation?.SedeLocalita ? "Campo obbligatorio" : ""
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                error={validation?.SedeCAP}
                
                margin="dense"
                name="SedeCAP"
                label="Cap"
                type="text"
                fullWidth
                value={cliente?.SedeCAP}
                onChange={handleChange}
                variant="outlined"
                helperText={validation?.SedeCAP ? "Campo obbligatorio" : ""}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
          >
            Annulla
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={handleSave}
          >
            Crea
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
