import React, { useState, useEffect,useMemo } from "react";
import unsplash from "../../api/unsplash";
import {
  Box,
  CircularProgress,
  Tooltip,
  Typography,
  LinearProgress,
} from "@mui/material";

import moment from "moment";
import { DataGrid, itIT } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { Circle, Lock, CalendarMonth, FileCopy, FileDownload, FileUpload } from "@mui/icons-material";

import { toast, ToastContainer } from "react-toastify";
import {
  IconButton,
  ButtonGroup,
  TextField,
  Button,
  Tab,
  Tabs,
  Paper,
  Grid,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormControl,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Zoom,
} from "@mui/material";

import Dropzone from "react-dropzone";
import DropzoneComponent from "react-dropzone-component";
import { useDropzone } from "react-dropzone";
import Upload from "@mui/icons-material/Upload";
import { Close } from "@mui/icons-material";
import logoChiaro from "../../assets/images/Logo-chiaro.png";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});


const HomepageMedico = () => {
  const [analisiFormazione, setAnalisiFormazione] = useState(null);
  const [analisiFormazionePie, setAnalisiFormazionePie] = useState(null);
  const [prossimiCorsi, setProssimiCorsi] = useState([]);
  const [prossimeVisite, setProssimeVisite] = useState([]);
  const [openMed, setOpenMed] = useState(false);
  const [openFormazione, setOpenformazione] = useState(false);
  const [valMedicina, setValMedicina] = useState(0);
  const [valFormazione, setValFormazione] = useState(0);
  const [valSicurezza, setValSicurezza] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const columns = [
    {
      field: "Descrizione",
      headerName: "Descrizione",
      flex: 1,
    },
    {
      field: "DataInizio",
      headerName: "Data Inizio",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.DataInizio
              ? moment(params.row.DataInizio).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },

    {
      field: "DataFine",
      headerName: "Data Fine",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.DataFine
              ? moment(params.row.DataFine).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },
  ];

  const columnsVisite = [
    {
      field: "Lavoratore",
      headerName: "Lavoratore",
      flex: 1,
    },
    {
      field: "Data",
      headerName: "Data",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.Data
              ? moment(params.row.Data).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },

    {
      field: "Accertamenti",
      headerName: "Accertamenti",
      flex: 1,
    },
  ];

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      try {
        setValMedicina(85);
        setValFormazione(90);
        setValSicurezza(87);
        let responseAnalysis = await unsplash.get("analysis", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: {
            user: sessionStorage.getItem("User"),
            operazione: "percentualiCorsiInScadenzaUtente",
          },
        });

        setAnalisiFormazione(responseAnalysis.data.data);

        let responseAnalysisDettaglio = await unsplash.get("analysis", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: {
            user: sessionStorage.getItem("User"),
            operazione: "percentualiCorsiInScadenzaUtenteDettaglio",
          },
        });

        setAnalisiFormazionePie(responseAnalysisDettaglio.data.data.data);

        let responseProssimiCorsi = await unsplash.get("analysis", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: {
            user: sessionStorage.getItem("User"),
            operazione: "prossimiCorsiUtente",
          },
        });

        setProssimiCorsi(responseProssimiCorsi.data.data);

        let responseProssimiVisite = await unsplash.get("analysis", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: {
            user: sessionStorage.getItem("User"),
            operazione: "prossimeVisiteUtente",
          },
        });

        setProssimeVisite(responseProssimiVisite.data.data);

        setLoaded(true);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  const askForArea = async (area) => {
    try {
      await unsplash.post(
        "utenti",
        { utente: sessionStorage.getItem("User"), area: area },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      toast.success(
        "Grazie per aver effettuato la richiesta. Verrai contattato il prima possibile",
        {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    } catch {
      toast.error("Attenzione! Errore durante la richiesta", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (<>
    <div className="flex flex-wrap ">
      <div className=" bg-primary-600 dark:bg-white p-4 sm:p-7 rounded-md h-fit w-full">
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
          theme="colored"
        />
        {/* Background illustration */}

        {/* Content */}
        <div className="relative">
          {/* <span className="absolute inset-y-0 right-0 top-0 pointer-events-none z-50 hidden xl:block h-20 w-20 light-logo"></span> */}
          <div className="absolute inset-y-0 right-0 top-0 pointer-events-none z-10 hidden xl:block">
            <img src={logoChiaro} alt="" className="h-20 w-20" />
          </div>
          <h1 className="text-2xl md:text-3xl text-white dark:text-primary-600 font-bold mb-1">
            Benvenuto, {sessionStorage.getItem("User")}.
          </h1>
          <p className="text-white dark:text-primary-600 w-5/6 ">
            Visualizza le tue prossime visite direttamente dalla tua area dedicata e carica le idoneità sul tuo portale. 
          </p>
        </div> 
    
    
      </div>
      </div>

      <div className="grid grid-cols-12 gap-3 mt-4">


        <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white rounded-md border border-slate-200">
              <div className="px-5 pt-5">
                <header className="text-lg font-semibold text-slate-800 mb-2">
                 
                Percentuale visite completate
                </header>
                <div className="flex justify-center mb-8 mt-8">
                  <CircularProgressWithLabel
                    variant="determinate"
                    value={100}
                    size={150}
                  />
                </div>
              </div>
              
              <div className="grow">
                
              </div>
            </div>



            <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white rounded-md border border-slate-200">
              <div className="px-5 pt-5">
                <header className="text-lg font-semibold text-slate-800 mb-2">
                 
                  Prossime Visite
                </header>
                <div className="flex justify-center mb-8 mt-8">
                 - 07/07/2023 14:30 - Pavia - Azienda 1
                </div>
                <div className="flex justify-center mb-8 mt-8">
                 - 08/07/2023 14:30 - Milano - Azienda 2
                </div>
                <div className="flex justify-center mb-8 mt-8">
                 - 09/07/2023 14:30 - Francolino - Azienda 3
                </div>
              </div>
              
              <div className="grow">
                
              </div>
            </div>


            
        <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white rounded-md border border-slate-200">
              <div className="px-5 pt-5">
                <header className="text-lg font-semibold text-slate-800 mb-2">
                 
                  Documenti
                </header>
                <div className="flex justify-center mb-8 mt-8">
                <h2 className="text-md font-semibold text-slate-800 mb-2">
                 Carica ora i documenti relativi alle visite effettuate
                </h2>
                
                </div>
              <FileUploader/>
              </div>
              
              <div className="grow">
                
              </div>
            </div>


            </div>


      {/*
      <div className="grid grid-cols-12 gap-3 mt-4">
        
        {
          sessionStorage.getItem("M") === "1" ? (
            
            <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white rounded-md border border-slate-200">
              <div className="px-5 pt-5">
                <header className="flex justify-between items-start mb-2">
                 
                  Medicina
                
                  <Circle
                    className="relative inline-flex"
                    sx={{ color: "#51E838" }}
                  />
                </header>
                <h2 className="text-lg font-semibold text-slate-800 mb-2">
                  % Lavoratori Visitati
                </h2>
                <div className="flex justify-center mb-8 mt-8">
                  <CircularProgressWithLabel
                    variant="determinate"
                    value={valMedicina}
                    size={150}
                  />
                </div>
              </div>
              
              <div className="grow">
                
              </div>
            </div>
          ) : (
            <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-grey hover:bg-white hover:cursor-pointer transition duration-300  rounded-md border border-slate-200">
              <div className="px-5 pt-5">
                <header className="flex justify-between items-start mb-2">
                  
                  Medicina
                  
                  <Circle
                    className="relative inline-flex"
                    sx={{ color: "#ff5757" }}
                  />
                </header>
                <h2 className="text-lg font-semibold text-slate-800 mb-2">
                  Pacchetto non Acquistato{" "}
                </h2>
                <div className="text-xs font-semibold text-slate-400 uppercase mb-1">
                  Vuoi acquistare questo pacchetto?
                </div>
                <div className="flex items-start">
                  <button
                    className="w-full m-5 p-2 text-white bg-primary-600 hover:bg-primary-100  transition duration-300 rounded-md"
                    id="Locker"
                    onClick={() => askForArea("Medicina")}
                  >
                    <Lock id="LockerUP" />
                    SBLOCCA{" "}
                  </button>
                </div>
              </div>
              
              <div className="grow">
               
              </div>
            </div>
          )

          
        }
        
        {
          sessionStorage.getItem("S") === "1" ? (
            
            <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white  rounded-md border border-slate-200">
              <div className="px-5 pt-5">
                <header className="flex justify-between items-start mb-2">
                 
                  Sicurezza
                  
                  <Circle
                    className="relative inline-flex"
                    sx={{ color: "#51E838" }}
                  />
                </header>
                <h2 className="text-lg font-semibold text-slate-800 mb-2">
                  % Sicurezza
                </h2>
                <div className="flex justify-center mb-8 mt-8">
                  <CircularProgressWithLabel
                    variant="determinate"
                    value={valSicurezza}
                    size={150}
                  />
                </div>
              </div>
             
              <div className="grow">
                
              </div>
            </div>
          ) : (
            <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-grey hover:bg-white hover:cursor-pointer transition duration-300   rounded-md border border-slate-200">
              <div className="px-5 pt-5">
                <header className="flex justify-between items-start mb-2">
                  
                  Sicurezza
                  
                  <Circle
                    className="relative inline-flex"
                    sx={{ color: "#ff5757" }}
                  />
                </header>
                <h2 className="text-lg font-semibold text-slate-800 mb-2">
                  Pacchetto non Acquistato{" "}
                </h2>
                <div className="text-xs font-semibold text-slate-400 uppercase mb-1">
                  Vuoi acquistare questo pacchetto?
                </div>
                <div className="flex items-start">
                  <button
                    className="w-full m-5 p-2 text-white bg-primary-600 hover:bg-primary-100  transition duration-300 rounded-md"
                    id="Locker"
                    onClick={() => askForArea("Sicurezza")}
                  >
                    <Lock id="LockerUP" />
                    SBLOCCA{" "}
                  </button>
                </div>
              </div>
              
              <div className="grow">
                
              </div>
            </div>
          )

          
        }
        
        {
          sessionStorage.getItem("F") === "1" ? (
            
            <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white  rounded-md border border-slate-200">
              <div className="px-5 pt-5">
                <header className="flex justify-between items-start mb-2">
                  
                  Formazione
                  
                  <Circle
                    className="relative inline-flex"
                    sx={{ color: "#51E838" }}
                  />
                </header>
                <h2 className="text-lg font-semibold text-slate-800 mb-2">
                  % Andamento Formazione
                </h2>
                <div className="flex justify-center mb-8 mt-8">
                  <CircularProgressWithLabel
                    variant="determinate"
                    value={valFormazione}
                    size={150}
                  />
                </div>
              </div>
              
              <div className="grow">
                
              </div>
            </div>
          ) : (
            <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-grey hover:bg-white hover:cursor-pointer transition duration-300   rounded-md border border-slate-200">
              <div className="px-5 pt-5">
                <header className="flex justify-between items-start mb-2">
                  
                  Formazione
                  
                  <Circle
                    className="relative inline-flex"
                    sx={{ color: "#ff5757" }}
                  />
                </header>
                <h2 className="text-lg font-semibold text-slate-800 mb-2">
                  Pacchetto non Acquistato{" "}
                </h2>
                <div className="text-xs font-semibold text-slate-400 uppercase mb-1">
                  Vuoi acquistare questo pacchetto?
                </div>
                <div className="flex items-start">
                  <button
                    className="w-full m-5 p-2 text-white bg-primary-600 hover:bg-primary-100  transition duration-300 rounded-md"
                    id="Locker"
                    onClick={() => askForArea("Formazione")}
                  >
                    <Lock id="LockerUP" />
                    SBLOCCA{" "}
                  </button>
                </div>
              </div>
              
              <div className="grow">
                
              </div>
            </div>
          )

          
        }
        
      </div> 
      <div className="bg-primary-600 dark:bg-white p-4 sm:p-6 rounded-md mt-4">
        <div className="flex flex-row items-center">
          <h4 className="text-xl md:text-2xl text-white dark:text-primary-600 font-bold mb-1 mr-5 mt-2 my-auto">
            Hai bisogno di aiuto? Chiedi supporto ai nostri operatori!
          </h4>
          <button
            className="flex items-center p-2 text-white bg-primary-100  hover:bg-primary-200 transition duration-300 rounded-md h-20 md:h-16"
            id="Locker"
          >
            <span className="flex items-center p-1">
              <CalendarMonth />
              <p className="pl-3">ORGANIZZA UNA CALL </p>
            </span>
          </button>
        </div>
      </div>
      */}
   
    </>
  );
};

export default HomepageMedico;

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        {...props}
        sx={{
          color:
            props.value <= 50
              ? "red"
              : props.value <= 75
              ? "#36CAF6"
              : "#51E838",
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Tooltip title={props.title}>
          <Typography
            component="div"
            color="text.secondary"
            variant="h4"
            className="cursor-pointer"
          >
            {`${Math.round(props.value)}%`}
          </Typography>
        </Tooltip>
      </Box>
    </Box>
  );
}

const FileUploader = (props) => {
  const [open, setOpen] = useState(false);
  const {
    acceptedFiles,
    isDragAccept,
    isDragReject,
    isFocused,
    getRootProps,
    getInputProps,
  } = useDropzone();
  const [files, setFiles] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      setSnack({
        severity: "error",
        messaggio: "Nessun file selezionato",
      });
      setOpenSnack(true);
    } else {
      const formData = new FormData();
      formData.append("colonna", props.colonna);
      formData.append("tipologia", "i");
      formData.append("cf", "BRNFRC98M13M102U");
      files.map((file) => {
        formData.append("file", file);
      });
      try {
        await unsplash.post("caricaDocHilti", formData, {
          headers: {
  
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });

        setSnack({
          severity: "success",
          messaggio: "File caricato con successo",
        });
        setOpenSnack(true);
       // props.reloadAfterUpload();
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante il caricamento",
        });
        setOpenSnack(true);
      
    }
  }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
  

      <button
                    className="w-full p-2 text-white bg-primary-600 hover:bg-primary-100  transition duration-300 rounded-md"
                    id="Locker"
                    onClick={() => {
                      setOpen(true);
                      setFiles([]);
                    }}
                  >
                    <FileUpload id="LockerUP" />
                    Carica{" "}
                  </button>

      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
    
        <DialogTitle>Carica File</DialogTitle>
        <DialogContent>
          <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Trascina e rilascia qui o fai clic per selezionare i file</p>
              </div>
            )}
          </Dropzone>
          <aside>
            <h4 style={{ marginTop: "10px", textAlign: "center" }}>Files</h4>
            {files?.map((file) => {
              return (
                <>
                  <ul style={{ textAlign: "center", padding: 0 }}>
                    {file.path}
                  </ul>
                </>
              );
            })}
          </aside>
        </DialogContent>
        <DialogActions>
          <Grid
            xs={12}
            item
            sx={{
              display: "flex",
              mt: 2,
              justifyContent: "space-between",
              px: 2,
            }}
            fullWidth
          >
             <button
                    className=" p-2 mr-2 text-white bg-primary-600 hover:bg-primary-100  transition duration-300 rounded-md"
                    id="Locker"
                    onClick={handleUpload}
                  >
                    <FileUpload id="LockerUP" />
                    Carica{" "}
                  </button>
                  <button
                    className=" p-2 text-white bg-primary-600 hover:bg-primary-100  transition duration-300 rounded-md"
                    id="Locker"
                    onClick={handleClose}
                  >
                    <Close id="LockerUP" />
                    Annulla{" "}
                  </button>
          
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
