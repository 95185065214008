import {
  AccountCircle,
  Business,
  Fingerprint,
  Badge,
} from "@mui/icons-material";
import moment from "moment";

export const ProfiloLavoratore = ({}) => {
  return (
    <>
      <div className="w-full flex ">
        <div className="w-full lg:w-full rounded-md p-1 lg:p-4 bg-azulMiller/70 text-white ">
          <div className="grid grid-cols-2 my-2 appear-effect">
            <div className="lg:text-2xl text-lg col-span-2 ">
              <Fingerprint className="mr-2 text-primary-600" fontSize="large" />
              Dati Anagrafici
            </div>
            <div className="col-span-2 grid grid-cols-2 py-2 pr-2 pl-11 ">
              <div className="lg:text-xl text-lg col-span-2 lg:col-span-2 flex">
                <div className="w-fit sm:w-1/2 lg:w-1/4 mr-2">Nome:</div>{" "}
                {" " + sessionStorage.getItem("Nome")}
              </div>
              <div className="lg:text-lg text-lg col-span-2 lg:col-span-1 border-b-2 boder-white my-1"></div>
              <div className="lg:text-xl text-lg col-span-2 lg:col-span-2 flex">
                <div className="w-fit sm:w-1/2 lg:w-1/4 mr-2">Cognome:{" "}</div>
                {" " + sessionStorage.getItem("Cognome")}
              </div>
              <div className="lg:text-lg text-lg col-span-2 lg:col-span-1 border-b-2 boder-white my-1"></div>
              <div className="lg:text-xl text-lg col-span-2 lg:col-span-2 flex">
                <div className="w-fit sm:w-1/2 lg:w-1/4 mr-2">CF:</div>
                {" " + sessionStorage.getItem("CF")}
              </div>
              <div className="lg:text-lg text-lg col-span-2 lg:col-span-1 border-b-2 boder-white my-1"></div>
            </div>

            <div className="lg:text-2xl text-lg col-span-2 flex items-center mt-4">
              <Business className="mr-2 text-primary-600" fontSize="large" />
              Dati Aziendali
            </div>
            <div className="col-span-2 grid grid-cols-2 py-2 pr-2 pl-11">
              <div className="lg:text-xl text-lg col-span-2 lg:col-span-2 flex">
                <div className="w-fit sm:w-1/2 lg:w-1/4 mr-2">Mansione:</div>{" "}
                {sessionStorage.getItem("Mansione")}
              </div>
              <div className="lg:text-lg text-lg col-span-2 lg:col-span-1 border-b-2 boder-white my-1"></div>
              <div className="lg:text-xl text-lg col-span-2 lg:col-span-2 flex">
                <div className="w-fit sm:w-1/2 lg:w-1/4 mr-2">Ragione Sociale:</div>{" "}
                {" " + sessionStorage.getItem("RagioneSociale")}
              </div>
              <div className="lg:text-lg text-lg col-span-2 lg:col-span-1 border-b-2 boder-white my-1"></div>
              <div className="lg:text-xl text-lg col-span-2 lg:col-span-2 flex">
                <div className="w-fit sm:w-1/2 lg:w-1/4 mr-2">Data Assunzione:</div>
                {" " +
                  moment(sessionStorage.getItem("DataAssunzione")).format(
                    "DD/MM/YYYY"
                  )}
              </div>
              <div className="lg:text-lg text-lg col-span-2 lg:col-span-1 border-b-2 boder-white my-1"></div>
            </div>
            {/* <div className="lg:text-lg text-lg col-span-2 border-b-2 boder-white mt-2 mb-4"></div> */}
          </div>
        </div>
      </div>
    </>
  );
};
