import React, { useEffect, useState } from "react";
import unsplash from "../../api/unsplash";
import { useNavigate } from "react-router-dom";

import {
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  Tooltip,
  Checkbox,
  Chip,
  Select,
  Card,
  Grid,
  CardContent,
  OutlinedInput,
  MenuItem,
  ListItemText,
  FormControl,
  InputLabel,
  Autocomplete,
  TextField,
  LinearProgress,
} from "@mui/material";
import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import {
  Add,
  Circle,
  Search,
  HideSource,
  Delete,
  Person,
  PersonOff,
  ConnectingAirportsOutlined,
  CheckCircle,
  Cancel,
} from "@mui/icons-material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import GlobalSnackbar from "./Snackbar";
import Allegati from "./Allegati";
import moment from "moment";
import ReactHtmlTableToExcel from "react-html-table-to-excel";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { CustomFooterPersonalized } from "../../utils/CustomFooterPersonalized";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Corsi = () => {
  const [scadenzeCorsi, setScadenzeCorsi] = useState([]);
  const [scadenzeCorsiBackup, setScadenzeCorsiBackup] = useState([]);
  const [filtro, setFiltro] = useState({
    verde: true,
    arancione: true,
    rosso: true,
    viola: true,
    attivi: true,
    passivi: false,
  });
  const [corsi, setCorsi] = useState([]);
  const [corsiSel, setCorsiSel] = useState([]);
  const [lavoratore, setLavoratore] = useState(0);
  const [loaded, setLoaded] = useState(true);
  const [exportData, setExportData] = useState([]);
  const [exportDataBackup, setExportDataBackup] = useState([]);
  const [filterClienti, setFilterClienti] = useState([]);
  const [filterClientiSel, setFilterClientiSel] = useState([]);
  const [columns, setColumns] = useState([]);

  const downloadStorico = () => {
    document.getElementById("printLavoratori").click();
  };
  function CustomToolbar() {
    return (
      <div className="flex flex-wrap justify-center md:justify-start my-2">
        <GridToolbarContainer sx={{ display: "flex", flexWrap: "wrap" }}>
          <GridToolbarQuickFilter placeholder="Cerca..." />

          <Tooltip title="Ok">
            <Checkbox
              icon={<HideSource />}
              checked={filtro.verde}
              checkedIcon={<Circle />}
              sx={{ color: "#51E838!important" }}
              onClick={(e) => filterData(e, "verde", corsiSel)}
            />
          </Tooltip>
          <Tooltip title="In scadenza">
            <Checkbox
              icon={<HideSource />}
              checked={filtro.arancione}
              checkedIcon={<Circle />}
              sx={{ color: "orange!important" }}
              onClick={(e) => filterData(e, "arancione", corsiSel)}
            />
          </Tooltip>
          <Tooltip title="Scaduti">
            <Checkbox
              icon={<HideSource />}
              checked={filtro.rosso}
              checkedIcon={<Circle />}
              sx={{ color: "#ff5757!important" }}
              onClick={(e) => filterData(e, "rosso", corsiSel)}
            />
          </Tooltip>
          <Tooltip title="Corso base mancante">
            <Checkbox
              icon={<HideSource />}
              checked={filtro.viola}
              checkedIcon={<Circle />}
              sx={{ color: "#9059C3!important" }}
              onClick={(e) => filterData(e, "viola", corsiSel)}
            />
          </Tooltip>
        </GridToolbarContainer>
      </div>
    );
  }

  const filterData = (e, colore, corsi) => {
    let f = { ...filtro };
    if (colore) {
      f[colore] = e.target.checked;
      setFiltro(f);
    }
    let scadenzeTemp =
      filterClientiSel.length === 0
        ? scadenzeCorsiBackup
        : scadenzeCorsiBackup.filter((el) =>
            filterClientiSel.includes(el.Cliente)
          );
    let checkArr = []; //metto i colori che in base al flag popolo

    if (f.verde) {
      checkArr.push("verde");
    }
    if (f.arancione) {
      checkArr.push("arancione");
    }
    if (f.rosso) {
      checkArr.push("rosso");
    }

    if (f.viola) {
      checkArr.push("viola");
    }

    if (f.rosso && f.verde && f.viola && f.arancione && corsi.length === 0) {
    } else {
      if (corsi.length > 0) {
        scadenzeTemp = scadenzeTemp.filter(
          (s) =>
            s.Corsi.filter(
              (sf) => checkArr.includes(sf.Colore) && corsi.includes(sf.Ruolo)
            ).length > 0
        );
      } else {
        scadenzeTemp = scadenzeTemp.filter(
          (s) => s.Corsi.filter((sf) => checkArr.includes(sf.Colore)).length > 0
        );
      }
    }

    if (!f.attivi) {
      scadenzeTemp = scadenzeTemp.filter(
        (v) =>
          !(
            !v.DataTermine ||
            moment(v.DataTermine).format("YYYY-MM-DD") >
              moment(new Date()).format("YYYY-MM-DD")
          )
      );
    }
    if (!f.passivi) {
      scadenzeTemp = scadenzeTemp.filter(
        (v) =>
          !v.DataTermine ||
          moment(v.DataTermine).format("YYYY-MM-DD") >
            moment(new Date()).format("YYYY-MM-DD")
      );
    }

    setScadenzeCorsi(scadenzeTemp);

    setExportData(
      exportDataBackup.filter((elem) => {
        return scadenzeTemp.some((ele) => {
          return ele.Lavoratore === elem.ID;
        });
      })
    );
  };

  useEffect(() => {
    (async () => {
      try {
        let responsecorsiClienteExport = await unsplash.get("corsiCliente", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: {
            cliente: sessionStorage.getItem("User"),
            web: true,
          },
        });
        
        let response = await unsplash.get("corsi", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { user: sessionStorage.getItem("User") },
        });
        
        let reduceProps = response.data.data.filter(
          (v) =>
            !v.DataTermine ||
            moment(v.DataTermine).format("YYYY-MM-DD") >
              moment(new Date()).format("YYYY-MM-DD")
        );
        let fltered = reduceProps.map(
          ({
            Lavoratore,
            Sede,
            Nome,
            Cognome,
            Corsi,
            Cliente,
            EmailReferente,
            Somministrato,
          }) => ({
            Lavoratore,
            Sede,
            Nome,
            Cognome,
            Corsi,
            Cliente,
            EmailReferente,
            Somministrato,
          })
        );
        //se l'utente deve vedere solo i dati relativi alla sua sede
        if (sessionStorage.getItem("Dettaglio") === "1") {
          setScadenzeCorsi(
            fltered.filter(
              (el) => sessionStorage.getItem("Email") === el.EmailReferente
            )
          );
          setScadenzeCorsiBackup(
            response.data.data.filter(
              (el) => sessionStorage.getItem("Email") === el.EmailReferente
            )
          );
          //array per filtro multiplo
          const ragSoc = response.data.data
            .filter(
              (el) => sessionStorage.getItem("Email") === el.EmailReferente
            )
            .map((el) => el.Cliente);
          const a = new Set(ragSoc);
          setFilterClienti([...a]);
          //controllo se è nel gruppo Conter
          if (response.data.data[0]?.Gruppo === "Conter") {
            //array per esportazione excel
            setExportData(
              responsecorsiClienteExport.data.data
                ?.filter(
                  (el) => sessionStorage.getItem("Email") === el.EmailReferente
                )
                .map((el) => {
                  //tolgo email referente dalle colonne che verranno esportate nell'excel
                  const { EmailReferente, Gruppo, ...otherData } = el;

                  return {
                    ...otherData,
                    Somministrato: el.Somministrato === 1 ? "SI" : "NO",
                  };
                })
            );
            setExportDataBackup(
              responsecorsiClienteExport.data.data
                ?.filter(
                  (el) => sessionStorage.getItem("Email") === el.EmailReferente
                )
                .map((el) => {
                  //tolgo email referente dalle colonne che verranno esportate nell'excel
                  const { EmailReferente, Gruppo, ...otherData } = el;

                  return {
                    ...otherData,
                    Somministrato: el.Somministrato === 1 ? "SI" : "NO",
                  };
                })
            );
          } else {
            setExportData(
              responsecorsiClienteExport.data.data
                ?.filter(
                  (el) => sessionStorage.getItem("Email") === el.EmailReferente
                )
                .map((el) => {
                  //tolgo le colonne che non verranno esportate nell'excel
                  const {
                    EmailReferente,
                    Gruppo,
                    Somministrato,
                    ...otherData
                  } = el;
                  return otherData;
                })
            );
            setExportDataBackup(
              responsecorsiClienteExport.data.data
                ?.filter(
                  (el) => sessionStorage.getItem("Email") === el.EmailReferente
                )
                .map((el) => {
                  //tolgo le colonne che non verranno esportate nell'excel
                  const {
                    EmailReferente,
                    Gruppo,
                    Somministrato,
                    ...otherData
                  } = el;
                  return otherData;
                })
            );
          }
        } else {
          setScadenzeCorsi(fltered);
          setScadenzeCorsiBackup(response.data.data);
          //array per filtro multiplo
          const ragSoc = response.data.data.map((el) => el.Cliente);
          const a = new Set(ragSoc);
          setFilterClienti([...a]);
          //controllo se è nel gruppo Conter
          if (response.data.data[0]?.Gruppo === "Conter") {
            //array per esportazione excel
            setExportData(
              responsecorsiClienteExport.data.data.map((el) => {
                //tolgo email referente dalle colonne che verranno esportate nell'excel
                const { EmailReferente, Gruppo, ...otherData } = el;
                return {
                  ...otherData,
                  Somministrato: el.Somministrato === 1 ? "SI" : "NO",
                };
              })
            );
            setExportDataBackup(
              responsecorsiClienteExport.data.data.map((el) => {
                //tolgo email referente dalle colonne che verranno esportate nell'excel
                const { EmailReferente, Gruppo, ...otherData } = el;
                return {
                  ...otherData,
                  Somministrato: el.Somministrato === 1 ? "SI" : "NO",
                };
              })
            );
          } else {
            setExportData(
              responsecorsiClienteExport.data.data.map((el) => {
                //tolgo email referente dalle colonne che verranno esportate nell'excel
                const { EmailReferente, Gruppo, Somministrato, ...otherData } =
                  el;
                return otherData;
              })
            );
            setExportDataBackup(
              responsecorsiClienteExport.data.data.map((el) => {
                //tolgo email referente dalle colonne che verranno esportate nell'excel
                const { EmailReferente, Gruppo, Somministrato, ...otherData } =
                  el;
                return otherData;
              })
            );
          }
        }

        setLoaded(false);
        let responsecorsiCliente = await unsplash.get(
          "corsiCliente",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          } //props.idAzienda}}
        );

        setCorsi(responsecorsiCliente.data.data);

        setColumns([
          {
            field: "Sede",
            headerName: "Sede",
            flex: 1,
            headerAlign: "center",
            align: "center",
            sort: "asc",
          },
          {
            field: "Cliente",
            headerName: "Ragione Sociale",
            flex: 1,
            headerAlign: "center",
            align: "center",
            sort: "asc",
          },
          {
            field: "Cognome",
            headerName: "Cognome",
            flex: 1,
            headerAlign: "center",
            align: "center",
            sort: "asc",
          },
          {
            field: "Nome",
            headerName: "Nome",
            flex: 1,
            headerAlign: "center",
            align: "center",
            sort: "asc",
          },
          //condizione per vedere se il gruppo del cliente è Conter
          ...(response.data.data[0]?.Gruppo === "Conter"
            ? [
                {
                  field: "Somministrato",
                  headerName: "Somministrato",
                  headerAlign: "center",
                  align: "center",
                  width: 100,
                  renderCell: (params) => {
                    if (params.row.Somministrato === 1)
                      return <CheckCircle sx={{ color: "#51E838" }} />;
                    if (
                      params.row.Somministrato === 0 ||
                      params.row.Somministrato === null
                    )
                      return <Cancel sx={{ color: "#ff5757" }} />;
                  },
                },
              ]
            : []),

          {
            field: "Corsi",
            headerName: "Corsi",
            filterable: false,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sort: "asc",
            renderCell: (params) => {
              return (
                <div>
                  {params.row.Corsi.map((c) => {
                    let colore = "",
                      testo = "";
                    let date = new Date();
                    let show = true;
                    // add a day
                    date.setDate(date.getDate() + 90);
                    if (c.BaseOk === 0) {
                      colore = "#9059C3";
                      testo = "Corso Base mancante";
                      if (filtro.viola === true) {
                        show = true;
                      } else {
                        show = false;
                      }
                    } else if (c.Scadenza === "") {
                      colore = "#51E838";
                      testo = "Nessuna scadenza";
                      if (filtro.verde === true) {
                        show = true;
                      } else {
                        show = false;
                      }
                    } else if (
                      moment(c.Scadenza).format("YYYY-MM-DD") <
                      moment(new Date()).format("YYYY-MM-DD")
                    ) {
                      colore = "#ff5757";
                      testo =
                        "Scaduto il " + moment(c.Scadenza).format("DD/MM/YYYY");

                      if (filtro.rosso === true) {
                        show = true;
                      } else {
                        show = false;
                      }
                    } else if (
                      moment(c.Scadenza).format("YYYY-MM-DD") <
                      moment(date).format("YYYY-MM-DD")
                    ) {
                      colore = "orange";
                      testo =
                        "In scadenza il " +
                        moment(c.Scadenza).format("DD/MM/YYYY");
                      if (filtro.arancione === true) {
                        show = true;
                      } else {
                        show = false;
                      }
                    } else {
                      colore = "#51E838";
                      testo =
                        "Scadrà il " + moment(c.Scadenza).format("DD/MM/YYYY");
                      if (filtro.verde === true) {
                        show = true;
                      } else {
                        show = false;
                      }
                    }
                    let showCorso = true;
                    if (corsiSel.length > 0 && !corsiSel.includes(c.Ruolo)) {
                      showCorso = false;
                    }
                    if (show && showCorso) {
                      return (
                        <Tooltip title={testo}>
                          <Chip
                            label={c.Ruolo}
                            size="small"
                            sx={{
                              width: "70px",
                              marginLeft: "2px",
                              backgroundColor: colore,
                              color: "white",
                            }}
                          />
                        </Tooltip>
                      );
                    }
                  })}
                </div>
              );
            },
            valueGetter: (params) => {
              const toPrint = params.row.Corsi.map((c) => {
                return (
                  c.Ruolo +
                  ": " +
                  (c.Scadenza ? c.Scadenza : "Nessuna Scadenza")
                );
              });
              let stringToPrint = toPrint.toString();
              return stringToPrint;
            },
          },
        ]);
      } catch (error) {
        console.error(error.message);
      }
    })();
  }, []);

  const handleChangeCorsiSel = (event) => {
    setCorsiSel(event.target.value);
    filterData("", "", event.target.value);
  };

  return (
    <>
      {/* <!-- component --> */}
      <div className="hidden">
        <ReactHtmlTableToExcel
          id="printLavoratori"
          className="download-table-xls-button-none"
          table="table-to-xls"
          filename={"Scadenzario"}
          sheet={"Scadenzario"}
          style={{ display: "None!important" }}
          buttonText="Download Log"
        />
      </div>
      {exportData?.length !== 0 ? (
        <table
          id="table-to-xls"
          style={{ marginTop: 10, display: "none" }}
          cellSpacing={0}
          border={0}
        >
          <tr>
            {exportData?.length > 0
              ? Object.keys(exportData[0])?.map((c, index) => (
                  <>{c === "ID" ? <></> : <th key={index}>{c}</th>}</>
                ))
              : ""}
          </tr>
          {exportData?.map((c, index) => (
            <tr key={index}>
              {Object.keys(c).map((o, index) => (
                <>
                  {o === "ID" ? (
                    <></>
                  ) : o === "Nome" ||
                    o === "Cognome" ||
                    o === "Sede" ||
                    o === "Somministrato" ? (
                    <td key={index}>{c[o]}</td>
                  ) : c[o]?.Data ? (
                    <td
                      key={index}
                      style={{ background: c[o]?.ColoreScadenza }}
                    >
                      {moment(c[o].Data).format("DD/MM/YYYY")}
                    </td>
                  ) : (
                    <td
                      key={index}
                      style={{ background: c[o]?.ColoreScadenza }}
                    ></td>
                  )}
                </>
              ))}
            </tr>
          ))}
        </table>
      ) : (
        <></>
      )}

      <div className="flex flex-wrap flex-1 h-screen bg-white p-4  shadow-lg rounded-md border border-slate-200">
        <PopUpCorsi lavoratore={lavoratore} />
        <div className="flex w-full items-center justify-between flex-wrap md:flex-nowrap gap-4">
          <div className="w-full md:w-1/2 flex gap-4 flex-wrap md:flex-nowrap">
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={filterClienti}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              onChange={(event, newValue) => {
                setFilterClientiSel(newValue);
                setFiltro({
                  verde: true,
                  arancione: true,
                  rosso: true,
                  viola: true,
                  attivi: true,
                  passivi: false,
                });
                if (newValue.length > 0) {
                  let filtered = scadenzeCorsiBackup.filter((el) =>
                    newValue.includes(el.Cliente)
                  );
                  setScadenzeCorsi(filtered);
                  setExportData(
                    exportDataBackup.filter((elem) => {
                      return filtered.some((ele) => {
                        return ele.Lavoratore === elem.ID;
                      });
                    })
                  );
                } else {
                  setScadenzeCorsi(scadenzeCorsiBackup);
                  setExportData(exportDataBackup);
                }
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              size="medium"
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Clienti"
                  placeholder="Ragioni Sociali"
                />
              )}
            />
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="demo-multiple-checkbox-label" size="normal">
                Corsi
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={corsiSel}
                onChange={handleChangeCorsiSel}
                input={<OutlinedInput label="Corsi" size="normal" />}
                renderValue={(selected) => selected.join(", ")}
              >
                {corsi.map((c) => (
                  <MenuItem key={c.Codice} value={c.Codice}>
                    <Checkbox checked={corsiSel.indexOf(c.Codice) > -1} />
                    <ListItemText primary={c.Codice} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="w-full xl:w-1/2 flex md:flex-nowrap flex-wrap justify-start xl:justify-between">
            <button onClick={downloadStorico} className="btnExport">
              <FileDownloadIcon /> ESPORTA SCADENZARIO CORSI
            </button>
          </div>
        </div>
        <div className="h-[70vh] md:h-[80vh] w-full">
          <DataGridPro
            sx={{ fontSize: 12 }}
            loading={loaded}
            components={{
              Toolbar: CustomToolbar,
              Footer: CustomFooterPersonalized,
            }}
            slots={{
              loadingOverlay: LinearProgress,
            }}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            rows={scadenzeCorsi}
            columns={columns}
            getRowId={(row) => row.Lavoratore}
            pageSize={100}
            disableSelectionOnClick
            onRowDoubleClick={async (params) => {
              await setLavoratore(params.row.Lavoratore);
              document.getElementById("allegati").click();
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Corsi;

const PopUpCorsi = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        aria-label="edit"
        id="allegati"
        onClick={handleClickOpen}
        sx={{ display: "None" }}
      >
        Allegati
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={"md"}
        maxWidth={"md"}
      >
        <div className="py-6 px-6 lg:px-8">
          <h3 className="mb-4 text-xl font-medium text-center text-black">
            Storico
          </h3>
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="absolute top-4 right-5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="authentication-modal"
          >
            <svg
              aria-hidden="true"
              className="w-7 h-7"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="h-[400px] md:h-[500px] w-full">
            <Allegati
              idLavoratore={props.lavoratore}
              id={props.lavoratore}
              colonna="Lavoratore"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

/*

 let responseCorsi =  await unsplash
    .get('corsi',{ headers: {
      'Authorization': `Bearer ${localStorage.getItem("jwt")}` 
    },params:{user:sessionStorage.getItem("User")}}
     );

*/
