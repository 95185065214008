import { Block } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Barcode from "react-barcode";
import JsPDF from "jspdf";
import { Print } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import moment from "moment";
const Idoneita = () => {
  const location = useLocation();

  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css"
      />
      <link
        rel="stylesheet"
        href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css"
        integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO"
        crossorigin="anonymous"
      ></link>
      <div
        className="hide-from-print"
        style={{ backgroundColor: "#2265B1", padding: "10px" }}
      >
        <Grid item xs={1}>
          <Button
            variant="contained"
            onClick={() => window.print()}
            endIcon={<Print />}
            id="printPageButton"
            className="ButtonMillerWhite"
            sx={{ marginLeft: "10px", marginTop: "10px", color: "" }}
          >
            Stampa
          </Button>
        </Grid>
        <center>
          {location.state.visite.map(
            (
              v //ricordati che questa parte è solo quella visiva, la stampa è giù
            ) => (
              <div
                className="hide-from-print"
                style={{
                  width: "300mm",
                  margin: "10mm 16mm 27mm 16mm",
                  backgroundColor: "white",
                  padding: "10px",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={6} sx={{ textAlign: "left" }}>
                      Medico competente: <b>Dr. {v.Medico}</b>
                      <br />
                      Data delle visita:{" "}
                      <b>{moment(v.Data).format("DD/MM/YYYY")}</b> <br />
                      Società:<b> {v.Cliente}</b>
                      <br />
                      Dipendente:{" "}
                      <b>{v.LavoratoreNome + " " + v.LavoratoreCognome} </b>
                      <br />
                      {v.Gruppo === "Conter" ? "Classe di rischio" : "Mansione"}
                      : <b>{v.Mansione}</b> <br />
                      {v.RagioneSocialeDa ? (
                        <>
                          Distaccato da:<b> {v.RagioneSocialeDa}</b>
                        </>
                      ) : (
                        ""
                      )}
                      {v.Distaccato ? (
                        <>
                          Distaccato:<b> {v.Distaccato}</b>
                        </>
                      ) : (
                        ""
                      )}
                      {v.Gruppo === "Conter" ? (
                        ""
                      ) : v.Somministrato === 1 ? ( //aggiungo condizione se clienti.gruppi === "Counter" nascondo
                        <>
                          <b>
                            Lavoratore somministrato a termine da altra Società
                          </b>
                        </>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                      <Barcode
                        value={v.ID}
                        format="CODE39"
                        height="40"
                        displayValue={false}
                        width="4"
                      ></Barcode>
                      <h3>GIUDIZIO DI IDONEITA' ALLA MANSIONE</h3>
                      Codice Fiscale: <b>{v.CF} </b> <br />
                      Data di Nascita:{" "}
                      <b>
                        {v.DataDiNascita
                          ? moment(v.DataDiNascita).format("DD/MM/YYYY")
                          : ""}
                      </b>{" "}
                      <br />
                    </Grid>
                    <Grid item xs={12} textAlign={"left"}>
                      <h3 className="font-semibold text-lg mb-1">
                        Fattori di rischio valutati
                      </h3>
                      <div className="grid grid-cols-2 gap-2 w-full p-2 rounded-md border-[1.5px] border-gray-800 mb-2 text-sm">
                        {v.DescRischi.map((rischio, index) => {
                          return (
                            <div
                              id={index}
                              className={`flex col-span-2 gap-2 px-1 `}
                              /* className={`flex w-full gap-2 px-1 ${
                                v.DescRischi.length !== index + 1
                                  && "border-b-2 border-gray-600"
                              }`} */
                            >
                              <input type="checkbox" defaultChecked />
                              {rischio}
                            </div>
                          );
                        })}
                        <div className={`flex col-span-2 gap-1 px-1 `}>
                          Altro:
                          <hr className="h-2 w-full border-[1.5px] border-gray-800 mx-2 mb-0" />
                        </div>
                      </div>
                      Tipologia Visita: <b>{v?.TipoVDesc}</b> <br />
                      {
                        //#region Terzo pezzo
                      }
                      <div className="font-semibold text-base mt-2">
                        Note
                        <hr className="h-2 border-[1.5px] border-gray-800 mx-2" />
                      </div>
                      {
                        //#endregion
                      }
                      Periodicità: <b>{v?.ScadenzaAnni}</b> <br />
                      <div className="font-semibold text-base mt-2">
                        Note
                        <hr className="h-2 border-[1.5px] border-gray-800 mx-2" />
                      </div>
                      <h3 className="font-semibold text-lg mb-1">
                        Accertamenti previsti
                      </h3>
                      <div className="grid grid-cols-2 gap-2 w-full p-2 rounded-md border-[1.5px] border-gray-800 mb-2 text-sm">
                        {v.Accertamenti?.split(",").map((acc, index) => {
                          return (
                            <div
                              id={index}
                              className={`flex col-span-1 gap-2 px-1 `}
                            >
                              <input type="checkbox" />
                              {acc}
                            </div>
                          );
                        })}
                        <div className={`flex col-span-2 gap-1 px-1 `}>
                          Altro:
                          <hr className="h-2 w-full border-[1.5px] border-gray-800 mx-2 mb-0" />
                        </div>
                      </div>
                      <h3 className="font-semibold text-lg mb-1">Giudizio</h3>
                      <div className="grid grid-cols-3 gap-2 w-full p-2 rounded-md border-[1.5px] border-gray-800 mb-2 text-sm">
                        <div className={`flex col-span-1 gap-2 px-1 `}>
                          <input type="checkbox" />
                          Idoneo
                        </div>
                        <div className={`flex col-span-1 gap-2 px-1 `}>
                          <input type="checkbox" />
                          Non idoneo permanentemente
                        </div>
                        <div className={`flex col-span-1 gap-2 px-1 `}>
                          <input type="checkbox" />
                          Non idoneo temporaneo
                        </div>
                        <div className="col-span-3 grid grid-cols-2">
                          <div className={`flex col-span-1 gap-2 px-1 `}>
                            <input type="checkbox" />
                            Idoneo con prescrizioni o limitazioni permanenti
                          </div>
                          <div className={`flex col-span-1 gap-2 px-1 `}>
                            <input type="checkbox" />
                            Idoneo con prescrizioni o limitazioni temporanee
                          </div>
                        </div>
                        <div className={`flex col-span-3 gap-2 px-1 `}>
                          <input type="checkbox" />
                          IDONEO AI SENSI DEL D.LGS. 131/2003 e del protocollo
                          di intesa GU266/2007 art. 1,2,3 allo svolgimentodi
                          mansioni di cui all'allegato
                        </div>
                      </div>
                      <div className="font-semibold text-base">
                        Note Giudizio
                        <hr className="h-2 border-[1.5px] border-gray-800 mx-2" />
                        <hr className="h-2 border-[1.5px] border-gray-800 mx-2" />
                        <hr className="h-2 border-[1.5px] border-gray-800 mx-2" />
                        <hr className="h-2 border-[1.5px] border-gray-800 mx-2 mb-2" />
                      </div>
                      <div className="mt-2 text-sm w-full text-justify">
                        Avverso al giudizio di idoneità specifica è ammesso
                        ricorso entro 30gg. all'organo di vigilanza territoriale
                        competente (D.Lgs 81/08, art. 41 comma 9).
                      </div>
                      <div className="mt-2 text-sm w-full text-justify">
                        TRATTAMENTO DEI DATI PARTICOLARI (Reg. Ue 2016/679)
                        Prendo atto che il trattamento dei miei dati personali,
                        compresi i dati facenti parte delle categorie
                        particolari di dati personali, sono trattati per
                        finalità di medicina del lavoro ed emissione del
                        giudizio di idoneità alla mansione. Tale trattamento è
                        lecito come consentito dall’art. 9 comma 2 paragrafo h.
                        Confermo inoltre che le notizie anamnestiche fornite
                        sono complete e veritiere. Confermo infine che sono
                        stato informato dal medico competente sul significato
                        degli accertamenti sanitari a cui sono stato sottoposto
                        e sui risultati degli stessi.
                      </div>
                      {v.FirmaDL === 1 ? (
                        <table
                          style={{
                            width: "100%",
                            marginTop: "20px",
                          }}
                        >
                          <tr style={{ width: "100%" }}>
                            <td
                              style={{
                                width: "40%",
                                fontSize: "14px",
                              }}
                            >
                              Data di trasmissione del giudizio al lavoratore:
                              <span style={{ marginLeft: "20px" }}>
                                {"   "}/
                              </span>
                              <span style={{ marginLeft: "20px" }}>
                                {"   "}/
                              </span>
                            </td>{" "}
                            <td style={{ width: "50%", fontSize: "14px" }}>
                              Data di trasmissione del giudizio al datore di
                              lavoro:
                              <span style={{ marginLeft: "20px" }}>
                                {"   "}/
                              </span>
                              <span style={{ marginLeft: "20px" }}>
                                {"   "}/
                              </span>
                            </td>
                          </tr>
                          <tr style={{ width: "100%", height: "20px" }}></tr>
                          <tr style={{ width: "100%" }}>
                            <td style={{ width: "33%", textAlign: "center" }}>
                              Firma del Medico{" "}
                              <hr
                                style={{
                                  marginTop: "40px",
                                  width: "98%",
                                  border: "none",
                                  borderTop: "solid 1px black",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              />
                            </td>{" "}
                            <td style={{ width: "33%", textAlign: "center" }}>
                              Firma del Dipendente{" "}
                              <hr
                                style={{
                                  marginTop: "40px",
                                  width: "98%",
                                  border: "none",
                                  borderTop: "solid 1px black",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              />
                            </td>
                            <td style={{ width: "33%", textAlign: "center" }}>
                              Firma del datore di lavoro{" "}
                              <hr
                                style={{
                                  marginTop: "40px",
                                  width: "98%",
                                  border: "none",
                                  borderTop: "solid 1px black",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              />
                            </td>
                          </tr>
                        </table>
                      ) : (
                        <table
                          style={{
                            width: "100%",
                            marginTop: "20px",
                          }}
                        >
                          <tr style={{ width: "100%" }}>
                            <td
                              style={{
                                width: "40%",
                                fontSize: "14px",
                              }}
                            >
                              Data di trasmissione del giudizio al lavoratore:
                              <span style={{ marginLeft: "20px" }}>
                                {"   "}/
                              </span>
                              <span style={{ marginLeft: "20px" }}>
                                {"   "}/
                              </span>
                            </td>{" "}
                            <td style={{ width: "50%", fontSize: "14px" }}>
                              Data di trasmissione del giudizio al datore di
                              lavoro:
                              <span style={{ marginLeft: "20px" }}>
                                {"   "}/
                              </span>
                              <span style={{ marginLeft: "20px" }}>
                                {"   "}/
                              </span>
                            </td>
                          </tr>
                          <tr style={{ width: "100%", height: "20px" }}></tr>
                          <tr style={{ width: "100%" }}>
                            <td style={{ width: "50%", textAlign: "center" }}>
                              Firma del Medico{" "}
                              <hr
                                style={{
                                  marginTop: "40px",
                                  width: "50%",
                                  border: "none",
                                  marginRight: "auto",
                                  marginLeft: "auto",
                                  borderTop: "solid 1px black",
                                }}
                              />
                            </td>{" "}
                            <td style={{ width: "50%", textAlign: "center" }}>
                              Firma del Dipendente{" "}
                              <hr
                                style={{
                                  marginTop: "40px",
                                  width: "50%",
                                  border: "none",
                                  marginRight: "auto",
                                  marginLeft: "auto",
                                  borderTop: "solid 1px black",
                                }}
                              />
                            </td>
                          </tr>
                        </table>
                      )}
                    </Grid>
                  </Grid>
                  <br></br>
                  <br></br>
                </Box>
              </div>
            )
          )}
        </center>
      </div>
      <div className="hide-from-screen">
        {location.state.visite.map((v, index) => (
          <Box sx={{ flexGrow: 1, marginTop: index == 0 ? "0px" : "80px" }}>
            <Grid container spacing={3}>
              <Grid item xs={6} sx={{ textAlign: "left" }}>
                Medico competente:<b> Dr. {v.Medico}</b>
                <br />
                Data delle visita: <b>
                  {moment(v.Data).format("DD/MM/YYYY")}
                </b>{" "}
                <br />
                Società:<b> {v.Cliente}</b>
                <br />
                Dipendente:{" "}
                <b>{v.LavoratoreNome + " " + v.LavoratoreCognome} </b>
                <br />
                {v.Gruppo === "Conter" ? "Classe di rischio" : "Mansione"}:{" "}
                <b>{v.Mansione}</b> <br />
                {v.RagioneSocialeDa ? (
                  <>
                    Distaccato da:<b> {v.RagioneSocialeDa}</b>
                  </>
                ) : (
                  ""
                )}
                {v.Distaccato ? (
                  <>
                    Distaccato:<b> {v.Distaccato}</b>
                  </>
                ) : (
                  ""
                )}
                {v.Gruppo === "Conter" ? (
                  ""
                ) : v.Somministrato === 1 ? (
                  <>
                    <b>Lavoratore somministrato a termine da altra Società</b>
                  </>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <Barcode
                  value={v.ID}
                  format="CODE39"
                  height="40"
                  displayValue={false}
                  width="4"
                ></Barcode>
                <h3>GIUDIZIO DI IDONEITA' ALLA MANSIONE</h3>
                Codice Fiscale: <b>{v.CF} </b> <br />
                Data di Nascita:{" "}
                <b>
                  {v.DataDiNascita
                    ? moment(v.DataDiNascita).format("DD/MM/YYYY")
                    : ""}
                </b>{" "}
                <br />
                {/* Specifica: <b>{} </b> */}
              </Grid>
              <Grid item xs={12}>
                <div className="min-h-[900px]">
                  <h3 className="font-semibold text-lg mb-1">
                    Fattori di rischio valutati
                  </h3>
                  <div className="grid grid-cols-2 gap-1 w-full rounded-md p-2 border-[1.5px] border-gray-800 mb-4 text-sm">
                    {v.DescRischi.map((rischio, index) => {
                      return (
                        <div
                          id={index}
                          className={`flex col-span-2 gap-x-2 px-1 `}
                          /* className={`flex w-full gap-2 px-1 ${
                                v.DescRischi.length !== index + 1
                                  && "border-b-2 border-gray-600"
                              }`} */
                        >
                          <input type="checkbox" defaultChecked />
                          {rischio}
                        </div>
                      );
                    })}
                    <div className={`flex col-span-2 gap-1 px-1 `}>
                      Altro:
                      <hr className="h-2 w-full border-[1.5px] border-gray-800 mx-2 mb-0" />
                    </div>
                  </div>
                  Tipologia Visita: <b>{v?.TipoVDesc}</b> <br />
                  {
                    //#region Terzo pezzo
                  }
                  <div className="font-semibold text-base mt-2">
                    Note
                    <hr className="h-2 border-[1.5px] border-gray-800 mx-2" />
                  </div>
                  {
                    //#endregion
                  }
                  Periodicità: <b>{v?.ScadenzaAnni}</b> <br />
                  <div className="font-semibold text-base mt-2">
                    Note
                    <hr className="h-2 border-[1.5px] border-gray-800 mx-2" />
                  </div>
                  <h3 className="font-semibold text-lg mb-1">
                    Accertamenti previsti
                  </h3>
                  <div className="grid grid-cols-2 gap-1 w-full p-2 rounded-md border-[1.5px] border-gray-800 mb-4 text-sm">
                    {v.Accertamenti?.split(",").map((acc, index) => {
                      return (
                        <div
                          id={index}
                          className={`flex col-span-1 gap-2 px-1 `}
                        >
                          <input type="checkbox" />
                          {acc}
                        </div>
                      );
                    })}
                    <div className={`flex col-span-2 gap-1 px-1 `}>
                      Altro:
                      <hr className="h-2 w-full border-[1.5px] border-gray-800 mx-2 mb-0" />
                    </div>
                  </div>
                  <h3 className="font-semibold text-lg mb-1">Giudizio</h3>
                  <div className="grid grid-cols-3 gap-3 w-full py-3 px-2 rounded-md border-[1.5px] border-gray-800 mb-4 text-sm">
                    <div className={`flex col-span-1 gap-2 px-1 `}>
                      <input type="checkbox" />
                      Idoneo
                    </div>
                    <div className={`flex col-span-1 gap-2 px-1 `}>
                      <input type="checkbox" />
                      Non idoneo permanentemente
                    </div>
                    <div className={`flex col-span-1 gap-2 px-1 `}>
                      <input type="checkbox" />
                      Non idoneo temporaneo
                    </div>
                    <div className="col-span-3 grid grid-cols-2">
                      <div className={`flex col-span-1 gap-2 px-1 `}>
                        <input type="checkbox" />
                        Idoneo con prescrizioni o limitazioni permanenti
                      </div>
                      <div className={`flex col-span-1 gap-2 px-1 `}>
                        <input type="checkbox" />
                        Idoneo con prescrizioni o limitazioni temporanee
                      </div>
                    </div>
                    <div className={`flex col-span-3 gap-2 px-1 `}>
                      <input type="checkbox" />
                      IDONEO AI SENSI DEL D.LGS. 131/2003 e del protocollo di
                      intesa GU266/2007 art. 1,2,3 allo svolgimentodi mansioni
                      di cui all'allegato
                    </div>
                  </div>
                  <div className="font-semibold text-base">
                    Note Giudizio
                    <hr className="h-2 border-[1.5px] border-gray-800 mx-2" />
                    <hr className="h-2 border-[1.5px] border-gray-800 mx-2" />
                    <hr className="h-2 border-[1.5px] border-gray-800 mx-2" />
                    <hr className="h-2 border-[1.5px] border-gray-800 mx-2 mb-2" />
                  </div>
                </div>

                <div className="mt-2 text-base w-full text-justify">
                  Avverso al giudizio di idoneità specifica è ammesso ricorso
                  entro 30gg. all'organo di vigilanza territoriale competente
                  (D.Lgs 81/08, art. 41 comma 9).
                </div>
                <div className="mt-1 text-base w-full text-justify">
                  TRATTAMENTO DEI DATI PARTICOLARI (Reg. Ue 2016/679) Prendo
                  atto che il trattamento dei miei dati personali, compresi i
                  dati facenti parte delle categorie particolari di dati
                  personali, sono trattati per finalità di medicina del lavoro
                  ed emissione del giudizio di idoneità alla mansione. Tale
                  trattamento è lecito come consentito dall’art. 9 comma 2
                  paragrafo h. Confermo inoltre che le notizie anamnestiche
                  fornite sono complete e veritiere. Confermo infine che sono
                  stato informato dal medico competente sul significato degli
                  accertamenti sanitari a cui sono stato sottoposto e sui
                  risultati degli stessi.
                </div>
                {v.FirmaDL === null ? (
                  <table
                    style={{
                      width: "100%",
                      marginTop: "30px",
                    }}
                  >
                    <tr style={{ width: "100%" }}>
                      <tr style={{ width: "100%" }}>
                        <td
                          style={{
                            width: "40%",
                            fontSize: "14px",
                          }}
                        >
                          Data di trasmissione del giudizio al lavoratore:
                          <span style={{ marginLeft: "20px" }}>{"   "}/</span>
                          <span style={{ marginLeft: "20px" }}>{"   "}/</span>
                        </td>{" "}
                        <td style={{ width: "50%", fontSize: "14px" }}>
                          Data di trasmissione del giudizio al datore di lavoro:
                          <span style={{ marginLeft: "20px" }}>{"   "}/</span>
                          <span style={{ marginLeft: "20px" }}>{"   "}/</span>
                        </td>
                      </tr>
                      <tr style={{ width: "100%", height: "20px" }}></tr>
                      <td style={{ width: "33%", textAlign: "center" }}>
                        Firma del Medico{" "}
                        <hr
                          style={{
                            marginTop: "40px",
                            width: "98%",
                            border: "none",
                            borderTop: "solid 1px black",
                            marginRight: "auto",
                            marginLeft: "auto",
                          }}
                        />
                      </td>{" "}
                      <td style={{ width: "33%", textAlign: "center" }}>
                        Firma del Dipendente{" "}
                        <hr
                          style={{
                            marginTop: "40px",
                            width: "98%",
                            border: "none",
                            borderTop: "solid 1px black",
                            marginRight: "auto",
                            marginLeft: "auto",
                          }}
                        />
                      </td>
                      <td style={{ width: "33%", textAlign: "center" }}>
                        Firma del datore di lavoro{" "}
                        <hr
                          style={{
                            marginTop: "40px",
                            width: "98%",
                            border: "none",
                            borderTop: "solid 1px black",
                            marginRight: "auto",
                            marginLeft: "auto",
                          }}
                        />
                      </td>
                    </tr>
                  </table>
                ) : (
                  <table
                    style={{
                      width: "100%",
                      marginTop: "30px",
                    }}
                  >
                    <tr style={{ width: "100%" }}>
                      <td
                        style={{
                          width: "40%",
                          fontSize: "14px",
                        }}
                      >
                        Data di trasmissione del giudizio al lavoratore:
                        <span style={{ marginLeft: "20px" }}>{"   "}/</span>
                        <span style={{ marginLeft: "20px" }}>{"   "}/</span>
                      </td>{" "}
                      <td style={{ width: "50%", fontSize: "14px" }}>
                        Data di trasmissione del giudizio al datore di lavoro:
                        <span style={{ marginLeft: "20px" }}>{"   "}/</span>
                        <span style={{ marginLeft: "20px" }}>{"   "}/</span>
                      </td>
                    </tr>
                    <tr style={{ width: "100%", height: "20px" }}></tr>
                    <tr style={{ width: "100%" }}>
                      <td style={{ width: "50%", textAlign: "center" }}>
                        Firma del Medico{" "}
                        <hr
                          style={{
                            marginTop: "40px",
                            width: "50%",
                            border: "none",
                            marginRight: "auto",
                            marginLeft: "auto",
                            borderTop: "solid 1px black",
                          }}
                        />
                      </td>{" "}
                      <td style={{ width: "50%", textAlign: "center" }}>
                        Firma del Dipendente{" "}
                        <hr
                          style={{
                            marginTop: "40px",
                            width: "50%",
                            border: "none",
                            marginRight: "auto",
                            marginLeft: "auto",
                            borderTop: "solid 1px black",
                          }}
                        />
                      </td>
                    </tr>
                  </table>
                )}
              </Grid>
            </Grid>
          </Box>
        ))}
      </div>
    </>
  );
};

export default Idoneita;
