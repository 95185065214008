import React, { useEffect, useRef, useState } from "react";
/* const { getJsDateFromExcel } = require("excel-date-to-js"); */
import {
  Download,
  Upload,
  ArrowDropUp,
  ConnectingAirportsOutlined,
  Circle,
} from "@mui/icons-material";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Zoom,
  DialogActions,
  ButtonGroup,
  MenuItem,
} from "@mui/material";
import { Close, Check } from "@mui/icons-material";
import { writeFile, utils } from "xlsx";
import * as XLSX from "xlsx";

import * as excelJs from "exceljs";

import GlobalSnackbar from "../components/Snackbar";

import Grow from "@mui/material/Grow";
import EditIcon from "@mui/icons-material/Edit";
import GroupsIcon from "@mui/icons-material/Groups";
import PrintIcon from "@mui/icons-material/Print";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import TableChartIcon from "@mui/icons-material/TableChart";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import unsplash from "../api/unsplash";
import moment from "moment";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

export const Excel = ({
  fileName,
  handleSave,
  filterColumn,
  sheets,
  excelHeader,
  requiredColumn,
  getAllTableData,
  thereIsData,
  reloadData,
}) => {
  const [open, setOpen] = useState(false);

  const [openButton, setOpenButton] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const alphabet = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "AA",
    "AB",
    "AC",
    "AD",
    "AE",
    "AF",
    "AG",
    "AH",
    "AI",
    "AJ",
    "AK",
    "AL",
    "AM",
    "AN",
    "AO",
    "AP",
    "AQ",
    "AR",
    "AS",
    "AT",
    "AU",
    "AV",
    "AW",
    "AX",
    "AY",
    "AZ",
  ];

  const options = [
    {
      icon: <Download />,
      text: "SCARICA ESEMPIO",
    },
    {
      icon: <Upload />,
      text: "IMPORTA",
    },
  ];

  // submit state
  const [excelDataImported, setExcelDataImported] = useState([]);
  const [excelDataDuplicated, setExcelDataDuplicated] = useState([]);
  const [excelDataMissingVal, setExcelDataMissingVal] = useState([]);
  const [excelDataMissingId, setExcelDataMissingId] = useState([]);

  const [changeDialog, setChangeDialog] = useState(false);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleClose = () => {
    reloadData();
    setExcelDataImported([]);
    setOpen(false);
    setChangeDialog(false);
  };

  const handleMenuItemClick = (event, index) => {
    document.getElementById(`${index}`).click();
    //setSelectedIndex(index);
    setOpenButton(false);
  };

  const handleToggleButton = () => {
    setOpenButton(!openButton);
  };

  const handleCloseButton = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenButton(false);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const stylingCell = async (worksheet, cols) => {
    worksheet.getCell(cols).font = {
      name: "Inter",
      size: worksheet === 10,
      color: { argb: "FFFFFF" },
      bold: true,
    };
    worksheet.getCell(cols).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    worksheet.getCell(cols).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "11526F" },
    };
    worksheet.columns.forEach(function (column, i) {
      let maxLength = 0;
      column["eachCell"]({ includeEmpty: true }, function (cell) {
        var columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength;
    });
  };

  //Passo Stringa interna all'excelHeader e mi ritorna la colonna di riferimento
  const handleAlphabetCol = (wordToTranslate) => {
    let stringToTranslate = "";
    excelHeader.find((word, index) => {
      if (word === wordToTranslate) {
        return (stringToTranslate = alphabet[index]);
      }
    });
    return stringToTranslate;
  };

  const removeDuplicated = async (arr, dataToFilter) => {
    const removeFileDuplicated = await arr?.map(
      ({ dataToFilter }) => dataToFilter
    );
    const filtered = arr?.filter(({ dataToFilter }, index) =>
      removeFileDuplicated.includes(dataToFilter, index + 1)
    );
    return filtered;
  };

  const exportToExcel = async () => {
    const workbook = new excelJs.Workbook();

    const ws = workbook.addWorksheet("Dati");
    ws.pageSetup.printArea = "A1:G20";
    ws.addRow(excelHeader);

    let rrr = [];
    let nomePagina = "";
    let nomeColonna = "";
    let letteraColonna = "";

    if (sheets?.length > 0) {
      for (let index = 0; index < sheets.length; index++) {
        const el = sheets[index];
        nomePagina = Object.getOwnPropertyNames(el)[0];
        nomeColonna = el.colonnaSel.nome;
        letteraColonna = el.colonnaSel.lettera;
        rrr = el[nomePagina]?.map((el) => {
          return [el[nomeColonna]];
        });

        let filtered = [...rrr];
        if (el.exist) {
          const neee = filtered.map((el) => el[0]);
          filtered = [...new Set(neee)];
          filtered = filtered.map((el) => [el]);
        }

        //CREAZIONE SHEET ANAGRAFICA
        const ws2 = workbook.addWorksheet(nomePagina.toString());
        ws2.addRow([nomeColonna]);
        filtered.map((el) => ws2.addRow(el));
        stylingCell(ws2, `A1`);
        //CREAZIONE DROPDOWN LIST
        ws.dataValidations.model[`${letteraColonna}2:${letteraColonna}9999`] = {
          allowBlank: false,
          formulae: [`=${nomePagina}!$A$2:$A$${filtered.length + 2}`],
          type: "list",
        };
      }
    }
    //PERSONALIZZAZIONE COLONNE DATA
    if (thereIsData?.length > 0) {
      thereIsData.map((el) => {
        let letterColumnData = handleAlphabetCol(el);
        ws.dataValidations.model[
          `${letterColumnData}2:${letterColumnData}9999`
        ] = {
          type: "date",
          showErrorMessage: true,
          allowBlank: true,
          showInputMessage: true,
          prompt: `Inserisci una Data: DD/MM/AAAA`,
        };
      });
    }
    //CREO COLONNE OBBLIGATORIE
    for (let i = 0; i < filterColumn.length; i++) {
      let requiredLetter = handleAlphabetCol(filterColumn[i]);
      ws.getColumn(`${requiredLetter}`).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ffff00" },
      };
      ws.getColumn(`${requiredLetter}`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      ws.dataValidations.model[`${requiredLetter}2:${requiredLetter}9999`] = {
        allowBlank: false,
        showInputMessage: true,
        prompt: `Campo obbligatorio!`,
      };
    }

    excelHeader.map((key, index) => {
      stylingCell(ws, `${alphabet[index]}1`);
    });

    const excelBlob = await workbook.xlsx.writeBuffer();
    const excelUrl = URL.createObjectURL(
      new Blob([excelBlob], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );

    const link = document.createElement("a");
    link.href = excelUrl;
    link.download = fileName + ".xlsx";
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(excelUrl);
    document.body.removeChild(link);
  };

  // onchange event
  const handleFile = (e) => {
    let selectedFile = e.target.files[0];
    let reader = new FileReader();
    reader.readAsArrayBuffer(selectedFile);
    reader.onload = (e) => {
      handleFileSubmit(e.target.result);
    };
  };

  // submit event
  const handleFileSubmit = async (file) => {
    //e.preventDefault();
    if (file !== null) {
      const workbook = XLSX.read(file, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);

      let missingVal = [];

      //TRADUCO LA DATA DA EXCEL A JS
      let correctData = [];
      data.map((el) => {
        let translateData = { ...el };
        thereIsData?.map((dataCols) => {
          translateData[dataCols] = translateData[dataCols]
            ? moment(new Date((el[dataCols] - 25569) * 86400 * 1000)).format(
                "YYYY-MM-DD"
              )
            : null;
          return translateData;
        });
        correctData.push(translateData);
      });

      //TOLGO I DUPLICATI INTERNI AL FILE
      const removeFileDuplicated = correctData.map(
        ({ requiredColumn }) => requiredColumn
      );
      let filtered = [];
      correctData.filter((el, index) =>
        !removeFileDuplicated.includes(el[requiredColumn], index + 1)
          ? filtered.push(el)
          : ""
      );

      //CONTROLLO PRESENZA VALORI OBBLIGATORI
      const removeMissing = filtered.filter((el) => {
        console.log(el);
        var hasAll = filterColumn.every(
          (prop) =>
            el.hasOwnProperty(prop) && el[prop] !== null && el[prop] !== ""
        );
        if (hasAll) {
          return el;
        } else {
          missingVal.push(el);
        }
      });
      const requireData = removeMissing?.map((el) => el[requiredColumn]);

      //FACCIO GET PER PRENDERMI TUTTI I VALORI REQUIRED ESISTENTI
      let getResponse = [];
      try {
        const response = await unsplash.get("excel", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: {
            ids: requireData,
            table: getAllTableData,
            col: requiredColumn,
          },
        });
        getResponse = [...response.data.data];
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'importazione",
        });
        setOpenSnack(true);
      }

      //ELIMINO DUPLICATI
      const duplicated = removeMissing.filter((object1) => {
        return getResponse.some((object2) => {
          return (
            object1[requiredColumn].toUpperCase() ===
            object2[requiredColumn].toUpperCase()
          );
        });
      });
      const removeDuplicated = removeMissing.filter((object1) => {
        return !getResponse.some((object2) => {
          return (
            object1[requiredColumn].toUpperCase() ===
            object2[requiredColumn].toUpperCase()
          );
        });
      });

      setExcelDataDuplicated(duplicated);
      setExcelDataMissingVal(missingVal);
      setExcelDataImported(removeDuplicated);
      setOpen(true);
    }
  };
  const beforeSave = () => {
    let importedData = [];
    let dataError = [];
    if (sheets?.length > 0) {
      excelDataImported.map((obj) => {
        let o = { ...obj };
        for (let index = 0; index < sheets.length; index++) {
          const el = sheets[index];
          const colonnaDB = Object.getOwnPropertyNames(el)[0];

          //prendo solo l'array che devo controllare
          let arrayOfEl = el[colonnaDB];
          //Controllo se esiste riga
          let isGood = arrayOfEl.filter(
            (value) =>
              value[el.exist] === obj[el.exist] &&
              obj.hasOwnProperty(colonnaDB) &&
              obj[colonnaDB] === value[el.colonnaSel.nome]
          );

          // se isgood è vuoto, non ho la riga che mi interessa.
          if (isGood.length > 0) {
            o[colonnaDB] = isGood[0].ID;
          } else {
            if (filterColumn.includes(colonnaDB)) {
              o[colonnaDB] = "idError";
            }
          }
        }
        let isVerified = true;
        Object.getOwnPropertyNames(o).map((el) => {
          if (o[el] === "idError") {
            isVerified = false;
          }
        });

        if (isVerified) {
          importedData.push(o);
        } else {
          dataError.push(o);
        }
      });
    } else {
      importedData = [...excelDataImported];
    }

    if (importedData.length > 0) {
      handleSave(importedData);
      setExcelDataImported(importedData);
    } else {
      setExcelDataImported([]);
    }
    if (dataError.length > 0) {
      setExcelDataMissingId(dataError);
    }
    setChangeDialog(true);

  };
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <ButtonGroup
        variant="contained"
        className="ButtonMiller border border-[#11526f] ml-4 h-[30px]"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button
          size="small"
          startIcon={<InsertDriveFileIcon />}
          variant="contained"
          className="ButtonMiller"
          onClick={() => {
            handleToggleButton();
          }}
        >
          Importazione
        </Button>
        <Button
          variant="contained"
          className="ButtonMiller"
          size="small"
          aria-controls={openButton ? "split-button-menu" : undefined}
          aria-expanded={openButton ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={() => {
            handleToggleButton();
          }}
        >
          {openButton ? <ArrowDropUp /> : <ArrowDropDownIcon />}
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 100,
        }}
        open={openButton}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}>
            <Paper sx={{ ml: "15px" }}>
              <ClickAwayListener onClickAway={handleCloseButton}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => {
                        handleMenuItemClick(event, index);
                      }}
                    >
                      <span style={{ color: "#11526f" }}>
                        {option.icon} {option.text}
                      </span>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <div style={{ display: "none" }}>
        <Button
          id="0"
          size="small"
          startIcon={<Download />}
          onClick={(e) => exportToExcel(fileName)}
          sx={{ color: "white" }}
          variant="outlined"
          className="ButtonMiller"
        >
          Scarica Esempio
        </Button>
        <Button
          id="1"
          size="small"
          startIcon={<Upload />}
          onClick={() => document.getElementById("excelImport").click()}
          sx={{ color: "white" }}
          variant="outlined"
          className="ButtonMiller"
        >
          Importa
        </Button>
      </div>
      <input
        type="file"
        id="excelImport"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        className="hidden"
        onChange={handleFile}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="md"
        TransitionComponent={Transition}
      >
        {/* Title Container */}
        <DialogTitle
          sx={{
            mx: "auto",
          }}
        >
          {!changeDialog
            ? "Dati Estratti da file Excel"
            : "Dati Importati da file Excel"}
        </DialogTitle>
        {/* Body Container */}
        <DialogContent className="max-h-60 md:max-h-96 overflow-auto h-full w-full ">
          {!changeDialog ? (
            <>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "whitesmoke" }} />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: "40%" }}>
                    Righe che possono essere importate:
                  </Typography>
                  <b style={{ width: "5%" }}>{excelDataImported.length}</b>
                  {excelDataImported.length !== 0 ? (
                    <Circle
                      sx={{
                        color: "#40C18F",
                      }}
                    >
                      {" "}
                    </Circle>
                  ) : (
                    ""
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  {excelDataImported?.length > 0 ? (
                    <>
                      {excelDataImported.map((el, index) => (
                        <div key={index}>
                          -
                          {excelHeader.map((cols) => {
                            return (
                              <b>
                                {el[cols] ? cols + ": " + el[cols] + ", " : ""}{" "}
                              </b>
                            );
                          })}
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <div className="text-lg">
                        Errore nell'importazione dei dati
                      </div>
                      <div className="">Controllare file Excel importato!</div>
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "whitesmoke" }} />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography sx={{ width: "40%" }}>
                    Righe con dati mancanti:
                  </Typography>
                  <b style={{ width: "5%" }}>{excelDataMissingVal.length}</b>
                  {excelDataMissingVal.length !== 0 ? (
                    <Circle
                      sx={{
                        color: "#FF2768",
                      }}
                    >
                      {" "}
                    </Circle>
                  ) : (
                    <></>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  {excelDataMissingVal.map((el, index) => (
                    <div key={index}>
                      -
                      {excelHeader.map((cols) => {
                        return (
                          <b>
                            {el[cols] ? cols + ": " + el[cols] + ", " : ""}{" "}
                          </b>
                        );
                      })}
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
                sx={{
                  borderRadius: "0 0 4px 4px",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "whitesmoke" }} />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography sx={{ width: "40%" }}>
                    Righe con dati duplicati:
                  </Typography>
                  <b style={{ width: "5%" }}>{excelDataDuplicated.length}</b>
                  {excelDataDuplicated.length !== 0 ? (
                    <Circle
                      sx={{
                        color: "#FF2768",
                      }}
                    >
                      {" "}
                    </Circle>
                  ) : (
                    <></>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  {excelDataDuplicated.map((el, index) => (
                    <div key={index}>
                      -
                      {excelHeader.map((cols) => {
                        return (
                          <b>
                            {el[cols] ? cols + ": " + el[cols] + ", " : ""}{" "}
                          </b>
                        );
                      })}
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            </>
          ) : (
            <>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "whitesmoke" }} />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: "35%" }}>
                    Righe caricate correttamente:
                  </Typography>
                  <b style={{ width: "5%" }}>{excelDataImported.length}</b>
                  <Circle
                    sx={{
                      color:
                        excelDataImported.length !== 0 ? "#40C18F" : "#FF2768",
                    }}
                  >
                    {" "}
                  </Circle>
                </AccordionSummary>
                <AccordionDetails>
                  {excelDataImported?.length > 0 ? (
                    <>
                      {excelDataImported.map((el, index) => (
                        <div key={index}>
                          -
                          {excelHeader.map((cols) => {
                            return (
                              <b>
                                {el[cols] ? cols + ": " + el[cols] + ", " : ""}{" "}
                              </b>
                            );
                          })}
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <div className="text-lg">
                        Errore nell'importazione delle righe
                      </div>
                      <div className="">Controlla il file Excel importato!</div>
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "whitesmoke" }} />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography sx={{ width: "35%" }}>
                    Errori presenti nelle righe:
                  </Typography>
                  <b style={{ width: "5%" }}>{excelDataMissingId.length}</b>
                  {excelDataMissingId.length !== 0 ? (
                    <Circle
                      sx={{
                        color: "#FF2768",
                      }}
                    >
                      {" "}
                    </Circle>
                  ) : (
                    <></>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  {excelDataMissingId.map((el, index) => (
                    <div key={index}>
                      Controllare i valori della riga:{" "}
                      <b>
                        {requiredColumn + ": " + el[requiredColumn]},
                        {excelHeader.map((cols) => {
                          return (
                            <>
                              {el[cols] === "idError"
                                ? cols + ": " + el[cols] + ", "
                                : ""}{" "}
                            </>
                          );
                        })}
                      </b>
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </DialogContent>
        <DialogActions
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mx: 2,
          }}
        >
          <Button
            onClick={handleClose}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
          >
            Chiudi
          </Button>
          {!changeDialog ? (
            <>
              {excelDataMissingId.length === 0 &&  excelDataImported.length !== 0? (
                <Button
                  onClick={beforeSave}
                  variant="contained"
                  className="ButtonMiller"
                  startIcon={<Check />}
                >
                  Salva
                </Button>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
