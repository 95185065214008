import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import unsplash from "../api/unsplash";
import Avatar from "@mui/material/Avatar";
import GlobalSnackbar from "./Snackbar";
import LinearProgress from "@mui/material/LinearProgress";
import Switch from "@mui/material/Switch";

import {
  Grid,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Input,
  CardActionArea,
  CardMedia,
  Divider,
  CardActions,
  InputAdornment,
  ButtonGroup,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Zoom,
  DialogActions,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import { IconButton } from "@mui/material";

import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";

import {
  Add,
  Delete,
  Close,
  Check,
  Search,
  MenuBook,
  FiberNew,
  Circle,
} from "@mui/icons-material";

let newFormatore = {
  Nome: "",
  Cognome: "",
  Titolo: "",
  Email: "",
  Firma: "",
  Attivo: 0,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const Formatori = () => {
  const [formatori, setFormatori] = useState([]);
  const [formatoriVisibili, setFormatoriVisibili] = useState([]);
  const [loading, setLoading] = useState(true);
  const [vistaFormatore, setVistaFormatore] = useState([]);

  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [filtro, setFiltro] = useState({
    attivo: true,
    disattivo: false,
  });
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={async () => {
              await setVistaFormatore(params.row);
              document.getElementById("edit").click();
              handleClickOpen();
            }}
          >
            <EditIcon
              style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
            />
          </IconButton>

          <IconButton
            style={{ display: "none" }}
            aria-label="delete"
            onClick={async () => {
              setVistaFormatore(params.row);
              toggleAlert();
            }}
          >
            <Delete
              style={{ height: "2rem", width: "2rem", color: "#FF2768" }}
            />
          </IconButton>

          {params.row.Attivo === 1 ? (
            <Tooltip title="Attivo">
              <Circle
                sx={{ color: "#40C18F", width: "15px", height: "15px" }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Disattivo">
              <Circle
                sx={{ color: "#FF2768", width: "15px", height: "15px" }}
              />
            </Tooltip>
          )}
        </>
      ),
    },
    {
      field: "Nome",
      headerName: "Nome",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Cognome",
      headerName: "Cognome",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Logo",
      headerName: "Logo",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Avatar
          sx={{
            bgcolor: "white",
            width: 50,
            height: 50,
          }}
        >
          <img
            style={{
              width:
                params.row.Firma !== "" && params.row.Firma ? "70%" : "100%",
              height:
                params.row.Firma !== "" && params.row.Firma ? "40%" : "100%",
            }}
            src={
              params.row.Firma !== "" && params.row.Firma
                ? "data:image/png;base64," + params.row.Firma
                : require("../assets/images/MILLERLogo.jpg")
            }
            alt=""
          />
        </Avatar>
      ),
    },
  ];
  const loadFormatori = async () => {
    try {
      let response = await unsplash.get("formatori", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setFormatori(response.data.data);
      setFormatoriVisibili(response.data.data.filter((f) => f.Attivo === 1));
      setLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    (() => {
      loadFormatori();
    })();
  }, []);

  const handleSave = async (formatore, operazione) => {
    if (operazione === "new") {
      try {
        await unsplash.post(
          "formatori",
          {
            formatore: formatore,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Formatore creato con successo",
        });
        setOpenSnack(true);

        loadFormatori();
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante la creazione",
        });
        setOpenSnack(true);
      }
      handleClose();
    } else {
      if (formatore.Firma === null) {
        let copiaVistaFormatore = { ...formatore };
        copiaVistaFormatore.Firma = "";
        setVistaFormatore(copiaVistaFormatore);
      }
      try {
        await unsplash.put(
          "formatori",
          { auth: "a5d42c3ed7977afa2d8e922569e7940b", formatore: formatore },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Formatore aggiornato con successo",
        });
        setOpenSnack(true);

        loadFormatori();
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'aggiornamento",
        });
        setOpenSnack(true);
      }
      handleClose();
    }
  };
  const toggleAlert = () => {
    setOpenAlert(!openAlert);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const filter = (e, tipologia) => {
    let filtroUp = { ...filtro };
    filtroUp[tipologia] = e.target.checked;
    setFiltro(filtroUp);

    let form = formatori;
    if (!filtroUp.attivo) {
      form = form.filter((f) => f.Attivo !== 1);
    }
    if (!filtroUp.disattivo) {
      form = form.filter((f) => f.Attivo !== 0);
    }
    setFormatoriVisibili(form);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
          <FormControlLabel
            sx={{
              marginBottom: "0px!important",
              fontSize: "13px",
              color: "#11526F",
            }}
            control={
              <Checkbox
                size="small"
                checked={filtro.attivo}
                onClick={(e) => filter(e, "attivo")}
              />
            }
            label="Attivi"
          />
          <FormControlLabel
            sx={{
              marginBottom: "0px!important",
              fontSize: "13px",
              color: "#11526F",
            }}
            control={
              <Checkbox
                size="small"
                name="Disattivi"
                checked={filtro.disattivo}
                onClick={(e) => filter(e, "disattivo")}
              />
            }
            label="Disattivi"
          />
        </Grid>
      </GridToolbarContainer>
    );
  }
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid item xs={12}>
        <Card>
          <CardContent sx={{ p: { xs: 0 } }}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <NewFromatori
                      id="edit"
                      formatore={vistaFormatore}
                      saveFunction={handleSave}
                      title="Modifica Formatore"
                    />
                    <NewFromatori
                      id="new"
                      formatore={vistaFormatore}
                      saveFunction={handleSave}
                      title="Nuovo Formatore"
                    />
                  </Grid>
                  <Box
                    sx={{
                      height: { xs: "75vh", md: "60vh", xl: "75vh" },
                      width: 1,
                    }}
                  >
                    <DataGridPro
                      initialState={{
                        pinnedColumns: { left: ["Actions"] },
                      }}
                      components={{
                        Toolbar: CustomToolbar,
                        Footer: CustomFooterPersonalized,
                      }}
                      slots={{
                        loadingOverlay: LinearProgress,
                      }}
                      loading={loading}
                      localeText={
                        itIT.components.MuiDataGrid.defaultProps.localeText
                      }
                      rowHeight={52}
                      rows={formatoriVisibili}
                      columns={columns}
                      getRowId={(row) => row.ID}
                      pageSize={20}
                      rowsPerPageOptions={[20]}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <DeleteFormatori
              formatore={vistaFormatore}
              openValue={openAlert}
              openFunction={toggleAlert}
            />
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default Formatori;

/* Eliminazione Formatori */
const DeleteFormatori = (props) => {
  const [openSnackDel, setOpenSnackDel] = useState(false);
  const [snackDel, setSnackDel] = useState({
    severity: "success",
    messaggio: "Eliminazione effettuata correttamente",
    open: false,
  });
  const handleCloseSnackDel = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackDel(false);
  };
  const handleDelete = async () => {
    try {
      await unsplash.delete("formatori", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: { id: props.formatore.ID },
      });
      setSnackDel({
        severity: "success",
        messaggio: "Eliminazione effettuata correttamente",
      });
      setOpenSnackDel(true);
    } catch {
      setSnackDel({
        severity: "error",
        messaggio: "Errore durante l'eliminazione",
      });
      setOpenSnackDel(true);
    }
  };
  {
    /* Dialog Alert Eliminazione Formatore */
  }
  return (
    <>
      <GlobalSnackbar
        severity={snackDel.severity}
        messaggio={snackDel.messaggio}
        openSnack={openSnackDel}
        handleCloseSnack={handleCloseSnackDel}
      />
      <Dialog
        open={props.openValue}
        onClose={props.openFunction}
        maxWidth="md"
        TransitionComponent={Transition}
      >
        <DialogTitle>
          Sei sicuro di voler eliminare {props.formatore.Nome}{" "}
          {props.formatore.Cognome}?
        </DialogTitle>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button onClick={props.openFunction}>ANNULLA</Button>
          <Button onClick={handleDelete}>Conferma</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

/* Creazione Nuovi Formatori */
const NewFromatori = (props) => {
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [checked, setChecked] = useState(false);
  const [formatore, setFormatore] = useState({
    Nome: "",
    Cognome: "",
    Titolo: "",
    Email: "",
    Firma: "",
    Attivo: 0,
  });

  const handleClickOpen = () => {
    if (props.id === "edit") {
      setChecked(props.formatore.Attivo === 1 ? true : false);
      setFormatore(props.formatore);
    } else {
      setFormatore({
        Nome: "",
        Cognome: "",
        Titolo: "",
        Email: "",
        Firma: "",
        Attivo: 1,
      });
    }
    setOpen(true);
  };
  const handleClose = () => {
    setFormatore({
      Nome: "",
      Cognome: "",
      Titolo: "",
      Email: "",
      Firma: "",
      Attivo: 0,
    });
    setOpen(false);
  };
  const handleChange = (e) => {
    let copiaFormatore = { ...formatore };
    copiaFormatore[e.target.name] = e.target.value;
    setFormatore(copiaFormatore);
  };
  const handleSave = async () => {
    props.saveFunction(formatore, props.id);
    handleClose();
  };
  const handleImage = async (e) => {
    const img = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(img);
    reader.onload = function () {
      document.getElementById("firmaFormatore").src = reader.result;
      let f = { ...formatore };
      let foto = reader.result.replace("data:image/jpeg;base64,", "");
      foto = foto.replace("data:image/png;base64,", "");
      f.Firma = foto;
      setFormatore(f);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
      >
        {props.id === "edit" ? (
          <Button
            startIcon={<Add />}
            onClick={handleClickOpen}
            id={props.id}
            sx={{ color: "white", display: "none" }}
            variant="outlined"
            className="ButtonMiller"
          >
            Aggiungi
          </Button>
        ) : (
          <Button
            startIcon={<Add />}
            onClick={handleClickOpen}
            id={props.id}
            sx={{ color: "white" }}
            variant="outlined"
            className="ButtonMiller"
          >
            Aggiungi
          </Button>
        )}
      </ButtonGroup>

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="lg"
          TransitionComponent={Transition}
        >
          <DialogTitle
            sx={{
              mx: "auto",
            }}
          >
            {props.title}
          </DialogTitle>
          <DialogContent>
            {props.id === "edit" ? (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                  fullWidth
                >
                  Attivo:
                  <Switch
                    checked={checked}
                    onChange={(e) => {
                      setChecked(e.target.checked);
                      let a = { ...formatore };
                      if (e.target.checked) {
                        a.Attivo = 1;
                      } else {
                        a.Attivo = 0;
                      }
                      setFormatore(a);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  value={formatore.Nome}
                  id="outlined-basic"
                  label="Nome"
                  name="Nome"
                  onChange={handleChange}
                  fullWidth
                  sx={{ mt: 2 }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={formatore.Cognome}
                  id="outlined-basic"
                  label="Cognome"
                  name="Cognome"
                  onChange={handleChange}
                  fullWidth
                  sx={{ mt: 2 }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  value={formatore.Titolo}
                  id="outlined-basic"
                  label="Titolo"
                  name="Titolo"
                  onChange={handleChange}
                  fullWidth
                  sx={{ mt: 2 }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={formatore.Email}
                  id="outlined-basic"
                  label="Email"
                  name="Email"
                  onChange={handleChange}
                  fullWidth
                  sx={{ mt: 2 }}
                />
              </Grid>
            </Grid>

            <Card
              sx={{
                mt: 2,
                alignContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Card
                sx={{
                  py: 4,
                  //backgroundColor: "#11526F",
                  border: props.id === "new" ? "1px dashed gray" : "none",
                }}
              >
                <Box
                  height="140"
                  src={
                    formatore.Firma !== "" && formatore.Firma
                      ? "data:image/png;base64," + formatore.Firma
                      : require("../assets/images/MILLERLogo.jpg")
                  }
                  id="firmaFormatore"
                  alt="img"
                  component="img"
                  sx={{
                    height: "100%",
                    maxHeight: "250px",
                    mx: "auto",
                    display: "flex",
                  }}
                />
                <label
                  htmlFor="contained-button-file"
                  style={{ display: "none", margin: 0 }}
                >
                  <Input
                    accept="image/*"
                    id="file"
                    name="Firma"
                    multiple
                    type="file"
                    onChange={(e) => handleImage(e)}
                    hidden
                  />
                </label>
              </Card>
              <CardActions>
                <Button
                  fullWidth
                  variant="text"
                  onClick={() => {
                    document.getElementById("file").click();
                  }}
                >
                  Carica Foto
                </Button>
              </CardActions>
            </Card>

            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Button
                  onClick={handleClose}
                  variant="contained"
                  className="ButtonMiller"
                  startIcon={<Close />}
                >
                  Chiudi
                </Button>
                <Button
                  onClick={handleSave}
                  variant="contained"
                  className="ButtonMiller"
                  startIcon={<Check />}
                >
                  Salva
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
};
