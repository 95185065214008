import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  Button,
  Typography,
  Grid,
  TextField,
  Card,
  CardContent,
  CircularProgress,
  Paper,
  Backdrop,
  Stack,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardHeader,
  Box,
} from "@mui/material";
import PropTypes from "prop-types";
import unsplash from "../api/unsplash";
import { LocalizationProvider,DatePicker,PickersDay,TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment as DateAdapter} from '@mui/x-date-pickers/AdapterMoment'
import moment from "moment";
import momentTimezone from "moment-timezone";
import jsPDF from "jspdf";

import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  LabelList,
  Cell,
} from "recharts";
import {
  DragHandle,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
} from "@mui/icons-material";

const data = [
  {
    name: "Page A",
    uv: 590,
    pv: 800,
    amt: 1400,
  },
  {
    name: "Page B",
    uv: 868,
    pv: 967,
    amt: 1506,
  },
  {
    name: "Page C",
    uv: 1397,
    pv: 1098,
    amt: 989,
  },
  {
    name: "Page D",
    uv: 1480,
    pv: 1200,
    amt: 1228,
  },
  {
    name: "Page E",
    uv: 1520,
    pv: 1108,
    amt: 1100,
  },
  {
    name: "Page F",
    uv: 1400,
    pv: 680,
    amt: 1700,
  },
];

const KpiMedicina = () => {
  const [date, setDate] = useState(new Date());
  const [users, setUsers] = useState([]);
  const [kpi, setKpi] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [kpiPeriodo, setKpiPeriodo] = useState([]);
  const [kpiIncrements, setKpiIncrements] = useState(null);

  useEffect(async () => {
    let auth = sessionStorage.getItem("Autorizzazione")
    if(auth === "0"){
      setSelectedUser(sessionStorage.getItem("idUsername"))
      loadKPI(sessionStorage.getItem("idUsername"))
      loadKPIPeriodo(moment(new Date()).format("MM"),moment(new Date()).format("YYYY"),sessionStorage.getItem("idUsername"))
    }
    loadUsers();
  }, []);

  const loadUsers = async () => {
    let responseUtenti = await unsplash.get("utenti", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setUsers(responseUtenti.data.data.filter(us => us.Medicina === 1));
  };

  const loadKPI = async (user) => {
    let responseKpi = await unsplash.get("kpi", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: {
        user: user,
        tipologia: "Medicina",
      },
    });
    setKpi(responseKpi.data.data);

    let responseIncremnts = await unsplash.get("kpi", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: {
        user: user,
        tipologia: "IncrementsRateMedicina",
      },
    });

    setKpiIncrements(
      responseIncremnts.data.data.length > 0
        ? responseIncremnts.data.data[0]
        : null
    );
  };

  const loadKPIPeriodo = async (mese, anno, user) => {
    let responseKpi = await unsplash.get("kpi", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: {
        mese: mese,
        user: user,
        tipologia: "MedicinaPeriodo",
        anno: anno,
      },
    });
    setKpiPeriodo(responseKpi.data.data);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}: ${payload[0].value} %`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <Card sx={{ boxShadow: "none" }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} style={{display:sessionStorage.getItem("Autorizzazione")=== "0"?"None":""}}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Utente</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  //value={cliente.UtenteAssociato}
                  label="Utente"
                  onChange={(e) => {
                    loadKPI(e.target.value);
                    setSelectedUser(e.target.value);
                    loadKPIPeriodo(
                      moment(date).format("MM"),
                      moment(date).format("YYYY"),
                      e.target.value
                    );
                  }}
                >
                  <MenuItem value={null}>Seleziona...</MenuItem>
                  {users?.map((u) => (
                    <MenuItem value={u.ID}>{u.username}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <p
            style={{
              margin: "0",
              fontWeight: 400,
              fontSize: "1.5rem",
              lineHeight: 1.334,
              letterSpacing: "0em",
              display: "block",
            }}
          >
            Accurancy Index
          </p>
          <Grid container spacing={2}>
            <Grid xs={0} md={3} />
            <Grid xs={6} md={2} item>
              <center>
                last 3 months <br />
                <CircularProgressWithLabel
                  variant="determinate"
                  value={
                    kpiIncrements ? kpiIncrements.MediaAttuale?.toFixed(2) : 0
                  }
                  size={100}
                />
              </center>
            </Grid>
            <Grid xs={6} md={2} item>
              <center>
                3 months before <br />
                <CircularProgressWithLabel
                  variant="determinate"
                  value={
                    kpiIncrements ? kpiIncrements.MediaVecchia?.toFixed(2) : 0
                  }
                  size={100}
                />
              </center>
            </Grid>
            <Grid xs={6} md={2} item>
              <center>
                Change <br />
                <Box sx={{ position: "relative", display: "inline-flex" }}>
                  <CircularProgress
                    variant="determinate"
                    thickness={7}
                    value={100}
                    size={100}
                    sx={{ visibility: "hidden" }}
                  />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {kpiIncrements ? (
                    kpiIncrements.Stato?.toFixed(2) > 0 ? (
                      <KeyboardDoubleArrowUp
                        fontSize="large"
                        sx={{ color: "#40C18F" }}
                        className="objectArrow"
                      />
                      
                    ) : ( kpiIncrements.Stato?.toFixed(2) < 0 ?
                      <KeyboardDoubleArrowDown
                        className="objectArrowDown"
                        fontSize="large"
                        sx={{ color: "#FF2768" }}
                      /> 
                      :
                      <DragHandle
                      fontSize="large"
                      sx={{ color: "#11526f" }}
                      className="objectArrow"
                    />

                    )
                    ) : (
                      ""
                    )}

                    <Typography
                      component="div"
                      color="text.secondary"
                      variant="h5"
                    >
                      {`${Math.round(
                        kpiIncrements ? kpiIncrements.Stato?.toFixed(2) : 0
                      )}%`}
                    </Typography>
                  </Box>
                </Box>
              </center>
            </Grid>

            <Grid xs={0} md={3} />
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ width: "100%", height: " 40vh" }}>
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart width={500} height={400} data={kpi}>
                  <CartesianGrid />
                  <XAxis dataKey="Name" />
                  <YAxis domain={[0, 100]} />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend />
                  <Bar dataKey="Media" barSize={20} fill="#11526F" />
                  <Line type="monotone" dataKey="Media" stroke="#8E75AC" />
                </ComposedChart>
              </ResponsiveContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ marginTop: "15px" }}>
        <CardHeader
          title="Dettaglio Periodo selezionato"
          subheader={"Clienti totali periodo: " + kpiPeriodo.length}
        />

        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  views={["month", "year"]}
                  ampmInClock={false}
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  label="Periodo"
                  value={date}
                  onChange={(newValue) => {
                    setDate(newValue ? newValue : null);
                    loadKPIPeriodo(
                      newValue.format("MM"),
                      newValue.format("YYYY"),
                      selectedUser
                    );

                    /*  setAccertamentiDaFatturare([]);*/
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sx={{ width: "100%", height: "40vh" }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart width={500} height={300} data={kpiPeriodo}>
                  <CartesianGrid />
                  <XAxis dataKey="RagioneSociale" />
                  <YAxis domain={[0, 100]} />
                  <Tooltip />

                  <Bar dataKey="Percentuale">
                    {kpiPeriodo.map((entry, index) => (
                      <Cell fill={entry.color} key={`cell-${index}`} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

function CircularProgressWithLabel(props) {
  return (
    <>
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"
          thickness={7}
          {...props}
          sx={{
            color:
              props.value <= 50
                ? "#FF2768"
                : props.value <= 75
                ? "#2265B1"
                : "#40C18F",
          }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography component="div" color="text.secondary" variant="h5">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default KpiMedicina;
