import React, { useState } from "react";
import unsplash from "../api/unsplash";
import {
  Checkbox,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  TextField,
  DialogActions,
  Grid,
  ButtonGroup,
  FormControlLabel,
  Zoom,
  IconButton,
  LinearProgress,
  Autocomplete,
} from "@mui/material";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import { Add, Group, Edit } from "@mui/icons-material";
import GlobalSnackbar from "./Snackbar";
import EditIcon from "@mui/icons-material/Edit";
import { Delete, Close, Check } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const GestioneUtenti = (props) => {
  const [open, setOpen] = useState(false);
  const [utenti, setUtenti] = useState([]);
  const [loading, setLoading] = useState(true);
  const [utenteSel, setUtenteSel] = useState(null);

  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      filterable: false,

      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <ModificaUtente
            utente={params.row}
            handleLoadUtenti={handleLoadUtenti}
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
          />
          <DeleteDialog
            categoria={params.row}
            myFunction={handleDelete}
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
          />
        </>
      ),
    },
    {
      field: "username",
      headerName: "Username",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "tipoUtente",
      headerName: "Tipologia",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          {params.row.Sicurezza ? "Sicurezza" : ""}{" "}
          {params.row.Medicina ? "Medicina" : ""}{" "}
          {params.row.Formazione ? "Formazione" : ""}
        </>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Admin",
      headerName: "Admin",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
  ];

  const handleClickOpen = async () => {
    let response = await unsplash.get("utenti", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setUtenti(response.data.data);
    setLoading(false);
    setUtenteSel(null);

    setOpen(true);
  };

  const handleLoadUtenti = async () => {
    let response = await unsplash.get("utenti", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setUtenti(response.data.data);
    setUtenteSel(null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (utente) => {
    let u = { ...utente };
    u.Attivo = 0;
    try {
      let response = await unsplash.put(
        "utenti",
        { utente: u, operazione: "elimina" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      handleLoadUtenti();
      setSnack({
        severity: "success",
        messaggio: "Utente eliminato con successo",
      });
      setOpenSnack(true);
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione",
      });
      setOpenSnack(true);
    }
  };

  //p[index].IDAggiuntivo = null;

  return (
    <>
      <Button
        startIcon={<Group />}
        className="ButtonMiller"
        fullWidth
        variant="contained"
        onClick={handleClickOpen}
      >
        Gestione Utenti
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="md"
        TransitionComponent={Transition}
      >
        <GlobalSnackbar
          severity={snack.severity}
          messaggio={snack.messaggio}
          openSnack={openSnack}
          handleCloseSnack={handleCloseSnack}
        />
        <DialogTitle>Gestione Utenti</DialogTitle>
        <DialogContent>
          <ButtonGroup sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CreaUtente handleLoadUtenti={handleLoadUtenti} />
          </ButtonGroup>
          <Box
            sx={{
              height: 600,
              width: 1,
              marginTop: "10px",
            }}
          >
            <DataGridPro
              initialState={{ pinnedColumns: { left: ["Actions"] } }}
              sx={{ fontSize: 12 }}
              components={{
                Toolbar: CustomToolbar,
                Footer: CustomFooterPersonalized,
              }}
              slots={{
                loadingOverlay: LinearProgress,
              }}
              loading={loading}
              localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
              rows={utenti}
              columns={columns}
              getRowId={(row) => row.ID}
              pageSize={100}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ marginLeft: 2, display: "flex", justifyContent: "flex-start" }}
        >
          <Button
            onClick={handleClose}
            className="ButtonMiller"
            variant="contained"
            startIcon={<Close />}
          >
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default GestioneUtenti;

const CreaUtente = (props) => {
  const [open, setOpen] = useState(false);
  const [utente, setUtente] = useState();
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleChange = (e) => {
    let u = { ...utente };
    u[e.target.name] = e.target.value;
    setUtente(u);
  };

  const handleClickOpen = async () => {
    setUtente({
      username: "",
      //tipoUtente: "Sicurezza",
      Admin: 0,
      email: "",
      Medicina: 0,
      Formazione: 0,
      Sicurezza: 0,
      Parita: 0,
    });

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //p[index].IDAggiuntivo = null;

  const handleSave = async () => {
    try {
      let response = await unsplash.post(
        "utenti",
        { utente: utente },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      setOpen(false);
      props.handleLoadUtenti();
      setSnack({
        severity: "success",
        messaggio: "Utente creato con successo",
      });
      setOpenSnack(true);
    } catch {
      setSnack({ severity: "error", messaggio: "Errore durante la creazione" });
      setOpenSnack(true);
    }
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Button
        startIcon={<Add />}
        className="ButtonMiller"
        variant="contained"
        onClick={handleClickOpen}
      >
        Aggiungi
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="md"
        TransitionComponent={Transition}
      >
        <DialogTitle>Aggiungi Utente</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                //error={validation?.RagioneSociale}

                margin="dense"
                name="username"
                label="Username"
                type="text"
                fullWidth
                value={utente?.username}
                onChange={handleChange}
                variant="outlined"
                //helperText={validation?.RagioneSociale?"Campo obbligatorio":""}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                //error={validation?.RagioneSociale}

                margin="dense"
                name="email"
                label="Email"
                type="text"
                fullWidth
                value={utente?.email}
                onChange={handleChange}
                variant="outlined"
                //helperText={validation?.RagioneSociale?"Campo obbligatorio":""}
              />
            </Grid>
            {/* <Grid item xs={6}>
              Tipo Utente
              <select
                name="Tipologia"
                className="SelectFromSettings"
                id="tipologia"
                onChange={(e) => {
                  let u = { ...utente };
                  u.tipoUtente = e.target.value;
                  setUtente(u);
                }}
                value={utente?.tipoUtente}
              >
                <option value="Sicurezza">Sicurezza</option>
                <option value="Medicina">Medicina</option>
                <option value="Formazione">Formazione</option>
              </select>
            </Grid> */}
            <Grid item xs={12}>
              <label htmlFor="subtitle">
                <b> Tipologia Utente:</b>
              </label>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", flexWrap: "wrap" }}
              style={{ paddingTop: "0px" }}
            >
              <FormControlLabel
                sx={{ flexGrow: 1, m: 0 }}
                control={
                  <Checkbox
                    checked={utente?.Sicurezza === 1}
                    onChange={(e) => {
                      let u = { ...utente };
                      u.Sicurezza = e.target.checked ? 1 : 0;
                      setUtente(u);
                    }}
                  />
                }
                label="Sicurezza"
              />
              <FormControlLabel
                sx={{ flexGrow: 1, m: 0 }}
                control={
                  <Checkbox
                    checked={utente?.Medicina === 1}
                    onChange={(e) => {
                      let u = { ...utente };
                      u.Medicina = e.target.checked ? 1 : 0;
                      setUtente(u);
                    }}
                  />
                }
                label="Medicina"
              />
              <FormControlLabel
                sx={{ flexGrow: 1, m: 0 }}
                control={
                  <Checkbox
                    checked={utente?.Formazione === 1}
                    onChange={(e) => {
                      let u = { ...utente };
                      u.Formazione = e.target.checked ? 1 : 0;
                      setUtente(u);
                    }}
                  />
                }
                label="Formazione"
              />
              <FormControlLabel
                sx={{ flexGrow: 1, m: 0 }}
                control={
                  <Checkbox
                    checked={utente?.Parita === 1}
                    onChange={(e) => {
                      let u = { ...utente };
                      u.Parita = e.target.checked ? 1 : 0;
                      setUtente(u);
                    }}
                  />
                }
                label="Parita"
              />
              <FormControlLabel
                sx={{ flexGrow: 1, m: 0 }}
                control={
                  <Checkbox
                    checked={utente?.Admin === 1}
                    onChange={(e) => {
                      let u = { ...utente };
                      u.Admin = e.target.checked ? 1 : 0;
                      setUtente(u);
                    }}
                  />
                }
                label="Amministratore"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between", mx: 2 }}
        >
          <Button
            onClick={handleClose}
            className="ButtonMiller"
            variant="contained"
            startIcon={<Close />}
          >
            Chiudi
          </Button>
          <Button
            onClick={handleSave}
            className="ButtonMiller"
            variant="contained"
            startIcon={<Check />}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ModificaUtente = (props) => {
  const [open, setOpen] = useState(false);
  const [visibilityTable, setVisibilityTable] = useState(false);
  const [utente, setUtente] = useState();
  const [relClienti, setRelClienti] = useState([]);
  const [clienti, setClienti] = useState([]);
  const [utenteSel, setUtenteSel] = useState(null);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const [loading, setLoading] = useState(true);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      filterable: false,

      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <ModalRelUtenteCliente
            clienti={clienti}
            operazione="editRel"
            utenteCliente={params.row}
            relClienti={relClienti}
            handleLoadUtenti={handleLoadUtentiClienti}
            saveFunction={handleSaveRel}
          />
          {/* <IconButton
            aria-label="edit"
            onClick={async () => {
              await setUtenteSel(params.row);
              console.log(params.row);
              document.getElementById("editRel").click();
            }}
          >
            <EditIcon
              style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
            />
          </IconButton> */}
          <DeleteDialogRel
            utenteCliente={params.row}
            handleLoad={handleLoadUtentiClienti}
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
          />
        </>
      ),
    },
    {
      field: "RagioneSociale",
      headerName: "RagioneSociale",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
  ];

  const handleChange = (e) => {
    let u = { ...utente };
    u[e.target.name] = e.target.value;
    setUtente(u);
  };

  const handleLoadUtentiClienti = async (eliminazione) => {
    if (eliminazione) {
      setSnack({
        severity: eliminazione,
        messaggio:
          eliminazione === "success"
            ? "Cliente eliminato con successo"
            : "Errore durante l'eliminazione",
      });
      setOpenSnack(true);
    }
    let response = await unsplash.get("relUtentiClienti", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: {
        id: props.utente.ID,
      },
    });
    setRelClienti(response.data.data);
  };

  const handleClickOpen = async () => {
    setUtente(props.utente);
    try {
      let response = await unsplash.get("clienti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { all: "yes" },
      });
      setClienti(response.data.data);
    } catch (error) {
      console.error(error.message);
    }
    if (props.utente?.VisibilitaLimitata) {
      setVisibilityTable(true);
      handleLoadUtentiClienti();
      setLoading(false);
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setVisibilityTable(false);
  };

  const handleSave = async () => {
    try {
      let u = { ...utente };
      u.Attivo = 1;
      let response = await unsplash.put(
        "utenti",
        { utente: u },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      setOpen(false);
      setVisibilityTable(false);
      props.handleLoadUtenti();
      props.setSnack({
        severity: "success",
        messaggio: "Utente modificato con successo",
      });
      props.setOpenSnack(true);
    } catch {
      props.setSnack({
        severity: "error",
        messaggio: "Errore durante la modifica",
      });
      props.setOpenSnack(true);
    }
  };

  const handleSaveRel = async (utenteCliente, operazione) => {
    if (operazione === "new") {
      try {
        let response = await unsplash.post(
          "relUtentiClienti",
          { utentiClienti: utenteCliente },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        handleLoadUtentiClienti();
        setSnack({
          severity: "success",
          messaggio: "Cliente aggiunto con successo",
        });
        setOpenSnack(true);
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante la creazione",
        });
        setOpenSnack(true);
      }
    } else {
      try {
        let response = await unsplash.put(
          "relUtentiClienti",
          { utentiClienti: utenteCliente },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        //setOpen(false);
        handleLoadUtentiClienti();
        setSnack({
          severity: "success",
          messaggio: "Cliente aggiornato con successo",
        });
        setOpenSnack(true);
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante la creazione",
        });
        setOpenSnack(true);
      }
    }
  };

  return (
    <>
      <IconButton
        aria-label="edit"
        onClick={() => handleClickOpen()}
        id="editUser"
      >
        <EditIcon style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="md"
        TransitionComponent={Transition}
      >
        <GlobalSnackbar
          severity={snack.severity}
          messaggio={snack.messaggio}
          openSnack={openSnack}
          handleCloseSnack={handleCloseSnack}
        />
        <DialogTitle>Modifica Utente</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                //error={validation?.RagioneSociale}

                margin="dense"
                name="username"
                label="Username"
                type="text"
                fullWidth
                value={utente?.username}
                onChange={handleChange}
                variant="outlined"
                disabled
                //helperText={validation?.RagioneSociale?"Campo obbligatorio":""}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                //error={validation?.RagioneSociale}

                margin="dense"
                name="email"
                label="Email"
                type="text"
                fullWidth
                value={utente?.email}
                onChange={handleChange}
                variant="outlined"
                //helperText={validation?.RagioneSociale?"Campo obbligatorio":""}
              />
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="subtitle">
                <b> Tipologia Utente:</b>
              </label>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", flexWrap: "wrap" }}
              style={{ paddingTop: "0px" }}
            >
              <FormControlLabel
                sx={{ flexGrow: 1, m: 0 }}
                control={
                  <Checkbox
                    checked={utente?.Sicurezza === 1}
                    onChange={(e) => {
                      let u = { ...utente };
                      u.Sicurezza = e.target.checked ? 1 : 0;
                      setUtente(u);
                    }}
                  />
                }
                label="Sicurezza"
              />
              <FormControlLabel
                sx={{ flexGrow: 1, m: 0 }}
                control={
                  <Checkbox
                    checked={utente?.Medicina === 1}
                    onChange={(e) => {
                      let u = { ...utente };
                      u.Medicina = e.target.checked ? 1 : 0;
                      setUtente(u);
                    }}
                  />
                }
                label="Medicina"
              />
              <FormControlLabel
                sx={{ flexGrow: 1, m: 0 }}
                control={
                  <Checkbox
                    checked={utente?.Formazione === 1}
                    onChange={(e) => {
                      let u = { ...utente };
                      u.Formazione = e.target.checked ? 1 : 0;
                      setUtente(u);
                    }}
                  />
                }
                label="Formazione"
              />
              <FormControlLabel
                sx={{ flexGrow: 1, m: 0 }}
                control={
                  <Checkbox
                    checked={utente?.Parita === 1}
                    onChange={(e) => {
                      let u = { ...utente };
                      u.Parita = e.target.checked ? 1 : 0;
                      setUtente(u);
                    }}
                  />
                }
                label="Parita"
              />
              <FormControlLabel
                sx={{ flexGrow: 1, m: 0 }}
                control={
                  <Checkbox
                    checked={utente?.Admin === 1}
                    onChange={(e) => {
                      let u = { ...utente };
                      u.Admin = e.target.checked ? 1 : 0;
                      setUtente(u);
                    }}
                  />
                }
                label="Amministratore"
              />
              <FormControlLabel
                sx={{ flexGrow: 1, m: 0 }}
                control={
                  <Checkbox
                    checked={utente?.VisibilitaLimitata === 1}
                    onChange={(e) => {
                      let u = { ...utente };
                      u.VisibilitaLimitata = e.target.checked ? 1 : 0;
                      setUtente(u);
                    }}
                  />
                }
                label="Visibilità Limitata"
              />
            </Grid>
            {visibilityTable ? (
              <Grid item xs={12}>
                <ButtonGroup
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <ModalRelUtenteCliente
                    operazione={"new"}
                    idUtente={utente.ID}
                    relClienti={relClienti}
                    clienti={clienti}
                    handleLoadUtenti={handleLoadUtentiClienti}
                    saveFunction={handleSaveRel}
                  />
                </ButtonGroup>
                <Box
                  sx={{
                    height: 400,
                    width: 1,
                    marginTop: "10px",
                  }}
                >
                  <DataGridPro
                    initialState={{ pinnedColumns: { left: ["Actions"] } }}
                    sx={{ fontSize: 12 }}
                    components={{
                      Toolbar: CustomToolbar,
                      Footer: CustomFooterPersonalized,
                    }}
                    slots={{
                      loadingOverlay: LinearProgress,
                    }}
                    loading={loading}
                    localeText={
                      itIT.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={relClienti}
                    columns={columns}
                    getRowId={(row) => row.ID}
                    pageSize={100}
                  />
                </Box>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between", mx: 2 }}
        >
          <Button
            onClick={handleClose}
            className="ButtonMiller"
            variant="contained"
            startIcon={<Close />}
          >
            Chiudi
          </Button>

          <Button
            onClick={handleSave}
            className="ButtonMiller"
            variant="contained"
            startIcon={<Check />}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DeleteDialog = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare l'utente selezionata?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare il seguente utente:
            <br />
            <b>{props.categoria.username}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={() => props.myFunction(props.categoria)}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const ModalRelUtenteCliente = (props) => {
  const [openRel, setOpenRel] = useState(false);
  const [utenteCliente, setUtenteCliente] = useState({});

  const [clienti, setClienti] = useState([]);

  const [clientiSel, setClientiSel] = useState(null);

  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleClickOpen = () => {
    const client = props.clienti.filter((el) => {
      return props.relClienti?.every((rel) => rel.IDCliente !== el.ID);
    });
    setClienti(client);
    if (props.operazione !== "new") {
      setUtenteCliente({
        IDutente: props.utenteCliente?.IDutente,
        IDCliente: props.utenteCliente?.IDCliente,
      });
      setClientiSel(
        props.clienti?.filter((c) => c.ID === props.utenteCliente?.IDCliente)[0]
      );
    } else {
      //filtro clienti già associati
      setUtenteCliente({
        IDutente: props.idUtente,
        IDCliente: null,
      });
      setClientiSel(null);
    }
    setOpenRel(true);
  };

  const handleClose = () => {
    setOpenRel(false);
  };

  const handleSave = async () => {
    props.saveFunction(utenteCliente, props.operazione);
    setOpenRel(false);
  };

  return (
    <>
      <IconButton
        style={{ display: props.operazione !== "editRel" ? "None" : "" }}
        aria-label="editRel"
        onClick={handleClickOpen}
        id={props.operazione}
      >
        <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
      </IconButton>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
      >
        <Button
          startIcon={<Add />}
          onClick={handleClickOpen}
          id={props.operazione}
          sx={{
            color: "white",
            display: props.operazione === "editRel" ? "None" : "",
          }}
          variant="outlined"
          className="ButtonMiller"
        >
          Aggiungi
        </Button>
      </ButtonGroup>
      <Dialog
        open={openRel}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="md"
        TransitionComponent={Transition}
      >
        <DialogTitle>
          {props.operazione === "new" ? "Aggiungi" : "Modifica"} Cliente
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} marginTop={1}>
              <Autocomplete
                id="combo-box-demo"
                options={clienti}
                fullWidth
                value={clientiSel}
                getOptionLabel={(option) => option.RagioneSociale}
                onChange={(event, newValue) => {
                  setClientiSel(newValue);
                  let a = { ...utenteCliente };
                  a.IDCliente = newValue ? newValue.ID : null;
                  setUtenteCliente(a);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Clienti" />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between", mx: 2 }}
        >
          <Button
            onClick={handleClose}
            className="ButtonMiller"
            variant="contained"
            startIcon={<Close />}
          >
            Chiudi
          </Button>
          <Button
            onClick={handleSave}
            className="ButtonMiller"
            variant="contained"
            startIcon={<Check />}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const DeleteDialogRel = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteRel = async (utenteCliente) => {
    try {
      await unsplash.delete("relUtentiClienti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: utenteCliente.ID,
        },
      });
      props.handleLoad("success");
    } catch {
      props.handleLoad("error");
    }
    handleClose();
  };

  return (
    <>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare il cliente selezionato?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare il seguente cliente:
            <br />
            <b>{props.utenteCliente?.RagioneSociale}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={() => handleDeleteRel(props.utenteCliente)}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
