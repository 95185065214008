import {
  AccountCircle,
  Business,
  Fingerprint,
  Badge,
  LocationOn,
  Language,
  LocalPhone,
  Email,
} from "@mui/icons-material";
import moment from "moment";
import { useEffect, useState } from "react";
import unsplash from "../../api/unsplash";
import { CircularProgress } from "@mui/material";

export const ProfiloParita = ({}) => {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [cliente, setCliente] = useState({
    SedeLegale: "",
    RagioneSociale: "",
    Piva: "",
    Sito: "",
    Pec: "",
    Telefono: "",
  });
  function designStep(numberStep) {
    const content = [];
    for (let i = 0; i < numberStep; i++) {
      content.push(
        <div
          key={i}
          className={`col-span-1 bg-primary-100 h-5 ${i === 0 && "rounded-l"}`}
        ></div>
      );
    }
    /* for (let i = 0; i < 6 - numberStep; i++) {
      content.push(
        <div
          key={i}
          className="col-span-1 border-2 border-primary-100 h-5 rounded"
        ></div>
      );
    } */
    return content;
  }

  useEffect(() => {
    (async () => {
      setLoading(false);
      let stepGetted;
      let response = await unsplash.post(
        "portaleParita",
        {
          operation: "getCliente",
          id: sessionStorage.getItem("Cliente"),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      stepGetted = response.data[0]?.Step;
      setStep(stepGetted);
      setCliente(response.data[0]);
      setLoading(true);
    })();
  }, []);
  return (
    <>
      <div className="w-full flex ">
        <div className="w-full lg:w-full rounded-md p-3 lg:p-4 bg-primary-600 text-white ">
          {loading ? (
            <div className="grid grid-cols-2 my-2 appear-effect gap-2">
              <div className="col-span-2 flex gap-2 items-center text-lg md:text-2xl">
                Step Completati
              </div>
              <div className="col-span-2 flex gap-2 items-center">
                <div className="w-full grid grid-cols-6  items-center px-[2px] bg-white h-6 rounded-md">
                  {designStep(step)}
                </div>
                <div className="text-white">
                  {Math.round((step / 6) * 100)}%
                </div>
              </div>
              <div className="lg:text-2xl text-lg col-span-2 flex items-center mt-4">
                Dati Aziendali
              </div>
              <div className="col-span-2 grid grid-cols-2 py-2 pr-2 gap-3">
                <div className="lg:text-xl text-lg col-span-2 lg:col-span-1 flex items-center">
                  <Business
                    className="mr-2 text-primary-100"
                    fontSize="large"
                  />
                  Ragione Sociale:
                  {cliente?.RagioneSociale ? " " + cliente?.RagioneSociale : ""}
                </div>
                <div className="lg:text-xl text-lg col-span-2 lg:col-span-1 flex items-center">
                  <LocalPhone
                    className="mr-2 text-primary-100"
                    fontSize="large"
                  />
                  Telefono:
                  {cliente?.Telefono ? " " + cliente?.Telefono : ""}
                </div>
                <div className="lg:text-xl text-lg col-span-2 lg:col-span-1 flex items-center">
                  <Language
                    className="mr-2 text-primary-100"
                    fontSize="large"
                  />
                  Sito:
                  {cliente?.Sito ? " " + cliente?.Sito : ""}
                </div>

                <div className="lg:text-xl text-lg col-span-2 lg:col-span-1 flex items-center">
                  <LocationOn
                    className="mr-2 text-primary-100"
                    fontSize="large"
                  />
                  Sede Legale:
                  {cliente?.SedeLegale ? " " + cliente?.SedeLegale : ""}
                </div>
                <div className="lg:text-xl text-lg col-span-2 lg:col-span-1 flex items-center">
                  <Badge className="mr-2 text-primary-100" fontSize="large" />
                  Partita iva:
                  {cliente?.Piva ? " " + cliente?.Piva : ""}
                </div>
                <div className="lg:text-xl text-lg col-span-2 lg:col-span-1 flex items-center">
                  <Email className="mr-2 text-primary-100" fontSize="large" />
                  Pec:
                  {cliente?.Pec ? " " + cliente?.Pec : ""}
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full justify-center flex">
            <CircularProgress sx={{color:"white"}} thickness={5} size={60}/>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
