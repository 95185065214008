import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { isLogin,isToken } from '../utils';

function PrivateRouteMedico({ children }) {
    const logok = isLogin();


    return logok ? children : <Navigate to="/AreaMedico/Login" />;
  }

export default PrivateRouteMedico;
