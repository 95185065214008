import React, { useEffect, useState, useMemo } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import unsplash from "../../api/unsplash";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  itIT,
} from "@mui/x-data-grid-pro";
import {
  IconButton,
  Grid,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  Toolbar,
  LinearProgress,
  Zoom,
  FormControlLabel,
  Checkbox,
  ButtonGroup,
  Skeleton,
} from "@mui/material";
import {
  Download,
  RemoveRedEye,
  Delete,
  Close,
  Check,
  ContactsOutlined,
  VpnKey,
  FileDownloadDone,
  Folder,
  Upload,
  UploadFile,
  Place,
  VerifiedUser,
  FormatListBulleted,
  Visibility,
} from "@mui/icons-material";
import GlobalSnackbar from "../Snackbar";
import Edit from "@mui/icons-material/Edit";
import moment from "moment";
import { Tooltip } from "recharts";
import { CustomFooterPersonalized } from "../../utils/CustomFooterPersonalized";
import { ModalNewDoc } from "../AccessoLavoratore/AllegatiLavoratori";
import Dropzone from "react-dropzone";
import { useDropzone } from "react-dropzone";
import { LocalizationProvider,DatePicker,PickersDay,TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment as DateAdapter} from '@mui/x-date-pickers/AdapterMoment'
import { ContainerList } from "../AccessoLavoratore/ContainerList";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const ext = {
  doc: "application/msword",
  dot: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  dotx: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  docm: "application/vnd.ms-word.document.macroEnabled.12",
  dotm: "application/vnd.ms-word.template.macroEnabled.12",
  xls: "application/vnd.ms-excel",
  xlt: "application/vnd.ms-excel",
  xla: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xltx: "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  xlsm: "application/vnd.ms-excel.sheet.macroEnabled.12",
  xltm: "application/vnd.ms-excel.template.macroEnabled.12",
  xlam: "application/vnd.ms-excel.addin.macroEnabled.12",
  xlsb: "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  ppt: "application/vnd.ms-powerpoint",
  pot: "application/vnd.ms-powerpoint",
  pps: "application/vnd.ms-powerpoint",
  ppa: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  potx: "application/vnd.openxmlformats-officedocument.presentationml.template",
  ppsx: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  ppam: "application/vnd.ms-powerpoint.addin.macroEnabled.12",
  pptm: "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  potm: "application/vnd.ms-powerpoint.template.macroEnabled.12",
  ppsm: "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
  mdb: "application/vnd.ms-access",
  png: "application/png",
  pdf: "application/pdf",
};

const DetailVisiteComponent = ({ el }) => {
  const navigate = useNavigate();
  return (
    <IconButton
      aria-label="edit"
      onClick={() =>
        navigate("/AreaMedico/DettaglioVisitaMedico", {
          state: { visita: el },
        })
      }
    >
      <Visibility style={{ height: "2rem", width: "2rem", color: "#11526F" }} />
    </IconButton>
  );
};

const VisiteMedici = (props) => {
  const [visite, setVisite] = useState([]);
  const [visiteBackup, setVisiteBackup] = useState([]);

  const [loading, setLoading] = useState(true);
  const [dafare, setDaFare] = useState(false);
  const [dal, setDal] = useState(
    moment(new Date()).subtract(6, "months").format("YYYY-MM-DD")
  );
  const [al, setAl] = useState(moment(new Date()).format("YYYY-MM-DD"));

  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          padding={2}
          columnGap={2}
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }
  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      align: "center",
      headerAlign: "center",
      width: "100",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => <DetailVisiteComponent el={params.row} />,
    },
    {
      field: "DataWithoutTime",
      headerName: "Data",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) =>
        params.row.DataWithoutTime
          ? moment(params.row.DataWithoutTime).format("DD/MM/YYYY")
          : "",
    },
    {
      field: "Luogo",
      headerName: "Luogo",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Lavoratori",
      headerName: "N° Lavoratori",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let respData = await unsplash.post(
          "portaleMedici",
          {
            operation: "GetAll",
            medico: sessionStorage.getItem("Medico"),
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        setVisiteBackup(respData.data.visite);
        setVisite(
          respData.data.visite.filter(
            (el) =>
              moment(el.DataWithoutTime).format("YYYY-MM-DD") >=
                moment(new Date()).subtract(6, "months").format("YYYY-MM-DD") &&
              moment(el.DataWithoutTime).format("YYYY-MM-DD") <=
                moment(moment(new Date()).format("YYYY-MM-DD")).format(
                  "YYYY-MM-DD"
                )
          )
        );
      } catch {
        setVisite([]);
        setVisiteBackup([]);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (dafare) {
      setVisite(
        visiteBackup.filter(
          (el) =>
            moment(new Date()).format("YYYYMMDD") <=
            moment(el.DataWithoutTime).format("YYYYMMDD")
        )
      );
    } else {
      setVisite(
        visiteBackup.filter(
          (el) =>
            moment(el.DataWithoutTime).format("YYYYMMDD") <=
            moment(new Date()).format("YYYYMMDD")
        )
      );
    }
  }, [dafare]);

  useEffect(() => {
    setDaFare(false);
    setVisite(
      visiteBackup.filter(
        (el) =>
          moment(el.DataWithoutTime).format("YYYY-MM-DD") >=
            moment(dal).format("YYYY-MM-DD") &&
          moment(el.DataWithoutTime).format("YYYY-MM-DD") <=
            moment(al).format("YYYY-MM-DD")
      )
    );
  }, [dal, al]);

  return (
    <>
      <div className="lg:hidden">
        {!loading ? (
          <>
            <ContainerList
              data={visite.map((visita) => {
                //Accorpo i giorni uguali
                return {
                  ...visita,
                  DataFilter: moment(visita.DataWithoutTime).format(
                    "DD/MM/YYYY"
                  ),
                };
              })}
              header={["Giorno", "Visita"]}
              leftCard={["DataFilter"]}
              additionalFilter={["DataFilter"]}
              rightCard={[
                { title: true, prop: "Lavoratori" },
                { prop: "Luogo", icon: <Place fontSize="12" /> },
              ]}
              titleCard="Lavoratori: "
              component={<DetailVisiteComponent />}
            />
          </>
        ) : (
          <div className="flex flex-wrap">
            <div className="flex w-full gap-x-2">
              <Skeleton variant="rounded" width="20%" height={50} />
              <Skeleton variant="rectangular" width="4px" height={60} />
              <Skeleton variant="rounded" width="80%" height={50} />
            </div>
            <div className="flex w-full gap-x-2">
              <Skeleton variant="rounded" width="20%" height={50} />
              <Skeleton variant="rectangular" width="4px" height={60} />
              <Skeleton variant="rounded" width="80%" height={50} />
            </div>
            <div className="flex w-full gap-x-2">
              <Skeleton variant="rounded" width="20%" height={50} />
              <Skeleton variant="rectangular" width="4px" height={60} />
              <Skeleton variant="rounded" width="80%" height={50} />
            </div>
            <div className="flex w-full gap-x-2">
              <Skeleton variant="rounded" width="20%" height={50} />
              <Skeleton variant="rectangular" width="4px" height={60} />
              <Skeleton variant="rounded" width="80%" height={50} />
            </div>
            <div className="flex w-full gap-x-2">
              <Skeleton variant="rounded" width="20%" height={50} />
              <Skeleton variant="rectangular" width="4px" height={60} />
              <Skeleton variant="rounded" width="80%" height={50} />
            </div>
            <div className="flex w-full gap-x-2">
              <Skeleton variant="rounded" width="20%" height={50} />
              <Skeleton variant="rectangular" width="4px" height={60} />
              <Skeleton variant="rounded" width="80%" height={50} />
            </div>
            <div className="flex w-full gap-x-2">
              <Skeleton variant="rounded" width="20%" height={50} />
              <Skeleton variant="rectangular" width="4px" height={60} />
              <Skeleton variant="rounded" width="80%" height={50} />
            </div>
          </div>
        )}
      </div>
      <>
        <div className="h-[75vh] md:h-[80vh] w-full lg:block hidden">
          <DataGridPro
            components={{
              Toolbar: CustomToolbar,
              Footer: CustomFooterPersonalized,
            }}
            slots={{
              loadingOverlay: LinearProgress,
            }}
            loading={loading}
            sx={{ fontSize: 12 }}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            rows={visite}
            columns={columns}
            getRowId={(row) => row.ID}
            pageSize={100}
            disableSelectionOnClick
          />
        </div>
      </>
    </>
  );
};

export default VisiteMedici;
