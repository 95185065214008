import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import unsplash from "../../api/unsplash";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  itIT,
} from "@mui/x-data-grid-pro";
import {
  IconButton,
  Grid,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  Toolbar,
  Skeleton,
} from "@mui/material";
import {
  Download,
  RemoveRedEye,
  Delete,
  Close,
  Check,
  ContactsOutlined,
  VpnKey,
  FileDownloadDone,
  CalendarMonth,
  Visibility,
} from "@mui/icons-material";
import GlobalSnackbar from "../Snackbar";
import Edit from "@mui/icons-material/Edit";
import moment from "moment";
import { Tooltip } from "recharts";
import { CustomFooterPersonalized } from "../../utils/CustomFooterPersonalized";
import { ContainerList } from "./ContainerList";
import { AllegatiList } from "./AllegatiList";

const ext = {
  doc: "application/msword",
  dot: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  dotx: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  docm: "application/vnd.ms-word.document.macroEnabled.12",
  dotm: "application/vnd.ms-word.template.macroEnabled.12",
  xls: "application/vnd.ms-excel",
  xlt: "application/vnd.ms-excel",
  xla: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xltx: "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  xlsm: "application/vnd.ms-excel.sheet.macroEnabled.12",
  xltm: "application/vnd.ms-excel.template.macroEnabled.12",
  xlam: "application/vnd.ms-excel.addin.macroEnabled.12",
  xlsb: "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  ppt: "application/vnd.ms-powerpoint",
  pot: "application/vnd.ms-powerpoint",
  pps: "application/vnd.ms-powerpoint",
  ppa: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  potx: "application/vnd.openxmlformats-officedocument.presentationml.template",
  ppsx: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  ppam: "application/vnd.ms-powerpoint.addin.macroEnabled.12",
  pptm: "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  potm: "application/vnd.ms-powerpoint.template.macroEnabled.12",
  ppsm: "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
  mdb: "application/vnd.ms-access",
  png: "application/png",
  pdf: "application/pdf",
};

const AllegatiLavoratore = (props) => {
  const [allegati, setAllegati] = useState([]);
  const navigate = useNavigate();
  const [openBackdrop, setOpenBackDrop] = useState(false);

  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const [allegato, setAllegato] = useState(null);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          padding={2}
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }
  const columnsAllegati = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "100",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <ModalNewDoc
          el={params.row}
          reloadAll={reloadAll}
          download={() =>
            downloadFile(
              params.row.ID,
              params.row.Estensione,
              params.row.Descrizione,
              "download"
            )
          }
          downloadCrypt={downloadFileCrypt}
        />
      ),
    },
    /* {
      filterable: false,
      field: "Criptato",
      headerName: "",
      width: "20",
      renderCell: (params) =>
        params.row.Criptato === 1 ? (
          <VpnKey style={{ color: "#2265B1" }} />
        ) : (
          ""
        ),
    }, */
    {
      filterable: false,
      field: "DataVisualizzazione",
      headerName: "Data Visualizzazione",
      width: "150",
      renderCell: (params) =>
        params.row.DataVisualizzazione
          ? moment(params.row.DataVisualizzazione).format("DD/MM/YYYY")
          : "",
    },

    {
      field: "Descrizione",
      headerName: "Descrizione",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) =>
        params.row.Criptato === 1
          ? params.row.Descrizione.replace(
              params.row.Estensione,
              params.row.EstensioneOriginale
            )
          : params.row.Descrizione,
    },
  ];

  const downloadFile = async (id, estensione, descrizione, operazione) => {
    try {
      const response = await unsplash.post(
        "ftp",
        {
          file: id + estensione,
          nomeOriginale: descrizione.includes(estensione)
            ? descrizione
            : descrizione + estensione,
          operazione: "download",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: ext[estensione.replace(".", "")] })
      );
      const link = document.createElement("a");
      link.href = url;
      if (operazione === "download") {
        link.setAttribute(
          "download",
          descrizione.includes(estensione)
            ? descrizione
            : descrizione + estensione
        );
        document.body.appendChild(link);
        link.click();
      }
      setSnack({
        severity: "success",
        messaggio: "File scaricato con successo",
      });
      setOpenSnack(true);
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante il dowload del file",
      });
      setOpenSnack(true);
    }
  };

  const downloadFileCrypt = async (
    id,
    estensione,
    descrizione,
    operazione,
    criptato,
    estensioneOriginale,
    psw
  ) => {
    //let psw;

    /* if (criptato === 1) {
      psw = window.prompt(
        "Inserisci la tua password per scaricare questo documento"
      );
    } */
    try {
      const response = await unsplash.post(
        "ftp",
        {
          file: id + estensione,
          nomeOriginale: descrizione.includes(estensione)
            ? descrizione
            : descrizione + estensione,
          operazione: "downloadCrypt",
          lavoratore: sessionStorage.getItem("lavoratore"),
          estensioneOriginale: estensioneOriginale,
          password: psw,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: ext[estensione.replace(".", "")] })
      );
      const link = document.createElement("a");
      link.href = url;
      if (operazione === "download") {
        link.setAttribute(
          "download",
          descrizione.replace(estensione, estensioneOriginale)
        );
        document.body.appendChild(link);
        link.click();
      }
      setSnack({
        severity: "success",
        messaggio: "File scaricato con successo",
      });
      setOpenSnack(true);
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante il dowload del file",
      });
      setOpenSnack(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let respData = await unsplash.post(
          "portaleLavoratori",
          {
            operation: "GetAll",
            lavoratore: sessionStorage.getItem("lavoratore"),
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        setAllegati(respData.data.allegati);
      } catch {
        setAllegati([]);
      }
    };
    fetchData();
  }, []);

  const reloadAll = async () => {
    let respData = await unsplash.post(
      "portaleLavoratori",
      { operation: "GetAll", lavoratore: sessionStorage.getItem("lavoratore") },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );

    setAllegati(respData.data.allegati);
  };

  const listAction = async (params) => {
    await setAllegato(params);
    document.getElementById("allegatoList").click();
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />

      <div className="hidden lg:block h-[75vh] md:h-[80vh] w-full bg-transparent">
        <DataGridPro
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooterPersonalized,
          }}
          sx={{ fontSize: 12, backgroundColor: "white" }}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rows={allegati}
          columns={columnsAllegati}
          getRowId={(row) => row.ID}
          pageSize={100}
          disableSelectionOnClick
        />
      </div>
      <div className="lg:hidden block">
        {allegati?.length > 0 ? (
          <>
            <AllegatiList
              data={allegati.map((al) => {
                return {
                  ...al,
                  DataVisualizzazione: al.DataVisualizzazione
                    ? moment(al.DataVisualizzazione).format("DD/MM/YYYY")
                    : "",
                };
              })}
              rightCard={[
                { title: true, prop: "Descrizione" },
                {
                  prop: "DataVisualizzazione",
                  icon: <Visibility fontSize="medium" />,
                },
              ]}
              actions={listAction}
              component={
                <ModalNewDoc
                  reloadAll={reloadAll}
                  downloadCrypt={downloadFileCrypt}
                  download={downloadFile}
                />
              }
            />
          </>
        ) : (
          <div className="flex flex-wrap gap-2">
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AllegatiLavoratore;

export const ModalNewDoc = (props) => {
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [openMessage, setOpenMessage] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confermaEvisuallizza = async () => {
    await unsplash.put(
      "allegati/primaVisualizzazione",
      { id: props.el.ID },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    if (props.el.Criptato === 1) {
      setOpenMessage(true);
    } else {
      props.download();
      props.reloadAll();
      setOpen(false);
    }
  };

  return (
    <>
      <div className="flex flex-row-reverse lg:flex-row">
        <IconButton
          aria-label="edit"
          onClick={handleClickOpen}
          id={"allegatoList"}
        >
          <Download
            style={{ height: "2rem", width: "2rem", color: "#11526f" }}
          />
        </IconButton>
        {props.el?.Criptato === 1 ? (
          <IconButton aria-label="edit">
            <VpnKey style={{ color: "#11526f" }} />
          </IconButton>
        ) : (
          <></>
        )}
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={"md"}
        maxWidth={"md"}
      >
        <div className="py-6 px-6 lg:px-8">
          <h3 className="mb-4 text-xl font-medium text-center text-black">
            {openMessage ? "Inserimento Password" : "Conferma Visualizzazione"}
          </h3>
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="absolute top-4 right-5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="authentication-modal"
          >
            <svg
              aria-hidden="true"
              className="w-7 h-7"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="w-full">
            {openMessage ? (
              <>
                <p>Inserisci la tua password per scaricare questo documento:</p>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="Password"
                    label="Password"
                    type="text"
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
              </>
            ) : (
              <p>
                Confermi di voler visualizzare il seguente documento:{" "}
                <b>{props.el?.Descrizione}</b>?
              </p>
            )}

            <div className="flex justify-between mt-4 ">
              <Button
                className="ButtonMiller"
                variant="contained"
                startIcon={<Close />}
                //className="bg-primary-600 text-white p-2 hover:bg-primary-100 mb-2 transition-all duration-300 ease-in-out"
                onClick={handleClose}
              >
                Annulla
              </Button>
              <Button
                className="ButtonMiller"
                variant="contained"
                startIcon={<Check />}
                //className="bg-primary-600 text-white p-2 hover:bg-primary-100 mb-2 transition-all duration-300 ease-in-out"
                onClick={() => {
                  if (!openMessage) {
                    confermaEvisuallizza();
                  } else {
                    props.downloadCrypt(
                      props.el.ID,
                      props.el.Estensione,
                      props.el.Descrizione,
                      "download",
                      props.el.Criptato,
                      props.el.EstensioneOriginale,
                      password
                    );
                    setOpen(false);
                    setOpenMessage(false);
                    setPassword("");
                  }
                }}
              >
                {openMessage ? "Invia" : "Conferma e visualizza"}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
