import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import unsplash from "../../api/unsplash";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  itIT,
} from "@mui/x-data-grid-pro";
import {
  IconButton,
  Grid,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  Toolbar,
  LinearProgress,
  Zoom,
  FormControlLabel,
  Checkbox,
  ButtonGroup,
  Skeleton,
} from "@mui/material";
import {
  Download,
  RemoveRedEye,
  Delete,
  Close,
  Check,
  ContactsOutlined,
  VpnKey,
  FileDownloadDone,
  Folder,
  Upload,
  UploadFile,
  Place,
  VerifiedUser,
  FormatListBulleted,
  Visibility,
  ConnectingAirportsOutlined,
  MedicalInformation,
  Business,
} from "@mui/icons-material";
import GlobalSnackbar from "../Snackbar";
import Edit from "@mui/icons-material/Edit";
import moment from "moment";
import { Tooltip } from "recharts";
import { CustomFooterPersonalized } from "../../utils/CustomFooterPersonalized";
import { ModalNewDoc } from "../AccessoLavoratore/AllegatiLavoratori";
import Dropzone from "react-dropzone";
import { useDropzone } from "react-dropzone";
import { LocalizationProvider,DatePicker,PickersDay,TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment as DateAdapter} from '@mui/x-date-pickers/AdapterMoment'
import { ContainerList } from "../AccessoLavoratore/ContainerList";
import { AllegatiList } from "../AccessoLavoratore/AllegatiList";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const ext = {
  doc: "application/msword",
  dot: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  dotx: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  docm: "application/vnd.ms-word.document.macroEnabled.12",
  dotm: "application/vnd.ms-word.template.macroEnabled.12",
  xls: "application/vnd.ms-excel",
  xlt: "application/vnd.ms-excel",
  xla: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xltx: "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  xlsm: "application/vnd.ms-excel.sheet.macroEnabled.12",
  xltm: "application/vnd.ms-excel.template.macroEnabled.12",
  xlam: "application/vnd.ms-excel.addin.macroEnabled.12",
  xlsb: "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  ppt: "application/vnd.ms-powerpoint",
  pot: "application/vnd.ms-powerpoint",
  pps: "application/vnd.ms-powerpoint",
  ppa: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  potx: "application/vnd.openxmlformats-officedocument.presentationml.template",
  ppsx: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  ppam: "application/vnd.ms-powerpoint.addin.macroEnabled.12",
  pptm: "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  potm: "application/vnd.ms-powerpoint.template.macroEnabled.12",
  ppsm: "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
  mdb: "application/vnd.ms-access",
  png: "application/png",
  pdf: "application/pdf",
};

const DettaglioVisiteMedici = (props) => {
  const location = useLocation();
  const [visite, setVisite] = useState([]);
  const [visiteBackup, setVisiteBackup] = useState([]);

  const [loading, setLoading] = useState(true);
  const [dafare, setDaFare] = useState(false);
  const [dal, setDal] = useState(
    moment(new Date()).subtract(6, "months").format("YYYY-MM-DD")
  );
  const [al, setAl] = useState(moment(new Date()).format("YYYY-MM-DD"));

  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          padding={2}
          columnGap={2}
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />

          {/* <Grid item>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                ampmInClock={false}
                renderInput={(props) => (
                  <TextField size="small" fullWidth {...props} />
                )}
                label="Dal"
                value={dal}
                onChange={(newValue) => {
                  setDal(newValue ? newValue.format("YYYY-MM-DD") : null);
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                ampmInClock={false}
                renderInput={(props) => (
                  <TextField size="small" fullWidth {...props} />
                )}
                label="Al"
                value={al}
                onChange={(newValue) => {
                  setAl(newValue ? newValue.format("YYYY-MM-DD") : null);
                }}
              />
            </LocalizationProvider>
          </Grid>
          <FormControlLabel
            sx={{ marginBottom: "0px!important" }}
            control={
              <Checkbox
                checked={dafare}
                onClick={(e) => setDaFare(e.target.checked)}
              />
            }
            label="Da svolgere"
          /> */}
        </Grid>
      </GridToolbarContainer>
    );
  }
  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "100",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => <ModalAllegati el={params.row} />,
    },
    {
      field: "Data",
      headerName: "Data",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) =>
        params.row.Data
          ? moment(params.row.Data).format("DD/MM/YYYY HH:mm")
          : "",
    },
    {
      field: "LavoratoreNomeCompleto",
      headerName: "Lavoratore",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "CF",
      headerName: "CF",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Accertamenti",
      headerName: "Accertamenti",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "DescEsito",
      headerName: "Esito",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "DescSede",
      headerName: "Sede",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        let respData = await unsplash.post(
          "portaleMedici",
          {
            operation: "visiteWrapped",
            idMedico: sessionStorage.getItem("Medico"),
            dataVisita: moment(location.state.visita.DataWithoutTime).format(
              "YYYY-MM-DD"
            ),
            luogoVisita: location.state.visita.Luogo,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        setVisiteBackup(respData.data);
        setVisite(
          respData.data.filter(
            (el) =>
              moment(el.Data).format("YYYY-MM-DD") >=
                moment(new Date()).subtract(6, "months").format("YYYY-MM-DD") &&
              moment(el.Data).format("YYYY-MM-DD") <=
                moment(moment(new Date()).format("YYYY-MM-DD")).format(
                  "YYYY-MM-DD"
                )
          )
        );
      } catch {
        setVisite([]);
        setVisiteBackup([]);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (dafare) {
      setVisite(
        visiteBackup.filter(
          (el) =>
            moment(new Date()).format("YYYYMMDD") <=
            moment(el.Data).format("YYYYMMDD")
        )
      );
    } else {
      setVisite(
        visiteBackup.filter(
          (el) =>
            moment(el.Data).format("YYYYMMDD") <=
            moment(new Date()).format("YYYYMMDD")
        )
      );
    }
  }, [dafare]);

  useEffect(() => {
    setDaFare(false);
    setVisite(
      visiteBackup.filter(
        (el) =>
          moment(el.Data).format("YYYY-MM-DD") >=
            moment(dal).format("YYYY-MM-DD") &&
          moment(el.Data).format("YYYY-MM-DD") <=
            moment(al).format("YYYY-MM-DD")
      )
    );
  }, [dal, al]);

  return (
    <>
      <div className="lg:hidden">
        {visite.length > 0 ? (
          <>
            <ContainerList
              data={visite.map((visita) => {
                //Accorpo i giorni uguali
                return {
                  ...visita,
                  DataFilter: moment(visita.Data).format("DD/MM/YYYY HH:mm"),
                  Data: [
                    <b>{moment(visita.Data).format("DD/MM/YYYY")}</b>,
                    <br />,
                    "Alle ",
                    <b>{moment(visita.Data).format("HH:mm")}</b>,
                  ],
                };
              })}
              header={["Giorno", "Visita"]}
              leftCard={["Data"]}
              additionalFilter={["DataFilter"]}
              rightCard={[
                { title: true, prop: "LavoratoreNomeCompleto" },
                { prop: "CF", icon: <MedicalInformation fontSize="12" /> },
                { prop: "Accertamenti", icon: <VerifiedUser fontSize="12" /> },
                { prop: "DescSede", icon: <Business fontSize="12" /> },
              ]}
              titleCard="Lavoratore: "
              component={<ModalAllegati />}
              /* otherFilter={
                <>
                  <div className="col-span-1">
                    <LocalizationProvider dateAdapter={DateAdapter}>
                      <DatePicker
                        ampmInClock={false}
                        renderInput={(props) => (
                          <TextField size="medium" fullWidth {...props} />
                        )}
                        label="Dal"
                        value={dal}
                        onChange={(newValue) => {
                          setDal(
                            newValue ? newValue.format("YYYY-MM-DD") : null
                          );
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="col-span-1">
                    <LocalizationProvider dateAdapter={DateAdapter}>
                      <DatePicker
                        ampmInClock={false}
                        renderInput={(props) => (
                          <TextField size="medium" fullWidth {...props} />
                        )}
                        label="Al"
                        value={al}
                        onChange={(newValue) => {
                          setAl(
                            newValue ? newValue.format("YYYY-MM-DD") : null
                          );
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                  <FormControlLabel
                    sx={{ marginBottom: "0px!important" }}
                    control={
                      <Checkbox
                        checked={dafare}
                        onClick={(e) => setDaFare(e.target.checked)}
                      />
                    }
                    label="Da svolgere"
                  />
                </>
              } */
            />
          </>
        ) : (
          <div className="flex flex-wrap">
            <div className="flex w-full gap-x-2">
              <Skeleton variant="rounded" width="20%" height={50} />
              <Skeleton variant="rectangular" width="4px" height={60} />
              <Skeleton variant="rounded" width="80%" height={50} />
            </div>
            <div className="flex w-full gap-x-2">
              <Skeleton variant="rounded" width="20%" height={50} />
              <Skeleton variant="rectangular" width="4px" height={60} />
              <Skeleton variant="rounded" width="80%" height={50} />
            </div>
            <div className="flex w-full gap-x-2">
              <Skeleton variant="rounded" width="20%" height={50} />
              <Skeleton variant="rectangular" width="4px" height={60} />
              <Skeleton variant="rounded" width="80%" height={50} />
            </div>
            <div className="flex w-full gap-x-2">
              <Skeleton variant="rounded" width="20%" height={50} />
              <Skeleton variant="rectangular" width="4px" height={60} />
              <Skeleton variant="rounded" width="80%" height={50} />
            </div>
            <div className="flex w-full gap-x-2">
              <Skeleton variant="rounded" width="20%" height={50} />
              <Skeleton variant="rectangular" width="4px" height={60} />
              <Skeleton variant="rounded" width="80%" height={50} />
            </div>
            <div className="flex w-full gap-x-2">
              <Skeleton variant="rounded" width="20%" height={50} />
              <Skeleton variant="rectangular" width="4px" height={60} />
              <Skeleton variant="rounded" width="80%" height={50} />
            </div>
            <div className="flex w-full gap-x-2">
              <Skeleton variant="rounded" width="20%" height={50} />
              <Skeleton variant="rectangular" width="4px" height={60} />
              <Skeleton variant="rounded" width="80%" height={50} />
            </div>
          </div>
        )}
      </div>
      <div className="h-[75vh] md:h-[80vh] w-full lg:block hidden">
        <DataGridPro
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooterPersonalized,
          }}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={loading}
          sx={{ fontSize: 12 }}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rows={visite}
          columns={columns}
          getRowId={(row) => row.ID}
          pageSize={100}
          disableSelectionOnClick
        />
      </div>
    </>
  );
};

export default DettaglioVisiteMedici;

const ModalAllegati = (props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [openMessage, setOpenMessage] = useState(false);
  const [allegati, setAllegati] = useState([]);

  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const columnsAllegati = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "100",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <ModalNewDoc
          el={params.row}
          reloadAll={reloadAll}
          download={() =>
            downloadFile(
              params.row.ID,
              params.row.Estensione,
              params.row.Descrizione,
              "download"
            )
          }
          downloadCrypt={downloadFileCrypt}
        />
      ),
    },
    {
      filterable: false,
      field: "DataVisualizzazione",
      headerName: "Data Visualizzazione",
      width: "150",
      renderCell: (params) =>
        params.row.DataVisualizzazione
          ? moment(params.row.DataVisualizzazione).format("DD/MM/YYYY")
          : "",
    },

    {
      field: "Descrizione",
      headerName: "Descrizione",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) =>
        params.row.Criptato === 1
          ? params.row.Descrizione.replace(
              params.row.Estensione,
              params.row.EstensioneOriginale
            )
          : params.row.Descrizione,
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          padding={2}
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const downloadFile = async (id, estensione, descrizione, operazione) => {
    try {
      const response = await unsplash.post(
        "ftp",
        {
          file: id + estensione,
          nomeOriginale: descrizione.includes(estensione)
            ? descrizione
            : descrizione + estensione,
          operazione: "download",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: ext[estensione.replace(".", "")] })
      );
      const link = document.createElement("a");
      link.href = url;
      if (operazione === "download") {
        link.setAttribute(
          "download",
          descrizione.includes(estensione)
            ? descrizione
            : descrizione + estensione
        );
        document.body.appendChild(link);
        link.click();
      }
      setSnack({
        severity: "success",
        messaggio: "File scaricato con successo",
      });
      setOpenSnack(true);
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante il dowload del file",
      });
      setOpenSnack(true);
    }
  };

  const downloadFileCrypt = async (
    id,
    estensione,
    descrizione,
    operazione,
    criptato,
    estensioneOriginale,
    psw
  ) => {
    //let psw;

    /* if (criptato === 1) {
      psw = window.prompt(
        "Inserisci la tua password per scaricare questo documento"
      );
    } */
    try {
      const response = await unsplash.post(
        "ftp",
        {
          file: id + estensione,
          nomeOriginale: descrizione.includes(estensione)
            ? descrizione
            : descrizione + estensione,
          operazione: "downloadCrypt",
          estensioneOriginale: estensioneOriginale,
          password: psw,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: ext[estensione.replace(".", "")] })
      );
      const link = document.createElement("a");
      link.href = url;
      if (operazione === "download") {
        link.setAttribute(
          "download",
          descrizione.replace(estensione, estensioneOriginale)
        );
        document.body.appendChild(link);
        link.click();
      }
      setSnack({
        severity: "success",
        messaggio: "File scaricato con successo",
      });
      setOpenSnack(true);
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante il dowload del file",
      });
      setOpenSnack(true);
    }
  };

  const reloadAll = async () => {
    try {
      setLoading(true);
      let respData = await unsplash.post(
        "portaleMedici",
        {
          operation: "GetAll",
          medico: sessionStorage.getItem("Medico"),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setAllegati(
        respData.data.allegati.filter((el) => el.VisitaMedica === props.el.ID)
      );
      setLoading(false);
    } catch {
      setAllegati([]);
    }
  };

  const handleClickOpen = async () => {
    reloadAll();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Folder style={{ height: "2rem", width: "2rem", color: "#11526F" }} />
      </IconButton>
      <IconButton aria-label="edit">
        {props.el.Criptato === 1 ? (
          <VpnKey style={{ color: "#11526F" }} />
        ) : (
          <></>
        )}
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={"md"}
        maxWidth={"md"}
      >
        <GlobalSnackbar
          severity={snack.severity}
          messaggio={snack.messaggio}
          openSnack={openSnack}
          handleCloseSnack={handleCloseSnack}
        />
        <div className="py-6 px-6 lg:px-8">
          <h3 className="mb-4 text-xl font-medium text-center text-black">
            {openMessage ? "Controllo" : "Allegati"}
          </h3>
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="absolute top-4 right-5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="authentication-modal"
          >
            <svg
              aria-hidden="true"
              className="w-7 h-7"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="w-full">
            <div className="w-full flex justify-end">
              <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
              >
                <FileUploaderMedici
                  visiteMedicheLavoratore={true}
                  colonna="VisitaMedica"
                  id={props.el.ID}
                  colonnaExtra="Lavoratore"
                  medico={props.el.Medico}
                  extra={props.el.Lavoratore}
                  email={props.el.Email}
                  description={
                    moment(props.el.Data).format("DD-MM-YYYY") +
                    " - " +
                    props.el.Nome +
                    " " +
                    props.el.Cognome +
                    " - " +
                    props.el.CF
                  }
                />
                <FileUploaderMediciCrypt
                  visiteMedicheLavoratore={true}
                  visitaMedica={props.el.ID}
                  email={props.el.Email}
                  lavoratore={props.el.Lavoratore}
                />
              </ButtonGroup>
            </div>
            <div className="hidden lg:block h-[45vh] md:h-[50vh] w-full">
              <DataGridPro
                components={{
                  Toolbar: CustomToolbar,
                  Footer: CustomFooterPersonalized,
                }}
                sx={{ fontSize: 12, backgroundColor: "white" }}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                rows={allegati}
                columns={columnsAllegati}
                getRowId={(row) => row.ID}
                pageSize={100}
                disableSelectionOnClick
              />
            </div>
            <div className="lg:hidden block">
              {!loading ? (
                <>
                  <AllegatiList
                    data={allegati.map((al) => {
                      return {
                        ...al,
                        DataVisualizzazione: al.DataVisualizzazione
                          ? moment(al.DataVisualizzazione).format("DD/MM/YYYY")
                          : "",
                      };
                    })}
                    rightCard={[
                      { title: true, prop: "Descrizione" },
                      {
                        prop: "DataVisualizzazione",
                        icon: <Visibility fontSize="medium" />,
                      },
                    ]}
                    component={
                      <ModalNewDoc
                        reloadAll={reloadAll}
                        downloadCrypt={downloadFileCrypt}
                        download={downloadFile}
                      />
                    }
                  />
                </>
              ) : (
                <>
                  <div className="flex flex-wrap gap-2">
                    <div className="flex w-full gap-2">
                      <Skeleton variant="rounded" width={70} height={70} />
                      <Skeleton variant="rounded" width="90%" height={70} />
                    </div>
                    <div className="flex w-full gap-2">
                      <Skeleton variant="rounded" width={70} height={70} />
                      <Skeleton variant="rounded" width="90%" height={70} />
                    </div>
                    <div className="flex w-full gap-2">
                      <Skeleton variant="rounded" width={70} height={70} />
                      <Skeleton variant="rounded" width="90%" height={70} />
                    </div>
                    <div className="flex w-full gap-2">
                      <Skeleton variant="rounded" width={70} height={70} />
                      <Skeleton variant="rounded" width="90%" height={70} />
                    </div>
                    <div className="flex w-full gap-2">
                      <Skeleton variant="rounded" width={70} height={70} />
                      <Skeleton variant="rounded" width="90%" height={70} />
                    </div>
                    <div className="flex w-full gap-2">
                      <Skeleton variant="rounded" width={70} height={70} />
                      <Skeleton variant="rounded" width="90%" height={70} />
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="flex justify-between mt-4 ">
              <Button
                className="ButtonMiller"
                variant="contained"
                startIcon={<Close />}
                //className="bg-primary-600 text-white p-2 hover:bg-primary-100 mb-2 transition-all duration-300 ease-in-out"
                onClick={handleClose}
              >
                Chiudi
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

const FileUploaderMedici = (props) => {
  const [open, setOpen] = useState(false);
  const {
    acceptedFiles,
    isDragAccept,
    isDragReject,
    isFocused,
    getRootProps,
    getInputProps,
  } = useDropzone();
  const [files, setFiles] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      setSnack({
        severity: "error",
        messaggio: "Nessun file selezionato",
      });
      setOpenSnack(true);
    } else if (props.visiteMedicheLavoratore) {
      if (files.length > 1) {
        setSnack({
          severity: "error",
          messaggio: "Carica solo un file",
        });
        setOpenSnack(true);
      } else {
        const formData = new FormData();
        formData.append("description", props.description);
        formData.append("colonna", props.colonna);
        formData.append("ID", props.id);
        formData.append("colonnaExtra", props.colonnaExtra);
        formData.append("extra", props.extra);
        formData.append("email", props.email);
        files.map((file) => {
          formData.append("file", file);
        });
        try {
          await unsplash.post("caricaDoc", formData, {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          });
          setSnack({
            severity: "success",
            messaggio: "File caricato con successo",
          });
          setOpenSnack(true);
          //props.reloadAfterUpload();
        } catch {
          setSnack({
            severity: "error",
            messaggio: "Errore durante il caricamento",
          });
          setOpenSnack(true);
        }
      }
    } else {
      const formData = new FormData();
      formData.append("colonna", props.colonna);
      formData.append("ID", props.id);
      if (
        props.hasOwnProperty("colonnaExtra") &&
        props.colonnaExtra !== undefined &&
        props.colonnaExtra !== ""
      ) {
        formData.append("colonnaExtra", props.colonnaExtra);
        formData.append("extra", props.extra);
      }
      files.map((file) => {
        formData.append("file", file);
      });
      try {
        await unsplash.post("caricaDoc", formData, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });

        setSnack({
          severity: "success",
          messaggio: "File caricato con successo",
        });
        setOpenSnack(true);
        props.reloadAfterUpload();
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante il caricamento",
        });
        setOpenSnack(true);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      <Button
        className="ButtonMiller"
        startIcon={<Upload />}
        onClick={() => {
          setOpen(true);
          setFiles([]);
        }}
      >
        ASSOCIA IDONEITÀ
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <GlobalSnackbar
          severity={snack.severity}
          messaggio={snack.messaggio}
          openSnack={openSnack}
          handleCloseSnack={handleCloseSnack}
        />
        <DialogTitle>Carica File</DialogTitle>
        <DialogContent>
          <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Trascina e rilascia qui o fai clic per selezionare i file</p>
              </div>
            )}
          </Dropzone>
          <aside>
            <h4 style={{ marginTop: "10px", textAlign: "center" }}>Files</h4>
            {files?.map((file) => {
              return (
                <>
                  <ul style={{ textAlign: "center", padding: 0 }}>
                    {file.path}
                  </ul>
                </>
              );
            })}
          </aside>
        </DialogContent>
        <DialogActions
          xs={12}
          item
          sx={{
            display: "flex",
            mt: 2,
            justifyContent: "space-between",
            px: 2,
          }}
          fullWidth
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
          >
            Annulla
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Upload />}
            onClick={handleUpload}
          >
            Carica
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const FileUploaderMediciCrypt = (props) => {
  const [open, setOpen] = useState(false);
  const {
    acceptedFiles,
    isDragAccept,
    isDragReject,
    isFocused,
    getRootProps,
    getInputProps,
  } = useDropzone();
  const [files, setFiles] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      setSnack({
        severity: "error",
        messaggio: "Nessun file selezionato",
      });
      setOpenSnack(true);
    } else {
      const formData = new FormData();
      formData.append("visitaMedica", props.visitaMedica);
      formData.append("lavoratore", props.lavoratore);
      formData.append("email", props.email);
      files.map((file) => {
        formData.append("file", file);
      });
      try {
        await unsplash.post("caricaDocCrypt", formData, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        setSnack({
          severity: "success",
          messaggio: "File caricato con successo",
        });
        setOpenSnack(true);
        //props.reloadAfterUpload();
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante il caricamento",
        });
        setOpenSnack(true);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      <Button
        className="ButtonMiller"
        startIcon={<VpnKey />}
        onClick={() => {
          setOpen(true);
          setFiles([]);
        }}
      >
        ASSOCIA ESITO PERSONALE
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <GlobalSnackbar
          severity={snack.severity}
          messaggio={snack.messaggio}
          openSnack={openSnack}
          handleCloseSnack={handleCloseSnack}
        />
        <DialogTitle>Carica File</DialogTitle>
        <DialogContent>
          <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Trascina e rilascia qui o fai clic per selezionare i file</p>
              </div>
            )}
          </Dropzone>
          <aside>
            <h4 style={{ marginTop: "10px", textAlign: "center" }}>Files</h4>
            {files?.map((file) => {
              return (
                <>
                  <ul style={{ textAlign: "center", padding: 0 }}>
                    {file.path}
                  </ul>
                </>
              );
            })}
          </aside>
        </DialogContent>
        <DialogActions
          xs={12}
          item
          sx={{
            display: "flex",
            mt: 2,
            justifyContent: "space-between",
            px: 2,
          }}
          fullWidth
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
          >
            Annulla
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Upload />}
            onClick={handleUpload}
          >
            Carica
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
