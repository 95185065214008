import React, { useEffect, useState } from "react";
import unsplash from "../api/unsplash";
import Avatar from "@mui/material/Avatar";
import GlobalSnackbar from "./Snackbar";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SignpostIcon from "@mui/icons-material/Signpost";
import EngineeringIcon from "@mui/icons-material/Engineering";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import {
  Grid,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Typography,
  CardActions,
  Autocomplete,
  Zoom,
  DialogActions,
  DialogContentText,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  ListItemAvatar,
  OutlinedInput,
} from "@mui/material";

import PropTypes from "prop-types";
import { IconButton } from "@mui/material";

import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";

import {
  Add,
  Delete,
  Close,
  Check,
  Edit,
  ForkRight,
} from "@mui/icons-material";
import LinearProgress from "@mui/material/LinearProgress";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <GridToolbarQuickFilter placeholder="Cerca..." />
      </Grid>
    </GridToolbarContainer>
  );
}

export const AnagraficheDPI = () => {
  const [value, setValue] = useState(0);
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid item xs={12}>
        <Card>
          <CardContent sx={{ p: { xs: 0 } }}>
            <Grid item xs={12}>
              <Card>
                <CardContent
                  style={{
                    paddingLeft: "32px",
                    paddingRight: "32px",
                    paddingTop: 0,
                  }}
                >
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      textColor="inherit"
                      variant="scrollable"
                      onChange={handleChangeTab}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        icon={<EngineeringIcon />}
                        iconPosition="start"
                        label="DPI"
                      />
                      <Tab
                        icon={<SensorOccupiedIcon />}
                        iconPosition="start"
                        label="Protezioni"
                      />
                      <Tab
                        icon={<SignpostIcon />}
                        iconPosition="start"
                        label="Pittogrammi"
                      />
                      <Tab
                        icon={<AdminPanelSettingsIcon />}
                        iconPosition="start"
                        label="Pittogrammi Caratteristiche"
                      />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                    <DPI setSnack={setSnack} setOpenSnack={setOpenSnack} />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Protezioni
                      setSnack={setSnack}
                      setOpenSnack={setOpenSnack}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Pittogrammi
                      setSnack={setSnack}
                      setOpenSnack={setOpenSnack}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <PittogrammiCat
                      setSnack={setSnack}
                      setOpenSnack={setOpenSnack}
                    />
                  </TabPanel>
                </CardContent>
              </Card>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const DPI = ({ setSnack, setOpenSnack }) => {
  const [dpi, setDpi] = useState([]);
  const [protezioni, setProtezioni] = useState([]);
  const [pittogrammi, setPittogrammi] = useState([]);
  const [loading, setLoading] = useState(true);
  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <DettaglioDPI
            title="Modifica Anagrafica DPI"
            id="editDPI"
            dpiSel={params.row}
            saveFunction={handleSave}
            deleteFunction={handleDelete}
            protezioni={protezioni}
            pittogrammi={pittogrammi}
          />
          <EliminazioneDPI dpiSel={params.row} deleteFunction={handleDelete} />
        </>
      ),
    },
    {
      field: "NomeProtezione",
      headerName: "Protezione",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Tipologia",
      headerName: "Tipologia",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Normativa",
      headerName: "Normativa",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Pittogramma",
      headerName: "Pittogramma",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Avatar
          sx={{
            width: 50,
            height: 50,
          }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
            }}
            src={
              params.row.Pittogramma !== "" && params.row.Pittogramma
                ? "data:image/png;base64," + params.row.Pittogramma
                : require("../assets/images/MILLERLogo.jpg")
            }
            alt=""
          />
        </Avatar>
      ),
    },
    {
      field: "Descrizione",
      headerName: "Descrizione",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Info",
      headerName: "Info",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Specifica",
      headerName: "Specifica",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  const handleSave = async (dpiSelected, operazione) => {
    if (operazione === "newDPI") {
      try {
        await unsplash.post(
          "anagraficaDPI",
          {
            anagraficaDPI: dpiSelected,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "DPI creato con successo",
        });
        setOpenSnack(true);
        loadData();
      } catch (error) {
        setSnack({
          severity: "error",
          messaggio: "Errore durante la creazione",
        });
        setOpenSnack(true);
      }
    } else {
      try {
        await unsplash.put(
          "anagraficaDPI",
          {
            anagraficaDPI: dpiSelected,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        if (operazione === "deleteProtezione") {
          setSnack({
            severity: "success",
            messaggio: "Protezione eliminata con successo",
          });
        } else {
          setSnack({
            severity: "success",
            messaggio: "Protezione modificata con successo",
          });
        }
        setOpenSnack(true);
        loadData();
      } catch (error) {
        if (operazione === "deleteProtezione") {
          setSnack({
            severity: "error",
            messaggio: "Errore durante l'eliminazione",
          });
        } else {
          setSnack({
            severity: "error",
            messaggio: "Errore durante la modificata",
          });
        }

        setOpenSnack(true);
      }
    }
  };
  const handleDelete = async (dpi) => {
    try {
      await unsplash.delete("anagraficaDPI", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          ID: dpi,
        },
      });
      setSnack({
        severity: "success",
        messaggio: "DPI eliminato con successo",
      });
      setOpenSnack(true);
      loadData();
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione",
      });
      setOpenSnack(true);
    }
  };

  const loadData = async () => {
    let response = await unsplash.get("anagraficaDPI", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setDpi(response.data.data);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      loadData();
      let responseProtezioni = await unsplash.get("protezioni", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setProtezioni(responseProtezioni.data.data);
      let responsePittogrammi = await unsplash.get("pittogrammiDPI", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setPittogrammi(responsePittogrammi.data.data);
    })();
  }, []);

  return (
    <>
      <Grid item xs={12} md={4}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <DettaglioDPI
            title="Nuova Anagrafica DPI"
            id="newDPI"
            saveFunction={handleSave}
            deleteFunction={handleDelete}
            protezioni={protezioni}
            pittogrammi={pittogrammi}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          height: { xs: "75vh", md: "60vh", xl: "75vh" },
          width: 1,
        }}
      >
        <DataGridPro
          initialState={{
            pinnedColumns: { left: ["Actions"] },
          }}
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooterPersonalized,
          }}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={loading}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rowHeight={52}
          rows={dpi}
          columns={columns}
          getRowId={(row) => row.ID}
          pageSize={20}
          rowsPerPageOptions={[20]}
          disableSelectionOnClick
        />
      </Box>
    </>
  );
};
const DettaglioDPI = ({
  title,
  id,
  dpiSel,
  saveFunction,
  deleteFunction,
  protezioni,
  pittogrammi,
}) => {
  const [open, setOpen] = useState(false);
  const [protezione, setProtezione] = useState(null);
  const [pittogramma, setPittogramma] = useState(null);
  const [dpiSelezionato, setDpiSelezionato] = useState({
    IDProtezione: null,
    Tipologia: "",
    Normativa: "",
    IDPittogramma: null,
    Descrizione: "",
    Info: "",
    Specifica: "",
    Eliminato: 0,
  });

  const handleClickOpen = () => {
    if (id === "editDPI") {
      setDpiSelezionato(dpiSel);
      setPittogramma(
        pittogrammi?.filter((el) => el.ID === dpiSel.IDPittogramma)[0]
      );
      setProtezione(
        protezioni?.filter((el) => el.ID === dpiSel.IDProtezione)[0]
      );
    }
    setOpen(true);
  };
  const handleClose = () => {
    setDpiSelezionato({
      IDProtezione: null,
      Tipologia: "",
      Normativa: "",
      IDPittogramma: null,
      Descrizione: "",
      Info: "",
      Specifica: "",
      Eliminato: 0,
    });
    setOpen(false);
  };
  const handleChange = (e) => {
    let copiaDpi = { ...dpiSelezionato };
    copiaDpi[e.target.name] = e.target.value;
    setDpiSelezionato(copiaDpi);
  };
  const handleSave = async () => {
    saveFunction(dpiSelezionato, id);
    handleClose();
  };
  const handleImage = (event) => {
    const {
      target: { value },
    } = event;
    setPittogramma(typeof value === "string" ? value.split(",") : value);
    let r = { ...dpiSelezionato };
    r.IDPittogramma = value;
    setDpiSelezionato(r);
  };

  return (
    <>
      <IconButton
        style={{ display: id !== "editDPI" ? "None" : "" }}
        aria-label="editDPI"
        onClick={handleClickOpen}
        id={id}
      >
        <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
      </IconButton>

      <Button
        startIcon={<Add />}
        onClick={handleClickOpen}
        id={id}
        sx={{
          color: "white",
          display: id === "editDPI" ? "None" : "",
        }}
        variant="outlined"
        className="ButtonMiller"
      >
        Aggiungi
      </Button>

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="xl"
          TransitionComponent={Transition}
        >
          {/* Title Container */}
          <DialogTitle
            sx={{
              mx: "auto",
            }}
          >
            {title}
          </DialogTitle>
          {/* Body Container */}
          <DialogContent>
            <Grid container marginTop={2} spacing={1}>
              <Grid item xs={12} md={4}>
                <Autocomplete
            
                  id="combo-box-demo"
                  options={protezioni}
                  getOptionLabel={(option) => option.Descrizione}
                  value={protezione}
                  fullWidth
                  onChange={(event, newValue) => {
                    let c = { ...dpiSelezionato };
                    c.IDProtezione = newValue.ID ? newValue.ID : null;
                    setDpiSelezionato(c);
                    setProtezione(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="Protezione" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControl sx={{ width: "100%", padding: 0 }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Pittogramma
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    defaultValue={pittogramma?.ID}
                    onChange={handleImage}
                    input={<OutlinedInput label="Pittogramma" />}
                  >
                    {pittogrammi.map((pit) => (
                      <MenuItem key={pit.ID} value={pit.ID} style={{}}>
                        <ListItemAvatar>
                          <Avatar
                            sx={{ height: 30, width: 30 }}
                            alt="Remy Sharp"
                            src={"data:image/png;base64," + pit?.Immagine}
                          />
                        </ListItemAvatar>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={dpiSelezionato.Tipologia}
                  id="outlined-basic"
                  label="Tipologia"
                  name="Tipologia"
                  onChange={handleChange}
                  fullWidth
                  type="text"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={dpiSelezionato.Normativa}
                  id="outlined-basic"
                  label="Normativa"
                  name="Normativa"
                  onChange={handleChange}
                  fullWidth
                  type="text"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  value={dpiSelezionato.Descrizione}
                  id="outlined-basic"
                  label="Descrizione"
                  name="Descrizione"
                  onChange={handleChange}
                  fullWidth
                  type="text"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={dpiSelezionato.Info}
                  id="outlined-basic"
                  label="Info"
                  name="Info"
                  onChange={handleChange}
                  fullWidth
                  type="text"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={dpiSelezionato.Specifica}
                  id="outlined-basic"
                  label="Specifica"
                  name="Specifica"
                  onChange={handleChange}
                  fullWidth
                  type="text"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mx: 2,
            }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Close />}
            >
              Chiudi
            </Button>
            <Button
              onClick={handleSave}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Check />}
            >
              Salva
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};
const EliminazioneDPI = ({ dpiSel, deleteFunction }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare la DPI selezionato?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare:<br/>
            <b>{dpiSel?.Tipologia}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button
            onClick={() => {
              deleteFunction(dpiSel?.ID);
              handleClose();
            }}
            
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const Protezioni = ({ setSnack, setOpenSnack }) => {
  const [protezioni, setProtezioni] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleDelete = async (IDProtezione) => {
    try {
      await unsplash.delete("protezioni", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          ID: IDProtezione,
        },
      });
      setSnack({
        severity: "success",
        messaggio: "Protezione eliminata con successo",
      });
      setOpenSnack(true);
      loadData();
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione",
      });
      setOpenSnack(true);
    }
  };
  const handleSave = async (protezione, operazione) => {
    if (operazione === "newProtezioni") {
      try {
        await unsplash.post(
          "protezioni",
          {
            protezioni: protezione,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Protezione creata con successo",
        });
        setOpenSnack(true);
        loadData();
      } catch (error) {
        setSnack({
          severity: "error",
          messaggio: "Errore durante la creazione",
        });
        setOpenSnack(true);
      }
    } else {
      try {
        await unsplash.put(
          "protezioni",
          {
            protezioni: protezione,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        if (operazione === "deleteProtezione") {
          setSnack({
            severity: "success",
            messaggio: "Protezione eliminata con successo",
          });
        } else {
          setSnack({
            severity: "success",
            messaggio: "Protezione modificata con successo",
          });
        }
        setOpenSnack(true);
        loadData();
      } catch (error) {
        if (operazione === "deleteProtezione") {
          setSnack({
            severity: "error",
            messaggio: "Errore durante l'eliminazione",
          });
        } else {
          setSnack({
            severity: "error",
            messaggio: "Errore durante la modificata",
          });
        }

        setOpenSnack(true);
      }
    }
  };

  const loadData = async () => {
    let response = await unsplash.get("protezioni", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setProtezioni(response.data.data);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      loadData();
    })();
  }, []);
  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <DettaglioProtezioni
            id="editProtezioni"
            protezioneSel={params.row}
            title="Modifica Protezione"
            saveFunction={handleSave}
          />
          <EliminazioneProtezioni
            protezione={params.row}
            deleteFunction={handleDelete}
          />
        </>
      ),
    },
    {
      field: "Descrizione",
      headerName: "Descrizione",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];
  return (
    <>
      <Grid item xs={12} md={4}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <DettaglioProtezioni
            id="newProtezioni"
            title="Nouva Protezione"
            saveFunction={handleSave}
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          height: { xs: "75vh", md: "60vh", xl: "75vh" },
          width: 1,
        }}
      >
        <DataGridPro
          initialState={{
            pinnedColumns: { left: ["Actions"] },
          }}
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooterPersonalized,
          }}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={loading}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rowHeight={52}
          rows={protezioni}
          columns={columns}
          getRowId={(row) => row.ID}
          pageSize={20}
          rowsPerPageOptions={[20]}
          disableSelectionOnClick
        />
      </Box>
    </>
  );
};
const DettaglioProtezioni = ({ id, protezioneSel, title, saveFunction }) => {
  const [open, setOpen] = useState(false);
  const [protezione, setProtezione] = useState({
    Descrizione: "",
    Eliminato: 0,
  });

  const handleClickOpen = () => {
    if (id === "editProtezioni") {
      setProtezione(protezioneSel);
    }
    setOpen(true);
  };
  const handleClose = () => {
    setProtezione({
      Descrizione: "",
      Eliminato: 0,
    });
    setOpen(false);
  };
  const handleChange = (e) => {
    let copiaProtezione = { ...protezione };
    copiaProtezione[e.target.name] = e.target.value;
    setProtezione(copiaProtezione);
  };
  const handleSave = async () => {
    saveFunction(protezione, id);
    handleClose();
  };
  return (
    <>
      <IconButton
        style={{ display: id !== "editProtezioni" ? "None" : "" }}
        aria-label="editProtezioni"
        onClick={handleClickOpen}
        id={id}
      >
        <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
      </IconButton>

      <Button
        startIcon={<Add />}
        onClick={handleClickOpen}
        id={id}
        sx={{
          color: "white",
          display: id === "editProtezioni" ? "None" : "",
        }}
        variant="outlined"
        className="ButtonMiller"
      >
        Aggiungi
      </Button>

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="xs"
          TransitionComponent={Transition}
        >
          <DialogTitle
            sx={{
              mx: "auto",
            }}
          >
            {title}
          </DialogTitle>
          <DialogContent>
            <Grid container marginTop={2} spacing={1}>
              <Grid item xs={12}>
                <TextField
                  value={protezione.Descrizione}
                  id="outlined-basic"
                  label="Descrizione"
                  name="Descrizione"
                  onChange={handleChange}
                  fullWidth
                  type="text"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mx: 2,
            }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Close />}
            >
              Chiudi
            </Button>
            <Button
              onClick={handleSave}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Check />}
            >
              Salva
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};
const EliminazioneProtezioni = ({ protezione, deleteFunction }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare la Protezione selezionata?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare:<br/>
            <b>{protezione?.Descrizione}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button
            onClick={() => {
              deleteFunction(protezione?.ID);
              handleClose();
            }}
            
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const Pittogrammi = ({ setSnack, setOpenSnack }) => {
  const [pittogrammi, setPittogrammi] = useState([]);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "150",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <DettaglioPittogrammi
            id="editPittogrammi"
            pittogramma={params.row}
            title="Modifica Pittogramma"
            saveFunction={handleSave}
          />
          <EliminazionePittogrammi
            dialogTitle="Sei sicuro di voler eliminare il Pittogramma selezionato?"
            pittogramma={params.row}
          />
          <AggiungiCaratteristiche
            pittogramma={params.row}
            saveFunction={handleSave}
            deleteFunction={handleDelete}
            title="Pittogramma Caratteristiche"
          />
        </>
      ),
    },
    {
      field: "Immagine",
      headerName: "Immagine",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Avatar
          sx={{
            width: 50,
            height: 50,
          }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
            }}
            src={
              params.row.Immagine !== "" && params.row.Immagine
                ? "data:image/png;base64," + params.row.Immagine
                : require("../assets/images/MILLERLogo.jpg")
            }
            alt=""
          />
        </Avatar>
      ),
    },
  ];
  const handleDelete = async (IDPittogramma) => {
    try {
      await unsplash.delete("pittogrammiDPI", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          ID: IDPittogramma,
        },
      });
      setSnack({
        severity: "success",
        messaggio: "Pittogramma eliminato con successo",
      });
      setOpenSnack(true);
      loadData();
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione",
      });
      setOpenSnack(true);
    }
  };
  const handleSave = async (pittogramma, operazione) => {
    if (operazione === "newPittogrammi") {
      try {
        await unsplash.post(
          "pittogrammiDPI",
          {
            pittogrammiDPI: pittogramma,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Pittogramma creato con successo",
        });
        setOpenSnack(true);
        loadData();
      } catch (error) {
        setSnack({
          severity: "error",
          messaggio: "Errore durante la creazione",
        });
        setOpenSnack(true);
      }
    } else {
      try {
        await unsplash.put(
          "pittogrammiDPI",
          {
            pittogrammiDPI: pittogramma,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        if (operazione === "deletePittogramma") {
          setSnack({
            severity: "success",
            messaggio: "Pittogramma eliminato con successo",
          });
        } else {
          setSnack({
            severity: "success",
            messaggio: "Pittogramma modificato con successo",
          });
        }
        setOpenSnack(true);
        loadData();
      } catch (error) {
        if (operazione === "deletePittogramma") {
          setSnack({
            severity: "error",
            messaggio: "Errore durante l'eliminazione",
          });
        } else {
          setSnack({
            severity: "error",
            messaggio: "Errore durante la modificata",
          });
        }

        setOpenSnack(true);
      }
    }
  };

  const loadData = async () => {
    let response = await unsplash.get("pittogrammiDPI", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setPittogrammi(response.data.data);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      loadData();
    })();
  }, []);

  return (
    <>
      <Grid item xs={12} md={4}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <DettaglioPittogrammi
            id="newPittogrammi"
            title="Nouvo Pittogramma"
            saveFunction={handleSave}
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          height: { xs: "75vh", md: "60vh", xl: "75vh" },
          width: 1,
        }}
      >
        <DataGridPro
          initialState={{
            pinnedColumns: { left: ["Actions"] },
          }}
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooterPersonalized,
          }}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={loading}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rowHeight={52}
          rows={pittogrammi}
          columns={columns}
          getRowId={(row) => row.ID}
          pageSize={20}
          rowsPerPageOptions={[20]}
          disableSelectionOnClick
        />
      </Box>
    </>
  );
};
const DettaglioPittogrammi = (props) => {
  const [open, setOpen] = useState(false);
  const [pittogramma, setPittogramma] = useState({
    Immagine: "",
    Eliminato: 0,
  });

  const handleImage = async (e) => {
    const img = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(img);
    reader.onload = function () {
      document.getElementById("firmaFormatore").src = reader.result;
      let f = { ...pittogramma };
      let foto = reader.result.replace("data:image/jpeg;base64,", "");
      foto = foto.replace("data:image/png;base64,", "");
      f.Immagine = foto;
      setPittogramma(f);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleClickOpen = () => {
    if (props.id === "editPittogrammi") {
      setPittogramma(props.pittogramma);
    }
    setOpen(true);
  };
  const handleClose = () => {
    setPittogramma({
      Descrizione: "",
      Eliminato: 0,
    });
    setOpen(false);
  };
  const handleChange = (e) => {
    let copiaPittogramma = { ...pittogramma };
    copiaPittogramma[e.target.name] = e.target.value;
    setPittogramma(copiaPittogramma);
  };
  const handleSave = async () => {
    props.saveFunction(pittogramma, props.id);
    handleClose();
  };
  return (
    <>
      <IconButton
        style={{ display: props.id !== "editPittogrammi" ? "None" : "" }}
        aria-label="editPittogrammi"
        onClick={handleClickOpen}
        id={props.id}
      >
        <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
      </IconButton>

      <Button
        startIcon={<Add />}
        onClick={handleClickOpen}
        id={props.id}
        sx={{
          color: "white",
          display: props.id === "editPittogrammi" ? "None" : "",
        }}
        variant="outlined"
        className="ButtonMiller"
      >
        Aggiungi
      </Button>

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="xs"
          TransitionComponent={Transition}
        >
          <DialogTitle
            sx={{
              mx: "auto",
            }}
          >
            {props.title}
          </DialogTitle>
          <DialogContent>
            <Card
              sx={{
                mt: 2,
                alignContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Card
                sx={{
                  py: 4,
                  border: props.id === "new" ? "1px dashed gray" : "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  document.getElementById("file").click();
                }}
              >
                <Box
                  height="140"
                  src={
                    pittogramma.Immagine !== "" && pittogramma.Immagine
                      ? "data:image/png;base64," + pittogramma.Immagine
                      : require("../assets/images/MILLERLogo.jpg")
                  }
                  id="firmaFormatore"
                  alt="img"
                  component="img"
                  sx={{
                    height: "100%",
                    maxHeight: "250px",
                    mx: "auto",
                    display: "flex",
                  }}
                />
                <label
                  htmlFor="contained-button-file"
                  style={{ display: "none", margin: 0 }}
                >
                  <Input
                    accept="image/*"
                    id="file"
                    name="Immagine"
                    multiple
                    type="file"
                    onChange={(e) => handleImage(e)}
                    hidden
                  />
                </label>
              </Card>
              <CardActions>
                <Button
                  fullWidth
                  variant="text"
                  onClick={() => {
                    document.getElementById("file").click();
                  }}
                >
                  Carica Foto
                </Button>
              </CardActions>
            </Card>
          </DialogContent>
          <DialogActions
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mx: 2,
            }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Close />}
            >
              Chiudi
            </Button>
            <Button
              onClick={handleSave}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Check />}
            >
              Salva
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};
const EliminazionePittogrammi = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          {props.dialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare:
          </DialogContentText>
          <Card
            sx={{
              mt: 2,
              alignContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Card
              sx={{
                py: 4,
                //backgroundColor: "#11526F",
                border: props.id === "new" ? "1px dashed gray" : "none",
              }}
            >
              <Box
                height="140"
                src={
                  props.pittogramma?.Immagine !== "" &&
                  props.pittogramma?.Immagine
                    ? "data:image/png;base64," + props.pittogramma?.Immagine
                    : require("../assets/images/MILLERLogo.jpg")
                }
                id="firmaFormatore"
                alt="img"
                component="img"
                sx={{
                  height: "100%",
                  maxHeight: "250px",
                  mx: "auto",
                  display: "flex",
                }}
              />
              <label
                htmlFor="contained-button-file"
                style={{ display: "none", margin: 0 }}
              >
                <Input
                  accept="image/*"
                  id="file"
                  name="Immagine"
                  multiple
                  type="file"
                  //onChange={(e) => handleImage(e)}
                  hidden
                />
              </label>
            </Card>
          </Card>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button
            onClick={() => {
              props.deleteFunction(props.pittogramma?.ID);
              handleClose();
            }}
            
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const AggiungiCaratteristiche = (props) => {
  const [open, setOpen] = useState(false);
  const [relPittoCara, setRelPittoCara] = useState([]);
  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pittogrammiCat, setPittogrammiCat] = useState([]);
  const [selectedPittogrammi, setSelectedPittogrammi] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const [openSecondSnack, setOpenSecondSnack] = useState(false);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleCloseSecondSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSecondSnack(false);
  };

  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handlePittogrammiChange = (event) => {
    setSelectedPittogrammi(event.target.value);
  };

  useEffect(() => {
    (async () => {
      loadDataCaratt();
    })();
  }, []);

  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <EliminazionePittogrammi
            dialogTitle="Sei sicuro di voler disassociare la seguente caratteristica?"
            pittogramma={params.row}
            deleteFunction={() => handleDelete(params.row.ID)}
          />
        </>
      ),
    },
    {
      field: "Immagine",
      headerName: "Immagine",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Avatar
          sx={{
            width: 50,
            height: 50,
            borderRadius: 0,
          }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
            }}
            src={
              params.row.Immagine !== "" && params.row.Immagine
                ? "data:image/png;base64," + params.row.Immagine
                : require("../assets/images/MILLERLogo.jpg")
            }
            alt=""
          />
        </Avatar>
      ),
    },
  ];

  const handleClickOpen = () => {
    loadData();
    setOpen(true);
  };

  const handleSecondDialogOpen = () => {
    setPittogrammiCat(
      pittogrammiCat.filter(
        (i) =>
          !relPittoCara.filter((y) => y.IDPittogrammaCaratteristiche === i.ID)
            .length
      )
    );
    setOpenSecondDialog(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSecondClose = () => {
    setOpenSecondDialog(false);
    setSelectedPittogrammi([]);
    setOpenSnack(false);
  };

  const handleDelete = async (IDPittogrammaCaratteristiche) => {
    try {
      await unsplash.delete("pittogrammaCaratteristiche/rel", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          ID: IDPittogrammaCaratteristiche,
        },
      });
      setSnack({
        severity: "success",
        messaggio: "Caratteristica eliminata con successo",
      });
      setOpenSnack(true);
      loadData();
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione della Caratteristica",
      });
      setOpenSnack(true);
    }
  };

  const handleSave = async () => {
    const selectedPittogrammiID = selectedPittogrammi.map(
      (pittogramma) => pittogramma.ID
    );
    if (selectedPittogrammiID.length !== 0) {
      try {
        await unsplash.post(
          "pittogrammaCaratteristiche/rel",
          {
            IDPittogramma: props.pittogramma.ID,
            IDPittogrammaCaratteristiche: selectedPittogrammiID,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        setSnack({
          severity: "success",
          messaggio: "Operazione di associazione effettuata con successo",
        });
        setOpenSecondSnack(true);

        setOpenSecondDialog(false);
        await loadData(); // Wait for data to be loaded before clearing selectedPittogrammi
        setSelectedPittogrammi([]);
      } catch (error) {
        setSnack({
          severity: "error",
          messaggio: "Errore durante disassociazione",
        });
        setOpenSecondSnack(true);
      }
    } else {
      setSnack({
        severity: "error",
        messaggio: "Per favore seleziona almeno una delle Caratteristiche",
      });
      setOpenSecondSnack(true);
    }
  };

  const loadDataCaratt = async () => {
    let response = await unsplash.get("pittogrammaCaratteristiche", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setPittogrammiCat(response.data.data);
  };

  const loadData = async () => {
    let response = await unsplash.get("pittogrammaCaratteristiche/:id", {
      params: {
        ID: props.pittogramma.ID,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setRelPittoCara(response.data.data);
    setLoading(false);
  };

  return (
    <>
      <IconButton
        aria-label="editProtezioni"
        onClick={handleClickOpen}
        id={props.id}
      >
        <AddModeratorIcon
          style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
        />
      </IconButton>
      <>
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="xl"
          TransitionComponent={Transition}
        >
          <DialogTitle
            sx={{
              mx: "auto",
            }}
          >
            {props.title}
          </DialogTitle>
          <GlobalSnackbar
            severity={snack.severity}
            messaggio={snack.messaggio}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
          />
          <DialogContent>
            <Card>
              <Box
                sx={{
                  height: { xs: "75vh", md: "60vh", xl: "75vh" },
                  width: 1,
                }}
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Button
                    startIcon={<Add />}
                    onClick={handleSecondDialogOpen}
                    id={props.id}
                    sx={{
                      color: "white",
                      display: props.id === "editProtezioni" ? "None" : "",
                    }}
                    variant="outlined"
                    className="ButtonMiller"
                  >
                    Aggiungi
                  </Button>
                </Grid>
                <DataGridPro
                  initialState={{
                    pinnedColumns: { left: ["Actions"] },
                  }}
                  components={{
                    Toolbar: CustomToolbar,
                    Footer: CustomFooterPersonalized,
                  }}
                  slots={{
                    loadingOverlay: LinearProgress,
                  }}
                  loading={loading}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  rowHeight={52}
                  rows={relPittoCara}
                  columns={columns}
                  getRowId={(row) => row.ID}
                  pageSize={20}
                  rowsPerPageOptions={[20]}
                  disableSelectionOnClick
                />
              </Box>
            </Card>
          </DialogContent>
        </Dialog>
        <Dialog
          open={openSecondDialog}
          onClose={handleSecondClose}
          fullWidth={true}
          maxWidth="sm"
          TransitionComponent={Transition}
        >
          <DialogTitle
            sx={{
              mx: "auto",
            }}
          >
            Aggiungi Pittogramma Caratteristiche
          </DialogTitle>
          <GlobalSnackbar
            severity={snack.severity}
            messaggio={snack.messaggio}
            openSnack={openSecondSnack}
            handleCloseSnack={handleCloseSecondSnack}
          />
          <DialogContent>
            <Grid
              item
              xs={12}
              md={2}
              marginTop={2}
              /* direction="column"
              alignItems="center"
              justifyContent="center" */
            >
              <FormControl
                sx={{ width: "100%", padding: 0, maxHeight: "200px" }}
              >
                <InputLabel id="demo-multiple-checkbox-label">
                  Pittogrammi Caratteristiche Associate
                </InputLabel>
                <Select
                  autoWidth
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedPittogrammi}
                  onChange={handlePittogrammiChange}
                  input={
                    <OutlinedInput label="Pittogrammi Caratteristiche associate" />
                  }
                >
                  {pittogrammiCat.map((pit) => (
                    <MenuItem key={pit.ID} value={pit}>
                      <ListItemAvatar>
                        <Avatar
                          sx={{ height: 40, width: 40 }}
                          alt="Remy Sharp"
                          src={"data:image/png;base64," + pit?.Immagine}
                        />
                      </ListItemAvatar>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </DialogContent>
          <DialogActions
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mx: 2,
            }}
          >
            <Button
              onClick={handleSecondClose}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Close />}
            >
              Chiudi
            </Button>
            <Button
              onClick={handleSave}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Check />}
            >
              Salva
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
};

const PittogrammiCat = ({ setSnack, setOpenSnack }) => {
  const [pittogrammiCat, setPittogrammiCat] = useState([]);
  const [loading, setLoading] = useState(true);
  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <DettaglioPittogrammiCat
            id="editPittogrammiCat"
            pittogramma={params.row}
            title="Modifica Pittogramma"
            saveFunction={handleSave}
          />
          <EliminazionePittogrammiCat
            pittogramma={params.row}
            deleteFunction={handleDelete}
          />
        </>
      ),
    },
    {
      field: "Immagine",
      headerName: "Immagine",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Avatar
          sx={{
            width: 50,
            height: 50,
            borderRadius: 0,
          }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
            }}
            src={
              params.row.Immagine !== "" && params.row.Immagine
                ? "data:image/png;base64," + params.row.Immagine
                : require("../assets/images/MILLERLogo.jpg")
            }
            alt=""
          />
        </Avatar>
      ),
    },
  ];
  const handleDelete = async (IDPittogramma) => {
    try {
      await unsplash.delete("pittogrammaCaratteristiche", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          ID: IDPittogramma,
        },
      });
      setSnack({
        severity: "success",
        messaggio: "Caratteristica eliminata con successo",
      });
      setOpenSnack(true);
      loadData();
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione",
      });
      setOpenSnack(true);
    }
  };
  const handleSave = async (pittogramma, operazione) => {
    if (operazione === "newPittogrammiCat") {
      try {
        await unsplash.post(
          "pittogrammaCaratteristiche",
          {
            pittogrammaCaratteristiche: pittogramma,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Caratteristica aggiunto con successo",
        });
        setOpenSnack(true);
        loadData();
      } catch (error) {
        setSnack({
          severity: "error",
          messaggio: "Errore durante la creazione",
        });
        setOpenSnack(true);
      }
    } else {
      try {
        await unsplash.put(
          "pittogrammaCaratteristiche",
          {
            pittogrammaCaratteristiche: pittogramma,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        if (operazione === "deletePittogramma") {
          setSnack({
            severity: "success",
            messaggio: "Pittogramma eliminato con successo",
          });
        } else {
          setSnack({
            severity: "success",
            messaggio: "Pittogramma modificato con successo",
          });
        }
        setOpenSnack(true);
        loadData();
      } catch (error) {
        if (operazione === "deletePittogramma") {
          setSnack({
            severity: "error",
            messaggio: "Errore durante l'eliminazione",
          });
        } else {
          setSnack({
            severity: "error",
            messaggio: "Errore durante la modificata",
          });
        }

        setOpenSnack(true);
      }
    }
  };

  const loadData = async () => {
    let response = await unsplash.get("pittogrammaCaratteristiche", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setPittogrammiCat(response.data.data);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      loadData();
    })();
  }, []);

  return (
    <>
      <Grid item xs={12} md={4}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <DettaglioPittogrammiCat
            id="newPittogrammiCat"
            title="Nouvo Pittogramma"
            saveFunction={handleSave}
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          height: { xs: "75vh", md: "60vh", xl: "75vh" },
          width: 1,
        }}
      >
        <DataGridPro
          initialState={{
            pinnedColumns: { left: ["Actions"] },
          }}
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooterPersonalized,
          }}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={loading}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rowHeight={52}
          rows={pittogrammiCat}
          columns={columns}
          getRowId={(row) => row.ID}
          pageSize={20}
          rowsPerPageOptions={[20]}
          disableSelectionOnClick
        />
      </Box>
    </>
  );
};
const DettaglioPittogrammiCat = (props) => {
  const [open, setOpen] = useState(false);
  const [pittogramma, setPittogramma] = useState({
    Immagine: "",
    Eliminato: 0,
  });

  const handleImage = async (e) => {
    const img = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(img);
    reader.onload = function () {
      document.getElementById("firmaFormatore").src = reader.result;
      let f = { ...pittogramma };
      let foto = reader.result.replace("data:image/jpeg;base64,", "");
      foto = foto.replace("data:image/png;base64,", "");
      f.Immagine = foto;
      setPittogramma(f);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleClickOpen = () => {
    if (props.id === "editPittogrammiCat") {
      setPittogramma(props.pittogramma);
    }
    setOpen(true);
  };
  const handleClose = () => {
    setPittogramma({
      Descrizione: "",
      Eliminato: 0,
    });
    setOpen(false);
  };
  const handleChange = (e) => {
    let copiaPittogramma = { ...pittogramma };
    copiaPittogramma[e.target.name] = e.target.value;
    setPittogramma(copiaPittogramma);
  };
  const handleSave = async () => {
    props.saveFunction(pittogramma, props.id);
    handleClose();
  };
  return (
    <>
      <IconButton
        style={{ display: props.id !== "editPittogrammiCat" ? "None" : "" }}
        aria-label="editPittogrammiCat"
        onClick={handleClickOpen}
        id={props.id}
      >
        <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
      </IconButton>

      <Button
        startIcon={<Add />}
        onClick={handleClickOpen}
        id={props.id}
        sx={{
          color: "white",
          display: props.id === "editPittogrammiCat" ? "None" : "",
        }}
        variant="outlined"
        className="ButtonMiller"
      >
        Aggiungi
      </Button>

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="xs"
          TransitionComponent={Transition}
        >
          <DialogTitle
            sx={{
              mx: "auto",
            }}
          >
            {props.title}
          </DialogTitle>
          <DialogContent>
            <Card
              sx={{
                mt: 2,
                alignContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Card
                sx={{
                  py: 4,
                  border: props.id === "new" ? "1px dashed gray" : "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  document.getElementById("file").click();
                }}
              >
                <Box
                  height="140"
                  src={
                    pittogramma.Immagine !== "" && pittogramma.Immagine
                      ? "data:image/png;base64," + pittogramma.Immagine
                      : require("../assets/images/MILLERLogo.jpg")
                  }
                  id="firmaFormatore"
                  alt="img"
                  component="img"
                  sx={{
                    height: "100%",
                    maxHeight: "250px",
                    mx: "auto",
                    display: "flex",
                  }}
                />
                <label
                  htmlFor="contained-button-file"
                  style={{ display: "none", margin: 0 }}
                >
                  <Input
                    accept="image/*"
                    id="file"
                    name="Immagine"
                    multiple
                    type="file"
                    onChange={(e) => handleImage(e)}
                    hidden
                  />
                </label>
              </Card>
              <CardActions>
                <Button
                  fullWidth
                  variant="text"
                  onClick={() => {
                    document.getElementById("file").click();
                  }}
                >
                  Carica Foto
                </Button>
              </CardActions>
            </Card>
          </DialogContent>
          <DialogActions
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mx: 2,
            }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Close />}
            >
              Chiudi
            </Button>
            <Button
              onClick={handleSave}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Check />}
            >
              Salva
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};
const EliminazionePittogrammiCat = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare il Pittogramma selezionato?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare:
          </DialogContentText>
          <Card
            sx={{
              mt: 2,
              alignContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Card
              sx={{
                py: 4,
                //backgroundColor: "#11526F",
                border: props.id === "new" ? "1px dashed gray" : "none",
              }}
            >
              <Box
                height="140"
                src={
                  props.pittogramma?.Immagine !== "" &&
                  props.pittogramma?.Immagine
                    ? "data:image/png;base64," + props.pittogramma?.Immagine
                    : require("../assets/images/MILLERLogo.jpg")
                }
                id="firmaFormatore"
                alt="img"
                component="img"
                sx={{
                  height: "100%",
                  maxHeight: "250px",
                  mx: "auto",
                  display: "flex",
                }}
              />
              <label
                htmlFor="contained-button-file"
                style={{ display: "none", margin: 0 }}
              >
                <Input
                  accept="image/*"
                  id="file"
                  name="Immagine"
                  multiple
                  type="file"
                  //onChange={(e) => handleImage(e)}
                  hidden
                />
              </label>
            </Card>
          </Card>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button
            onClick={() => {
              props.deleteFunction(props.pittogramma?.ID);
              handleClose();
            }}
            
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
