import React, { useRef, useState } from "react";
import {
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  TextField,
  Typography,
  Slide,
  Grid,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  Box,
  Tabs,
  Tab,
  ButtonGroup,
  LinearProgress,
} from "@mui/material";
import {
  Add,
  Close,
  ArrowForward,
  ArrowBack,
  ConstructionOutlined,
  Print,
  Check,
  ArrowDropUp,
  Delete,
  Edit,
  FolderShared,
  Email,
  Circle,
  Person,
} from "@mui/icons-material";
import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import { LocalizationProvider,DatePicker,PickersDay,TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment as DateAdapter} from '@mui/x-date-pickers/AdapterMoment'
import moment from "moment-timezone";
import PropTypes from "prop-types";
import unsplash from "../api/unsplash";
import jsPDF from "jspdf";
import "jspdf-autotable";
import GlobalSnackbar from "./Snackbar";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Grow from "@mui/material/Grow";
import Zoom from "@mui/material/Zoom";
import EditIcon from "@mui/icons-material/Edit";
import GroupsIcon from "@mui/icons-material/Groups";
import PrintIcon from "@mui/icons-material/Print";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

var QRCode = require("qrcode");

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const DettaglioCorsi = (props) => {
  const [open, setOpen] = useState(false);
  const [dal, setDal] = useState(null);
  const [al, setAl] = useState(null);
  const [formatore, setFormatore] = useState(null);
  const [codiceCorso, setCodiceCorso] = useState(null);
  const [modalita, setModalita] = useState(null);
  const [modalitaFatturazione, setModalitaFatturazione] = useState(null);
  const [value, setValue] = useState(0);
  const [operation, setOperation] = useState("");

  const [openButton, setOpenButton] = useState(false);
  const [openButtonReport, setOpenButtonReport] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [postiRimanenti, setPostiRimanenti] = useState(0);

  const [openReport, setOpenReport] = useState(false);
  const [openPartecipanti, setOpenPartecipanti] = useState(false);
  const anchorRefReport = useRef(null);
  const [edit, setEdit] = useState(false);
  const [selectedIndexReport, setSelectedIndexReport] = useState(1);
  const [hideDialog, setHideDialog] = useState(false);

  const [corsoH, setCorsoH] = useState({
    Tipo: null,
    Modalita: null,
    Descrizione: "",
    NomeFormatore: "",
    ModalitaFatturazione: null,
    CostoForfait: null,
    Formatore: null,
    Luogo: "",
    Aggiornamento: 0,
    Note: "",
    Visibile: 0,
    PostiTotali: 0,
  });
  const [corsiR, setCorsiR] = useState([]);
  const [loading, setLoading] = useState(true);
  const [corsiPresenze, setCorsiPresenze] = useState([]);
  const [loadingPres, setLoadingPres] = useState(true);
  const [updated, setUpdated] = useState(false);
  const [idCorso, setIdCorso] = useState(null);
  const [giornoSel, setGiornoSel] = useState(null);
  const [attestatiSel, setAttestatiSel] = useState([]);
  const [clienti, setClienti] = useState([]);
  const giornoCorso = useRef(0);
  const [corsiAttestati, setCorsiAttestati] = useState([
    {
      Attestato1: "",
      Attestato2: "",
      Attestato3: "",
    },
  ]);
  const [stampa, setStampa] = useState(true);

  const [expanded, setExpanded] = useState(false);
  const [missingEmail, setMissingEmail] = useState([]);

  const options = [
    {
      icon: <ContentCopyIcon />,
      text: "ATTESTATI MASSIVI",
    },
    {
      icon: <FormatListBulletedIcon />,
      text: "REGISTRO CONSEGNE",
    },
    {
      icon: <GroupsIcon />,
      text: "REGISTRO PRESENZE",
    },
    {
      icon: <FolderShared />,
      text: "STAMPA E ASSOCIA ",
    },
  ];

  const optionsReport = [
    {
      icon: <GroupsIcon />,
      text: "DIPENDENTI",
      ind: 4,
    },
    {
      icon: <Person />,
      text: "REFERENTI",
      ind: 5,
    },
  ];

  const columns = [
    {
      field: "Nome",
      headerName: "Nome",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Cognome",
      headerName: "Cognome",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "RagioneSociale",
      headerName: "Ragione Sociale",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "presenza",
      headerName: "Presenza",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => {
        return (
          <Checkbox
            checked={params.row.presenza === 1}
            onChange={(e) => handleClickCheckBox(e, params.row)}
          ></Checkbox>
        );
      },
    },
  ];

  const columnsOre = [
    {
      field: "Actions",
      filterable: false,
      headerName: "Azioni",
      width: 150,
      headerAlign: "center",
      align: "center",

      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <IconButton
            aria-label="people"
            onClick={async () => {
              setHideDialog(true);
              setOpenPartecipanti(true);
              setStampa(false);
              giornoCorso.current = moment(params.row.Data).format(
                "DD/MM/YYYY"
              );
            }}
          >
            <PeopleAltIcon
              style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
            />
          </IconButton>
          <DialogGestioneDateCorsi
            setEdit={setEdit}
            giornoSel={[params.row]}
            Title="Modifica Giornata"
            operation="Edit"
            idCorso={idCorso}
            formatore={formatore?.ID}
            handleLoadRighe={handleLoadRighe}
            snack={props.snack}
            openSnack={props.openSnack}
            handleCloseSnack={props.handleCloseSnack}
            setSnack={props.setSnack}
            setOpenSnack={props.setOpenSnack}
          />
          <DialogDeleteData
            giornoSel={[params.row]}
            idCorso={idCorso}
            handleLoadRighe={handleLoadRighe}
            snack={props.snack}
            openSnack={props.openSnack}
            handleCloseSnack={props.handleCloseSnack}
            setSnack={props.setSnack}
            setOpenSnack={props.setOpenSnack}
          />
        </>
      ),
    },
    {
      field: "Data",
      headerName: "Data",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            {params.row.Data
              ? moment(params.row.Data).format("DD/MM/YYYY")
              : ""}
          </>
        );
      },
    },
    {
      field: "Ora",
      headerName: "Ora",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Durata",
      headerName: "Durata",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClickCheckBox = async (e, element) => {
    let c = [...corsiPresenze];

    //let index = c[value].map(obj =>obj.ID).indexOf(element.ID);
    const index = c[value].findIndex((object) => {
      return object.ID === element.ID;
    });

    c[value][index].presenza = e.target.checked ? 1 : 0;

    try {
      let corsoPresenza = {
        Presenza: c[value][index].presenza,
        Fatturato: c[value][index].Fatturato,
        ID: c[value][index].ID,
      };
      let responseCorso = await unsplash.put(
        "dettaglioCorsi",
        { corsoPresenza: corsoPresenza }, //Creo corso header STEP 1
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
    } catch {
      props.setSnack({
        severity: "error",
        messaggio: "Errore durante l'aggiornamento",
      });
      props.setOpenSnack(true);
    }

    setCorsiPresenze(c);

    // E ora va messo l'add
  };

  const handleMenuItemClick = (event, index) => {
    document.getElementById(`${index}`).click();
    //setSelectedIndex(index);
    setOpenButton(false);
  };

  const handleMenuItemClickReport = (event, index) => {
    document.getElementById(`${index}`).click();
    //setSelectedIndex(index);
    setOpenButtonReport(false);
  };

  const handleToggleButton = () => {
    setOpenButton(!openButton);
  };

  const handleCloseButton = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenButton(false);
  };

  function cap(arr) {
    for (var i = 0; i < arr.length; i++) {
      arr[i] =
        arr[i].charAt(0).toUpperCase() + arr[i].substring(1).toLowerCase();
    }
    return arr.join(" ");
  }
  const handleToggleButtonReport = () => {
    setOpenButtonReport(!openButtonReport);
  };

  const handleCloseButtonReport = (event) => {
    if (
      anchorRefReport.current &&
      anchorRefReport.current.contains(event.target)
    ) {
      return;
    }
    setOpenButtonReport(false);
  };
  const exportPDF = (tipo) => {
    //FUNZIONE PER GENERARE I CERTIFICATI
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    var img = new Image();
    var barcode = new Image();
    var qrcode = new Image();

    if(document.location.href.includes("koala-demo")){
      img.src = require("../assets/images/backCertificateASTM.png");
    }else{
      img.src = require("../assets/images/backCertificate.png");
    }
    



    const totaleOre = corsiR.reduce((somma, corso) => {
      return somma + corso.Durata;
    }, 0);

    let presenze = [];

    if (tipo == "all") {
      presenze = corsiPresenze[0];
    } else {
      if (attestatiSel.length === 0) {
        props.setSnack({
          severity: "error",
          messaggio: "Seleziona almeno un cliente",
        });
        props.setOpenSnack(true);
        return;
      } else {
        setOpen(true);
      }
      presenze = attestatiSel;
    }

    var opts = {
      errorCorrectionLevel: "H",
      type: "image/jpeg",
      quality: 0.3,
      margin: 1,
      color: {
        //dark: "#000000",
        dark: "#2264ae",
        light: "#FFFFFF",
      },
    };

    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    for (let i = 0; i < presenze.length; i++) {
      doc.addImage(img, "PNG", 0, 0, width, height);
      doc.setFontSize(12);
      doc.setFontSize(24);
      doc.setTextColor(34, 100, 174);
      doc.setFont(undefined, "bold");
      //assegno l'ID dei Corsi_Presenze
      /*QRCode.toDataURL(`${presenze[i].ID}`, opts, function (err, url) {
        if (err) throw err;
        qrcode.src = url;
        doc.addImage(qrcode, "JPEG", 33, 107, 50, 50);
      });*/
      doc.text("ATTESTATO DI PARTECIPAZIONE", 33, 195);
      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      doc.text(
        doc.splitTextToSize(
          `${corsiAttestati[0].Attestato1}`,
          doc.internal.pageSize.getWidth() - 150
        ),
        33,
        215
      );
      doc.text("Si attesta che:", 33, 265);
      doc.setFontSize(16);
      doc.setTextColor(34, 100, 174);

      //massimo 28 lettere se no spezzo

      doc.text(
        presenze[i].Nome
          ? cap(presenze[i].Nome.split(" ")) +
              " " +
              (presenze[i].Cognome ? cap(presenze[i].Cognome.split(" ")) : "")
          : presenze[i].Cognome
          ? cap(presenze[i].Cognome.split(" "))
          : "",
        33,
        290
      );
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text("CF: ", 33, 310);
      doc.setFont(undefined, "normal");
      doc.text(presenze[i].CF ? presenze[i].CF : "", 52, 310);
      doc.setFont(undefined, "bold");
      doc.text("LUOGO DI NASCITA: ", 33, 325);
      doc.setFont(undefined, "normal");
      doc.text(
        presenze[i].LuogoDiNascita ? presenze[i].LuogoDiNascita : "",
        136,
        325
      );
      doc.setFont(undefined, "bold");
      doc.text("DATA DI NASCITA: ", 33, 340);
      doc.setFont(undefined, "normal");
      doc.text(
        presenze[i].DataDiNascita
          ? moment(presenze[i].DataDiNascita).format("DD/MM/YYYY")
          : "",
        127,
        340
      );

      doc.setFont(undefined, "bold");
      doc.text(
        `HA PARTECIPATO E SUPERATO LE PROVE FINALI DEL CORSO${
          corsoH.Aggiornamento === 1 ? " DI AGGIORNAMENTO" : ""
        }:`,
        33,
        365
      );
      doc.setFontSize(14);
      doc.setTextColor(34, 100, 174);
      doc.text(
        doc.splitTextToSize(
          `${corsiAttestati[0].Attestato2.toUpperCase()}`,
          doc.internal.pageSize.getWidth() - 50
        ),
        33,
        385
      );

      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text("MONTE ORE FREQUENTATO: ", 33, 425);
      doc.setFont(undefined, "normal");
      doc.text(totaleOre + " ORE", 178, 425);
      doc.setFont(undefined, "bold");
      doc.text("SETTORE DI RIFERIMENTO: ", 33, 440);
      doc.setFont(undefined, "normal");
      doc.text(
        presenze[i].CodiceATECO ? presenze[i].CodiceATECO : "",
        170,
        440
      );
      doc.setFont(undefined, "bold");
      doc.text(
        "ORGANIZZATORE DEL CORSO E RESPONSABILE DEL PROGETTO FORMATIVO:",
        33,
        455
      );
      doc.setFont(undefined, "normal");
      doc.text("MILLER SICUREZZA", 424, 455);
      doc.setFont(undefined, "bold");
      doc.text("SEDE DEL CORSO:", 33, 470);
      doc.setFont(undefined, "normal");
      let luogo =
        corsoH.Luogo === null ? "FRANCOLINO DI MEDIGLIA" : corsoH.Luogo;
      doc.text(luogo, 130, 470);
      doc.setFont(undefined, "bold");
      doc.text("PERIODO DI SVOLGIMENTO DEL CORSO: ", 33, 485);
      doc.setFont(undefined, "normal");


      

      doc.text(
        (props.corsoH?.DataInizio
          ? moment(props.corsoH.DataInizio).format("DD/MM/YYYY")
          : (corsiR.length>0?moment(corsiR[0].Data).format("DD/MM/YYYY"):"DATA INIZIO NON INSERITA")) +
          " - " +
          (props.corsoH?.DataFine
            ? moment(props.corsoH.DataFine).format("DD/MM/YYYY")
            : (corsiR.length>0?moment(corsiR[corsiR.length-1].Data).format("DD/MM/YYYY"):"DATA FINE NON INSERITA")),
        237,
        485
      );
      doc.setFont(undefined, "bold");
      doc.text("N° PROGRESSIVO DI REGISTRAZIONE: ", 33, 500);
      doc.setFont(undefined, "normal");
      doc.text(i + 1 + "/" + corsiPresenze[0].length, 225, 500);

      /* test azzurro chiaro */
      doc.setTextColor(141, 196, 235);

      doc.text(
        doc.splitTextToSize(
          `${corsiAttestati[0].Attestato3}`,
          doc.internal.pageSize.getWidth() - 200
        ),
        200,
        565
      );

      /* Firme 
      var title = "IL FORMATORE";
xOffset =
      doc.internal.pageSize.width / 2 -
      (doc.getStringUnitWidth(title) * doc.internal.getFontSize()) / 2;
    doc.text(title, xOffset, 635);
    doc.text("IL FORMATORE", 350, 635);
      */

      doc.setTextColor(0, 0, 0);
      var title = "IL FORMATORE",
        xOffset =
          doc.internal.pageSize.width / 2 -
          (doc.getStringUnitWidth(title) * doc.internal.getFontSize()) / 2;
      doc.text(title, xOffset, 635);

      doc.setTextColor(0, 0, 0);
      var title2 = "SOGGETTO ORGANIZZATORE",
        xOffset2 =
          doc.internal.pageSize.width / 2 +
          doc.internal.pageSize.width / 4 -
          (doc.getStringUnitWidth(title2) * doc.internal.getFontSize()) / 2;
      doc.text(title2, xOffset2, 635);
      doc.setTextColor(0, 0, 0);
      var title3 = "DEL CORSO",
        xOffset2 =
          doc.internal.pageSize.width / 2 +
          doc.internal.pageSize.width / 4 -
          (doc.getStringUnitWidth(title3) * doc.internal.getFontSize()) / 2;
      doc.text(title3, xOffset2, 645);

      doc.setTextColor(34, 100, 174);
      var title1 = "MILLER SICUREZZA",
        xOffset =
          doc.internal.pageSize.width / 2 -
          (doc.getStringUnitWidth(title1) * doc.internal.getFontSize()) / 2;
      doc.text(title1, xOffset, 650);
      //doc.text("MILLER SICUREZZA", 350, 650);
      const imgFirma = new Image();
      imgFirma.src = "data:image/png;base64," + formatore.Firma;

      if (formatore.Firma) {
        doc.addImage(imgFirma, "PNG", xOffset, 670, 100, imgFirma.height / 2);
      }
      if (i != presenze.length - 1) {
        doc.addPage();
      }
    }
    doc.output("pdfobjectnewwindow", "attestato.pdf");
  };

  const exportPDFPartecipanti = (tipo, date, persone, corso) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const doc = new jsPDF(orientation, unit, size);
    const img = new Image();
    img.src = require("../assets/images/logoBlack.png");

    const imgLogo = new Image();
    imgLogo.src = require("../assets/images/MILLERLogo-copia.jpg");

    const headers = ["N", "COGNOME", "NOME", "DATA CONSEGNA", "FIRMA"];
    var count = 1;
    const data = persone.map((el) => [
      `${count++}/${persone.length}`,
      cap(el.Cognome.split(" ")),
      cap(el.Nome.split(" ")),
      "",
      "",
    ]);

    let content = {
      startY: 210,
      startX: 0,
      tableWidth: "auto",
      columnWidth: "auto",
      headStyles: { fillColor: [34, 100, 174] },
      footStyles: { fillColor: [34, 100, 174] },
      margin: { left: 33, right: 33 },
      showFoot: "lastPage",
    };

    let presenze = [];
    if (!giornoSel) {
      props.setSnack({
        severity: "error",
        messaggio: "Seleziona almeno un giorno",
      });
      props.setOpenSnack(true);
      return;
    } else {
      setOpen(true);
    }

    if (tipo === "all") {
      presenze = corsiPresenze[0];
    } else {
      presenze = giornoSel;
    }

    for (let i = 0; i < presenze.length; i++) {
      doc.addImage(imgLogo, "PNG", 10, 30, 318.9, 94.5);
      doc.setFontSize(24);
      doc.setTextColor(34, 100, 174);
      doc.setFont(undefined, "bold");
      doc.text("REGISTRO CONSEGNA ATTESTATI", 33, 200);

      doc.setFontSize(16);
      doc.setTextColor(34, 100, 174);
      doc.setFont(undefined, "normal");
      doc.text("DENOMINAZIONE CORSO:", 33, 250);
      doc.setFontSize(14);
      doc.setTextColor(0, 0, 0);
      doc.setFont(undefined, "bold");
      doc.text(corso.Descrizione, 33, 270);
      doc.setFontSize(16);
      doc.setTextColor(34, 100, 174);
      doc.setFont(undefined, "normal");
      doc.text("DURATA COMPLESSIVA:", 33, 310);
      doc.setFontSize(14);
      doc.setTextColor(0, 0, 0);
      doc.setFont(undefined, "bold");
      doc.text(presenze[i].Durata + "h", 33, 330);

      doc.setFontSize(16);
      doc.setTextColor(34, 100, 174);
      doc.setFont(undefined, "normal");
      doc.text("DATA - DURATA - ORARI:", 33, 350);

      doc.setFontSize(14);
      doc.setTextColor(0, 0, 0);
      doc.setFont(undefined, "bold");

      doc.text(
        moment(presenze[i].Data).format("DD/MM/YYYY") +
          " - " +
          presenze[i].Durata +
          "h - dalle " +
          presenze[i].Ora.slice(0, 2) +
          " alle " +
          (presenze[i].Durata > 4
            ? parseInt(presenze[i].Ora.slice(0, 2)) + presenze[i].Durata + 1
            : parseInt(presenze[i].Ora.slice(0, 2)) + presenze[i].Durata),
        33,
        370
      );
      doc.setFontSize(16);
      doc.setTextColor(34, 100, 174);
      doc.setFont(undefined, "normal");
      doc.text("RESPONSABILE DEL PROGETTO FORMATIVO:", 33, 390);
      doc.setFontSize(14);
      doc.setTextColor(0, 0, 0);
      doc.setFont(undefined, "bold");
      let formatore = props.Formatori?.filter(
        (p) => p.ID === corsoH?.Formatore
      )[0];
      const nomeCompleto = formatore?.Nome + " " + formatore?.Cognome;

      doc.text(nomeCompleto, 33, 410);

      doc.setFontSize(16);
      doc.setTextColor(34, 100, 174);
      doc.setFont(undefined, "normal");
      doc.text("SOGGETTO ORGANIZZATORE DEL CORSO:", 33, 430);
      doc.setFontSize(14);
      doc.setTextColor(0, 0, 0);
      doc.setFont(undefined, "bold");
      doc.text("", 33, 450);

      doc.setTextColor(0, 0, 0);
      doc.setFont(undefined, "normal");

      var title = "SOGGETTO ORGANIZZATORE",
        xOffset =
          doc.internal.pageSize.width / 4 -
          (doc.getStringUnitWidth(title) * doc.internal.getFontSize()) / 2;
      doc.text(title, xOffset, 635);
      var title2 = "DEL CORSO",
        xOffset2 =
          doc.internal.pageSize.width / 4 -
          (doc.getStringUnitWidth(title2) * doc.internal.getFontSize()) / 2;
      doc.text(title2, xOffset2, 650);

      doc.setTextColor(34, 100, 174);
      doc.setFont(undefined, "bold");
      var title3 = "DATORE DI LAVORO",
        xOffset3 =
          doc.internal.pageSize.width / 4 -
          (doc.getStringUnitWidth(title3) * doc.internal.getFontSize()) / 2;
      doc.text(title3, xOffset3, 665);

      doc.setTextColor(0, 0, 0);
      doc.setFont(undefined, "normal");
      var title0 = "IL FORMATORE",
        xOffset0 =
          doc.internal.pageSize.width / 2 +
          doc.internal.pageSize.width / 4 -
          (doc.getStringUnitWidth(title0) * doc.internal.getFontSize()) / 2;
      doc.text(title0, xOffset0, 635);

      doc.setTextColor(34, 100, 174);
      doc.setFont(undefined, "bold");
      var title1 = "MILLER SICUREZZA",
        xOffset1 =
          doc.internal.pageSize.width / 2 +
          doc.internal.pageSize.width / 4 -
          (doc.getStringUnitWidth(title1) * doc.internal.getFontSize()) / 2;
      doc.text(title1, xOffset1, 650);
      const imgFirma = new Image();
      imgFirma.src = "data:image/png;base64," + formatore.Firma;

      if (formatore.Firma) {
        doc.addImage(imgFirma, "PNG", 350, 670, 200, imgFirma.height / 2);
      }
      if (i != presenze.length - 1) {
        doc.addPage();
      }
      doc.addPage();
      doc.addImage(imgLogo, "PNG", 10, 30, 318.9, 94.5);
      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      doc.setFont(undefined, "bold");
      doc.text(
        doc.splitTextToSize(
          "Con la presente si dichiara che a seguito del superamento del test finale, i discenti sotto elencati ricevono gli attestati di frequenza del corso '" +
            corso.Descrizione +
            "'. Dichiarano inoltre di aver frequentato, come riportato nel registro presenze, almeno il 90% del monte ore totale e previsto (" +
            presenze[i].Durata +
            "h)",
          doc.internal.pageSize.getWidth() - 80
        ),
        33,
        150
      );
    }
    doc.autoTable(headers, data, content);

    //if()
    doc.output("pdfobjectnewwindow", "attestato.pdf");
  };

  const exportPDFPresenze = (tipo, date, persone, corso) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    let presenze = [];
    if (tipo === "all") {
      presenze = corsiR;
    } else {
      presenze = giornoSel;
    }
    /* durata complessiva corsi */
    const durata = presenze.map((a) => parseInt(a.Durata));
    const tot = durata.reduce((partialSum, a) => partialSum + a, 0);

    /* orario entrata uscita */

    const doc = new jsPDF(orientation, unit, size);
    const img = new Image();
    img.src = require("../assets/images/logoBlack.png");

    const imgLogo = new Image();
    imgLogo.src = require("../assets/images/MILLERLogo-copia.jpg");

    const headerDocenti = (val) => {
      return [
        "NOME",
        "COGNOME",
        `ENTRATA ${val.Ora.slice(0, 2)}:00`,
        `USCITA ${
          val.Durata > 4
            ? parseInt(val.Ora.slice(0, 2)) + val.Durata + 1
            : parseInt(val.Ora.slice(0, 2)) + val.Durata
        }:00`,
      ];
    };
    const headerDiscenti = (val) => {
      return [
        "N",
        "COGNOME",
        "NOME",
        `ENTRATA ${val.Ora.slice(0, 2)}:00`,
        `USCITA ${
          val.Durata > 4
            ? parseInt(val.Ora.slice(0, 2)) + val.Durata + 1
            : parseInt(val.Ora.slice(0, 2)) + val.Durata
        }:00`,
      ];
    };
    var count = 1;
    const data = persone.map((el) => [
      `${count++}/${persone.length}`,
      cap(el.Cognome.split(" ")),
      cap(el.Nome.split(" ")),
      "",
      "",
    ]);
    const formatore = props.Formatori?.filter(
      (p) => p.ID === corsoH?.Formatore
    )[0];
    const nomeCompleto = formatore?.Nome + " " + formatore?.Cognome;
    const docenti = [nomeCompleto.split(" ")];

    let contentDocenti = {
      startY: 150,
      startX: 0,
      tableWidth: "auto",
      columnWidth: "auto",
      headStyles: { fillColor: [34, 100, 174] },
      footStyles: { fillColor: [34, 100, 174] },
      margin: { left: 33, right: 33 },
      showFoot: "lastPage",
    };

    let contentDiscenti = {
      startY: 270,
      startX: 0,
      tableWidth: "auto",
      columnWidth: "auto",
      headStyles: { fillColor: [34, 100, 174] },
      footStyles: { fillColor: [34, 100, 174] },
      margin: { left: 33, right: 33 },
      showFoot: "lastPage",
    };

    setOpen(true);
    doc.addImage(imgLogo, "PNG", 10, 30, 318.9, 94.5);
    doc.setFontSize(24);
    doc.setTextColor(34, 100, 174);
    doc.setFont(undefined, "bold");
    doc.text("REGISTRO PRESENZE", 33, 200);

    doc.setFontSize(16);
    doc.setTextColor(34, 100, 174);
    doc.setFont(undefined, "normal");
    doc.text("DENOMINAZIONE CORSO:", 33, 250);
    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
    doc.setFont(undefined, "bold");
    doc.text(
      corso.Descrizione +
        (corsoH.Aggiornamento === 1 ? " - Aggiornamento" : ""),
      33,
      270
    );
    doc.setFontSize(16);
    doc.setTextColor(34, 100, 174);
    doc.setFont(undefined, "normal");
    doc.text(`DURATA COMPLESSIVA: `, 33, 310);
    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
    doc.setFont(undefined, "bold");
    //sommo tutte le ore dei corsi

    doc.text(tot + "h", 33, 330);

    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.setFont(undefined, "bold");
    doc.setFontSize(16);
    doc.setTextColor(34, 100, 174);
    doc.setFont(undefined, "normal");
    doc.text(`RESPONSABILE DEL PROGETTO FORMATIVO:`, 33, 350);
    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
    doc.setFont(undefined, "bold");
    doc.text(cap(nomeCompleto.split(" ")), 33, 370);

    doc.setFontSize(16);
    doc.setTextColor(34, 100, 174);
    doc.setFont(undefined, "normal");
    doc.text("SOGGETTO ORGANIZZATORE DEL CORSO:", 33, 390);
    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
    doc.setFont(undefined, "bold");
    doc.text("", 33, 410);

    doc.setFontSize(16);
    doc.setTextColor(34, 100, 174);
    doc.setFont(undefined, "normal");
    doc.text("DATA - DURATA - ORARI:", 33, 430);

    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.setFont(undefined, "bold");
    for (let i = 0; i < presenze.length; i++) {
      doc.text(
        moment(presenze[i].Data).format("DD/MM/YYYY") +
          " - " +
          presenze[i].Durata +
          "h - dalle " +
          presenze[i].Ora.slice(0, 2) +
          " alle " +
          (presenze[i].Durata > 4
            ? parseInt(presenze[i].Ora.slice(0, 2)) + presenze[i].Durata + 1
            : parseInt(presenze[i].Ora.slice(0, 2)) + presenze[i].Durata),
        33,
        i * 12 + 445
      );
    }

    doc.setTextColor(0, 0, 0);
    doc.setFont(undefined, "normal");

    var title = "SOGGETTO ORGANIZZATORE",
      xOffset =
        doc.internal.pageSize.width / 4 -
        (doc.getStringUnitWidth(title) * doc.internal.getFontSize()) / 2;
    doc.text(title, xOffset, 635);
    var title2 = "DEL CORSO",
      xOffset2 =
        doc.internal.pageSize.width / 4 -
        (doc.getStringUnitWidth(title2) * doc.internal.getFontSize()) / 2;
    doc.text(title2, xOffset2, 650);

    doc.setTextColor(34, 100, 174);
    doc.setFont(undefined, "bold");
    var title3 = "DATORE DI LAVORO",
      xOffset3 =
        doc.internal.pageSize.width / 4 -
        (doc.getStringUnitWidth(title3) * doc.internal.getFontSize()) / 2;
    doc.text(title3, xOffset3, 665);

    doc.setTextColor(0, 0, 0);
    doc.setFont(undefined, "normal");
    var title0 = "IL FORMATORE",
      xOffset0 =
        doc.internal.pageSize.width / 2 +
        doc.internal.pageSize.width / 4 -
        (doc.getStringUnitWidth(title0) * doc.internal.getFontSize()) / 2;
    doc.text(title0, xOffset0, 635);

    doc.setTextColor(34, 100, 174);
    doc.setFont(undefined, "bold");
    var title1 = "MILLER SICUREZZA",
      xOffset1 =
        doc.internal.pageSize.width / 2 +
        doc.internal.pageSize.width / 4 -
        (doc.getStringUnitWidth(title1) * doc.internal.getFontSize()) / 2;
    doc.text(title1, xOffset1, 650);

    const imgFirma = new Image();
    imgFirma.src = "data:image/png;base64," + formatore.Firma;

    if (formatore.Firma) {
      doc.addImage(imgFirma, "PNG", 350, 670, 200, imgFirma.height / 2);
    }

    for (let i = 0; i < presenze.length; i++) {
      doc.addPage();
      doc.setFontSize(14);
      doc.setTextColor(0, 0, 0);
      doc.setFont(undefined, "normal");
      doc.text(`${corso.Descrizione}`, 33, 100);
      doc.text(
        `DURATA: ${presenze[i].Durata}h - DATA: ${moment(
          presenze[i].Data
        ).format("DD/MM/YYYY")}`,
        33,
        120
      );
      doc.setFontSize(14);
      doc.setTextColor(0, 0, 0);
      doc.setFont(undefined, "bold");
      doc.text(`DOCENTI`, 33, 140);
      /* tabella Docenti */
      doc.autoTable(headerDocenti(presenze[i]), docenti, contentDocenti);

      doc.setFontSize(14);
      doc.setTextColor(0, 0, 0);
      doc.setFont(undefined, "normal");
      doc.text(`${corso.Descrizione}`, 33, 220);
      doc.text(
        `DURATA: ${presenze[i].Durata}h - DATA: ${moment(
          presenze[i].Data
        ).format("DD/MM/YYYY")}`,
        33,
        240
      );
      doc.setFontSize(14);
      doc.setTextColor(0, 0, 0);
      doc.setFont(undefined, "bold");
      doc.text(`DISCENTI`, 33, 260);
      /* tabella Discenti */
      doc.autoTable(headerDiscenti(presenze[i]), data, contentDiscenti);
    }
    doc.output("pdfobjectnewwindow", "presenze.pdf");
  };

  const handleCorsiAttestati = async (idCorsoo, agg) => {
    try {
      let res = await unsplash.get("corsiAttestati", {
        params: { idCorso: idCorsoo },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      if (res.data.data.length === 0) {
        setStampa(false);
      } else if (res.data.data.length === 2) {
        setStampa(true);
        var formatoriX = [];
        var i;
        for (i = 0; i < res.data.data.length; i++) {
          if (res.data.data[i].Aggiornamento == agg) {
            formatoriX.push(res.data.data[i]);
          }
          setCorsiAttestati(formatoriX);
        }
      } else {
        setCorsiAttestati(res.data.data);
        setStampa(true);
      }
    } catch {
      props.setSnack({
        severity: "error",
        messaggio: "Errore durante il caricamento degli attestati",
      });
      props.setOpenSnack(true);
    }
  };

  const handleClickOpen = () => {
    setEdit(false);
    setValue(0);
    setOperation(props.Operation);
    setGiornoSel(null);
    setCorsiPresenze(null);
    setFormatore(null);
    setModalita(null);

    if (props.Operation === "Nuovo") {
      //setCorsoH();
      setCodiceCorso(null);
    } else {
      handleCorsiAttestati(props.Tipo, props.Aggiornamento);
      let ch = props.corsoH;
      setCorsoH({
        ID: ch.ID,
        Tipo: ch.Tipo,
        Modalita: ch.Modalita,
        Formatore: ch.IDFormatore,
        NomeFormatore: ch.Formatore,
        Descrizione: ch.Descrizione,
        Luogo: ch.Luogo,
        Aggiornamento: ch.Aggiornamento,
        Note: ch.Note,
        Visibile: ch.Visibile,
        PostiTotali: ch.PostiTotali,
      });
      setDal(moment(ch.DataInizio));
      setAl(moment(ch.DataFine));
      setIdCorso(ch.ID);
      setCodiceCorso(props.Corsi.filter((p) => p.Codice === ch.Tipo)[0]);
      setModalita(props.Modalita.filter((p) => p.ID === ch.Modalita)[0]);
      setFormatore(props.Formatori.filter((p) => p.ID === ch.IDFormatore)[0]);
      //setLuogo(ch.LuogoLocalita)
      handleLoadRighe(ch.ID);
      handleLoadClientiCorso(props.corsoH.ID);
    }

    setOpen(true);
  };

  const handleClose = () => {
    setCorsoH({
      Tipo: null,
      Modalita: null,
      Formatore: null,
      Descrizione: "",
      NomeFormatore: "",
      Luogo: "",
      Aggiornamento: 0,
      Note: "",
      Visibile: 0,
      PostiTotali: 0,
    });
    setUpdated(false);
    setOpen(false);
  };
  const handleCloseReport = () => {
    setOpenReport(false);
  };

  const handleCourseCreation = async () => {
    if (operation === "Nuovo") {
      if (corsoH.Tipo === null) {
        props.setSnack({
          severity: "error",
          messaggio: "Corso Obbligatorio per la creazione",
        });
        props.setOpenSnack(true);
      } else {
        try {
          let responseCorso = await unsplash.post(
            "corsi",
            { corso: corsoH, tipo: "H" }, //Creo corso header STEP 1
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );
          let idCorso = responseCorso.data.message.insertId;
          setIdCorso(idCorso);
          setCorsoH(corsoH);

          /* let corsoRiga = {
          Corso: idCorso,
          Formatore: corsoH.Formatore,
          DataDal: moment(dal).format("YYYY-MM-DD"),
          DataAl: moment(al).format("YYYY-MM-DD"),
          Presenza: 0,
          Fatturato: 0,
        };
        let responseCorsoRiga = await unsplash.post(
          "corsi",
          { corso: corsoRiga, tipo: "R" }, //Creo corso header STEP 1
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
*/

          handleLoadRighe(idCorso);
          props.reloadCorsi();
          setOperation("Edit");
          props.setSnack({
            severity: "success",
            messaggio: "Corso salvato con successo",
          });
          props.setOpenSnack(true);
        } catch {
          props.setSnack({
            severity: "error",
            messaggio: "Errore durante l'aggiornamento",
          });
          props.setOpenSnack(true);
        }
      }
    } else {
      try {
        await unsplash.put(
          "corsi",
          { corso: corsoH, tipo: "H" },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        props.reloadCorsi();
        props.setSnack({
          severity: "success",
          messaggio: "Corso modificato con successo",
        });
        props.setOpenSnack(true);
      } catch {
        props.setSnack({
          severity: "error",
          messaggio: "Errore durante l'aggiornamento",
        });
        props.setOpenSnack(true);
      }
    }
  };

  const handleLoadRighe = async (idCorso) => {
    let response = await unsplash.get("corsi", {
      params: { idCorso: idCorso },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setCorsiR(response.data.data);
    setLoading(false);
    handleLoadPresenze(response.data.data);
  };

  const handleLoadPresenze = async (cR) => {
    //qua carico le presenze

    let response = await unsplash.get("dettaglioCorsi", {
      params: { tipologia: "Presenze", corso: idCorso, corsiR: cR },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setCorsiPresenze(response.data.data);
    setLoadingPres(false);
    if (response?.data?.data?.length > 0) {
      setPostiRimanenti(response.data.data[0]?.length);
    }
  };

  const hadleReport = async (type, giorni, destinatari) => {
    
    if (corsoH?.Luogo === null || corsoH?.Luogo === "") {
      props.setSnack({
        severity: "error",
        messaggio: "Inserire il luogo",
      });
      props.setOpenSnack(true);
    } else if (corsoH?.Formatore === null || corsoH?.Formatore === "") {
      props.setSnack({
        severity: "error",
        messaggio: "Inserire il formatore",
      });
      props.setOpenSnack(true);
    } else if (corsoH?.Descrizione === null || corsoH?.Descrizione === "") {
      props.setSnack({
        severity: "error",
        messaggio: "Inserire la modalità",
      });
      props.setOpenSnack(true);
    } else {
      const corsi = giorni.map((el) => {
        return {
          corso: corsoH.Descrizione,
          giorno: moment(el?.Data).format("YYYY-MM-DD"),
          ora: el?.Ora,
          luogo: corsoH?.Luogo,
          docente: corsoH?.NomeFormatore,
          modalita: modalita?.Descrizione,
        };
      });
      let missingEmail = [];
      let emails = [];
      if (type === "dipendenti") {
        destinatari.map((el) => {
          if (el?.Email === null || el?.Email === "") {
            missingEmail.push({ nome: el?.Nome, cognome: el?.Cognome });
          } else {
            emails.push({
              email: el?.Email,
              nome: el?.Nome,
              cognome: el?.Cognome,
            });
          }
        });
        setMissingEmail(missingEmail);
        if (emails.length === 0) {
          props.setSnack({
            severity: "error",
            messaggio: "Lavoratori non presenti",
          });
          props.setOpenSnack(true);
        } else {
          try {
            await unsplash.post(
              "report",
              {
                type: "corsi",
                dipendenti: emails,
                corsi: corsi,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
              }
            );
            props.setSnack({
              severity: "success",
              messaggio: "Report inviato con successo",
            });
            props.setOpenSnack(true);
            if (missingEmail?.length !== 0) {
              setOpenReport(true);
            }
          } catch (error) {
            props.setSnack({
              severity: "error",
              messaggio: "Errore durante l'invio del report",
            });
            props.setOpenSnack(true);
          }
        }

        //variabili chiamata post: type: "corsi", corsi: giorni, dipendenti:destinatari
      } else {
        //variabili chiamata post: type: "corsi", corsi: giorni, referente:destinatari
        let lavoratori = [];

        destinatari.map((el) => {
          const responsabili = emails?.map((el) => el?.email);
          lavoratori.push({ nome: el?.Nome, cognome: el?.Cognome });
          if (el?.Responsabile === null || el?.Responsabile === "") {
            missingEmail.push({ nome: el?.Nome, cognome: el?.Cognome });
          } else {
            if (!responsabili.includes(el?.Responsabile)) {
              emails.push({
                email: el?.Responsabile,
                nome: el?.Nome,
                cognome: el?.Cognome,
              });
            }
          }
        });
        if (emails.length === 0) {
          props.setSnack({
            severity: "error",
            messaggio: "Referenti non presenti",
          });
          props.setOpenSnack(true);
        } else if (lavoratori.length === 0) {
          props.setSnack({
            severity: "error",
            messaggio: "Lavoratori non presenti",
          });
          props.setOpenSnack(true);
        } else {
          try {
            await unsplash.post(
              "report",
              {
                type: "corsi",
                referenti: emails,
                lavoratori: lavoratori,
                corsi: corsi,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
              }
            );
            props.setSnack({
              severity: "success",
              messaggio: "Report inviato con successo",
            });
            props.setOpenSnack(true);
            if (missingEmail?.length !== 0) {
              setOpenReport(true);
            }
          } catch (error) {
            props.setSnack({
              severity: "error",
              messaggio: "Errore durante l'invio del report",
            });
            props.setOpenSnack(true);
          }
        }
      }
    }
  };

  const handleLoadClientiCorso = async (idCorso) => {
    let response = await unsplash.get("modalitaFatturazioneCorsi", {
      params: { IDCorso: idCorso },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    setClienti(response.data.data);
  };

  const exportPDFwithAssociation = async (tipo) => {
    //FUNZIONE PER GENERARE I CERTIFICATI
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    var img = new Image();
    var barcode = new Image();
    var qrcode = new Image();
    img.src = require("../assets/images/backCertificate.png");
    const totaleOre = corsiR.reduce((somma, corso) => {
      return somma + corso.Durata;
    }, 0);

    let presenze = [];

    if (tipo == "all") {
      presenze = corsiPresenze[0].filter((el) => el.presenza === 1);
    } else {
      if (attestatiSel.length === 0) {
        props.setSnack({
          severity: "error",
          messaggio: "Seleziona almeno un cliente",
        });
        props.setOpenSnack(true);
        return;
      } else {
        setOpen(true);
      }
      presenze = attestatiSel;
    }

    var opts = {
      errorCorrectionLevel: "H",
      type: "image/jpeg",
      quality: 0.3,
      margin: 1,
      color: {
        //dark: "#000000",
        dark: "#2264ae",
        light: "#FFFFFF",
      },
    };

    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    if (presenze.length > 0) {
      for (let i = 0; i < presenze.length; i++) {
        doc.addImage(img, "PNG", 0, 0, width, height);
        doc.setFontSize(12);
        doc.setFontSize(24);
        doc.setTextColor(34, 100, 174);
        doc.setFont(undefined, "bold");
        //assegno l'ID dei Corsi_Presenze
        QRCode.toDataURL(`${presenze[i].ID}`, opts, function (err, url) {
          if (err) throw err;
          qrcode.src = url;
          doc.addImage(qrcode, "JPEG", 33, 107, 50, 50);
        });
        doc.text("ATTESTATO DI PARTECIPAZIONE", 33, 195);
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        doc.text(
          doc.splitTextToSize(
            `${corsiAttestati[0].Attestato1}`,
            doc.internal.pageSize.getWidth() - 150
          ),
          33,
          215
        );
        doc.text("Si attesta che:", 33, 265);
        doc.setFontSize(16);
        doc.setTextColor(34, 100, 174);

        //massimo 28 lettere se no spezzo

        doc.text(
          presenze[i].Nome
            ? cap(presenze[i].Nome.split(" ")) +
                " " +
                (presenze[i].Cognome ? cap(presenze[i].Cognome.split(" ")) : "")
            : presenze[i].Cognome
            ? cap(presenze[i].Cognome.split(" "))
            : "",
          33,
          290
        );
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        doc.text("CF: ", 33, 310);
        doc.setFont(undefined, "normal");
        doc.text(presenze[i].CF ? presenze[i].CF : "", 52, 310);
        doc.setFont(undefined, "bold");
        doc.text("LUOGO DI NASCITA: ", 33, 325);
        doc.setFont(undefined, "normal");
        doc.text(
          presenze[i].LuogoDiNascita ? presenze[i].LuogoDiNascita : "",
          136,
          325
        );
        doc.setFont(undefined, "bold");
        doc.text("DATA DI NASCITA: ", 33, 340);
        doc.setFont(undefined, "normal");
        doc.text(
          presenze[i].DataDiNascita
            ? moment(presenze[i].DataDiNascita).format("DD/MM/YYYY")
            : "",
          127,
          340
        );

        doc.setFont(undefined, "bold");
        doc.text(
          `HA PARTECIPATO E SUPERATO LE PROVE FINALI DEL CORSO${
            corsoH.Aggiornamento === 1 ? " DI AGGIORNAMENTO" : ""
          }:`,
          33,
          365
        );
        doc.setFontSize(14);
        doc.setTextColor(34, 100, 174);
        doc.text(
          doc.splitTextToSize(
            `${corsiAttestati[0].Attestato2.toUpperCase()}`,
            doc.internal.pageSize.getWidth() - 50
          ),
          33,
          385
        );

        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        doc.text("MONTE ORE FREQUENTATO: ", 33, 425);
        doc.setFont(undefined, "normal");
        doc.text(totaleOre + " ORE", 178, 425);
        doc.setFont(undefined, "bold");
        doc.text("SETTORE DI RIFERIMENTO: ", 33, 440);
        doc.setFont(undefined, "normal");
        doc.text(
          presenze[i].CodiceATECO ? presenze[i].CodiceATECO : "",
          170,
          440
        );
        doc.setFont(undefined, "bold");
        doc.text(
          "ORGANIZZATORE DEL CORSO E RESPONSABILE DEL PROGETTO FORMATIVO:",
          33,
          455
        );
        doc.setFont(undefined, "normal");
        doc.text("MILLER SICUREZZA", 424, 455);
        doc.setFont(undefined, "bold");
        doc.text("SEDE DEL CORSO:", 33, 470);
        doc.setFont(undefined, "normal");
        let luogo =
          corsoH.Luogo === null ? "FRANCOLINO DI MEDIGLIA" : corsoH.Luogo;
        doc.text(luogo, 130, 470);
        doc.setFont(undefined, "bold");
        doc.text("PERIODO DI SVOLGIMENTO DEL CORSO: ", 33, 485);
        doc.setFont(undefined, "normal");
        doc.text(
          (props.corsoH.DataInizio
            ? moment(props.corsoH.DataInizio).format("DD/MM/YYYY")
            : "DATA INIZIO NON INSERITA") +
            " - " +
            (props.corsoH.DataFine
              ? moment(props.corsoH.DataFine).format("DD/MM/YYYY")
              : "DATA FINE NON INSERITA"),
          237,
          485
        );
        doc.setFont(undefined, "bold");
        doc.text("N° PROGRESSIVO DI REGISTRAZIONE: ", 33, 500);
        doc.setFont(undefined, "normal");
        doc.text(i + 1 + "/" + corsiPresenze[0].length, 225, 500);

        /* test azzurro chiaro */
        doc.setTextColor(141, 196, 235);

        doc.text(
          doc.splitTextToSize(
            `${corsiAttestati[0].Attestato3}`,
            doc.internal.pageSize.getWidth() - 200
          ),
          200,
          565
        );

        /* Firme 
      var title = "IL FORMATORE";
xOffset =
      doc.internal.pageSize.width / 2 -
      (doc.getStringUnitWidth(title) * doc.internal.getFontSize()) / 2;
    doc.text(title, xOffset, 635);
    doc.text("IL FORMATORE", 350, 635);
      */

        doc.setTextColor(0, 0, 0);
        var title = "IL FORMATORE",
          xOffset =
            doc.internal.pageSize.width / 2 -
            (doc.getStringUnitWidth(title) * doc.internal.getFontSize()) / 2;
        doc.text(title, xOffset, 635);

        doc.setTextColor(0, 0, 0);
        var title2 = "SOGGETTO ORGANIZZATORE",
          xOffset2 =
            doc.internal.pageSize.width / 2 +
            doc.internal.pageSize.width / 4 -
            (doc.getStringUnitWidth(title2) * doc.internal.getFontSize()) / 2;
        doc.text(title2, xOffset2, 635);
        doc.setTextColor(0, 0, 0);
        var title3 = "DEL CORSO",
          xOffset2 =
            doc.internal.pageSize.width / 2 +
            doc.internal.pageSize.width / 4 -
            (doc.getStringUnitWidth(title3) * doc.internal.getFontSize()) / 2;
        doc.text(title3, xOffset2, 645);

        doc.setTextColor(34, 100, 174);
        var title1 = "MILLER SICUREZZA",
          xOffset =
            doc.internal.pageSize.width / 2 -
            (doc.getStringUnitWidth(title1) * doc.internal.getFontSize()) / 2;
        doc.text(title1, xOffset, 650);
        //doc.text("MILLER SICUREZZA", 350, 650);

        if (i != presenze.length - 1) {
          doc.addPage();
        }
      }
      doc.output("pdfobjectnewwindow", "attestato.pdf");

      let filePdf = doc.output("datauristring").split("base64,")[1];

      const response = await unsplash.post(
        `/corsi`,
        {
          filePdf: filePdf,
          upload: true,
          presenze: presenze,
          corsoH: props.corsoH,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      props.setSnack({
        severity: "success",
        messaggio:
          "Generati attestati per i lavoratori presenti, e associati con successo",
      });
      props.setOpenSnack(true);
    } else {
      props.setSnack({
        severity: "warning",
        messaggio:
          "Nessun lavoratore presente al corso, per favore segna prima le presenze",
      });
      props.setOpenSnack(true);
    }
    //oggetto,
    ///testo,
    //id: preventivoSel.id_client,

    //oggetto,
    ///testo,
    //id: preventivoSel.id_client,
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <IconButton
        style={{ display: props.Operation !== "Edit" ? "None" : "" }}
        aria-label="edit"
        onClick={handleClickOpen}
        id={props.id}
      >
        <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
      </IconButton>
      <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          style={{ display: props.Operation === "Edit" ? "None" : "" }}
          id={props.id}
          variant="contained"
          className="ButtonMiller"
          onClick={handleClickOpen}
          startIcon={<Add />}
        >
          Aggiungi
        </Button>
      </Grid>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        maxWidth="xl"
        style={{ display: hideDialog ? "none" : "block" }}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <h4 style={{ textAlign: "center" }}>{props.Title}</h4>
        </DialogTitle>
        <Grid container spacing={2} px={3}>
          <Grid item xs={6}>
            <Autocomplete
              id="combo-box-demo"
              options={props.Corsi}
              getOptionLabel={(option) =>
                option.Codice + " - " + option.Descrizione
              }
              value={codiceCorso}
              fullWidth
              onChange={(event, newValue) => {
                let c = { ...corsoH };
                c.Tipo = newValue.Codice ? newValue.Codice : null;
                c.Descrizione = newValue.Descrizione
                setCorsoH(c);
                setCodiceCorso(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Corso" />
              )}
              disabled={operation === "Edit"}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="combo-box-demo"
              options={props.Modalita}
              getOptionLabel={(option) => option.Descrizione}
              fullWidth
              value={modalita}
              onChange={(event, newValue) => {
                let c = { ...corsoH };
                c.Modalita = newValue.ID ? newValue.ID : null;
                setCorsoH(c);
                setModalita(newValue);
                setUpdated(true);
                //setMedicoSel(newValue);
                //handleChangeCliente(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Modalità" />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="combo-box-demo"
              options={props.Formatori}
              getOptionLabel={(option) => option.Nome + " " + option.Cognome}
              value={formatore}
              fullWidth
              onChange={(event, newValue) => {
                let c = { ...corsoH };
                c.Formatore = newValue.ID ? newValue.ID : null;
                c.NomeFormatore = newValue.Nome + " " + newValue.Cognome
                setCorsoH(c);
                setFormatore(newValue);
                setUpdated(true);
              }}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Formatore" />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Luogo"
              value={corsoH.Luogo}
              onChange={(e) => {
                let c = { ...corsoH };
                c.Luogo = e.target.value;
                setCorsoH(c);
                setUpdated(true);
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              type="number"
              label="Posti Totali"
              value={corsoH.PostiTotali}
              onChange={(e) => {
                let c = { ...corsoH };
                c.PostiTotali = e.target.value;
                setCorsoH(c);
                setUpdated(true);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Note"
              value={corsoH.Note}
              onChange={(e) => {
                let c = { ...corsoH };
                c.Note = e.target.value;
                setCorsoH(c);
                setUpdated(true);
              }}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <FormControlLabel
              control={
                <Checkbox
                  name="GestioneMedicina"
                  checked={corsoH.Aggiornamento === 1 ? true : false}
                  onChange={(e) => {
                    let c = { ...corsoH };
                    c.Aggiornamento = e.target.checked ? 1 : 0;
                    setCorsoH(c);
                    setUpdated(true);
                  }}
                />
              }
              label="Aggiornamento"
            />

            <FormControlLabel
              control={
                <Checkbox
                  name="GestioneMedicina"
                  checked={corsoH.Visibile === 1 ? true : false}
                  onChange={(e) => {
                    let c = { ...corsoH };
                    c.Visibile = e.target.checked ? 1 : 0;
                    setCorsoH(c);
                    setUpdated(true);
                  }}
                />
              }
              label="Visibile"
            />
          </Grid>
          {operation === "Edit" ? (
            <>
              <Grid
                container
                direction="row"
                spacing={2}
                alignItems="center"
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  m: 0,
                }}
              >
                <Grid
                  item
                  xs={8}
                  sx={{ p: 0, alignItems: "center", display: "flex", gap: 1 }}
                >
                  {corsiR.length > 0 ? (
                    <DialogGestionePartecipanti
                      idCorso={idCorso}
                      ModalitaFatturazioneCorsi={
                        props.ModalitaFatturazioneCorsi
                      }
                      clienti={clienti}
                      tipoCorso={corsoH.Tipo}
                      corsiR={corsiR}
                      handleLoadRighe={handleLoadRighe}
                      partecipanti={
                        corsiPresenze?.length > 0 ? corsiPresenze[0] : []
                      }
                      corsiPresenze={corsiPresenze}
                      snack={props.snack}
                      openSnack={props.openSnack}
                      handleCloseSnack={props.handleCloseSnack}
                      setSnack={props.setSnack}
                      setOpenSnack={props.setOpenSnack}
                      hideDialog={() => setHideDialog(!hideDialog)}
                      postiTot={corsoH.PostiTotali}
                      postiRim={postiRimanenti}
                    />
                  ) : (
                    ""
                  )}
                  <Dialog
                    open={openReport}
                    onClose={handleCloseReport}
                    fullWidth={true}
                    maxWidth="md"
                    TransitionComponent={Transition}
                  >
                    {/* Title Container */}
                    <DialogTitle
                      sx={{
                        mx: "auto",
                      }}
                    >
                      Errore invio Report
                    </DialogTitle>
                    {/* Body Container */}
                    <DialogContent className="max-h-60 md:max-h-96 overflow-auto h-full w-full ">
                      <>
                        <Accordion
                          expanded={expanded === "panel1"}
                          onChange={handleChange("panel1")}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon sx={{ color: "whitesmoke" }} />
                            }
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                          >
                            <Typography sx={{ width: "40%" }}>
                              Indirizzi email mancanti per:
                            </Typography>
                            <b style={{ width: "5%" }}>{missingEmail.length}</b>
                            {missingEmail.length !== 0 ? (
                              <Circle
                                sx={{
                                  color: "#FF2768",
                                }}
                              >
                                {" "}
                              </Circle>
                            ) : (
                              <></>
                            )}
                          </AccordionSummary>
                          <AccordionDetails>
                            {missingEmail?.map((el, index) => (
                              <div key={index}>
                                -
                                <b>
                                  {el?.nome ? el.nome : ""}{" "}
                                  {el?.cognome ? el.cognome : ""}
                                </b>
                              </div>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      </>
                    </DialogContent>
                    <DialogActions
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mx: 2,
                      }}
                    >
                      <Button
                        onClick={handleCloseReport}
                        variant="contained"
                        className="ButtonMiller"
                        startIcon={<Close />}
                      >
                        Chiudi
                      </Button>
                      {/* <>
              {excelDataMissingId.length === 0 ? (
                <Button
                  onClick={beforeSave}
                  variant="contained"
                  className="ButtonMiller"
                  startIcon={<Check />}
                >
                  Salva
                </Button>
              ) : (
                <></>
              )}
            </> */}
                    </DialogActions>
                  </Dialog>
                  <ButtonGroup
                    variant="contained"
                    className="ButtonMiller"
                    ref={anchorRef}
                    aria-label="split button"
                  >
                    <Button
                      startIcon={<PrintIcon />}
                      variant="contained"
                      className="ButtonMiller"
                      onClick={() => {
                        handleToggleButton();
                      }}
                    >
                      STAMPA
                    </Button>
                    <Button
                      variant="contained"
                      className="ButtonMiller"
                      size="small"
                      aria-controls={
                        openButton ? "split-button-menu" : undefined
                      }
                      aria-expanded={openButton ? "true" : undefined}
                      aria-label="select merge strategy"
                      aria-haspopup="menu"
                      onClick={() => {
                        handleToggleButton();
                      }}
                    >
                      {openButton ? <ArrowDropUp /> : <ArrowDropDownIcon />}
                    </Button>
                  </ButtonGroup>
                  <Popper
                    sx={{
                      zIndex: 100,
                    }}
                    open={openButton}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow {...TransitionProps}>
                        <Paper sx={{ ml: "70px" }}>
                          <ClickAwayListener onClickAway={handleCloseButton}>
                            <MenuList id="split-button-menu" autoFocusItem>
                              {options.map((option, index) => (
                                <MenuItem
                                  key={index}
                                  selected={index === selectedIndex}
                                  onClick={(event) => {
                                    handleMenuItemClick(event, index);
                                  }}
                                >
                                  <span style={{ color: "#11526f" }}>
                                    {option.icon} {option.text}
                                  </span>
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                  <div style={{ display: "none" }}>
                    <Button
                      id="0"
                      variant="contained"
                      className="ButtonMiller"
                      onClick={() => {
                        if (updated) {
                          handleCourseCreation();
                        }
                        exportPDF("all");
                      }}
                      sx={{
                        display: stampa ? "flex" : "none",
                      }}
                    >
                      STAMPA TUTTI GLI ATTESTATI
                    </Button>

                    <Button
                      id="1"
                      variant="contained"
                      className="ButtonMiller"
                      sx={{ display: operation === "Edit" ? "" : "None" }}
                      onClick={() => {
                        if (!corsiR[0].Durata && !corsiR[0].Ora) {
                          props.setSnack({
                            severity: "error",
                            messaggio: "Aggiungi l'ora e la durata del corso",
                          });
                          props.setOpenSnack(true);
                        } else if (!corsiR[0].Durata) {
                          props.setSnack({
                            severity: "error",
                            messaggio: "Aggiungi la durata del corso",
                          });
                          props.setOpenSnack(true);
                        } else if (!corsiR[0].Ora) {
                          props.setSnack({
                            severity: "error",
                            messaggio: "Aggiungi l'ora del corso",
                          });
                          props.setOpenSnack(true);
                        } else if (!corsoH.Formatore) {
                          props.setSnack({
                            severity: "error",
                            messaggio: "Seleziona il formatore del corso",
                          });
                          props.setOpenSnack(true);
                        } else if (giornoSel?.length !== 1) {
                          props.setSnack({
                            severity: "error",
                            messaggio: "Seleziona solo un giorno",
                          });
                          props.setOpenSnack(true);
                        } else {
                          if (updated) {
                            handleCourseCreation();
                          }
                          exportPDFPartecipanti(
                            "",
                            corsiR[0],
                            corsiPresenze[0],
                            props.corsoH?props.corsoH:corsoH
                          );
                        }
                      }}
                    >
                      REGISTRO CONSEGNE
                    </Button>
                    <Button
                      id="2"
                      variant="contained"
                      className="ButtonMiller"
                      sx={{ display: operation === "Edit" ? "" : "None" }}
                      onClick={() => {
                        if (!corsiR[0].Durata && !corsiR[0].Ora) {
                          props.setSnack({
                            severity: "error",
                            messaggio: "Aggiungi l'ora e la durata del corso",
                          });
                          props.setOpenSnack(true);
                        } else if (!corsiR[0].Durata) {
                          props.setSnack({
                            severity: "error",
                            messaggio: "Aggiungi la durata del corso",
                          });
                          props.setOpenSnack(true);
                        } else if (!corsiR[0].Ora) {
                          props.setSnack({
                            severity: "error",
                            messaggio: "Aggiungi l'ora del corso",
                          });
                          props.setOpenSnack(true);
                        } else if (corsoH.Formatore) {
                          if (updated) {
                            handleCourseCreation();
                          }
                          exportPDFPresenze(
                            "all",
                            corsiR[0],
                            corsiPresenze[0],
                            props.corsoH?props.corsoH:corsoH
                          );
                        } else {
                          props.setSnack({
                            severity: "error",
                            messaggio: "Seleziona il formatore del corso",
                          });
                          props.setOpenSnack(true);
                        }
                      }}
                    >
                      REGISTRO PRESENZE
                    </Button>
                    <Button
                      id="3"
                      variant="contained"
                      className="ButtonMiller"
                      onClick={() => exportPDFwithAssociation("all")}
                      sx={{
                        display: stampa ? "flex" : "none",
                      }}
                    >
                      STAMPA E ASSOCIA
                    </Button>
                  </div>

                  {giornoSel?.length > 0 ? (
                    <ButtonGroup
                      variant="contained"
                      className="ButtonMiller"
                      ref={anchorRefReport}
                      aria-label="split button"
                    >
                      <Button
                        startIcon={<Email />}
                        variant="contained"
                        className="ButtonMiller"
                        onClick={() => {
                          handleToggleButtonReport();
                        }}
                      >
                        REPORT
                      </Button>
                      <Button
                        variant="contained"
                        className="ButtonMiller"
                        size="small"
                        aria-controls={
                          openButtonReport ? "split-button-menu" : undefined
                        }
                        aria-expanded={openButtonReport ? "true" : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={() => {
                          handleToggleButtonReport();
                        }}
                      >
                        {openButtonReport ? (
                          <ArrowDropUp />
                        ) : (
                          <ArrowDropDownIcon />
                        )}
                      </Button>
                    </ButtonGroup>
                  ) : (
                    ""
                  )}

                  <Popper
                    sx={{
                      zIndex: 100,
                    }}
                    open={openButtonReport}
                    anchorEl={anchorRefReport.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow {...TransitionProps}>
                        <Paper sx={{ ml: "10px" }}>
                          <ClickAwayListener
                            onClickAway={handleCloseButtonReport}
                          >
                            <MenuList id="split-button-menu" autoFocusItem>
                              {optionsReport.map((option, index) => (
                                <MenuItem
                                  key={option}
                                  selected={option.ind === selectedIndexReport}
                                  onClick={(event) => {
                                    handleMenuItemClickReport(
                                      event,
                                      option.ind
                                    );
                                  }}
                                >
                                  <span style={{ color: "#11526f" }}>
                                    {option.icon} {option.text}
                                  </span>
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                  <div style={{ display: "none" }}>
                    <Button
                      id="4"
                      variant="contained"
                      className="ButtonMiller"
                      onClick={() => {
                        if (giornoSel?.length === 0) {
                          props.setSnack({
                            severity: "error",
                            messaggio: "Seleziona un giorno",
                          });
                          props.setOpenSnack(true);
                        } else {
                          hadleReport(
                            "dipendenti",
                            giornoSel,
                            corsiPresenze[0]
                          );
                        }
                      }}
                    >
                      Dipendente
                    </Button>

                    <Button
                      id="5"
                      variant="contained"
                      className="ButtonMiller"
                      sx={{ display: operation === "Edit" ? "" : "None" }}
                      onClick={() => {
                        if (giornoSel?.length === 0) {
                          props.setSnack({
                            severity: "error",
                            messaggio: "Seleziona un giorno",
                          });
                          props.setOpenSnack(true);
                        } else {
                          hadleReport("referenti", giornoSel, corsiPresenze[0]);
                        }
                      }}
                    >
                      Referenti
                    </Button>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 5,
                  }}
                >
                  <DialogGestioneDateCorsi
                    setEdit={setEdit}
                    Title="Aggiungi Giornata"
                    operation="Nuovo"
                    idCorso={idCorso}
                    formatore={formatore?.ID}
                    handleLoadRighe={handleLoadRighe}
                    snack={props.snack}
                    openSnack={props.openSnack}
                    handleCloseSnack={props.handleCloseSnack}
                    setSnack={props.setSnack}
                    setOpenSnack={props.setOpenSnack}
                  />
                </Grid>
              </Grid>

              <Box
                sx={{
                  height: 300,
                  width: 1,
                  marginTop: "10px",
                  marginLeft: "16px",
                }}
              >
                <DataGridPro
                  initialState={{ pinnedColumns: { left: ["Actions"] } }}
                  sx={{ fontSize: 12 }}
                  components={{
                    Toolbar: CustomToolbar,
                    Footer: CustomFooterPersonalized,
                  }}
                  slots={{
                    loadingOverlay: LinearProgress,
                  }}
                  loading={loading}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  disableSelection={true}
                  rows={corsiR}
                  columns={columnsOre}
                  getRowId={(row) => row.ID}
                  onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRowData = corsiR.filter((row) =>
                      selectedIDs.has(row.ID)
                    );

                    setGiornoSel(selectedRowData);
                  }}
                  checkboxSelection
                  onCellClick={(params, e) => {
                    if (params.field === "Actions") {
                      e.stopPropagation();
                    }
                  }}
                />
              </Box>
            </>
          ) : (
            ""
          )}
          <Dialog
            open={openPartecipanti}
            onClose={() => {
              setOpenPartecipanti(false);
              setHideDialog(false);
            }}
            fullWidth={true}
            maxWidth="lg"
            TransitionComponent={Transition}
          >
            <DialogTitle
              sx={{
                mx: "auto",
              }}
            >
              {giornoCorso.current}
            </DialogTitle>
            <DialogContent>
              <Grid
                container
                xs={12}
                sx={{ display: "flex", mt: 2, justifyContent: "flex-end" }}
              >
                <ButtonGroup
                  container
                  xs={12}
                  sx={{
                    display: "flex",
                    mt: 2,
                    pl: 2,
                    flexWrap: "nowrap",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="contained"
                    className="ButtonMiller"
                    onClick={exportPDF}
                    sx={{
                      display: stampa ? "flex" : "none",
                    }}
                    startIcon={<Print />}
                  >
                    STAMPA ATTESTATO
                  </Button>
                </ButtonGroup>
              </Grid>
              {corsiR.length > 0 ? (
                corsiR.map((c, index) => {
                  return (
                    <TabPanel value={value} index={index} key={index}>
                      <Box
                        sx={{
                          height: 400,
                          width: 1,
                          marginTop: "10px",
                        }}
                      >
                        <DataGridPro
                          sx={{
                            fontSize: 12,
                          }}
                          checkboxSelection
                          localeText={
                            itIT.components.MuiDataGrid.defaultProps.localeText
                          }
                          components={{
                            Toolbar: CustomToolbar,
                            Footer: CustomFooterPersonalized,
                          }}
                          slots={{
                            loadingOverlay: LinearProgress,
                          }}
                          loading={loadingPres}
                          rows={corsiPresenze ? corsiPresenze[index] : []}
                          getRowId={(row) => row.ID}
                          columns={columns}
                          pageSize={100}
                          onSelectionModelChange={(ids) => {
                            const selectedIDs = new Set(ids);
                            const selectedRowData = corsiPresenze[index].filter(
                              (row) => selectedIDs.has(row.ID)
                            );
                            if (selectedRowData.length !== 0) {
                              setStampa(true);
                            } else {
                              setStampa(false);
                            }
                            setAttestatiSel(selectedRowData);
                          }}
                        />
                      </Box>
                    </TabPanel>
                  );
                })
              ) : (
                <Box
                  sx={{
                    height: 400,
                    width: 1,
                    marginTop: "10px",
                  }}
                >
                  <DataGridPro
                    sx={{
                      fontSize: 12,
                    }}
                    components={{
                      Toolbar: CustomToolbar,
                      Footer: CustomFooterPersonalized,
                    }}
                    checkboxSelection
                    localeText={
                      itIT.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={[]}
                    getRowId={(row) => row.ID}
                    columns={columns}
                    pageSize={100}
                  />
                </Box>
              )}

              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <Button
                    onClick={() => {
                      setOpenPartecipanti(false);
                      setHideDialog(false);
                    }}
                    variant="contained"
                    className="ButtonMiller"
                    startIcon={<Close />}
                  >
                    Chiudi
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </Grid>

        <DialogActions>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 2,
                p: 2,
              }}
            >
              <Button
                onClick={handleClose}
                variant="contained"
                className="ButtonMiller"
                startIcon={<Close />}
              >
                Chiudi
              </Button>
              <Button
                className="ButtonMiller"
                variant="contained"
                onClick={handleCourseCreation}
                startIcon={<Check />}
              >
                Salva
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DettaglioCorsi;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DialogGestionePartecipanti = (props) => {
  const [open, setOpen] = useState(false);
  const [lavoratori, setLavoratori] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lavoratoriSelezionati, setLavoratoriSelezionati] = useState([]);
  const [lavoratoriPartecipanti, setLavoratoriPartecipanti] = useState([]);
  const [loadingPart, setLoadingPart] = useState(true);
  const [lavoratoriDaRimuovere, setLavoratoriDaRimuovere] = useState([]);
  const postiRimanenti = useRef(0);
  const [idsPartecipantiDaEliminare, setIdsPartecipantiDaEliminare] = useState(
    []
  );

  const columns = [
    {
      field: "Cognome",
      headerName: "Cognome",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Nome",
      headerName: "Nome",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Cliente",
      headerName: "Cliente",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Scadenza",
      headerName: "Scadenza",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => {
        return (
          <div>
            {params.row.Scadenza
              ? moment(new Date(params.row.Scadenza)).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },
  ];

  const handleClickOpen = async () => {
    let response = await unsplash.get("dettaglioCorsi", {
      params: { tipologia: "Lavoratori", tipologiaCorso: props.tipoCorso },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    setLavoratori(
      getDifference(response.data.data, props.partecipanti, "ID", "Lavoratore")
    );
    setLoading(false);
    setLavoratoriPartecipanti(
      getUnion(response.data.data, props.partecipanti, "ID", "Lavoratore")
    );
    setLoadingPart(false);
    postiRimanenti.current =
      props.postiTot -
      getUnion(response.data.data, props.partecipanti, "ID", "Lavoratore")
        .length;
    props.hideDialog();
    setOpen(true);
  };

  const handleClose = () => {
    props.hideDialog();
    setOpen(false);
  };

  const handleSpostaLavoratori = async () => {
    let x = lavoratoriSelezionati.concat(lavoratoriPartecipanti);
    setLavoratoriPartecipanti(x);
    postiRimanenti.current = props.postiTot - x.length;

    let lavoratoriAttualmenteDisponibili = getDifference(
      lavoratori,
      x,
      "ID",
      "ID"
    );

    setLavoratori(lavoratoriAttualmenteDisponibili);

    let clientiGiaPresenti = [
      ...new Set(lavoratoriPartecipanti.map((l) => l.IDCliente)),
    ];
    let daAggiungere = [];
    let clienti = [];
    let clientiDaAggiungere = [];

    for (let i = 0; i < props.corsiR.length; i++) {
      let corsoR = props.corsiR[i].ID;

      var output = lavoratoriSelezionati.map(function (obj) {
        // trasformo in array di array

        if (
          clienti.indexOf(obj.IDCliente) === -1 &&
          clientiGiaPresenti.indexOf(obj.IDCliente) === -1
        ) {
          clienti.push(obj.IDCliente);
          clientiDaAggiungere.push([props.corsiR[i].Corso, obj.IDCliente, 1]);
        }

        daAggiungere.push([corsoR, obj.ID, 0, 0]);
      });

      //daAggiungere.push(output);
    }
    try {
      let responseCorso = await unsplash.post(
        "dettaglioCorsi",
        {
          corsiPresenze: daAggiungere,
          clientiDaAggiungere: clientiDaAggiungere,
        }, //Creo corso header STEP 1
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      props.handleLoadRighe(props.idCorso);
      props.setSnack({
        severity: "success",
        messaggio: "Partecipante spostato con successo",
      });
      props.setOpenSnack(true);
    } catch {
      props.setSnack({
        severity: "error",
        messaggio: "Errore durante lo spostamento",
      });
      props.setOpenSnack(true);
    }
  };

  const handleRemoveLavoratori = async () => {
    let x = lavoratoriDaRimuovere.concat(lavoratori);

    setLavoratori(x);
    let lavoratoriAttualmenteDisponibili = getDifference(
      lavoratoriPartecipanti,
      lavoratoriDaRimuovere,
      "ID",
      "ID"
    );

    setLavoratoriPartecipanti(lavoratoriAttualmenteDisponibili);
    postiRimanenti.current =
      props.postiTot - lavoratoriAttualmenteDisponibili.length;

    //delete CorsiPresenza

    try {
      let responseCorso = await unsplash.delete("dettaglioCorsi", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: { id: idsPartecipantiDaEliminare },
      });
      props.setSnack({
        severity: "success",
        messaggio: "Partecipante eliminato con successo",
      });
      props.setOpenSnack(true);
      props.handleLoadRighe(props.idCorso);
    } catch {
      props.setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione",
      });
      props.setOpenSnack(true);
    }
  };

  function CustomToolbar(title) {
    return (
      <>
        <GridToolbarContainer>
          <h5 style={{ textAlign: "center", width: "100%" }}>{title} </h5>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <GridToolbarQuickFilter placeholder="Cerca..." />
          </Grid>
        </GridToolbarContainer>
      </>
    );
  }

  return (
    <>
      <Button
        startIcon={<GroupsIcon />}
        className="ButtonMiller"
        variant="contained"
        onClick={handleClickOpen}
      >
        Gestione Partecipanti
        <i>
          ({props.postiRim}/{props.postiTot})
        </i>
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth="xl"
        maxWidth="xl"
        TransitionComponent={Transition}
      >
        <DialogTitle sx={{ mx: "auto" }} id="alert-dialog-title">
          <h4>Gestione Partecipanti</h4>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "5px",
              }}
            >
              <h5>
                Posti rimanenti: {postiRimanenti.current}/{props.postiTot}
              </h5>
            </Grid>

            <Grid item xs={6} style={{ height: "30px" }}>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  height: "30px",
                }}
              >
                <Button
                  variant="contained"
                  className="ButtonMiller"
                  size="small"
                  sx={{
                    display: lavoratoriSelezionati.length > 0 ? "" : "none",
                  }}
                  endIcon={<ArrowForward />}
                  onClick={handleSpostaLavoratori}
                >
                  Sposta
                </Button>
              </Grid>
              <Box
                sx={{
                  height: 400,
                  width: 1,
                  marginTop: "10px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <DataGridPro
                  components={{
                    Toolbar: () => CustomToolbar("Disponibili"),
                    Footer: CustomFooterPersonalized,
                  }}
                  slots={{
                    loadingOverlay: LinearProgress,
                  }}
                  loading={loading}
                  sx={{
                    fontSize: 12,
                  }}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  rows={lavoratori}
                  getRowId={(row) => row.ID}
                  columns={columns}
                  pageSize={100}
                  checkboxSelection
                  onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRowData = lavoratori.filter((row) =>
                      selectedIDs.has(row.ID)
                    );
                    setLavoratoriSelezionati(selectedRowData);
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  height: "30px",
                }}
              >
                <Button
                  variant="contained"
                  className="ButtonMiller"
                  size="small"
                  sx={{
                    display: lavoratoriDaRimuovere.length > 0 ? "" : "none",
                  }}
                  startIcon={<ArrowBack />}
                  onClick={handleRemoveLavoratori}
                >
                  Sposta
                </Button>
              </Grid>
              <Box
                sx={{
                  height: 400,
                  width: 1,
                  marginTop: "10px",
                }}
              >
                <DataGridPro
                  components={{
                    Toolbar: () => CustomToolbar("Partecipanti"),
                    Footer: CustomFooterPersonalized,
                  }}
                  slots={{
                    loadingOverlay: LinearProgress,
                  }}
                  loading={loadingPart}
                  sx={{ fontSize: 12 }}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  rows={lavoratoriPartecipanti}
                  getRowId={(row) => row.ID}
                  columns={columns}
                  pageSize={100}
                  checkboxSelection
                  onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRowData = lavoratoriPartecipanti.filter(
                      (row) => selectedIDs.has(row.ID)
                    );
                    let idToDelete = [];
                    const idLavoratoriSelected = props.corsiPresenze[0].filter(
                      (el) => {
                        for (let i = 0; i < ids.length; i++) {
                          if (el.Lavoratore === ids[i]) {
                            idToDelete.push(el.ID);
                          }
                        }
                      }
                    );

                    setIdsPartecipantiDaEliminare(idToDelete);

                    setLavoratoriDaRimuovere(selectedRowData);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            mx: 2,
          }}
        >
          <Button
            onClick={handleClose}
            className="ButtonMiller"
            variant="contained"
            startIcon={<Close />}
          >
            CHIUDI
          </Button>
          <div style={{ display: "flex", flexWrap: "nowrap", gap: "5px" }}>
            <DialogGestioneClientiFatturazione
              clienti={props.clienti}
              ModalitaFatturazioneCorsi={props.ModalitaFatturazioneCorsi}
              idCorso={props.idCorso}
              snack={props.snack}
              openSnack={props.openSnack}
              handleCloseSnack={props.handleCloseSnack}
              setSnack={props.setSnack}
              setOpenSnack={props.setOpenSnack}
            />
            <Button
              onClick={handleClose}
              className="ButtonMiller"
              variant="contained"
              startIcon={<Check />}
            >
              Salva
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DialogGestioneDateCorsi = (props) => {
  const [open, setOpen] = useState(false);
  const [giorno, setGiorno] = useState(null);
  const [orario, setOrario] = useState(null);
  const [durata, setDurata] = useState("");

  const handleClickOpen = async () => {
    if (props.operation === "Nuovo") {
      setGiorno(null);
      setOrario(null);
      setDurata(null);
      setOpen(true);
    } else {
      if (!props.giornoSel) {
        props.setSnack({
          severity: "error",
          messaggio: "Seleziona almeno un giorno",
        });
        props.setOpenSnack(true);
        return;
      }
      setGiorno(moment(props.giornoSel[0].Data));
      setOrario(moment(new Date("2022-09-02 " + props.giornoSel[0].Ora)));
      setDurata(props.giornoSel[0].Durata);

      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    try {
      if (props.operation === "Nuovo") {
        let corsoRiga = {
          Corso: props.idCorso,
          Formatore: props.formatore,
          Data: moment(giorno).format("YYYY-MM-DD"),
          Ora: orario.format("HH:mm:ss"),
          Durata: durata,
        };
        if (giorno === null) {
          props.setSnack({
            severity: "error",
            messaggio: "Inserisci il giorno del corso",
          });
          props.setOpenSnack(true);
        } else if (orario === null) {
          props.setSnack({
            severity: "error",
            messaggio: "Inserisci l'orario del corso",
          });
          props.setOpenSnack(true);
        } else {
          try {
            await unsplash.post(
              "corsi",
              { corso: corsoRiga, tipo: "R" }, //Creo corso header STEP 1
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
              }
            );
            props.setSnack({
              severity: "success",
              messaggio: "Corso creato con successo",
            });
            props.setOpenSnack(true);
          } catch {
            props.setSnack({
              severity: "error",
              messaggio: "Errore durante la creazione del corso",
            });
            props.setOpenSnack(true);
          }
        }
      } else {
        try {
          let corsoRiga = {
            ID: props.giornoSel[0].ID,
            Corso: props.idCorso,
            Formatore: props.formatore,
            Data: moment(giorno).format("YYYY-MM-DD"),
            Ora: orario.format("HH:mm:ss"),
            Durata: durata,
          };
          let responseCorsoRiga = await unsplash.put(
            "corsi",
            { corso: corsoRiga, tipo: "R" }, //Creo corso header STEP 1
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );
          props.setSnack({
            severity: "success",
            messaggio: "Corso modificato con successo",
          });
          props.setOpenSnack(true);
        } catch {
          props.setSnack({
            severity: "error",
            messaggio: "Errore durante la modifica del corso",
          });
          props.setOpenSnack(true);
        }
      }

      props.handleLoadRighe(props.idCorso);
      props.setEdit(true);
    } catch {
      props.setSnack({
        severity: "error",
        messaggio: "Errore durante l'aggiornamento del corso",
      });
      props.setOpenSnack(true);
    }
    setOpen(false);
  };

  return (
    <>
      {/* <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      /> */}
      {props.operation === "Nuovo" ? (
        <Button
          startIcon={<Add />}
          className="ButtonMiller"
          variant="contained"
          onClick={handleClickOpen}
        >
          Aggiungi
        </Button>
      ) : (
        <IconButton aria-label="edit">
          <EditIcon
            style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
            onClick={handleClickOpen}
          />
        </IconButton>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          {props.Title}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  slotProps={{ textField: { fullWidth: true } }}
                  ampmInClock={false}
                  renderInput={(props) => (
                    <TextField
                      sx={{ mt: 1 }}
                      size="small"
                      fullWidth
                      {...props}
                    />
                  )}
                  label="Giorno"
                  value={moment(giorno)}
                  onChange={(newValue) => {
                    setGiorno(newValue ? newValue : null);
                  }}
                  sx={{marginTop:"5px"}}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <TimePicker
                 slotProps={{ textField: { fullWidth: true } }}
                  ampmInClock={false}
                  renderInput={(props) => (
                    <TextField size="small" fullWidth {...props} />
                  )}
                  label="Ora"
                  value={moment(orario)}
                  onChange={(newValue) => {
                    setOrario(newValue ? newValue : null);
                  }}
                  sx={{marginTop:"5px"}}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                {...props}
                label="Durata"
                type="number"
                value={durata}
                onChange={(e) => setDurata(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between", mx: 2 }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
          >
            annulla
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={handleSave}
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DialogDeleteData = (props) => {
  const [open, setOpen] = useState(false);
  const [giorno, setGiorno] = useState(null);
  const [orario, setOrario] = useState(null);
  const [durata, setDurata] = useState("");
  /* const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  }); */

  const handleClickOpen = async () => {
    if (!props.giornoSel) {
      props.setSnack({
        severity: "error",
        messaggio: "Seleziona almeno un giorno",
      });
      props.setOpenSnack(true);
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      let responseCorsoRiga = await unsplash.delete("corsi", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: { corso: props.giornoSel[0].ID, tipo: "R" },
      });

      props.handleLoadRighe(props.idCorso);
      props.setSnack({
        severity: "success",
        messaggio: "Giorno corso eliminato con successo",
      });
      props.setOpenSnack(true);
    } catch {
      props.setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione",
      });
      props.setOpenSnack(true);
    }

    setOpen(false);
  };

  return (
    <>
      {/* <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      /> */}
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      {/* <Button
        startIcon={<Delete />}
        className="ButtonMillerError"
        variant="contained"
        onClick={handleClickOpen}
      >
        ELIMINA
      </Button> */}

      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Sei sicuro di voler eliminare?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare il giorno selezionato:{" "}
            <br />
            <b>{moment(props.giornoSel[0]?.Data).format("YYYY-MM-DD")}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between", mx: 2 }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={handleDelete}>Conferma</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DialogGestioneClientiFatturazione = (props) => {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [clienti, setClienti] = useState([]);

  const handleClickOpen = (scrollType) => async () => {
    setOpen(true);
    setScroll(scrollType);
    let response = await unsplash.get("modalitaFatturazioneCorsi", {
      params: { IDCorso: props.idCorso },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    setClienti(response.data.data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (index, event) => {
    let c = [...clienti];

    c[index].ModalitaFatturazione = event.target.value;
    c[index].Edited = 1;
    setClienti(c);
  };

  const handleChangeText = (e, index) => {
    let c = [...clienti];

    c[index].CostoForfait = e.target.value;
    c[index].Edited = 1;
    setClienti(c);
  };

  const handleSave = async () => {
    try {
      let responseModalitaFattuazione = await unsplash.post(
        "modalitaFatturazioneCorsi",
        { corso: props.idCorso, modalitaFatturazione: clienti }, //Creo corso header STEP 1
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      let response = await unsplash.get("modalitaFatturazioneCorsi", {
        params: { IDCorso: props.idCorso },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      setClienti(response.data.data);
      props.setSnack({
        severity: "success",
        messaggio: "Fatturazione aggiornata con successo",
      });
      props.setOpenSnack(true);
    } catch {
      props.setSnack({
        severity: "error",
        messaggio: "Errore durante l'aggiornamento",
      });
      props.setOpenSnack(true);
    }
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <GlobalSnackbar
        severity={props.snack.severity}
        messaggio={props.snack.messaggio}
        openSnack={props.openSnack}
        handleCloseSnack={props.handleCloseSnack}
      />
      <Button
        //size="small"
        variant="contained"
        className="ButtonMiller"
        onClick={handleClickOpen("paper")}
      >
        Modalità Fatturazione Corso Clienti
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        maxWidth="lg"
        fullWidth="lg"
        TransitionComponent={Transition}
      >
        <DialogTitle id="scroll-dialog-title">
          Gestisci modalità di pagamento
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Grid container spacing={2}>
              {clienti.map((p, index) => (
                <>
                  <Grid key={index} item xs={4}>
                    {p.RagioneSociale}{" "}
                  </Grid>{" "}
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Modalità
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={p.ModalitaFatturazione}
                        label="Modalita"
                        onChange={(e) => handleChange(index, e)}
                      >
                        {props.ModalitaFatturazioneCorsi.map((p) => (
                          <MenuItem key={p.ID} value={p.ID}>
                            {p.Descrizione}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>{" "}
                  </Grid>{" "}
                  <Grid item xs={4}>
                    <TextField
                      sx={{
                        display: p.ModalitaFatturazione === 2 ? "" : "None",
                      }}
                      value={p.CostoForfait}
                      label="Costo Forfait"
                      onChange={(e) => handleChangeText(e, index)}
                    />
                  </Grid>
                </>
              ))}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            px: 2,
          }}
        >
          <Button
            onClick={handleClose}
            className="ButtonMiller"
            variant="contained"
            startIcon={<Close />}
          >
            CHIUDI
          </Button>
          <Button
            onClick={handleSave}
            className="ButtonMiller"
            variant="contained"
            startIcon={<Check />}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

function getDifference(array1, array2, prop1, prop2) {
  //riutilizzabile per differenze tra due array di oggetti
  return array1.filter((object1) => {
    return !array2.some((object2) => {
      return object1[prop1] === object2[prop2];
    });
  });
}

function getUnion(array1, array2, prop1, prop2) {
  //riutilizzabile per differenze tra due array di oggetti
  return array1.filter((object1) => {
    return array2.some((object2) => {
      return object1[prop1] === object2[prop2];
    });
  });
}
