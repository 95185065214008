import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import unsplash from "../api/unsplash";
import Avatar from "@mui/material/Avatar";
import GlobalSnackbar from "./Snackbar";
import LinearProgress from "@mui/material/LinearProgress";
import Switch from "@mui/material/Switch";

import {
	Grid,
	Box,
	Card,
	CardContent,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Button,
	Input,
	CardActionArea,
	CardMedia,
	Divider,
	CardActions,
	InputAdornment,
	ButtonGroup,
	Tooltip,
	FormControlLabel,
	Checkbox,
	FormControl,
	MenuItem,
	Select,
	InputLabel,
	OutlinedInput,
	DialogContentText,
	DialogActions,
	Zoom,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import { IconButton } from "@mui/material";

import {
	DataGridPro,
	itIT,
	GridToolbar,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
	GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";

import {
	Add,
	Delete,
	Close,
	Check,
	Search,
	MenuBook,
	Circle,
} from "@mui/icons-material";
import moment from "moment";
import { LocalizationProvider,DatePicker,PickersDay,TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment as DateAdapter} from '@mui/x-date-pickers/AdapterMoment'
import { memo } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const Memo = (props) => {
	const [memo, setMemo] = useState([]);
	const [memoVisibili, setMemoVisibili] = useState([]);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const [vistaMemoUtente, setVistaMemoUtente] = useState([]);

	const [filtro, setFiltro] = useState({
		attivo: true,
		disattivo: false,
	});

	const [open, setOpen] = useState(false);
	const [openAlert, setOpenAlert] = useState(false);
	const [openSnack, setOpenSnack] = useState(false);
	const [snack, setSnack] = useState({
		severity: "success",
		messaggio: "Salvataggio Effettuato correttamente",
		open: false,
	});
	const handleCloseSnack = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnack(false);
	};

	const handleDelete = async (identificativo) => {
		try {
			await unsplash.delete("memo", {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("jwt")}`,
				},
				data: {
					id: identificativo,
				},
			});

			let res = await unsplash.get("memo", {
				params: { idMemo: props.idAzienda },
				headers: {
					Authorization: `Bearer ${localStorage.getItem("jwt")}`,
				},
			});
			setMemoVisibili(res.data.data);

			setSnack({
				severity: "success",
				messaggio: "Memo eliminato con successo",
			});
			setOpenSnack(true);
		} catch (error) {
			setSnack({
				severity: "error",
				messaggio: "Errore durante la cancellazione",
			});
			setOpenSnack(true);
		}
	};
	const columns = [
		{
			filterable: false,
			field: "Actions",
			headerName: "Azioni",
			hide: false,
			width: "130",
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<>
					<IconButton
						aria-label="edit"
						onClick={async () => {
							await setMemo(params.row);
							document.getElementById("edit").click();
							handleClickOpen();
						}}
					>
						<EditIcon
							style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
						/>
					</IconButton>
					<DeleteDialog memo={params.row} myFunction={handleDelete} />
				</>
			),
		},
		{
			field: "Data",
			headerName: "Data",
			flex: 1,
			align: "center",
			headerAlign: "center",
			renderCell: (
				params //mi aiuta a mettere il valore
			) => (
				<>
					{params.row.Data ? moment(params.row.Data).format("DD/MM/YYYY") : ""}
				</>
			),
		},
		{
			field: "Utente",
			headerName: "Utente",
			flex: 1,
			align: "center",
			headerAlign: "center",
		},
		{
			field: "Nota",
			headerName: "Nota",
			flex: 1,
			align: "center",
			headerAlign: "center",
		},
	];

	const handleSave = async (memoUtente, operazione) => {
		if (operazione === "new") {
			try {
				await unsplash.post(
					"memo",
					{
						memo: memoUtente,
					},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem("jwt")}`,
						},
					}
				);
				setSnack({
					severity: "success",
					messaggio: "Memo creato con successo",
				});
				setOpenSnack(true);

				let res = await unsplash.get("memo", {
					params: { idMemo: props.idAzienda },
					headers: {
						Authorization: `Bearer ${localStorage.getItem("jwt")}`,
					},
				});
				setMemoVisibili(res.data.data);
			} catch {
				setSnack({
					severity: "error",
					messaggio: "Errore durante la creazione",
				});
				setOpenSnack(true);
			}
			handleClose();
		} else {
			let m = { ...memoUtente };
			m.Data = moment(m.Data).format("YYYY-MM-DD");
			try {
				await unsplash.put(
					"memo",
					{
						memo: m,
					},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem("jwt")}`,
						},
					}
				);
				setSnack({
					severity: "success",
					messaggio: "Memo aggiornato con successo",
				});
				setOpenSnack(true);

				let res = await unsplash.get("memo", {
					params: { idMemo: props.idAzienda },
					headers: {
						Authorization: `Bearer ${localStorage.getItem("jwt")}`,
					},
				});
				setMemoVisibili(res.data.data);
			} catch {
				setSnack({
					severity: "error",
					messaggio: "Errore durante l'aggiornamento",
				});
				setOpenSnack(true);
			}
			handleClose();
		}
	};

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<Grid
					container
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
				>
					<GridToolbarQuickFilter placeholder="Cerca..." />
				</Grid>
			</GridToolbarContainer>
		);
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				let res = await unsplash.get("memo", {
					params: { idMemo: props.idAzienda },
					headers: {
						Authorization: `Bearer ${localStorage.getItem("jwt")}`,
					},
				});
				setMemoVisibili(res.data.data);
				setLoading(false);
				let responseUtenti = await unsplash.get("utenti", {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("jwt")}`,
					},
				});
				setVistaMemoUtente(responseUtenti.data.data);
			} catch (error) {
				console.error(error.message);
			}
		};
		fetchData();
	}, []);
	return (
		<>
			<GlobalSnackbar
				severity={snack.severity}
				messaggio={snack.messaggio}
				openSnack={openSnack}
				handleCloseSnack={handleCloseSnack}
			/>
			<Grid item xs={12}>
				<Grid
					container
					direction="row"
					spacing={2}
					alignItems="center"
					justifyContent="flex-end"
				>
					<NewMemo
						id="edit"
						memoUtente={vistaMemoUtente}
						idAzienda={props.idAzienda}
						memoDati={memo}
						saveFunction={handleSave}
						title="Modifica Memo"
						snack={snack}
						openSnack={openSnack}
						handleCloseSnack={handleCloseSnack}
						setSnack={setSnack}
						setOpenSnack={setOpenSnack}
					/>
					<NewMemo
						id="new"
						memoUtente={vistaMemoUtente}
						idAzienda={props.idAzienda}
						saveFunction={handleSave}
						title="Nuovo Memo"
						snack={snack}
						openSnack={openSnack}
						handleCloseSnack={handleCloseSnack}
						setSnack={setSnack}
						setOpenSnack={setOpenSnack}
					/>
				</Grid>
				<Box
					sx={{
						height: "60vh",
						width: 1,
					}}
				>
					<DataGridPro
						initialState={{ pinnedColumns: { left: ["Actions"] } }}
						components={{
							Toolbar: CustomToolbar,
							Footer: CustomFooterPersonalized,
						}}
						slots={{
							loadingOverlay: LinearProgress,
						}}
						loading={loading}
						localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
						rowHeight={52}
						rows={memoVisibili}
						columns={columns}
						getRowId={(row) => row.ID}
						pageSize={20}
						rowsPerPageOptions={[20]}
					/>
				</Box>
			</Grid>
		</>
	);
};

export default Memo;

/* Creazione Nuovi Memo */
const NewMemo = (props) => {
	const [open, setOpen] = useState(false);
	const [memoUtente, setMemoUtente] = useState([]);
	const [memoSel, setMemoSel] = useState({
		Utente: "",
		Data: null,
		Nota: "",
		Cliente: props.idAzienda,
	});

	const handleClickOpen = async () => {
		setMemoUtente(props.memoUtente);
		if (props.id === "edit") {
			setMemoSel(props.memoDati);
		}
		setOpen(true);
	};
	const handleClose = () => {
		setMemoSel({
			Utente: sessionStorage.getItem("username"),
			Data: new Date(),
			Nota: "",
			Cliente: props.idAzienda,
		});
		setOpen(false);
	};

	const handleSave = async () => {
		props.saveFunction(memoSel, props.id);
		handleClose();
	};

	return (
		<>
			<ButtonGroup
				variant="contained"
				aria-label="outlined primary button group"
			>
				{props.id === "edit" ? (
					<Button
						startIcon={<Add />}
						onClick={handleClickOpen}
						id={props.id}
						sx={{ color: "white", display: "none" }}
						variant="outlined"
						className="ButtonMiller"
					>
						Aggiungi
					</Button>
				) : (
					<Button
						startIcon={<Add />}
						onClick={handleClickOpen}
						id={props.id}
						sx={{ color: "white" }}
						variant="outlined"
						className="ButtonMiller"
					>
						Aggiungi
					</Button>
				)}
			</ButtonGroup>

			<Grid
				container
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Dialog
					open={open}
					onClose={handleClose}
					fullWidth={true}
					maxWidth="lg"
					TransitionComponent={Transition}
				>
					{/* Title Container */}
					<DialogTitle
						sx={{
							mx: "auto",
						}}
					>
						{props.title}
					</DialogTitle>
					{/* Body Container */}
					<DialogContent>
						<Grid container></Grid>
						{/* Row 2 */}
						<Grid container spacing={2} sx={{ alignItems: "flex-end" }}>
							{/* <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-multiple-name-label">Utente</InputLabel>
                  <Select
                    value={memoSel.Utente}
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    onChange={(e) => {
                      let m = { ...memoSel };
                      m.Utente = e.target.value;
                      setMemoSel(m);
                    }}
                    input={<OutlinedInput label="Utente" />}
                  >
                    {props.memoUtente?.map((utente) => (
                      <MenuItem key={utente.ID} value={utente.username}>
                        {utente.username}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                    </Grid>
              <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DatePicker
                    renderInput={(props) => <TextField fullWidth {...props} />}
                    ampmInClock={false}
                    label="Data"
                    value={memoSel ? memoSel.Data : null}
                    onChange={(newValue) => {
                      let v = { ...memoSel };
                      v.Data = newValue.format("YYYY-MM-DD");
                      setMemoSel(v);
                    }}
                  />
                </LocalizationProvider>
              </Grid> */}
							<Grid item xs={12} md={12}>
								<TextField
									multiline
									rows={2}
									value={memoSel.Nota}
									id="outlined-basic"
									label="Nota"
									name="Nota"
									onChange={(e) => {
										let m = { ...memoSel };
										m.Nota = e.target.value;
										setMemoSel(m);
									}}
									fullWidth
									sx={{ mt: 2 }}
								/>
							</Grid>
						</Grid>

						{/* Bottoni Salva & Esci */}
						<Grid container>
							<Grid
								item
								xs={12}
								sx={{
									display: "flex",
									justifyContent: "space-between",
									mt: 2,
								}}
							>
								<Button
									onClick={handleClose}
									variant="contained"
									className="ButtonMiller"
									startIcon={<Close />}
								>
									Chiudi
								</Button>
								<Button
									onClick={handleSave}
									variant="contained"
									className="ButtonMiller"
									startIcon={<Check />}
								>
									Salva
								</Button>
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>
			</Grid>
		</>
	);
};
const DeleteDialog = (props) => {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<IconButton aria-label="edit" onClick={handleClickOpen}>
				<Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
			</IconButton>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				TransitionComponent={Transition}
			>
				<DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
					Sei sicuro di voler eliminare il memo selezionato?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Attenzione, sei sicuro di voler eliminare:
						<br />
						<b>{props.memo.Nota}</b>?
					</DialogContentText>
				</DialogContent>
				<DialogActions
					style={{ display: "flex", justifyContent: "space-between" }}
				>
					<Button onClick={handleClose}>annulla</Button>
					<Button onClick={() => props.myFunction(props.memo.ID)}>
						Conferma
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
