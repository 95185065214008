import React, { PureComponent, useState, useEffect } from "react";
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from "recharts";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
} from "@mui/material";
import unsplash from "../api/unsplash";
import { Circle } from "@mui/icons-material";
import ListAltIcon from "@mui/icons-material/ListAlt";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const Charts = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [totale, setTotale] = useState(0);
  const [pOK, setPOk] = useState(0);
  const [pScaduti, setPScaduti] = useState(0);
  const [pMancanti, setPMancanti] = useState(0);
  const [pScadenza, setPScadenza] = useState(0);
  const [pParziale, setPParziale] = useState(0);
  const [data, setData] = useState([]);
  const [user, setUser] = useState({});
  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      let response = await unsplash.get("utenti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      const user = response.data.data.filter(
        (utente) => sessionStorage.getItem("username") === utente.username
      );
      setUser(user[0]);
      loadData(user[0]);
    };

    fetchData();
  }, []);

  const loadData = async (user) => {
    try {
      if (props.Sicurezza || user.Formazione) {
        let response = await unsplash.get("analysis", {
          params: { operazione: "sicurezzaPercentuali" },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        setData(response.data.data.data);
        setTotale(response.data.data.totale);
        setPOk(response.data.data.POk);
        setPScadenza(response.data.data.Pscadenza);
        setPScaduti(response.data.data.Pscaduto);
        setPMancanti(response.data.data.Pmancante);
      }

      if (user.tipologia.Medicina) {
        let response = await unsplash.get("analysis", {
          params: { operazione: "medicinaPercentuali" },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        setData(response.data.data.data);
        setTotale(response.data.data.totale);
        setTotale(response.data.data.totale);
        setPOk(response.data.data.POk);
        setPScadenza(response.data.data.Pscadenza);
        setPScaduti(response.data.data.Pscaduto);
        setPMancanti(response.data.data.Pmancante);
        setPParziale(response.data.data.Pparziale);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={10}
          textAnchor="middle"
          fill={fill}
          fontSize={25}
        >
          {payload.value}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
      </g>
    );
  };

  return (
    <Card
      sx={{
        boxShadow: "none",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <CardContent sx={{ paddingTop: "10px" }}>
        <Grid container spacing={2} sx={{ p: 0 }}>
          <Grid item xs={12} lg={4} sx={{ width: "250px", height: "250px" }}>
            <ResponsiveContainer width="100%" height="100%">
              <center>
                {data.length > 0 ? (
                  <PieChart width={250} height={250}>
                    <Pie
                      activeIndex={activeIndex}
                      activeShape={renderActiveShape}
                      data={data}
                      cx="50%"
                      cy="50%"
                      innerRadius={50}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                      onMouseEnter={onPieEnter}
                    >
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                  </PieChart>
                ) : (
                  <CircularProgress
                    sx={{ marginLeft: "6px", color: "#2265B1" }}
                  />
                )}
              </center>
            </ResponsiveContainer>
          </Grid>
          <Grid
            item
            xs={12}
            lg={8}
            className="flex flex-wrap items-center"
          >
            <div className="flex flex-no-wrap items-center">
              <ListAltIcon sx={{ fontSize: 55, color: "#11526F" }} />
              <div style={{ marginLeft: "5px" }} className="flex flex-wrap">
                <span
                  className="w-full fw-light fs-6"
                  //style={{ color: "rgba(58, 53, 65, 0.6)" }}
                >
                  {user.Sicurezza ? "Corsi totali" : "Visite totali"}
                </span>
                <h6 className="fs-5 fw-bold" 
                style={{ color: "#11526F" }}
                >
                  {totale ?? "0000"}
                </h6>
              </div>
            </div>
            <Divider sx={{ color: "rgba(58, 53, 65, 0.6)", width: "100%" }} />
            <div className="flex flex-wrap  " style={{ padding: "15px 0" }}>
              <div className="w-1/2 flex flex-wrap items-center">
                <Circle sx={{ fontSize: 40, color: "#40C18F" }} />
                <div style={{ marginLeft: "5px" }} className="flex flex-wrap">
                  <span
                    style={{
                      float: "left",
                      marginRight: "5px",
                      width: "100%",
                      //color: "rgba(58, 53, 65, 0.6)",
                    }}
                  >
                    {" OK"}
                  </span>
                  <span
                    className="fs-6 fw-semibold"
                    style={{ color: "#11526F" }}
                  >
                    {pOK + "%"}
                  </span>
                </div>
              </div>
              <div className="w-1/2 flex flex-wrap items-center">
                <Circle sx={{ fontSize: 40, color: "#FFC897" }} />
                <div style={{ marginLeft: "5px" }} className="flex flex-wrap">
                  <span
                    style={{
                      float: "left",
                      marginRight: "5px",
                      width: "100%",
                      //color: "rgba(58, 53, 65, 0.6)",
                    }}
                  >
                    {" In Scadenza"}
                  </span>

                  <span
                    className="fs-6 fw-semibold"
                    style={{ color: "#11526F" }}
                  >
                    {pScadenza + "%"}
                  </span>
                </div>
              </div>
              <div className="w-1/2 flex flex-wrap items-center">
                <Circle sx={{ fontSize: 40, color: "#FF2768" }} />
                <div style={{ marginLeft: "5px" }} className="flex flex-wrap">
                  <span
                    style={{
                      float: "left",
                      marginRight: "5px",
                      width: "100%",
                      //color: "rgba(58, 53, 65, 0.6)",
                    }}
                  >
                    {" Scaduti"}
                  </span>
                  <span
                    className="fs-6 fw-semibold"
                    style={{ color: "#11526F" }}
                  >
                    {pScaduti + "%"}
                  </span>
                </div>
              </div>
              <div className="w-1/2 flex flex-wrap items-center">
                <Circle sx={{ fontSize: 40, color: "#8E75AC" }} />
                <div style={{ marginLeft: "5px" }} className="flex flex-wrap">
                  <span
                    style={{
                      float: "left",
                      marginRight: "5px",
                      width: "100%",
                      //color: "rgba(58, 53, 65, 0.6)",
                    }}
                  >
                    {" Mancante"}
                  </span>
                  <span
                    className="fs-6 fw-semibold"
                    style={{ color: "#11526F" }}
                  >
                    {pMancanti + "%"}
                  </span>
                </div>
              </div>
              {!user.Sicurezza && !user.Formazione ? (
                <div className="w-1/2 flex flex-wrap items-center">
                  <Circle sx={{ fontSize: 40, color: "#2265B1" }} />
                  <div
                    style={{ marginLeft: "5px" }}
                    className="flex flex-wrap"
                  >
                    <span
                      style={{
                        float: "left",
                        marginRight: "5px",
                        width: "100%",
                        color: "rgba(58, 53, 65, 0.6)",
                      }}
                    >
                      {" Parziali"}
                    </span>
                    <span
                      className="fs-6 fw-semibold"
                      style={{ color: "#11526F" }}
                    >
                      {pParziale + "%"}
                    </span>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Charts;
