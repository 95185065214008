import React, { useEffect, useState } from "react";
import "../../style/style.css";
import unsplash from "../../api/unsplash";
import GlobalSnackbar from "../Snackbar";
import moment from "moment-timezone";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { fontVerdanaNormal } from "../../style/verdana-normalW";
import { fontVerdanaBold } from "../../style/verdana-bold";

import { Grid, Button } from "@mui/material";

import { Check } from "@mui/icons-material";

export const StampaDPI = ({ lavoratore, cliente }) => {
  const [openPDF, setOpenPDF] = useState(false);
  const [tableHeight, setTableHeight] = useState(0);
  const [tableDpi, setTableDpi] = useState([]);
  const [sede, setSede] = useState([]);
  const [organigramma, setOrganigramma] = useState([]);

  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  useEffect(() => {
    (async () => {
      try {
        let responseSedi = await unsplash.get("sedi", {
          params: { id: lavoratore.Cliente },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        const sedi = responseSedi.data.data.filter(
          (el) => el.ID === lavoratore.Sede
        );
        if (sedi.length > 0) {
          setSede(sedi[0]);
        }

        let responseOrganigramma = await unsplash.get("organigrammi", {
          params: { sede: lavoratore.Sede, cliente: lavoratore.Cliente },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        const datore = responseOrganigramma.data.data.filter(
          (el) => el.IDRuolo === 1
        );
        if (datore.length > 0) {
          setOrganigramma(datore[0]);
        }

        let response = await unsplash.get("dpi", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: {
            cliente: cliente.ID,
            mansioneSicurezza: lavoratore.MansioneSicurezza,
          },
        });
        setTableDpi(response.data.data);
      } catch {
        console.log("error");
      }
    })();
  }, []);
  function cap(arr) {
    if (arr) {
      for (var i = 0; i < arr.length; i++) {
        arr[i] =
          arr[i].charAt(0).toUpperCase() + arr[i].substring(1).toLowerCase();
      }
      return arr.join(" ");
    }
  }
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size, true);
    var imgDef = cliente.Logo;
    var dataCorrente = new Date();
    dataCorrente = moment(dataCorrente).format("DD/MM/YYYY");

    //AGGIUNGO I FONT CHE HO IMPORTATO
    doc.addFileToVFS("verdana-normal.ttf", fontVerdanaNormal);
    doc.addFileToVFS("verdana-bold.ttf", fontVerdanaBold);
    doc.addFont("verdana-normal.ttf", "verdana", "normal");
    doc.addFont("verdana-bold.ttf", "verdana", "bold");

    setOpenPDF(true);

    doc.setTextColor(50, 62, 79);

    doc.setDrawColor(17, 82, 111);
    doc.setLineWidth(1);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(32, 8, 130, 77, 8, 8); // Black sqaure with rounded corners
    doc.addImage(imgDef, "PNG", 37, 13, 120, 68); //da riguardare se così o al contrario

    doc.roundedRect(172, 8, 280, 77, 8, 8);
    doc.setFontSize(9);
    doc.setFont("verdana", "bold");
    var title1 = "MODULO DI CONSEGNA";
    var title2 = "DISPOSITIVI DI PROTEZIONE INDIVIDUALE (D.P.I.)";
    var title4 = "ai sensi del D.Lgs. 81/2008, art. 18 comma 1, lettera d)";
    var title3 = "Rev.: 00";

    doc.text(
      title1,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title1) * doc.internal.getFontSize()) / 2,
      40
    );

    doc.text(
      title2,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title2) * doc.internal.getFontSize()) / 2,
      52
    );

    doc.setFontSize(8);
    doc.setFont("verdana", "normal");
    doc.text(
      title4,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title4) * doc.internal.getFontSize()) / 2,
      64
    );

    doc.setFontSize(9);
    doc.setFont("verdana", "bold");
    doc.text(
      title3,
      500 +
        15 -
        (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2,
      45
    );
    doc.roundedRect(462, 8, 100, 77, 8, 8);

    doc.text(
      dataCorrente,
      500 +
        15 -
        (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2,
      59
    );

    /* INIZIO TESTO */
    doc.setFontSize(10);
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Il sottoscritto ${cap(
          organigramma?.Nominativo.split(" ")
        )} in qualità di Datore di Lavoro della società ${
          cliente?.RagioneSociale
        }, ${sede?.Via} ${sede?.Descrizione}, ${
          sede?.Cap
        } in seguito alla valutazione dei rischi, sentito il Responsabile del Servizio di Prevenzione e Protezione, il Medico Competente e il Rappresentante dei Lavoratori per la Sicurezza, ha consegnato in data odierna a ${
          lavoratore?.Nome
        } ${
          lavoratore?.Cognome
        } i seguenti DPI per lo svolgimento della mansione ${
          lavoratore?.Descrizione
        }:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      120
    );
    let height = 0;
    let tdArr = [];
    doc.autoTable({
      startY: 190,
      html: "#table-DPI",
      useCss: true, //IMPORTANTISSIMO

      didDrawCell: function (data) {
        const td = data.cell.raw;
        if (td.cellIndex === 0 && td.id !== "th") {
          tdArr.push(data.cell.height);
          const result = tdArr.reduce((a, b) => Math.max(a, b));
          let img = td.getElementsByTagName("img")[0];
          let textPos = data.cell;
          doc.addImage(
            img.src,
            (data.cell.width - 30) / 2 + textPos.x,
            (data.cell.height - 30) / 2 + textPos.y,
            30,
            30
          );
        }
      },
      columnStyles: {
        0: { cellWidth: 80 },
      },
      tableWidth: "auto",
      didDrawPage: function (column) {
        const arrHeight = column.table.body.map((el) => el.height);
        height = arrHeight.reduce((total, amount) => total + amount);
        if (height > 521.89) {
          height = height - 421.89;
        } else {
          height = height + 220;
        }
      },
      margin: { top: 40, right: 40, bottom: 80, left: 40 },
      rowPageBreak: "auto",
    });

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Distinti saluti`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      height
    );
    doc.text(
      doc.splitTextToSize(
        `Il Datore di lavoro`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      height + 20
    );
    doc.text(
      doc.splitTextToSize(
        `____________________`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      height + 40
    );

    doc.addPage();
    doc.setDrawColor(17, 82, 111);
    doc.setLineWidth(1);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(32, 8, 130, 77, 8, 8); // Black sqaure with rounded corners
    doc.addImage(imgDef, "PNG", 37, 13, 120, 68); //da riguardare se così o al contrario

    doc.roundedRect(172, 8, 280, 77, 8, 8);
    doc.setFontSize(9);
    doc.setFont("verdana", "bold");
    var title1 = "MODULO DI CONSEGNA";
    var title2 = "DISPOSITIVI DI PROTEZIONE INDIVIDUALE (D.P.I.)";
    var title4 = "ai sensi del D.Lgs. 81/2008, art. 18 comma 1, lettera d)";
    var title3 = "Rev.: 00";

    doc.text(
      title1,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title1) * doc.internal.getFontSize()) / 2,
      40
    );

    doc.text(
      title2,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title2) * doc.internal.getFontSize()) / 2,
      52
    );

    doc.setFontSize(8);
    doc.setFont("verdana", "normal");
    doc.text(
      title4,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title4) * doc.internal.getFontSize()) / 2,
      64
    );

    doc.setFontSize(9);
    doc.setFont("verdana", "bold");
    doc.text(
      title3,
      500 +
        15 -
        (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2,
      45
    );
    doc.roundedRect(462, 8, 100, 77, 8, 8);

    doc.text(
      dataCorrente,
      500 +
        15 -
        (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2,
      59
    );

    doc.setFontSize(9);
    doc.setFont("verdana", "bold");
    var title1 = "INFORMATIVA ALLEGATA AL MODULO DI CONSEGNA DEI D.P.I.";
    doc.text(
      title1,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title1) * doc.internal.getFontSize()) / 2,
      102
    );

    doc.setFont("verdana", "normal");

    doc.text(
      doc.splitTextToSize(
        `I dispositivi di protezione vanno usati obbligatoriamente durante lo svolgimento delle mansioni, secondo le disposizioni del Decreto Legislativo n. 81/2008 riportati per intero nella presente nota informativa`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      120
    );

    doc.text(
      doc.splitTextToSize(
        `Articolo 20 D.Lgs. 81/2008 - Obbligo di uso`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      154
    );
    doc.text(
      doc.splitTextToSize(
        `1. Ogni lavoratore deve prendersi cura della propria salute e sicurezza e di quella delle altre persone presenti sul luogo di lavoro, su cui ricadono gli effetti delle sue azioni o omissioni, conformemente alla sua formazione, alle istruzioni e ai mezzi forniti dal datore di lavoro.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      164
    );
    doc.text(
      doc.splitTextToSize(
        `2. I lavoratori devono in particolare:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      194
    );
    doc.text(
      doc.splitTextToSize(
        `a) contribuire, insieme al datore di lavoro, ai dirigenti e ai preposti, all’adempimento degli obblighi previsti a tutela della salute e sicurezza sui luoghi di lavoro;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      204
    );
    doc.text(
      doc.splitTextToSize(
        `b) osservare le disposizioni e le istruzioni impartite dal datore di lavoro, dai dirigenti e dai preposti, ai fini della protezione collettiva ed individuale;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      224
    );
    doc.text(
      doc.splitTextToSize(
        `c) utilizzare correttamente le attrezzature di lavoro, le sostanze e i preparati pericolosi, i mezzi di trasporto e, nonché i dispositivi di sicurezza;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      244
    );
    doc.text(
      doc.splitTextToSize(
        `d) utilizzare in modo appropriato i dispositivi di protezione messi a loro disposizione;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      264
    );
    doc.text(
      doc.splitTextToSize(
        `e) segnalare immediatamente al datore di lavoro, al dirigente o al preposto le deficienze dei mezzi e dei dispositivi di cui alle lettere`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      274
    );
    doc.text(
      doc.splitTextToSize(
        `c) e d), nonché qualsiasi eventuale condizione di pericolo di cui vengano a conoscenza, adoperandosi direttamente, in caso di urgenza, nell’ambito delle proprie competenze e possibilità e fatto salvo l’obbligo di cui alla lettera f) per eliminare o ridurre le situazioni di pericolo grave e incombente, dandone notizia al rappresentante dei lavoratori per la sicurezza;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      294
    );
    doc.text(
      doc.splitTextToSize(
        `f) non rimuovere o modificare senza autorizzazione i dispositivi di sicurezza o di segnalazione o di controllo;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      334
    );
    doc.text(
      doc.splitTextToSize(
        `g) non compiere di propria iniziativa operazioni o manovre che non sono di loro competenza ovvero che possono compromettere la sicurezza propria o di altri lavoratori;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      344
    );
    doc.text(
      doc.splitTextToSize(
        `h) partecipare ai programmi di formazione e di addestramento organizzati dal datore di lavoro;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      364
    );
    doc.text(
      doc.splitTextToSize(
        `i) sottoporsi ai controlli sanitari previsti dal presente decreto legislativo o comunque disposti dal medico competente.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      374
    );
    doc.text(
      doc.splitTextToSize(
        `3. I lavoratori di aziende che svolgono attività in regime di appalto o subappalto, devono esporre apposita tessera di riconoscimento, corredata di fotografia, contenente le generalità del lavoratore e l’indicazione del datore di lavoro. Tale obbligo grava anche in capo ai lavoratori autonomi che esercitano direttamente la propria attività nel medesimo luogo di lavoro, i quali sono tenuti a provvedervi per proprio conto.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      394
    );

    doc.text(
      doc.splitTextToSize(
        `Articolo 59 - Sanzioni per i lavoratori`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      440
    );
    doc.text(
      doc.splitTextToSize(
        `1. I lavoratori sono puniti:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      450
    );
    doc.text(
      doc.splitTextToSize(
        `a) con l’arresto fino a un mese o con l’ammenda da 245,70 a 737,10 euro per la violazione degli articoli 20, comma 2, lettere b), c), d), e), f), g), h) e i), e 43, comma 3, primo periodo;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      460
    );
    doc.text(
      doc.splitTextToSize(
        `b) con la sanzione amministrativa pecuniaria da 61,42 a 368,56 euro per la violazione dell’articolo 20 comma 3.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      480
    );
    doc.text(
      doc.splitTextToSize(
        `Articolo 75 D.Lgs. 81/2008 - Obbligo di uso`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      500
    );
    doc.text(
      doc.splitTextToSize(
        `I DPI devono essere impiegati quando i rischi non possono essere evitati o sufficientemente ridotti da misure tecniche di prevenzione, da mezzi di protezione collettiva, da misure, metodi o procedimenti di riorganizzazione del lavoro.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      510
    );
    doc.text(
      doc.splitTextToSize(
        `Articolo 78 D.Lgs. 81/2008 - Obblighi dei lavoratori`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      540
    );
    doc.text(
      doc.splitTextToSize(
        `1. In ottemperanza a quanto previsto dall’articolo 20, comma 2, lettera h), i lavoratori si sottopongono al programma di formazione e addestramento organizzato dal datore di lavoro nei casi ritenuti necessari ai sensi dell’articolo 77 commi 4, lettera h), e 5.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      550
    );
    doc.text(
      doc.splitTextToSize(
        `2. In ottemperanza a quanto previsto dall’articolo 20, comma 2, lettera d), i lavoratori utilizzano i DPI messi a loro disposizione conformemente all’informazione e alla formazione ricevute e all’addestramento eventualmente organizzato ed espletato.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      580
    );
    doc.text(
      doc.splitTextToSize(
        `3. I lavoratori:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      610
    );
    doc.text(
      doc.splitTextToSize(
        `a) provvedono alla cura dei DPI messi a loro disposizione;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      620
    );
    doc.text(
      doc.splitTextToSize(
        `b) non vi apportano modifiche di propria iniziativa.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      630
    );
    doc.text(
      doc.splitTextToSize(
        `4. Al termine dell'utilizzo i lavoratori seguono le procedure aziendali in materia di riconsegna dei DPI.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      640
    );
    doc.text(
      doc.splitTextToSize(
        `5. I lavoratori segnalano immediatamente al datore di lavoro o al dirigente o al preposto qualsiasi difetto o inconveniente da essi rilevato nei DPI messi a loro disposizione.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      650
    );

    doc.setFontSize(10);
    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        `Data ${dataCorrente}`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      705
    );

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Il/La sottoscritto/a ${cap(lavoratore.Nome.split(" "))} ${cap(
          lavoratore.Cognome.split(" ")
        )} nel ricevere in data ${dataCorrente} i DPI sopra riportati  dichiara di aver preso visione dell’informativa allegata e di attenersi a quanto in essa riportato.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      725
    );

    doc.text(
      doc.splitTextToSize(
        `Il lavoratore`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      777
    );
    doc.text(
      doc.splitTextToSize(
        `____________________`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      797
    );

    doc.output("pdfobjectnewwindow", "DPI.pdf");
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <table id="table-DPI" hidden>
        <tr>
          <th scope="col" id="th"></th>
          <th scope="col">Descrizione DPI</th>
          <th scope="col">Rischi associati</th>
        </tr>
        {tableDpi?.map((el) => {
          return (
            <>
              <tr>
                <td>
                  <img
                    id="table-pittogramma"
                    src={
                      el?.Immagine
                        ? "data:image/png;base64," + el?.Immagine
                        : require("../../assets/images/MILLERLogo.jpg")
                    }
                    alt="pittogramma"
                  />
                </td>
                <td>{el?.DescrizioneDPI}</td>
                <td>{el?.Rischio}</td>
              </tr>
            </>
          );
        })}
      </table>
      <Grid item xs={12}>
        <Button
          variant="contained"
          className="ButtonMiller"
          startIcon={<Check />}
          onClick={() => {
            exportPDF();
          }}
        >
          CONSEGNA DPI
        </Button>
      </Grid>
    </>
  );
};
