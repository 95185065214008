import React, { useEffect, useState, useRef } from "react";
import unsplash from "../api/unsplash";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import {
  IconButton,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Box,
  Checkbox,
  Autocomplete,
  ButtonGroup,
  Zoom,
  Divider,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import {
  Delete,
  Add,
  AccountTree,
  Print,
  Check,
  Close,
  ArrowDropUp,
  ArrowBack,
  ArrowForward,
  Edit,
} from "@mui/icons-material";
import "../style/style.css";
import moment from "moment-timezone";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { fontVerdanaNormal } from "../style/verdana-normalW";
import { fontVerdanaBold } from "../style/verdana-bold";
import GlobalSnackbar from "./Snackbar";

import Grow from "@mui/material/Grow";
import PrintIcon from "@mui/icons-material/Print";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import GroupsIcon from "@mui/icons-material/Groups";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { Tree, TreeNode } from "react-organizational-chart";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import * as htmlToImage from "html-to-image";
import SwipeRightAltIcon from "@mui/icons-material/SwipeRightAlt";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { async } from "q";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const steps = [
  "Introduzione",
  "Step 1",
  "Step 2",
  "Step 3",
  "Step 4",
  "Step 5",
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const Organigramma = (props) => {
  const [openPDF, setOpenPDF] = useState(false);
  const [organigramma, setOrganigramma] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tabellaFormazione, setTabellaFormazione] = useState([]);
  const [personeFormazione, setPersoneFormazione] = useState({
    Datore: null,
    RSPP: null,
    RLS: null,
  });

  const [ruoliOrganigramma, setRuoliOrganigramma] = useState([]);
  const [lavoratori, setLavoratori] = useState(false);
  const [openPrint, setOpenPrint] = useState(false);
  const [openPrintASPP, setOpenPrintASPP] = useState(false);
  const [openPrintPrimoSoccorso, setOpenPrintPrimoSoccorso] = useState(false);
  const [openPrintEmergenza, setOpenPrintEmergenza] = useState(false);
  const [RLS, setRLS] = useState([]);
  const [RLSSel, setRLSSel] = useState("___________________");
  const [valueRSPP, setValueRSPP] = useState({ dl: "", rspp: "" });
  const [valueASPP, setValueASPP] = useState({ dl: "", aspp: "" });
  const [datoreLavoroRSPP, setDatoreLavoroRSPP] = useState(false);
  const [datoreLavoroPrimoSoccorso, setDatoreLavoroPrimoSoccorso] =
    useState(false);
  const [datoreLavoroEmergenza, setDatoreLavoroEmergenza] = useState(false);
  const [primoSoccorsoValue, setPrimoSoccorsoValue] = useState(
    "___________________"
  );
  const [emergenzaValue, setEmergenzaValue] = useState("___________________");

  const anchorRef = useRef(null);
  const [openButton, setOpenButton] = useState(false);
  const [operation, setOperation] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [medici, setMedici] = useState([]);

  const [organigrammaSel, setOrganigrammaSel] = useState(null);
  const [loadingStampe, setLoadingStampe] = useState(false);

  const options = [
    {
      icon: <FormatListBulletedIcon />,
      text: "CONVOCAZIONE RIUNIONE",
    },
    /* {
      icon: <GroupsIcon />,
      text: "PIANO FORMAZIONE",
    }, */
    {
      icon: <SwipeRightAltIcon />,
      text: "RIUNIONE PERIODICA",
    },
  ];

  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleChange = (e) => {
    let s = { ...valueRSPP };
    s[e.target.name] = e.target.value;
    setValueRSPP(s);
  };
  const handleChangeASPP = (e) => {
    let s = { ...valueASPP };
    s[e.target.name] = e.target.value;
    setValueASPP(s);
  };
  function cap(arr) {
    if (arr) {
      for (var i = 0; i < arr.length; i++) {
        arr[i] =
          arr[i].charAt(0).toUpperCase() + arr[i].substring(1).toLowerCase();
      }
      return arr.join(" ");
    }
  }

  const exportPDFEmergenza = (
    logo,
    nominativo,
    ragioneSociale,
    sedeLoc,
    sedeInd,
    sedeCap,
    bloccoSede
  ) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size, true);
    const imgLogo = new Image();
    var imgDef = logo;
    var dataCorrente = new Date();
    dataCorrente = moment(dataCorrente).format("DD/MM/YYYY");

    //AGGIUNGO I FONT CHE HO IMPORTATO
    doc.addFileToVFS("verdana-normal.ttf", fontVerdanaNormal);
    doc.addFileToVFS("verdana-bold.ttf", fontVerdanaBold);
    doc.addFont("verdana-normal.ttf", "verdana", "normal");
    doc.addFont("verdana-bold.ttf", "verdana", "bold");

    var col = [
      `${imgLogo}`,
      "Budgeted Operating Expenses ",
      "Exclude",
      "Expenses",
    ];
    var rows = [];
    setOpenPDF(true);

    doc.setTextColor(50, 62, 79);

    doc.setDrawColor(17, 82, 111);
    doc.setLineWidth(1);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(32, 8, 130, 77, 8, 8); // Black sqaure with rounded corners
    doc.addImage(imgDef, "PNG", 37, 13, 120, 68); //da riguardare se così o al contrario

    doc.roundedRect(172, 8, 280, 77, 8, 8);
    doc.setFontSize(9);
    doc.setFont("verdana", "bold");

    var title1 = "DESIGNAZIONE ai sensi dell'art. 18 comma 1";
    var title2 = "lettera b) del D.Lgs 81/08 e art. 4";
    var title4 = "D.M. 02.09.2021 a INCARICATO ALLA LOTTA";
    var title5 = "ANTINCENDIO E GESTIONE DELLE EMERGENZE";
    var title3 = "Rev.: 00";
    xOffset =
      doc.internal.pageSize.width / 2 +
      15 -
      (doc.getStringUnitWidth(title1) * doc.internal.getFontSize()) / 2;
    doc.text(title1, xOffset, 30);

    xOffset =
      doc.internal.pageSize.width / 2 +
      15 -
      (doc.getStringUnitWidth(title2) * doc.internal.getFontSize()) / 2;
    doc.text(title2, xOffset, 42);

    xOffset =
      doc.internal.pageSize.width / 2 +
      15 -
      (doc.getStringUnitWidth(title4) * doc.internal.getFontSize()) / 2;
    doc.text(title4, xOffset, 54);
    xOffset =
      doc.internal.pageSize.width / 2 +
      15 -
      (doc.getStringUnitWidth(title5) * doc.internal.getFontSize()) / 2;
    doc.text(title5, xOffset, 66);

    doc.setFontSize(10);
    xOffset =
      500 +
      15 -
      (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2;
    doc.text(title3, xOffset, 45);
    doc.roundedRect(462, 8, 100, 77, 8, 8);
    xOffset =
      500 +
      15 -
      (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2;
    doc.text(dataCorrente, xOffset, 59);

    /* INIZIO TESTO */
    doc.setFontSize(10);
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Premesso che:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      120
    );

    doc.text(
      doc.splitTextToSize(
        "L’art. 18, comma 1) lett. b) del D.Lgs 81/08 prevede che il Datore di lavoro debba “designare preventivamente i lavoratori incaricati dell'attuazione delle misure di prevenzione incendi e lotta antincendio, di evacuazione dei luoghi di lavoro in caso di pericolo grave e immediato, di salvataggio, di primo soccorso e, comunque, di gestione dell'emergenza”.",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      132
    );
    doc.text(
      doc.splitTextToSize(
        "Con la presente Le comunico che, in base all’art. 18, c. 1, lett. b) e art. 43 comma 1 lett. b) del D. L.gs 9 aprile 2008, n° 81, è stato designato, previa consultazione con il Rappresentante dei Lavoratori per la Sicurezza, quale:",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      185
    );

    /* TESTO CENTRATO */
    doc.setFont("verdana", "bold");
    doc.setFontSize(9);
    var text =
        "Lavoratore incaricato dell’attuazione delle misure di prevenzione incendi,",
      xOffset =
        doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / 2;
    doc.text(text, xOffset, 221);

    var text2 = "lotta antincendio e di evacuazione",
      xOffset =
        doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth(text2) * doc.internal.getFontSize()) / 2;
    doc.text(text2, xOffset, 233);

    doc.setFontSize(10);
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Tale attività sarà prestata presso il sito di ${sedeLoc} ${sedeCap} in ${sedeInd}, conformemente alla Sua formazione e alle disposizioni e istruzioni impartite dalla dirigenza.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      250
    );

    doc.text(
      doc.splitTextToSize(
        "Si evidenzia che a norma dell’articolo 43 comma 3, Lei non può, se non per giustificato motivo, rifiutare la designazione (in tal caso la motivazione dovrà essere comunicata per iscritto) e che l’incarico avrà durata fino a revoca.",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      282
    );

    doc.text(
      doc.splitTextToSize(
        "Si evidenzia inoltre che per Lei è prevista, ai sensi dell’art. 37 sempre del suddetto Decreto, una adeguata e specifica formazione ed un aggiornamento periodico.",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      322
    );

    doc.text(
      doc.splitTextToSize(
        "In qualità di Incaricato alla lotta antincendio e gestione delle emergenze, dovrà:",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      350
    );

    doc.text(
      doc.splitTextToSize(
        "\u2022 " +
          "Collaborare con gli altri componenti della squadra antincendio durante il servizio di lavoro;",
        doc.internal.pageSize.getWidth() - 80
      ),
      48,
      365
    );
    doc.text(
      doc.splitTextToSize(
        "\u2022 " +
          "Partecipare attivamente alle esercitazioni periodiche previste dal D.M. 02/09/21, adoperandosi per ",
        doc.internal.pageSize.getWidth() - 80
      ),
      48,
      377
    );
    doc.text(
      doc.splitTextToSize(
        "il miglior esito delle stesse;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      389
    );
    doc.text(
      doc.splitTextToSize(
        "\u2022 " +
          "Conoscere le norme di esercizio contenute nel piano di emergenza, e concernenti gli impianti;",
        doc.internal.pageSize.getWidth() - 80
      ),
      48,
      401
    );
    doc.text(
      doc.splitTextToSize(
        "\u2022 " +
          "Conoscere l’ubicazione dei percorsi e delle uscite di sicurezza;",
        doc.internal.pageSize.getWidth() - 80
      ),
      48,
      413
    );
    doc.text(
      doc.splitTextToSize(
        "\u2022 " +
          "Conoscere l’ubicazione dei dispositivi di sicurezza (pulsanti di sgancio energia elettrica, pulsanti di ",
        doc.internal.pageSize.getWidth() - 80
      ),
      48,
      425
    );
    doc.text(
      doc.splitTextToSize(
        "allarme, valvole gas metano ecc);",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      437
    );
    doc.text(
      doc.splitTextToSize(
        "\u2022 " +
          "Collaborare con il coordinatore della squadra durante l’attività di controllo delle presenze nel punto ",
        doc.internal.pageSize.getWidth() - 80
      ),
      48,
      449
    );
    doc.text(
      doc.splitTextToSize(
        "esterno di raccolta;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      461
    );

    doc.setFontSize(10);
    doc.text(
      doc.splitTextToSize(
        "Distinti saluti",
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      490
    );
    doc.text(
      doc.splitTextToSize(
        "Il Datore di lavoro",
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      515
    );
    doc.text(
      doc.splitTextToSize(
        "_____________________",
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      535
    );

    doc.setFontSize(10);
    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        `Data ${dataCorrente}`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      570
    );

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Il/La sottoscritto/a ${cap(
          nominativo?.split(" ")
        )} nel ricevere in data ${dataCorrente} l'originale della presente nomina, prende atto di quanto, in essa contenuto e dichiara di accettare l'incarico di addetto alla lotta antincendi e gestione emergenze, nel contesto dell’azienda ${ragioneSociale} presso il sito di ${bloccoSede}.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      585
    );

    doc.text(
      doc.splitTextToSize(`L’addetto`, doc.internal.pageSize.getWidth() - 60),
      33,
      650
    );
    doc.text(
      doc.splitTextToSize(
        `____________________`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      675
    );

    doc.text(
      doc.splitTextToSize(
        `Per consultazione il Rappresentante`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`Per consultazione il Rappresentante`) *
          doc.internal.getFontSize() -
        40,
      700
    );
    doc.text(
      doc.splitTextToSize(
        `dei lavoratori per la sicurezza`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`dei lavoratori per la sicurezza`) *
          doc.internal.getFontSize() -
        40,
      712
    );
    doc.text(
      doc.splitTextToSize(
        `_____________________________`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`_____________________________`) *
          doc.internal.getFontSize() -
        40,
      737
    );

    doc.output("pdfobjectnewwindow", "attestato.pdf");
  };
  const exportPDFDatoreLavoroEmergenza = (
    logo,
    nominativo,
    ragioneSociale,
    sedeLoc,
    sedeInd,
    sedeCap,
    bloccoSede
  ) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size, true);
    const imgLogo = new Image();
    var imgDef = logo;
    var dataCorrente = new Date();
    dataCorrente = moment(dataCorrente).format("DD/MM/YYYY");

    //AGGIUNGO I FONT CHE HO IMPORTATO
    doc.addFileToVFS("verdana-normal.ttf", fontVerdanaNormal);
    doc.addFileToVFS("verdana-bold.ttf", fontVerdanaBold);
    doc.addFont("verdana-normal.ttf", "verdana", "normal");
    doc.addFont("verdana-bold.ttf", "verdana", "bold");

    setOpenPDF(true);

    doc.setTextColor(50, 62, 79);

    doc.setDrawColor(17, 82, 111);
    doc.setLineWidth(1);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(32, 8, 130, 77, 8, 8); // Black sqaure with rounded corners
    doc.addImage(imgDef, "PNG", 37, 13, 120, 68); //da riguardare se così o al contrario

    doc.roundedRect(172, 8, 280, 77, 8, 8);
    doc.setFontSize(9);
    doc.setFont("verdana", "bold");
    var title1 = "DESIGNAZIONE ai sensi dell'art. 18 comma 1";
    var title2 = "lettera b) del D.Lgs. 81/08 e art. 4";
    var title4 = "D.M. 02.09.2021 a INCARICATO ALLA LOTTA";
    var title5 = "ANTINCENDIO E GESTIONE DELLE EMERGENZE";
    var title3 = "Rev.: 00";

    doc.text(
      title1,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title1) * doc.internal.getFontSize()) / 2,
      35
    );

    doc.text(
      title2,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title2) * doc.internal.getFontSize()) / 2,
      47
    );

    doc.text(
      title4,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title4) * doc.internal.getFontSize()) / 2,
      59
    );

    doc.text(
      title5,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title5) * doc.internal.getFontSize()) / 2,
      71
    );

    doc.setFontSize(10);

    doc.text(
      title3,
      500 +
        15 -
        (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2,
      45
    );
    doc.roundedRect(462, 8, 100, 77, 8, 8);

    doc.text(
      dataCorrente,
      500 +
        15 -
        (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2,
      59
    );

    /* INIZIO TESTO */
    doc.setFontSize(10);
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        "Premesso che:",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      105
    );
    doc.text(
      doc.splitTextToSize(
        "L’art. 18, comma 1) lett. b) del D.Lgs 81/08 prevede che il Datore di lavoro debba “designare preventivamente i lavoratori incaricati dell'attuazione delle misure di prevenzione incendi e lotta antincendio, di evacuazione dei luoghi di lavoro in caso di pericolo grave e immediato, di salvataggio, di primo soccorso e, comunque, di gestione dell'emergenza”.",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      117
    );
    doc.text(
      doc.splitTextToSize(
        "L’art. 34 comma 1 prevede che “ Salvo che nei casi di cui all'articolo 31, comma 6, il datore di lavoro può svolgere direttamente i compiti propri del servizio di prevenzione e protezione dai rischi, di primo soccorso, nonché di prevenzione incendi e di evacuazione, nelle ipotesi previste nell'allegato 2 dandone preventiva informazione al rappresentante dei lavoratori per la sicurezza ed alle condizioni di cui ai commi successivi”.",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      165
    );

    doc.text(
      doc.splitTextToSize(
        `Il/La sottoscritto/a ${cap(
          nominativo?.split(" ")
        )}, in qualità di Datore di Lavoro dell'azienda ${ragioneSociale}, dopo aver:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      232
    );

    doc.text(
      doc.splitTextToSize(
        "\u2022    " +
          "verificato di non rientrare nella casistica citata all’Allegato 2 del D.Lgs.81/2008",
        doc.internal.pageSize.getWidth() - 80
      ),
      48,
      260
    );
    doc.text(
      doc.splitTextToSize(
        "\u2022    " +
          "verificato di essere esclusa dalla casistica dai casi citati all’art. 31 comma 6 del D.Lgs 81/08",
        doc.internal.pageSize.getWidth() - 80
      ),
      48,
      272
    );

    doc.setFont("verdana", "bold");

    doc.text(
      "DICHIARA",
      doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth("DICHIARA") * doc.internal.getFontSize()) / 2,
      290
    );

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `di assumere in prima persona l’incarico di addetto alla lotta antincendio e gestione delle emergenze presso l’azienda ${ragioneSociale}, in relazione a quanto previsto dall’art. 34 del D.Lgs 81/08;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      310
    );

    doc.setFont("verdana", "bold");

    doc.text(
      "DICHIARA INOLTRE",
      doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth("DICHIARA INOLTRE") *
          doc.internal.getFontSize()) /
          2,
      340
    );
    doc.setFont("verdana", "normal");
    doc.text(doc.splitTextToSize("\u2022"), 48, 360);
    doc.text(
      doc.splitTextToSize(
        "di aver frequentato e di essere in possesso del relativo attestato di partecipazione, di apposito corso di formazione secondo quanto previsto dall’art 34 comma 2-bis del D.Lgs 81/08",
        doc.internal.pageSize.getWidth() - 80
      ),
      58,
      360
    );

    doc.text(
      doc.splitTextToSize("In fede", doc.internal.pageSize.getWidth() - 60),
      33,
      415
    );
    doc.text(
      doc.splitTextToSize(
        "Il Datore di lavoro",
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      445
    );
    doc.text(
      doc.splitTextToSize(
        "_____________________",
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      475
    );

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Il/La sottoscritto/a ${cap(
          RLSSel?.split(" ")
        )}, in qualità di RLS della società ${ragioneSociale}, dichiara di essere stato preventivamente informato dal Datore di Lavoro in merito alla volontà di ricoprire in prima persona il ruolo di addetto alla lotta antincendio e gestione delle emergenze all’interno del sito di ${bloccoSede}.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      520
    );

    doc.text(
      doc.splitTextToSize(
        `Il Rappresentante dei lavoratori`,
        doc.internal.pageSize.getWidth() - 60
      ),

      doc.internal.pageSize.getWidth() - //prendo la grandezza totale della riga e sottraggo
        doc.getStringUnitWidth(`Il Rappresentante dei lavoratori`) * //la grandezza della frase
          doc.internal.getFontSize() - //per la grandezza del font
        40, //mettto del margine
      615
    );

    doc.text(
      doc.splitTextToSize(
        `Per la sicurezza`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`Per la sicurezza`) *
          doc.internal.getFontSize() -
        40,
      627
    );

    doc.text(
      doc.splitTextToSize(
        `________________________`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`________________________`) *
          doc.internal.getFontSize() -
        40,
      650
    );

    doc.output("pdfobjectnewwindow", "attestato.pdf");
  };
  const exportPDFDirigente = (
    infoSede,
    logo,
    ragioneSociale,
    sedeLoc,
    sedeInd,
    sedeCap,
    bloccoSede,
    sedeCF,
    dirigente
  ) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size, true);
    const imgLogo = new Image();
    var imgDef = logo;
    var dataCorrente = new Date();
    dataCorrente = moment(dataCorrente).format("DD/MM/YYYY");
    //AGGIUNGO I FONT CHE HO IMPORTATO
    doc.addFileToVFS("verdana-normal.ttf", fontVerdanaNormal);
    doc.addFileToVFS("verdana-bold.ttf", fontVerdanaBold);
    doc.addFont("verdana-normal.ttf", "verdana", "normal");
    doc.addFont("verdana-bold.ttf", "verdana", "bold");
    let ddl = organigramma.filter((o) => o.IDRuolo === 1);
    var col = [
      `${imgLogo}`,
      "Budgeted Operating Expenses ",
      "Exclude",
      "Expenses",
    ];
    var rows = [];
    setOpenPDF(true);

    doc.setTextColor(50, 62, 79);

    /* IMG w=125 h=80 la grandezza dell'immagine deve essere come massimo di 125 di w e 80 di h  */

    doc.setDrawColor(17, 82, 111);
    doc.setLineWidth(1);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(32, 8, 130, 77, 8, 8); // Black sqaure with rounded corners
    doc.addImage(imgDef, "PNG", 37, 13, 120, 68); //da riguardare se così o al contrario

    doc.roundedRect(172, 8, 280, 77, 8, 8);
    doc.setFontSize(10);
    doc.setFont("verdana", "bold");
    var title1 = "NOMINA DIRIGENTE AI SENSI DELL’ART. 2,";
    var title2 = "COMMA 1, LETTERA d) DEL D.LGS 81/08 S.M.I.";
    var title3 = "Rev.: 00";
    xOffset =
      doc.internal.pageSize.width / 2 +
      15 -
      (doc.getStringUnitWidth(title1) * doc.internal.getFontSize()) / 2;
    doc.text(title1, xOffset, 45);

    xOffset =
      doc.internal.pageSize.width / 2 +
      15 -
      (doc.getStringUnitWidth(title2) * doc.internal.getFontSize()) / 2;
    doc.text(title2, xOffset, 59);
    xOffset =
      500 +
      15 -
      (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2;
    doc.text(title3, xOffset, 45);
    doc.roundedRect(462, 8, 100, 77, 8, 8);
    xOffset =
      500 +
      15 -
      (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2;
    doc.text(dataCorrente, xOffset, 59);

    // INIZIO TESTO
    doc.setFontSize(10);
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Premesso che:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      120
    );
    doc.text(
      doc.splitTextToSize(
        `L’art. 1 lett. d) del D.Lgs 81/08 definisce il “Dirigente” come la “persona che, in ragione delle competenze professionali e di poteri gerarchici e funzionali adeguati alla natura dell'incarico conferitogli, attua le direttive del datore di lavoro organizzando l'attività lavorativa e vigilando su di essa;”.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      132
    );
    doc.text(
      doc.splitTextToSize(
        `Il sottoscritto ${cap(
          ddl[0]?.Nominativo?.split(" ")
        )} in qualità di “datore di lavoro” della società ${ragioneSociale} con sede legale in ${sedeLoc} ${sedeInd}, C.F. e P.IVA ${sedeCF}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      190
    );
    doc.setFont("verdana", "bold");
    var text = "PREMESSO",
      xOffset =
        doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / 2;
    doc.text(text, xOffset, 215);

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        "di aver verificato che Ella è il possesso delle necessarie conoscenze tecniche e di capacità ed esperienza per l’esecuzione in sicurezza dei compiti che Le sono assegnati, con la presente",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      233
    );

    doc.setFont("verdana", "bold");
    var text = "FORMALIZZA",
      xOffset =
        doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / 2;
    doc.text(text, xOffset, 265);

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `il Suo ruolo di Dirigente dell’applicazione delle norme in materia di salute e sicurezza nei luoghi di lavoro per il sito di ${
          infoSede?.Ubicazione + ", " + infoSede?.Via
        }, così come definito dall’art.2 c.1 lett. d) del D.Lgs.9 aprile 2008 n.81.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      280
      //220
    );

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        "In particolare, i compiti propri di questa funzione, nell’ambito dell’organizzazione aziendale, sono quelli di cui all’art.18 - Obblighi del datore di lavoro e del dirigente - del D.Lgs.81/2008, in base alle deleghe ed incarichi ricevuti.",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      320
    );

    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        "Articolo 18 - D.lgs 81/08 - Obblighi del datore di lavoro e del dirigente:",
        doc.internal.pageSize.getWidth() - 80
      ),
      26,
      360
    );

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        "1. Il datore di lavoro, che esercita le attività di cui all’articolo 3 e i dirigenti, che organizzano e dirigono le stesse attività secondo le attribuzioni e competenze ad essi conferite, devono:",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      375
    );

    //INIZIO ELENCO PUNTATO
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      400
    );
    doc.text(
      doc.splitTextToSize(
        "nominare il medico competente per l’effettuazione della sorveglianza sanitaria nei casi previsti dal presente decreto legislativo e qualora richiesto dalla valutazione dei rischi;",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      400
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      425
    );
    doc.text(
      doc.splitTextToSize(
        "designare preventivamente i lavoratori incaricati dell’attuazione delle misure di prevenzione;",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      425
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      437.5
    );
    doc.text(
      doc.splitTextToSize(
        "incendi e lotta antincendio, di evacuazione dei luoghi di lavoro in caso di pericolo grave e immediato, di salvataggio, di primo soccorso e, comunque, di gestione dell’emergenza;",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      437.5
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      462.5
    );
    doc.text(
      doc.splitTextToSize(
        "nell’affidare i compiti ai lavoratori, tenere conto delle capacità e delle condizioni degli stessi in rapporto alla loro salute e alla sicurezza;",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      462.5
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      487
    );
    doc.text(
      doc.splitTextToSize(
        "fornire ai lavoratori i necessari e idonei dispositivi di protezione individuale, sentito il responsabile del servizio di prevenzione e protezione e il medico competente, ove presente;",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      487
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      510
    );
    doc.text(
      doc.splitTextToSize(
        "prendere le misure appropriate affinché soltanto i lavoratori che hanno ricevuto adeguate istruzioni e specifico addestramento accedano alle zone che li espongono ad un rischio grave e specifico;",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      510
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      535
    );
    doc.text(
      doc.splitTextToSize(
        "richiedere l’osservanza da parte dei singoli lavoratori delle norme vigenti, nonché delle disposizioni aziendali in materia di sicurezza e di igiene del lavoro e di uso dei mezzi di protezione collettivi e dei dispositivi di protezione individuali messi a loro disposizione;",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      535
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      571
    );
    doc.text(
      doc.splitTextToSize(
        "richiedere al medico competente l’osservanza degli obblighi previsti a suo carico nel presente decreto;",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      571
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      593
    );
    doc.text(
      doc.splitTextToSize(
        "adottare le misure per il controllo delle situazioni di rischio in caso di emergenza e dare istruzioni affinché i lavoratori, in caso di pericolo grave, immediato ed inevitabile, abbandonino il posto di lavoro o la zona pericolosa;",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      593
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      629
    );
    doc.text(
      doc.splitTextToSize(
        "informare il più presto possibile i lavoratori esposti al rischio di un pericolo grave e immediato circa il rischio stesso e le disposizioni prese o da prendere in materia di protezione;",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      629
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      653
    );
    doc.text(
      doc.splitTextToSize(
        "adempiere agli obblighi di informazione, formazione e addestramento di cui agli articoli 36 e 37;",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      653
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      665
    );
    doc.text(
      doc.splitTextToSize(
        "astenersi, salvo eccezione debitamente motivata da esigenze di tutela della salute e sicurezza, dal richiedere ai lavoratori di riprendere la loro attività in una situazione di lavoro in cui persiste un pericolo grave e immediato;",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      665
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      701
    );
    doc.text(
      doc.splitTextToSize(
        "consentire ai lavoratori di verificare, mediante il rappresentante dei lavoratori per la sicurezza, l’applicazione delle misure di sicurezza e di protezione della salute;",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      701
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      725
    );
    doc.text(
      doc.splitTextToSize(
        "consegnare tempestivamente al rappresentante dei lavoratori per la sicurezza, su richiesta di questi e per l’espletamento della sua funzione, copia del documento di cui all’articolo 17, comma 1, lettera a), nonché consentire al medesimo rappresentante di accedere ai dati di cui alla lettera r);",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      725
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      761
    );
    doc.text(
      doc.splitTextToSize(
        "elaborare il documento di cui all’articolo 26, comma 3, e, su richiesta di questi e per l’espletamento della sua funzione, consegnarne tempestivamente copia ai rappresentanti dei lavoratori per la sicurezza;",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      761
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      797
    );
    doc.text(
      doc.splitTextToSize(
        "prendere appropriati provvedimenti per evitare che le misure tecniche adottate possano causare rischi per la salute della popolazione o deteriorare l'ambiente esterno verificando periodicamente la perdurante assenza di rischio;",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      797
    );
    doc.addPage(); //nuoa pagina
    doc.setDrawColor(17, 82, 111);
    doc.setLineWidth(1);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(32, 8, 130, 77, 8, 8); // Black sqaure with rounded corners
    doc.addImage(imgDef, "PNG", 37, 13, 120, 68); //da riguardare se così o al contrario

    doc.roundedRect(172, 8, 280, 77, 8, 8);
    doc.setFontSize(10);
    doc.setFont("verdana", "bold");
    var title1 = "NOMINA DIRIGENTE AI SENSI DELL’ART. 2,";
    var title2 = "COMMA 1, LETTERA d) DEL D.LGS 81/08 S.M.I.";
    var title3 = "Rev.: 00";
    xOffset =
      doc.internal.pageSize.width / 2 +
      15 -
      (doc.getStringUnitWidth(title1) * doc.internal.getFontSize()) / 2;
    doc.text(title1, xOffset, 45);

    xOffset =
      doc.internal.pageSize.width / 2 +
      15 -
      (doc.getStringUnitWidth(title2) * doc.internal.getFontSize()) / 2;
    doc.text(title2, xOffset, 59);
    xOffset =
      500 +
      15 -
      (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2;
    doc.text(title3, xOffset, 45);
    doc.roundedRect(462, 8, 100, 77, 8, 8);
    xOffset =
      500 +
      15 -
      (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2;
    doc.text(dataCorrente, xOffset, 59);

    doc.setFont("verdana", "normal");

    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      98
    );
    doc.text(
      doc.splitTextToSize(
        "comunicare all’INAIL, o all’IPSEMA, in relazione alle rispettive competenze, a fini statistici e informativi, i dati relativi agli infortuni sul lavoro che comportino un’assenza dal lavoro di almeno un giorno, escluso quello dell’evento e, a fini assicurativi, le informazioni relative agli infortuni sul lavoro che comportino un’assenza dal lavoro superiore a tre giorni;",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      98
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      146
    );
    doc.text(
      doc.splitTextToSize(
        "consultare il rappresentante dei lavoratori per la sicurezza nelle ipotesi di cui all’articolo 50;",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      146
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      158
    );
    doc.text(
      doc.splitTextToSize(
        "adottare le misure necessarie ai fini della prevenzione incendi e dell’evacuazione dei luoghi di lavoro, nonché per il caso di pericolo grave e immediato, secondo le disposizioni di cui all’articolo 43. Tali misure devono essere adeguate alla natura dell’attività, alle dimensioni dell’azienda o dell’unità produttiva, e al numero delle persone presenti;",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      158
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      206
    );
    doc.text(
      doc.splitTextToSize(
        "nell’ambito dello svolgimento di attività in regime di appalto e di subappalto, munire i lavoratori di apposita tessera di riconoscimento, corredata di fotografia, contenente le generalità del lavoratore e l’indicazione del datore di lavoro;",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      206
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      242
    );
    doc.text(
      doc.splitTextToSize(
        "nelle unità produttive con più di 15 lavoratori, convocare la riunione periodica di cui all’articolo 35;",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      242
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      254
    );
    doc.text(
      doc.splitTextToSize(
        "aggiornare le misure di prevenzione in relazione ai mutamenti organizzativi e produttivi che hanno rilevanza ai fini della salute e sicurezza del lavoro, o in relazione al grado di evoluzione della tecnica della prevenzione e della protezione;",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      254
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      290
    );
    doc.text(
      doc.splitTextToSize(
        "comunicare annualmente all’INAIL i nominativi dei rappresentanti dei lavoratori per la sicurezza;",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      290
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      302
    );
    doc.text(
      doc.splitTextToSize(
        "vigilare affinché i lavoratori per i quali vige l’obbligo di sorveglianza sanitaria non siano adibiti alla mansione lavorativa specifica senza il prescritto giudizio di idoneità.",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      302
    );

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        "2. Dovrà inoltre fornire al servizio di prevenzione e protezione ed al medico competente informazioni in merito a:",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      335
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      359
    );
    doc.text(
      doc.splitTextToSize(
        "la natura dei rischi;",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      359
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      371
    );
    doc.text(
      doc.splitTextToSize(
        "l’organizzazione del lavoro, la programmazione e l’attuazione delle misure preventive e protettive;",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      371
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      383
    );
    doc.text(
      doc.splitTextToSize(
        "la descrizione degli impianti e dei processi produttivi;",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      383
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      395
    );
    doc.text(
      doc.splitTextToSize(
        "i dati di cui al comma 1, lettera r), e quelli relativi alle malattie professionali;",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      395
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      407
    );
    doc.text(
      doc.splitTextToSize(
        "i provvedimenti adottati dagli organi di vigilanza.",
        doc.internal.pageSize.getWidth() - 80
      ),
      63,
      407
    );

    doc.text(
      doc.splitTextToSize(
        "Le rammentiamo che il mancato rispetto da parte Sua degli obblighi di cui all’art. 18 sopra riportato comporterà l’applicazione delle sanzioni previste dall’art. 55 del D.Lgs 81/2008.",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      430
    );
    doc.text(
      doc.splitTextToSize(
        "Nel ricordarLe inoltre che Ella è tenuto al segreto in ordine ai processi lavorativi di cui venga a conoscenza nell’esercizio delle sue funzioni, La preghiamo di volerci restituire la presente quale consapevole accettazione degli obblighi sopra riportati.",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      465
    );
    doc.text(
      doc.splitTextToSize(
        "Distinti saluti",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      515
    );
    doc.text(
      doc.splitTextToSize(
        "Il Datore di lavoro",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      540
    );
    doc.text(
      doc.splitTextToSize(
        "_________________________________",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      565
    );

    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        "Data " + `${moment(new Date()).format("DD/MM/YYYY")}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      615
    );

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Il sottoscritto ${cap(
          dirigente?.Nominativo?.split(" ")
        )} nel ricevere in data ${moment(new Date()).format(
          "DD/MM/YYYY"
        )} l'originale della presente nomina, prende atto di quanto, in essa contenuto e dichiara di ricoprire il ruolo di dirigente, nel contesto dell’azienda ${ragioneSociale} presso il sito di ${
          infoSede?.Descrizione
        }`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      635
    );

    doc.text(
      doc.splitTextToSize(
        "Il Dirigente",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      685
    );
    doc.text(
      doc.splitTextToSize(
        "_________________________________",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      710
    );

    doc.output("pdfobjectnewwindow", "attestato.pdf");
  };
  const exportPDFPreposto = (
    logo,
    dati,
    ragioneSociale,
    sedeLoc,
    sedeInd,
    sedeCap,
    bloccoSede
  ) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size, true);
    const imgLogo = new Image();
    var imgDef = logo;
    var dataCorrente = new Date();
    dataCorrente = moment(dataCorrente).format("DD/MM/YYYY");

    //AGGIUNGO I FONT CHE HO IMPORTATO
    doc.addFileToVFS("verdana-normal.ttf", fontVerdanaNormal);
    doc.addFileToVFS("verdana-bold.ttf", fontVerdanaBold);
    doc.addFont("verdana-normal.ttf", "verdana", "normal");
    doc.addFont("verdana-bold.ttf", "verdana", "bold");

    setOpenPDF(true);

    doc.setTextColor(50, 62, 79);

    doc.setDrawColor(17, 82, 111);
    doc.setLineWidth(1);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(32, 8, 130, 77, 8, 8); // Black sqaure with rounded corners
    doc.addImage(imgDef, "PNG", 37, 13, 120, 68); //da riguardare se così o al contrario

    doc.roundedRect(172, 8, 280, 77, 8, 8);
    doc.setFontSize(9);
    doc.setFont("verdana", "bold");
    var title1 = "COMUNICAZIONE INCARICO PREPOSTO AI SENSI";
    var title2 = "DELL’ART. 2, COMMA 1, LETTERA e DEL D.LGS";
    var title4 = "81/08 S.M.I.";
    var title3 = "Rev.: 00";
    xOffset =
      doc.internal.pageSize.width / 2 +
      15 -
      (doc.getStringUnitWidth(title1) * doc.internal.getFontSize()) / 2;
    doc.text(title1, xOffset, 40);

    xOffset =
      doc.internal.pageSize.width / 2 +
      15 -
      (doc.getStringUnitWidth(title2) * doc.internal.getFontSize()) / 2;
    doc.text(title2, xOffset, 52);

    xOffset =
      doc.internal.pageSize.width / 2 +
      15 -
      (doc.getStringUnitWidth(title4) * doc.internal.getFontSize()) / 2;
    doc.text(title4, xOffset, 64);

    doc.setFontSize(10);
    xOffset =
      500 +
      15 -
      (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2;
    doc.text(title3, xOffset, 45);
    doc.roundedRect(462, 8, 100, 77, 8, 8);
    xOffset =
      500 +
      15 -
      (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2;
    doc.text(dataCorrente, xOffset, 59);

    /* INIZIO TESTO */
    doc.setFontSize(10);
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        "Premesso che:",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      105
    );
    doc.text(
      doc.splitTextToSize(
        "L’art. 1 lett. e) del D.Lgs 81/08 definisce il “Preposto” come la “persona che, in ragione delle competenze personali e ei limiti dei poteri gerarchici e funzionali adeguati alla natura dell’incarico conferitogli, sovraintende all’attività lavorativa e garantisce l’attuazione delle direttive ricevute, controllandone la corretta esecuzione da parte dei lavoratori ed esercitando un funzionale potere di iniziativa”.",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      115
    );

    doc.text(
      doc.splitTextToSize(
        "In riferimento alle attività specifiche previste dalla sua mansione, fermi restando i Suoi attuali compiti e funzioni, nonché l’attuale orario di lavoro da Lei eseguito, Le ricordiamo che in virtù delle Sue competenze professionali e nei limiti dei poteri gerarchici e funzionali correlati al suo ruolo, sovraintendendo di fatto all’operato di più lavoratori, ",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      175
    );

    /* TESTO CENTRATO */
    doc.setFontSize(10);
    doc.setFont("verdana", "bold");
    var text =
        "viene ad essere individuato dalla normativa vigente come PREPOSTO",
      xOffset =
        doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / 2;
    doc.text(text, xOffset, 225);

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        "Di seguito le riportiamo gli obblighi che l’art. 19 del D.Lgs. 81/08 pone a carico del Preposto",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      240
    );

    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        "Articolo 19 - D.lgs 81/08 - Obblighi del preposto",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      252
    );

    doc.setFontSize(10);
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize("a)", doc.internal.pageSize.getWidth() - 80),
      33,
      264
    );
    doc.text(
      doc.splitTextToSize(
        "sovrintendere e vigilare sulla osservanza da parte dei singoli lavoratori dei loro obblighi di legge, nonché delle disposizioni aziendali in materia di salute e sicurezza sul lavoro e di uso dei mezzi di protezione collettivi e dei dispositivi di protezione individuale messi a loro disposizione e, in caso di rilevazione di non conformità comportamentali in ordine alle disposizioni e istruzioni impartite dal datore di lavoro e dirigenti ai fini della protezione collettiva e individuale, intervenire per modificare il comportamento non conforme fornendo le necessarie indicazioni di sicurezza. In caso di mancata attuazione delle disposizioni impartite o di persistenza della inosservanza, interrompere l'attività del lavoratore e informare i superiori diretti;",
        doc.internal.pageSize.getWidth() - 80
      ),
      48,
      264
    );
    doc.text(
      doc.splitTextToSize("b)", doc.internal.pageSize.getWidth() - 80),
      33,
      360
    );
    doc.text(
      doc.splitTextToSize(
        "verificare affinché soltanto i lavoratori che hanno ricevuto adeguate istruzioni accedano alle zone che li espongono ad un rischio grave e specifico;",
        doc.internal.pageSize.getWidth() - 80
      ),
      48,
      360
    );
    doc.text(
      doc.splitTextToSize("c)", doc.internal.pageSize.getWidth() - 80),
      33,
      384
    );
    doc.text(
      doc.splitTextToSize(
        "richiedere l’osservanza delle misure per il controllo delle situazioni di rischio in caso di emergenza e dare istruzioni affinché i lavoratori, in caso di pericolo grave, immediato e inevitabile, abbandonino il posto di lavoro o la zona pericolosa;",
        doc.internal.pageSize.getWidth() - 80
      ),
      48,
      384
    );
    doc.text(
      doc.splitTextToSize("d)", doc.internal.pageSize.getWidth() - 80),
      33,
      420
    );
    doc.text(
      doc.splitTextToSize(
        "informare il più presto possibile i lavoratori esposti al rischio di un pericolo grave e immediato circa il rischio stesso e le disposizioni prese o da prendere in materia di protezione;",
        doc.internal.pageSize.getWidth() - 80
      ),
      48,
      420
    );
    doc.text(
      doc.splitTextToSize("e)", doc.internal.pageSize.getWidth() - 80),
      33,
      444
    );
    doc.text(
      doc.splitTextToSize(
        "astenersi, salvo eccezioni debitamente motivate, dal richiedere ai lavoratori di riprendere la loro attività in una situazione di lavoro in cui persiste un pericolo grave ed immediato;",
        doc.internal.pageSize.getWidth() - 80
      ),
      48,
      444
    );
    doc.text(
      doc.splitTextToSize("f)", doc.internal.pageSize.getWidth() - 80),
      33,
      468
    );
    doc.text(
      doc.splitTextToSize(
        "segnalare tempestivamente al datore di lavoro o al dirigente sia le deficienze dei mezzi e delle attrezzature di lavoro e dei dispositivi di protezione individuale, sia ogni altra condizione di pericolo che si verifichi durante il lavoro, delle quali venga a conoscenza sulla base della formazione ricevuta; f-bis) in caso di rilevazione di deficienze dei mezzi e delle attrezzature di lavoro e di ogni condizione di pericolo rilevata durante la vigilanza, se necessario, interrompere temporaneamente l'attività e, comunque, segnalare tempestivamente al datore di lavoro e al dirigente le non conformità rilevate;",
        doc.internal.pageSize.getWidth() - 80
      ),
      48,
      468
    );

    doc.text(
      doc.splitTextToSize("g)", doc.internal.pageSize.getWidth() - 80),
      33,
      540
    );
    doc.text(
      doc.splitTextToSize(
        "frequentare appositi corsi di formazione",
        doc.internal.pageSize.getWidth() - 80
      ),
      48,
      540
    );
    doc.text(
      doc.splitTextToSize(
        "Le rammentiamo che il mancato rispetto da parte Sua degli obblighi di cui all’art. 19 sopra riportato comporterà l’applicazione delle sanzioni previste dall’art. 56 del D.Lgs 81/2008.",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      562
    );
    doc.text(
      doc.splitTextToSize(
        "Voglia cortesemente restituire copia della presente lettera firmata per accettazione.",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      593
    );

    doc.setFontSize(11);
    doc.text(
      doc.splitTextToSize(
        "Distinti saluti",
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      620
    );
    doc.text(
      doc.splitTextToSize(
        "Il Datore di lavoro",
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      635
    );
    doc.text(
      doc.splitTextToSize(
        "_____________________",
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      660
    );
    doc.setFontSize(10);
    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        `Data ${dataCorrente}`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      685
    );

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Il sottoscritto ${cap(
          dati?.Nominativo?.split(" ")
        )} nel ricevere in data ${dataCorrente} l'originale della presente designazione, prende atto di quanto,  in essa contenuto e dichiara di essere consapevole di ricoprire l'incarico di preposto, nel contesto dell’azienda ${ragioneSociale} presso il sito di ${bloccoSede}.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      700
    );

    doc.text(
      doc.splitTextToSize(`Il preposto`, doc.internal.pageSize.getWidth() - 60),
      33,
      760
    );
    doc.text(
      doc.splitTextToSize(
        `____________________`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      785
    );

    doc.output("pdfobjectnewwindow", "attestato.pdf");
  };
  const exportPDFPrimoSoccorso = (
    logo,
    nominativo,
    ragioneSociale,
    sedeLoc,
    sedeInd,
    sedeCap,
    bloccoSede
  ) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size, true);
    const imgLogo = new Image();
    var imgDef = logo;
    var dataCorrente = new Date();
    dataCorrente = moment(dataCorrente).format("DD/MM/YYYY");

    //AGGIUNGO I FONT CHE HO IMPORTATO
    doc.addFileToVFS("verdana-normal.ttf", fontVerdanaNormal);
    doc.addFileToVFS("verdana-bold.ttf", fontVerdanaBold);
    doc.addFont("verdana-normal.ttf", "verdana", "normal");
    doc.addFont("verdana-bold.ttf", "verdana", "bold");

    setOpenPDF(true);

    doc.setTextColor(50, 62, 79);

    doc.setDrawColor(17, 82, 111);
    doc.setLineWidth(1);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(32, 8, 130, 77, 8, 8); // Black sqaure with rounded corners
    doc.addImage(imgDef, "PNG", 37, 13, 120, 68); //da riguardare se così o al contrario

    doc.roundedRect(172, 8, 280, 77, 8, 8);
    doc.setFontSize(9);
    doc.setFont("verdana", "bold");
    var title1 = "DESIGNAZIONE ai sensi dell'art. 18 comma 1";
    var title2 = "lettera b) del d.lgs. 81/08 a INCARICATO NELLA";
    var title4 = "SQUADRA PRIMO SOCCORSO";
    var title3 = "Rev.: 00";
    xOffset =
      doc.internal.pageSize.width / 2 +
      15 -
      (doc.getStringUnitWidth(title1) * doc.internal.getFontSize()) / 2;
    doc.text(title1, xOffset, 40);

    xOffset =
      doc.internal.pageSize.width / 2 +
      15 -
      (doc.getStringUnitWidth(title2) * doc.internal.getFontSize()) / 2;
    doc.text(title2, xOffset, 52);

    xOffset =
      doc.internal.pageSize.width / 2 +
      15 -
      (doc.getStringUnitWidth(title4) * doc.internal.getFontSize()) / 2;
    doc.text(title4, xOffset, 64);

    doc.setFontSize(10);
    xOffset =
      500 +
      15 -
      (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2;
    doc.text(title3, xOffset, 45);
    doc.roundedRect(462, 8, 100, 77, 8, 8);
    xOffset =
      500 +
      15 -
      (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2;
    doc.text(dataCorrente, xOffset, 59);

    /* INIZIO TESTO */
    doc.setFontSize(10);
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        "Premesso che:",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      105
    );
    doc.text(
      doc.splitTextToSize(
        "L’art. 18, comma 1) lett. b) del D.Lgs 81/08 prevede che il Datore di lavoro debba “designare preventivamente i lavoratori incaricati dell'attuazione delle misure di prevenzione incendi e lotta antincendio, di evacuazione dei luoghi di lavoro in caso di pericolo grave e immediato, di salvataggio, di primo soccorso e, comunque, di gestione dell'emergenza”.",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      117
    );
    doc.setFontSize(10);
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        "Con la presente Le comunico che, in base all’art. 18, c. 1, lett. b) e art. 43 comma 1 lett. b) del D. L.gs 9 aprile 2008, n° 81, è stato designato, previa consultazione con il Rappresentante dei Lavoratori per la Sicurezza, quale:",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      175
      /*210 */
    );

    /* TESTO CENTRATO */
    doc.setFont("verdana", "bold");
    var text =
        "Lavoratore incaricato dell’attuazione delle misure di primo soccorso",
      xOffset =
        doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / 2;
    doc.text(text, xOffset, 216);

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Tale attività sarà prestata presso il sito di ${sedeInd}, ${sedeCap} ${sedeLoc}, conformemente alla Sua formazione e alle disposizioni e istruzioni impartite dalla dirigenza.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      232
    );

    doc.text(
      doc.splitTextToSize(
        "Si evidenzia che a norma dell’articolo 43 comma 3, Lei non può, se non per giustificato motivo, rifiutare la designazione (in tal caso la motivazione dovrà essere comunicata per iscritto) e che l’incarico avrà durata fino a revoca.",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      260
    );

    doc.text(
      doc.splitTextToSize(
        "Si evidenzia inoltre che per Lei è prevista, ai sensi dell’art. 37 sempre del suddetto Decreto, una adeguata e specifica formazione ed un aggiornamento periodico.",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      296
    );

    doc.text(
      doc.splitTextToSize(
        "In qualità di Incaricato al primo soccorso aziendale, dovrà:",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      330
    );

    doc.text(
      doc.splitTextToSize(
        "\u2022    " +
          "Collaborare con gli altri componenti della squadra di primo soccorso durante il servizio di lavoro;",
        doc.internal.pageSize.getWidth() - 80
      ),
      48,
      342
    );
    doc.text(
      doc.splitTextToSize(
        "\u2022    " +
          "Conoscere l’ubicazione delle cassette di primo soccorso",
        doc.internal.pageSize.getWidth() - 80
      ),
      48,
      354
    );
    doc.text(
      doc.splitTextToSize(
        "\u2022    " +
          "Attivarsi per la chiamata dei soccorsi esterni in caso di necessità",
        doc.internal.pageSize.getWidth() - 80
      ),
      48,
      366
    );

    doc.text(
      doc.splitTextToSize(
        "Voglia cortesemente restituire copia della presente lettera firmata per accettazione.",
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      382
    );
    doc.text(
      doc.splitTextToSize(
        "Distinti saluti",
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      415
    );
    doc.text(
      doc.splitTextToSize(
        "Il Datore di lavoro",
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      445
    );
    doc.text(
      doc.splitTextToSize(
        "_____________________",
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      475
    );

    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        `Data ${dataCorrente}`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      530
    );

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Il/La sottoscritto/a ${cap(
          nominativo?.split(" ")
        )} nel ricevere in data ${dataCorrente} l'originale della presente nomina, prende atto di quanto, in essa contenuto e dichiara di accettare l'incarico di addetto incaricato all’attuazione delle misure di primo soccorso, nel contesto dell’azienda ${ragioneSociale} presso il sito di ${bloccoSede}.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      545
    );

    doc.text(
      doc.splitTextToSize(`L’addetto`, doc.internal.pageSize.getWidth() - 60),
      33,
      610
    );
    doc.text(
      doc.splitTextToSize(
        `____________________`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      633
    );

    doc.text(
      doc.splitTextToSize(
        `Per consultazione il Rappresentante`,
        doc.internal.pageSize.getWidth() - 60
      ),

      doc.internal.pageSize.getWidth() - //prendo la grandezza totale della riga e sottraggo
        doc.getStringUnitWidth(`Per consultazione il Rappresentante`) * //la grandezza della frase
          doc.internal.getFontSize() - //per la grandezza del font
        40, //mettto del margine
      665
    );

    doc.text(
      doc.splitTextToSize(
        `dei lavoratori per la sicurezza`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`dei lavoratori per la sicurezza`) *
          doc.internal.getFontSize() -
        40,
      677
    );

    doc.text(
      doc.splitTextToSize(
        `_____________________________`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`_____________________________`) *
          doc.internal.getFontSize() -
        40,
      700
    );

    doc.output("pdfobjectnewwindow", "attestato.pdf");
  };
  const exportPDFDatoreLavoroPrimoSoccorso = (
    logo,
    nominativo,
    ragioneSociale,
    sedeLoc,
    sedeInd,
    sedeCap,
    bloccoSede
  ) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size, true);
    const imgLogo = new Image();
    var imgDef = logo;
    var dataCorrente = new Date();
    dataCorrente = moment(dataCorrente).format("DD/MM/YYYY");

    //AGGIUNGO I FONT CHE HO IMPORTATO
    doc.addFileToVFS("verdana-normal.ttf", fontVerdanaNormal);
    doc.addFileToVFS("verdana-bold.ttf", fontVerdanaBold);
    doc.addFont("verdana-normal.ttf", "verdana", "normal");
    doc.addFont("verdana-bold.ttf", "verdana", "bold");

    var col = [
      `${imgLogo}`,
      "Budgeted Operating Expenses ",
      "Exclude",
      "Expenses",
    ];
    var rows = [];
    setOpenPDF(true);

    doc.setTextColor(50, 62, 79);

    doc.setDrawColor(17, 82, 111);
    doc.setLineWidth(1);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(32, 8, 130, 77, 8, 8); // Black sqaure with rounded corners
    doc.addImage(imgDef, "PNG", 37, 13, 120, 68); //da riguardare se così o al contrario

    doc.roundedRect(172, 8, 280, 77, 8, 8);
    doc.setFontSize(9);
    doc.setFont("verdana", "bold");
    var title1 = "DESIGNAZIONE ai sensi dell'art. 18 comma 1";
    var title2 = "lettera b) del d.lgs. 81/08 a INCARICATO NELLA";
    var title4 = "SQUADRA PRIMO SOCCORSO";
    var title3 = "Rev.: 00";

    doc.text(
      title1,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title1) * doc.internal.getFontSize()) / 2,
      40
    );

    doc.text(
      title2,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title2) * doc.internal.getFontSize()) / 2,
      52
    );

    doc.text(
      title4,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title4) * doc.internal.getFontSize()) / 2,
      64
    );

    doc.setFontSize(10);

    doc.text(
      title3,
      500 +
        15 -
        (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2,
      45
    );
    doc.roundedRect(462, 8, 100, 77, 8, 8);

    doc.text(
      dataCorrente,
      500 +
        15 -
        (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2,
      59
    );

    /* INIZIO TESTO */
    doc.setFontSize(10);
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        "Premesso che:",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      105
    );
    doc.text(
      doc.splitTextToSize(
        "L’art. 18, comma 1) lett. b) del D.Lgs 81/08 prevede che il Datore di lavoro debba “designare preventivamente i lavoratori incaricati dell'attuazione delle misure di prevenzione incendi e lotta antincendio, di evacuazione dei luoghi di lavoro in caso di pericolo grave e immediato, di salvataggio, di primo soccorso e, comunque, di gestione dell'emergenza”.",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      117
    );
    doc.text(
      doc.splitTextToSize(
        "L’art. 34 comma 1 prevede che “ Salvo che nei casi di cui all'articolo 31, comma 6, il datore di lavoro può svolgere direttamente i compiti propri del servizio di prevenzione e protezione dai rischi, di primo soccorso, nonché di prevenzione incendi e di evacuazione, nelle ipotesi previste nell'allegato 2 dandone preventiva informazione al rappresentante dei lavoratori per la sicurezza ed alle condizioni di cui ai commi successivi”.",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      165
    );

    /* TESTO CENTRATO 
    doc.setFont("verdana", "bold");
    var text =
        "Lavoratore incaricato dell’attuazione delle misure di primo soccorso",
      xOffset =
        doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / 2;
    doc.text(text, xOffset, 216);*/

    doc.text(
      doc.splitTextToSize(
        `Il/La sottoscritto/a ${cap(
          nominativo?.split(" ")
        )}, in qualità di Datore di Lavoro dell'azienda ${ragioneSociale}, dopo aver:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      232
    );

    doc.text(
      doc.splitTextToSize(
        "\u2022    " +
          "verificato di non rientrare nella casistica citata all’Allegato 2 del D.Lgs.81/2008",
        doc.internal.pageSize.getWidth() - 80
      ),
      48,
      260
    );
    doc.text(
      doc.splitTextToSize(
        "\u2022    " +
          "verificato di essere esclusa dalla casistica dai casi citati all’art. 31 comma 6 del D.Lgs 81/08",
        doc.internal.pageSize.getWidth() - 80
      ),
      48,
      272
    );

    doc.setFont("verdana", "bold");

    doc.text(
      "DICHIARA",
      doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth("DICHIARA") * doc.internal.getFontSize()) / 2,
      290
    );

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `di assumere in prima persona l’incarico di addetto all’attuazione delle misure di primo soccorso presso l’azienda ${ragioneSociale}, in relazione a quanto previsto dall’art. 34 del D.Lgs 81/08;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      310
    );

    doc.setFont("verdana", "bold");

    doc.text(
      "DICHIARA INOLTRE",
      doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth("DICHIARA INOLTRE") *
          doc.internal.getFontSize()) /
          2,
      340
    );
    doc.setFont("verdana", "normal");
    doc.text(doc.splitTextToSize("\u2022"), 48, 360);
    doc.text(
      doc.splitTextToSize(
        "di aver frequentato e di essere in possesso del relativo attestato di partecipazione, di apposito corso di formazione secondo quanto previsto dall’art 34 comma 2-bis del D.Lgs 81/08",
        doc.internal.pageSize.getWidth() - 80
      ),
      58,
      360
    );

    doc.text(
      doc.splitTextToSize("In fede", doc.internal.pageSize.getWidth() - 60),
      33,
      415
    );
    doc.text(
      doc.splitTextToSize(
        "Il Datore di lavoro",
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      445
    );
    doc.text(
      doc.splitTextToSize(
        "_____________________",
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      475
    );

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Il/La sottoscritto/a ${cap(
          RLSSel?.split(" ")
        )}, in qualità di RLS della società ${ragioneSociale}, dichiara di essere stato preventivamente informato dal Datore di Lavoro in merito alla volontà di ricoprire in prima persona il ruolo di incaricato all’attuazione delle misure di primo soccorso all’interno del sito di ${bloccoSede}.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      520
    );

    doc.text(
      doc.splitTextToSize(
        `Il Rappresentante dei lavoratori`,
        doc.internal.pageSize.getWidth() - 60
      ),

      doc.internal.pageSize.getWidth() - //prendo la grandezza totale della riga e sottraggo
        doc.getStringUnitWidth(`Il Rappresentante dei lavoratori`) * //la grandezza della frase
          doc.internal.getFontSize() - //per la grandezza del font
        40, //mettto del margine
      615
    );

    doc.text(
      doc.splitTextToSize(
        `Per la sicurezza`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`Per la sicurezza`) *
          doc.internal.getFontSize() -
        40,
      627
    );

    doc.text(
      doc.splitTextToSize(
        `________________________`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`________________________`) *
          doc.internal.getFontSize() -
        40,
      650
    );

    doc.output("pdfobjectnewwindow", "attestato.pdf");
  };
  const exportPDFRSPP = (
    logo,
    dati,
    ragioneSociale,
    sedeLoc,
    sedeInd,
    sedeCap,
    bloccoSede
  ) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size, true);
    const imgLogo = new Image();
    var imgDef = logo;

    var dataCorrente = new Date();
    dataCorrente = moment(dataCorrente).format("DD/MM/YYYY");

    //AGGIUNGO I FONT CHE HO IMPORTATO
    doc.addFileToVFS("verdana-normal.ttf", fontVerdanaNormal);
    doc.addFileToVFS("verdana-bold.ttf", fontVerdanaBold);
    doc.addFont("verdana-normal.ttf", "verdana", "normal");
    doc.addFont("verdana-bold.ttf", "verdana", "bold");

    setOpenPDF(true);

    doc.setTextColor(50, 62, 79);

    doc.setDrawColor(17, 82, 111);
    doc.setLineWidth(1);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(32, 8, 130, 77, 8, 8); // Black sqaure with rounded corners
    doc.addImage(imgDef, "PNG", 37, 13, 120, 68); //da riguardare se così o al contrario

    doc.roundedRect(172, 8, 280, 77, 8, 8);
    doc.setFontSize(9);
    doc.setFont("verdana", "bold");
    var title1 = "DESIGNAZIONE ai sensi del Titolo I articolo 17";
    var title2 = "comma 1 lettera b) del D. Lgs. 81/08 a";
    var title4 = "RESPONSABILE DEL SERVIZIO DI PREVENZIONE E";
    var title5 = "PROTEZIONE";
    var title3 = "Rev.: 00";

    doc.text(
      title1,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title1) * doc.internal.getFontSize()) / 2,
      30
    );

    doc.text(
      title2,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title2) * doc.internal.getFontSize()) / 2,
      42
    );

    doc.text(
      title4,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title4) * doc.internal.getFontSize()) / 2,
      54
    );

    doc.text(
      title5,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title5) * doc.internal.getFontSize()) / 2,
      66
    );

    doc.setFontSize(10);

    doc.text(
      title3,
      500 +
        15 -
        (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2,
      45
    );
    doc.roundedRect(462, 8, 100, 77, 8, 8);

    doc.text(
      dataCorrente,
      500 +
        15 -
        (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2,
      59
    );

    /* INIZIO TESTO */
    doc.setFontSize(10);
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Premesso che:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      105
    );
    doc.text(
      doc.splitTextToSize(
        `L’art. 2 lett. f) del D.Lgs 81/08 definisce il “Responsabile del Servizio Prevenzione e Protezione” come la “persona in possesso delle capacità e dei requisiti professionali di cui all'articolo 32 designata dal datore di lavoro, a cui risponde, per coordinare il servizio di prevenzione e protezione dai rischi”.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      117
    );
    let capRSPP = cap(dati?.dl?.split(" "));
    doc.text(
      doc.splitTextToSize(
        `Il/La sottoscritto/a ${capRSPP}, in qualità di Datore di Lavoro dell'azienda ${ragioneSociale}, dopo aver verificato il possesso delle capacità e dei requisiti previsti dalla normativa vigente per lo svolgimento dell'incarico di Responsabile del Servizio di prevenzione e Protezione, ai sensi dell'art. 32 D.Lgs 81/08, ha deciso di designarla, Responsabile del Servizio di Prevenzione e Protezione, nel contesto del sito di ${sedeInd} - ${sedeCap} ${sedeLoc}.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      158
    );

    doc.text(
      doc.splitTextToSize(
        "Pertanto, in collaborazione con le altre figure di riferimento individuate nell'ambito della sicurezza del lavoro:",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      235
    );
    /* ELENCO PUNTATO */
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      259
    );
    doc.text(
      doc.splitTextToSize(
        "dovrà adoperarsi, compatibilmente con tempo e mezzi a disposizione, per svolgere appieno i compiti previsti dall'articolo 33, comma 1, D.Lgs 81/08 s.m.i, come di seguito riportato:",
        doc.internal.pageSize.getWidth() - 100
      ),
      58,
      259
    );
    doc.text(
      doc.splitTextToSize("\u25E6 ", doc.internal.pageSize.getWidth() - 80),
      58,
      283
    );
    doc.text(
      doc.splitTextToSize(
        "collaborare all'individuazione dei fattori di rischio, alla valutazione dei rischi e all'individuazione delle misure per la sicurezza e la salubrità degli ambienti di lavoro, nel rispetto della normativa vigente sulla base della specifica conoscenza dell'organizzazione aziendale;",
        doc.internal.pageSize.getWidth() - 120
      ),
      73,
      283
    );
    doc.text(
      doc.splitTextToSize("\u25E6 ", doc.internal.pageSize.getWidth() - 100),
      58,
      331
    );
    doc.text(
      doc.splitTextToSize(
        "elaborare, per quanto di competenza, le misure preventive e protettive, per le varie attività aziendali;",
        doc.internal.pageSize.getWidth() - 120
      ),
      73,
      331
    );
    doc.text(
      doc.splitTextToSize("\u25E6 ", doc.internal.pageSize.getWidth() - 100),
      58,
      355
    );
    doc.text(
      doc.splitTextToSize(
        "proporre i programmi di informazione e formazione dei lavoratori;",
        doc.internal.pageSize.getWidth() - 120
      ),
      73,
      355
    );
    doc.text(
      doc.splitTextToSize("\u25E6 ", doc.internal.pageSize.getWidth() - 100),
      58,
      367
    );
    doc.text(
      doc.splitTextToSize(
        "partecipare alle consultazioni in materia di tutela della salute e sicurezza sul lavoro, nonché alla riunione periodica;",
        doc.internal.pageSize.getWidth() - 120
      ),
      73,
      367
    );
    doc.text(
      doc.splitTextToSize("\u25E6 ", doc.internal.pageSize.getWidth() - 100),
      58,
      391
    );
    doc.text(
      doc.splitTextToSize(
        "fornire ai lavoratori le informazioni di cui all'art. 36 del D.Lgs 81/08.",
        doc.internal.pageSize.getWidth() - 120
      ),
      73,
      391
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      413
    );
    doc.text(
      doc.splitTextToSize(
        "sarà tenuto al segreto in ordine ai processi lavorativi di cui verrà a conoscenza nell'esercizio delle funzioni che le sono state assegnate.",
        doc.internal.pageSize.getWidth() - 100
      ),
      58,
      413
    );
    doc.text(
      doc.splitTextToSize(
        `Per il conseguimento dei medesimi fini, il sottoscritto ${capRSPP}, in qualità di Datore di Lavoro dell'azienda ${ragioneSociale} fornirà, secondo quanto previsto dal D.Lgs 81/08, art. 18, comma 3, tutte le informazioni in merito a:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      440
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      476
    );
    doc.text(
      doc.splitTextToSize(
        "la natura dei rischi;",
        doc.internal.pageSize.getWidth() - 80
      ),
      58,
      476
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      488
    );
    doc.text(
      doc.splitTextToSize(
        "l'organizzazione del lavoro, la programmazione e l'attuazione delle misure preventive e protettive;",
        doc.internal.pageSize.getWidth() - 80
      ),
      58,
      488
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      500
    );
    doc.text(
      doc.splitTextToSize(
        "la descrizione degli impianti e dei processi produttivi;",
        doc.internal.pageSize.getWidth() - 80
      ),
      58,
      500
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      512
    );
    doc.text(
      doc.splitTextToSize(
        "i dati relativi ad infortuni e malattie professionali;",
        doc.internal.pageSize.getWidth() - 80
      ),
      58,
      512
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      524
    );
    doc.text(
      doc.splitTextToSize(
        "i provvedimenti adottati dagli organi di vigilanza;",
        doc.internal.pageSize.getWidth() - 80
      ),
      58,
      524
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      536
    );
    doc.text(
      doc.splitTextToSize(
        "collaborerà con la Sua opera e le Sue funzioni in materia di prevenzione degli infortuni ed igiene del lavoro.",
        doc.internal.pageSize.getWidth() - 80
      ),
      58,
      536
    );

    doc.text(
      doc.splitTextToSize(
        "Distinti saluti",
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      575
    );
    doc.text(
      doc.splitTextToSize(
        "Il Datore di lavoro",
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      600
    );
    doc.text(
      doc.splitTextToSize(
        "_____________________",
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      620
    );

    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        `Data ${dataCorrente}`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      655
    );

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Il/La sottoscritto/a ${cap(
          dati?.rspp?.split(" ")
        )} nel ricevere l'originale della presente designazione, prende atto di quanto in essa contenuto e dichiara di accettare l'incarico di Responsabile del Servizio di Prevenzione e Protezione, nel contesto del sito di ${sedeInd} - ${sedeCap} ${sedeLoc}.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      675
    );

    doc.text(
      doc.splitTextToSize(`Il RSPP`, doc.internal.pageSize.getWidth() - 60),
      33,
      737
    );
    doc.text(
      doc.splitTextToSize(
        `____________________`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      757
    );
    doc.text(
      doc.splitTextToSize(
        `Per consultazione`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`Per consultazione`) *
          doc.internal.getFontSize() -
        40,
      777
    );
    doc.text(
      doc.splitTextToSize(`Il RLS`, doc.internal.pageSize.getWidth() - 60),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`Il RLS`) * doc.internal.getFontSize() -
        40,
      789
    );
    doc.text(
      doc.splitTextToSize(
        `____________________`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`____________________`) *
          doc.internal.getFontSize() -
        40,
      810
    );

    doc.output("pdfobjectnewwindow", "attestato.pdf");
  };
  const exportPDFDatoreLavoroRSPP = (
    logo,
    dati,
    ragioneSociale,
    sedeLoc,
    sedeInd,
    sedeCap,
    bloccoSede
  ) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size, true);
    const imgLogo = new Image();
    var imgDef = logo;

    var dataCorrente = new Date();
    dataCorrente = moment(dataCorrente).format("DD/MM/YYYY");

    //AGGIUNGO I FONT CHE HO IMPORTATO
    doc.addFileToVFS("verdana-normal.ttf", fontVerdanaNormal);
    doc.addFileToVFS("verdana-bold.ttf", fontVerdanaBold);
    doc.addFont("verdana-normal.ttf", "verdana", "normal");
    doc.addFont("verdana-bold.ttf", "verdana", "bold");

    setOpenPDF(true);

    doc.setTextColor(50, 62, 79);

    doc.setDrawColor(17, 82, 111);
    doc.setLineWidth(1);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(32, 8, 130, 77, 8, 8); // Black sqaure with rounded corners
    doc.addImage(imgDef, "PNG", 37, 13, 120, 68); //da riguardare se così o al contrario

    doc.roundedRect(172, 8, 280, 77, 8, 8);
    doc.setFontSize(9);
    doc.setFont("verdana", "bold");
    var title1 = "DESIGNAZIONE ai sensi del Titolo I articolo 34";
    var title2 = "comma 1 del D. Lgs. 81/08 a RESPONSABILE DEL";
    var title4 = "SERVIZIO DI PREVENZIONE E PROTEZIONE";
    var title3 = "Rev.: 00";

    doc.text(
      title1,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title1) * doc.internal.getFontSize()) / 2,
      39
    );

    doc.text(
      title2,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title2) * doc.internal.getFontSize()) / 2,
      51
    );

    doc.text(
      title4,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title4) * doc.internal.getFontSize()) / 2,
      63
    );

    doc.setFontSize(10);

    doc.text(
      title3,
      500 +
        15 -
        (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2,
      45
    );
    doc.roundedRect(462, 8, 100, 77, 8, 8);

    doc.text(
      dataCorrente,
      500 +
        15 -
        (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2,
      59
    );

    /* INIZIO TESTO */
    doc.setFontSize(10);
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Premesso che:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      105
    );
    doc.text(
      doc.splitTextToSize(
        `L’art. 2 lett. f) del D.Lgs 81/08 definisce il “Responsabile del Servizio Prevenzione e Protezione” come la “persona in possesso delle capacità e dei requisiti professionali di cui all'articolo 32 designata dal datore di lavoro, a cui risponde, per coordinare il servizio di prevenzione e protezione dai rischi”.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      117
    );
    doc.text(
      doc.splitTextToSize(
        `L’art. 34 comma 1 prevede che “ Salvo che nei casi di cui all'articolo 31, comma 6, il datore di lavoro può svolgere direttamente i compiti propri del servizio di prevenzione e protezione dai rischi, di primo soccorso, nonché di prevenzione incendi e di evacuazione, nelle ipotesi previste nell'allegato 2 dandone preventiva informazione al rappresentante dei lavoratori per la sicurezza ed alle condizioni di cui ai commi successivi”.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      153
    );
    doc.text(
      doc.splitTextToSize(
        `Il/La sottoscritto/a ${cap(
          dati?.dl?.split(" ")
        )}, in qualità di Datore di Lavoro dell'azienda ${ragioneSociale}, dopo aver: `,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      223
    );

    doc.text(
      doc.splitTextToSize("\u2022", doc.internal.pageSize.getWidth() - 80),
      43,
      250
    );
    doc.text(
      doc.splitTextToSize(
        "verificato di non rientrare nella casistica citata all’Allegato 2 del D.Lgs.81/2008",
        doc.internal.pageSize.getWidth() - 80
      ),
      58,
      250
    );
    doc.text(
      doc.splitTextToSize("\u2022", doc.internal.pageSize.getWidth() - 80),
      43,
      262
    );
    doc.text(
      doc.splitTextToSize(
        "verificato di essere esclusa dalla casistica dai casi citati all’art. 31 comma 6 del D.Lgs 81/08",
        doc.internal.pageSize.getWidth() - 80
      ),
      58,
      262
    );

    doc.setFont("verdana", "bold");

    doc.text(
      "DICHIARA",
      doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth("DICHIARA") * doc.internal.getFontSize()) / 2,
      280
    );
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `di assumere in prima persona l’incarico di responsabile del Servizio Prevenzione e Protezione presso l’azienda ${ragioneSociale}, in relazione a quanto previsto dall’art. 34 del D.Lgs 81/08;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      295
    );
    doc.setFont("verdana", "bold");
    doc.text(
      "DICHIARA INOLTRE",
      doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth("DICHIARA INOLTRE") *
          doc.internal.getFontSize()) /
          2,
      325
    );
    doc.setFont("verdana", "normal");

    doc.text(
      doc.splitTextToSize("\u2022", doc.internal.pageSize.getWidth() - 80),
      43,
      342
    );
    doc.text(
      doc.splitTextToSize(
        "sotto la sua piena responsabilità, di possedere i requisiti di idoneità necessari per lo svolgimento dei compiti di prevenzione e protezione previsti dall’art. 33 del D.Lgs. 81/2008;",
        doc.internal.pageSize.getWidth() - 80
      ),
      58,
      342
    );
    doc.text(
      doc.splitTextToSize("\u2022", doc.internal.pageSize.getWidth() - 80),
      43,
      366
    );
    doc.text(
      doc.splitTextToSize(
        "di aver frequentato e di essere in possesso del relativo attestato di partecipazione, di apposito corso di formazione secondo quanto previsto dall’art 34 comma 2 del D.Lgs 81/08",
        doc.internal.pageSize.getWidth() - 80
      ),
      58,
      366
    );

    doc.text(
      doc.splitTextToSize("In fede", doc.internal.pageSize.getWidth() - 60),
      33,
      430
    );
    doc.text(
      doc.splitTextToSize(
        "Il Datore di lavoro",
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      455
    );
    doc.text(
      doc.splitTextToSize(
        "_____________________",
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      475
    );

    /* doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        `Data ${dataCorrente}`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      655
    ); */

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Il/La sottoscritto/a ${RLSSel}, in qualità di RLS della società ${ragioneSociale}, dichiara di essere stato preventivamente informato dal Datore di Lavoro in merito alla volontà di ricoprire in prima persona il ruolo di RSPP all’interno del sito di ${bloccoSede}.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      515
    );

    doc.text(
      doc.splitTextToSize(`Il RLS`, doc.internal.pageSize.getWidth() - 60),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`Il RLS`) * doc.internal.getFontSize() -
        40,
      570
    );
    doc.text(
      doc.splitTextToSize(
        `____________________`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`____________________`) *
          doc.internal.getFontSize() -
        40,
      595
    );
    setRLSSel("___________________");
    doc.output("pdfobjectnewwindow", "attestato.pdf");
  };
  const exportPDFASPP = (
    logo,
    dati,
    ragioneSociale,
    sedeLoc,
    sedeInd,
    sedeCap,
    bloccoSede
  ) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size, true);
    const imgLogo = new Image();
    var imgDef = logo;

    var dataCorrente = new Date();
    dataCorrente = moment(dataCorrente).format("DD/MM/YYYY");

    //AGGIUNGO I FONT CHE HO IMPORTATO
    doc.addFileToVFS("verdana-normal.ttf", fontVerdanaNormal);
    doc.addFileToVFS("verdana-bold.ttf", fontVerdanaBold);
    doc.addFont("verdana-normal.ttf", "verdana", "normal");
    doc.addFont("verdana-bold.ttf", "verdana", "bold");

    setOpenPDF(true);

    doc.setTextColor(50, 62, 79);

    doc.setDrawColor(17, 82, 111);
    doc.setLineWidth(1);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(32, 8, 130, 77, 8, 8); // Black sqaure with rounded corners
    doc.addImage(imgDef, "PNG", 37, 13, 120, 68); //da riguardare se così o al contrario

    doc.roundedRect(172, 8, 280, 77, 8, 8);
    doc.setFontSize(9);
    doc.setFont("verdana", "bold");
    var title1 = "DESIGNAZIONE ai sensi del Titolo I articolo 17";
    var title2 = "comma 1 lettera b) del D. Lgs. 81/08 a";
    var title4 = "RESPONSABILE DEL SERVIZIO DI PREVENZIONE E";
    var title5 = "PROTEZIONE";
    var title3 = "Rev.: 00";

    doc.text(
      title1,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title1) * doc.internal.getFontSize()) / 2,
      30
    );

    doc.text(
      title2,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title2) * doc.internal.getFontSize()) / 2,
      42
    );

    doc.text(
      title4,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title4) * doc.internal.getFontSize()) / 2,
      54
    );

    doc.text(
      title5,
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(title5) * doc.internal.getFontSize()) / 2,
      66
    );

    doc.setFontSize(10);

    doc.text(
      title3,
      500 +
        15 -
        (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2,
      45
    );
    doc.roundedRect(462, 8, 100, 77, 8, 8);

    doc.text(
      dataCorrente,
      500 +
        15 -
        (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2,
      59
    );

    /* INIZIO TESTO */
    doc.setFontSize(10);
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Premesso che:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      105
    );
    doc.text(
      doc.splitTextToSize(
        `L’art. 2 lett. g) del D.Lgs 81/08 definisce l’”Addetto del servizio di prevenzione e protezione” come la “Persona in possesso delle capacità e dei requisiti professionali di cui all’articolo 32 (D.Lgs. 81/08), facente parte del Servizio di Prevenzione e Protezione dai rischi”.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      117
    );
    doc.text(
      doc.splitTextToSize(
        `Il/La sottoscritto/a ${cap(
          dati?.dl?.split(" ")
        )}, in qualità di Datore di Lavoro dell'azienda ${ragioneSociale} per la sede di ${bloccoSede}, dopo aver provveduta alla nomina di un Responsabile del servizio di prevenzione e protezione e dopo aver verificato il possesso delle capacità e dei requisiti previsti dalla normativa vigente per lo svolgimento dell'incarico di Addetto del Servizio di prevenzione e Protezione, ai sensi dell'art. 32 D.Lgs 81/08, ha deciso di designarla, Addetto del Servizio di Prevenzione e Protezione, nel contesto del sito di ${sedeInd} - ${sedeCap} ${sedeLoc}.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      158
    );

    doc.text(
      doc.splitTextToSize(
        "Pertanto, in collaborazione con le altre figure di riferimento individuate nell'ambito della sicurezza del lavoro:",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      240
    );
    /* ELENCO PUNTATO */
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      264
    );
    doc.text(
      doc.splitTextToSize(
        "dovrà adoperarsi, compatibilmente con tempo e mezzi a disposizione, per svolgere appieno i compiti previsti dall'articolo 33, comma 1, D.Lgs 81/08 s.m.i, come di seguito riportato:",
        doc.internal.pageSize.getWidth() - 100
      ),
      58,
      264
    );
    doc.text(
      doc.splitTextToSize("\u25E6 ", doc.internal.pageSize.getWidth() - 80),
      58,
      288
    );
    doc.text(
      doc.splitTextToSize(
        "collaborare all'individuazione dei fattori di rischio, alla valutazione dei rischi e all'individuazione delle misure per la sicurezza e la salubrità degli ambienti di lavoro, nel rispetto della normativa vigente sulla base della specifica conoscenza dell'organizzazione aziendale;",
        doc.internal.pageSize.getWidth() - 120
      ),
      73,
      288
    );
    doc.text(
      doc.splitTextToSize("\u25E6 ", doc.internal.pageSize.getWidth() - 100),
      58,
      336
    );
    doc.text(
      doc.splitTextToSize(
        "elaborare, per quanto di competenza, le misure preventive e protettive, per le varie attività aziendali;",
        doc.internal.pageSize.getWidth() - 120
      ),
      73,
      336
    );
    doc.text(
      doc.splitTextToSize("\u25E6 ", doc.internal.pageSize.getWidth() - 100),
      58,
      360
    );
    doc.text(
      doc.splitTextToSize(
        "proporre i programmi di informazione e formazione dei lavoratori;",
        doc.internal.pageSize.getWidth() - 120
      ),
      73,
      360
    );
    doc.text(
      doc.splitTextToSize("\u25E6 ", doc.internal.pageSize.getWidth() - 100),
      58,
      372
    );
    doc.text(
      doc.splitTextToSize(
        "partecipare alle consultazioni in materia di tutela della salute e sicurezza sul lavoro, nonchè alla riunione periodica;",
        doc.internal.pageSize.getWidth() - 120
      ),
      73,
      372
    );
    doc.text(
      doc.splitTextToSize("\u25E6 ", doc.internal.pageSize.getWidth() - 100),
      58,
      396
    );
    doc.text(
      doc.splitTextToSize(
        "fornire ai lavoratori le informazioni di cui all'art. 36 del D.Lgs 81/08.",
        doc.internal.pageSize.getWidth() - 120
      ),
      73,
      396
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      418
    );
    doc.text(
      doc.splitTextToSize(
        "sarà tenuto al segreto in ordine ai processi lavorativi di cui verrà a conoscenza nell'esercizio delle funzioni che le sono state assegnate.",
        doc.internal.pageSize.getWidth() - 100
      ),
      58,
      418
    );
    doc.text(
      doc.splitTextToSize(
        `Per il conseguimento dei medesimi fini, il sottoscritto ${cap(
          dati?.dl?.split(" ")
        )}, in qualità di Datore di Lavoro dell'azienda ${ragioneSociale} fornirà, secondo quanto previsto dal D.Lgs 81/08, art. 18, comma 3, tutte le informazioni in merito a:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      445
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      481
    );
    doc.text(
      doc.splitTextToSize(
        "la natura dei rischi;",
        doc.internal.pageSize.getWidth() - 80
      ),
      58,
      481
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      493
    );
    doc.text(
      doc.splitTextToSize(
        "l'organizzazione del lavoro, la programmazione e l'attuazione delle misure preventive e protettive;",
        doc.internal.pageSize.getWidth() - 80
      ),
      58,
      493
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      505
    );
    doc.text(
      doc.splitTextToSize(
        "la descrizione degli impianti e dei processi produttivi;",
        doc.internal.pageSize.getWidth() - 80
      ),
      58,
      505
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      517
    );
    doc.text(
      doc.splitTextToSize(
        "i dati relativi ad infortuni e malattie professionali;",
        doc.internal.pageSize.getWidth() - 80
      ),
      58,
      517
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      529
    );
    doc.text(
      doc.splitTextToSize(
        "i provvedimenti adottati dagli organi di vigilanza;",
        doc.internal.pageSize.getWidth() - 80
      ),
      58,
      529
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      43,
      541
    );
    doc.text(
      doc.splitTextToSize(
        "collaborerà con la Sua opera e le Sue funzioni in materia di prevenzione degli infortuni ed igiene del lavoro.",
        doc.internal.pageSize.getWidth() - 80
      ),
      58,
      541
    );

    doc.text(
      doc.splitTextToSize(
        "Distinti saluti",
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      580
    );
    doc.text(
      doc.splitTextToSize(
        "Il Datore di lavoro",
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      605
    );
    doc.text(
      doc.splitTextToSize(
        "_____________________",
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      625
    );

    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        `Data ${dataCorrente}`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      660
    );

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Il/La sottoscritto/a ${cap(
          dati?.aspp?.split(" ")
        )} nel ricevere l'originale della presente designazione, prende atto di quanto in essa contenuto e dichiara di accettare l'incarico di Responsabile del Servizio di Prevenzione e Protezione, nel contesto del sito di ${sedeInd} - ${sedeCap} ${sedeLoc}.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      680
    );

    doc.text(
      doc.splitTextToSize(`Il ASPP`, doc.internal.pageSize.getWidth() - 60),
      33,
      742
    );
    doc.text(
      doc.splitTextToSize(
        `____________________`,
        doc.internal.pageSize.getWidth() - 60
      ),
      33,
      762
    );
    doc.text(
      doc.splitTextToSize(
        `Per consultazione`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`Per consultazione`) *
          doc.internal.getFontSize() -
        40,
      782
    );
    doc.text(
      doc.splitTextToSize(`Il RLS`, doc.internal.pageSize.getWidth() - 60),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`Il RLS`) * doc.internal.getFontSize() -
        40,
      794
    );
    doc.text(
      doc.splitTextToSize(
        `____________________`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`____________________`) *
          doc.internal.getFontSize() -
        40,
      815
    );

    doc.output("pdfobjectnewwindow", "attestato.pdf");
  };
  /*   const exportPDFFormazione = (
    infoSede,
    logo,
    ragioneSociale,
    sedeLoc,
    sedeInd,
    sedeCap,
    bloccoSede,
    sedeCF,
    dirigente
  ) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size, true);
    //const imgLogo = new Image();
    var imgLogo = logo;
    var imgDef = require("../assets/images/MILLERLogo-copia.jpg");
    var dataCorrente = new Date();
    dataCorrente = moment(dataCorrente).format("DD/MM/YYYY");
    var annoCorrente = moment(new Date()).format("YYYY");
    //AGGIUNGO I FONT CHE HO IMPORTATO
    doc.addFileToVFS("verdana-normal.ttf", fontVerdanaNormal);
    doc.addFileToVFS("verdana-bold.ttf", fontVerdanaBold);
    doc.addFont("verdana-normal.ttf", "verdana", "normal");
    doc.addFont("verdana-bold.ttf", "verdana", "bold");

    setOpenPDF(true);

    doc.setTextColor(50, 62, 79);

    doc.setDrawColor(17, 82, 111);
    doc.setLineWidth(1);
    doc.setFillColor(255, 255, 255);

    doc.addImage(imgDef, "PNG", 13, 13, 300, 89); //da riguardare se così o al contrario

    doc.setFont("verdana", "bold");
    doc.setFontSize(24);
    doc.text(
      "PIANO DELLA FORMAZIONE",
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth("PIANO DELLA FORMAZIONE") *
          doc.internal.getFontSize()) /
          2,
      500
    );
    doc.text(
      "ANNUALE",
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth("ANNUALE") * doc.internal.getFontSize()) / 2,
      520
    );

    doc.setFontSize(18);
    doc.text(
      moment(new Date()).format("MM/YYYY"),
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth(moment(new Date()).format("MM/YYYY")) *
          doc.internal.getFontSize()) /
          2,
      550
    );
    doc.autoTable({
      startY: 570,
      html: "#table-persone",
      useCss: true, //IMPORTANTISSIMO

      margin: { top: 40, right: 40, bottom: 60, left: 40 },
      rowPageBreak: "auto",
    });

    doc.setFont("verdana", "normal");
    //(IMG, extention, positionX, positionY, dimensionX, dimensionY)
    doc.setFontSize(9);
    doc.text(
      doc.splitTextToSize(
        `${ragioneSociale}`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`${ragioneSociale}`) *
          doc.internal.getFontSize() -
        40,
      120
    );
    doc.text(
      doc.splitTextToSize(
        `${sedeInd} - ${sedeCap}`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`${sedeInd} - ${sedeCap}`) *
          doc.internal.getFontSize() -
        40,
      132
    );
    doc.text(
      doc.splitTextToSize(
        `${bloccoSede}`,
        doc.internal.pageSize.getWidth() - 60
      ),
      doc.internal.pageSize.getWidth() -
        doc.getStringUnitWidth(`${bloccoSede}`) * doc.internal.getFontSize() -
        40,
      144
    );

    doc.text(
      doc.splitTextToSize(`1`, doc.internal.pageSize.getWidth() - 80),
      560,
      800
    );

    doc.text(
      doc.splitTextToSize(
        `${ragioneSociale}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      780
    );

    doc.text(
      doc.splitTextToSize(
        `Piano della formazione annuale`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      788
    );
    doc.text(
      doc.splitTextToSize(
        `${dataCorrente}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      796
    );

    doc.addImage(
      imgLogo,
      "PNG",
      (doc.internal.pageSize.getWidth() - 135) / 2,
      350,
      155,
      87.5
    );

    doc.addPage();

    doc.addImage(imgDef, "PNG", 513, 13, 67, 20);

    // INIZIO TESTO
    doc.setFontSize(9);
    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(`SCHEDA 5`, doc.internal.pageSize.getWidth() - 80),
      33,
      50
    );
    doc.text(
      doc.splitTextToSize(
        `PIANO DELLA FORMAZIONE ANNUALE`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      65
    );
    doc.text(
      doc.splitTextToSize(
        `1 Scopo e campo di applicazione`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      85
    );
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Scopo del presente piano è definire la logica e la modalità di gestione delle tematiche riguardanti l’inserimento, l’addestramento, la formazione e il coinvolgimento nelle attività aziendali dei dipendenti in coerenza con gli obiettivi di Salute e Sicurezza che l’organizzazione si pone.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      100
    );
    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        `2 Responsabilità`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      135
    );
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `La responsabilità nella gestione delle tematiche sopra riportate (inserimento, addestramento, formazione) risulta in capo al Datore di Lavoro.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      150
    );
    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        `3 Modalità operative`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      175
    );
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Il responsabile dell’organizzazione con l’RSPP elabora annualmente il piano di formazione; il presente piano tiene conto, in particolare:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      190
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      33,
      210
    );
    doc.text(
      doc.splitTextToSize(
        `Dell’inserimento in azienda di nuovi dipendenti che necessitano di inquadramento/informazione in fase di inserimento, formazione preventiva, affiancamento operativo o addestramento tecnico-professionale;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      210
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      33,
      230
    );
    doc.text(
      doc.splitTextToSize(
        `Delle esigenze di qualificazione del personale addetto a compiti particolarmente per cui la normativa richiede una specifica formazione e/o addestramento;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      230
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      33,
      250
    );
    doc.text(
      doc.splitTextToSize(
        `Di quanto prescritto dalle leggi vigenti in materia di salute e sicurezza dei lavoratori.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      250
    );

    doc.text(
      doc.splitTextToSize(
        `La partecipazione dei dipendenti a qualsiasi programma di formazione, informazione e/o addestramento viene documentata sistematicamente mediante la registrazione sul modulo Registro delle Presenze.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      270
    );

    doc.text(
      doc.splitTextToSize(
        `Viene inoltre conservata la documentazione utilizzata in fase di formazione (dispense, documenti, ecc.).`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      300
    );

    doc.text(
      doc.splitTextToSize(
        `A seguito formazione, informazione e/o addestramento effettuati, viene verificata l’efficacia della formazione ricevuta dai dipendenti e, se necessario, ripianificata una formazione aggiuntiva.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      320
    );
    doc.text(
      doc.splitTextToSize(
        `Copia degli attestati è conservata presso l’ufficio del referente aziendale della sicurezza.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      340
    );

    doc.text(
      doc.splitTextToSize(
        `Gli interventi formativi si distinguono in:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      360
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      33,
      370
    );
    doc.text(
      doc.splitTextToSize(
        `Interventi di informazione aggiornamento e sensibilizzazione: consegna di opuscoli informativi o documenti inerenti tematiche generiche o specifiche in materia di Salute e Sicurezza sul lavoro;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      370
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      33,
      390
    );
    doc.text(
      doc.splitTextToSize(
        `Interventi di formazione: corsi, lezioni o seminari su argomenti specifici di interesse operativo o mirati alla crescita delle conoscenze e competenze dei partecipanti.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      390
    );

    doc.text(
      doc.splitTextToSize(
        `La formazione viene solitamente affidata a consulenti esterni esperti nelle differenti materie o direttamente all’RSPP.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      420
    );

    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        `Formazione per nuovi assunti o in occasione di cambio mansione`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      455
    );

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Per i nuovi assunti in coerenza con la posizione da ricoprire ed in collaborazione con il Datore di Lavoro viene predisposto un piano di inserimento in azienda che applica la logica prevalente dell’affiancamento operativo, tramite:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      475
    );

    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      33,
      515
    );
    doc.text(
      doc.splitTextToSize(
        `Consegna di un opuscolo informativo con informazioni generali in merito alla normativa vigente, ai rischi presenti nella realtà produttiva e alle pratiche di prevenzione dei rischi sul lavoro;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      515
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      33,
      535
    );
    doc.text(
      doc.splitTextToSize(
        `L’addestramento operativo e tecnico con supervisione del Datore di Lavoro o di un Responsabile / dei dipendenti più esperti, sull’attività da svolgere, sui rischi presenti nell’area di lavoro e sulle procedure o modalità operative da mettere in atto per ridurre i rischi per la Salute e la Sicurezza dei lavoratori;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      535
    );

    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      33,
      565
    );
    doc.text(
      doc.splitTextToSize(
        `Corso di formazione in materia di salute e sicurezza sul lavoro secondo quanto previsto dall’Accordo Stato – Regioni dicembre 2011.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      565
    );

    doc.text(
      doc.splitTextToSize(
        `Oltre a quanto sopra, se la mansione prevede formazione specifica per l’utilizzo di attrezzature o sostanze o per ricoprire determinati ruoli in ambito salute e sicurezza verranno erogato i corsi previsti dalla normativa vigente.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      585
    );
    doc.text(
      doc.splitTextToSize(
        `Al momento dell’elaborazione del piano, non è possibile prevedere le assunzioni o cambi di mansione che potranno essere fatti nel corso d’anno. Per tale motivo il piano non prevede i corsi sopra menzionati per i nuovi assunti che saranno comunque svolti secondo le previsioni normative.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      605
    );

    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        `Formazione dei dipendenti`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      650
    );

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Per il personale che lavora già da tempo nell’organizzazione viene predisposto un piano di formazione (riportato in allegato) con lo scopo di migliorare la consapevolezza dei dipendenti in merito ai rischi presenti e alle modalità di gestione del rischio.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      670
    );
    doc.text(
      doc.splitTextToSize(
        `Sono previsti inoltre corsi di formazione per ricoprire specifici incarichi all’interno dell’organizzazione (es. addetto squadra di emergenza, addetto squadra di primo soccorso, preposto, ecc.).`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      700
    );
    doc.text(
      doc.splitTextToSize(`2`, doc.internal.pageSize.getWidth() - 80),
      560,
      800
    );

    doc.setFontSize(8);
    doc.text(
      doc.splitTextToSize(
        `${ragioneSociale}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      780
    );
    doc.text(
      doc.splitTextToSize(
        `Piano della formazione annuale`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      788
    );
    doc.text(
      doc.splitTextToSize(
        `${dataCorrente}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      796
    );

    //terza pagina
    doc.addPage();

    doc.addImage(imgDef, "PNG", 513, 13, 67, 20);

    doc.setFontSize(9);
    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        `4 Coinvolgimento e sensibilizzazione`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      50
    );

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Il coinvolgimento di tutto il personale viene favorito con sistematicità dalla comunicazione ed informazione da parte del Datore di Lavoro. Più in specifico, l'attività di sensibilizzazione è promossa tramite la continua opera di coinvolgimento da parte del Responsabile nei differenti aspetti legati all’attività lavorativa ed in particolare nei confronti della Salute e Sicurezza dei lavoratori.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      70
    );
    doc.text(
      doc.splitTextToSize(
        `Inoltre, con particolare riferimento alle tematiche di Salute e Sicurezza nei luoghi di lavoro, si prevedono incontri del Servizio di Prevenzione e Protezione annuali per discutere di:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      110
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      33,
      130
    );
    doc.text(
      doc.splitTextToSize(
        `Documento di valutazione dei rischi;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      130
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      33,
      140
    );
    doc.text(
      doc.splitTextToSize(
        `Andamento degli infortuni, delle malattie professionali e della sorveglianza sanitaria;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      140
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      33,
      150
    );
    doc.text(
      doc.splitTextToSize(
        `Gestione e utilizzo dei Dispositivi di Protezione Individuale;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      150
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      33,
      160
    );
    doc.text(
      doc.splitTextToSize(
        `Programmi di formazione e informazione ai fini della Salute e Sicurezza dei lavoratori;`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      160
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      33,
      170
    );
    doc.text(
      doc.splitTextToSize(
        `Obiettivi di miglioramento per la sicurezza dei dipendenti e procedure di sicurezza da adottare.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      170
    );
    doc.text(
      doc.splitTextToSize(
        `La consultazione dei lavoratori avviene durante gli incontri tra il Datore di Lavoro e/o l’RSPP e i dipendenti stessi durante i diversi sopralluoghi nei luoghi di lavoro.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      190
    );
    doc.text(
      doc.splitTextToSize(
        `Il Medico Competente viene consultato durante le riunioni periodiche del Servizio di Prevenzione e Protezione e ogniqualvolta se ne riscontri la necessità.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      210
    );
    doc.text(
      doc.splitTextToSize(
        `L’attività di consultazione dei lavoratori avviene anche tramite il coinvolgimento del Rappresentante dei Lavoratori per la Sicurezza (RLS) così come prescritto dalla normativa vigente, ed in particolare attraverso la riunione del Servizio di Prevenzione e Protezione (ex art. 35 D.Lgs. 81/08).`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      240
    );
    doc.text(
      doc.splitTextToSize(
        `Inoltre l’RLS viene consultato in ordine a tutte le tematiche collegate alla Valutazione dei Rischi presenti all’interno dell’Organizzazione.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      270
    );
    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        `5 Metodologia di compilazione della tabella`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      300
    );
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Nella tabella sotto riportata vengono elencati tutti i corsi in ambito sicurezza sul lavoro previsti dalla normativa vigente.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      315
    );
    doc.text(
      doc.splitTextToSize(
        `Nella colonna “assoggettamento” vengono crocettati i corsi a cui l’azienda è soggetta.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      335
    );
    doc.text(
      doc.splitTextToSize(
        `Nella colonna “applicabilità” vengono crocettati i corsi che l’azienda dovrà svolgere nell’anno a cui si riferisce il presente piano.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      345
    );
    doc.text(
      doc.splitTextToSize(
        `Non vengono considerati in tabella i corsi che dovranno svolgere eventuali neoassunti o lavoratori a cui sarà cambiata la mansione, come specificato nel capitolo 3.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      365
    );

    doc.text(
      doc.splitTextToSize(`3`, doc.internal.pageSize.getWidth() - 80),
      560,
      800
    );

    doc.setFontSize(8);
    doc.text(
      doc.splitTextToSize(
        `${ragioneSociale}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      780
    );
    doc.text(
      doc.splitTextToSize(
        `Piano della formazione annuale`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      788
    );
    doc.text(
      doc.splitTextToSize(
        `${dataCorrente}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      796
    );

    doc.addPage("a4", "landscape");

    doc.addImage(imgDef, "PNG", 763, 13, 67, 20);

    doc.setFontSize(12);
    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        `6 PIANIFICAZIONE DELLA FORMAZIONE anno ${annoCorrente}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      50
    );
    doc.setFont("verdana", "normal");

    doc.autoTable({
      startY: 70,
      html: "#table-formazione",
      useCss: true, //IMPORTANTISSIMO
      didDrawPage: function (column) {
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height;
        //Header
        doc.addImage(imgDef, "PNG", 763, 13, 67, 20);

        // Footer
        var str = doc.internal.getNumberOfPages();

        doc.setFontSize(9);
        doc.text(
          doc.splitTextToSize(str, doc.internal.pageSize.getWidth() - 80),
          805,
          565
        );
        doc.setFontSize(8);
        doc.text(
          doc.splitTextToSize(
            `${ragioneSociale}`,
            doc.internal.pageSize.getWidth() - 80
          ),
          33,
          560
        );
        doc.text(
          doc.splitTextToSize(
            `Piano della formazione annuale`,
            doc.internal.pageSize.getWidth() - 80
          ),
          33,
          568
        );
        doc.text(
          doc.splitTextToSize(
            `${dataCorrente}`,
            doc.internal.pageSize.getWidth() - 80
          ),
          33,
          576
        );
      },
      margin: { top: 40, right: 40, bottom: 60, left: 40 },
      rowPageBreak: "auto",
    });

    doc.output("pdfobjectnewwindow");
    doc.output(
      "save",
      ragioneSociale +
        "_" +
        "pianoformazione" +
        "_" +
        moment(new Date()).format("YYYY") +
        ".pdf"
    );
  }; */

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "200",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <IconButton
            aria-label="delete"
            onClick={async () => {
              await setOrganigrammaSel(params.row);
              document.getElementById("editOrg").click();
            }}
          >
            <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
          </IconButton>
          <ModalDelete
            check={false}
            selezionato={params.row}
            deleteAdempimento={() => deleteRuolo(params.row.ID)}
          />

          {params.row.InAttesa === 0 &&
          (params.row.Ruolo === "Addetto Emergenza" ||
            params.row.Ruolo === "Preposto" ||
            params.row.Ruolo === "Addetto Primo Soccorso" ||
            params.row.Ruolo === "Dirigente" ||
            params.row.Ruolo === "ASPP" ||
            params.row.Ruolo === "RSPP") ? (
            <IconButton aria-label="print">
              <Print
                onClick={() => {
                  if (params.row.Ruolo === "Addetto Emergenza") {
                    setEmergenzaValue(params.row.Nominativo);
                    setOpenPrintEmergenza(true);
                  } else if (params.row.Ruolo === "Addetto Primo Soccorso") {
                    setPrimoSoccorsoValue(params.row.Nominativo);
                    setOpenPrintPrimoSoccorso(true);
                  } else if (params.row.Ruolo === "Preposto") {
                    exportPDFPreposto(
                      props.logo,
                      params.row,
                      props.regSoc,
                      props.sedeLoc,
                      props.sedeInd,
                      props.sedeCap,
                      props.bloccoSede
                    );
                  } else if (params.row.Ruolo === "ASPP") {
                    setOpenPrintASPP(true);
                  } else if (params.row.Ruolo === "Dirigente") {
                    if (
                      organigramma.filter((o) => o.IDRuolo === 1).length !== 0
                    ) {
                      exportPDFDirigente(
                        props.infoSede,
                        props.logo,
                        props.regSoc,
                        props.sedeLoc,
                        props.sedeInd,
                        props.sedeCap,
                        props.bloccoSede,
                        props.sedeCF,
                        params.row
                      );
                    } else {
                      setSnack({
                        severity: "error",
                        messaggio: "Datore di lavoro mancante!",
                      });
                      setOpenSnack(true);
                    }
                  } else if (params.row.Ruolo === "RSPP") {
                    setOpenPrint(true);
                  }
                }}
                style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
              />
            </IconButton>
          ) : (
            <div style={{ height: "2rem", width: "2rem", margin: "8px" }}></div>
          )}
        </>
      ),
    },
    {
      field: "Ruolo",
      headerName: "Ruolo",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Nominativo",
      headerName: "Nominativo",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => {
        let nominativo = "";
        if (params.row.InAttesa === 1) {
          switch (params.row.IDRuolo) {
            case 4:
              nominativo = "In fase di elezione";
              break;
            case 5:
            case 6:
              nominativo = "In fase di individuazione";
              break;
            case 7:
            case 8:
              nominativo = "In fase di nomina";
              break;

            default:
              break;
          }
        } else {
          nominativo = cap(params.row?.Nominativo?.split(" "));
        }
        return <>{nominativo}</>;
      },
    },
  ];

  const loadOrganigramma = async () => {
    setLoading(true);
    let response = await unsplash.get("organigrammi", {
      params: { sede: props.sede, cliente: props.idCliente },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setOrganigramma(response.data.data);
    setLoading(false);
    setRLS(response.data.data.filter((el) => el.Ruolo === "RLS"));
    let ruoli = { ...personeFormazione };
    response.data.data.map((el) => {
      if (el.IDRuolo === 1) {
        ruoli.Datore = el.Nominativo;
      }
      if (el.IDRuolo === 2) {
        ruoli.RSPP = el.Nominativo;
      }
      if (el.IDRuolo === 4) {
        ruoli.RLS = el.Nominativo;
      }
      setPersoneFormazione(ruoli);
    });
  };
  const deleteRuolo = async (id) => {
    try {
      await unsplash.delete("organigrammi", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: id,
        },
      });

      loadOrganigramma();
      setSnack({
        severity: "success",
        messaggio: "Ruolo eliminato con successo",
      });
      setOpenSnack(true);
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione",
      });
      setOpenSnack(true);
    }
  };
  //wrapper stampe
  const handleMenuItemClick = (event, index) => {
    document.getElementById(`${index}`).click();
    //setSelectedIndex(index);
    setOpenButton(false);
  };
  const handleToggleButton = () => {
    setOpenButton(!openButton);
  };
  const handleCloseButton = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenButton(false);
  };

  useEffect(() => {
    async function fetchData() {
      //setLoadingStampe(true);
      let responsePersone = await unsplash.get("organigrammi", {
        params: { sede: props.sede, cliente: props.idCliente },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      let ruoli = { ...personeFormazione };
      responsePersone.data.data.map((el) => {
        if (el.IDRuolo === 1) {
          ruoli.Datore = el.Nominativo;
        }
        if (el.IDRuolo === 2) {
          ruoli.RSPP = el.Nominativo;
        }
        if (el.IDRuolo === 4) {
          ruoli.RLS = el.Nominativo;
        }
        setPersoneFormazione(ruoli);
      });

      let response = await unsplash.get("ruoliOrganigramma", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setRuoliOrganigramma(response.data.data);

      let responseLavoratori = await unsplash.get("lavoratori", {
        params: { sede: props.sede, cliente: props.idCliente },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setLavoratori(responseLavoratori.data.data);
      let responseMediciCliente = await unsplash.get(
        "medici",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { idSede: props.sede, idCliente: props.idCliente },
        } //props.idAzienda}}
      );

      setMedici(responseMediciCliente.data.data);
      loadOrganigramma();

      /* let responseTabella = await unsplash.get("scadenzario", {
        params: {
          idSede: props.sede,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setTabellaFormazione(responseTabella.data.data); */
      //setLoadingStampe(false);
    }
    fetchData();
  }, []);

  return (
    <>
      <table id="headerTable" hidden>
        <tr
          className="containerTbl"
          style={{ width: "300px", display: "flex" }}
        >
          <th style={{ w: 1 / 3, backgroundColor: "#FF2768" }}>Lastname</th>
          <th style={{ width: "50px", backgroundColor: "#40C18F" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <span style={{ textAlign: "center" }}>
                DESIGNAZIONE ai sensi dell'art. 18 comma 1 <br />
                INCARICATO ALLA LOTTA ANTINCENDIO E<br />
                GESTIONE DELLE EMERGENZE
              </span>
            </div>
          </th>
          <th style={{ w: 1 / 3, backgroundColor: "yellow" }}>Age</th>
        </tr>
      </table>
      <table id="table-formazione" hidden>
        <thead>
          <tr>
            <th colspan="6">FORMAZIONE FIGURE ORGANIGRAMMA SICUREZZA</th>
          </tr>
          <tr>
            <th scope="col">CORSO</th>
            <th scope="col">RIFERIMENTO NORMATIVO</th>
            <th scope="col">DURATA CORSO</th>
            <th scope="col">AGGIORNAMENTO DURATA E FREQUENZA</th>
            <th scope="col">ASSOGGETTABILITA'</th>
            <th scope="col">APPLICABILITA'</th>
          </tr>
        </thead>
        <tbody>
          {tabellaFormazione?.map((el) => {
            return (
              <>
                <tr>
                  <td>{el?.Descrizione}</td>
                  <td>{el?.RiferimentoNormativo}</td>
                  <td>{el?.Durata}</td>
                  <td>{el?.Frequenza}</td>
                  <td
                    style={{
                      backgroundColor:
                        el?.Codice === "FG"
                          ? "white"
                          : el?.Assoggettibilita === "Non Soggetta"
                          ? "#c3c3c3"
                          : "",
                    }}
                  >
                    {el?.Codice === "FG" ? "Soggetta" : el?.Assoggettibilita}
                  </td>
                  <td
                    style={{
                      backgroundColor:
                        el?.Applicabilita === "Non Applicabile"
                          ? "#c3c3c3"
                          : "",
                    }}
                  >
                    {el?.Applicabilita}
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
      <table id="table-persone" hidden>
        <tr>
          <th scope="col">DATORE DI LAVORO</th>
          <th scope="col">RSPP</th>
          <th scope="col">RLS</th>
        </tr>
        <tr>
          <td>
            {personeFormazione?.Datore
              ? cap(personeFormazione?.Datore?.split(" "))
              : null}
          </td>
          <td>
            {personeFormazione?.RSPP
              ? cap(personeFormazione?.RSPP?.split(" "))
              : null}
          </td>
          <td>
            {personeFormazione?.RLS
              ? cap(personeFormazione?.RLS?.split(" "))
              : null}
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </table>
      {/* DIALOG Antincendio */}
      <Dialog
        open={openPrintEmergenza}
        onClose={() => setOpenPrintEmergenza(false)}
        maxWidth="sm"
        TransitionComponent={Transition}
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={datoreLavoroEmergenza}
                  onChange={() =>
                    setDatoreLavoroEmergenza(!datoreLavoroEmergenza)
                  }
                />
              }
              label="Datore di Lavoro"
            />
          </Grid>
          {datoreLavoroEmergenza ? (
            <Grid item xs={12}>
              <Autocomplete
                id="combo-box-demo"
                options={RLS}
                onChange={(event, newValue) => {
                  setRLSSel(newValue ? newValue.Nominativo : null);
                }}
                getOptionLabel={(option) => option.Nominativo}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="RLS" />
                )}
              />
            </Grid>
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            onClick={() => setOpenPrintEmergenza(false)}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Print />}
            onClick={() => {
              if (datoreLavoroEmergenza) {
                exportPDFDatoreLavoroEmergenza(
                  props.logo,
                  emergenzaValue,
                  props.regSoc,
                  props.sedeLoc,
                  props.sedeInd,
                  props.sedeCap,
                  props.bloccoSede
                );
              } else {
                exportPDFEmergenza(
                  props.logo,
                  emergenzaValue,
                  props.regSoc,
                  props.sedeLoc,
                  props.sedeInd,
                  props.sedeCap,
                  props.bloccoSede
                );
              }
              setOpenPrintEmergenza(false);
              setRLSSel("___________________");
            }}
          >
            Stampa
          </Button>
        </DialogActions>
      </Dialog>
      {/* DIALOG PrimoSoccorso */}
      <Dialog
        open={openPrintPrimoSoccorso}
        onClose={() => setOpenPrintPrimoSoccorso(false)}
        maxWidth="sm"
        TransitionComponent={Transition}
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={datoreLavoroPrimoSoccorso}
                  onChange={() =>
                    setDatoreLavoroPrimoSoccorso(!datoreLavoroPrimoSoccorso)
                  }
                />
              }
              label="Datore di Lavoro"
            />
          </Grid>
          {datoreLavoroPrimoSoccorso ? (
            <Grid item xs={12}>
              <Autocomplete
                id="combo-box-demo"
                options={RLS}
                onChange={(event, newValue) => {
                  setRLSSel(newValue ? newValue.Nominativo : null);
                }}
                getOptionLabel={(option) => option.Nominativo}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="RLS" />
                )}
              />
            </Grid>
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            onClick={() => setOpenPrintPrimoSoccorso(false)}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Print />}
            onClick={() => {
              if (datoreLavoroPrimoSoccorso) {
                exportPDFDatoreLavoroPrimoSoccorso(
                  props.logo,
                  primoSoccorsoValue,
                  props.regSoc,
                  props.sedeLoc,
                  props.sedeInd,
                  props.sedeCap,
                  props.bloccoSede
                );
              } else {
                exportPDFPrimoSoccorso(
                  props.logo,
                  primoSoccorsoValue,
                  props.regSoc,
                  props.sedeLoc,
                  props.sedeInd,
                  props.sedeCap,
                  props.bloccoSede
                );
              }
              setOpenPrintPrimoSoccorso(false);
              setRLSSel("___________________");
            }}
          >
            Stampa
          </Button>
        </DialogActions>
      </Dialog>
      {/* DIALOG RSPP */}
      <Dialog
        open={openPrint}
        onClose={() => setOpenPrint(false)}
        maxWidth="sm"
        TransitionComponent={Transition}
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={datoreLavoroRSPP}
                  onChange={() => setDatoreLavoroRSPP(!datoreLavoroRSPP)}
                />
              }
              label="Datore di Lavoro"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={valueRSPP.dl}
              margin="dense"
              name="dl"
              label="Datore di Lavoro"
              type="text"
              fullWidth
              style={{ textTransform: "capitalize" }}
              variant="outlined"
              onChange={handleChange}
            />
          </Grid>
          {!datoreLavoroRSPP ? (
            <Grid item xs={12}>
              <TextField
                value={valueRSPP.rspp}
                margin="dense"
                name="rspp"
                label="RSPP nominato"
                type="text"
                fullWidth
                sx={{ textTransform: "capitalize" }}
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Autocomplete
                id="combo-box-demo"
                options={RLS}
                onChange={(event, newValue) => {
                  setRLSSel(newValue ? newValue.Nominativo : null);
                }}
                getOptionLabel={(option) => option.Nominativo}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="RLS" />
                )}
              />
            </Grid>
          )}
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            onClick={() => setOpenPrint(false)}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Print />}
            onClick={() => {
              if (datoreLavoroRSPP) {
                exportPDFDatoreLavoroRSPP(
                  props.logo,
                  valueRSPP,
                  props.regSoc,
                  props.sedeLoc,
                  props.sedeInd,
                  props.sedeCap,
                  props.bloccoSede
                );
              } else {
                exportPDFRSPP(
                  props.logo,
                  valueRSPP,
                  props.regSoc,
                  props.sedeLoc,
                  props.sedeInd,
                  props.sedeCap,
                  props.bloccoSede
                );
              }
              setOpenPrint(false);
              setRLSSel("___________________");
            }}
          >
            Stampa
          </Button>
        </DialogActions>
      </Dialog>
      {/* DIALOG ASPP */}
      <Dialog
        open={openPrintASPP}
        onClose={() => setOpenPrintASPP(false)}
        maxWidth="sm"
        TransitionComponent={Transition}
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12}>
            <TextField
              value={valueASPP.dl}
              margin="dense"
              name="dl"
              label="Datore di Lavoro"
              type="text"
              fullWidth
              style={{ textTransform: "capitalize" }}
              variant="outlined"
              onChange={handleChangeASPP}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={valueASPP.aspp}
              margin="dense"
              name="aspp"
              label="ASPP nominato"
              type="text"
              fullWidth
              sx={{ textTransform: "capitalize" }}
              variant="outlined"
              onChange={handleChangeASPP}
            />
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            onClick={() => setOpenPrintASPP(false)}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Print />}
            onClick={() => {
              exportPDFASPP(
                props.logo,
                valueASPP,
                props.regSoc,
                props.sedeLoc,
                props.sedeInd,
                props.sedeCap,
                props.bloccoSede
              );
              setOpenPrintASPP(false);
            }}
          >
            Stampa
          </Button>
        </DialogActions>
      </Dialog>

      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={2}
      >
        <ButtonGroup
          aria-label="split button"
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            ref={anchorRef}
            startIcon={<PrintIcon />}
            variant="contained"
            className={loadingStampe ? "ButtonMillerDisabled" : "ButtonMiller"}
            onClick={() => {
              if (loadingStampe) {
                setSnack({
                  severity: "error",
                  messaggio: "Caricamento in corso...",
                });
                setOpenSnack(true);
              } else {
                handleToggleButton();
              }
            }}
          >
            STAMPA
          </Button>

          <Button
            variant="contained"
            className={loadingStampe ? "ButtonMillerDisabled" : "ButtonMiller"}
            size="small"
            aria-controls={openButton ? "split-button-menu" : undefined}
            aria-expanded={openButton ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={() => {
              if (loadingStampe) {
                setSnack({
                  severity: "error",
                  messaggio: "Caricamento in corso...",
                });
                setOpenSnack(true);
              } else {
                handleToggleButton();
              }
            }}
          >
            {openButton ? <ArrowDropUp /> : <ArrowDropDownIcon />}
          </Button>

          <Popper
            sx={{
              zIndex: 100,
            }}
            open={openButton}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps}>
                <Paper sx={{ ml: "150px" }}>
                  <ClickAwayListener onClickAway={handleCloseButton}>
                    <MenuList id="split-button-menu" Item>
                      {options.map((option, index) => (
                        <MenuItem
                          key={option}
                          selected={index === selectedIndex}
                          onClick={(event) => {
                            handleMenuItemClick(event, index);
                          }}
                        >
                          <span style={{ color: "#11526f" }}>
                            {option.icon} {option.text}
                          </span>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <div style={{ display: "none" }}>
            <Button
              id="0"
              variant="contained"
              className="ButtonMiller"
              sx={{ display: operation === "Edit" ? "" : "None" }}
              onClick={() => {
                document.getElementById("convocazione").click();
              }}
            >
              CONVOCAZIONE RIUNIONE
            </Button>
            <ConvocazioneRiunione
              organigramma={organigramma}
              cliente={props.idCliente}
            />
            {/* <Button
              id="1"
              variant="contained"
              className="ButtonMiller"
              sx={{ display: operation === "Edit" ? "" : "None" }}
              onClick={() => {
                if (!personeFormazione.Datore) {
                  setSnack({
                    severity: "error",
                    messaggio: "Datore di lavoro mancante",
                  });
                  setOpenSnack(true);
                } else if (!personeFormazione.RLS) {
                  setSnack({
                    severity: "error",
                    messaggio: "RLS mancante",
                  });
                  setOpenSnack(true);
                } else if (!personeFormazione.RSPP) {
                  setSnack({
                    severity: "error",
                    messaggio: "RSPP mancante",
                  });
                  setOpenSnack(true);
                } else {
                  exportPDFFormazione(
                    props.infoSede,
                    props.logo,
                    props.regSoc,
                    props.sedeLoc,
                    props.sedeInd,
                    props.sedeCap,
                    props.bloccoSede,
                    props.sedeCF
                    //params.row
                  );
                }
              }}
            >
              PIANO FORMAZIONE
            </Button> */}
            <Button
              id="1"
              variant="contained"
              className="ButtonMiller"
              sx={{ display: operation === "Edit" ? "" : "None" }}
              onClick={() => {
                document.getElementById("riunione").click();
              }}
            >
              RIUNIONE PERIODICA
            </Button>
            <RiunionePeriodica
              organigramma={organigramma}
              ruoli={ruoliOrganigramma}
              lavoratori={lavoratori}
              sede={props.sede}
              cliente={props.idCliente}
              loadOrganigramma={loadOrganigramma}
              snack={snack}
              openSnack={openSnack}
              handleCloseSnack={handleCloseSnack}
              setSnack={setSnack}
              setOpenSnack={setOpenSnack}
              logo={props.logo}
            />
            {/* <DVR /> */}
          </div>
          {/*  */}
          <DialogGraficoOrganigramma
            organigramma={organigramma}
            reload={loadOrganigramma}
            medici={medici}
            logo={props.logo}
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
          />

          <DialogOrganigramma
            id="editOrg"
            title="Modifica"
            organigrammaSel={organigrammaSel}
            ruoli={ruoliOrganigramma}
            lavoratori={lavoratori}
            sede={props.sede}
            cliente={props.idCliente}
            loadOrganigramma={loadOrganigramma}
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
            logo={props.logo}
          />
          <DialogOrganigramma
            id="new"
            title="Aggiungi"
            ruoli={ruoliOrganigramma}
            lavoratori={lavoratori}
            sede={props.sede}
            cliente={props.idCliente}
            loadOrganigramma={loadOrganigramma}
            snack={snack}
            openSnack={openSnack}
            handleCloseSnack={handleCloseSnack}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
            logo={props.logo}
          />
        </ButtonGroup>
      </Grid>
      <Box sx={{ height: "60vh", width: 1 }}>
        <DataGridPro
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooterPersonalized,
          }}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={loading}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          initialState={{ pinnedColumns: { left: ["Actions"] } }}
          rows={organigramma}
          columns={columns}
          getRowId={(row) => row.ID}
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = organigramma.filter((row) =>
              selectedIDs.has(row.ID)
            );
          }}
        />
      </Box>
    </>
  );
};

const ModalDelete = (props) => {
  const [open, setOpen] = useState(false);
  const [organigrammaSel, setOrganigrammaSel] = useState(null);

  const handleClickOpen = () => {
    setOrganigrammaSel(props.selezionato);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    props.deleteAdempimento(organigrammaSel);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth="sm"
        TransitionComponent={Transition}
      >
        <DialogTitle>Rimuovi Organigramma</DialogTitle>
        <DialogContent>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={12} textAlign={"center"}>
              Sei sicuro di voler eliminare:{" "}
              <b>
                {props.selezionato.Ruolo}-{props.selezionato.Nominativo}?
              </b>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 1,
            px: 3,
          }}
        >
          <Button
            //variant="contained"
            //className="ButtonMiller"
            //startIcon={<Close />}
            onClick={handleClose}
          >
            Chiudi
          </Button>
          <Button
            //variant="contained"
            //className="ButtonMiller"
            //startIcon={<Check />}
            onClick={handleDelete}
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const DialogOrganigramma = (props) => {
  const [open, setOpen] = useState(false);

  const [organigramma, setOrganigramma] = useState(null);
  const [lavoratoreSel, setLavoratoreSel] = useState(null);

  const handleClickOpen = () => {
    if (props.id === "new") {
      setLavoratoreSel(null);
      setOrganigramma({
        Cliente: props.cliente,
        Sede: props.sede,
        Interno: 1,
        InAttesa: 0,
      });
    } else {
      setOrganigramma({
        ...props.organigrammaSel,
        Ruolo: props.organigrammaSel.IDRuolo,
      });
      //se è un lavoratore esterno assegno solo la stringa
      if (props.organigrammaSel.Interno === 1) {
        setLavoratoreSel(
          props.lavoratori.filter(
            (lavoratore) =>
              lavoratore.ID === Number(props.organigrammaSel.Lavoratore)
          )[0]
        );
      }
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    if (props.id === "new") {
      try {
        await unsplash.post(
          "organigrammi",
          { organigramma: { ...organigramma } },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        props.setSnack({
          severity: "success",
          messaggio: "Ruolo creato con successo",
        });
        props.setOpenSnack(true);
        props.loadOrganigramma();
        handleClose();
      } catch {
        props.setSnack({
          severity: "error",
          messaggio: "Errore durante la modifica",
        });
        props.setOpenSnack(true);
      }
    } else {
      try {
        await unsplash.put(
          "organigrammi",
          { organigramma: { ...organigramma, ID: props.organigrammaSel.ID } },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        props.setSnack({
          severity: "success",
          messaggio: "Ruolo creato con successo",
        });
        props.setOpenSnack(true);
        props.loadOrganigramma();
        handleClose();
      } catch {
        props.setSnack({
          severity: "error",
          messaggio: "Errore durante la creazione",
        });
        props.setOpenSnack(true);
      }
    }
  };

  return (
    <>
      <GlobalSnackbar
        severity={props.snack.severity}
        messaggio={props.snack.messaggio}
        openSnack={props.openSnack}
        handleCloseSnack={props.handleCloseSnack}
      />
      <Button
        className="ButtonMiller"
        size="small"
        id={props.id}
        style={{ display: props.id === "new" ? "flex" : "none" }}
        variant="contained"
        endIcon={<Add />}
        onClick={handleClickOpen}
      >
        Aggiungi
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth="sm"
        maxWidth="sm"
        TransitionComponent={Transition}
      >
        <DialogTitle id="alert-dialog-title">
          {props.title} Organigramma
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {" "}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={organigramma?.Interno === 1 ? true : false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setOrganigramma({
                            ...organigramma,
                            Interno: e.target.checked ? 1 : 0,
                          });
                        } else {
                          setOrganigramma({
                            ...organigramma,
                            Interno: e.target.checked ? 1 : 0,
                            Nominativo: "",
                          });
                        }
                      }}
                    />
                  }
                  label="Interno"
                />{" "}
              </Grid>
              <Grid item xs={6}>
                {" "}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={organigramma?.InAttesa === 1 ? true : false}
                      onChange={(e) =>
                        setOrganigramma({
                          ...organigramma,
                          InAttesa: e.target.checked ? 1 : 0,
                        })
                      }
                    />
                  }
                  label="Lavoratore in attesa"
                />{" "}
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">Ruolo</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Ruolo"
                    value={organigramma?.Ruolo}
                    onChange={(e) =>
                      setOrganigramma({
                        ...organigramma,
                        Ruolo: e.target.value,
                      })
                    }
                  >
                    {props.ruoli.map((r) => (
                      <MenuItem key={r.ID} value={r.ID}>
                        {r.Ruolo}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {organigramma?.InAttesa === 0 ? (
                  <>
                    {organigramma?.Interno === 1 ? (
                      <>
                        <Autocomplete
                          fullWidth
                          value={lavoratoreSel}
                          id="combo-box-demo"
                          options={props.lavoratori?.filter(el=> !el.DataTermine ||
                            moment(el.DataTermine).format("YYYY-MM-DD") >
                              moment(new Date()).format("YYYY-MM-DD"))}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setOrganigramma({
                                ...organigramma,
                                Nominativo: newValue.ID,
                              });
                              setLavoratoreSel(newValue);
                            }
                          }}
                          getOptionLabel={(option) =>
                            option.Nome + " " + option.Cognome
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              fullWidth
                              label="Lavoratore"
                            />
                          )}
                        />
                      </>
                    ) : (
                      <>
                        <TextField
                          label="Nominativo"
                          size="small"
                          fullWidth
                          value={organigramma?.Nominativo}
                          onChange={(e) =>
                            setOrganigramma({
                              ...organigramma,
                              Nominativo: e.target.value,
                            })
                          }
                        ></TextField>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between", mx: 2 }}
        >
          <Button
            onClick={handleClose}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
          >
            ANNULLA
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DialogGraficoOrganigramma = (props) => {
  const [open, setOpen] = useState(false);
  const [openPDF, setOpenPDF] = useState(false);
  const [loading, setLoading] = useState(false);
  const [datoreDiLavoro, setDatoreDiLavoro] = useState("non presente");
  const [rspp, setRspp] = useState("non presente");
  const [aspp, setAspp] = useState("non presente");
  const [addettiEmergenza, setAddettiEmergenza] = useState([]);
  const [primoSoccorso, setPrimoSoccorso] = useState([]);
  const [dirigenti, setDirigenti] = useState([]);
  const [preposti, setPreposti] = useState([]);
  const [medicoCoordinatore, setMedicoCoordinatore] = useState("");
  const [medici, setMedici] = useState([]);

  const [rls, setRls] = useState([]);

  const [thereIsPreposto, setThereIsPreposto] = useState(false);
  const [thereIsEmergenza, setThereIsEmergenza] = useState(false);
  const [thereIsPrimoSocc, setThereIsPrimoSocc] = useState(false);
  const [thereIsDirigente, setThereIsDirigente] = useState(false);

  const exportPDFMansionario = (imgOrg) => {
    let doc = new jsPDF({
      orientation: "p",
      unit: "pt",
      compressPdf: true,
      format: "A4",
    });

    var imgLogo = props.logo;
    var imgDef =
      imgLogo === ""
        ? require("../assets/images/MILLERLogo-copia.jpg")
        : imgLogo;
    var dataCorrente = new Date();
    dataCorrente = moment(dataCorrente).format("DD/MM/YYYY");

    //AGGIUNGO I FONT CHE HO IMPORTATO
    doc.addFileToVFS("verdana-normal.ttf", fontVerdanaNormal);
    doc.addFileToVFS("verdana-bold.ttf", fontVerdanaBold);
    doc.addFont("verdana-normal.ttf", "verdana", "normal");
    doc.addFont("verdana-bold.ttf", "verdana", "bold");

    setOpenPDF(true);

    doc.setTextColor(50, 62, 79);

    doc.setDrawColor(17, 82, 111);
    doc.setLineWidth(1);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(32, 8, 130, 77, 8, 8); // Black sqaure with rounded corners
    doc.addImage(imgDef, "PNG", 37, 13, 120, 68); //da riguardare se così o al contrario

    doc.roundedRect(172, 8, 280, 77, 8, 8);
    doc.setFontSize(12);
    doc.setFont("verdana", "bold");

    doc.text(
      "MANSIONARIO SICUREZZA",
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth("MANSIONARIO SICUREZZA") *
          doc.internal.getFontSize()) /
          2,
      50
    );

    doc.setFontSize(10);
    doc.text(
      "Rev.: 00",
      500 +
        15 -
        (doc.getStringUnitWidth("Rev.: 00") * doc.internal.getFontSize()) / 2,
      45
    );
    doc.roundedRect(462, 8, 100, 77, 8, 8);

    doc.text(
      dataCorrente,
      500 +
        15 -
        (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2,
      59
    );

    /* INIZIO TESTO */
    doc.setFontSize(10);
    doc.text(
      "Ruoli e responsabilità",
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth("Ruoli e responsabilità") *
          doc.internal.getFontSize()) /
          2,
      110
    );

    doc.setFontSize(9);
    doc.text(
      doc.splitTextToSize(
        `Il datore di lavoro:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      130
    );

    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        `Il soggetto titolare del rapporto di lavoro con il lavoratore o, comunque, il soggetto che, secondo il tipo e l'assetto dell'organizzazione nel cui ambito il lavoratore presta la propria attività, ha la responsabilità dell'organizzazione stessa o dell'unità produttiva in quanto esercita i poteri decisionali e di spesa.`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      140
    );
    doc.text(
      doc.splitTextToSize(
        `I principali obblighi del datore di lavoro sono:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      180
    );

    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      190
    );
    doc.text(
      doc.splitTextToSize(
        "la valutazione di tutti i rischi e conseguente elaborazione del documento (questo obbligo non è delegabile);",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      190
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      200
    );
    doc.text(
      doc.splitTextToSize(
        "designare il responsabile del servizio di prevenzione e protezione dai rischi (questo obbligo non è delegabile); ",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      200
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      210
    );
    doc.text(
      doc.splitTextToSize(
        "nominare il medico competente per l’effettuazione della sorveglianza sanitaria per i casi previsti dal D.Lgs 81/08 e qualora richiesto dalla valutazione dei rischi; ",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      210
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      230
    );
    doc.text(
      doc.splitTextToSize(
        "designare preventivamente i lavoratori incaricati dell’attuazione delle misure di prevenzione incendi e lotta antincendio, di evacuazione dei luoghi di lavoro in caso di pericolo grave e immediato, di salvataggio, di primo soccorso e, comunque, di gestione delle emergenze; ",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      230
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      260
    );
    doc.text(
      doc.splitTextToSize(
        "individuare il/i preposto/i per l’effettuazione delle attività di vigilanza di cui al D.Lgs 81/08 art. 19;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      260
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      270
    );
    doc.text(
      doc.splitTextToSize(
        "fornire ai lavoratori i necessari e idonei dispositivi di protezione individuale, sentito il servizio di prevenzione e protezione ed il medico competente, ove presente; ",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      270
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      290
    );
    doc.text(
      doc.splitTextToSize(
        "prendere le misure appropriate affinché soltanto i lavoratori che hanno ricevuto adeguate istruzioni e specifico addestramento accedano alle zone che li espongono ad un rischio grave e specifico;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      290
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      310
    );
    doc.text(
      doc.splitTextToSize(
        "richiedere l’osservanza da parte dei singoli lavoratori delle norme vigenti nonché delle disposizioni aziendali in materia di sicurezza e di igiene del lavoro e di uso dei mezzi di protezione collettivi e dei dispositivi di protezione individuali messi a loro disposizione;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      310
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      340
    );
    doc.text(
      doc.splitTextToSize(
        "inviare i lavoratori alla visita medica entro le scadenze previste dal programma di sorveglianza sanitaria e richiedere al medico competente l’osservanza degli obblighi previsti a suo carico nel presente decreto;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      340
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      360
    );
    doc.text(
      doc.splitTextToSize(
        "adottare le misure per il controllo delle situazioni di rischio in caso di emergenza e dare istruzioni affinché i lavoratori, in caso di pericolo grave, immediato ed inevitabile, abbandonino il posto di lavoro o la zona pericolosa; ",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      360
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      390
    );
    doc.text(
      doc.splitTextToSize(
        "consentire ai lavoratori di verificare, mediante il rappresentante dei lavoratori per la sicurezza, l’applicazione delle misure di sicurezza e di protezione della salute;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      390
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      410
    );
    doc.text(
      doc.splitTextToSize(
        "Elaborare il DUVRI;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      410
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      420
    );
    doc.text(
      doc.splitTextToSize(
        "informare il più presto possibile i lavoratori esposti a rischi gravi; ",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      420
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      430
    );
    doc.text(
      doc.splitTextToSize(
        "adempiere agli obblighi di informazione, formazione e addestramento;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      430
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      440
    );
    doc.text(
      doc.splitTextToSize(
        "adottare le misure necessarie ai fini della prevenzione incendi e dell’evacuazione dei luoghi di lavoro;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      440
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      450
    );
    doc.text(
      doc.splitTextToSize(
        "vigilare in ordine all’adempimento degli obblighi;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      450
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      460
    );
    doc.text(
      doc.splitTextToSize(
        "nelle unità produttive con più di 15 lavoratori, convocare la riunione periodica;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      460
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      470
    );
    doc.text(
      doc.splitTextToSize(
        "aggiornare le misure di prevenzione in relazione ai mutamenti organizzativi e produttivi che hanno rilevanza ai fini della salute e sicurezza del lavoro, o in relazione al grado di evoluzione della tecnica della prevenzione e della protezione;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      470
    );

    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        "Il responsabile del servizio di prevenzione e protezione (RSPP): ",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      510
    );
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        "Figura interna o esterna all’azienda scelta dal Datore di Lavoro, previa consultazione del Rappresentante dei Lavoratori per la Sicurezza. Il RSPP deve possedere capacità ed i requisiti professionali adeguati alla natura dei rischi presenti sul luogo di lavoro e relativi alle attività lavorative. Il RSPP risponde al Datore di lavoro per coordinare il servizio di prevenzione e protezione.",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      520
    );
    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        "L’addetto del servizio prevenzione e protezione (ASPP): ",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      570
    );
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        "Figura interna o esterna all’azienda scelta come membro del servizio di prevenzione e protezione. L’ASPP deve possedere capacità ed i requisiti professionali adeguati alla natura dei rischi presenti sul luogo di lavoro e relativi alle attività lavorative. L’ASPP è coordinato dal Responsabile del Servizio di Prevenzione e Protezione.",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      580
    );
    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        "Il medico competente: ",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      620
    );
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        "Medico in possesso di specifici titoli e requisiti formativi e professionali, che collabora con il datore di lavoro ai fini della valutazione dei rischi ed è nominato dallo stesso per effettuare la sorveglianza sanitaria e per tutti gli altri compiti ad esso riferiti all’interno del D.Lgs 81/08.",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      630
    );

    doc.text(
      doc.splitTextToSize(
        "I principali obblighi del Medico Competente sono:",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      670
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      685
    );
    doc.text(
      doc.splitTextToSize(
        "Collabora con il datore di lavoro e con il servizio di prevenzione e protezione alla valutazione dei rischi, anche ai fini della programmazione, ove necessario, della sorveglianza sanitaria, alla predisposizione della attuazione delle misure per la tutela della salute e della integrità psico-fisica dei lavoratori, all’attività di formazione e informazione nei confronti dei lavoratori, per la parte di competenza, e alla organizzazione del servizio di primo soccorso considerando i particolari tipi di lavorazione ed esposizione e le peculiari modalità organizzative del lavoro.",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      685
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      745
    );
    doc.text(
      doc.splitTextToSize(
        "Programma ed effettua la sorveglianza sanitaria attraverso protocolli sanitari definiti in funzione dei rischi specifici e tenendo in considerazione gli indirizzi scientifici più avanzati;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      745
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      765
    );
    doc.text(
      doc.splitTextToSize(
        "istituisce, aggiorna e custodisce, sotto la propria responsabilità, una cartella sanitaria e di rischio per ogni lavoratore sottoposto a sorveglianza sanitaria; tale cartella è conservata con salvaguardia del segreto professionale e, salvo il tempo strettamente necessario per l’esecuzione della sorveglianza sanitaria e la trascrizione dei relativi risultati, presso il luogo di custodia concordato al momento della nomina del medico competente;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      765
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      815
    );
    doc.text(
      doc.splitTextToSize(
        "consegna al datore di lavoro, alla cessazione dell’incarico, la documentazione sanitaria in suo possesso;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      815
    );
    doc.addPage();

    doc.roundedRect(32, 8, 130, 77, 8, 8); // Black sqaure with rounded corners
    doc.addImage(imgDef, "PNG", 37, 13, 120, 68); //da riguardare se così o al contrario

    doc.roundedRect(172, 8, 280, 77, 8, 8);
    doc.setFontSize(12);
    doc.setFont("verdana", "bold");

    doc.text(
      "MANSIONARIO SICUREZZA",
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth("MANSIONARIO SICUREZZA") *
          doc.internal.getFontSize()) /
          2,
      50
    );

    doc.setFontSize(10);
    doc.text(
      "Rev.: 00",
      500 +
        15 -
        (doc.getStringUnitWidth("Rev.: 00") * doc.internal.getFontSize()) / 2,
      45
    );
    doc.roundedRect(462, 8, 100, 77, 8, 8);

    doc.text(
      dataCorrente,
      500 +
        15 -
        (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2,
      59
    );
    doc.setFontSize(9);
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      105
    );
    doc.text(
      doc.splitTextToSize(
        "in occasione delle visite di assunzione, richiede al lavoratore la cartella sanitaria rilasciata dal precedente datore di lavoro e tiene conto del suo contenuto ai fini della formulazione del giudizio di idoneità;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      105
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      125
    );
    doc.text(
      doc.splitTextToSize(
        "fornisce informazioni ai lavoratori sul significato della sorveglianza sanitaria cui sono sottoposti e, nel caso di esposizione ad agenti con effetti a lungo termine, sulla necessità di sottoporsi ad accertamenti sanitari anche dopo la cessazione dell’attività che comporta l’esposizione a tali agenti.",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      125
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      155
    );
    doc.text(
      doc.splitTextToSize(
        "informa ogni lavoratore interessato dei risultati della sorveglianza sanitaria di cui all’articolo 41 e, a richiesta dello stesso, gli rilascia copia della documentazione sanitaria;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      155
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      175
    );
    doc.text(
      doc.splitTextToSize(
        "comunica per iscritto, in occasione delle riunioni di cui all’articolo 35 del D.Lgs 81/08, al datore di lavoro, al responsabile del servizio di prevenzione protezione dai rischi, ai rappresentanti dei lavoratori per la sicurezza, i risultati anonimi collettivi della sorveglianza sanitaria effettuata e fornisce indicazioni sul significato di detti risultati ai fini della attuazione delle misure per la tutela della salute e della integrità psico-fisica dei lavoratori; ",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      175
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      215
    );
    doc.text(
      doc.splitTextToSize(
        "visita gli ambienti di lavoro almeno una volta all’anno o a cadenza diversa che stabilisce in base alla valutazione dei rischi;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      215
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      235
    );
    doc.text(
      doc.splitTextToSize(
        "in caso di impedimento per gravi e motivate ragioni, comunica per iscritto al datore di lavoro il nominativo di un sostituto, in possesso dei requisiti di cui all’articolo 38 del D.Lgs 81/08, per l’adempimento degli obblighi di legge durante il relativo intervallo temporale specificato",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      235
    );

    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        "Il medico competente coordinato:",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      275
    );
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        "Secondo quanto previsto dall’art. 39 comma 6 del D.Lgs 81/08 “Nei casi di aziende con più unità produttive, nei casi di gruppi d'imprese nonché qualora la valutazione dei rischi ne evidenzi la necessità, il datore di lavoro può nominare più medici competenti individuando tra essi un medico con funzioni di coordinamento.” In tal caso il medico con funzioni di coordinamento sarà il “medico competente coordinatore”, mentre gli altri medici verranno nominati “medici competente coordinati”.",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      285
    );

    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        "Il dirigente",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      345
    );
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        "Persona che, in ragione delle competenze professionali e di poteri gerarchici e funzionali adeguati alla natura dell’incarico conferitogli, attua le direttive del datore di lavoro organizzando l’attività lavorativa e vigilando su di essa; ",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      355
    );

    doc.text(
      doc.splitTextToSize(
        "I principali obblighi del dirigente coincidono con quelli delegabili del datore di lavoro in funzione di eventuali deleghe o incarichi ricevuti.",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      385
    );

    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize("Il preposto", doc.internal.pageSize.getWidth() - 80),
      33,
      415
    );
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        "Persona che, in ragione delle competenze professionali e nei limiti di poteri gerarchici e funzionali adeguati alla natura dell’incarico conferitogli, sovrintende alla attività lavorativa e garantisce l’attuazione delle direttive ricevute, controllandone la corretta esecuzione da parte dei lavoratori ed esercitando un funzionale potere di iniziativa;",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      425
    );

    doc.text(
      doc.splitTextToSize(
        "I principali obblighi del preposto sono:",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      475
    );

    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      490
    );
    doc.text(
      doc.splitTextToSize(
        "Sovrintendere e vigilare sulla osservanza da parte dei singoli lavoratori dei loro obblighi di legge, nonché delle disposizioni aziendali in materia di salute e sicurezza sul lavoro e di uso dei mezzi di protezione collettivi e dei dispositivi di protezione individuale messi a loro disposizione e, in caso di rilevazione di comportamenti non conformi alle disposizioni e istruzioni impartite dal datore di lavoro e dirigenti ai fini della protezione collettiva e individuale, intervenire per modificare il comportamento non conforme fornendo le necessarie indicazioni di sicurezza. In caso di mancata attuazione delle disposizioni impartite o di persistenza della inosservanza, interrompere l'attività del lavoratore e informare i superiori diretti; ",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      490
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      560
    );
    doc.text(
      doc.splitTextToSize(
        "verificare affinché soltanto i lavoratori che hanno ricevuto adeguate istruzioni accedano alle zone che li espongono ad un rischio grave e specifico;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      560
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      580
    );
    doc.text(
      doc.splitTextToSize(
        "richiedere l’osservanza delle misure per il controllo delle situazioni di rischio in caso di emergenza e dare istruzioni affinché i lavoratori, in caso di pericolo grave, immediato e inevitabile, abbandonino il posto di lavoro o la zona pericolosa;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      580
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      610
    );
    doc.text(
      doc.splitTextToSize(
        "informare il più presto possibile i lavoratori esposti al rischio di un pericolo grave e immediato circa il rischio stesso e le disposizioni prese o da prendere in materia di protezione;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      610
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      630
    );
    doc.text(
      doc.splitTextToSize(
        "astenersi, salvo eccezioni debitamente motivate, dal richiedere ai lavoratori di riprendere la loro attività in una situazione di lavoro in cui persiste un pericolo grave ed immediato;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      630
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      650
    );
    doc.text(
      doc.splitTextToSize(
        "segnalare tempestivamente al datore di lavoro o al dirigente sia le deficienze dei mezzi e delle attrezzature di lavoro e dei dispositivi di protezione individuale, sia ogni altra condizione di pericolo che si verifichi durante il lavoro, delle quali venga a conoscenza sulla base della formazione ricevuta;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      650
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      680
    );
    doc.text(
      doc.splitTextToSize(
        "in caso di rilevazione di deficienze dei mezzi e delle attrezzature di lavoro e di ogni condizione di pericolo rilevata durante la vigilanza, se necessario, interrompere temporaneamente l'attività e, comunque, segnalare tempestivamente al datore di lavoro e al dirigente le non conformità rilevate;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      680
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      710
    );
    doc.text(
      doc.splitTextToSize(
        "frequentare appositi corsi di formazione secondo quanto previsto dall’articolo 37.",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      710
    );

    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        "Rappresentante dei Lavoratori per la Sicurezza (RLS): ",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      730
    );
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        "Persona eletta o designata per rappresentare i lavoratori per quanto concerne gli aspetti della salute e della sicurezza durante il lavoro.",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      740
    );
    doc.text(
      doc.splitTextToSize(
        "Il Rappresentante dei Lavoratori per la Sicurezza:",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      765
    );

    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      780
    );
    doc.text(
      doc.splitTextToSize(
        "Accede ai luoghi di lavoro in cui si svolgono le lavorazioni;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      780
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      790
    );
    doc.text(
      doc.splitTextToSize(
        "è consultato preventivamente e tempestivamente in ordine alla valutazione dei rischi, alla individuazione, programmazione, realizzazione e verifica della prevenzione nella azienda o unità produttiva;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      790
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      810
    );
    doc.text(
      doc.splitTextToSize(
        "è consultato sulla designazione del responsabile e degli addetti al servizio di prevenzione, alla attività di prevenzione incendi, al primo soccorso, alla evacuazione dei luoghi di lavoro e del medico competente;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      810
    );
    doc.addPage();

    doc.roundedRect(32, 8, 130, 77, 8, 8); // Black sqaure with rounded corners
    doc.addImage(imgDef, "PNG", 37, 13, 120, 68); //da riguardare se così o al contrario

    doc.roundedRect(172, 8, 280, 77, 8, 8);
    doc.setFontSize(12);
    doc.setFont("verdana", "bold");

    doc.text(
      "MANSIONARIO SICUREZZA",
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth("MANSIONARIO SICUREZZA") *
          doc.internal.getFontSize()) /
          2,
      50
    );

    doc.setFontSize(10);
    doc.text(
      "Rev.: 00",
      500 +
        15 -
        (doc.getStringUnitWidth("Rev.: 00") * doc.internal.getFontSize()) / 2,
      45
    );
    doc.roundedRect(462, 8, 100, 77, 8, 8);

    doc.text(
      dataCorrente,
      500 +
        15 -
        (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2,
      59
    );
    doc.setFontSize(9);
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      105
    );
    doc.text(
      doc.splitTextToSize(
        "è consultato in merito all’organizzazione della formazione di cui all’articolo 37 del D.Lgs 81/08;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      105
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      115
    );
    doc.text(
      doc.splitTextToSize(
        "riceve le informazioni e la documentazione aziendale inerente alla valutazione dei rischi e le misure di prevenzione relative, nonché quelle inerenti alle sostanze ed alle miscele pericolose, alle macchine, agli impianti, alla organizzazione e agli ambienti di lavoro, agli infortuni ed alle malattie professionali;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      115
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      145
    );
    doc.text(
      doc.splitTextToSize(
        "riceve le informazioni provenienti dai servizi di vigilanza;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      145
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      155
    );
    doc.text(
      doc.splitTextToSize(
        "riceve una formazione adeguata e, comunque, non inferiore a quella prevista dall’articolo 37 del D.Lgs 81/08;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      155
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      165
    );
    doc.text(
      doc.splitTextToSize(
        "promuove l’elaborazione, l’individuazione e l’attuazione delle misure di prevenzione idonee a tutelare la salute e l’integrità fisica dei lavoratori;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      165
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      185
    );
    doc.text(
      doc.splitTextToSize(
        "formula osservazioni in occasione di visite e verifiche effettuate dalle autorità competenti, dalle quali è, di norma, sentito;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      185
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      205
    );
    doc.text(
      doc.splitTextToSize(
        "partecipa alla riunione periodica di cui all’articolo 35 del D.Lgs 81/08;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      205
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      215
    );
    doc.text(
      doc.splitTextToSize(
        "fa proposte in merito alla attività di prevenzione;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      215
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      225
    );
    doc.text(
      doc.splitTextToSize(
        "avverte il responsabile della azienda dei rischi individuati nel corso della sua attività;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      225
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      235
    );
    doc.text(
      doc.splitTextToSize(
        "può fare ricorso alle autorità competenti qualora ritenga che le misure di prevenzione e protezione dai rischi adottate dal datore di lavoro o dai dirigenti e i mezzi impiegati per attuarle non siano idonei a garantire la sicurezza e la salute durante il lavoro.",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      235
    );
    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        "Lavoratori incaricati della gestione delle emergenze:",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      275
    );
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        "lavoratori “addetti alle emergenze”, sostanzialmente suddivisi in due categorie:",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      285
    );

    doc.text(
      doc.splitTextToSize(" - ", doc.internal.pageSize.getWidth() - 80),
      45,
      295
    );
    doc.text(
      doc.splitTextToSize(
        "Addetti alla lotta antincendio, evacuazione ed emergenza in genere;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      295
    );
    doc.text(
      doc.splitTextToSize(" - ", doc.internal.pageSize.getWidth() - 80),
      45,
      305
    );
    doc.text(
      doc.splitTextToSize(
        "Addetti al primo soccorso.",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      305
    );
    doc.text(
      doc.splitTextToSize(
        "I lavoratori nominati non possono rifiutare l’incarico, se non per giustificato motivo, e devono partecipare a uno specifico corso di formazione in modo da acquisire un sufficiente bagaglio di conoscenze, teoriche e pratiche, per poter efficacemente intervenire in caso di emergenza, incendio e primo soccorso.",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      325
    );
    doc.setFont("verdana", "bold");
    doc.text(
      doc.splitTextToSize(
        "Il lavoratore: ",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      365
    );
    doc.setFont("verdana", "normal");
    doc.text(
      doc.splitTextToSize(
        "Persona che, indipendentemente dalla tipologia contrattuale, svolge un'attività lavorativa nell'ambito dell'organizzazione di un datore di lavoro pubblico o privato, con o senza retribuzione, anche al solo fine di apprendere un mestiere, un'arte o una professione, esclusi gli addetti ai servizi domestici e familiari. ",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      375
    );
    doc.text(
      doc.splitTextToSize(
        "Principali obblighi del lavoratore:",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      415
    );
    doc.text(
      doc.splitTextToSize(
        "Ogni lavoratore deve prendersi cura della propria sicurezza e della propria salute e di quella delle altre persone presenti sul luogo di lavoro, su cui possono ricadere gli effetti delle sue azioni o omissioni, conformemente alla sua formazione e alle istruzioni e ai mezzi forniti dal datore di lavoro.",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      425
    );
    doc.text(
      doc.splitTextToSize(
        "In particolare i lavoratori:",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      465
    );

    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      480
    );
    doc.text(
      doc.splitTextToSize(
        "Contribuiscono, insieme al datore di lavoro, ai dirigenti e ai preposti, all’adempimento degli obblighi previsti a tutela della salute e sicurezza sui luoghi di lavoro;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      480
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      500
    );
    doc.text(
      doc.splitTextToSize(
        "Osservano le disposizioni e le istruzioni impartite dal datore di lavoro, dai dirigenti, e dai preposti ai fini della protezione collettiva ed individuale;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      500
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      520
    );
    doc.text(
      doc.splitTextToSize(
        "Utilizzano correttamente i macchinari, le apparecchiature, gli utensili, le sostanze e i preparati pericolosi, i mezzi di trasporto e le altre attrezzature di lavoro, nonché i dispositivi di sicurezza;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      520
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      540
    );
    doc.text(
      doc.splitTextToSize(
        "Utilizzano in modo appropriato i dispositivi di protezione messi a loro disposizione; ",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      540
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      550
    );
    doc.text(
      doc.splitTextToSize(
        "Segnalano immediatamente al datore di lavoro, al dirigente o al preposto le deficienze dei mezzi e dispositivi di cui alle lettere c) e d), nonché le altre eventuali condizioni di pericolo di cui vengono a conoscenza, adoperandosi direttamente, in caso di urgenza, nell’ambito delle loro competenze e possibilità, per eliminare o ridurre tali deficienze o pericoli, dandone notizia al rappresentante dei lavoratori per la sicurezza;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      550
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      590
    );
    doc.text(
      doc.splitTextToSize(
        "Non rimuovono o modificano senza autorizzazione i dispositivi di sicurezza o di segnalazione o di controllo;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      590
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      600
    );
    doc.text(
      doc.splitTextToSize(
        "Non compiono di propria iniziativa operazioni o manovre che non sono di loro competenza ovvero che possono compromettere la sicurezza propria o di altri lavoratori;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      600
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      620
    );
    doc.text(
      doc.splitTextToSize(
        "Partecipano ai programmi di formazione e addestramento organizzati dal datore di lavoro;",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      620
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      630
    );
    doc.text(
      doc.splitTextToSize(
        "Si sottopongono ai controlli sanitari previsti nei loro confronti o comunque disposti dal medico competente; ",
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      630
    );
    //PAGINA ORIZZONTALE
    doc.addPage(null, "l");
    doc.setDrawColor(17, 82, 111);
    doc.setLineWidth(1);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(152, 8, 130, 77, 8, 8); // Black sqaure with rounded corners
    doc.addImage(imgDef, "PNG", 157, 13, 120, 68);
    //doc.addImage(imgDef, "PNG", 37, 13, 120, 68);

    doc.roundedRect(292, 8, 280, 77, 8, 8);
    doc.setFontSize(12);
    doc.setFont("verdana", "bold");

    doc.text(
      "MANSIONARIO SICUREZZA",
      doc.internal.pageSize.width / 2 +
        15 -
        (doc.getStringUnitWidth("MANSIONARIO SICUREZZA") *
          doc.internal.getFontSize()) /
          2,
      50
    );

    doc.setFontSize(10);
    doc.text(
      "Rev.: 00",
      620 +
        15 -
        (doc.getStringUnitWidth("Rev.: 00") * doc.internal.getFontSize()) / 2,
      45
    );
    doc.roundedRect(582, 8, 100, 77, 8, 8);

    doc.text(
      dataCorrente,
      620 +
        15 -
        (doc.getStringUnitWidth(dataCorrente) * doc.internal.getFontSize()) / 2,
      59
    );
    var imgDef = imgOrg;
    doc.addImage(
      imgDef,
      "PNG",
      20,
      100,
      doc.internal.pageSize.getWidth() - 40,
      doc.internal.pageSize.getHeight() - 150
    );

    doc.output("pdfobjectnewwindow", "attestato.pdf");
  };

  const handlePrintOrganigramma = async () => {
    document.getElementById("OrgContainer").style.display = "Block";
    await htmlToImage
      .toPng(document.getElementById("OrgContainer"))
      .then((dataUrl) => {
        exportPDFMansionario(dataUrl);
        document.getElementById("OrgContainer").style.display = "None";
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  const handleClose = () => {
    setOpen(false);
    setThereIsDirigente(false);
    setThereIsPreposto(false);
  };

  const handleClickOpen = () => {
    // 1 = Datore di lavoro
    // 2 = RSPP
    // 3 = ASPP
    // 4 = RLS
    // 5 = Dirigente
    // 6 = Preposto
    // 7 = Addetto emergenza
    // 8 = Primo soccorso

    let dlFind = props.organigramma.filter((el) => el.IDRuolo === 1);
    let rsppFind = props.organigramma.filter((el) => el.IDRuolo === 2);
    let asppFind = props.organigramma.filter((el) => el.IDRuolo === 3);
    let rlsFind = props.organigramma.filter((el) => el.IDRuolo === 4);
    let dirFind = props.organigramma.filter((el) => el.IDRuolo === 5);
    let preFind = props.organigramma.filter((el) => el.IDRuolo === 6);

    setThereIsPreposto(false);
    setThereIsEmergenza(false);
    setThereIsPrimoSocc(false);
    setThereIsDirigente(false);

    if (rsppFind.length === 0) {
      props.setSnack({ severity: "error", messaggio: "Aggiungi RSPP" });
      props.setOpenSnack(true);
    } else if (dlFind.length === 0) {
      props.setSnack({
        severity: "error",
        messaggio: "Aggiungi Datore di Lavoro",
      });
      props.setOpenSnack(true);
    } else if (rlsFind.length === 0) {
      props.setSnack({
        severity: "error",
        messaggio: "Aggiungi RLS",
      });
      props.setOpenSnack(true);
    } else {
      //se tutti i ruoli obbligatori ci sono procedo con la creazione del grafico
      let medFind = props.medici.filter((el) => el.MedicoCoordinatore === 1);
      if (medFind.length > 0) {
        //c'è coordinatore
        setMedicoCoordinatore(medFind[0].Descrizione);
        setMedici(props.medici.filter((el) => el.ID !== medFind[0].ID));
      } else {
        //non c'è
        setMedici(props.medici);
      }
      setDirigenti(dirFind);
      setPreposti(preFind);
      const addettiPrimoSoccorso = props.organigramma.filter(
        (el) => el.IDRuolo === 8
      );
      setPrimoSoccorso(addettiPrimoSoccorso);
      if (addettiPrimoSoccorso.length > 0) {
        setThereIsPrimoSocc(true);
      }
      const addettiEmergenza = props.organigramma.filter(
        (el) => el.IDRuolo === 7
      );
      setAddettiEmergenza(addettiEmergenza);
      if (addettiEmergenza.length > 0) {
        setThereIsEmergenza(true);
      }
      setDatoreDiLavoro(dlFind[0]?.Nominativo);
      setAspp(asppFind[0]?.Nominativo);
      setRspp(rsppFind[0]?.Nominativo);
      const rlsList = props.organigramma.filter((el) => el.IDRuolo === 4);
      setRls(rlsList);

      setOpen(true);
      setLoading(true);
      renderPreposto(dirFind, preFind);
    }
  };

  const renderPreposto = (dir, pre) => {
    if (dir.length === 0 && pre.length > 0) {
      // non c'è dirigente
      setThereIsPreposto(true);
    } else if (dir.length > 0 && pre.length > 0) {
      // ci sono entrambi
      setThereIsPreposto(true);
      setThereIsDirigente(true);
    } else if (dir.length === 0 && pre.length === 0) {
      //non ci sono entrambi
      setThereIsPreposto(false);
      setThereIsDirigente(false);
    } else {
      //non c'è preposto
      setThereIsDirigente(true);
    }
    setTimeout(() => {
      showImage();
      setLoading(false);
    }, 500);
  };

  const showImage = async () => {
    document.getElementById("OrgContainer").style.display = "block";
    await htmlToImage
      .toPng(document.getElementById("OrgContainer"))
      .then((dataUrl) => {
        var img = new Image();
        img.src = dataUrl;
        img.style.height = "calc(100% - 64px)";
        img.style.width = "calc(100% - 64px)";
        img.style.margin = "auto";
        document.getElementById("imgOrg").appendChild(img);
        document.getElementById("OrgContainer").style.display = "none";
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };
  function cap(arr) {
    if (arr) {
      for (var i = 0; i < arr.length; i++) {
        arr[i] =
          arr[i].charAt(0).toUpperCase() + arr[i].substring(1).toLowerCase();
      }
      return arr.join(" ");
    }
  }

  return (
    <>
      <Button
        className="ButtonMiller"
        size="small"
        variant="contained"
        endIcon={<AccountTree />}
        onClick={handleClickOpen}
      >
        Grafico
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xl"
        TransitionComponent={Transition}
      >
        <DialogTitle id="alert-dialog-title">Organigramma</DialogTitle>
        <DialogContent>
          <div
            id="imgOrg"
            style={{
              height: "65vh",
              maxHeight: "calc(100% - 64px)",
              maxWidth: "1536px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <center style={{ display: loading ? "block" : "none" }}>
              <CircularProgress
                sx={{
                  //marginLeft: "6px",
                  color: "#2265B1",
                }}
              />
            </center>
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mx: 2,
          }}
        >
          <Button
            onClick={handleClose}
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
          >
            annulla
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Print />}
            onClick={handlePrintOrganigramma}
          >
            Stampa
          </Button>
        </DialogActions>
      </Dialog>

      <div id="OrgContainer" style={{ display: "none" }}>
        {/* PARTE CHE SERVE PER GENERARE l'Immagine */}
        <Tree
          lineWidth={"2px"}
          lineColor={"rgb(17, 82, 111)"}
          label={
            <div className="w-fit mx-auto border-2 border-[#11526f] p-2 rounded-lg">
              <span className="text-lg font-semibold">DATORE DI LAVORO</span>
              <br /> {cap(datoreDiLavoro?.split(" "))}
            </div>
          }
          className="tree-box"
        >
          {
            //#region Colonna sinistra
          }

          {aspp && aspp && (rspp?.length !== 0) != "non presente" ? (
            <TreeNode
              label={
                <div className="tree-box">
                  <span className="title-box">RSPP</span>
                  <br />
                  <span className="text-sm">{cap(rspp?.split(" "))}</span>
                </div>
              }
            >
              <TreeNode
                label={
                  <div className="tree-box">
                    <span className="title-box">ASPP</span>
                    <br />{" "}
                    <span className="text-sm">{cap(aspp?.split(" "))}</span>
                  </div>
                }
              />
            </TreeNode>
          ) : (
            <TreeNode
              label={
                <div className="tree-box">
                  <span className="title-box">RSPP</span>
                  <br />{" "}
                  <span className="text-sm">{cap(rspp?.split(" "))}</span>
                </div>
              }
            />
          )}
          {
            //#endregion
          }
          {
            //#region Colonna centrale che inserisco dentro tanti TreeNode così da creare più spazio verticalmente
          }
          <TreeNode label={<div className="hidden"></div>}>
            <TreeNode label={<div className="hidden"></div>}>
              <TreeNode label={<div className="hidden"></div>}>
                <TreeNode label={<div className="hidden"></div>}>
                  <TreeNode
                    className={`${
                      !thereIsDirigente && thereIsPreposto
                        ? "-mt-10"
                        : thereIsPreposto
                        ? "mt-10 "
                        : "after:hidden "
                    } `}
                    label={
                      <div
                        className="tree-box"
                        style={{
                          visibility: thereIsPreposto ? "" : "hidden",
                        }}
                      >
                        <span className="title-box">PREPOSTO</span>
                        <br />
                        {preposti.map((el) => (
                          <span className="text-sm">
                            {el.InAttesa === 0
                              ? cap(el?.Nominativo?.split(" "))
                              : "In fase di individuazione"}{" "}
                            <br />
                          </span>
                        ))}
                      </div>
                    }
                  />
                  <TreeNode
                    className={`${
                      thereIsPreposto ? "before:mt-10 " : " before:hidden"
                    } ${thereIsDirigente ? "" : "-mt-20 after:hidden "} `}
                    label={<div className="hidden"></div>}
                  >
                    <TreeNode label={<div className="hidden"></div>}>
                      <TreeNode label={<div className="hidden"></div>}>
                        <TreeNode label={<div className="hidden"></div>}>
                          <TreeNode label={<div className="hidden"></div>}>
                            <TreeNode label={<div className="hidden"></div>}>
                              <TreeNode
                                className={`${"after:hidden -mt-4"}`}
                                label={
                                  <div
                                    className="tree-box"
                                    style={{
                                      visibility: "hidden",
                                    }}
                                  >
                                    <span className="title-box">
                                      RAMO SINISTRO RLS
                                    </span>
                                  </div>
                                }
                              />
                              <TreeNode
                                className={`${"before:hidden"} `}
                                label={<div className="hidden"></div>}
                              >
                                <TreeNode
                                  label={<div className="hidden"></div>}
                                >
                                  <TreeNode
                                    label={<div className="hidden"></div>}
                                  >
                                    <TreeNode
                                      label={<div className="hidden"></div>}
                                    >
                                      <TreeNode
                                        label={<div className="hidden"></div>}
                                      >
                                        <TreeNode
                                          className={`${
                                            !thereIsPrimoSocc &&
                                            thereIsEmergenza
                                              ? "-mt-20"
                                              : thereIsEmergenza
                                              ? " "
                                              : "after:hidden "
                                          } `}
                                          label={
                                            <div
                                              className="tree-box"
                                              style={{
                                                visibility: thereIsEmergenza
                                                  ? ""
                                                  : "hidden",
                                              }}
                                            >
                                              <span className="title-box">
                                                ADDETTI EMERGENZA
                                              </span>
                                              <br />
                                              {addettiEmergenza.map((el) => (
                                                <span className="text-sm">
                                                  {el.InAttesa === 0
                                                    ? cap(
                                                        el.Nominativo.split(" ")
                                                      )
                                                    : "In fase di nomina"}
                                                  <br />
                                                </span>
                                              ))}
                                            </div>
                                          }
                                        />
                                        <TreeNode
                                          className={`${
                                            thereIsEmergenza
                                              ? " "
                                              : " before:hidden"
                                          } ${
                                            thereIsPrimoSocc
                                              ? ""
                                              : "-mt-20 after:hidden "
                                          } `}
                                          label={<div className="hidden"></div>}
                                        >
                                          <TreeNode
                                            label={
                                              <div className="hidden"></div>
                                            }
                                          >
                                            <TreeNode
                                              label={
                                                <div className="hidden"></div>
                                              }
                                            >
                                              <TreeNode
                                                label={
                                                  <div className="hidden"></div>
                                                }
                                              >
                                                <TreeNode
                                                  label={
                                                    <div className="hidden"></div>
                                                  }
                                                >
                                                  <TreeNode
                                                    label={
                                                      <div className="tree-box">
                                                        <span className="title-box">
                                                          LAVORATORI
                                                        </span>
                                                      </div>
                                                    }
                                                  />
                                                </TreeNode>
                                              </TreeNode>
                                            </TreeNode>
                                          </TreeNode>
                                        </TreeNode>
                                        <TreeNode
                                          className={`${
                                            thereIsPrimoSocc
                                              ? ""
                                              : "before:hidden"
                                          }`}
                                          label={
                                            <div
                                              className="tree-box"
                                              style={{
                                                visibility: thereIsPrimoSocc
                                                  ? ""
                                                  : "hidden",
                                              }}
                                            >
                                              <span className="title-box">
                                                ADDETTI PRIMO SOCCORSO
                                              </span>
                                              <br />
                                              {primoSoccorso?.map((el) => (
                                                <span className="text-sm">
                                                  {el.InAttesa === 0
                                                    ? cap(
                                                        el.Nominativo.split(" ")
                                                      )
                                                    : "In fase di nomina"}
                                                  <br />
                                                </span>
                                              ))}
                                            </div>
                                          }
                                        />
                                      </TreeNode>
                                    </TreeNode>
                                  </TreeNode>
                                </TreeNode>
                              </TreeNode>

                              <TreeNode
                                label={
                                  <div className="tree-box">
                                    <span className="title-box">RLS</span>
                                    <br />
                                    {rls?.map((el) => (
                                      <span className="text-sm">
                                        {el?.InAttesa === 0
                                          ? cap(el?.Nominativo.split(" "))
                                          : "In fase di elezione"}
                                        <br />
                                      </span>
                                    ))}
                                  </div>
                                }
                              />
                            </TreeNode>
                          </TreeNode>
                        </TreeNode>
                      </TreeNode>
                    </TreeNode>
                  </TreeNode>
                  <TreeNode
                    className={`${thereIsDirigente ? "" : "before:hidden"}`}
                    label={
                      <div
                        className="tree-box"
                        style={{
                          visibility: thereIsDirigente ? "" : "hidden",
                        }}
                      >
                        <span className="title-box">DIRIGENTE</span>
                        <br />
                        {dirigenti.map((el) => (
                          <span className="text-sm">
                            {el.InAttesa === 0
                              ? cap(el.Nominativo.split(" "))
                              : "In fase di individuazione"}
                            <br />
                          </span>
                        ))}
                      </div>
                    }
                  />
                </TreeNode>
              </TreeNode>
            </TreeNode>
          </TreeNode>
          {
            //#endregion
          }
          {
            //#region Colonna destra
          }

          {medici.length === 0 && medicoCoordinatore !== "" ? (
            <TreeNode
              label={
                <div className="tree-box">
                  <span className="title-box">
                    MEDICO COMPETENTE COORDINATORE
                  </span>
                  <br /> {cap(medicoCoordinatore.split(" "))}
                </div>
              }
            />
          ) : medicoCoordinatore !== "" ? (
            <TreeNode
              label={
                <div className="tree-box">
                  <span className="title-box">
                    MEDICO COMPETENTE COORDINATORE
                  </span>
                  <br /> {cap(medicoCoordinatore.split(" "))}
                </div>
              }
            >
              <TreeNode
                label={
                  <div className="tree-box">
                    <span className="title-box">
                      MEDICO COMPETENTE COORDINATO
                    </span>
                    <br />
                    {medici.map((el) => (
                      <>
                        {cap(el.Descrizione.split(" "))} <br />
                      </>
                    ))}
                  </div>
                }
              />
            </TreeNode>
          ) : (
            <TreeNode
              label={
                <div className="tree-box">
                  <span className="title-box">MEDICO COMPETENTE</span>
                  <br />
                  {medici.map((el) => (
                    <>
                      {cap(el.Descrizione.split(" "))} <br />
                    </>
                  ))}
                </div>
              }
            />
          )}
          {
            //#endregion
          }
        </Tree>
      </div>
    </>
  );
};

export default Organigramma;

const ConvocazioneRiunione = ({ organigramma, cliente }) => {
  const [open, setOpen] = useState(false);
  const [dataPrenotazione, setDataPrenotazione] = useState("");
  const [medici, setMedici] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleClick = async () => {
    setOpen(true);
    let responseMediciCliente = await unsplash.get("medici", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: cliente },
    });

    setMedici(
      responseMediciCliente.data.data.filter(
        (el) => el.MedicoCoordinatore === 1
      )
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const creaConvocazione = () => {
    if (dataPrenotazione.length < 16) {
      setSnack({ severity: "error", messaggio: "Inserisci la data" });
      setOpenSnack(true);
    } else if (medici.length <= 0) {
      setSnack({ severity: "error", messaggio: "Seleziona Medico Competente" });
      setOpenSnack(true);
    } else {
      let datoreDiLavoro = organigramma.filter(
        (l) => l.Ruolo === "Datore Di Lavoro"
      );

      let rls = organigramma.filter((l) => l.Ruolo === "RLS");
      const [date, time] = dataPrenotazione.split("T");
      const formattedTime = time;
      function cap(arr) {
        if (arr) {
          for (var i = 0; i < arr.length; i++) {
            arr[i] =
              arr[i].charAt(0).toUpperCase() +
              arr[i].substring(1).toLowerCase();
          }
          return arr.join(" ");
        }
      }

      if (datoreDiLavoro?.length === 0) {
        setSnack({
          severity: "error",
          messaggio: "Inserisci il Datore di Lavoro",
        });
        setOpenSnack(true);
      } else if (medici?.length === 0) {
        setSnack({ severity: "error", messaggio: "Inserisci i Medici" });
        setOpenSnack(true);
      } else if (rls?.length === 0) {
        setSnack({ severity: "error", messaggio: "Inserisci l'RLS" });
        setOpenSnack(true);
      } else {
        let capDatore = cap(datoreDiLavoro[0]?.Nominativo.split(" "));
        let capMedico = cap(medici[0]?.Descrizione.split(" "));
        let capRls = cap(rls[0]?.Nominativo.split(" "));
        let data_convo = {
          nomeDatore: capDatore,
          nomeMedico: capMedico,
          nomeRappresentante: capRls,
          dataPrenotazione: moment(dataPrenotazione).format("DD/MM/YYYY"),
          oraPrenotazione: formattedTime,
          Cliente: cliente,
          dataRilascio: moment(new Date()).format("DD/MM/YYYY"),
        };
        unsplash
          .get("convocazione", {
            params: {
              data_convo: data_convo,
            },
            responseType: "blob",
          })
          .then((response) => {
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: "application/pdf" })
            );
            window.open(url);
          });
      }
    }
  };

  const handleDataPrenotazioneChange = (event) => {
    setDataPrenotazione(event.target.value);
  };

  return (
    <>
      <Button
        id="convocazione"
        className="ButtonMiller"
        size="small"
        variant="contained"
        onClick={handleClick}
      >
        Convocazione Riunione
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth="sm"
        maxWidth="sm"
        TransitionComponent={Transition}
      >
        <GlobalSnackbar
          severity={snack.severity}
          messaggio={snack.messaggio}
          openSnack={openSnack}
          handleCloseSnack={handleCloseSnack}
        />
        <DialogTitle>Inserisci Data e l'ora della riunione</DialogTitle>
        <DialogContent>
          <TextField
            //
            margin="dense"
            id="dataPrenotazione"
            //label="Data"
            type="datetime-local"
            fullWidth
            variant="standard"
            value={dataPrenotazione}
            onChange={handleDataPrenotazioneChange}
          />
        </DialogContent>
        <DialogActions sx={{ px: 3 }}>
          <Button
            variant="contained"
            className="ButtonMiller"
            onClick={() => handleClose()}
            startIcon={<Close />}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Print />}
            onClick={creaConvocazione}
            style={{ marginLeft: "auto" }}
          >
            Crea Riunione
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const RiunionePeriodica = ({
  organigramma,
  ruoli,
  lavoratori,
  sede,
  cliente,
  loadOrganigramma,
  logo,
}) => {
  const [open, setOpen] = useState(false);
  const [openPDF, setOpenPDF] = useState(false);
  const [dataPrenotazione, setDataPrenotazione] = useState("");
  const [sedi, setSedi] = useState([]);

  //STEPPER
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const [medico, setMedico] = useState({});
  const [delegati, setDelegati] = useState([]);
  const [lavoratoriFormati, setLavoratoriFormati] = useState([]);
  const [lavoratoriFormatiSel, setLavoratoriFormatiSel] = useState([]);
  const [lavoratoriDaFormare, setLavoratoriDaFormare] = useState([]);
  const [lavoratoriDaFormareSel, setLavoratoriDaFormareSel] = useState([]);

  const [step0, setStep0] = useState({
    data: null,
    sedi: "",
    ragioneSociale: "",
    delegato: "",
    datore: false,
    ruolo: "",
    nomeCognome: "",
    testo: "",
  });

  //Step 1
  const [step1, setStep1] = useState({
    annoRishi: 0,
    aggRishi: false,
    testoRishi: "",
    necessRumori: false,
    annoRumori: 0,
    testoRumori: "",
    aggRumori: false,
    necessMMC: false,
    annoMMC: 0,
    testoMMC: "",
    aggMMC: false,
    percheMMC: "",
    maiFattaStress: false,
    annoStress: 0,
    aggStress: false,
    percheStress: "",
    testoStress: "",
  });
  //Step 2
  const [step2, setStep2] = useState({
    annoInfortunei: 0,
    presenzaInfortunei: false,
    quantiInfortunei: 0,
    quantiItinereInfortunei: 0,
    testoInfortunei: "",
    registriQuasiInfortunei: false,
    quantiQuasiInfortunei: 0,
    malattie: false,
    quanteMalattie: 0,
    annoVisite: 0,
    quanteVisite: 0,
    dettagliVisite: "",
  });

  //Step 3
  const [step3, setStep3] = useState({
    dpi: false,
    necessario: false,
  });

  //Step 4
  const [step4, setStep4] = useState({
    annoFormazione: 0,
  });

  //Step 5
  const [step5, setStep5] = useState({
    documentazione: "",
    prova: "",
    organigramma: "",
    ecc: "",
  });
  const [allegati, setAllegati] = useState([
    { id: 1, Frase: "Relazione sanitaria" },
    { id: 2, Frase: "Analisi infortuni" },
  ]);
  const [allegatiSel, setAllegatiSel] = useState([]);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleClick = async () => {
    let responseSedi = await unsplash.get("sedi", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: cliente },
    });

    let responseMediciCliente = await unsplash.get("medici", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: cliente },
    });
    const medici = responseMediciCliente.data.data;
    if (medici.length === 1) {
      setMedico(medici[0]);
    } else {
      setMedico(medici.filter((el) => el.MedicoCoordinatore === 1)[0]);
    }
    setSedi(responseSedi.data.data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function cap(arr) {
    if (arr) {
      for (var i = 0; i < arr.length; i++) {
        arr[i] =
          arr[i].charAt(0).toUpperCase() + arr[i].substring(1).toLowerCase();
      }
      return arr.join(" ");
    }
  }
  const exportPDFRiunionePeriodica = (
    sedeLoc,
    sedeInd,
    sedeCap,
    bloccoSede,
    sedeCF,
    dirigente
  ) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size, true);
    var imgDef = require("../assets/images/MILLERLogo-copia.jpg");
    var dataCorrente = new Date();
    dataCorrente = moment(dataCorrente).format("DD/MM/YYYY");
    var annoCorrente = moment(new Date()).format("YYYY");
    //AGGIUNGO I FONT CHE HO IMPORTATO
    doc.addFileToVFS("verdana-normal.ttf", fontVerdanaNormal);
    doc.addFileToVFS("verdana-bold.ttf", fontVerdanaBold);
    doc.addFont("verdana-normal.ttf", "verdana", "normal");
    doc.addFont("verdana-bold.ttf", "verdana", "bold");

    setOpenPDF(true);

    doc.setTextColor(50, 62, 79);

    /* IMG w=125 h=80 la grandezza dell'immagine deve essere come massimo di 125 di w e 80 di h  */

    doc.setDrawColor(17, 82, 111);
    doc.setLineWidth(1);
    doc.setFillColor(255, 255, 255);

    doc.addImage(
      imgDef,
      "PNG",
      (doc.internal.pageSize.width - 300) / 2,
      13,
      300,
      89
    ); //da riguardare se così o al contrario

    doc.setFontSize(9);
    doc.setFont("verdana", "bold");

    doc.text(
      "VERBALE DI RIUNIONE PERIODICA PER LA PREVENZIONE E PROTEZIONE DAI RISCHI",
      doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth(
          "VERBALE DI RIUNIONE PERIODICA PER LA PREVENZIONE E PROTEZIONE DAI RISCHI"
        ) *
          doc.internal.getFontSize()) /
          2,
      120
    );
    doc.text(
      "(Art. 35 D.Lgs. 81/08)",
      doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth("(Art. 35 D.Lgs. 81/08)") *
          doc.internal.getFontSize()) /
          2,
      132
    );
    doc.setFontSize(10);
    doc.setFont("verdana", "normal");

    let mese = moment(step0.data);
    mese.locale("it");

    doc.text(
      doc.splitTextToSize(
        `L’anno ${moment(step0.data).format("YYYY")} il giorno ${moment(
          step0.data
        ).format("DD")} del mese di ${mese.format("MMMM")} presso l’azienda ${
          step0.ragioneSociale
        } sita in ${
          step0.sedi
        } in applicazione art. 35 D.Lgs. 81/08, convocati nelle forme di legge, sono intervenuti:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      160
    );

    //Lista ruolo: nome e cognome
    if (step0.datore) {
      doc.text(
        doc.splitTextToSize(
          "\u2022 " + "Datore di Lavoro:",
          doc.internal.pageSize.getWidth() - 80
        ),
        45,
        208
      );
      doc.text(
        doc.splitTextToSize(
          cap(
            organigramma
              ?.filter((el) => el.IDRuolo === 1)[0]
              ?.Nominativo?.split(" ")
          ),
          doc.internal.pageSize.getWidth() - 80
        ),
        doc.internal.pageSize.width / 2,
        208
      );
    } else {
      doc.text(
        doc.splitTextToSize(
          "\u2022 " + "Delegato del datore di lavoro:",
          doc.internal.pageSize.getWidth() - 80
        ),
        45,
        208
      );
      doc.text(
        doc.splitTextToSize(
          cap(step0.delegato.split(" ")),
          doc.internal.pageSize.getWidth() - 80
        ),
        doc.internal.pageSize.width / 2,
        208
      );
    }
    doc.text(
      doc.splitTextToSize(
        "\u2022 " + "RSPP:",
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      220
    );
    if (organigramma?.length !== 0) {
      doc.text(
        doc.splitTextToSize(
          cap(
            organigramma
              ?.filter((el) => el.IDRuolo === 2)[0]
              ?.Nominativo.split(" ")
          ),
          doc.internal.pageSize.getWidth() - 80
        ),
        doc.internal.pageSize.width / 2,
        220
      );
    }

    doc.text(
      doc.splitTextToSize(
        "\u2022 " + "RLS:",
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      232
    );

    if (organigramma?.length !== 0) {
      doc.text(
        doc.splitTextToSize(
          cap(
            organigramma
              .filter((el) => el.IDRuolo === 4)[0]
              ?.Nominativo.split(" ")
          ),
          doc.internal.pageSize.getWidth() - 80
        ),
        doc.internal.pageSize.width / 2,
        232
      );
    }
    doc.text(
      doc.splitTextToSize(
        "\u2022 " + "Medico Competente:",
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      244
    );
    doc.text(
      doc.splitTextToSize(
        cap(medico?.Nome.split(" ")) + " " + cap(medico?.Cognome.split(" ")),
        doc.internal.pageSize.getWidth() - 80
      ),
      doc.internal.pageSize.width / 2,
      244
    );
    if (step0.ruolo !== "" && step0.nomeCognome !== "") {
      doc.text(
        doc.splitTextToSize(
          "\u2022 " + step0.ruolo,
          doc.internal.pageSize.getWidth() - 80
        ),
        45,
        256
      );
      doc.text(
        doc.splitTextToSize(
          cap(step0.nomeCognome.split(" ")),
          doc.internal.pageSize.getWidth() - 80
        ),
        doc.internal.pageSize.width / 2,
        256
      );
    }

    doc.text(
      doc.splitTextToSize(
        "Nel corso della riunione sono sottoposti all’esame dei partecipanti:",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      280
    );
    doc.text(
      doc.splitTextToSize("1.", doc.internal.pageSize.getWidth() - 80),
      45,
      296
    );
    doc.text(
      doc.splitTextToSize(
        "Il documento di valutazione dei rischi;",
        doc.internal.pageSize.getWidth() - 80
      ),
      60,
      296
    );
    doc.text(
      doc.splitTextToSize("2.", doc.internal.pageSize.getWidth() - 80),
      45,
      308
    );
    doc.text(
      doc.splitTextToSize(
        "L’andamento degli infortuni e delle malattie professionali e della sorveglianza sanitaria;",
        doc.internal.pageSize.getWidth() - 80
      ),
      60,
      308
    );
    doc.text(
      doc.splitTextToSize("3.", doc.internal.pageSize.getWidth() - 80),
      45,
      320
    );
    doc.text(
      doc.splitTextToSize(
        "I criteri di scelta, le caratteristiche tecniche e l’efficacia dei Dispositivi di Protezione Individuale;",
        doc.internal.pageSize.getWidth() - 80
      ),
      60,
      320
    );
    doc.text(
      doc.splitTextToSize("4.", doc.internal.pageSize.getWidth() - 80),
      45,
      332
    );
    doc.text(
      doc.splitTextToSize(
        "I programmi di informazione e formazione dei dirigenti, dei preposti e dei lavoratori ai fini della sicurezza e della protezione della loro salute.",
        doc.internal.pageSize.getWidth() - 80
      ),
      60,
      332
    );
    doc.text(
      doc.splitTextToSize("5.", doc.internal.pageSize.getWidth() - 80),
      45,
      356
    );
    doc.text(
      doc.splitTextToSize(
        `Varie ed eventuali: ${step0.testo}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      60,
      356
    );

    doc.text(
      doc.splitTextToSize("1.", doc.internal.pageSize.getWidth() - 80),
      33,
      380
    );
    doc.text(
      doc.splitTextToSize(
        `E’ presente il documento di valutazione dei rischi completo di tutti gli allegati inerenti le valutazioni specifiche. A seguito della redazione del documento (anno ${
          step1.annoRishi
        }) ${
          step1.aggRishi
            ? `si provvederà all’aggiornamento del documento a seguito di ${step1.testoRishi}`
            : `non sono intervenute significative modifiche tali per cui si renda necessario un aggiornamento del documento.`
        }`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      380
    );

    if (step1.necessRumori) {
      doc.text(
        doc.splitTextToSize(
          `La valutazione del rischio rumore e vibrazioni sono state effettuate nell’anno ${step1.annoRumori}, dalla valutazione emerge ${step1.testoRumori}.`,
          doc.internal.pageSize.getWidth() - 80
        ),
        45,
        428
        //step1.aggRishi ? 428 : 416
      );
      //LASCIO 2 RIGHRE DI DISTANZA
      doc.text(
        doc.splitTextToSize(
          `Secondo la normativa vigente tale tipologia di valutazione va aggiornata con cadenza quadriennale o ogni qualvolta intervengano modifiche sostanziali che possano modificare l’entità o del rischio. ${
            step1.aggRumori
              ? "È quindi necessario procedere all’aggiornamento di tali valutazioni."
              : "Si ritiene quindi di non procedere all’aggiornamento di queste valutazioni."
          }`,
          doc.internal.pageSize.getWidth() - 80
        ),
        45,
        476
        //step1.aggRishi ? 476 : 464
      );
    }
    if (step1.necessMMC) {
      doc.text(
        doc.splitTextToSize(
          `La valutazione del rischio derivante dalla movimentazione manuale dei carichi è stata effettuata nell’anno ${step1.annoMMC}, dalla valutazione emerge ${step1.testoMMC}.`,
          doc.internal.pageSize.getWidth() - 80
        ),
        45,
        512
        //step1.necessRumori ? 512 : 440
      );
      doc.text(
        doc.splitTextToSize(
          `${
            step1.aggMMC
              ? `Si ritiene necessario aggiornare il documento a seguito delle seguenti modifiche intervenute ${step1.percheMMC}`
              : `A seguito della valutazione non sono intervenute modifiche tali da giustificare un aggiornamento del documento`
          }.`,
          doc.internal.pageSize.getWidth() - 80
        ),
        45,
        560
        //step1.necessRumori ? 560 : 488
      );
    }
    var heightStress = 0;
    if (step1.maiFattaStress) {
      doc.text(
        doc.splitTextToSize(
          `La valutazione del rischio stress lavoro correlato è stata effettuata nel 4${
            step1.annoStress
          }, poiché la normativa prevede un aggiornamento biennale di tale valutazione ${
            step1.aggStress
              ? `si procederà all’aggiornamento della stessa mediante check list Inail. A tal fine vengono individuati due principali gruppi omogenei: ${step1.percheStress}.`
              : `non è necessario aggiornare il documento.`
          }`,
          doc.internal.pageSize.getWidth() - 80
        ),
        45,
        608
        //step1.aggMMC ? 608 : 584
      );
      doc.text(
        doc.splitTextToSize(
          `${step1.testoStress}`,
          doc.internal.pageSize.getWidth() - 80
        ),
        45,
        668
        //step1.aggMMC ? 668 : 644
      );
      heightStress = step1.testoStress === "" ? 0 : 60;
    } else {
      doc.text(
        doc.splitTextToSize(
          `Non è mai stata effettuata la valutazione del rischio stress lavoro correlato. E' quindi necessario procedere all'individuazione dei gruppi omogenei ed elaborare lo specifico documento.`,
          doc.internal.pageSize.getWidth() - 80
        ),
        45,
        608
        //step1.aggMMC ? 608 : 584
      );
    }
    doc.text(
      doc.splitTextToSize("2.", doc.internal.pageSize.getWidth() - 80),
      33,
      696 + heightStress
    );
    doc.text(
      doc.splitTextToSize(
        `Nel corso dell’anno ${step2.annoInfortunei} ${
          step2.presenzaInfortunei
            ? `Si sono verificati ${step2.quantiInfortunei} infortuni di cui ${step2.quantiItinereInfortunei} in itinere ${step2.testoInfortunei}.`
            : `non si sono verificati infortunei`
        }`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      696 + heightStress
    );
    if (step2.registriQuasiInfortunei) {
      doc.text(
        doc.splitTextToSize(
          `Si segnalano ${step2.quantiQuasiInfortunei} quasi infortuni.`,
          doc.internal.pageSize.getWidth() - 80
        ),
        45,
        732 + heightStress
      );
    }
    doc.text(
      doc.splitTextToSize(
        `Le visite mediche e gli esami biologici eseguiti non hanno evidenziato nulla di rilevante tale da poter condizionare la salute, le capacità lavorative o la sicurezza dei lavoratori. ${
          step2.malattie
            ? `Si segnalano ${step2.quanteMalattie} malattie professionali.`
            : `Non si segnalano malattie professionali.`
        } `,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      744 + heightStress
    );

    doc.addPage();
    doc.addImage(
      imgDef,
      "PNG",
      (doc.internal.pageSize.width - 300) / 2,
      13,
      300,
      89
    ); //da riguardare se così o al contrario
    var heightVisiteMediche = 0;
    doc.text(
      doc.splitTextToSize(
        `Nell’anno ${step2.annoVisite} sono state effettuate ${step2.quanteVisite} visite mediche. ${step2.dettagliVisite}`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      120
    );
    heightVisiteMediche = step2.dettagliVisite === "" ? 0 : 40;
    doc.text(
      doc.splitTextToSize("3.", doc.internal.pageSize.getWidth() - 80),
      33,
      140 + heightVisiteMediche
    );
    doc.text(
      doc.splitTextToSize(
        `${
          step3.dpi
            ? `I lavoratori utilizzano i DPI previsti dal Documento di Valutazione dei rischi. ${
                step3.necessario
                  ? `Sono presenti lettere di consegna dei DPI`
                  : `E’ necessario predisporre/aggiornare le lettere di consegna dei DPI.`
              }`
            : `L’attività svolta non prevede l’utilizzo di DPI, così come riportato all’interno del documento di valutazione dei rischi.`
        }`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      140 + heightVisiteMediche
    );
    doc.text(
      doc.splitTextToSize("4.", doc.internal.pageSize.getWidth() - 80),
      33,
      176 + heightVisiteMediche
    );

    doc.text(
      doc.splitTextToSize(
        `Nel corso dell’anno ${step4.annoFormazione} sono stati svolti i seguenti corsi di formazione:`,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      176 + heightVisiteMediche
    );
    let heightFormati = lavoratoriFormatiSel.length * 12 + heightVisiteMediche;
    lavoratoriFormatiSel?.map((element, index) => {
      return doc.text(
        doc.splitTextToSize(
          "\u2022 " + element.corso,
          doc.internal.pageSize.getWidth() - 80
        ),
        57,
        190 + index * 12
      );
    });

    doc.text(
      doc.splitTextToSize(
        `In base alle previsioni normative, si programmano i seguenti corsi di formazione per l’anno ${
          parseInt(step4.annoFormazione) + 1
        }: `,
        doc.internal.pageSize.getWidth() - 80
      ),
      45,
      heightFormati + 202
    );
    let heightDaFormare =
      lavoratoriDaFormareSel.length * 12 + heightFormati + heightVisiteMediche;
    lavoratoriDaFormareSel?.map((element, index) => {
      return doc.text(
        doc.splitTextToSize(
          "\u2022 " + element.corso,
          doc.internal.pageSize.getWidth() - 80
        ),
        57,
        heightFormati + 216 + index * 12
      );
    });

    doc.text(
      doc.splitTextToSize("5.", doc.internal.pageSize.getWidth() - 80),
      33,
      heightDaFormare + 225
    );
    doc.text(
      doc.splitTextToSize("Varie:", doc.internal.pageSize.getWidth() - 80),
      45,
      heightDaFormare + 225
    );

    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      heightDaFormare + 240
    );
    doc.text(
      doc.splitTextToSize(
        step5.documentazione,
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      heightDaFormare + 240
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      heightDaFormare + 264
    );
    doc.text(
      doc.splitTextToSize(step5.prova, doc.internal.pageSize.getWidth() - 80),
      57,
      heightDaFormare + 264
    );
    doc.text(
      doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
      45,
      heightDaFormare + 288
    );
    doc.text(
      doc.splitTextToSize(
        step5.organigramma,
        doc.internal.pageSize.getWidth() - 80
      ),
      57,
      heightDaFormare + 288
    );
    if (step5.ecc !== "") {
      doc.text(
        doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
        45,
        heightDaFormare + 312
      );
      doc.text(
        doc.splitTextToSize(step5.ecc, doc.internal.pageSize.getWidth() - 80),
        57,
        heightDaFormare + 312
      );
    }
    doc.text(
      doc.splitTextToSize("Firme:", doc.internal.pageSize.getWidth() - 80),
      33,
      heightDaFormare + 324
    );
    doc.text(
      doc.splitTextToSize(
        "Il presente verbale viene redatto al termine della riunione condividendone i contenuti con tutti i partecipanti:",
        doc.internal.pageSize.getWidth() - 80
      ),
      33,
      heightDaFormare + 336
    );
    doc.autoTable({
      startY: heightDaFormare + 360,
      html: "#table-firme",
      useCss: true, //IMPORTANTISSIMO
      margin: { top: 40, right: 100, bottom: 60, left: 100 },
      rowPageBreak: "auto",
    });

    doc.text(
      doc.splitTextToSize("Allegati:", doc.internal.pageSize.getWidth() - 80),
      33,
      heightDaFormare + 540
    );

    if (!step0.datore) {
      doc.text(
        doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
        45,
        heightDaFormare + 557
      );
      doc.text(
        doc.splitTextToSize(
          `Delega alla partecipazione da parte del datore di lavoro per il sig. ${step0.delegato}`,
          doc.internal.pageSize.getWidth() - 80
        ),
        57,
        heightDaFormare + 557
      );
    }
    if (allegatiSel.length === 2) {
      doc.text(
        doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
        45,
        heightDaFormare + 569
      );
      doc.text(
        doc.splitTextToSize(
          `Relazione sanitaria annuale redatta dal medico competente`,
          doc.internal.pageSize.getWidth() - 80
        ),
        57,
        heightDaFormare + 569
      );
      doc.text(
        doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
        45,
        heightDaFormare + 581
      );
      doc.text(
        doc.splitTextToSize(
          `Analisi infortuni`,
          doc.internal.pageSize.getWidth() - 80
        ),
        57,
        heightDaFormare + 581
      );
    } else if (allegatiSel.length === 1 && allegatiSel[0].id === 1) {
      doc.text(
        doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
        45,
        heightDaFormare + 569
      );
      doc.text(
        doc.splitTextToSize(
          `Relazione sanitaria annuale redatta dal medico competente`,
          doc.internal.pageSize.getWidth() - 80
        ),
        57,
        heightDaFormare + 569
      );
    } else if (allegatiSel.length === 1 && allegatiSel[0].id === 2) {
      doc.text(
        doc.splitTextToSize("\u2022 ", doc.internal.pageSize.getWidth() - 80),
        45,
        heightDaFormare + 569
      );
      doc.text(
        doc.splitTextToSize(
          `Analisi infortuni`,
          doc.internal.pageSize.getWidth() - 80
        ),
        57,
        heightDaFormare + 569
      );
    }

    doc.output("pdfobjectnewwindow", "attestato.pdf");
  };

  return (
    <>
      <Button
        id="riunione"
        className="ButtonMiller"
        size="small"
        variant="contained"
        onClick={handleClick}
      >
        Riunione Periodica
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth="lg"
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <GlobalSnackbar
          severity={snack.severity}
          messaggio={snack.messaggio}
          openSnack={openSnack}
          handleCloseSnack={handleCloseSnack}
        />
        <DialogTitle>RIUNIONE PERIODICA</DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <Stepper
              nonLinear
              activeStep={activeStep}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps} sx={{}}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <React.Fragment>
              <div style={{ marginTop: "15px", height: "250px" }}>
                {activeStep === 0 ? (
                  <>
                    <Grid container sx={{ mt: 2, mb: 1, overflow: "auto" }}>
                      <Grid sx={{ mt: 2, mb: 1 }} xs={12} md={12}>
                        <TextField
                          label="Data"
                          type="date"
                          fullWidth
                          variant="outlined"
                          value={step0.data}
                          onChange={(e) => {
                            let s = { ...step0 };
                            s.data = e.target.value;
                            setStep0(s);
                          }}
                        />
                      </Grid>
                      {sedi.length > 1 ? (
                        <Grid sx={{ mt: 2, mb: 1 }} xs={12} md={12}>
                          <Autocomplete
                            id="combo-box-demo"
                            getOptionLabel={(option) => option.Descrizione}
                            options={sedi}
                            onChange={(e, newValue) => {
                              let s = { ...step0 };
                              s.sedi =
                                newValue.Via +
                                ", " +
                                newValue.Descrizione +
                                ", " +
                                newValue.Cap;
                              s.ragioneSociale = newValue.RagioneSociale;
                              setStep0(s);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Sedi" />
                            )}
                          />
                        </Grid>
                      ) : (
                        <>
                          {/* Se c'è solo una sede aggiungo i dati automaticamente */}
                        </>
                      )}

                      <Grid sx={{ mt: 2, mb: 1 }} xs={12} md={12}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Datore/Delegato
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            //value={age}
                            label="Datore/Delegato"
                            onChange={(e) => {
                              if (e.target.value === 1) {
                                //controllo organigramma
                                let s = { ...step0 };
                                s.datore = true;
                                setStep0(s);
                              } else if (e.target.value === 2) {
                                let s = { ...step0 };
                                s.datore = false;
                                setStep0(s);
                                setDelegati(lavoratori);
                              }
                            }}
                          >
                            <MenuItem value={0}>Seleziona...</MenuItem>
                            <MenuItem value={1}>Datore di Lavoro</MenuItem>
                            <MenuItem value={2}>
                              Delegato del datore di lavoro
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {delegati.length > 0 ? (
                        <Grid sx={{ mt: 2, mb: 1 }} xs={12} md={12}>
                          <Autocomplete
                            id="combo-box-demo"
                            getOptionLabel={(option) =>
                              option.Nome + " " + option.Cognome
                            }
                            options={delegati}
                            onChange={(e, newValue) => {
                              let s = { ...step0 };
                              s.delegato =
                                newValue.Nome + " " + newValue.Cognome;
                              setStep0(s);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Delegato del datore di lavoro"
                              />
                            )}
                          />
                        </Grid>
                      ) : (
                        <>
                          {/* Se c'è solo una sede aggiungo i dati automaticamente */}
                        </>
                      )}
                      <Grid sx={{ mt: 2, mb: 1 }} xs={12} md={6}>
                        <TextField
                          label="Ruolo"
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={step0.ruolo}
                          onChange={(e) => {
                            let s = { ...step0 };
                            s.ruolo = e.target.value;
                            setStep0(s);
                          }}
                        />
                      </Grid>
                      <Grid sx={{ mt: 2, mb: 1 }} xs={12} md={6}>
                        <TextField
                          label="Nome e Cognome"
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={step0.nomeCognome}
                          onChange={(e) => {
                            let s = { ...step0 };
                            s.nomeCognome = e.target.value;
                            setStep0(s);
                          }}
                        />
                      </Grid>
                      <Grid sx={{ mt: 2, mb: 1 }} xs={12}>
                        <TextField
                          label="Varie ed eventuali"
                          type="text"
                          multiline={4}
                          fullWidth
                          variant="outlined"
                          value={step0.testo}
                          onChange={(e) => {
                            let s = { ...step0 };
                            s.testo = e.target.value;
                            setStep0(s);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : activeStep === 1 ? (
                  <>
                    <Grid container sx={{ mt: 2, mb: 1, overflow: "auto" }}>
                      <Divider
                        sx={{
                          color: "rgba(58, 53, 65, 0.6)",
                          width: "100%",
                          my: 1,
                        }}
                      />
                      <h5>Rischi</h5>
                      <Grid sx={{ mt: 2, mb: 1 }} xs={12}>
                        <TextField
                          label="Anno"
                          type="number"
                          fullWidth
                          variant="outlined"
                          value={step1.annoRishi}
                          onChange={(e) => {
                            let s = { ...step1 };
                            s.annoRishi = e.target.value;
                            setStep1(s);
                          }}
                        />
                      </Grid>
                      <Grid xs={12} md={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={step1.aggRishi}
                              onChange={(e) => {
                                let s = { ...step1 };
                                s.aggRishi = !step1.aggRishi;
                                setStep1(s);
                              }}
                            />
                          }
                          label="Aggiornamento necessario?"
                        />
                      </Grid>
                      {step1.aggRishi ? (
                        <>
                          <Grid sx={{ mb: 1 }} xs={12} md={12}>
                            <TextField
                              label="Perchè?"
                              type="text"
                              fullWidth
                              variant="outlined"
                              value={step1.testoRishi}
                              multiline={2}
                              onChange={(e) => {
                                let s = { ...step1 };
                                s.testoRishi = e.target.value;
                                setStep1(s);
                              }}
                              minRows={2}
                            />
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}

                      <Divider
                        sx={{
                          color: "rgba(58, 53, 65, 0.6)",
                          width: "100%",
                          my: 1,
                        }}
                      />
                      <h5>Rumori e vibrazioni</h5>
                      <Grid sx={{ mt: 2, mb: 1 }} xs={12} md={12}>
                        <Grid xs={12} md={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={step1.necessRumori}
                                onChange={(e) => {
                                  let s = { ...step1 };
                                  s.necessRumori = !step1.necessRumori;
                                  setStep1(s);
                                }}
                              />
                            }
                            label="Necessario?"
                          />
                        </Grid>
                        {step1.necessRumori ? (
                          <>
                            <Grid sx={{ mt: 2, mb: 1 }} xs={12}>
                              <TextField
                                label="Anno"
                                type="number"
                                fullWidth
                                variant="outlined"
                                value={step1.annoRumori}
                                onChange={(e) => {
                                  let s = { ...step1 };
                                  s.annoRumori = e.target.value;
                                  setStep1(s);
                                }}
                              />
                            </Grid>
                            <Grid sx={{ mb: 1 }} xs={12} md={12}>
                              <TextField
                                label="Contenuto valutazione?"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={step1.testoRumori}
                                multiline={2}
                                onChange={(e) => {
                                  let s = { ...step1 };
                                  s.testoRumori = e.target.value;
                                  setStep1(s);
                                }}
                                minRows={2}
                              />
                            </Grid>
                            <Grid xs={12} md={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={step1.aggRumori}
                                    onChange={(e) => {
                                      let s = { ...step1 };
                                      s.aggRumori = !step1.aggRumori;
                                      setStep1(s);
                                    }}
                                  />
                                }
                                label="Aggiornamento necessario?"
                              />
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>
                      <Divider
                        sx={{
                          color: "rgba(58, 53, 65, 0.6)",
                          width: "100%",
                          my: 1,
                        }}
                      />
                      <h5>MMC</h5>
                      <Grid sx={{ mt: 2, mb: 1 }} xs={12} md={12}>
                        <Grid xs={12} md={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={step1.necessMMC}
                                onChange={(e) => {
                                  let s = { ...step1 };
                                  s.necessMMC = !step1.necessMMC;
                                  setStep1(s);
                                }}
                              />
                            }
                            label="Necessario?"
                          />
                        </Grid>
                        {step1.necessMMC ? (
                          <>
                            <Grid sx={{ mt: 2, mb: 1 }} xs={12}>
                              <TextField
                                label="Anno"
                                type="number"
                                fullWidth
                                variant="outlined"
                                value={step1.annoMMC}
                                onChange={(e) => {
                                  let s = { ...step1 };
                                  s.annoMMC = e.target.value;
                                  setStep1(s);
                                }}
                              />
                            </Grid>
                            <Grid sx={{ mb: 1 }} xs={12} md={12}>
                              <TextField
                                label="Contenuto valutazione?"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={step1.testoMMC}
                                multiline={2}
                                onChange={(e) => {
                                  let s = { ...step1 };
                                  s.testoMMC = e.target.value;
                                  setStep1(s);
                                }}
                                minRows={2}
                              />
                            </Grid>
                            <Grid xs={12} md={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={step1.aggMMC}
                                    onChange={(e) => {
                                      let s = { ...step1 };
                                      s.aggMMC = !step1.aggMMC;
                                      setStep1(s);
                                    }}
                                  />
                                }
                                label="Aggiornamento necessario?"
                              />
                            </Grid>

                            {step1.aggMMC ? (
                              <>
                                <Grid sx={{ mb: 1 }} xs={12} md={12}>
                                  <TextField
                                    label="Perchè?"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    value={step1.percheMMC}
                                    multiline={2}
                                    onChange={(e) => {
                                      let s = { ...step1 };
                                      s.percheMMC = e.target.value;
                                      setStep1(s);
                                    }}
                                    minRows={2}
                                  />
                                </Grid>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>
                      <Divider
                        sx={{
                          color: "rgba(58, 53, 65, 0.6)",
                          width: "100%",
                          my: 1,
                        }}
                      />
                      <h5>Stress</h5>
                      <Grid sx={{ mt: 2, mb: 1 }} xs={12} md={12}>
                        <Grid xs={12} md={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={step1.maiFattaStress}
                                onChange={(e) => {
                                  let s = { ...step1 };
                                  s.maiFattaStress = !step1.maiFattaStress;
                                  setStep1(s);
                                }}
                              />
                            }
                            label="Mai Stata fatta?"
                          />
                        </Grid>
                        {step1.maiFattaStress ? (
                          <>
                            <Grid sx={{ mb: 1 }} xs={12} md={12}>
                              <TextField
                                label="Anno"
                                type="number"
                                fullWidth
                                variant="outlined"
                                value={step1.annoStress}
                                onChange={(e) => {
                                  let s = { ...step1 };
                                  s.annoStress = e.target.value;
                                  setStep1(s);
                                }}
                                minRows={2}
                              />
                            </Grid>
                            <Grid xs={12} md={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={step1.aggStress}
                                    onChange={(e) => {
                                      let s = { ...step1 };
                                      s.aggStress = !step1.aggStress;
                                      setStep1(s);
                                    }}
                                  />
                                }
                                label="Aggiornamento necessario?"
                              />
                            </Grid>

                            {step1.aggStress ? (
                              <>
                                <Grid sx={{ mb: 1 }} xs={12} md={12}>
                                  <TextField
                                    label="Perchè?"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    value={step1.percheStress}
                                    multiline={2}
                                    onChange={(e) => {
                                      let s = { ...step1 };
                                      s.percheStress = e.target.value;
                                      setStep1(s);
                                    }}
                                    minRows={2}
                                  />
                                </Grid>
                              </>
                            ) : (
                              <></>
                            )}
                            <Grid sx={{ mb: 1 }} xs={12} md={12}>
                              <TextField
                                label="Altri argomenti da inserire?"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={step1.testoStress}
                                multiline={6}
                                onChange={(e) => {
                                  let s = { ...step1 };
                                  s.testoStress = e.target.value;
                                  setStep1(s);
                                }}
                                minRows={2}
                              />
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                  </>
                ) : activeStep === 2 ? (
                  <>
                    <Grid container sx={{ mt: 2, mb: 1, overflow: "auto" }}>
                      <Divider
                        sx={{
                          color: "rgba(58, 53, 65, 0.6)",
                          width: "100%",
                          my: 1,
                        }}
                      />
                      <h5>Infortuni</h5>
                      <Grid sx={{ mt: 2, mb: 1 }} xs={12}>
                        <TextField
                          label="Anno"
                          type="number"
                          fullWidth
                          variant="outlined"
                          value={step2.annoInfortunei}
                          onChange={(e) => {
                            let s = { ...step2 };
                            s.annoInfortunei = e.target.value;
                            setStep2(s);
                          }}
                        />
                      </Grid>
                      <Grid xs={12} md={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={step2.presenzaInfortunei}
                              onChange={(e) => {
                                let s = { ...step2 };
                                s.presenzaInfortunei =
                                  !step2.presenzaInfortunei;
                                setStep2(s);
                              }}
                            />
                          }
                          label="Ci sono stati infortuni?"
                        />
                      </Grid>
                      {step2.presenzaInfortunei ? (
                        <>
                          <Grid xs={6}>
                            <TextField
                              label="Quanti"
                              type="number"
                              fullWidth
                              variant="outlined"
                              value={step2.quantiInfortunei}
                              onChange={(e) => {
                                let s = { ...step2 };
                                s.quantiInfortunei = e.target.value;
                                setStep2(s);
                              }}
                            />
                          </Grid>
                          <Grid xs={6}>
                            <TextField
                              label="Quanti in itinere"
                              type="number"
                              fullWidth
                              variant="outlined"
                              value={step2.quantiItinereInfortunei}
                              onChange={(e) => {
                                let s = { ...step2 };
                                s.quantiItinereInfortunei = e.target.value;
                                setStep2(s);
                              }}
                            />
                          </Grid>
                          <Grid sx={{ mb: 1, mt: 2 }} xs={12} md={12}>
                            <TextField
                              label="Descrizione"
                              type="text"
                              fullWidth
                              variant="outlined"
                              value={step2.testoInfortunei}
                              multiline={2}
                              onChange={(e) => {
                                let s = { ...step2 };
                                s.testoInfortunei = e.target.value;
                                setStep2(s);
                              }}
                              minRows={2}
                            />
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                      <Divider
                        sx={{
                          color: "rgba(58, 53, 65, 0.6)",
                          width: "100%",
                          my: 1,
                        }}
                      />
                      <h5>Quasi Infortuni</h5>
                      <Grid xs={12} md={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={step2.registriQuasiInfortunei}
                              onChange={(e) => {
                                let s = { ...step2 };
                                s.registriQuasiInfortunei =
                                  !step2.registriQuasiInfortunei;
                                setStep2(s);
                              }}
                            />
                          }
                          label="Registri?"
                        />
                      </Grid>
                      {step2.registriQuasiInfortunei ? (
                        <>
                          <Grid sx={{}} xs={12}>
                            <TextField
                              label="Quanti"
                              type="number"
                              fullWidth
                              variant="outlined"
                              value={step2.quantiQuasiInfortunei}
                              onChange={(e) => {
                                let s = { ...step2 };
                                s.quantiQuasiInfortunei = e.target.value;
                                setStep2(s);
                              }}
                            />
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                      <Divider
                        sx={{
                          color: "rgba(58, 53, 65, 0.6)",
                          width: "100%",
                          my: 1,
                        }}
                      />
                      <h5>Malattie</h5>
                      <Grid xs={12} md={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={step2.malattie}
                              onChange={(e) => {
                                let s = { ...step2 };
                                s.malattie = !step2.malattie;
                                setStep2(s);
                              }}
                            />
                          }
                          label="Ci sono delle malattie professionali?"
                        />
                      </Grid>
                      {step2.malattie ? (
                        <>
                          <Grid sx={{}} xs={12}>
                            <TextField
                              label="Quante"
                              type="number"
                              fullWidth
                              variant="outlined"
                              value={step2.quanteMalattie}
                              onChange={(e) => {
                                let s = { ...step2 };
                                s.quanteMalattie = e.target.value;
                                setStep2(s);
                              }}
                            />
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                      <Divider
                        sx={{
                          color: "rgba(58, 53, 65, 0.6)",
                          width: "100%",
                          my: 1,
                        }}
                      />
                      <h5 style={{ width: "100%" }}>Visite Mediche</h5>
                      <Grid sx={{ mt: 2, mb: 1 }} xs={12} md={6}>
                        <TextField
                          label="Anno"
                          type="number"
                          fullWidth
                          variant="outlined"
                          value={step2.annoVisite}
                          onChange={(e) => {
                            let s = { ...step2 };
                            s.annoVisite = e.target.value;
                            setStep2(s);
                          }}
                        />
                      </Grid>
                      <Grid sx={{ mt: 2, mb: 1 }} xs={12} md={6}>
                        <TextField
                          label="Quante"
                          type="number"
                          fullWidth
                          variant="outlined"
                          value={step2.quanteVisite}
                          onChange={(e) => {
                            let s = { ...step2 };
                            s.quanteVisite = e.target.value;
                            setStep2(s);
                          }}
                        />
                      </Grid>
                      <Grid sx={{ mt: 2, mb: 1 }} xs={12}>
                        <TextField
                          label="Ulteriori dettagli"
                          type="text"
                          multiline={4}
                          fullWidth
                          variant="outlined"
                          value={step2.dettagliVisite}
                          onChange={(e) => {
                            let s = { ...step2 };
                            s.dettagliVisite = e.target.value;
                            setStep2(s);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : activeStep === 3 ? (
                  <>
                    <Grid
                      container
                      sx={{
                        mt: 2,
                        mb: 1,
                        overflow: "auto",
                        alignItems: "flex-start",
                      }}
                    >
                      <Divider
                        sx={{
                          color: "rgba(58, 53, 65, 0.6)",
                          width: "100%",
                          my: 1,
                        }}
                      />
                      <h5>DPI</h5>
                      <Grid xs={12} md={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={step3.dpi}
                              onChange={(e) => {
                                let s = { ...step3 };
                                s.dpi = !step3.dpi;
                                setStep3(s);
                              }}
                            />
                          }
                          label="Sono utilizzati DPI"
                        />
                      </Grid>
                      {step3.dpi ? (
                        <>
                          <Grid xs={12} md={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={step3.necessario}
                                  onChange={(e) => {
                                    let s = { ...step3 };
                                    s.necessario = !step3.necessario;
                                    setStep3(s);
                                  }}
                                />
                              }
                              label="Sono presenti lettere di consegna?"
                            />
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </>
                ) : activeStep === 4 ? (
                  <>
                    <Grid container sx={{ mt: 2, mb: 1, overflow: "auto" }}>
                      <Divider
                        sx={{
                          color: "rgba(58, 53, 65, 0.6)",
                          width: "100%",
                          my: 1,
                        }}
                      />
                      <h5>Formazione</h5>
                      <Grid sx={{ mt: 2, mb: 1 }} xs={12}>
                        <TextField
                          label="Anno"
                          type="number"
                          fullWidth
                          variant="outlined"
                          value={step4.annoFormazione}
                          onChange={async (e) => {
                            let s = { ...step4 };
                            s.annoFormazione = e.target.value;
                            setStep4(s);
                            //eseguo chiamata per prendere tutti i lavoratori che hanno eseguito la formazione durante l'anno selezionato
                            if (e.target.value.length === 4) {
                              let response = await unsplash.get("lavoratori", {
                                params: {
                                  idSede: sede,
                                  anno: e.target.value,
                                },
                                headers: {
                                  Authorization: `Bearer ${localStorage.getItem(
                                    "jwt"
                                  )}`,
                                },
                              });
                              const initialValue = [];
                              response.data.data.map((currentValue) => {
                                if (
                                  !initialValue.includes(
                                    currentValue.Descrizione
                                  )
                                ) {
                                  initialValue.push(currentValue.Descrizione);
                                }
                              });
                              var arr = [];
                              initialValue.forEach((el) => {
                                return arr.push({ corso: el });
                              });
                              setLavoratoriFormati(arr);
                              setLavoratoriFormatiSel(arr);
                              let addYear = Number(e.target.value) + 1;
                              let yearString = addYear.toString();
                              let responseDaFormare = await unsplash.get(
                                "lavoratori",
                                {
                                  params: {
                                    idSede: sede,
                                    anno: yearString,
                                  },
                                  headers: {
                                    Authorization: `Bearer ${localStorage.getItem(
                                      "jwt"
                                    )}`,
                                  },
                                }
                              );
                              const initialValueDa = [];
                              responseDaFormare.data.data.map(
                                (currentValue) => {
                                  if (
                                    !initialValueDa.includes(
                                      currentValue.Descrizione
                                    )
                                  ) {
                                    initialValueDa.push(
                                      currentValue.Descrizione
                                    );
                                  }
                                }
                              );
                              var arrDa = [];
                              initialValueDa.forEach((el) => {
                                return arrDa.push({ corso: el });
                              });
                              setLavoratoriDaFormare(arrDa);
                              setLavoratoriDaFormareSel(arrDa);
                            }
                          }}
                        />
                      </Grid>
                      {step4.annoFormazione.length === 4 ? (
                        <>
                          <Grid sx={{ mt: 2, mb: 1 }} xs={12}>
                            <Autocomplete
                              multiple
                              id="checkboxes-tags-demo"
                              options={lavoratoriFormati}
                              value={lavoratoriFormatiSel}
                              disableCloseOnSelect
                              onChange={(e, newValue) => {
                                setLavoratoriFormatiSel(newValue);
                              }}
                              getOptionLabel={(option) => option.corso}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.corso}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Lavoratori Formati"
                                />
                              )}
                            />
                          </Grid>
                          <Grid sx={{ mt: 2, mb: 1 }} xs={12}>
                            <Autocomplete
                              multiple
                              id="checkboxes-tags-demo"
                              options={lavoratoriDaFormare}
                              value={lavoratoriDaFormareSel}
                              disableCloseOnSelect
                              onChange={(e, newValue) => {
                                setLavoratoriDaFormareSel(newValue);
                              }}
                              getOptionLabel={(option) => option.corso}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.corso}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Lavoratori Da Formare"
                                />
                              )}
                            />
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </>
                ) : activeStep === 5 ? (
                  <>
                    <Grid container sx={{ mt: 2, mb: 1, overflow: "auto" }}>
                      <Divider
                        sx={{
                          color: "rgba(58, 53, 65, 0.6)",
                          width: "100%",
                          my: 1,
                        }}
                      />
                      <h5>Varie</h5>
                      <Grid sx={{ mt: 2, mb: 1 }} xs={12}>
                        <TextField
                          label="Documentazione Tecnica"
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={step5.documentazione}
                          onChange={(e) => {
                            let s = { ...step5 };
                            s.documentazione = e.target.value;
                            setStep5(s);
                          }}
                        />
                      </Grid>
                      <Grid sx={{ mt: 2, mb: 1 }} xs={12}>
                        <TextField
                          label="Prova di evacuazione"
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={step5.prova}
                          onChange={(e) => {
                            let s = { ...step5 };
                            s.prova = e.target.value;
                            setStep5(s);
                          }}
                        />
                      </Grid>
                      <Grid sx={{ mt: 2, mb: 1 }} xs={12}>
                        <TextField
                          label="Organigramma e nomine"
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={step5.organigramma}
                          onChange={(e) => {
                            let s = { ...step5 };
                            s.organigramma = e.target.value;
                            setStep5(s);
                          }}
                        />
                      </Grid>
                      <Grid sx={{ mt: 2, mb: 1 }} xs={12}>
                        <TextField
                          label="Ecc"
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={step5.ecc}
                          onChange={(e) => {
                            let s = { ...step5 };
                            s.ecc = e.target.value;
                            setStep5(s);
                          }}
                        />
                      </Grid>
                      <Divider
                        sx={{
                          color: "rgba(58, 53, 65, 0.6)",
                          width: "100%",
                          my: 1,
                        }}
                      />
                      <h5>Allegati</h5>
                      <Grid sx={{ mt: 2, mb: 1 }} xs={12}>
                        <Autocomplete
                          multiple
                          id="checkboxes-tags-demo"
                          options={allegati}
                          value={allegatiSel}
                          disableCloseOnSelect
                          onChange={(e, newValue) => {
                            setAllegatiSel(newValue);
                          }}
                          getOptionLabel={(option) => option.Frase}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.Frase}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="Allegati" />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                <table id="table-firme" hidden>
                  <tr>
                    <td id="text">
                      {step0.datore
                        ? "Datore di Lavoro"
                        : "Delegato del datore di lavoro"}
                    </td>
                    <td>______________________</td>
                  </tr>
                  <tr>
                    <td id="text">RSPP</td>
                    <td>______________________</td>
                  </tr>
                  <tr>
                    <td id="text">RLS</td>
                    <td>______________________</td>
                  </tr>
                  <tr>
                    <td id="text">Medico Competente</td>
                    <td>______________________</td>
                  </tr>
                </table>
              </div>
            </React.Fragment>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between", mx: 2 }}
        >
          {activeStep === 0 ? (
            <>
              <div style={{ mr: 1 }}></div>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                className="ButtonMiller"
                onClick={handleBack}
                startIcon={<ArrowBack />}
                sx={{ mr: 1 }}
              >
                INDIETRO
              </Button>
            </>
          )}

          <Button
            onClick={() => {
              if (activeStep === steps.length - 1) {
                exportPDFRiunionePeriodica();
                //handleClose()
              } else {
                handleNext();
              }
            }}
            sx={{ align: "right" }}
            variant="contained"
            className="ButtonMiller"
            startIcon={
              activeStep === steps.length - 1 ? <Print /> : <ArrowForward />
            }
          >
            {activeStep === steps.length - 1 ? "STAMPA" : "AVANTI"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
