import React, { useEffect, useState } from "react";
import unsplash from "../api/unsplash";
import { useNavigate } from "react-router-dom";

import {
  Box,
  IconButton,
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogContent,
  Alert,
  Button,
  TextField,
  DialogActions,
  Select,
  OutlinedInput,
  MenuItem,
  FormControl,
  Card,
  Grid,
  CardContent,
  InputAdornment,
  Zoom,
  Switch,
  FormControlLabel,
  Checkbox,
  LinearProgress,
} from "@mui/material";
import {
  DataGridPro,
  itIT,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import {
  Add,
  Delete,
  Circle,
  Edit,
  Search,
  Close,
  Check,
} from "@mui/icons-material";
import GlobalSnackbar from "./Snackbar";
import moment from "moment";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const AnagarficaCorsi = () => {
  const [corsi, setCorsi] = useState([]);
  const [corsiVisibili, setCorsiVisibili] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const [filtro, setFiltro] = useState({
    nonAttivi: false,
    attivi: true,
  });
  const columns = [
    {
      filterable: false,
      field: "Azioni",
      headerName: "Azioni",
      width: 100,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <NewCorsoModal
            myFunction={handleSaveData}
            title="Modifica corso"
            corsi={corsi}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
            operation="Edit"
            corsoSel={params.row}
          />
          <DeleteDialog corso={params.row} myFunction={handleDelete} />
        </>
      ),
    },
    {
      field: "Attivo",
      headerName: "Attivo",
      width: 70,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => (
        <Circle sx={{ color: params.row.Attivo === 1 ? "#40C18F" : "#FF2768" }}>
          {" "}
        </Circle>
      ),
    },
    {
      field: "IncludiNelPianoFormazione",
      headerName: "Formazione",
      width: 70,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => (
        <Circle
          sx={{
            color:
              params.row.IncludiNelPianoFormazione === 1
                ? "#40C18F"
                : "#FF2768",
          }}
        >
          {" "}
        </Circle>
      ),
    },
    {
      field: "Codice",
      headerName: "Codice",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Descrizione",
      headerName: "Descrizione",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Durata",
      headerName: "Durata",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Scadenza",
      headerName: "Scadenza",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "DurataAggiornamento",
      headerName: "Durata Aggiornamento",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
  ];

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSaveData = async (corso, operazione) => {
    try {
      //AGGIUNGERE LA CREAZIONE DELLA SEDE

      if (operazione === "new") {
        let responseCorso = await unsplash.post(
          "anagraficaCorsi",
          { corso: corso },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Corso creato con successo",
        });
        setOpenSnack(true);
      } else {
        let responseCorso = await unsplash.put(
          "anagraficaCorsi",
          { corso: corso },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Corso modificato con successo",
        });
        setOpenSnack(true);
      }
      reloadData();
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante la creazione del corso",
      });
      setOpenSnack(true);
    }
  };

  const reloadData = async () => {
    try {
      let response = await unsplash.get("anagraficaCorsi", {
        params: {
          getAll: true,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      setCorsi(response.data.data);
      setCorsiVisibili(response.data.data.filter((el) => el.Attivo === 1));
      setLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    // è un loop qui carico i dati
    (() => {
      reloadData();
    })();
  }, []);

  const handleDelete = async (codice) => {
    try {
      await unsplash.delete("anagraficaCorsi", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          codice: codice,
        },
      });

      let response = await unsplash.get("anagraficaCorsi", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      setCorsi(response.data.data);
      setCorsiVisibili(response.data.data);

      setSnack({
        severity: "success",
        messaggio: "Corso eliminato con successo",
      });
      setOpenSnack(true);
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante la cancellazione",
      });
      setOpenSnack(true);
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
          <FormControlLabel
            sx={{
              marginBottom: "0px!important",
              fontSize: "13px",
              color: "#11526F",
            }}
            control={
              <Checkbox
                size="small"
                checked={filtro.attivi}
                onClick={(e) => filter(e, "attivi")}
              />
            }
            label="Attivi"
          />
          <FormControlLabel
            sx={{
              marginBottom: "0px!important",
              fontSize: "13px",
              color: "#11526F",
            }}
            control={
              <Checkbox
                size="small"
                checked={filtro.nonAttivi}
                onClick={(e) => filter(e, "nonAttivi")}
              />
            }
            label="Non attivi"
          />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const filter = (e, tipologia) => {
    let f = { ...filtro };
    f[tipologia] = e.target.checked;
    setFiltro(f);

    let corsiTemp = corsi;

    if (!f.nonAttivi) {
      corsiTemp = corsiTemp.filter((c) => c.Attivo !== 0);
    }

    if (!f.attivi) {
      corsiTemp = corsiTemp.filter((c) => c.Attivo !== 1);
    }
    setCorsiVisibili(corsiTemp);
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid item xs={12}>
        <Card sx={{ boxShadow: "none" }}>
          <CardContent>
            <Grid
              container
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Grid item xs={12} md={4}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <NewCorsoModal
                    myFunction={handleSaveData}
                    title="Crea nuovo corso"
                    corsi={corsi}
                    setSnack={setSnack}
                    setOpenSnack={setOpenSnack}
                    operation="new"
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* <div ><center >
      
      <div className="form-outline mb-4 input-group" style={{width:"33%"}}>
      <span className="input-group-text bg-transparent">
                  <i className="bi bi-search" ></i>
                  </span>
      <input onChange={handleChange} className="form-control form-control-lg border-left-0"/>
           
                 
                </div>
        
        
        
       </center></div> */}

            {/* <NewCorsoModal myFunction={handleSaveData} title="Crea nuovo corso" corsi={corsi} setSnack={setSnack} setOpenSnack={setOpenSnack} operation="new" /> */}
            <Box
              sx={{
                height: { xs: "75vh", md: "60vh", xl: "75vh" },
                width: 1,
              }}
            >
              <DataGridPro
                sx={{ fontSize: 12 }}
                components={{
                  Toolbar: CustomToolbar,
                  Footer: CustomFooterPersonalized,
                }}
                slots={{
                  loadingOverlay: LinearProgress,
                }}
                loading={loading}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                rows={corsiVisibili}
                columns={columns}
                getRowId={(row) => row.Codice}
                pageSize={100}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "Cognome", sort: "ASC" }],
                  },
                  pinnedColumns: { left: ["Azioni"] },
                }}
                disableSelectionOnClick
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

const NewCorsoModal = (props) => {
  const [open, setOpen] = useState(false);
  const [corso, setCorso] = useState();
  const [validation, setValidation] = useState();

  const handleClickOpen = () => {
    setValidation({ Nome: false, Cognome: false, CF: false, eMail: false });
    if (props.operation === "new") {
      setCorso({
        Codice: "",
        Descrizione: "",
        Durata: "",
        Scadenza: "",
        DurataAggiornamento: "",
        Ordinamento: "",
        Attivo: 1,
        IncludiNelPianoFormazione: 1,
        PeriodicitaAggiornamento: 0,
      });
    } else {
      setCorso(props.corsoSel);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    let c = { ...corso };
    c[e.target.name] = e.target.value;
    setCorso(c);
  };

  const handleSave = async () => {
    if (props.operation === "new") {
      let filter = props.corsi.filter((c) => c.Codice === corso.Codice);

      if (filter.length > 0) {
        props.setSnack({
          severity: "warning",
          messaggio: "Attenzione il cliente esiste già",
        });
        props.setOpenSnack(true);
        return;
      }
    }
    props.myFunction(corso, props.operation);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen} sx={{ p: 0 }}>
        {props.operation === "Edit" ? (
          <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
        ) : (
          <Button
            startIcon={<Add />}
            onClick={handleClickOpen}
            id={props.id}
            sx={{ color: "white" }}
            variant="outlined"
            className="ButtonMiller"
          >
            Aggiungi
          </Button>
        )}
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <DialogTitle sx={{ mx: "auto" }}>{props.title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
              fullWidth
            >
              Attivo:
              <Switch
                checked={corso?.Attivo === 1}
                onChange={(e) => {
                  let c = { ...corso };
                  c.Attivo = c.Attivo === 1 ? 0 : 1;
                  setCorso(c);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
              fullWidth
            >
              Piano Formazione:
              <Switch
                checked={corso?.IncludiNelPianoFormazione === 1}
                onChange={(e) => {
                  let c = { ...corso };
                  c.IncludiNelPianoFormazione =
                    c.IncludiNelPianoFormazione === 1 ? 0 : 1;
                  setCorso(c);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
              fullWidth
            >
              Periodicità Aggiornamento
              <Switch
                checked={corso?.PeriodicitaAggiornamento === 1}
                onChange={(e) => {
                  setCorso({ ...corso, PeriodicitaAggiornamento: corso.PeriodicitaAggiornamento === 1 ? 0 : 1});
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                name="Codice"
                label="Codice"
                type="text"
                fullWidth
                value={corso?.Codice}
                disabled={props.operation === "Edit"}
                onChange={handleChange}
                variant="outlined"
                helperText={validation?.Nome ? "Campo obbligatorio" : ""}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                name="Descrizione"
                label="Descrizione"
                type="text"
                fullWidth
                value={corso?.Descrizione}
                onChange={handleChange}
                variant="outlined"
                helperText={validation?.Cognome ? "Campo obbligatorio" : ""}
              />
            </Grid>
            <hr
              style={{
                height: "2px",
                marginTop: "10px",
                marginBottom: "5px",
                width: "100%",
                marginLeft: "16px",
              }}
            />
            <Grid item xs={12} md={3}>
              <TextField
                margin="dense"
                name="Durata"
                label="Durata"
                type="number"
                fullWidth
                value={corso?.Durata}
                onChange={handleChange}
                variant="outlined"
                helperText={validation?.CF ? "Campo obbligatorio" : ""}
                inputProps={{ maxLength: 16 }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                margin="dense"
                name="Scadenza"
                label="Scadenza"
                type="number"
                fullWidth
                disabled={corso?.PeriodicitaAggiornamento === 1}
                value={corso?.Scadenza}
                onChange={handleChange}
                variant="outlined"
                helperText={validation?.eMail ? "Campo obbligatorio" : ""}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                margin="dense"
                name="DurataAggiornamento"
                label="Durata Aggiornamento"
                type="number"
                fullWidth
                value={corso?.DurataAggiornamento}
                onChange={handleChange}
                variant="outlined"
                helperText={validation?.eMail ? "Campo obbligatorio" : ""}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                margin="dense"
                name="Ordinamento"
                label="Ordinamento"
                type="number"
                fullWidth
                value={corso?.Ordinamento}
                onChange={handleChange}
                variant="outlined"
                helperText={validation?.eMail ? "Campo obbligatorio" : ""}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
          >
            Annulla
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={handleSave}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AnagarficaCorsi;

const DeleteDialog = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare il corso selezionato?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare il seguente Corso :<br />
            <b>{props.corso.Codice + " - " + props.corso.Descrizione}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={() => props.myFunction(props.corso.Codice)}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
