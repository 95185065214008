import React, { useEffect, useState } from "react";
import unsplash from "../api/unsplash";
import { useNavigate } from "react-router-dom";

import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  DialogActions,
  Card,
  Grid,
  CardContent,
  Zoom,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@mui/material";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { Add, Check, Circle, Close, Edit } from "@mui/icons-material";
import GlobalSnackbar from "./Snackbar";
import moment from "moment";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import { Excel } from "../utils/Excel";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const Medici = (props) => {
  const [medici, setMedici] = useState([]);
  const [mediciVisibili, setMediciVisibili] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [filtro, setFiltro] = useState({
    attivi: true,
    cessati: false,
  });
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const filter = (e, tipologia) => {
    let filtroUp = { ...filtro };
    filtroUp[tipologia] = e.target.checked;
    setFiltro(filtroUp);

    let form = medici;
    if (!filtroUp.attivi) {
      form = form.filter((f) => f.Attivo !== 1);
    }
    if (!filtroUp.cessati) {
      form = form.filter((f) => f.Attivo !== 0);
    }
    setMediciVisibili(form);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
          <FormControlLabel
            sx={{
              marginBottom: "0px!important",
              fontSize: "13px",
              color: "#11526F",
            }}
            control={
              <Checkbox
                size="small"
                checked={filtro.attivi}
                onClick={(e) => filter(e, "attivi")}
              />
            }
            label="Attivi"
          />
          <FormControlLabel
            sx={{
              marginBottom: "0px!important",
              fontSize: "13px",
              color: "#11526F",
            }}
            control={
              <Checkbox
                size="small"
                name="Cessati"
                checked={filtro.cessati}
                onClick={(e) => filter(e, "cessati")}
              />
            }
            label="Cessati"
          />
          <Excel
            requiredColumn={"CF"}
            getAllTableData={"Medici"}
            filterColumn={["Nome", "Cognome", "CF"]}
            thereIsData={["DataDiNascita"]}
            excelHeader={["Nome", "Cognome", "CF", "DataDiNascita", "eMail"]}
            fileName={"Medici"}
            reloadData={async () => {
              let response = await unsplash.get("medici", {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
              });
              setMedici(response.data.data);
              setMediciVisibili(response.data.data);
            }}
            handleSave={async (importedData) => {
              try {
                await unsplash.post(
                  "excel",
                  {
                    excel: importedData,
                    table: "Medici",
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                  }
                );
              } catch {
                setSnack({
                  severity: "error",
                  messaggio: "Errore durante l'importazione",
                });
                setOpenSnack(true);
              }
            }}
          />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      headerAlign: "center",
      hide: false,
      width: "100",
      align: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => {
              navigate("/dettaglioMedico", {
                state: { medico: params.row },
              });
            }}
          >
            <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
          </IconButton>
          {params.row.Attivo === 1 ? (
            <Tooltip title="Attivo">
              <Circle
                sx={{ color: "#40C18F", width: "15px", height: "15px" }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Cessato">
              <Circle
                sx={{ color: "#FF2768", width: "15px", height: "15px" }}
              />
            </Tooltip>
          )}
        </>
      ),
    },
    {
      field: "Cognome",
      headerName: "Cognome",
      //width: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Nome",
      headerName: "Nome",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "CF",
      headerName: "Codice Fiscale",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "Indirizzo",
      headerName: "Indirizzo",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "IndirizzoSecondario",
      headerName: "Indirizzo Secondario",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
    {
      field: "DataDiNascita",
      headerName: "Data di Nascita",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (params) => {
        return (
          <div>
            {params.row.DataDiNascita
              ? moment(params.row.DataDiNascita).format("DD - MM - YYYY")
              : ""}
          </div>
        );
      },
    },
    {
      field: "eMail",
      headerName: "Email",
      //width: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sort: "asc",
    },
  ];

  const reloadData = async () => {
    try {
      let response = await unsplash.get("medici", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setMedici(response.data.data);
      setMediciVisibili(response.data.data.filter((f) => f.Attivo === 1));
      setLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleSaveData = async (medico) => {
    try {
      //AGGIUNGERE LA CREAZIONE DELLA SEDE
      let responseMedico = await unsplash.post(
        "medici",
        { medico: medico },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({
        severity: "success",
        messaggio: "Medico creato con successo",
      });
      setOpenSnack(true);
      reloadData();
      /* medico.new = true;
      medico.ID = responseMedico.data.message.insertId;
      let m = [...medici];
      m.unshift(medico); //aggiungo in prima posizione
      setMedici(m);
      setMediciVisibili(m); */
    } catch (error) {
      console.log(error);
      setSnack({
        severity: "error",
        messaggio: "Errore durante la creazione del medico",
      });
      setOpenSnack(true);
    }
  };

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      try {
        let response = await unsplash.get("medici", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        setMedici(response.data.data);
        setMediciVisibili(response.data.data.filter((f) => f.Attivo === 1));
        setLoading(false);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <div>
              <Grid
                container
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="flex-end"
              >
                <Grid item xs={12} md={4}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <NewMedicoModal
                      myFunction={handleSaveData}
                      snack={snack}
                      openSnack={openSnack}
                      handleCloseSnack={handleCloseSnack}
                      setSnack={setSnack}
                      setOpenSnack={setOpenSnack}
                      reloadData={reloadData}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Box
                sx={{
                  height: { xs: "75vh", md: "60vh", xl: "75vh" },
                  width: 1,
                }}
              >
                <DataGridPro
                  sx={{ fontSize: 12 }}
                  components={{
                    Toolbar: CustomToolbar,
                    Footer: CustomFooterPersonalized,
                  }}
                  slots={{
                    loadingOverlay: LinearProgress,
                  }}
                  loading={loading}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  rows={mediciVisibili}
                  columns={columns}
                  getRowId={(row) => row.ID}
                  pageSize={20}
                  initialState={{
                    pinnedColumns: { left: ["Actions"] },
                    sorting: {
                      sortModel: [{ field: "Cognome", sort: "ASC" }],
                    },
                  }}
                  disableSelectionOnClick
                />
              </Box>
            </div>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

const NewMedicoModal = (props) => {
  const [open, setOpen] = useState(false);
  const [medico, setMedico] = useState();
  const [validation, setValidation] = useState();

  const handleClickOpen = () => {
    setMedico({
      Nome: "",
      Cognome: "",
      CF: "",
      eMail: "",
      Indirizzo: "",
      IndirizzoSecondario: "",
      Sesso: "",
    });
    setValidation({ Nome: false, Cognome: false, CF: false, eMail: false });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    let m = { ...medico };
    m[e.target.name] = e.target.value;
    setMedico(m);
  };

  const handleSave = async () => {
    var check = false;
    let val = { ...validation };
    if (medico.Nome === "" || medico.Nome === null) {
      val.Nome = true;
      check = true;
    } else {
      val.Nome = false;
    }
    if (medico.Cognome === "" || medico.Cognome === null) {
      val.Cognome = true;
      check = true;
    } else {
      val.Cognome = false;
    }
    if (medico.CF === "" || medico.CF === null || medico.CF.length < 16) {
      val.CF = true;
      check = true;
    } else {
      val.CF = false;
    }

    if (medico.eMail === "" || medico.eMail === null) {
      val.eMail = true;
      check = true;
    } else {
      val.eMail = false;
    }

    setValidation(val);

    if (!check) {
      props.myFunction(medico);
      handleClose();
    }
  };

  return (
    <div>
      <Button
        startIcon={<Add />}
        onClick={handleClickOpen}
        id={props.id}
        sx={{ color: "white" }}
        variant="outlined"
        className="ButtonMiller"
      >
        Aggiungi
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="md"
        TransitionComponent={Transition}
      >
        <DialogTitle>Inserisci nuovo medico</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                error={validation?.Nome}
                margin="dense"
                name="Nome"
                label="Nome"
                type="text"
                fullWidth
                value={medico?.Nome}
                onChange={handleChange}
                variant="outlined"
                helperText={validation?.Nome ? "Campo obbligatorio" : ""}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                error={validation?.Cognome}
                margin="dense"
                name="Cognome"
                label="Cognome"
                type="text"
                fullWidth
                value={medico?.Cognome}
                onChange={handleChange}
                variant="outlined"
                helperText={validation?.Cognome ? "Campo obbligatorio" : ""}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                error={validation?.CF}
                margin="dense"
                name="CF"
                label="Codice Fiscale"
                type="text"
                fullWidth
                value={medico?.CF}
                onChange={handleChange}
                variant="outlined"
                helperText={
                  validation?.CF
                    ? "Il codice fiscale deve essere di 16 caratteri"
                    : ""
                }
                inputProps={{ maxLength: 16 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                error={validation?.eMail}
                margin="dense"
                name="eMail"
                label="Email"
                type="text"
                fullWidth
                value={medico?.eMail}
                onChange={handleChange}
                variant="outlined"
                helperText={validation?.eMail ? "Campo obbligatorio" : ""}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                name="Indirizzo"
                label="Indirizzo"
                type="text"
                fullWidth
                value={medico?.Indirizzo}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                name="IndirizzoSecondario"
                label="Indirizzo Secondario"
                type="text"
                fullWidth
                value={medico?.IndirizzoSecondario}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Sesso</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={medico?.Sesso}
                  label="Sesso"
                  onChange={(e) =>
                    setMedico({
                      ...medico,
                      Sesso: e.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={0}>
                    Maschio
                  </MenuItem>
                  <MenuItem key={1} value={1}>
                    Femmina
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            xs={12}
            item
            sx={{
              display: "flex",
              mt: 2,
              justifyContent: "space-between",
              px: 2,
            }}
            fullWidth
          >
            <Button
              variant="contained"
              className="ButtonMiller"
              startIcon={<Close />}
              onClick={handleClose}
            >
              Annulla
            </Button>
            <Button
              variant="contained"
              className="ButtonMiller"
              startIcon={<Check />}
              onClick={handleSave}
            >
              Salva
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Medici;
