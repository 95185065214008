import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import unsplash from "../api/unsplash";
import {
	DataGridPro,
	itIT,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
	GridToolbarQuickFilter,
	GridFooterContainer,
} from "@mui/x-data-grid-pro";
import {
	IconButton,
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	ButtonGroup,
	Autocomplete,
	FormControlLabel,
	Checkbox,
	Zoom,
	FormControl,
	OutlinedInput,
	Select,
	InputLabel,
	MenuItem,
	LinearProgress,
	Box,
	Divider,
} from "@mui/material";
import "../style/style.css";
import {
	Edit,
	Delete,
	Add,
	Close,
	Check,
	AirplaneTicket,
	ArrowForward,
	ArrowBack,
	ConnectingAirportsOutlined,
} from "@mui/icons-material";
import GlobalSnackbar from "./Snackbar";
import { useRef } from "react";

import Grow from "@mui/material/Grow";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import { Excel } from "../utils/Excel";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

let newSede = {
	Descrizione: "",
	Blocco: "",
	Referente: "",
	UnitaProduttiva: "",
	Ubicazione: "",
	Lon: null,
	Lat: null,
	Medico: null,
	Cantiere: 0,
};
const Sedi = (props) => {
	const navigate = useNavigate();
	const [sedi, setSedi] = useState([]);
	const [loading, setLoading] = useState(true);
	const [sedeEdit, setSedeEdit] = useState();
	const [infoSede, setInfoSede] = useState([]);
	const [openSnack, setOpenSnack] = useState(false);
	const [mediciCliente, setMediciCliente] = useState(false);

	const anchorRef = useRef(null);
	const [openButton, setOpenButton] = useState(false);

	const handleCloseSnack = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnack(false);
	};
	const [snack, setSnack] = useState({
		severity: "success",
		messaggio: "Salvataggio Effettuato correttamente",
		open: false,
	});
	const columns = [
		{
			filterable: false,
			field: "Actions",
			headerName: "Azioni",
			hide: false,
			width: 120,
			renderCell: (
				params //mi aiuta a mettere il valore
			) => (
				<>
					{!props?.proceduraGuidata ? (
						<IconButton
							aria-label="edit"
							onClick={() => {
								navigate("/dettaglioSedi", {
									state: {
										idC: params.row.Cliente,
										RagioneSociale: params.row.RagioneSociale,
										sedeId: params.row.ID,
										sede: params.row,
										bloccoSede: params.row.Descrizione,
										logo: props.logo,
										infoSede: infoSede,
										sedeCF: props.infoSede.CF,
									},
								});
							}}
						>
							<Edit
								style={{ height: "2rem", width: "2rem", color: "#2265B1" }}
							/>
						</IconButton>
					) : (
						<></>
					)}
					<DeleteDialog
						id={params.row.ID}
						blocco={params.row.Blocco}
						sede={params.row.Descrizione}
						myFunction={deleteSede}
					/>
				</>
			),
		},
		{ field: "Descrizione", headerName: "Sede", width: 200 },
		//{ field: "Blocco", headerName: "Blocco", width: 200 },
		//{field: "NomeMedico",headerName: "Medico",hide: false,width: "200",},
		//{ field: "Referente", headerName: "Referente", width: 200 },
		{ field: "ID", headerName: "", hide: true, width: "130" },
		{
			field: "UnitaProduttiva",
			headerName: "Unità produttiva",
			hide: false,
			width: "200",
		},
		{
			field: "Ubicazione",
			headerName: "Città",
			hide: false,
			width: "200",
		},
		{
			field: "Via",
			headerName: "Via",
			hide: false,
			width: "200",
		},
		{
			field: "Cap",
			headerName: "Cap",
			hide: false,
			width: "200",
		},
		{
			field: "Paese",
			headerName: "Paese",
			hide: false,
			width: "200",
		},
		{
			field: "Lon",
			headerName: "Longitudine",
			hide: false,
			width: "200",
		},
		{
			field: "Lat",
			headerName: "Latitudine",
			hide: false,
			width: "200",
		},
	];
	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<Grid
					container
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
				>
					<GridToolbarQuickFilter placeholder="Cerca..." />
					<Excel
						sheets={[
							{
								Medico: mediciCliente, //la prima proprietà deve essere uguale al nome della colonna che deve avere la dropdown list
								colonnaSel: { nome: "Descrizione", lettera: "G" }, //il nome deve essere lo stesso della colonna del DB che poi verrà sostituito dall'ID
							},
						]}
						requiredColumn={"Descrizione"}
						getAllTableData={"Sedi"}
						filterColumn={[
							"Descrizione",
							"UnitaProduttiva",
							"Ubicazione",
							"Via",
							"Cap",
							"Paese",
						]}
						excelHeader={[
							"Descrizione",
							"UnitaProduttiva",
							"Ubicazione",
							"Via",
							"Cap",
							"Paese",
							"Medico",
							"Referente",
							"Inail",
							"Inps",
							"Ateco",
							"DescrizioneAttivita",
							"Lon",
							"Lat",
						]}
						fileName={"Sedi"}
						reloadData={async () => {
							let responseSedi = await unsplash.get("sedi", {
								headers: {
									Authorization: `Bearer ${localStorage.getItem("jwt")}`,
								},
								params: { id: props.idAzienda },
							});

							setSedi(responseSedi.data.data);
						}}
						handleSave={async (importedData) => {
							const impoData = importedData.map((el) => {
								let l = { ...el };
								l.Cliente = props.idAzienda;
								return l;
							});
							try {
								await unsplash.post(
									"excel",
									{
										excel: impoData,
										table: "Sedi",
									},
									{
										headers: {
											Authorization: `Bearer ${localStorage.getItem("jwt")}`,
										},
									}
								);
							} catch {
								setSnack({
									severity: "error",
									messaggio: "Errore durante l'importazione",
								});
								setOpenSnack(true);
							}
						}}
					/>
				</Grid>
			</GridToolbarContainer>
		);
	}
	useEffect(() => {
		async function fetchData() {
			if (props.idAzienda !== 0) {
				let responseSedi = await unsplash.get("sedi", {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("jwt")}`,
					},
					params: { id: props.idAzienda },
				});

				setSedi(responseSedi.data.data);
				setLoading(false);
				setInfoSede(props.ragioneSociale);

				let responseMediciCliente = await unsplash.get(
					"medici",
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem("jwt")}`,
						},
						params: { id: props.idAzienda },
					} //props.idAzienda}}
				);

				setMediciCliente(responseMediciCliente.data.data);
			}
		}
		fetchData();
	}, [props.idAzienda]);

	useEffect(() => {}, [props.screenSize]);

	const handleSaveData = async (sede, operazione, sedeBackup) => {
		try {
			if (operazione === "Inserimento") {
				//inserisco il nuovo
				try {
					let response = await unsplash.post(
						"sedi",
						{ cliente: props.idAzienda, sede: sede },
						{
							headers: {
								Authorization: `Bearer ${localStorage.getItem("jwt")}`,
							},
						}
					);

					sede.ID = response.data.message.insertId;

					await unsplash.post(
						"storico",
						{
							tabella: "Sedi",
							key: sede.ID,
							utente: sessionStorage.getItem("username"),
							after: sede,
							before: [],
							operazione: "Inserimento",
						},
						{
							headers: {
								Authorization: `Bearer ${localStorage.getItem("jwt")}`,
							},
						}
					);

					let responseSedi = await unsplash.get("sedi", {
						headers: {
							Authorization: `Bearer ${localStorage.getItem("jwt")}`,
						},
						params: { id: props.idAzienda },
					});

					setSedi(responseSedi.data.data);
					setSnack({
						severity: "success",
						messaggio: "Sede creata con successo",
					});
					setOpenSnack(true);
				} catch {
					setSnack({
						severity: "error",
						messaggio: "Errore durante l'inserimento",
					});
					setOpenSnack(true);
				}
			}

			if (operazione === "Modifica") {
				//inserisco il nuovo
				try {
					let responseModifica = await unsplash.put(
						"sedi",
						{ cliente: props.idAzienda, sede: sede },
						{
							headers: {
								Authorization: `Bearer ${localStorage.getItem("jwt")}`,
							},
						}
					);

					await unsplash.post(
						"storico",
						{
							tabella: "Sedi",
							key: sede.ID,
							utente: sessionStorage.getItem("username"),
							after: sede,
							before: sedeBackup,
							operazione: "Modifica",
						},
						{
							headers: {
								Authorization: `Bearer ${localStorage.getItem("jwt")}`,
							},
						}
					);

					setSnack({
						severity: "success",
						messaggio: "Modifica effettuata con successo",
					});
					setOpenSnack(true);
				} catch {
					setSnack({
						severity: "error",
						messaggio: "Errore durante la modifica",
					});
					setOpenSnack(true);
				}
			}

			let responseSedi = await unsplash.get("sedi", {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("jwt")}`,
				},
				params: { id: props.idAzienda },
			});

			setSedi(responseSedi.data.data);
		} catch (error) {}
	};

	const deleteSede = async (id) => {
		try {
			await unsplash.delete("sedi", {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("jwt")}`,
				},
				data: {
					id: id,
				},
			});

			let responseSedi = await unsplash.get("sedi", {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("jwt")}`,
				},
				params: { id: props.idAzienda },
			});
			setSedi(responseSedi.data.data);
			setSnack({
				severity: "success",
				messaggio: "Sede eliminata con successo",
			});
			setOpenSnack(true);
		} catch (error) {
			setSnack({
				severity: "error",
				messaggio: "Errore durante l'eliminazione",
			});
			setOpenSnack(true);
		}
	};

	const loadSedi = async () => {
		let responseSedi = await unsplash.get("sedi", {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("jwt")}`,
			},
			params: { id: props.idAzienda },
		});

		setSedi(responseSedi.data.data);
	};

	return (
		<>
			<GlobalSnackbar
				severity={snack.severity}
				messaggio={snack.messaggio}
				openSnack={openSnack}
				handleCloseSnack={handleCloseSnack}
			/>
			<div>
				<Grid
					container
					direction="row"
					spacing={2}
					alignItems="center"
					justifyContent="flex-end"
				>
					<ButtonGroup
						variant="contained"
						aria-label="outlined primary button group"
					>
						<Button
							startIcon={<Add />}
							sx={{ color: "white" }}
							variant="outlined"
							className="ButtonMiller"
							onClick={() => document.getElementById("add").click()}
						>
							AGGIUNGI
						</Button>
						{!props?.proceduraGuidata ? (
							<RiordinamentoMassivoSedi
								screenSize={props.screenSize}
								sedi={sedi}
								loadSedi={loadSedi}
								cliente={props.idAzienda}
							/>
						) : (
							<></>
						)}
					</ButtonGroup>
				</Grid>
				<FormDialog
					id="edit"
					operazione="Modifica"
					sede={sedeEdit}
					saveFunction={handleSaveData}
					mediciCliente={mediciCliente}
				/>
				<FormDialog
					id="add"
					operazione="Inserimento"
					sede={newSede}
					saveFunction={handleSaveData}
					mediciCliente={mediciCliente}
				/>
				<Box sx={{ height: "60vh", width: "100%" }}>
					<DataGridPro
						components={{
							Toolbar: CustomToolbar,
							Footer: CustomFooterPersonalized,
						}}
						slots={{
							loadingOverlay: LinearProgress,
						}}
						loading={loading}
						localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
						rows={sedi}
						getRowId={(sedi) => sedi.ID}
						columns={columns}
						initialState={{ pinnedColumns: { left: ["Actions"] } }}
					/>
				</Box>
				{props?.proceduraGuidata ? (
					<>
						<Grid
							container
							sx={{
								display: "flex",
								justifyContent: "space-between",
								mt: 2,
								px: 1,
							}}
							fullWidth
						>
							{!props?.gestioneMedicina ? (
								<>
									<Button
										variant="contained"
										className="ButtonMiller"
										startIcon={<ArrowBack />}
										onClick={() => props.handleBack()}
									>
										Indietro
									</Button>
								</>
							) : (
								<div></div>
							)}

							<Button
								variant="contained"
								className="ButtonMiller"
								endIcon={<ArrowForward />}
								onClick={() => {
									if (sedi.length > 0) {
										props.handleComplete();
									} else {
										props.setSnack({
											severity: "error",
											messaggio: "Aggiungi almeno una Sede",
										});
										props.setOpenSnack(true);
									}
								}}
							>
								Completa Passaggio
							</Button>
						</Grid>
					</>
				) : (
					<></>
				)}
			</div>
		</>
	);
};

export default Sedi;

const FormDialog = (props) => {
	const [open, setOpen] = useState(false);
	const [medicoSel, setMedicoSel] = useState(false);
	const [sede, setSede] = useState({
		Descrizione: "",
		Blocco: "",
		Referente: "",
		UnitaProduttiva: "",
		Ubicazione: "",
		Via: "",
		Cap: "",
		Paese: "",
		Ateco: "",
		Inail: "",
		Inps: "",
		DescrizioneAttivita: "",
		Medico: null,
		Cantiere: 0,
	});
	const [sedeBackup, setSedeBackup] = useState(null);

	const [validation, setValidation] = useState(false);
	const handleClickOpen = () => {
		setValidation(false);
		setSede(props.sede);
		setSedeBackup(props.sede);
		if (props.operazione === "Modifica") {
			setMedicoSel(
				props.mediciCliente.filter((m) => m.ID === props.sede.Medico)[0]
			);
		} else {
			setMedicoSel(null);
		}

		setOpen(true);
	};

	const handleClose = () => {
		setSede(newSede);

		setOpen(false);
	};
	const handleChange = (e) => {
		let s = { ...sede };
		s[e.target.name] = e.target.value;
		setSede(s);
	};

	const handleSave = () => {
		props.saveFunction(sede, props.operazione, sedeBackup);
		setOpen(false);
	};

	return (
		<div>
			<Button
				variant="outlined"
				onClick={handleClickOpen}
				id={props.id}
				style={{ display: "None" }}
			>
				Open form dialog
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<DialogTitle style={{ justifyContent: "center", display: "flex" }}>
					{props.operazione} Sede
				</DialogTitle>
				<DialogContent>
					<Grid container xs={12}>
						<Grid
							container
							xs={12}
							sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}
							columnGap={2}
						>
							<Grid item xs={12} md={6}>
								<TextField
									value={sede.Descrizione}
									margin="dense"
									name="Descrizione"
									label="Sede"
									type="text"
									fullWidth
									variant="outlined"
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									value={sede.UnitaProduttiva}
									margin="dense"
									name="UnitaProduttiva"
									label="Unita Produttiva"
									type="text"
									fullWidth
									variant="outlined"
									onChange={handleChange}
								/>
							</Grid>
						</Grid>
						<Grid
							container
							xs={12}
							sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}
							columnGap={2}
						>
							<Grid item xs={12} md={6}>
								<TextField
									value={sede.Ubicazione}
									margin="dense"
									name="Ubicazione"
									label="Città"
									type="text"
									fullWidth
									variant="outlined"
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									value={sede.Via}
									margin="dense"
									name="Via"
									label="Via"
									type="text"
									fullWidth
									variant="outlined"
									onChange={handleChange}
								/>
							</Grid>
						</Grid>
						<Grid
							container
							xs={12}
							sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}
							columnGap={2}
						>
							<Grid item xs={12} md={6}>
								<TextField
									value={sede.Cap}
									margin="dense"
									name="Cap"
									label="Cap"
									type="text"
									fullWidth
									variant="outlined"
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									value={sede.Paese}
									margin="dense"
									name="Paese"
									label="Paese"
									type="text"
									fullWidth
									variant="outlined"
									onChange={handleChange}
								/>
							</Grid>
						</Grid>
						<Grid
							container
							xs={12}
							sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}
							columnGap={2}
						>
							<Grid item xs={12} md={6}>
								<TextField
									value={sede.Ateco}
									margin="dense"
									name="Ateco"
									label="Cod. Ateco"
									type="text"
									fullWidth
									variant="outlined"
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									value={sede.Inail}
									margin="dense"
									name="Inail"
									label="INAIL"
									type="text"
									fullWidth
									variant="outlined"
									onChange={handleChange}
								/>
							</Grid>
						</Grid>
						<Grid
							container
							xs={12}
							sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}
							columnGap={2}
						>
							<Grid item xs={12} md={6}>
								<TextField
									value={sede.Inps}
									margin="dense"
									name="Inps"
									label="INPS"
									type="text"
									fullWidth
									variant="outlined"
									onChange={handleChange}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								md={6}
								style={{ justifyContent: "flex-start", display: "flex" }}
							>
								<FormControlLabel
									style={{ marginLeft: 0, marginRight: 0, marginBottom: 0 }}
									control={
										<Checkbox
											checked={sede.Cantiere === 1 ? true : false}
											onChange={(e) => {
												let sedeUpdate = { ...sede };
												sedeUpdate.Cantiere = e.target.checked ? 1 : 0;
												setSede(sedeUpdate);
											}}
										/>
									}
									label="Cantiere"
								/>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<TextField
								value={sede.DescrizioneAttivita}
								margin="dense"
								name="DescrizioneAttivita"
								label="Descrizione Attività"
								type="text"
								fullWidth
								variant="outlined"
								onChange={handleChange}
							/>
						</Grid>

						<hr
							style={{
								height: "2px",
								marginTop: "10px",
								marginBottom: "5px",
								width: "100%",
							}}
						/>

						<Grid
							container
							xs={12}
							sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}
							columnGap={2}
						>
							<Grid item xs={12} md={6}>
								<TextField
									value={sede.Referente}
									margin="dense"
									name="Referente"
									label="Referente"
									type="text"
									fullWidth
									variant="outlined"
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12} md={6} display="flex" alignItems="center">
								<Autocomplete
									value={medicoSel}
									id="combo-box-demo"
									options={props.mediciCliente}
									getOptionLabel={(option) =>
										option.Nome + " " + option.Cognome
									}
									fullWidth
									onChange={(event, newValue) => {
										setMedicoSel(newValue);
										let s = { ...sede };
										s.Medico = newValue.ID;
										setSede(s);
										//handleChangeCliente(newValue);
									}}
									renderInput={(params) => (
										<TextField {...params} fullWidth label="Medico" />
									)}
								/>
							</Grid>
						</Grid>

						<Grid
							container
							xs={12}
							sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}
							columnGap={2}
						>
							<Grid item xs={12} md={6}>
								<TextField
									value={sede.Lon}
									margin="dense"
									name="Lon"
									label="Longitudine"
									type="number"
									fullWidth
									variant="outlined"
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									value={sede.Lat}
									margin="dense"
									name="Lat"
									label="Latitudine"
									type="number"
									fullWidth
									variant="outlined"
									onChange={handleChange}
								/>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions
					sx={{
						display: "flex",
						justifyContent: "space-between",
						px: 3,
					}}
				>
					<Button
						variant="contained"
						className="ButtonMiller"
						startIcon={<Close />}
						onClick={handleClose}
					>
						Chiudi
					</Button>
					<Button
						variant="contained"
						className="ButtonMiller"
						startIcon={<Check />}
						onClick={handleSave}
					>
						Salva
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

const DeleteDialog = (props) => {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<IconButton aria-label="edit" onClick={handleClickOpen}>
				<Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
			</IconButton>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				TransitionComponent={Transition}
			>
				<DialogTitle id="alert-dialog-title">
					Sei sicuro di voler eliminare la sede selezionata?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Attenzione, sei sicuro di voler eliminare la seguente sede: <br />
						<b>{props.sede + " - " + props.blocco}</b>?
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{
						display: "flex",
						justifyContent: "space-between",
						px: 2,
					}}
				>
					<Button onClick={handleClose}>annulla</Button>
					<Button onClick={() => props.myFunction(props.id)}>Conferma</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

const RiordinamentoMassivoSedi = (props) => {
	const [lavoratori, setLavoratori] = useState([]);
	const [stepDue, setStepDue] = useState(false);
	const [open, setOpen] = React.useState(false);
	const [sedi, setSedi] = useState([]);
	const [loading, setLoading] = useState(true);
	const [dettaglioMedicina, setDettaglioMedicina] = useState([]);
	const [daEliminare, setDaEliminare] = useState([]);
	const anchorRef = useRef(null);
	const [openButton, setOpenButton] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
		setSedi(props.sedi);
		setStepDue(false);
		setLoading(false);
	};

	const columns = [
		{ field: "Descrizione", headerName: "Sede", width: 200 },
		//{ field: "Blocco", headerName: "Blocco", width: 200 },
		//{field: "NomeMedico",headerName: "Medico",hide: false,width: "200",},
		//{ field: "Referente", headerName: "Referente", width: 200 },
		{ field: "ID", headerName: "", hide: true, width: "130" },
		{
			field: "UnitaProduttiva",
			headerName: "Unità produttiva",
			hide: false,
			width: "200",
		},
		{
			field: "Ubicazione",
			headerName: "Città",
			hide: false,
			width: "200",
		},
		{
			field: "Via",
			headerName: "Via",
			hide: false,
			width: "200",
		},
		{
			field: "Cap",
			headerName: "Cap",
			hide: false,
			width: "200",
		},
		{
			field: "Paese",
			headerName: "Paese",
			hide: false,
			width: "200",
		},
		{
			field: "Lon",
			headerName: "Longitudine",
			hide: false,
			width: "200",
		},
		{
			field: "Lat",
			headerName: "Latitudine",
			hide: false,
			width: "200",
		},
	];

	const handleDelete = async () => {
		if (daEliminare.length === -1) {
			return;
		}
		try {
			let eliminati = daEliminare.map((d) => d.ID);
			await unsplash.delete("sedi", {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("jwt")}`,
				},
				data: {
					id: daEliminare.map((d) => d.ID),
					massivo: true,
				},
			});
			alert("ELIMINATI");
			setSedi(sedi.filter((s) => eliminati.indexOf(s.ID) === -1)); //SOLO ELIMINATI
			props.loadSedi();
			setStepDue(true);

			let responseLavoratori = await unsplash.get("lavoratori", {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("jwt")}`,
				},
				params: { sediMassive: true, id: props.cliente },
			});
			setLavoratori(responseLavoratori.data.data);

			loadDettaglioMedicina(props.cliente);
		} catch {
			alert("NON ELIMINATI");
		}
	};

	const loadDettaglioMedicina = async (cliente) => {
		let responseDettaglioMedicina = await unsplash.get("dettaglioMedicina", {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("jwt")}`,
			},
			params: { cliente: cliente },
		});
		setDettaglioMedicina(responseDettaglioMedicina.data.data);
	};

	const UpdateMassivo = async (cliente) => {
		try {
			let responseDettaglioMedicina = await unsplash.put(
				"lavoratori",
				{ massivo: true, lavoratori: lavoratori },
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("jwt")}`,
					},
				}
			);
			alert("ok");
		} catch {
			alert("ERRORE");
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleToggleButton = () => {
		setOpenButton(!openButton);
	};
	const handleCloseButton = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpenButton(false);
	};
	function CustomFooter() {
		return (
			<GridFooterContainer
				sx={{
					display: "flex",
					justifyContent: { xs: "center", md: "flex-start" },
					px: 2,
					flexWrap: { xs: "wrap", md: "nowrap" },
				}}
			>
				{props.screenSize > "768" ? (
					<>
						<GridToolbarColumnsButton
							sx={{
								color: "#11526F",
								bgcolor: "transparent",
								boxShadow: "none",
							}}
							style={{ border: 0 }}
						/>
						<GridToolbarFilterButton
							sx={{
								color: "#11526F",
								bgcolor: "transparent",
								boxShadow: "none",
							}}
							style={{ border: 0 }}
						/>
						<GridToolbarDensitySelector
							sx={{
								color: "#11526F",
								bgcolor: "transparent",
								boxShadow: "none",
							}}
							style={{ border: 0 }}
						/>
						<GridToolbarExport
							sx={{
								color: "#11526F",
								bgcolor: "transparent",
								boxShadow: "none",
							}}
							style={{ border: 0 }}
						/>
					</>
				) : (
					<>
						<ButtonGroup
							aria-label="split button"
							sx={{
								display: "flex",
								justifyContent: "flex-end",
							}}
						>
							<Button
								ref={anchorRef}
								startIcon={
									<MoreVertIcon style={{ height: "28px", width: "28px" }} />
								}
								variant="contained"
								className="ButtonToolbarWrapped"
								onClick={() => {
									handleToggleButton();
								}}
								size="small"
							>
								<span
									style={{
										margin: "0  0 0 -10px",
										alignSelf: "center",
										fontSize: "16px",
										textTransform: "capitalize",
									}}
								>
									Opzioni tabella
								</span>
							</Button>
							<Popper
								sx={{
									zIndex: 100,
								}}
								open={openButton}
								anchorEl={anchorRef.current}
								role={undefined}
								transition
								disablePortal
							>
								{({ TransitionProps, placement }) => (
									<Grow {...TransitionProps}>
										<Paper sx={{ width: "181px" }}>
											<ClickAwayListener onClickAway={handleCloseButton}>
												<MenuList id="split-button-menu" Item sx={{ p: 0 }}>
													<MenuItem
														key={1}
														onClick={handleToggleButton}
														sx={{ p: 0 }}
													>
														<GridToolbarColumnsButton
															sx={{ color: "#11526F" }}
															style={{
																border: 0,
																width: "100%",
																padding: "10px 32px 10px 20px ",
																justifyContent: "flex-start",
															}}
														/>
													</MenuItem>
													<MenuItem
														key={0}
														onClick={handleToggleButton}
														sx={{ p: 0 }}
													>
														<GridToolbarFilterButton
															sx={{ color: "#11526F" }}
															style={{
																border: 0,
																width: "100%",
																padding: "10px 32px 10px 15px ",

																justifyContent: "flex-start",
															}}
															fullWidth
														/>
													</MenuItem>

													<MenuItem key={2} sx={{ p: 0 }}>
														<GridToolbarDensitySelector
															sx={{ color: "#11526F" }}
															style={{
																border: 0,
																width: "100%",
																padding: "10px 32px 10px 18px ",

																justifyContent: "flex-start",
															}}
														/>
													</MenuItem>
													<MenuItem key={3} sx={{ p: 0 }}>
														<GridToolbarExport
															sx={{ color: "#11526F" }}
															style={{
																border: 0,
																width: "100%",
																padding: "10px 32px 10px 18px ",

																justifyContent: "flex-start",
															}}
														/>
													</MenuItem>
												</MenuList>
											</ClickAwayListener>
										</Paper>
									</Grow>
								)}
							</Popper>
						</ButtonGroup>
					</>
				)}
				{/*  */}
			</GridFooterContainer>
		);
	}
	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<Grid
					container
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
				>
					<GridToolbarQuickFilter placeholder="Cerca..." />
				</Grid>
			</GridToolbarContainer>
		);
	}

	return (
		<>
			<Button
				startIcon={<AirplaneTicket />}
				sx={{ color: "white" }}
				variant="outlined"
				className="ButtonMiller"
				onClick={handleClickOpen}
			>
				RIORDINAMENTO MASSIVO SEDI
			</Button>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				TransitionComponent={Transition}
				maxWidth="lg"
				fullWidth={true}
			>
				<DialogTitle id="alert-dialog-title">
					RIORDINAMENTO MASSIVO SEDI
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{stepDue
							? "Ecco i lavoratori senza sede, imposta la nuova sede"
							: "Seleziona le sedi che vuoi eliminare"}
					</DialogContentText>
					{stepDue ? (
						<Grid container spacing={2}>
							{lavoratori.map((l, index) => {
								return (
									<>
										<Grid item xs={4}>
											{l.Cognome + " " + l.Nome}
										</Grid>
										<Grid item xs={4}>
											<FormControl fullWidth>
												<InputLabel id="demo-multiple-name-label">
													Sede
												</InputLabel>
												<Select
													labelId="demo-multiple-name-label"
													id="demo-multiple-name"
													onChange={(e) => {
														let lav = [...lavoratori];
														lav[index].Sede = e.target.value;
														setLavoratori(lav);
													}}
													input={<OutlinedInput label="Sede" />}
												>
													{props.sedi?.map((sede) => (
														<MenuItem key={sede.ID} value={sede.ID}>
															{sede.Descrizione}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={4}>
											<FormControl fullWidth>
												<InputLabel id="demo-multiple-name-label">
													Dettaglio Medicina
												</InputLabel>
												<Select
													// value={lavoratore.DettaglioMedicina}
													labelId="demo-multiple-name-label"
													id="demo-multiple-name"
													onChange={(e) => {
														let lav = [...lavoratori];
														lav[index].DettaglioMedicina = e.target.value;
														setLavoratori(lav);
													}}
													input={<OutlinedInput label="Dettaglio Medicina" />}
												>
													{dettaglioMedicina
														?.filter(
															(dettaglio) =>
																dettaglio.IDSede === lavoratori[index].Sede
														)
														.map((d) => (
															<MenuItem key={d.ID} value={d.ID}>
																{d.Blocco +
																	" - " +
																	d.Categoria +
																	" - " +
																	d.NomeMedico}
															</MenuItem>
														))}
												</Select>
											</FormControl>
										</Grid>
									</>
								);
							})}
						</Grid>
					) : (
						<div style={{ height: 400, width: "100%" }}>
							<DataGridPro
								components={{
									Toolbar: CustomToolbar,
									Footer: CustomFooterPersonalized,
								}}
								slots={{
									loadingOverlay: LinearProgress,
								}}
								loading={loading}
								localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
								rows={sedi}
								getRowId={(sedi) => sedi.ID}
								columns={columns}
								checkboxSelection
								onSelectionModelChange={(ids) => {
									let selectedIDs = new Set(ids);
									let selectedRowData = sedi.filter((row) =>
										selectedIDs.has(row.ID)
									);
									setDaEliminare(selectedRowData);
								}}
							/>
						</div>
					)}
				</DialogContent>
				<DialogActions
					sx={{ display: "flex", justifyContent: "space-between", mx: 2 }}
				>
					<Button
						onClick={handleClose}
						variant="contained"
						className="ButtonMiller"
						startIcon={<Close />}
					>
						annulla
					</Button>
					{stepDue ? (
						<Button
							onClick={UpdateMassivo}
							variant="contained"
							className="ButtonMiller"
							startIcon={<Check />}
						>
							Conferma
						</Button>
					) : (
						<Button
							onClick={handleDelete}
							variant="contained"
							className="ButtonMiller"
							startIcon={<Check />}
						>
							Conferma
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</>
	);
};
