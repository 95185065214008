import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";

import CssBaseline from "@mui/material/CssBaseline";

import { ListItemText, Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import ToggleColorMode from "./Header";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
const drawerWidth = 240;

export default function ClippedDrawer() {
  const [mode, setMode] = useState((prevMode) =>
    prevMode === "light" ? "dark" : "light"
  );
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  /*
  useEffect(() => {// è un loop qui carico i dati 
    setMode(sessionStorage.getItem("tema"))
   
  }, []);*/

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          backgroundColor: mode === "light" ? "#ffffff" : "#0a0705",
        }}
      >
        <CssBaseline />
        <ToggleColorMode mode={mode} mySetMode={setMode} />{" "}
        <div
          className="wrapper flex flex-col min-vh-100 w-full"
          style={{ overflowX: "hidden" }}
        >
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              backgroundColor:
                mode === "light" ? "rgb(238, 242, 246)" : "#0a0705",
              padding: { xs: "10px 10px 10px 10px", md: "30px 30px 30px 30px" },
              margin: { xs: "70px 0px 10px 0px", md: "70px 20px 30px 8px" },
              borderRadius: "5px",
            }}
          >
            <Grid>
              <Outlet />
            </Grid>
          </Box>
        </div>
      </Box>
    </ThemeProvider>
  );
}
